import { sdkAvailabilitySchema } from './types';

export function parseSDKAvailability(value: unknown) {
  const result = sdkAvailabilitySchema.safeParse(value);

  if (!result.success) {
    return undefined;
  }

  return result.data;
}
