import { booleanOptions, clauseOpOptions, isDateOp, segmentAttributeOptions } from '@gonfalon/clauses';
import { format } from 'date-fns';

import { Clause } from 'components/experimentation/common/types';

const opOptions = [...clauseOpOptions, ...segmentAttributeOptions, ...booleanOptions];

const clauseOptions = opOptions.reduce(
  (acc, clause) => ({
    ...acc,
    [String(clause.value)]: clause,
  }),
  {} as Record<string, (typeof opOptions)[0]>,
);

export function getOpLabel(value: string): string {
  if (clauseOptions[value]) {
    return clauseOptions[value].label;
  }
  return '';
}

export function getClauseValue(clause: Clause) {
  return clause.values.map((val, i) => {
    const parsedValue = isDateOp(clause.op)
      ? String(format(new Date(val as number), 'yyyy-MM-dd, kk:mm xxx'))
      : String(val);
    return <code key={i}>{parsedValue}</code>;
  });
}

/**
 * Utility function renders a rule description and clause values
 *
 * @param clause - Clause to render
 */
export const renderRuleDescription = (clause: Clause) => {
  const isNotSegmentMatch = clause.op !== 'segmentMatch';
  const opInWithBoolean = clause.op === 'in' && typeof clause.values[0] === 'boolean';
  const opValue = clause.negate ? `not-${clause.op}` : clause.op;
  const renderContextKind = () => {
    if (clause.attribute === 'kind') {
      //attribute is referring to the type of clause here
      return 'context kind';
    }
    return clause.contextKind ? clause.contextKind : '';
  };

  return (
    <>
      {renderContextKind() !== '' && <code>{renderContextKind()}</code>}{' '}
      {isNotSegmentMatch && clause.attribute !== 'kind' && clause.attribute}{' '}
      {opInWithBoolean ? 'is' : getOpLabel(opValue)} {getClauseValue(clause)}
    </>
  );
};
