import { startOfDay, subDays } from 'date-fns';

import { type CreationDate } from './types';

export function creationDateRangeToDate(creationDate: CreationDate, referenceTimestamp: number) {
  const today = startOfDay(referenceTimestamp);

  switch (creationDate) {
    case '1-week-ago':
      return subDays(today, 7);
    case '1-month-ago':
      return subDays(today, 30);
    case '2-months-ago':
      return subDays(today, 60);
    case '3-months-ago':
      return subDays(today, 90);
    case '6-months-ago':
      return subDays(today, 180);
    case '1-year-ago':
      return subDays(today, 365);
    default:
      return undefined;
  }
}
