import cx from 'clsx';

import 'stylesheets/components/Subhead.css';

type SubheadProps = React.OptgroupHTMLAttributes<{
  className?: string;
}>;

/* eslint-disable import/no-default-export */
export default function Subhead({ className, children }: SubheadProps) {
  return <header className={cx('Subhead', className)}>{children}</header>;
}
