import actionTypes from 'actionTypes/flags';
import formActionTypes from 'actionTypes/forms';
import registry from 'reducers/registry';
import { createRuleExclusionRecord } from 'utils/flagUtils';
import { createFormState } from 'utils/formUtils';

export const ruleExclusionFormKey = 'ruleExclusionForm';

const initialFormState = (ruleExclusionSettings = createRuleExclusionRecord()) =>
  createFormState(ruleExclusionSettings);

export function ruleExclusionForm(state = initialFormState(), action) {
  if (action.model && action.model !== ruleExclusionFormKey) {
    return state;
  }
  switch (action.type) {
    case actionTypes.EDIT_RULE_EXCLUSION: {
      return state.trackField(action.fieldNameOrRuleIndex).revalidate(action.ruleExclusionSettings);
    }
    case actionTypes.UPDATE_RULE_EXCLUSION:
      return state.submitting();
    case actionTypes.UPDATE_RULE_EXCLUSION_DONE:
      return initialFormState(
        createRuleExclusionRecord({
          trackEvents: action.flag.environments.get(action.options.envKey).trackEvents,
          trackEventsFallthrough: action.flag.environments.get(action.options.envKey).trackEventsFallthrough,
          rules: action.flag.environments.get(action.options.envKey).rules,
        }),
      );
    case actionTypes.UPDATE_RULE_EXCLUSION_FAILED:
      return state.submitFailed(action.flag, action.error);
    case formActionTypes.INITIALIZE:
      if (action.model && action.model !== ruleExclusionFormKey) {
        return state;
      }
      return initialFormState(action.initialState);
    case formActionTypes.BLUR:
      return state.hitField(action.field);
    case formActionTypes.DESTROY:
      return initialFormState();
    default:
      return state;
  }
}

export const ruleExclusionFormSelector = (state) => state.ruleExclusionForm;

registry.addReducers({ ruleExclusionForm });
