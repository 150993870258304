import OnOffChip from 'components/OnOffChip';
import VariationComponent from 'components/Variation';
import { colorVariation, VariationType } from 'utils/flagUtils';

import { VariationValue } from './variationInstructionListItemUtils';

export type VariationInfo = {
  index: number;
  displayValue: string;
};

type DefaultVariationInstructionEntryProps = {
  variationType: VariationType;
  onVariation?: VariationInfo;
  offVariation?: VariationInfo;
};

export function DefaultVariationInstructionEntry({
  variationType,
  onVariation,
  offVariation,
}: DefaultVariationInstructionEntryProps) {
  const andText = ' and ';
  const onVariationChanged = onVariation !== undefined;
  const offVariationChanged = offVariation !== undefined;

  return (
    <>
      {onVariationChanged &&
        getFlagTargetingInformation(true, onVariation.index, onVariation.displayValue, variationType)}
      {onVariationChanged && offVariationChanged && <>{andText}</>}
      {offVariationChanged &&
        getFlagTargetingInformation(false, offVariation.index, offVariation.displayValue, variationType)}
    </>
  );
}

function getFlagTargetingInformation(
  isOn: boolean,
  variationIndex: number,
  displayValue: string,
  variationType: VariationType,
) {
  const variationToNewValueText = ' variation to newly added variation ';
  const variationToText = ' variation to ';
  const variationText: string = variationIndex < 0 ? variationToNewValueText : variationToText;

  return (
    <>
      <OnOffChip isOn={isOn} />
      {variationText}
      {variationIndex >= 0 ? (
        <VariationComponent color={colorVariation(variationIndex)} value={displayValue} />
      ) : (
        <VariationValue value={displayValue} variationType={variationType} />
      )}
    </>
  );
}
