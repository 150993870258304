import { Fragment, ReactElement, useDeferredValue, useState } from 'react';
import { components, OptionProps, SingleValueProps } from 'react-select';
import { isFlagStatusEnabled } from '@gonfalon/dogfood-flags';
import { CustomSelect } from '@gonfalon/launchpad-experimental';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { getFlagByKey, getFlags, getFlagStatusesByEnvKeys } from 'sources/FlagAPI';
import { createFlagFilters, FlagSortOrder } from 'utils/flagFilterUtils';
import { FlagStatus as FlagStatusType } from 'utils/flagStatusUtils';
import { Flag } from 'utils/flagUtils';

import { FlagStatus } from './FlagStatus';
import OnOffChip from './OnOffChip';

const MAX_OPTION_COUNT = 15;
const FLAG_STATUS_STALE_TIME = 5 * 60 * 1000; // 5 minutes

type FlagOption = {
  value: string;
  label: string;
  isOn?: boolean;
  isTemporary?: boolean;
  status?: FlagStatusType;
};

function flagToOption(flag: Flag, environmentKey: string): FlagOption {
  return {
    value: flag.key,
    label: flag.name,
    isOn: flag.isOn(environmentKey),
    isTemporary: flag.temporary,
  };
}

type FlagStatusIndicatorProps = {
  scopeId: string;
  projectKey: string;
  environmentKey: string;
  flagKey: string;
  isTemporary?: boolean;
};

function FlagStatusIndicator({ projectKey, environmentKey, flagKey, scopeId, isTemporary }: FlagStatusIndicatorProps) {
  const flagStatusQuery = useQuery({
    queryKey: [scopeId, projectKey, environmentKey, 'flag-statuses', flagKey],
    refetchOnWindowFocus: false,
    staleTime: FLAG_STATUS_STALE_TIME,
    queryFn: async () => getFlagStatusesByEnvKeys(projectKey, flagKey, [environmentKey]),
    enabled: isFlagStatusEnabled(),
  });

  const statusData = flagStatusQuery.data;

  if (typeof isTemporary !== 'undefined' && statusData) {
    const status = statusData.get(environmentKey);
    invariant(status, 'Expected status');
    return <FlagStatus status={status} temporary={isTemporary} />;
  }

  // This div ensures there's no jump when/if the status lands.
  return <div className="u-small" />;
}

export type SelectFlagProps = {
  projectKey: string;
  environmentKey: string;
  flagKey: string | undefined;
  placeholder?: string;
  id: string;
  isDisabled: boolean;
  isInPortal?: boolean;
  maxOptionCount?: number;
  customNoOptionsMessage?: ReactElement | string;
  filterFlagByKey?(key: string): boolean;
  flagKeysToFilterOut?: string[];
  onChange(flag: Flag | undefined): void;
};

export function SelectFlag({
  environmentKey,
  projectKey,
  flagKey,
  id,
  placeholder = 'Search to find a flag',
  isDisabled,
  isInPortal,
  customNoOptionsMessage,
  maxOptionCount = MAX_OPTION_COUNT,
  filterFlagByKey,
  flagKeysToFilterOut,
  onChange,
}: SelectFlagProps) {
  const queryClient = useQueryClient();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const deferredSearchTerm = useDeferredValue(searchTerm);

  const limit = maxOptionCount + (flagKeysToFilterOut?.length || 0);

  const flagQuery = useQuery({
    queryKey: [id, projectKey, environmentKey, 'flags', flagKey],
    enabled: !!flagKey,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      invariant(flagKey, 'Expecting flagKey');
      return getFlagByKey(projectKey, flagKey, [environmentKey]);
    },
  });

  const flagSearchQuery = useQuery({
    queryKey: [id, projectKey, environmentKey, 'flags', 'search', deferredSearchTerm],
    enabled: isMenuOpen,
    refetchOnWindowFocus: false,
    queryFn: async () =>
      getFlags(
        projectKey,
        [environmentKey],
        createFlagFilters({ limit, sort: FlagSortOrder.NAME, q: deferredSearchTerm }),
        {
          fullListing: false,
        },
      ),
  });

  const renderOption = (option: FlagOption) => (
    <Fragment>
      {isFlagStatusEnabled() && (
        <FlagStatusIndicator
          projectKey={projectKey}
          environmentKey={environmentKey}
          flagKey={option.value}
          scopeId={id}
          isTemporary={option.isTemporary}
        />
      )}
      <span className="u-o-hidden u-to-ellipsis u-mr-s">{option.label}</span>
      {typeof option.isOn !== 'undefined' && (
        <OnOffChip isOn={option.isOn} className="u-pullright u-flex-none u-mr-s" />
      )}
    </Fragment>
  );

  const SingleValue = (singleValueProps: SingleValueProps<FlagOption>) => (
    <components.SingleValue {...singleValueProps}>{renderOption(singleValueProps.data)}</components.SingleValue>
  );

  const Option = (optionProps: OptionProps<FlagOption>) => (
    <components.Option {...optionProps}>{renderOption(optionProps.data)}</components.Option>
  );

  const LoadingIndicator = () => null;

  const flags = flagSearchQuery.data?.get('entities')?.get('flags');

  const filteredFlags = flagKeysToFilterOut?.length
    ? flags?.filter((flag) => !flagKeysToFilterOut?.includes(flag.key))
    : flags;

  const handleChange = (option: FlagOption) => {
    invariant(flags, 'Expected to have flags');
    const flagData = flags.get(option.value);

    queryClient.setQueryData([id, projectKey, environmentKey, 'flags', option.value], flagData);

    onChange(option ? flagData : undefined);
  };

  const options: FlagOption[] = [];

  for (const [, flag] of filteredFlags || []) {
    if (options.length < maxOptionCount) {
      options.push(flagToOption(flag, environmentKey));
    }
  }

  let valueOption: FlagOption | undefined;
  if (flagKey) {
    valueOption = flagQuery.data ? flagToOption(flagQuery.data, environmentKey) : { value: flagKey, label: flagKey };
  } else {
    valueOption = undefined;
  }

  const noOptionsMessage = customNoOptionsMessage ? customNoOptionsMessage : 'No flags found';

  return (
    <CustomSelect<FlagOption>
      id={id}
      placeholder={placeholder}
      isInPortal={isInPortal}
      isDisabled={isDisabled}
      isLoading={flagSearchQuery.isFetching}
      value={valueOption}
      options={options}
      noOptionsMessage={() => (flagSearchQuery.isFetching ? 'Loading flags' : noOptionsMessage)}
      customComponents={{ SingleValue, Option, LoadingIndicator }}
      filterOption={filterFlagByKey ? (option) => filterFlagByKey(option.value) : undefined}
      onChange={handleChange}
      onInputChange={(input) => setSearchTerm(input)}
      onMenuOpen={() => setIsMenuOpen(true)}
      onMenuClose={() => setIsMenuOpen(false)}
      styles={{
        option: {
          fontSize: 'var(--lp-font-size-300)',
          display: 'flex',
          alignItems: 'center',
          gap: 'var(--lp-spacing-200)',
        },
        singleValue: {
          width: '100%',
          fontSize: 'var(--lp-font-size-300)',
          display: 'flex',
          alignItems: 'center',
          gap: 'var(--lp-spacing-200)',
        },
      }}
    />
  );
}
