import { Map, OrderedSet } from 'immutable';
import { AnyAction, combineReducers } from 'redux';
import { createSelector } from 'reselect';

import { ContextOptionsAction, ContextResolutionAction, ContextTargetsAction } from 'actions/contexts';
import { ContextItem, ContextItemsEntities, ContextTargetKindKey } from 'components/Contexts/types';
import {
  convertResolveContextsGroupsForUsers,
  groupResolveContextsResponse,
  makeKindKeyStringFromKindKey,
} from 'components/Contexts/utils/contextTargetingUtils';
import { GlobalState } from 'reducers';
import { createRequestReducerByKey } from 'reducers/createRequestReducer';
import { currentEnvironmentKeySelector, currentProjectKeySelector } from 'reducers/projects';
import registry from 'reducers/registry';
import { ImmutableServerError } from 'utils/httpUtils';
import { createRequest, Request } from 'utils/requestUtils';

import 'epics/contexts';

const contextEntities = (
  state: ContextItemsEntities = {},
  action: ContextOptionsAction | ContextTargetsAction | ContextResolutionAction,
) => {
  switch (action.type) {
    case 'contexts/LOAD_TARGET_BATCH_DONE':
    case 'contexts/SEARCH_CONTEXTS_DONE':
    case 'contexts/RESOLVE_CONTEXTS_DONE':
      return { ...state, ...action.contextsResponse.entities };
    default:
      return state;
  }
};

export type ContextResolutionStateType = {
  isFetching?: boolean;
  lastFetched?: number | null;
  prevValues: OrderedSet<ContextTargetKindKey> | null;
  targets: OrderedSet<ContextTargetKindKey>;
  results: ContextItemsEntities;
  error?: ImmutableServerError | null;
};
const initialResolutionState = {
  isFetching: false,
  lastFetched: null,
  prevValues: null,
  targets: OrderedSet(),
  results: {},
  error: undefined,
};

const contextResolution = (
  state: ContextResolutionStateType = initialResolutionState,
  action: ContextResolutionAction,
) => {
  switch (action.type) {
    case 'contexts/RESOLVE_CONTEXTS':
      return { ...state, isFetching: true };
    case 'contexts/RESOLVE_CONTEXTS_DONE':
      return {
        ...state,
        isFetching: false,
        lastFetched: action.timestamp,
        prevValues: state.targets,
        targets: action.targets,
        results: action.results,
        error: null,
      };
    case 'contexts/RESOLVE_CONTEXTS_FAILED':
      return {
        ...state,
        isFetching: false,
        lastFetched: action.timestamp,
        prevValues: state.targets,
        targets: action.targets,
        error: action.error,
      };
    case 'contexts/CLEAR_RESOLUTION_RESULTS':
      return initialResolutionState;
    default:
      return state;
  }
};

const contextRequests = (
  state: ContextItemsRequests = {},
  action: ContextOptionsAction | ContextTargetsAction | ContextResolutionAction,
) => {
  switch (action.type) {
    // contexts loaded in batch by key
    case 'contexts/LOAD_TARGET':
      const kindKey = makeKindKeyStringFromKindKey(action.target);
      return {
        ...state,
        [kindKey]: createRequest({
          isFetching: true,
          error: null,
        }),
      };
    case 'contexts/LOAD_TARGET_BATCH_DONE':
    case 'contexts/LOAD_TARGET_BATCH_FAILED':
      const newTargetRequests = action.targets.reduce((accum, targetKindKey) => {
        const kindKeyString = makeKindKeyStringFromKindKey(targetKindKey);
        return {
          ...accum,
          [kindKeyString]: createRequest({
            isFetching: false,
            lastFetched: action.timestamp,
            error: null,
          }),
        };
      }, {});

      return {
        ...state,
        ...newTargetRequests,
      };

    // contexts loaded by way of resolving keys/emails
    case 'contexts/RESOLVE_CONTEXTS_DONE':
      const newResolveRequests = Object.keys(action.contextsResponse.entities).reduce(
        (accum, kindKeyString) => ({
          ...accum,
          [kindKeyString]: createRequest({
            isFetching: false,
            lastFetched: action.timestamp,
            error: null,
          }),
        }),
        {},
      );
      return {
        ...state,
        ...newResolveRequests,
      };

    default:
      return state;
  }
};

// Another view of the state of context requests by view ID.
const contextViewStatuses = createRequestReducerByKey(
  ['contexts/LOAD_TARGET', 'contexts/LOAD_TARGET_BATCH_DONE', 'contexts/LOAD_TARGET_BATCH_FAILED'],
  (action) => action.viewId,
);

type ContextOptionsByContextKind = {
  [id: string]: { contexts: ContextItem[]; isLoading: boolean };
};
const contextOptions = (state: ContextOptionsByContextKind = {}, action: ContextOptionsAction) => {
  switch (action.type) {
    case 'contexts/SEARCH_CONTEXTS':
      return { ...state, [action.id]: { contexts: [], isLoading: true } };
    case 'contexts/SEARCH_CONTEXTS_DONE':
      return { ...state, [action.id]: { contexts: action.contextsResponse.items, isLoading: false } };
    case 'contexts/SEARCH_CONTEXTS_FAILED':
      return { ...state, [action.id]: { contexts: [], isLoading: false } };
    case 'contexts/CLEAR_CONTEXTS_OPTIONS':
      const updatedState = { ...state };
      delete updatedState[action.id];
      return updatedState;
    default:
      return state;
  }
};

type ContextItemsRequests = { [kindKey: string]: Request };
type ContextsState = {
  entities: ContextItemsEntities;
  resolution: ContextResolutionStateType;
  requests: ContextItemsRequests;
  viewStatuses: Map<string, Request>;
  optionsById: ContextOptionsByContextKind;
};
export const contexts = combineReducers({
  entities: contextEntities,
  resolution: contextResolution,
  requests: contextRequests,
  viewStatuses: contextViewStatuses,
  optionsById: contextOptions,
});

export type ContextsSelectorProps = {
  projKey?: string;
  envKey?: string;
};
type ProjectAndEnvAction = AnyAction & ContextsSelectorProps;
type ContextsByProjectAndEnvironmentKeyState = { [s: string]: ContextsState };

export const contextsByProjectAndEnvironmentKey = (
  state: ContextsByProjectAndEnvironmentKeyState = {},
  action: ProjectAndEnvAction,
) =>
  action.projKey && action.envKey
    ? {
        ...state,
        [`${action.projKey}/${action.envKey}`]: contexts(state[`${action.projKey}/${action.envKey}`], action),
      }
    : state;

export const contextsSelector = (state: GlobalState, props?: ContextsSelectorProps) => {
  let projKey;
  let environmentKey;
  if (!props) {
    projKey = currentProjectKeySelector(state);
    environmentKey = currentEnvironmentKeySelector(state);
  } else {
    projKey = props.projKey ? props.projKey : currentProjectKeySelector(state);
    environmentKey = props.envKey ? props.envKey : currentEnvironmentKeySelector(state);
  }
  return (
    state.contextsByProjectAndEnvironmentKey[`${projKey}/${environmentKey}`] || contexts(undefined, { type: null })
  );
};

export const contextEntitiesSelector = (state: GlobalState, props: ContextsSelectorProps) =>
  contextsSelector(state, props).entities;

export const contextResolutionSelector = (state: GlobalState, props?: ContextsSelectorProps) =>
  contextsSelector(state, props).resolution;

export const contextsRequestsSelector = (state: GlobalState, props: ContextsSelectorProps) =>
  contextsSelector(state, props).requests;

export const groupedContextResolutionSelector = createSelector(contextResolutionSelector, (resolution) =>
  groupResolveContextsResponse(resolution.results, resolution.targets),
);

export const groupedUserContextResolutionSelector = createSelector(groupedContextResolutionSelector, (resolution) =>
  convertResolveContextsGroupsForUsers(resolution),
);

export const contextRequestByKindKeySelector = (
  state: GlobalState,
  props: ContextsSelectorProps & { kindKey: string },
) => contextsRequestsSelector(state, props)[props.kindKey] || createRequest();

export const contextViewStatusesSelector = (state: GlobalState, props: ContextsSelectorProps) =>
  contextsSelector(state, props).viewStatuses;

export const contextViewStatusByIdSelector = (state: GlobalState, props: ContextsSelectorProps & { viewId: string }) =>
  contextViewStatusesSelector(state, props).get(props.viewId, createRequest());

export const contextByKindKeySelector = (state: GlobalState, props: ContextsSelectorProps & { kindKey: string }) =>
  contextEntitiesSelector(state, props)[props.kindKey];

export const contextOptionsByIdSelectorWithoutProps = (
  state: GlobalState,
  projKey: string,
  envKey: string,
  id: string,
) => {
  const contextsByProjectAndEnvironmentKeyState =
    state.contextsByProjectAndEnvironmentKey[`${projKey}/${envKey}`] || contexts(undefined, { type: null });
  const optionsForId = contextsByProjectAndEnvironmentKeyState.optionsById[id];
  return {
    options:
      optionsForId?.contexts.map(({ context }) => ({
        value: String(context.key),
        context,
      })) || [],
    isLoading: optionsForId?.isLoading || false,
  };
};

registry.addReducers({ contextsByProjectAndEnvironmentKey });
