import { Day, Hour, Minute } from './durationUnits';
import type { MonitoringDuration } from './types';

export function millisecondsToMonitoringDuration(
  milliseconds: number,
  selectedUnit?: MonitoringDuration['unit'],
): MonitoringDuration {
  if (selectedUnit) {
    switch (selectedUnit) {
      case 'days':
        return { value: milliseconds / Day, unit: 'days' };
      case 'hours':
        return { value: milliseconds / Hour, unit: 'hours' };
      case 'minutes':
        return { value: milliseconds / Minute, unit: 'minutes' };
      default:
        throw new Error(`Invalid monitoring unit: ${selectedUnit}`);
    }
  }
  if (milliseconds % Day === 0) {
    return { value: milliseconds / Day, unit: 'days' };
  }
  if (milliseconds % Hour === 0) {
    return { value: milliseconds / Hour, unit: 'hours' };
  }
  if (milliseconds % Minute === 0) {
    return { value: milliseconds / Minute, unit: 'minutes' };
  }

  throw new Error(`Invalid monitoring duration: ${milliseconds}`);
}
