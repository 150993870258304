import { Map } from 'immutable';

import { ExperimentRolloutDescription } from 'components/InstructionList/ExperimentRolloutDescription';
import { RolloutDescription } from 'components/InstructionList/RolloutDescription';
import { Flag } from 'utils/flagUtils';
import { toJS } from 'utils/immutableUtils';
import {
  StopMeasuredRolloutOnFallthrough,
  UpdateFallthroughVariationOrRolloutSemanticInstruction,
} from 'utils/instructions/onOff/types';
import {
  StopMeasuredRolloutOnRule,
  UpdateRuleVariationOrRolloutSemanticInstruction,
} from 'utils/instructions/rules/types';

import { VariationInstructionEntry } from './VariationInstructionEntry';

export type VariationOrRolloutInstructionEntryProps = {
  flag: Flag;
  instruction: UpdateRuleVariationOrRolloutSemanticInstruction | UpdateFallthroughVariationOrRolloutSemanticInstruction;
};

export const VariationOrRolloutInstructionEntry = ({ flag, instruction }: VariationOrRolloutInstructionEntryProps) => {
  if (instruction.variationId !== undefined) {
    return <VariationInstructionEntry flag={flag} variationId={instruction.variationId} />;
  } else if (instruction.experimentAllocation) {
    return (
      <ExperimentRolloutDescription
        rolloutWeights={instruction.rolloutWeights ? Map(toJS(instruction.rolloutWeights)) : Map()}
        experimentAllocation={instruction.experimentAllocation}
        variations={flag.variations}
      />
    );
  } else {
    return (
      <RolloutDescription
        rolloutWeights={instruction.rolloutWeights ? Map(toJS(instruction.rolloutWeights)) : Map()}
        bucketBy={instruction.rolloutBucketBy ?? 'key'} // Defaults to 'key' if not specified
        contextKind={instruction.rolloutContextKind}
        variations={flag.variations}
      />
    );
  }
};

export const StopMeasuredRolloutInstructionEntry = ({
  flag,
  instruction,
}: {
  flag: Flag;
  instruction: StopMeasuredRolloutOnFallthrough | StopMeasuredRolloutOnRule;
}) => (
  <div>
    <VariationInstructionEntry flag={flag} variationId={instruction.finalVariationId} />
  </div>
);
