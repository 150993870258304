// eslint-disable-next-line no-restricted-imports
import { useSelector } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, OrderedMap } from 'immutable';
import { combineReducers } from 'redux';

import actionTypes from 'actionTypes/scheduledChanges';
import { GlobalState } from 'reducers';
import { createRequestReducerByKey } from 'reducers/createRequestReducer';
import registry from 'reducers/registry';
import {
  ConflictingChangesState,
  FlagConfigScheduledChangesState,
  InstructionConflictsList,
  ScheduledChange,
} from 'utils/scheduledChangesUtils';

import { getKeyFromFlagProjEnvKeys } from './utils/hashing';

export type ScheduledFlagChangesActionType = {
  deleteUpdates?: List<object>;
  envKey: string;
  projKey: string;
  flagKey: string;
  scheduledChange?: ScheduledChange;
  scheduledChanges?: OrderedMap<string, ScheduledChange>;
  workflowID?: string;
  type: string;
  shouldNotify?: boolean;
};

const scheduledFlagChangesInitialState: Map<string, FlagConfigScheduledChangesState> = Map();

/*
State formatted as:
{
  scheduledFlagChanges: {
    `${flagKey}/${projKey}/${envKey}`: {scheduledChangeWorkflowId: ScheduledChange}
  }
}
*/
export const scheduledFlagChanges = (
  state = scheduledFlagChangesInitialState,
  action: ScheduledFlagChangesActionType,
) => {
  if (action.type === actionTypes.RECEIVE_FLAG_SCHEDULED_CHANGES) {
    const key = getKeyFromFlagProjEnvKeys({ flagKey: action.flagKey, projKey: action.projKey, envKey: action.envKey });
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return state.set(key, action.scheduledChanges!); /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }
  if (
    action.type === actionTypes.CREATE_FLAG_SCHEDULED_CHANGE_DONE ||
    action.type === actionTypes.RECEIVE_FLAG_SCHEDULED_CHANGE
  ) {
    const key = getKeyFromFlagProjEnvKeys({ flagKey: action.flagKey, projKey: action.projKey, envKey: action.envKey });
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    const updatedState = state.setIn(
      [key, action.scheduledChange!.get('_id')],
      action.scheduledChange,
    ); /* eslint-enable @typescript-eslint/no-non-null-assertion */
    const sortedState = updatedState.get(key)?.sortBy((a) => +a.executionDate);
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return state.set(key, sortedState!); /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }
  if (action.type === actionTypes.DELETE_FLAG_SCHEDULED_CHANGE_DONE) {
    const key = getKeyFromFlagProjEnvKeys({ flagKey: action.flagKey, projKey: action.projKey, envKey: action.envKey });
    return state.deleteIn([key, action.workflowID]);
  }
  if (action.type === actionTypes.UPDATE_FLAG_SCHEDULED_CHANGE_DONE) {
    const key = getKeyFromFlagProjEnvKeys({ flagKey: action.flagKey, projKey: action.projKey, envKey: action.envKey });
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return state.setIn(
      [key, action.scheduledChange!.get('_id')],
      action.scheduledChange,
    ); /* eslint-enable @typescript-eslint/no-non-null-assertion */
  } else {
    return state;
  }
};

export const initialConflictState: ConflictingChangesState = fromJS({
  conflicts: null,
  isLoading: false,
  error: null,
});

export const scheduledFlagChangesConflicts = (
  state = initialConflictState,
  action: {
    type: string;
    conflicts: InstructionConflictsList;
    error?: Error;
  },
): ConflictingChangesState => {
  if (action.type === actionTypes.FETCH_CONFLICTS) {
    return state.merge({
      isLoading: true,
      error: null,
      conflicts: null,
    });
  }
  if (action.type === actionTypes.RECEIVE_CONFLICTS) {
    return state.merge({
      isLoading: false,
      error: null,
      conflicts: action.conflicts,
    });
  } else if (action.type === actionTypes.RECEIVE_CONFLICTS_FAILED) {
    return state.merge({
      isLoading: false,
      error: action.error,
      conflicts: action.conflicts,
    });
  } else {
    return state;
  }
};

const scheduledFlagChangesRequest = createRequestReducerByKey(
  [
    actionTypes.FETCH_FLAG_SCHEDULED_CHANGES,
    actionTypes.RECEIVE_FLAG_SCHEDULED_CHANGES,
    actionTypes.RECEIVE_FLAG_SCHEDULED_CHANGES_FAILED,
  ],
  (action) => getKeyFromFlagProjEnvKeys({ flagKey: action.flagKey, projKey: action.projKey, envKey: action.envKey }),
);

const scheduledFlagChangeRequest = createRequestReducerByKey(
  [
    actionTypes.FETCH_FLAG_SCHEDULED_CHANGE,
    actionTypes.RECEIVE_FLAG_SCHEDULED_CHANGE,
    actionTypes.RECEIVE_FLAG_SCHEDULED_CHANGE_FAILED,
  ],
  (action) => getKeyFromFlagProjEnvKeys({ flagKey: action.flagKey, projKey: action.projKey, envKey: action.envKey }),
);

export const scheduleChanges = combineReducers({
  scheduledFlagChangesRequest,
  scheduledFlagChangeRequest,
  scheduledFlagChanges,
  scheduledFlagChangesConflicts,
});

const scheduleChangesSelector = (state: GlobalState) => state.scheduleChanges;

export const scheduleChangesForFlagSelector = (
  state: GlobalState,
  { flagKey, projKey, envKey }: { flagKey: string; projKey: string; envKey: string },
) => {
  const key = getKeyFromFlagProjEnvKeys({ flagKey, projKey, envKey });
  return scheduleChangesSelector(state).scheduledFlagChanges.get(key);
};

export const scheduleChangesForFlagRequestSelector = (
  state: GlobalState,
  { flagKey, projKey, envKey }: { flagKey: string; projKey: string; envKey: string },
) => {
  const key = getKeyFromFlagProjEnvKeys({ flagKey, projKey, envKey });
  return scheduleChangesSelector(state).scheduledFlagChangesRequest.get(key);
};

export const scheduleChangeForFlagRequestSelector = (
  state: GlobalState,
  { flagKey, projKey, envKey }: { flagKey: string; projKey: string; envKey: string },
) => {
  const key = getKeyFromFlagProjEnvKeys({ flagKey, projKey, envKey });
  return scheduleChangesSelector(state).scheduledFlagChangeRequest.get(key);
};

export const scheduledFlagChangesConflictsSelector = (state: GlobalState) =>
  scheduleChangesSelector(state).scheduledFlagChangesConflicts;

export function useScheduledFlagChangesConflicts() {
  return useSelector(scheduledFlagChangesConflictsSelector);
}

registry.addReducers({ scheduleChanges });
