import { type GetSegmentsQueryParams } from '@gonfalon/openapi';

import { UISegmentsListView } from './types';

export function serializeUISegmentsViewForAPI(ui: UISegmentsListView) {
  const api: GetSegmentsQueryParams = {
    sort: ui.sort ? (ui.sort as GetSegmentsQueryParams['sort']) : undefined,
    filter: {
      query: ui.query ?? undefined,
      keys: ui.keys ? ui.keys : undefined,
      tags: ui.tags ? ui.tags : undefined,
      kind: ui.kind ? (ui.kind as NonNullable<GetSegmentsQueryParams['filter']>['kind']) : undefined,
    },
  };
  return api;
}
