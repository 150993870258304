import { createTrackerForCategory } from '@gonfalon/analytics';
// eslint-disable-next-line no-restricted-imports
import { fromJS, OrderedSet } from 'immutable';

import { EventLocation } from 'utils/analyticsUtils';
import { ImmutableMap } from 'utils/immutableUtils';

import { ImmutableServerError } from './httpUtils';

export const trackPaginationEvent = createTrackerForCategory('Pagination');

export type PaginationType = ImmutableMap<{
  isFetching: boolean;
  lastFetched?: number;
  nextPageUrl?: string;
  prevPageUrl?: string;
  firstPageUrl?: string;
  lastPageUrl?: string;
  prevPageOffset: number;
  nextPageOffset: number;
  lastPageOffset: number;
  totalCount: number;
  totalCountWithDifferences?: number;
  pageCount: number;
  error?: ImmutableServerError;
  currentPageIds: OrderedSet<string>;
  ids: OrderedSet<string>;
}>;

export function createPagination(props: Partial<PaginationType> = {}) {
  return fromJS(props) as PaginationType;
}

export enum PaginationClick {
  FIRST = 'first',
  PREV = 'prev',
  NEXT = 'next',
  LAST = 'last',
}

export function getPaginationUrl(pagination: PaginationType | undefined, dir: string) {
  let url: string;

  switch (dir) {
    case PaginationClick.PREV:
      url = pagination?.get('prevPageUrl') || '';
      break;
    case PaginationClick.NEXT:
      url = pagination?.get('nextPageUrl') || '';
      break;
    case PaginationClick.FIRST:
      url = pagination?.get('firstPageUrl') || '';
      break;
    case PaginationClick.LAST:
      url = pagination?.get('lastPageUrl') || '';
      break;
    default:
      url = '';
  }
  return url;
}

export function trackPaginationEventByDirection(direction: string, paginationControlLocation: EventLocation) {
  switch (direction) {
    case PaginationClick.FIRST:
      return trackPaginationEvent('Pagination First Page Button Click', {
        location: paginationControlLocation,
      });
    case PaginationClick.PREV:
      return trackPaginationEvent('Pagination Previous Page Button Click', {
        location: paginationControlLocation,
      });
    case PaginationClick.NEXT:
      return trackPaginationEvent('Pagination Next Page Button Click', {
        location: paginationControlLocation,
      });
    case PaginationClick.LAST:
      return trackPaginationEvent('Pagination Last Page Button Click', {
        location: paginationControlLocation,
      });
    default:
      return null;
  }
}

export function getPaginationOffset(flagsOffset: string) {
  return Math.max(0, (flagsOffset && parseInt(flagsOffset, 10)) || 0);
}

export function handleApiPaginationClick(
  trackingDirection: PaginationClick,
  clickHandler: (s: string) => void,
  pagination: PaginationType | undefined,
  eventLocation: EventLocation,
) {
  trackPaginationEventByDirection(trackingDirection, eventLocation);
  const url = getPaginationUrl(pagination, trackingDirection);
  if (!url) {
    return null;
  }

  const parts = url.split('?');
  if (parts.length > 2) {
    // bad stuff
  }
  const query = parts[1] || '';
  return clickHandler(query);
}
