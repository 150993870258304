import { type ReactNode } from 'react';
import { useSlots } from '@gonfalon/react';

import { Breadcrumbs } from '../Breadcrumbs';
import { SidebarToggle, useSidebarContext } from '../Sidebar';

import styles from './LayoutHeader.module.css';

export function LayoutHeader({ children }: { children?: ReactNode }) {
  const [{ actions }] = useSlots(children, {
    actions: LayoutHeaderActions,
  });

  return (
    <div className={styles.header}>
      <LayoutHeaderLocation />
      {actions}
    </div>
  );
}

function LayoutHeaderLocation() {
  const sidebar = useSidebarContext();

  return (
    <div className={styles.location}>
      <div className={styles.toggle} aria-hidden={sidebar.isVisible ? 'true' : undefined}>
        <SidebarToggle />
      </div>
      <Breadcrumbs />
    </div>
  );
}

export function LayoutHeaderActions({ children }: { children: ReactNode }) {
  return <div className={styles.actions}>{children}</div>;
}
