import type { ReactElement } from 'react';
import { cloneElement } from 'react';
import { ToastQueue, Tooltip, TooltipTrigger } from '@launchpad-ui/components';

type CopyToClipboardProps = {
  children: ReactElement;
  text: string;
  tooltip?: string;
  onPressEvent?(): void;
};

export const CopyToClipboard = ({ children, text, tooltip, onPressEvent }: CopyToClipboardProps) => {
  const pressable = cloneElement(children, {
    onPress: async () => {
      await navigator.clipboard.writeText(text);
      ToastQueue.success('Copied!');
      onPressEvent?.();
    },
  });
  return (
    <TooltipTrigger>
      {pressable}
      <Tooltip placement="bottom">{tooltip || 'Copy to clipboard'}</Tooltip>
    </TooltipTrigger>
  );
};
