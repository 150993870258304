import { addHours } from 'date-fns';

import {
  createEnvironment as createEnvironmentRecord,
  createProjectAndEnvironment,
  Environment,
} from 'utils/environmentUtils';
import http, { jsonToImmutable, jsonToImmutableError } from 'utils/httpUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { createJsonPatch } from 'utils/patchUtils';
import { Project } from 'utils/projectUtils';

// TODO: since the sidebar is loaded on all pages,
// we'd need to update *all* site handlers to pass
// the environment routes to avoid hardcoding the url.

export type CurrentProjectAndEnvResponse = ImmutableMap<{ currentProject: Project; currentEnvironment: Environment }>;

export async function getCurrentProjAndEnv(): Promise<CurrentProjectAndEnvResponse> {
  return http
    .get('/internal/account/environments/current')
    .then(jsonToImmutable)
    .then(createProjectAndEnvironment)
    .catch(jsonToImmutableError);
}

export async function selectEnvironment(environmentId: string): Promise<void> {
  return http
    .post('/internal/account/environments/current', {
      body: JSON.stringify({
        href: `/internal/account/environments/${environmentId}`,
      }),
    })
    .catch(jsonToImmutableError);
}

export async function createEnvironment(project: Project, env: Environment): Promise<Environment> {
  return http
    .post(project.environmentsLink(), {
      body: env.toRep(),
    })
    .then(jsonToImmutable)
    .then(createEnvironmentRecord)
    .catch(jsonToImmutableError);
}

export async function updateEnvironment(oldEnv: Environment, newEnv: Environment): Promise<Environment> {
  const patch = createJsonPatch(oldEnv.toRep(), newEnv.toRep());
  return http
    .patch(oldEnv.selfLink(), {
      body: patch,
    })
    .then(jsonToImmutable)
    .then(createEnvironmentRecord)
    .catch(jsonToImmutableError);
}

export async function deleteEnvironment(env: Environment): Promise<void> {
  return http.delete(env.getIn(['_links', 'self', 'href'])).catch(jsonToImmutableError);
}

export async function resetSDKKey(env: Environment, config: { expiry: number } | null): Promise<Environment> {
  const qs = config && config.expiry ? `?expiry=${addHours(new Date(), config.expiry).valueOf()}` : '';
  const keyUrl = (env.getIn(['_links', 'apiKey', 'href']) as string) + qs;
  return http.post(keyUrl).then(jsonToImmutable).then(createEnvironmentRecord).catch(jsonToImmutableError);
}

export async function resetMobileKey(env: Environment): Promise<Environment> {
  const keyUrl = env.getIn(['_links', 'mobileKey', 'href']);
  return http.post(keyUrl).then(jsonToImmutable).then(createEnvironmentRecord).catch(jsonToImmutableError);
}
