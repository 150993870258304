import { ReactNode, useContext, useEffect, useState } from 'react';
import { Alert, Button } from 'launchpad';

import { FormContext, FormState } from 'utils/formUtils';
import { getFirstInfoMessage } from 'utils/policyUtils';
import { trackAdvancedEditorLinkClicked, trackSimpleEditorLinkClicked } from 'utils/roleUtils';

import JsonPolicyEditor from './JsonPolicyEditor';
import SimplePolicyEditor from './SimplePolicyEditor';

import styles from './styles.module.css';

export type PolicyEditorProps = {
  value: string;
  name: string;
  label: string | ReactNode;
  isCreate?: boolean;
  onChange(value: string, options?: unknown): void;
  onBlur?(): void;
  onDisableSubmit?(): void;
  onEnableSubmit?(): void;
  disabled?: boolean;
  formState?: FormState;
  customEffects?: {
    allow: string;
    deny: string;
  };
  hideActions?: boolean;
  microcopy?: ReactNode;
};

/* eslint-disable import/no-default-export */
export default function PolicyEditor({
  value,
  name,
  label,
  isCreate,
  onChange,
  onBlur,
  onDisableSubmit,
  onEnableSubmit,
  disabled = false,
  formState,
  customEffects,
  hideActions,
  microcopy = <></>,
}: PolicyEditorProps) {
  const [showJsonEditor, setShowJsonEditor] = useState(false);
  // editingIndex is used to keep track of which statement is being edited; -1 means no statement is being edited
  const [statementEditingIndex, setStatementEditingIndex] = useState(-1);

  useEffect(() => {
    // if we're creating a new policy, we want to edit the default empty statement (if it exists)
    if (isCreate && value && value.length) {
      // value.length is a cheap hack to check if there's an existing statement
      setStatementEditingIndex(0);
    }
  }, [isCreate, value]);
  const context = useContext(FormContext);
  const getFormState = () => context.formState || formState;
  const handleStartEditingStatement = (index: number) => {
    onDisableSubmit?.();
    setStatementEditingIndex(index);
  };

  const handleStopEditingStatement = (keepIndex?: boolean) => {
    onEnableSubmit?.();
    if (!keepIndex) {
      setStatementEditingIndex(-1);
    }
  };

  const isPolicyValid = () => !getFormState()?.getError(name);

  const handleToggleJsonEditor = (shouldShowJsonEditor?: boolean) => {
    shouldShowJsonEditor ? trackAdvancedEditorLinkClicked() : trackSimpleEditorLinkClicked();
    // don't allow switching to the simple editor if the policy doesn't validate
    setShowJsonEditor(shouldShowJsonEditor || !isPolicyValid());
  };

  const isDisabledProps = isPolicyValid()
    ? {}
    : {
        disabled: true,
        tooltip: 'Fix any errors to switch to the simple editor',
        tooltipOptions: { placement: 'left', hoverOpenDelay: 300 },
      };

  const statements = value && isPolicyValid() ? JSON.parse(value) : [];
  const message = getFirstInfoMessage(statements);

  return (
    <>
      {/* @ts-expect-error "disabled" is not a valid attribute for HTMLDivElement */}
      <div className={styles.policy} disabled={disabled}>
        <div className={styles.policyHeader}>
          <label htmlFor="policy">{label}</label>
          <Button
            size="tiny"
            className={styles.toggleMode}
            onClick={() => handleToggleJsonEditor(!showJsonEditor)}
            {...isDisabledProps}
          >
            {showJsonEditor ? 'Simple editor' : 'Advanced editor'}
          </Button>
        </div>
        {microcopy}
        {showJsonEditor ? (
          <JsonPolicyEditor name={name} onBlur={onBlur} onChange={onChange} value={value} formState={getFormState()} />
        ) : (
          <SimplePolicyEditor
            customEffects={customEffects}
            hideActions={hideActions}
            editingIndex={statementEditingIndex}
            onChange={(v) => onChange(v, { shouldClearServerError: true })}
            onStartEditingStatement={handleStartEditingStatement}
            onStopEditingStatement={handleStopEditingStatement}
            statements={statements}
          />
        )}
        {message && (
          <Alert kind="info" className="u-mt-s">
            {message}
          </Alert>
        )}
      </div>
    </>
  );
}
