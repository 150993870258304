import { getPlanTypeForLimits as getModernSubscriptionPlanTypeForLimits } from '../internal/modernSubscription';
import { Subscription } from '../types';

import { isModernSubscription } from './isModernSubscription';

export function getPlanTypeForLimits(subscription: Subscription) {
  if (isModernSubscription(subscription)) {
    return getModernSubscriptionPlanTypeForLimits(subscription);
  }

  // empty string for legacy plan type
  return '';
}
