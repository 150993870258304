import { useEffect } from 'react';
import { noop } from '@gonfalon/es6-utils';

import { fetchGoaltenderManifests } from 'actions/integrations';
import { useIntegrationManifests as useReduxIntegrationManifests } from 'reducers/integrations';
import { ready } from 'utils/reduxUtils';

import { useDispatch } from './useDispatch';

export function useGoaltenderManifests(props: { enabled?: boolean } = { enabled: true }) {
  const data = useReduxIntegrationManifests();
  const dispatch = useDispatch();

  const isReady = ready(data);

  useEffect(() => {
    if (props.enabled && !isReady) {
      dispatch(fetchGoaltenderManifests())?.catch(noop);
    }
  }, [props.enabled, data]);

  return {
    isReady,
    manifests: data.get('manifests'),
  } as const;
}
