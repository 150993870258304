import { forwardRef } from 'react';
import cx from 'clsx';

import './styles.css';

export type DataListItemProps = {
  className?: string;
  isActive?: boolean;
  heading?: boolean;
  children?: React.ReactNode;
  role?: string;
  tabIndex?: number;
  onKeyPress?(): void;
  onClick?(): void;
  testid?: string;
  useSpaceBetween?: boolean;
};

export type Ref = HTMLDivElement;

export const DataListItem = forwardRef<Ref, DataListItemProps>((props, ref) => {
  const { heading, isActive, className, children, useSpaceBetween, ...other } = props;

  return (
    <div
      ref={ref}
      {...other}
      className={cx(
        'DataList-item',
        { 'DataList-item--heading': heading, 'is-active': isActive, 'is-space-between': useSpaceBetween },
        className,
      )}
    >
      {children}
    </div>
  );
});
