import { useEffect } from 'react';
import { useStorageSlot } from '@gonfalon/browser-storage';

import { createStorageKey } from './internal/createStorageKey';

export function useFlashMessage(key: string) {
  const [message, slot] = useStorageSlot(createStorageKey(key), { storageType: 'sessionStorage' });

  useEffect(() => {
    slot.remove();
  }, []);

  return message;
}
