import type { ComponentProps } from 'react';
import LaunchDarklyOsmoLogoFullSvg from 'img/launchdarkly-osmo.svg';
import LaunchDarklyOsmoLogoMarkSvg from 'img/launchdarkly-osmo-logomark.svg';

type LaunchDarklyOsmoProps = ComponentProps<'svg'> & {
  mark?: boolean;
};

const LaunchDarklyOsmo = ({ mark, ...props }: LaunchDarklyOsmoProps) =>
  mark ? <LaunchDarklyOsmoLogoMarkSvg {...props} /> : <LaunchDarklyOsmoLogoFullSvg {...props} />;

export { LaunchDarklyOsmo };
export type { LaunchDarklyOsmoProps };
