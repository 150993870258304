import { DependencyList, useCallback, useLayoutEffect, useRef } from 'react';

export function useCallbackIfMounted<T extends Parameters<typeof useCallback>[0]>(
  callback: T,
  deps: DependencyList = [],
) {
  const isMounted = useRef(false);

  useLayoutEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useCallback((...params: Parameters<any>) => {
    if (isMounted.current) {
      callback(...params);
    }
  }, deps);
}
