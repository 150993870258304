import { parseNumber } from './parseNumber';
import { parseSort } from './parseSort';
import { parseUIFlagFilterFromBrowser } from './parseUIFlagFilterFromBrowser';
import { type UIFlagListView } from './types';

export function parseUIFlagListViewFromBrowser(searchParams: URLSearchParams) {
  const ui: UIFlagListView = {};
  ui.offset = parseNumber(searchParams.get('offset'));
  ui.sort = parseSort(searchParams.get('sort'));
  ui.filter = parseUIFlagFilterFromBrowser(searchParams);
  return ui;
}
