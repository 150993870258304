import { createContext, ReactNode, useContext } from 'react';

type NewInformationArchitectureState = {};

export const NewInformationArchitectureContext = createContext<NewInformationArchitectureState | undefined>(undefined);

export function NewInformationArchitectureContextProvider({ children }: { children?: ReactNode }) {
  return <NewInformationArchitectureContext.Provider value={{}}>{children}</NewInformationArchitectureContext.Provider>;
}

export function useIsInNewInformationArchitecture() {
  const context = useContext(NewInformationArchitectureContext);
  if (context === undefined) {
    return false;
  }

  return true;
}
