import { Markdown, MarkdownProps } from '@launchpad-ui/core';
import parse from 'html-react-parser';

import { atlassianSmartLink, SmartLink } from './smartLinks';
import { parseLinkFromDOMNode } from './utils';

type SmartMarkdownProps = MarkdownProps & { onOpen?: () => void; onClose?: () => void };

export function SmartMarkdown({ onOpen, onClose, ...markdownProps }: SmartMarkdownProps) {
  const smartLinks: SmartLink[] = [atlassianSmartLink];

  return (
    <Markdown {...markdownProps}>
      {(parsedMarkdown) =>
        parse(parsedMarkdown, {
          replace: (node) => {
            const parsedLink = parseLinkFromDOMNode(node);
            if (!parsedLink) {
              return;
            }

            const smartLink = smartLinks.find((s) => parsedLink.url.hostname.endsWith(s.domain));
            if (!smartLink) {
              return;
            }

            return smartLink.render({ href: parsedLink.url.toString(), text: parsedLink.text, onOpen, onClose });
          },
        })
      }
    </Markdown>
  );
}
