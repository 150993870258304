import { isActiveTrialWithNoPlan as isLegacyActiveTrialWithNoPlan } from '../internal/legacySubscription';
import { isActiveTrialWithNoPlan as isModernActiveTrialWithNoPlan } from '../internal/modernSubscription';
import { Subscription } from '../types';

import { isModernSubscription } from './isModernSubscription';

export function isActiveTrialWithNoPlan(subscription: Subscription) {
  if (isModernSubscription(subscription)) {
    return isModernActiveTrialWithNoPlan(subscription);
  }
  return isLegacyActiveTrialWithNoPlan(subscription);
}
