import { typedObjectKeys } from '@gonfalon/types';
import { closestTo, startOfDay, subDays, subHours } from 'date-fns';

import { EvaluationDate } from './types';

export function evaluationDateToReferenceDate(timestamp: number, referenceTimestamp: number) {
  const referenceDate = startOfDay(referenceTimestamp);
  const optionsToDate: Record<EvaluationDate, number> = {
    'last-60-minutes': subHours(referenceTimestamp, 1).getTime(),
    'last-24-hours': subHours(referenceTimestamp, 24).getTime(),
    'last-7-days': subDays(referenceDate, 7).getTime(),
    'last-14-days': subDays(referenceDate, 14).getTime(),
    'last-30-days': subDays(referenceDate, 30).getTime(),
    'last-60-days': subDays(referenceDate, 60).getTime(),
  };

  const closestDateToEvalDate = closestTo(timestamp, Object.values(optionsToDate))?.getTime();
  return typedObjectKeys(optionsToDate).find((key) => optionsToDate[key] === closestDateToEvalDate);
}
