import { Fragment, useEffect, useState } from 'react';
import { VariationColor } from '@gonfalon/flags';
import { schemas } from '@gonfalon/openapi';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';
import { colorVariation } from 'ia-poc/services/data-model/flags/variation-utils';
import { Tooltip } from 'launchpad';

import {
  Cell,
  CellLayoutValue,
  DirectionValue,
  DirectionValueObject,
  GapValue,
  GapValueObject,
  Grid,
} from 'components/ui/grid';
import { Rollout as RolloutType } from 'utils/flagUtils';

import { StageCount } from '../../utils';

import styles from './Rollout.module.css';

type Variation = schemas['Variation'];
type VariationWithTooltip = Variation & { tooltip?: string };

const MAX_WIDTH_HORIZONTAL = 645;
export interface RolloutProps {
  variations: VariationWithTooltip[];
  stageCount: StageCount;
  rollout?: RolloutType;
}

export function Rollout({ variations, stageCount, rollout }: RolloutProps) {
  const [isHorizontal, setIsHorizontal] = useState(window.innerWidth > MAX_WIDTH_HORIZONTAL);
  const hasSixStages = stageCount === 6;

  useEffect(() => {
    function handleResize() {
      setIsHorizontal(window.innerWidth > MAX_WIDTH_HORIZONTAL);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderRolloutPercentage = (index: number, name?: string) => {
    if (rollout) {
      const rolloutVariation = rollout.getWeightForIndex(index);
      if (rolloutVariation !== null) {
        const percentage = `${rolloutVariation / 1000}%`;
        return (
          <h3 className={styles.percentageTitle} data-test-id={`${name}-${percentage}`}>
            {percentage}
          </h3>
        );
      }
    }
    return null;
  };

  const calculateBorderRadii = (percentage: number) => {
    switch (true) {
      case percentage === 100:
        return '0px 0px var(--lp-border-radius-medium) var(--lp-border-radius-medium)';
      case percentage < 3:
        return '0px 0px 0px 50px'; // need a very high border radius to show when percentage is small
      default:
        return '0px 0px 0px var(--lp-border-radius-medium)';
    }
  };

  const renderProgressBar = (index: number) => {
    if (rollout) {
      const rolloutVariation = rollout.getWeightForIndex(index);
      if (rolloutVariation !== null) {
        const percentage = rolloutVariation / 1000;
        const fillerStyles = {
          width: `${percentage}%`,
          backgroundColor: colorVariation(index),
          height: '100%',
          borderRadius: calculateBorderRadii(percentage),
        };
        return (
          <div className={styles.progressBar}>
            {rolloutVariation !== 0 && (
              <div className={styles.progressContainer}>
                <div aria-valuenow={percentage} style={fillerStyles}></div>
              </div>
            )}
          </div>
        );
      }
    }
  };

  const renderArrow = (index: number) =>
    index === variations.length - 1 ? (
      <></>
    ) : (
      <div className={cx(styles.arrow, { [styles.sixStageArrow]: hasSixStages })}>
        <Icon name={!isHorizontal && hasSixStages ? 'arrow-down-thin' : 'arrow-right-thin'} size="small" />
      </div>
    );

  const render = (stage: StageCount) => {
    if (!stage) {
      return <></>;
    }

    let gap: GapValue | GapValueObject = '1';
    let direction: DirectionValue | DirectionValueObject | undefined;
    switch (stage) {
      case 6:
        gap = { mobile: '0', desktop: '1' };
        direction = isHorizontal ? 'row' : 'column';
        break;
    }

    return (
      <>
        <Grid gap={gap} direction={direction} className={styles.container} data-test-id="rollout">
          {variations.map((variation, index) => (
            <Fragment key={variation._id}>
              <Tooltip targetClassName={styles.tooltip} content={variation.description} placement="top">
                <Cell layout={CellLayoutValue.TWELVE_OF_TWELVE}>
                  <div className={styles.variationGroup}>
                    <div
                      className={cx(styles.variationName, {
                        [styles.variationNameWithRollout]: !!rollout,
                      })}
                    >
                      <VariationColor variationIndex={index} />
                      <p>{variation.name}</p>
                    </div>
                    {renderRolloutPercentage(index, variation.name)}
                  </div>
                  {renderProgressBar(index)}
                </Cell>
              </Tooltip>
              {renderArrow(index)}
            </Fragment>
          ))}
        </Grid>
        {rollout && (
          <Grid>
            <Cell>
              By <code>{rollout.getContextKind()}</code> <code>{rollout?.bucketBy || 'key'}</code>
            </Cell>
          </Grid>
        )}
      </>
    );
  };

  return render(stageCount);
}
