import { Component, ComponentType, ForwardedRef, forwardRef } from 'react';
import { isFunction } from '@gonfalon/es6-utils';

export const reloadOnFailure = async <T,>(fn: () => Promise<T>) =>
  new Promise<T>((resolve) => {
    fn()
      .then(resolve)
      .catch(() => {
        window.location.reload();
      });
  });

type LazyLoadProps = {
  forwardedRef: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

type LazyLoadState = {
  dependencies: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export const lazyLoad =
  <T extends { [k: string]: () => Promise<any> }>( // eslint-disable-line @typescript-eslint/no-explicit-any
    deps: T,
  ) =>
  <PassedComponentProps,>(WrappedComponent: ComponentType<PassedComponentProps>) => {
    class LazyLoad extends Component<PassedComponentProps & LazyLoadProps, LazyLoadState> {
      constructor(props: PassedComponentProps & LazyLoadProps) {
        super(props);
        const dependencies = Object.keys(deps).reduce(async (obj, dependencyName) => {
          const dependency = deps[dependencyName];
          if (isFunction(dependency)) {
            await dependency().then((module) => {
              this.setState((previousState) => ({
                dependencies: {
                  ...previousState.dependencies,
                  [dependencyName]: module.default ? module.default : module,
                },
              }));
            });
            return obj;
          }
          return {
            ...obj,
            [dependencyName]: dependency,
          };
        }, {});
        this.state = {
          dependencies,
        };
      }

      render() {
        const { forwardedRef, ...rest } = this.props;
        const { dependencies } = this.state;

        const props = {
          ...dependencies,
          ...rest,
        };
        return <WrappedComponent {...props} ref={forwardedRef} />;
      }
    }

    const forwardRefFn = (
      props: PassedComponentProps,
      ref: ForwardedRef<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
    ) => <LazyLoad {...props} forwardedRef={ref} />;
    const name = WrappedComponent.displayName || WrappedComponent.name;
    forwardRefFn.displayName = `lazyLoad(${name})`;

    return forwardRef(forwardRefFn);
  };

export const withCodeMirror = lazyLoad({
  CodeMirror: async () => import(/* webpackChunkName: "codemirror" */ 'components/ui/codeEditor/codemirror'),
});
