import { useEffect, useLayoutEffect, useRef } from 'react';
import { type BrowserKeyComboEvent, bindKeyCombo, unbindKeyCombo } from '@rwh/keystrokes';

export function useKeyCombo(combo: string, callback: () => void) {
  const savedCallback = useRef(callback);

  useLayoutEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const handler: Parameters<typeof bindKeyCombo>[1] = {
      onReleased: (event) => {
        if (shouldFilterInputEvent(event)) {
          return;
        }

        savedCallback.current();
      },
    };

    bindKeyCombo(combo, handler);

    return () => {
      unbindKeyCombo(combo, handler);
    };
  }, [combo, savedCallback.current]);
}

function shouldFilterInputEvent(e: BrowserKeyComboEvent) {
  const browserEvent = e.finalKeyEvent.originalEvent;
  if (!browserEvent) {
    return false;
  }

  const target = browserEvent.target;

  if (!(target instanceof HTMLElement)) {
    return false;
  }

  let readonly = false;
  if (target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement) {
    readonly = target.readOnly;
  }

  return (
    target.isContentEditable ||
    ((target.tagName === 'INPUT' || target.tagName === 'TEXTAREA' || target.tagName === 'SELECT') && !readonly)
  );
}
