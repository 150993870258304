import { formatValueAsCurrency, isCurrency } from './formatValueAsCurrency';

function hasAllTrailingZerosInDecimal(num: number | string) {
  const decimalPart = String(num).split('.')[1];
  return decimalPart ? /^0+$/.test(decimalPart) : false;
}

export function formatTreatmentResultValue(value: number | string, unit?: string) {
  if (Number(value) === 0) {
    return '0.00';
  }

  if (unit && isCurrency(unit)) {
    return formatValueAsCurrency(value, unit);
  }

  if (hasAllTrailingZerosInDecimal(value)) {
    return Number(value).toFixed(2);
  }

  return `${value}${unit ? `${unit}` : ''}`;
}
