import {
  Group,
  IconButton,
  Input,
  SearchField as LPSearchField,
  SearchFieldProps as LPSearchFieldProps,
} from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';

interface SearchFieldProps extends LPSearchFieldProps {
  placeholder?: string | undefined;
}

export function SearchField({ placeholder, ...props }: SearchFieldProps) {
  return (
    <LPSearchField {...props}>
      <Group>
        <Icon name="search" size="small" />
        <Input placeholder={placeholder} />
        <IconButton aria-label="clear" icon="cancel-circle-outline" size="small" variant="minimal" />
      </Group>
    </LPSearchField>
  );
}
