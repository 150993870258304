import { useContext } from 'react';
import { isArray, isString } from '@gonfalon/es6-utils';
import { isImmutable } from 'immutable';
import { Alert } from 'launchpad';

import { AnyFormRecord, FormContext, FormState } from 'utils/formUtils';
import { ImmutableServerError } from 'utils/httpUtils';

import './styles/Form.css';

export type FormErrorProps = {
  exceptStatus?: number[] | number;
  filter?(v?: string | ImmutableServerError): boolean;
  formState?: FormState<AnyFormRecord>;
  className?: string;
};

export function FormError(props: FormErrorProps) {
  const { exceptStatus = [], filter = () => true, className = '' } = props;
  const formState = useContext(FormContext)?.formState || props.formState;
  const statuses = isArray(exceptStatus) ? exceptStatus : [exceptStatus];
  const error = formState?.getServerError();
  const message = isImmutable(error) ? error.get('message', 'An unknown error occurred. Try again later.') : error;

  if (
    formState?.hasServerError() &&
    !statuses.includes(formState.getIn(['serverError', 'status'])) &&
    filter &&
    filter(error) &&
    message &&
    isString(message)
  ) {
    const messages = message.split('\n');
    const body =
      messages.length === 1 ? (
        message
      ) : (
        <ul>
          {messages.map((item: string, i: number) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      );
    return (
      <Alert className={className} kind="error">
        {body}
      </Alert>
    );
  } else {
    return null;
  }
}
