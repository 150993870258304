// eslint-disable-next-line no-restricted-imports
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { track } from '@gonfalon/analytics';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { areWorkflowTemplatesEnabled } from '@gonfalon/dogfood-flags';
import { useFlagKey, useParam, useProjectKey } from '@gonfalon/router';
import { useMutation, useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { useWorkflowTemplateKeyURLSearchParam } from 'components/CustomWorkflows/WorkflowBuilder/WorkflowBuilderState/WorkflowBuilderStateContainer/utils';
import {
  getCustomWorkflowById,
  reportCustomWorkflowFromTemplate,
  WorkflowTemplateReportParams,
} from 'sources/CustomWorkflowsAPI';
import {
  createNewWorkflowTemplateFromWorkflow,
  CreateWorkflowTemplateBody,
  getWorkflowTemplates,
  WorkflowTemplatesSearchParams,
} from 'sources/WorkflowTemplatesAPI';
import { CustomWorkflow, workflowsPath } from 'utils/customWorkflowUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import { WorkflowTemplate, WorkflowTemplateReport, WorkflowTemplateSummary } from 'utils/workflowTemplateUtils';

export const WORKFLOW_TEMPLATES_QUERY_KEY = 'workflowTemplates';

export function useWorkflowTemplateList(
  searchParams: WorkflowTemplatesSearchParams & { summary: true },
  options?: Omit<UseQueryOptions<WorkflowTemplateSummary[], ImmutableServerError>, 'queryKey'>,
  location?: string,
): UseQueryResult<WorkflowTemplateSummary[], ImmutableServerError>;
export function useWorkflowTemplateList(
  searchParams?: WorkflowTemplatesSearchParams & { summary?: false },
  options?: Omit<UseQueryOptions<WorkflowTemplate[], ImmutableServerError>, 'queryKey'>,
  location?: string,
): UseQueryResult<WorkflowTemplate[], ImmutableServerError>;
export function useWorkflowTemplateList<T extends WorkflowTemplatesSearchParams>(
  searchParams?: T,
  options?: T extends { summary: true }
    ? Omit<UseQueryOptions<WorkflowTemplateSummary[], ImmutableServerError>, 'queryKey'>
    : Omit<UseQueryOptions<WorkflowTemplate[], ImmutableServerError>, 'queryKey'>,
  location?: string,
): UseQueryResult<Array<WorkflowTemplateSummary | WorkflowTemplate>, ImmutableServerError> {
  // @ts-expect-error Ignoring a gnarly type mismatch. We'll fix this in a future PR,
  // when we come around to updating workflows for Galaxy.
  return useQuery({
    queryKey: [WORKFLOW_TEMPLATES_QUERY_KEY, searchParams],
    queryFn: async () => {
      const response = await getWorkflowTemplates(searchParams as { summary: false });

      if (searchParams?.search) {
        track('Search submitted', 'Workflow templates', { location: location ?? 'Template selection modal' });
      }

      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      return response.result.items && response.entities.workflowTemplates
        ? response.result.items.map((id) => response.entities.workflowTemplates![id])
        : []; /* eslint-enable @typescript-eslint/no-non-null-assertion */
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...options,
  });
}

export function useWorkflowTemplateReport(
  params: WorkflowTemplateReportParams,
  options?: Omit<UseQueryOptions<WorkflowTemplateReport, ImmutableServerError>, 'queryKey'>,
) {
  return useQuery<WorkflowTemplateReport, ImmutableServerError>({
    queryKey: [WORKFLOW_TEMPLATES_QUERY_KEY, 'report', params],
    queryFn: async () => reportCustomWorkflowFromTemplate(params),
    ...options,
  });
}

// this will fetch a workflow template report for the template whose key is include as a URLSearchParam,
// and for the project/environment/flag in the URL as well
// if not found, navigates back to the workflow builder
export function useWorkflowBuilderTemplateReport() {
  const templateKey = useWorkflowTemplateKeyURLSearchParam();
  const projKey = useProjectKey();
  const envKey = useSelectedEnvironmentKey();
  const flagKey = useFlagKey();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const query = useWorkflowTemplateReport(
    {
      projKey,
      envKey,
      flagKey,
      workflowTemplateKey: templateKey || '',
    },
    {
      enabled: areWorkflowTemplatesEnabled() && !!templateKey,
      staleTime: 60000,
      retry: (retryCount, error) => retryCount < 3 && error.get('status') !== 404,
      refetchOnWindowFocus: false,
    },
  );

  const { isError, error } = query;

  useEffect(() => {
    if (isError) {
      navigate(workflowsPath({ projKey, envKey, flagKey }, { new: true }));

      dispatch({
        type: 'workflowTemplates/REPORT_WORKFLOW_TEMPLATE_FAILED',
        error,
        workflowTemplate: { key: templateKey },
      });
      return;
    }
  }, [isError]);

  // only return the real query result if the flag is enabled,
  // otherwise, return a mock query result with no data
  return areWorkflowTemplatesEnabled()
    ? query
    : ({
        data: undefined,
        error: null,
        isError: false,
        isIdle: false,
        isPending: false,
        isLoadingError: false,
        isRefetchError: false,
        isSuccess: true,
        status: 'success',
      } as unknown as ReturnType<typeof useWorkflowTemplateReport>);
}

export function useCreateWorkflowTemplate() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation<WorkflowTemplate, ImmutableServerError, CreateWorkflowTemplateBody>({
    mutationFn: async (body: CreateWorkflowTemplateBody) => createNewWorkflowTemplateFromWorkflow(body),
    onError: (error) => {
      dispatch({
        type: 'workflowTemplates/CREATE_WORKFLOW_TEMPLATE_FAILED',
        error,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [WORKFLOW_TEMPLATES_QUERY_KEY],
      });
    },
  });
}

export function useGetSourceWorkflow() {
  const projKey = useProjectKey();
  const envKey = useSelectedEnvironmentKey();
  const flagKey = useFlagKey();

  const workflowId = useParam('workflowId', {
    optional: true,
  });

  return useQuery<CustomWorkflow>({
    queryKey: ['source-workflow-for-create-template'],
    queryFn: async () => getCustomWorkflowById(projKey, envKey, flagKey, workflowId ?? ''),
    enabled: !!workflowId,
  });
}
