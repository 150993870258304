import { schemas } from '@gonfalon/openapi';
import { ProgressiveRolloutDetail } from '@gonfalon/progressive-rollouts';
import { pluralize } from '@gonfalon/strings';
import { v4 } from 'uuid';
import { z } from 'zod';

import { Flag, getControlAndTestVariations } from 'utils/flagUtils';
import { SemanticInstructionProgressiveRolloutConfiguration } from 'utils/instructions/shared/types';

import { PendingProgressiveRollout } from './FlagTargetingPendingProgressiveRolloutsProvider/FlagTargetingPendingProgressiveRolloutsProvider';
import {
  ProgressiveRolloutConfiguration,
  progressiveRolloutConfigurationSchema,
  ProgressiveRolloutConfigurationStep,
} from './schemas';

export const progressiveRolloutFailedRemediationGuidance =
  'Stop and restart the rollout or choose a variation to serve';

const rolloutSchema = z.number().int().min(10).max(100000);
export const toPercentage = (rollout: number) => clamp(rolloutSchema.parse(rollout) / 100000);

export const fromPercentage = (percentage: number) => clamp(percentage * 100000);

export function convertProgressiveRolloutOutputRepToProgressiveRolloutConfigurationState(
  rep: schemas['ProgressiveRolloutAudienceConfigurationRep'],
): Pick<ProgressiveRolloutConfiguration, 'steps'> {
  return {
    steps: rep.stages
      ?.filter(
        (
          stage,
        ): stage is Required<NonNullable<schemas['ProgressiveRolloutAudienceConfigurationRep']['stages']>[number]> =>
          stage.rolloutWeight !== undefined && stage.durationMs !== undefined,
      )
      .map(({ rolloutWeight, durationMs }) => ({
        key: v4(),
        rolloutWeight,
        duration: getProgressiveRolloutStepDurationFromMs(durationMs),
      }))
      // we don't want to include the final step to rollout to 100% in the builder because that's a step you can't configure
      // the config validation schema will fail if we include this
      .filter(({ rolloutWeight }) => rolloutWeight !== 100000) ?? [
      { key: v4(), rolloutWeight: 10000, duration: { quantity: 1, unit: 'day' } },
    ],
  };
}

const clampTo3FractionDigits = (ms: number) => clamp(Number(ms.toFixed(3)));
export function getProgressiveRolloutStepDurationFromMs(ms: number): ProgressiveRolloutConfigurationStep['duration'] {
  const minutes = ms / 1000 / 60;
  if (minutes < 60) {
    return {
      quantity: Math.round(minutes), // fractional minutes are not allowed
      unit: 'minute',
    };
  }

  const hours = minutes / 60;
  if (hours < 24) {
    return {
      quantity: clampTo3FractionDigits(hours),
      unit: 'hour',
    };
  }

  return {
    quantity: clampTo3FractionDigits(hours / 24),
    unit: 'day',
  };
}

export function getProgressiveRolloutStepDurationAsMs(duration: ProgressiveRolloutConfigurationStep['duration']) {
  const unit = duration.unit;
  switch (unit) {
    case 'day': {
      return Math.round(duration.quantity * 24 * 60 * 60 * 1000);
    }
    case 'hour': {
      return Math.round(duration.quantity * 60 * 60 * 1000);
    }
    case 'minute': {
      return Math.round(duration.quantity * 60 * 1000);
    }
    default: {
      throw new Error(`invalid progressive rollout step duration unit. Received "${unit}"`);
    }
  }
}

export function formatTotalDuration(total: { weeks: number; days: number; hours: number; minutes: number }) {
  return [
    total.weeks > 0 && `${total.weeks} ${pluralize('week', total.weeks)}`,
    total.days > 0 && `${total.days} ${pluralize('day', total.days)}`,
    total.hours > 0 && `${total.hours} ${pluralize('hour', total.hours)}`,
    total.minutes > 0 && `${total.minutes} ${pluralize('minute', total.minutes)}`,
  ]
    .filter(Boolean)
    .join(', ');
}

const oneMinute = 1000 * 60;
const oneHour = oneMinute * 60;
const oneDay = oneHour * 24;
const oneWeek = oneDay * 7;

export function msToTotalDuration(milliseconds: number) {
  const weeks = milliseconds / oneWeek;
  const days = clamp((weeks % 1) * 7);
  const hours = clamp((days % 1) * 24);
  const minutes = clamp((hours % 1) * 60);

  return {
    weeks: Math.floor(weeks),
    days: Math.floor(days),
    hours: Math.floor(hours),
    minutes: Math.floor(minutes),
  };
}

export function getTotalDuration(durations: Array<ProgressiveRolloutConfigurationStep['duration']>) {
  const totalDurationInMilliseconds = durations.reduce(
    (total, duration) => total + durationToMilliseconds(duration),
    0,
  );

  return msToTotalDuration(totalDurationInMilliseconds);
}

/**
 * This will round a value if it is less than 0.0001 or greater than 0.9999.
 * This can be useful when performing operations on numbers that result in
 * very large or very small remainders, when we would except an integer.
 */
export const clamp = (value: number) => (value % 1 < 0.0001 || value % 1 > 0.9999 ? Math.round(value) : value);

function durationToMilliseconds(duration: ProgressiveRolloutConfigurationStep['duration']) {
  let multiplier: number | undefined;

  if (duration.unit === 'day') {
    multiplier = 24 * 60 * 60 * 1000;
  } else if (duration.unit === 'hour') {
    multiplier = 60 * 60 * 1000;
  } else if (duration.unit === 'minute') {
    multiplier = 60 * 1000;
  }

  if (!multiplier) {
    throw new Error(`Cannot convert duration to milliseconds. Invalid duration unit: "${duration.unit}"`);
  }

  return duration.quantity * multiplier;
}

export function convertProgressiveRolloutConfigurationToReleasePipelineInputRep(
  configuration: Pick<ProgressiveRolloutConfiguration, 'steps'>,
): schemas['ProgressiveRolloutAudienceConfigurationRep'] {
  return {
    stages: [
      ...configuration.steps.map((step) => ({
        rolloutWeight: step.rolloutWeight,
        durationMs: getProgressiveRolloutStepDurationAsMs(step.duration),
        displayUnit: step.duration.unit,
      })),
      { rolloutWeight: 100000 },
    ],
  };
}

export function convertPendingProgressiveRolloutToSemanticInstructionProgressiveRolloutConfiguration({
  originalFlag,
  draftFlag,
  selectedEnvironmentKey,
  pendingProgressiveRollout,
}: {
  originalFlag: Flag;
  draftFlag: Flag;
  selectedEnvironmentKey: string;
  pendingProgressiveRollout: PendingProgressiveRollout;
}): SemanticInstructionProgressiveRolloutConfiguration | undefined {
  const rule =
    pendingProgressiveRollout.configurationKey === 'fallthrough'
      ? draftFlag.getFallthrough(selectedEnvironmentKey)
      : draftFlag.getRuleById(selectedEnvironmentKey, pendingProgressiveRollout.configurationKey);

  /* When creating an approval request to start a progressive rollout,
  there is a brief moment when the "draftFlag" changes are reset before we redirect
  to the approval request details page.

  To handle that case and not cause unnecessary errors by enforcing invariants, we return early.
  */
  if (!rule) {
    return;
  }

  const { controlVariation, testVariation } = getControlAndTestVariations(
    originalFlag,
    draftFlag,
    selectedEnvironmentKey,
    rule,
  );
  const controlVariationId = controlVariation?._id;
  const endVariationId = testVariation?._id;

  if (controlVariationId && endVariationId) {
    return {
      controlVariationId,
      endVariationId,
      stages: convertProgressiveRolloutConfigurationToSemanticInstructionProgressiveRolloutConfigurationStages({
        controlVariationId,
        endVariationId,
        unusedVariationIds: originalFlag.variations
          .filter((v) => v._id !== controlVariationId && v._id !== endVariationId)
          .map((v) => v._id)
          .toArray(),
        configuration: pendingProgressiveRollout.configuration,
      }),
    };
  }
}

export function convertProgressiveRolloutConfigurationToSemanticInstructionProgressiveRolloutConfigurationStages({
  controlVariationId,
  endVariationId,
  unusedVariationIds,
  configuration,
}: {
  controlVariationId: string;
  endVariationId: string;
  unusedVariationIds: string[];
  configuration: ProgressiveRolloutConfiguration;
}): SemanticInstructionProgressiveRolloutConfiguration['stages'] {
  if (unusedVariationIds.includes(controlVariationId) || unusedVariationIds.includes(endVariationId)) {
    throw new Error('unusedVariationIds should not include controlVariationId or endVariationId');
  }

  const unusedRolloutWeights: { [variationId: string]: number } = {};
  for (const variationId of unusedVariationIds) {
    unusedRolloutWeights[variationId] = 0;
  }

  return [
    ...configuration.steps.map((step) => ({
      rollout: {
        ...unusedRolloutWeights,
        [endVariationId]: step.rolloutWeight,
        [controlVariationId]: 100000 - step.rolloutWeight,
      },
      durationMs: getProgressiveRolloutStepDurationAsMs(step.duration),
      displayUnit: step.duration.unit,
    })),
    {
      rollout: {
        ...unusedRolloutWeights,
        [endVariationId]: 100000,
        [controlVariationId]: 0,
      },
    },
  ];
}

export function getProgressiveRolloutTotalTimeRemainingMs(progressiveRollout: ProgressiveRolloutDetail) {
  const totalDurationMs = progressiveRollout._stages.reduce((total, s) => total + s.durationMs, 0);
  const estimatedFinishTime = progressiveRollout._createdAt + totalDurationMs;

  return Math.max(estimatedFinishTime - Date.now(), 60000);
}

export function getProgressiveRolloutStepDurationDisplayValue(
  duration: ProgressiveRolloutConfigurationStep['duration'],
  { format = 'long' }: { format?: 'long' | 'short' } = {},
) {
  const rawQuantity = clampTo3FractionDigits(duration.quantity);
  const quantity =
    duration.unit === 'day'
      ? Number(rawQuantity.toFixed(2)) // allow 2 fractional digits for days
      : duration.unit === 'hour'
        ? Number(rawQuantity.toFixed(1)) // allow 1 fractional digit for hours
        : Math.ceil(rawQuantity); // round up to the nearest integer for minutes

  return format === 'long' ? `${quantity} ${pluralize(duration.unit, quantity)}` : `${quantity}${duration.unit[0]}`;
}

export function getProgressiveRolloutConfigurationErrors(configuration: ProgressiveRolloutConfiguration) {
  const errors = progressiveRolloutConfigurationSchema.safeParse(configuration).error?.format();

  return {
    errors,
    isRolloutWeightInvalidForStep(stepIndex: number) {
      return !!errors?.steps?.[stepIndex]?.rolloutWeight;
    },
    isDurationQuantityInvalidForStep(stepIndex: number) {
      return !!errors?.steps?.[stepIndex]?.duration?.quantity;
    },
  };
}
