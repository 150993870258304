import { getUniqueRandomizationUnitsForMetrics, Metric } from '@gonfalon/metrics';

import { SelectMetric } from 'components/experimentation/common/components/SelectMetric';

import styles from './MeasuredRolloutMetricsForm.module.css';
interface MeasuredRolloutMetricsFormProps {
  metrics?: Metric[];
  isDisabled?: boolean;
  showHelpText?: boolean;
  labelText?: string;
  onMetricsChange: (metrics: Metric[]) => void;
}

export function MeasuredRolloutMetricsForm({
  metrics,
  labelText,
  isDisabled = false,
  showHelpText = true,
  onMetricsChange,
}: MeasuredRolloutMetricsFormProps) {
  const randomizationUnits = getUniqueRandomizationUnitsForMetrics(metrics);
  return (
    <>
      {showHelpText && <p className="u-fs-sm-even">Add metrics to monitor in guarded rollouts on this flag.</p>}
      <SelectMetric value={metrics} onChange={onMetricsChange} disabled={isDisabled} label={labelText} isMulti />
      {randomizationUnits.length > 0 && (
        <dl className={styles.definitionList}>
          <dt>Randomization units: </dt>
          {randomizationUnits.map((r) => (
            <dd key={r}>
              <code>{r}</code>
            </dd>
          ))}
        </dl>
      )}
    </>
  );
}
