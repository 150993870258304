import { exposeExperimentationErrors } from '@gonfalon/dogfood-flags';
import { SnackbarQueue } from '@launchpad-ui/components';

// uses fallback when exposeExperimentationErrors flag is off
// fallback can be removed with the exposeExperimentationErrors flag
export function requestErrorNotification(
  message: string,
  fallback: (error: Response) => Promise<void> = Promise.resolve,
) {
  return exposeExperimentationErrors()
    ? async (error?: Response) => {
        const errorMessage =
          error?.status === 500 ? 'internal server error' : await error?.json?.().then((body) => body?.message);
        const description = `${message}: ${errorMessage ?? 'unknown error'}`;
        return SnackbarQueue.error({ description });
      }
    : fallback;
}
