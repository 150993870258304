import { List, Map, OrderedMap } from 'immutable';

import {
  Branch,
  CodeStatisticsForFlag,
  createBranch,
  createCodeStatistics,
  createExtinction,
  createRepository,
  Extinction,
  Repository,
} from 'utils/codeRefs/codeRefsUtils';
import http, { jsonToImmutable, jsonToImmutableError } from 'utils/httpUtils';
import { createJsonPatch } from 'utils/patchUtils';

const basePath = '/api/v2/code-refs/';
const reposPath = `${basePath}repositories`;

async function getRepositoriesForFlag(projectKey: string, flagKey: string) {
  return http
    .get(`${reposPath}?projKey=${projectKey}&flagKey=${flagKey}&withBranches=1&withReferencesForDefaultBranch=1`)
    .then(jsonToImmutable)
    .then<OrderedMap<string, Repository>>((repos) =>
      repos.get('items').reduce((acc: OrderedMap<string, Repository>, curr: Repository) => {
        const currRepo = createRepository(curr);
        return acc.set(currRepo.id, currRepo);
      }, OrderedMap()),
    )
    .catch(jsonToImmutableError);
}

async function getRepositories() {
  return http
    .get(reposPath)
    .then(jsonToImmutable)
    .then<OrderedMap<string, Repository>>((repos) => repos.get('items').map(createRepository))
    .catch(jsonToImmutableError);
}

async function getRepositoryCount(projectKey: string, flagKey: string) {
  return http
    .get(`/api/v2/code-refs/statistics/${projectKey}${flagKey ? `?flagKey=${flagKey}` : ''}`, { beta: true })
    .then(jsonToImmutable)
    .then<OrderedMap<string, CodeStatisticsForFlag>>((stats) => stats.get('flags').map(createCodeStatistics))
    .catch(jsonToImmutableError);
}

async function getBranch(repoName: string, branchName: string, projectKey: string, flagKey: string) {
  return http
    .get(`${reposPath}/${repoName}/branches/${encodeURIComponent(branchName)}?projKey=${projectKey}&flagKey=${flagKey}`)
    .then(jsonToImmutable)
    .then<Branch>((branch) => createBranch(branch))
    .catch(jsonToImmutableError);
}

async function getExtinctions(repoName: string, branchName: string, projectKey: string, flagKey: string) {
  return http
    .get(
      `${basePath}extinctions?projKey=${projectKey}&flagKey=${flagKey}&repoName=${repoName}&branchName=${branchName}`,
    )
    .then(jsonToImmutable)
    .then<Map<string, Map<string, List<Extinction>>>>((extinctions) =>
      extinctions.get('items').map((branchExtinctions: Extinction[]) => branchExtinctions.map(createExtinction)),
    )
    .catch(jsonToImmutableError);
}

async function updateRepository(oldRepo: Repository, newRepo: Repository) {
  const patch = createJsonPatch(oldRepo.toJS(), newRepo.toJS());
  return http
    .patch(oldRepo.selfLink(), {
      body: patch,
    })
    .then(jsonToImmutable)
    .then<Repository>(createRepository)
    .catch(jsonToImmutableError);
}

async function deleteRepository(repo: Repository) {
  return http.delete(repo.selfLink()).catch(jsonToImmutableError);
}

export {
  getRepositories,
  getRepositoriesForFlag,
  getBranch,
  getExtinctions,
  updateRepository,
  deleteRepository,
  getRepositoryCount,
};
