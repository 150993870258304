// eslint-disable-next-line no-restricted-imports
import { fromJS } from 'immutable';
import { AnyAction } from 'redux';

import { ImmutableMap } from 'utils/immutableUtils';
import { makeFilter } from 'utils/stringUtils';

type Properties = {
  type: string;
  props: string[];
};

type TextFilterState = ImmutableMap<{
  text: string;
  predicate: () => boolean;
}>;

const initialState: TextFilterState = fromJS({
  text: '',
  predicate: () => true,
});

/* eslint-disable import/no-default-export */
export default function textFilter({ type, props }: Properties) {
  return (state = initialState, action: AnyAction): TextFilterState => {
    switch (action.type) {
      case type:
        const text = action.text;
        return state.set('text', text).set('predicate', makeFilter(text, ...props));
      default:
        return state;
    }
  };
}
