export enum AriaLabel {
  FILTER_USER_ATTRIBUTES = 'Filter user attributes',
  SEARCH_ACCESS_TOKENS = 'Search access tokens',
  SEARCH_APPLICATION_VERSIONS = 'Search application versions',
  SEARCH_AUDIT_LOG = 'Search audit log',
  SEARCH_CONFIGURATIONS = 'Search configurations',
  SEARCH_ENVIRONMENTS = 'Search environments',
  SEARCH_EVENTS = 'Search events',
  SEARCH_LEGACY_EXPERIMENTS = 'Search legacy experiments',
  SEARCH_INTEGRATIONS = 'Search integrations',
  SEARCH_MEMBERS = 'Search members',
  SEARCH_METRICS = 'Search metrics',
  SEARCH_PAYLOAD_FILTERS = 'Search payload filters',
  SEARCH_PROJECTS = 'Search projects',
  SEARCH_APPLICATIONS = 'Search applications',
  SEARCH_RESOURCES = 'Search resources',
  SEARCH_ROLES = 'Search roles',
  SEARCH_SEGMENTS = 'Search segments',
  SEARCH_TEAMS = 'Search teams',
  SEARCH_USERS = 'Search users',
}

/* eslint-disable import/no-default-export */
export default AriaLabel;
