// eslint-disable-next-line no-restricted-imports
import { fromJS, Map, OrderedMap } from 'immutable';
import { normalize, schema } from 'normalizr';

import { convertMapToOrderedMap } from 'utils/collectionUtils';
import http, { jsonToImmutable, jsonToImmutableError } from 'utils/httpUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';
import { createJsonPatch } from 'utils/patchUtils';
import { getRoleFetchingQueryString, RoleFetchingQueryStringProps } from 'utils/roleFilterWithPaginationUtils';
import { createRole, Role, RoleForm, roleIdentifier } from 'utils/roleUtils';

const roles = new schema.Entity('roles', {}, { idAttribute: roleIdentifier });

type NormalizedRoles = ImmutableMap<{
  result: ImmutableMap<{
    _links: { self: Link };
    items: string[];
  }>;
  entities: ImmutableMap<{
    roles: OrderedMap<string, Role>;
  }>;
}>;

async function getAllRoles(): Promise<NormalizedRoles> {
  return (
    http
      // send the request for the roles
      .get('/api/v2/roles', {
        // UI doesn't handle pagination yet
        // https://app.ld.catamorphic.com/default/staging/features/enable-custom-role-paging/targeting
        headers: { 'Ld-Api-Version': '20220603' },
      })
      .then(async (res) =>
        // then take the response and parse the JSON
        res.json().then((data) => {
          // normalize the data, then convert it into an Immutable Map
          const normalized: NormalizedRoles = fromJS(normalize(data, { items: [roles] }));
          return normalized.withMutations((map) => {
            // convert the Immutable Map into an Immutable OrderedMap
            map.updateIn(['entities', 'roles'], (normalizedRoles: Map<string, Role>) =>
              convertMapToOrderedMap(normalizedRoles, normalized.getIn(['result', 'items'])),
            );
            // turn the JS roles into Immutable Role records (objects)
            map.updateIn(['entities', 'roles'], (OrderedRoles: OrderedMap<string, Role>) =>
              OrderedRoles ? OrderedRoles.map(createRole) : OrderedMap(),
            );
          });
        }),
      )
      // catch any errors
      .catch(jsonToImmutableError)
  );
}

async function getFilteredRoles({
  limit = 20,
  offset,
  searchValue,
}: RoleFetchingQueryStringProps): Promise<NormalizedRoles> {
  const queryString = getRoleFetchingQueryString({ limit, offset, searchValue });
  return (
    http
      // send the request for the roles
      .get(`/api/v2/roles?${queryString}`)
      .then(async (res) =>
        // then take the response and parse the JSON
        res.json().then((data) => {
          // normalize the data, then convert it into an Immutable Map
          const normalized: NormalizedRoles = fromJS(normalize(data, { items: [roles] }));
          return normalized.withMutations((map) => {
            // convert the Immutable Map into an Immutable OrderedMap
            map.updateIn(['entities', 'roles'], (normalizedRoles: Map<string, Role>) =>
              convertMapToOrderedMap(normalizedRoles, normalized.getIn(['result', 'items'])),
            );
            // turn the JS roles into Immutable Role records (objects)
            map.updateIn(['entities', 'roles'], (OrderedRoles: OrderedMap<string, Role>) =>
              OrderedRoles ? OrderedRoles.map(createRole) : OrderedMap(),
            );
          });
        }),
      )
      // catch any errors
      .catch(jsonToImmutableError)
  );
}

async function getRole(roleKey: string): Promise<Role> {
  // We don't have a `getRole` API endpoint, so we get all roles that match the key passed in.
  // This will return all roles that match for both the name and the key; to be safe, we'll then
  // filter them here. We do rather than 'limit: 1' because the DB query is out of our control,
  // and if we have keys matching 'role1' and 'role10', and we query for `role1`, we might want
  // 'role1' but get 'role10'.
  const allMatchingRoles = await getFilteredRoles({ limit: -1, searchValue: roleKey });
  const role = allMatchingRoles.getIn(['entities', 'roles']).get(roleKey);
  return role;
}

async function postRole(role: RoleForm) {
  // currently, the only Role-related entity with a toRep function is RoleForm
  return http
    .post('/api/v2/roles', {
      body: role.toRep(),
    })
    .then(jsonToImmutable)
    .then(createRole)
    .catch(jsonToImmutableError);
}

async function updateRole(oldRole: RoleForm, newRole: RoleForm, options = { comment: '' }) {
  const { comment } = options;
  const patch = createJsonPatch(oldRole.toRep(), newRole.toRep(), { comment });

  return http
    .patch(oldRole.selfLink(), {
      body: patch,
    })
    .then(jsonToImmutable)
    .then(createRole)
    .catch(jsonToImmutableError);
}

async function deleteRole(role: Role) {
  return http.delete(role.selfLink()).catch(jsonToImmutableError);
}

export { getAllRoles, getFilteredRoles, getRole, postRole, updateRole, deleteRole };
