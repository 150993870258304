export function createFormatter({
  precision,
  asPercent,
  maxDigits = 6,
}: {
  precision: number;
  asPercent?: boolean;
  maxDigits?: number;
}) {
  /*
   * Implements https://launchdarkly.atlassian.net/wiki/spaces/PD/pages/2552071392/One+Pager+Automatic+Scaling+and+Formatting+of+Numbers+in+Tables
   *
   * For statistical estimates, we should format numbers with the number of
   * significant digits of the uncertainty of their estimates. This naturally
   * conveys the uncertainty to the user, and also generally ensures that
   * numbers of different orders of magnitude (12345.6789 and 0.000012345) are
   * formatted reasonably like 12,345 and 0.00012 instead of with an arbitrary
   * number of decimals (12,345.00 and 0.00).
   */
  // for percentages the precision needs to be adjusted to account for multiplying by 100.
  // this assumes that the precision values are for the fracitonal form, not the percentage form.
  const adjustedPrecision = asPercent ? precision + 2 : precision;
  const digits = Math.min(Math.max(-adjustedPrecision, 0), maxDigits);

  return (num: number) =>
    num.toLocaleString('en-US', {
      style: asPercent ? 'percent' : 'decimal',
      maximumFractionDigits: digits,
      minimumFractionDigits: digits,
    });
}
