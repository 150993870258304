export enum FlagPrerequisitesInstructionKind {
  ADD_PREREQUISITE = 'addPrerequisite',
  UPDATE_PREREQUISITE = 'updatePrerequisite',
  REMOVE_PREREQUISITE = 'removePrerequisite',
  REPLACE_PREREQUISITES = 'replacePrerequisites',
}

export type SemanticRemovePrerequisiteInstruction = {
  kind: FlagPrerequisitesInstructionKind.REMOVE_PREREQUISITE;
  key: string;
};

export type SemanticUpdatePrerequisiteInstruction = {
  kind: FlagPrerequisitesInstructionKind.UPDATE_PREREQUISITE;
  key: string;
  variationId: string;
};

export type SemanticAddPrerequisiteInstruction = {
  kind: FlagPrerequisitesInstructionKind.ADD_PREREQUISITE;
  key: string;
  variationId: string;
};

export type SemanticReplacePrerequisitesInstruction = {
  kind: FlagPrerequisitesInstructionKind.REPLACE_PREREQUISITES;
  key: string;
  prerequisites: Array<{ key: string; variationId: string }>;
};

export type SemanticPrerequisiteInstruction =
  | SemanticAddPrerequisiteInstruction
  | SemanticRemovePrerequisiteInstruction
  | SemanticUpdatePrerequisiteInstruction
  | SemanticReplacePrerequisitesInstruction;
