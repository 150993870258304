import { ReactElement, useCallback, useState } from 'react';
import { getUniqueRandomizationUnitsForMetrics, Metric } from '@gonfalon/metrics';
import { SnackbarQueue } from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';
import { Button, Chip, Popover } from 'launchpad';

import Divider from 'components/ui/divider';

import styles from './LegacyMeasuredRolloutMetricsPopover.module.css';

interface LegacyMeasuredRolloutMetricsPopoverProps {
  target: ReactElement;
  metrics: Metric[];
  isOpen: boolean;
  onInteraction: (isOpen: boolean) => void;
  onClose: () => void;
  onEdit: () => void;
  onRemove: () => Promise<void>;
}

export function LegacyMeasuredRolloutMetricsPopover({
  target,
  metrics,
  isOpen,
  onInteraction,
  onClose,
  onEdit,
  onRemove,
}: LegacyMeasuredRolloutMetricsPopoverProps) {
  const [isRemoving, setRemoving] = useState(false);
  const randomizationUnits = getUniqueRandomizationUnitsForMetrics(metrics);
  const [activeRandomizationUnit, setActiveRandomizationUnit] = useState<string | undefined>();
  const metricHasActiveRandomizationUnit = (metric: Metric) =>
    Boolean(metric.randomizationUnits?.includes(activeRandomizationUnit ?? ''));

  const handleRemove = useCallback(async () => {
    setRemoving(true);

    try {
      await onRemove();
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'There was a problem saving this configuration';
      SnackbarQueue.error({ description: errorMessage });
      // trigger a toast here or something
    } finally {
      setRemoving(false);
    }
  }, [onRemove]);

  return (
    <Popover
      interactionKind="click"
      placement="bottom-end"
      isOpen={isOpen}
      onInteraction={onInteraction}
      onClose={onClose}
    >
      {target}
      <div className={styles.popover}>
        <dl className={styles.definitionList}>
          <dt>Metrics</dt>
          <dd>
            {metrics.map((metric) => (
              <Chip
                key={metric.key}
                className={cx(styles.metricChip, {
                  [styles.nonActive]: activeRandomizationUnit && !metricHasActiveRandomizationUnit(metric),
                  [styles.activeMetric]: activeRandomizationUnit && metricHasActiveRandomizationUnit(metric),
                })}
              >
                <div title={metric.name} className={styles.metricText}>
                  {metric.name}
                </div>
              </Chip>
            ))}
          </dd>
          {randomizationUnits.length > 0 && (
            <>
              <dt>Randomization units:</dt>
              <div className={styles.randomizationList}>
                {randomizationUnits.map((r) => (
                  <dd key={r}>
                    <code
                      role="presentation"
                      className={cx({
                        [styles.nonActive]: activeRandomizationUnit && activeRandomizationUnit !== r,
                      })}
                      onMouseEnter={() => setActiveRandomizationUnit(r)}
                      onMouseLeave={() => setActiveRandomizationUnit(undefined)}
                    >
                      {r}
                    </code>
                  </dd>
                ))}
              </div>
            </>
          )}
        </dl>
        <Divider />
        <Button
          kind="minimal"
          className={styles.fullWidthButton}
          icon={<Icon name="edit" />}
          renderIconFirst
          onClick={onEdit}
        >
          Edit metrics
        </Button>
        <Button
          kind="minimal"
          className={cx(styles.fullWidthButton, styles.isDelete)}
          icon={<Icon name="delete" />}
          renderIconFirst
          onClick={handleRemove}
          isLoading={isRemoving}
          disabled={isRemoving}
        >
          Remove all metrics
        </Button>
      </div>
    </Popover>
  );
}
