import { accessesIncludesAction } from './accessesIncludesAction';
import { accessesStartsWith } from './accessesStartsWith';
import { titleStartsWith } from './titleStartsWith';
import { AuditLogEntryRep } from './types';

export function isDeletion(AuditLogEntry: AuditLogEntryRep) {
  if (accessesIncludesAction(AuditLogEntry, 'updateScheduledChanges') && titleStartsWith(AuditLogEntry, 'cancelled')) {
    return true;
  }
  if (accessesIncludesAction(AuditLogEntry, 'updateFeatureWorkflows') && titleStartsWith(AuditLogEntry, 'removed')) {
    return true;
  }
  if (
    accessesIncludesAction(AuditLogEntry, 'removeReleasePipeline') &&
    (titleStartsWith(AuditLogEntry, 'removed') || titleStartsWith(AuditLogEntry, 'canceled'))
  ) {
    return true;
  }
  return accessesStartsWith(AuditLogEntry, 'delete');
}
