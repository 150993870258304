import { Suspense } from 'react';
import { DialogTrigger } from 'react-aria-components';
import { IconButton, Popover } from '@launchpad-ui/components';

import { type SelectedEnvironment } from '../types';

import { EnvironmentFallback } from './EnvironmentFallback';
import { SelectEnvironmentDialog } from './SelectEnvironmentDialog';

export function ReplaceEnvironmentMenuTrigger({
  className,
  projectKey,
  excludedIds,
  onSelect,
}: {
  className?: string;
  projectKey: string;
  excludedIds: Set<string>;
  onSelect: (environment: SelectedEnvironment) => void;
}) {
  return (
    <DialogTrigger>
      <IconButton className={className} aria-label="Add environment" variant="minimal" size="small" icon="swap-horiz" />
      <Popover>
        <Suspense fallback={<EnvironmentFallback />}>
          <SelectEnvironmentDialog projectKey={projectKey} excludedIds={excludedIds} onSelect={onSelect} />
        </Suspense>
      </Popover>
    </DialogTrigger>
  );
}
