import { type AddRuleInstruction, type SegmentRuleClause, type SegmentSemanticInstruction } from '@gonfalon/openapi';

type RuleUpdates = {
  clauseToUpdate?: SegmentRuleClause;
  weight?: number;
  contextKind?: string;
  resetRollout?: boolean;
  description?: string;
  beforeRuleId?: string;
  beforeRuleKey?: string;
  resetBeforeRule?: boolean;
};
export function makeUpdatedInstructionsForNewRule(
  instructions: SegmentSemanticInstruction[],
  ruleIdToUpdate: string,
  updates: RuleUpdates,
) {
  const {
    clauseToUpdate,
    weight,
    contextKind,
    resetRollout,
    description,
    beforeRuleId,
    beforeRuleKey,
    resetBeforeRule,
  } = updates;
  const existingRuleInstructionIdx = instructions.findIndex(
    (instruction) => '_key' in instruction && instruction._key === ruleIdToUpdate,
  );

  if (existingRuleInstructionIdx !== -1) {
    const updatedRuleInstruction = instructions[existingRuleInstructionIdx] as AddRuleInstruction;

    if (clauseToUpdate) {
      const existingRuleClauseIdx = updatedRuleInstruction.clauses.findIndex(
        (clause) => clause._key === clauseToUpdate._key,
      );

      if (existingRuleClauseIdx !== -1) {
        updatedRuleInstruction.clauses.splice(existingRuleClauseIdx, 1, clauseToUpdate);
      } else {
        updatedRuleInstruction.clauses.push(clauseToUpdate);
      }
    }

    if (weight !== undefined) {
      updatedRuleInstruction.weight = weight;
    }

    if (contextKind !== undefined) {
      updatedRuleInstruction.rolloutContextKind = contextKind;
    }

    if (resetRollout) {
      updatedRuleInstruction.weight = undefined;
      updatedRuleInstruction.rolloutContextKind = undefined;
    }

    if (description !== undefined) {
      updatedRuleInstruction.description = description;
    }

    if (resetBeforeRule || beforeRuleId !== undefined) {
      updatedRuleInstruction.beforeRuleId = beforeRuleId;
    }

    if (beforeRuleKey !== undefined) {
      const insertionIndex = instructions.findIndex(
        (instruction) => '_key' in instruction && instruction._key === beforeRuleKey,
      );

      return instructions.toSpliced(existingRuleInstructionIdx, 1).toSpliced(insertionIndex, 0, updatedRuleInstruction);
    }

    return instructions.toSpliced(existingRuleInstructionIdx, 1, updatedRuleInstruction);
  }

  return instructions;
}

export function insertAddRuleInstruction(
  instructions: SegmentSemanticInstruction[],
  addRuleInstruction: AddRuleInstruction,
  beforeRuleKey?: string,
) {
  if (beforeRuleKey) {
    const insertionIndex = instructions.findIndex(
      (instruction) => '_key' in instruction && instruction._key === beforeRuleKey,
    );
    return instructions.toSpliced(insertionIndex, 0, addRuleInstruction);
  }

  return instructions.concat(addRuleInstruction);
}
