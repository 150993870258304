import { ContextTargetingExpirationUpdates } from 'reducers/expiringContextTargets';
import {
  ContextTargetingExpirationInstruction,
  getContextTargetingExpirationInstructionsForFlag,
  getContextTargetingExpirationInstructionsForKey,
} from 'utils/expiringContextTargetsUtils';
import { InstructionCategory, InstructionsType, SemanticInstruction } from 'utils/instructions/shared/types';
import Logger from 'utils/logUtils';

import { getInstructionsByManyKinds } from '../shared/helpers';

import {
  AddExpireTargetDateSemanticInstruction,
  AddExpireUserTargetDateSemanticInstruction,
  ExpiringTargetsInstructionKind,
  RemoveExpireTargetDateSemanticInstruction,
  RemoveExpireUserTargetDateSemanticInstruction,
  SemanticExpiringTargetInstruction,
  SemanticExpiringUserTargetInstruction,
  UpdateExpireTargetDateSemanticInstruction,
  UpdateExpireUserTargetDateSemanticInstruction,
  UserTargetingExpirationInstruction,
} from './types';

const logger = Logger.get('expiringTargets');

function stripUndefinedProps<T extends object>(props: T) {
  const result = { ...props };

  Object.keys(result).forEach((key) => {
    if (typeof result[key as keyof typeof result] === 'undefined') {
      delete result[key as keyof typeof result];
    }
  });

  return result;
}

export function makeAddExpiringTargetInstruction(props: {
  contextKind: string;
  contextKey: string;
  flagKey?: string;
  variationId?: string;
  value?: number;
  segmentKey?: string;
  targetType?: string;
  version?: number;
}): AddExpireTargetDateSemanticInstruction {
  return {
    ...stripUndefinedProps(props),
    kind: ExpiringTargetsInstructionKind.ADD_EXPIRE_TARGET_DATE,
  };
}

export function makeUpdateExpiringTargetInstruction(props: {
  contextKind: string;
  contextKey: string;
  flagKey?: string;
  variationId?: string;
  value?: number;
  segmentKey?: string;
  targetType?: string;
  version?: number;
}): UpdateExpireTargetDateSemanticInstruction {
  return {
    ...stripUndefinedProps(props),
    kind: ExpiringTargetsInstructionKind.UPDATE_EXPIRE_TARGET_DATE,
  };
}

export function makeRemoveExpiringTargetInstruction(props: {
  contextKind: string;
  contextKey: string;
  flagKey?: string;
  variationId?: string;
  value?: number;
  segmentKey?: string;
  targetType?: string;
  version?: number;
}): RemoveExpireTargetDateSemanticInstruction {
  return {
    ...stripUndefinedProps(props),
    kind: ExpiringTargetsInstructionKind.REMOVE_EXPIRE_TARGET_DATE,
  };
}

export function makeAddExpiringUserTargetInstruction(props: {
  userKey: string;
  flagKey?: string;
  variationId?: string;
  value?: number;
  segmentKey?: string;
  targetType?: string;
  version?: number;
}): AddExpireUserTargetDateSemanticInstruction {
  return {
    ...stripUndefinedProps(props),
    kind: ExpiringTargetsInstructionKind.ADD_EXPIRE_USER_TARGET_DATE,
  };
}

export function makeUpdateExpiringUserTargetInstruction(props: {
  userKey: string;
  flagKey?: string;
  variationId?: string;
  value?: number;
  segmentKey?: string;
  targetType?: string;
  version?: number;
}): UpdateExpireUserTargetDateSemanticInstruction {
  return {
    ...stripUndefinedProps(props),
    kind: ExpiringTargetsInstructionKind.UPDATE_EXPIRE_USER_TARGET_DATE,
  };
}

export function makeRemoveExpiringUserTargetInstruction(props: {
  userKey: string;
  flagKey?: string;
  variationId?: string;
  value?: number;
  segmentKey?: string;
  targetType?: string;
  version?: number;
}): RemoveExpireUserTargetDateSemanticInstruction {
  return {
    ...stripUndefinedProps(props),
    kind: ExpiringTargetsInstructionKind.REMOVE_EXPIRE_USER_TARGET_DATE,
  };
}

export function makeExpiringTargetSemanticInstruction(
  kind: ExpiringTargetsInstructionKind,
  ins: UserTargetingExpirationInstruction | ContextTargetingExpirationInstruction,
): SemanticExpiringUserTargetInstruction | SemanticExpiringTargetInstruction | undefined {
  switch (kind) {
    case ExpiringTargetsInstructionKind.ADD_EXPIRE_USER_TARGET_DATE:
      return makeAddExpiringUserTargetInstruction({
        userKey: (ins as UserTargetingExpirationInstruction).userKey,
        flagKey: ins.flagKey,
        value: ins.value,
        variationId: ins.variationId,
      });

    case ExpiringTargetsInstructionKind.UPDATE_EXPIRE_USER_TARGET_DATE:
      return makeUpdateExpiringUserTargetInstruction({
        userKey: (ins as UserTargetingExpirationInstruction).userKey,
        flagKey: ins.flagKey,
        value: ins.value,
        variationId: ins.variationId,
      });
    case ExpiringTargetsInstructionKind.REMOVE_EXPIRE_USER_TARGET_DATE:
      return makeRemoveExpiringUserTargetInstruction({
        userKey: (ins as UserTargetingExpirationInstruction).userKey,
        flagKey: ins.flagKey,
        variationId: ins.variationId,
      });
    case ExpiringTargetsInstructionKind.ADD_EXPIRE_TARGET_DATE:
      return makeAddExpiringTargetInstruction({
        contextKey: (ins as ContextTargetingExpirationInstruction).contextKey,
        contextKind: (ins as ContextTargetingExpirationInstruction).contextKind,
        flagKey: ins.flagKey,
        value: ins.value,
        variationId: ins.variationId,
      });
    case ExpiringTargetsInstructionKind.REMOVE_EXPIRE_TARGET_DATE:
      return makeRemoveExpiringTargetInstruction({
        contextKey: (ins as ContextTargetingExpirationInstruction).contextKey,
        contextKind: (ins as ContextTargetingExpirationInstruction).contextKind,
        flagKey: ins.flagKey,
        variationId: ins.variationId,
      });
    case ExpiringTargetsInstructionKind.UPDATE_EXPIRE_TARGET_DATE:
      return makeUpdateExpiringTargetInstruction({
        contextKey: (ins as ContextTargetingExpirationInstruction).contextKey,
        contextKind: (ins as ContextTargetingExpirationInstruction).contextKind,
        flagKey: ins.flagKey,
        value: ins.value,
        variationId: ins.variationId,
      });
    default:
      logger.warn(`Unable to create expiring target semantic instruction with kind ${kind}`);
      return;
  }
}

export const getExpiringTargetInstructions = (
  targetingExpirationUpdates: ContextTargetingExpirationUpdates,
  flagKey: string,
) =>
  getContextTargetingExpirationInstructionsForFlag(targetingExpirationUpdates, flagKey).flatMap((ins) => {
    const semanticInstruction = makeExpiringTargetSemanticInstruction(ins.kind, ins);
    return semanticInstruction ? [semanticInstruction] : [];
  });

export const getExpiringTargetInstructionsForKey = (
  targetingExpirationUpdates: ContextTargetingExpirationUpdates,
  key: string,
) =>
  getContextTargetingExpirationInstructionsForKey(targetingExpirationUpdates, key).flatMap((ins) => {
    const semanticInstruction = makeExpiringTargetSemanticInstruction(ins.kind, ins);
    return semanticInstruction ? [semanticInstruction] : [];
  });

export function combineExpiringTargets(
  newInstruction: SemanticExpiringUserTargetInstruction | SemanticExpiringTargetInstruction,
  pendingSemanticPatch: InstructionsType,
): InstructionsType {
  const instruction = newInstruction as SemanticExpiringTargetInstruction;
  return pendingSemanticPatch.set(
    [InstructionCategory.EXPIRE_TARGETS, instruction.contextKind, instruction.contextKey].join('|'),
    instruction,
  );
}

export const filterExpiringTargetInstructionsByTargetTypeOrVariationId = (
  instructions: Array<SemanticExpiringUserTargetInstruction | SemanticExpiringTargetInstruction>,
  targetTypeOrVariationId: string,
) =>
  instructions.filter(
    (instruction) =>
      instruction.variationId === targetTypeOrVariationId || instruction.targetType === targetTypeOrVariationId,
  );

export const filterSemanticInstructionsByExpiringTargetsInstructionKinds = (
  semanticInstructions: SemanticInstruction[],
) => getInstructionsByManyKinds(semanticInstructions, Object.values(ExpiringTargetsInstructionKind));
