// eslint-disable-next-line no-restricted-imports
import { useSelector } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { fromJS } from 'immutable';

import { ApprovalAction } from 'actions/approvals';
import { FlagAction } from 'actions/flags';
import { ScheduledChangesAction } from 'actions/scheduledChanges';
import { GlobalState } from 'reducers';
import { Flag } from 'utils/flagUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { addPatchInstructions, removePatchInstructions } from 'utils/instructions/shared/helpers';
import { InstructionsType, SemanticInstruction } from 'utils/instructions/shared/types';

import registry from './registry';

type SemanticPatch = ImmutableMap<{
  valid: boolean;
  instructions: InstructionsType;
  environmentKey: string | null;
}>;

type FlagManagerState = ImmutableMap<{
  original: Flag | null;
  modified: Flag | null;
  pendingSemanticPatch: SemanticPatch;
  isSaving: boolean;
  error: ImmutableServerError | null;
  isFlagUpdateComplete: boolean;
}>;

export const initialState: FlagManagerState = fromJS({
  original: null,
  modified: null,
  pendingSemanticPatch: {
    valid: true,
    instructions: {},
    environmentKey: null,
  },
  isSaving: false,
  error: null,
  isFlagUpdateComplete: false,
});

export function flagManager(
  state: FlagManagerState = initialState,
  action: FlagAction | ScheduledChangesAction | ApprovalAction,
) {
  function isSameFlag(flag: Flag) {
    const originalFlag = state.get('original');
    if (originalFlag) {
      return originalFlag.key === flag.key;
    }
    return false;
  }

  switch (action.type) {
    case 'flags/INITIALIZE_FLAG_CONFIG_MANAGER':
      return initialState.merge({
        original: action.flag,
        modified: action.flag,
      });
    case 'flags/DESTROY_FLAG_CONFIG_MANAGER':
      return initialState;
    case 'flags/CHANGE_FLAG_CONFIGURATION':
      return state.merge({
        modified: action.flag,
        pendingSemanticPatch: appendPendingSemanticPatch(
          state.get('pendingSemanticPatch'),
          action.instructions,
          action.environmentKey,
        ),
        error: null,
        isSaving: false,
      });
    case 'approvals/CREATE_APPROVAL_REQUEST':
      return state.merge({ isSaving: true, error: null });
    case 'scheduledChanges/CREATE_FLAG_SCHEDULED_CHANGE_DONE':
    case 'approvals/CREATE_APPROVAL_REQUEST_DONE':
    case 'flags/RESET_FLAG_CONFIGURATION':
      return initialState.merge({
        original: state.get('original'),
        modified: state.get('original'),
        isSaving: false,
      });
    case 'approvals/CREATE_APPROVAL_REQUEST_FAILED':
      return state.merge({
        error: action.error,
        isSaving: false,
      });
    case 'flags/UPDATE_FLAG':
      if (!isSameFlag(action.newFlag)) {
        return state;
      }
      return state.merge({
        isSaving: true,
        error: null,
      });
    case 'flags/UPDATE_FLAG_FAILED':
      if (!isSameFlag(action.flag)) {
        return state;
      }
      return state.merge({
        error: action.error,
        isSaving: false,
      });
    case 'flags/UPDATE_FLAG_DONE':
    case 'flags/UPDATE_RULE_EXCLUSION_DONE':
    case 'flags/UPDATE_FLAG_GOALS_DONE':
      if (!isSameFlag(action.flag)) {
        return state;
      }
      return initialState.merge({
        original: action.flag,
        modified: action.flag,
        isFlagUpdateComplete: true,
      });
    case 'flags/DISCARD_SPECIFIC_CONFIG_CHANGES':
      if (!isSameFlag(action.flag)) {
        return state;
      }

      let updatedSemanticPatch = removeSemanticPatchInstructions(
        state.get('pendingSemanticPatch'),
        action.removeInstructions,
        action.environmentKey,
      );

      if (action.appendInstructions) {
        updatedSemanticPatch = appendPendingSemanticPatch(
          updatedSemanticPatch,
          action.appendInstructions,
          action.environmentKey,
        );
      }

      return state.merge({
        modified: action.flag,
        pendingSemanticPatch: updatedSemanticPatch,
      });
    default:
      return state;
  }
}
registry.addReducers({ flagManager });

function appendPendingSemanticPatch(
  semanticPatch: SemanticPatch,
  instructions: Iterable<SemanticInstruction>,
  environmentKey: string,
) {
  return fromJS({
    valid: semanticPatch.get('valid') && !!instructions,
    instructions: addPatchInstructions(semanticPatch.get('instructions'), instructions),
    environmentKey: environmentKey || null,
  });
}

function removeSemanticPatchInstructions(
  semanticPatch: SemanticPatch,
  instructions: Iterable<SemanticInstruction>,
  environmentKey: string,
) {
  return fromJS({
    valid: semanticPatch.get('valid') && !!instructions,
    instructions: removePatchInstructions(semanticPatch.get('instructions'), instructions),
    environmentKey: environmentKey || null,
  });
}

export const flagManagerSelector = (state: GlobalState) => state.flagManager;

export function useFlagManager() {
  return useSelector(flagManagerSelector);
}

export function useFlagManagerOriginalState() {
  return useFlagManager().get('original');
}

export function usePendingSemanticPatchInstructions() {
  return useFlagManager().get('pendingSemanticPatch').get('instructions');
}
