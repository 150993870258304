import { toMemberPermissions } from '@gonfalon/navigator';
import { Link } from '@launchpad-ui/components';
import cx from 'clsx';

import { Member, MemberSummary } from 'utils/accountUtils';

import styles from './MemberProfileLink.module.css';

const MemberProfileLink = ({
  member,
  onClick,
  className,
}: {
  member: Member | MemberSummary;
  onClick?(): void;
  className?: string;
}) => (
  <Link
    className={cx(styles.link, className, 'fs-exclude')}
    href={toMemberPermissions({ memberId: member._id })}
    data-test-id="MemberProfileLink"
    onPress={onClick}
  >
    {member.getDisplayName()}
  </Link>
);

/* eslint-disable import/no-default-export */
export default MemberProfileLink;
