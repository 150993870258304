import { AccessToken } from 'utils/accessTokenUtils';
import { Environment } from 'utils/environmentUtils';
import { Flag } from 'utils/flagUtils';
import { Project } from 'utils/projectUtils';
import { RelayAutoConfig } from 'utils/relayAutoConfigUtils';
import { predicateFor } from 'utils/validationUtils';

import { Segment } from './segmentUtils';

export const isResourceConfirmationValid = ({ input, name, key }: { input: string; name?: string; key?: string }) => {
  const s = input.trim().toLowerCase();
  const trimmedName = name?.trim().toLowerCase();
  const trimmedKey = key?.trim().toLowerCase();
  return s === trimmedName || s === trimmedKey;
};

export type ValidationEntity =
  | Environment
  | Project
  | Flag
  | Segment
  | AccessToken
  | RelayAutoConfig
  | {
      name: string;
    };

export const validation = (type: string, entity: ValidationEntity, label?: string) => ({
  label: label || `Type the name or key of your ${type} to confirm`,
  placeholder: 'key' in entity ? entity.key : entity.name,
  onValidate: (input: string) =>
    isResourceConfirmationValid({ input, name: entity.name, key: 'key' in entity ? entity.key : undefined }),
});

export const environmentValidation = (environment: Environment) => {
  const label = 'Type the environment name or key to confirm';
  return validation('environment', environment, label);
};

export const flagValidation = (flag: Flag) => {
  const label = 'Type the flag name or key to confirm';
  return validation('flag', flag, label);
};

export const segmentValidation = (segment: Segment) => {
  const label = 'Type the segment name or key to confirm';
  return validation('segment', segment, label);
};

export const accessTokenValidation = (accessToken: AccessToken) => {
  const label = 'Type the name of the access token to confirm';
  return validation('access token', accessToken, label);
};

export const revokeValidation = () => ({
  label: 'Type yes to confirm',
  onValidate: (input: string) => input.toLowerCase() === 'yes',
});

export const racValidation = (rac: RelayAutoConfig) => {
  const label = 'Type the name of the configuration to confirm';
  return validation('configuration', rac, label);
};

export const isValidResourceConfirmation = (key?: string, name?: string) =>
  predicateFor(
    (input: string) => !isResourceConfirmationValid({ input, key, name }),
    () => 'The key entered is incorrect',
  );
