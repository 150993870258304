export enum FilterOperator {
  ARE_ALL_OF = 'are all of',
  IS = 'is',
  OVER = 'over',
}

export enum FlagListQueryParam {
  CREATION_DATE = 'creationDate',
  STATUS = 'status',
  TAG = 'tag',
  TARGETING = 'targeting',
  TYPE = 'type',
  CONTEXT_KIND_TARGETED = 'contextKindTargeted',
  CONTEXT_KINDS_EVALUATED = 'contextKindsEvaluated',
  SEGMENT_TARGETED = 'segmentTargeted',
  APPLICATION_EVALUATED = 'applicationEvaluated',
  STATE = 'state',
}

export enum FlagListBackendQueryParam {
  CREATION_DATE = 'creationDate',
  STATUS = 'status',
  TAGS = 'tags',
  TARGETING = 'targeting',
  TYPE = 'type',
  CONTEXT_KIND_TARGETED = 'contextKindTargeted',
  CONTEXT_KINDS_EVALUATED = 'contextKindsEvaluated',
  SEGMENT_TARGETED = 'segmentTargeted',
  APPLICATION_EVALUATED = 'applicationEvaluated',
  STATE = 'state',
}
