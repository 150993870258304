import { useMeasuredRollouts } from '@gonfalon/rest-api';

export function useLatestMeasuredRollout({
  projectKey,
  flagKey,
  environmentKey,
  enabled = true,
}: {
  projectKey: string;
  flagKey: string;
  environmentKey: string;
  enabled?: boolean;
}) {
  const {
    data: measuredRollouts,
    isPending,
    isFetching,
  } = useMeasuredRollouts({ projectKey, flagKey, params: { filter: { environmentKey } } }, { enabled });

  const latestMeasuredRollout = measuredRollouts
    ? [...measuredRollouts.items].sort((a, b) => b.design.createdAt - a.design.createdAt)[0]
    : undefined;

  return {
    data: latestMeasuredRollout,
    isPending,
    isFetching,
  };
}
