import { type GetFeatureFlagsQueryParams } from '@gonfalon/openapi';

import { defaultFlagFilter } from './internal/defaultFlagFilter';
import { creationDateToReferenceDate } from './creationDateToReferenceDate';
import { evaluationDateToReferenceDate } from './evaluationDateToReferenceDate';
import { type UIFlagFilter } from './types';

export function parseUIFlagFilterFromAPI(api: NonNullable<GetFeatureFlagsQueryParams['filter']>) {
  const ui: UIFlagFilter = structuredClone(defaultFlagFilter);

  if (api.query) {
    ui.query = api.query;
  }

  if (api.hasDataExport) {
    ui.hasDataExport = api.hasDataExport;
  }

  if (api.hasExperiment) {
    ui.hasExperiment = api.hasExperiment;
  }

  if (api.maintainerId) {
    ui.maintainerId = api.maintainerId;
  }

  if (api.maintainerTeamKey) {
    ui.maintainerTeamKey = api.maintainerTeamKey;
  }

  if (api.tags && api.tags.length > 0) {
    ui.tags = api.tags;
  }

  if (api.type) {
    ui.type = api.type;
  }

  if (api.sdkAvailability) {
    ui.sdkAvailability = api.sdkAvailability;
  }

  if (api.filterEnv) {
    ui.filterEnv = api.filterEnv;
  }

  if (api.status) {
    ui.status = api.status;
  }

  if (api.state || api.staleStates) {
    ui.state = api.state || api.staleStates;
  }

  if (api.targeting) {
    ui.targeting = api.targeting;
  }

  if (api.contextKindTargeted) {
    ui.contextKindTargeted = api.contextKindTargeted;
  }

  if (api.contextKindsEvaluated && api.contextKindsEvaluated.length > 0) {
    ui.contextKindsEvaluated = api.contextKindsEvaluated;
  }

  if (api.followerId) {
    ui.followerId = api.followerId;
  }

  if (api.applicationEvaluated) {
    ui.applicationEvaluated = api.applicationEvaluated;
  }

  if (api.segmentTargeted) {
    ui.segmentTargeted = api.segmentTargeted;
  }

  if (api.codeReferences) {
    if (api.codeReferences.min !== undefined && api.codeReferences.min > 0) {
      ui.codeReferences = true;
    } else {
      ui.codeReferences = false;
    }
  }

  const now = Date.now();

  if (api.evaluated) {
    ui.evaluated = evaluationDateToReferenceDate(new Date(api.evaluated.after).getTime(), now);
  }

  if (api.creationDate) {
    ui.created = creationDateToReferenceDate(new Date(api.creationDate.before).getTime(), now);
  }

  return ui;
}
