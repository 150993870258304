import { useCallback } from 'react';

import { terminalEvents } from './terminalEvents';
import { useActiveMeasuredRollout } from './useActiveMeasuredRollout';

export function useMeasuredRolloutActive({
  enabled = true,
  projectKey,
  flagKey,
  environmentKey,
}: {
  enabled?: boolean;
  projectKey: string;
  flagKey: string;
  environmentKey: string;
}) {
  const {
    data: latestMeasuredRollout,
    isPending,
    isFetching,
  } = useActiveMeasuredRollout({
    enabled,
    projectKey,
    flagKey,
    environmentKey,
  });

  const measuredRolloutActive = Boolean(
    latestMeasuredRollout && !terminalEvents.includes(latestMeasuredRollout?.state.status),
  );

  const measuredRolloutRuleId = latestMeasuredRollout?.design.ruleIdOrFallthrough;

  const isMeasuredRolloutActive = useCallback(() => {
    if (!enabled) {
      return false;
    }

    if (isPending) {
      return false;
    }

    return measuredRolloutActive;
  }, [enabled, isPending, measuredRolloutActive]);

  const isMeasuredRolloutActiveForRule = useCallback(
    (ruleId: string) => isMeasuredRolloutActive() && measuredRolloutRuleId === ruleId,
    [isMeasuredRolloutActive, measuredRolloutRuleId],
  );

  const isMeasuredRolloutInactive = useCallback(() => {
    if (!enabled) {
      return true;
    }

    if (isFetching) {
      return false;
    }

    return !measuredRolloutActive;
  }, [enabled, isFetching, measuredRolloutActive]);

  const isMeasuredRolloutInactiveForRule = useCallback(
    (ruleId: string) =>
      isMeasuredRolloutInactive() || Boolean(!isFetching && measuredRolloutRuleId && measuredRolloutRuleId !== ruleId),
    [isMeasuredRolloutInactive, measuredRolloutRuleId, isFetching],
  );

  return {
    isMeasuredRolloutActive,
    isMeasuredRolloutActiveForRule,
    isMeasuredRolloutInactive,
    isMeasuredRolloutInactiveForRule,
  };
}
