import { parseUIContextViewFromAPI } from '@gonfalon/context-kinds';
import { parseUIFlagListViewFromAPI } from '@gonfalon/flag-filters';
import { ShortcutsPostRep } from '@gonfalon/rest-api';
import { parseUISegmentViewFromAPI } from '@gonfalon/segments';

import { Shortcut } from '../types';

export const transformShortcutForUI = (shortcut: ShortcutsPostRep): Shortcut => {
  let filters;
  switch (shortcut.type) {
    case 'flags':
      if (shortcut.filters) {
        filters = parseUIFlagListViewFromAPI(shortcut.filters);
      }
      break;
    case 'contexts':
      if (shortcut.filters) {
        filters = parseUIContextViewFromAPI(shortcut.filters);
      }
      break;
    case 'segments':
      if (shortcut.filters) {
        filters = parseUISegmentViewFromAPI(shortcut.filters);
      }
      break;

    default:
  }
  return {
    ...shortcut,
    filters,
    context: {
      ...shortcut.context,
      environmentKeys: new Set(shortcut.context.environmentKeys),
    },
  } as Shortcut;
};
