import Logger from './logUtils';

const logger = Logger.get('comparisonUtils');

/**
 * Performs equality by iterating through keys on an object and returning false
 * when any key has values which are not strictly equal between the arguments.
 * Returns true when the values of all keys are strictly equal.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function shallowEqual<P extends { [key: string]: any }>(
  objA: P | null,
  objB: P | null,
  options: { debug?: boolean } = {},
) {
  if (objA === objB) {
    return true;
  }
  const shouldPrintDebug = process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test' && options.debug;

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    shouldPrintDebug && logger.log('one of the objects is not actually an object', objA, objB);
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    shouldPrintDebug && logger.log('Object keys are different length', objA, objB);
    return false;
  }

  // Test for A's keys different from B.
  const bHasOwnProperty = Object.prototype.hasOwnProperty.bind(objB);
  for (const key of keysA) {
    if (!bHasOwnProperty(key) || objA[key] !== objB[key]) {
      shouldPrintDebug &&
        logger.log('Object property is not equal:', key, objA[key], bHasOwnProperty(key) ? objB[key] : undefined);
      return false;
    }
  }
  return true;
}
