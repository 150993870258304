import { sumBy } from '@gonfalon/es6-utils';
import { List, Map } from 'immutable';
import nullthrows from 'nullthrows';

import {
  createRollout,
  ExperimentRollout,
  Flag,
  idOrKey,
  MeasuredRolloutConfig,
  Rule as ImmutableRule,
  Variation,
} from 'utils/flagUtils';
import { toJS } from 'utils/immutableUtils';
import { isInstructionOfKind } from 'utils/instructions/shared/helpers';
import {
  AllFlagInstructionKinds,
  InstructionCategory,
  InstructionsType,
  SemanticInstruction,
  SemanticInstructionProgressiveRolloutConfiguration,
} from 'utils/instructions/shared/types';

import {
  combineAddClausesInstruction,
  combineClauseValuesInstruction,
  combineRemoveClausesInstruction,
  combineUpdateClauseInstruction,
  makeClauseValuesKey,
  makeUpdateClauseKey,
} from '../clauses/helpers';
import { ClauseInstructionKind } from '../clauses/types';

import {
  AddRuleSemanticInstruction,
  AddRuleWithMeasuredRolloutSemanticInstruction,
  AddRuleWithMeasuredRolloutV2SemanticInstruction,
  AddRuleWithProgressiveRolloutSemanticInstruction,
  AggregateRule,
  convertRolloutToSemanticInstructionRollout,
  RemoveRuleSemanticInstruction,
  ReorderRulesSemanticInstruction,
  ReplaceRulesSemanticInstruction,
  RuleInstructionKind,
  SemanticInstructionRollout,
  SemanticRuleDescriptionInstruction,
  SemanticRuleInstructionType,
  StopMeasuredRolloutOnRule,
  UpdateRuleVariationOrRolloutSemanticInstruction,
  UpdateRuleWithMeasuredRolloutSemanticInstruction,
  UpdateRuleWithMeasuredRolloutV2SemanticInstruction,
  UpdateRuleWithProgressiveRolloutSemanticInstruction,
} from './types';

export function getRuleEditingInstructions(allInstructions: List<SemanticInstruction>) {
  return allInstructions.filter((ins) =>
    [
      RuleInstructionKind.ADD_RULE,
      ClauseInstructionKind.ADD_CLAUSES,
      ClauseInstructionKind.UPDATE_CLAUSE,
      RuleInstructionKind.UPDATE_RULE_DESCRIPTION,
      RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT,
    ].some((k: AllFlagInstructionKinds) => isInstructionOfKind(ins, k)),
  );
}

export function makeAddRuleInstruction(rule: ImmutableRule): AddRuleSemanticInstruction {
  const ruleAsJS = toJS(rule);
  return {
    kind: RuleInstructionKind.ADD_RULE,
    ref: '',
    ...ruleAsJS,
    clauses: rule.clauses.toArray(),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ruleId: ruleAsJS._key!,
  };
}

export function makeAddRuleWithMeasuredRolloutInstruction(
  rule: ImmutableRule,
  testVariationId: string,
  controlVariationId: string,
  config: MeasuredRolloutConfig,
): AddRuleWithMeasuredRolloutSemanticInstruction {
  const ruleAsJS = toJS(rule);
  return {
    kind: RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT,
    ref: '',
    ...ruleAsJS,
    clauses: rule.clauses.toArray(),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ruleId: ruleAsJS._key!,
    testVariationId,
    controlVariationId,
    ...config.toRep(),
  };
}

export function makeAddRuleWithMeasuredRolloutV2Instruction(
  rule: ImmutableRule,
  testVariationId: string,
  controlVariationId: string,
  config: MeasuredRolloutConfig,
): AddRuleWithMeasuredRolloutV2SemanticInstruction {
  const ruleAsJS = toJS(rule);
  return {
    kind: RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT_V2,
    ref: '',
    ...ruleAsJS,
    clauses: rule.clauses.toArray(),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ruleId: ruleAsJS._key!,
    testVariationId,
    controlVariationId,
    ...config.toRep(),
  };
}

export function makeAddRuleWithProgressiveRolloutInstruction({
  rule,
  beforeRuleId,
  progressiveRolloutConfiguration,
  rolloutContextKind,
}: {
  rule: ImmutableRule;
  beforeRuleId?: string;
  progressiveRolloutConfiguration: SemanticInstructionProgressiveRolloutConfiguration;
  rolloutContextKind: string;
}): AddRuleWithProgressiveRolloutSemanticInstruction {
  return {
    kind: RuleInstructionKind.ADD_RULE,
    description: rule.description,
    clauses: rule.clauses.toArray(),
    trackEvents: rule.trackEvents,
    beforeRuleId,
    ruleId: nullthrows(rule._key, 'expected rule _key property'),
    ref: nullthrows(rule.ref, 'expected rule ref property'),
    progressiveRolloutConfiguration,
    rolloutContextKind,
  };
}

export function makeRemoveRuleInstruction(ruleId: string): RemoveRuleSemanticInstruction {
  return {
    kind: RuleInstructionKind.REMOVE_RULE,
    ruleId,
  };
}

export function makeUpdateRuleVariationInstruction(
  ruleId: string,
  variationId: string,
): UpdateRuleVariationOrRolloutSemanticInstruction {
  return { kind: RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT, ruleId, variationId };
}

export function makeStopMeasuredRolloutOnRuleInstruction(
  ruleId: string,
  finalVariationId: string,
  comment: string,
): StopMeasuredRolloutOnRule {
  return { kind: RuleInstructionKind.STOP_MEASURED_ROLLOUT_ON_RULE, ruleId, finalVariationId, comment };
}

export function makeUpdateRuleRolloutInstruction(
  ruleIdOrRef: string,
  rollout: SemanticInstructionRollout,
  isPending: boolean = false,
): UpdateRuleVariationOrRolloutSemanticInstruction {
  const { bucketBy, contextKind, experimentAllocation, weights } = rollout;

  return {
    kind: RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT,
    [isPending ? 'ref' : 'ruleId']: ruleIdOrRef,
    rolloutWeights: weights,
    rolloutBucketBy: bucketBy,
    rolloutContextKind: contextKind,
    experimentAllocation,
  };
}

export function makeUpdateRuleWithMeasuredRolloutInstruction(
  ruleId: string,
  testVariationId: string,
  controlVariationId: string,
  config: MeasuredRolloutConfig,
): UpdateRuleWithMeasuredRolloutSemanticInstruction {
  return {
    kind: RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT,
    ruleId,
    testVariationId,
    controlVariationId,
    ...config.toRep(),
  };
}

export function makeUpdateRuleWithMeasuredRolloutV2Instruction(
  ruleId: string,
  testVariationId: string,
  controlVariationId: string,
  config: MeasuredRolloutConfig,
): UpdateRuleWithMeasuredRolloutV2SemanticInstruction {
  return {
    kind: RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT_V2,
    ruleId,
    testVariationId,
    controlVariationId,
    ...config.toRep(),
  };
}

export function makeUpdateRuleWithProgressiveRolloutInstruction({
  ruleId,
  progressiveRolloutConfiguration,
  rolloutContextKind,
}: { ruleId: string } & Pick<
  UpdateRuleWithProgressiveRolloutSemanticInstruction,
  'progressiveRolloutConfiguration' | 'rolloutContextKind'
>): UpdateRuleWithProgressiveRolloutSemanticInstruction {
  return {
    kind: RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT,
    ruleId,
    progressiveRolloutConfiguration,
    rolloutContextKind,
  };
}

export function makeReorderRulesInstruction(ruleIds: string[]): ReorderRulesSemanticInstruction {
  return {
    kind: RuleInstructionKind.REORDER_RULES,
    ruleIds,
  };
}

export function makeReplaceRulesInstruction(rules: AggregateRule[]): ReplaceRulesSemanticInstruction {
  return {
    kind: RuleInstructionKind.REPLACE_RULES,
    rules,
  };
}

export function makeUpdateRuleDescription(ruleId: string, description: string): SemanticRuleDescriptionInstruction {
  return {
    ruleId,
    description,
    kind: RuleInstructionKind.UPDATE_RULE_DESCRIPTION,
  };
}

// sortRulesInstructions takes all of the pending rules instructions that we
// have and makes sure they're sorted such that any reordering the user has
// done is respected.  The way it works is that the "reorder rules" instruction
// that we have pending includes IDs of all the rules on the final flag, in the
// final order, including rules that are pending to be added. This function
// separates that into a reorder rules instruction just containing the IDs of
// existing rules which executes first, and then a sorted list of add rule
// instructions that have "beforeRuleId" set such that they get inserted into
// the correct place in the final order.
// visible for test
export function sortRulesInstructions(
  rulesInstructions: List<SemanticInstruction> | undefined,
): List<SemanticInstruction> {
  if (!rulesInstructions) {
    return List();
  }
  const orderInstruction = rulesInstructions.find(
    (i) => i.kind === RuleInstructionKind.REORDER_RULES,
  ) as ReorderRulesSemanticInstruction;
  if (!orderInstruction) {
    // There was no reordering, so sort order doesn't matter.
    return rulesInstructions;
  }

  // Separate out add rule instructions since we need to make sure that they are
  // sent in the right order such that the rules are created in the right position.
  const addRulesInstructions = rulesInstructions.filter(
    (i) => i.kind === RuleInstructionKind.ADD_RULE || i.kind === RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT,
  ) as List<AddRuleSemanticInstruction>;

  // Separate out remove rule instructions since they need to happen before the reorder, as their
  // IDs will not be included in the reorder instruction.
  const removeRulesInstructions = rulesInstructions.filter((i) => i.kind === RuleInstructionKind.REMOVE_RULE);

  // make a unique list of ruleIds belonging to reordered rule and ruleIds belonging to newly added rules
  const addedRuleIds = addRulesInstructions.map((r) => r.ruleId);
  const orderedRuleIds = orderInstruction.ruleIds;
  const ruleOrder = orderedRuleIds.concat(addedRuleIds.toArray().filter((r) => !orderedRuleIds.includes(r)));
  // Keep track of the next existing rule ID so that we can set it on the add rule instructions
  // to make sure we add the rule at the correct position.
  let anchorRuleId = '';
  let sortedAddRuleInstructions = List<SemanticInstruction>();
  const newRuleOrder = [];
  for (const ruleId of ruleOrder.reverse()) {
    if (removeRulesInstructions.find((i) => i.ruleId === ruleId)) {
      // Don't include a rule in the order if we're removing it.
      continue;
    }
    const addRuleInstruction = addRulesInstructions.find((i) => idOrKey(i) === ruleId);
    if (!addRuleInstruction) {
      // If there's no add rule instruction corresponding to this ID, it's an existing rule.
      // It becomes the new "beforeRuleId" since we're iterating in reverse, and we add it to the
      // final "reorder rules" instruction.
      anchorRuleId = ruleId;
      newRuleOrder.unshift(ruleId);
    } else {
      // If there is an add rule instruction, set the beforeRuleId if we have one (otherwise we're
      // just adding the rule at the end) and add it to the sorted array. Make a copy first so we don't
      // affect the pending instruction, just the one we're returning.
      let newAddRuleInstruction = addRuleInstruction;
      if (anchorRuleId) {
        newAddRuleInstruction = { ...addRuleInstruction, beforeRuleId: anchorRuleId };
      }
      sortedAddRuleInstructions = sortedAddRuleInstructions.unshift(newAddRuleInstruction);
    }
  }
  // Don't create a reorder rules instruction if it would be empty
  const newReorderInstruction = [];
  if (newRuleOrder.length) {
    newReorderInstruction.push(makeReorderRulesInstruction(newRuleOrder));
  }

  // Just copy over the rest of the instructions, since order doesn't matter for them.
  const rest = rulesInstructions.filter(
    (i) =>
      ![
        RuleInstructionKind.REORDER_RULES,
        RuleInstructionKind.ADD_RULE,
        RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT,
        RuleInstructionKind.REMOVE_RULE,
      ].includes(i.kind as RuleInstructionKind.ADD_RULE),
  );
  return List([...removeRulesInstructions, ...newReorderInstruction, ...sortedAddRuleInstructions, ...rest]);
}

export function combineRulesInstructions(
  newInstruction: SemanticRuleInstructionType,
  pendingSemanticPatch: InstructionsType,
  replaceId?: string,
): InstructionsType {
  let ruleId: string;
  let existingAddRule;
  let existingAddRuleWithMeasuredRollout;
  switch (newInstruction.kind) {
    case RuleInstructionKind.ADD_RULE:
      ruleId = newInstruction.ruleId || newInstruction.ref;
      const ruleKey = makeUpdateRuleKey(ruleId, RuleInstructionKind.ADD_RULE);
      return pendingSemanticPatch
        .delete(makeUpdateRuleKey(ruleId, RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT))
        .set(ruleKey, newInstruction);
    case RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT:
      return pendingSemanticPatch
        .delete(makeUpdateRuleKey(newInstruction.ruleId, RuleInstructionKind.ADD_RULE))
        .set(
          makeUpdateRuleKey(newInstruction.ruleId, RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT),
          newInstruction,
        );

    case RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT_V2:
      return pendingSemanticPatch
        .delete(makeUpdateRuleKey(newInstruction.ruleId, RuleInstructionKind.ADD_RULE))
        .set(
          makeUpdateRuleKey(newInstruction.ruleId, RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT_V2),
          newInstruction,
        );
    case ClauseInstructionKind.ADD_CLAUSES:
      return combineAddClausesInstruction(newInstruction, pendingSemanticPatch);
    case ClauseInstructionKind.REMOVE_CLAUSES:
      return combineRemoveClausesInstruction(newInstruction, pendingSemanticPatch, replaceId);
    case ClauseInstructionKind.UPDATE_CLAUSE:
      return combineUpdateClauseInstruction(newInstruction, pendingSemanticPatch);
    case ClauseInstructionKind.ADD_VALUES_TO_CLAUSE:
      return combineClauseValuesInstruction(newInstruction, pendingSemanticPatch);
    case ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE:
      return combineClauseValuesInstruction(newInstruction, pendingSemanticPatch);
    case RuleInstructionKind.STOP_MEASURED_ROLLOUT_ON_RULE:
      return pendingSemanticPatch.set(
        makeUpdateRuleKey(newInstruction.ruleId, RuleInstructionKind.STOP_MEASURED_ROLLOUT_ON_RULE),
        newInstruction,
      );
    case RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT: {
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      ruleId = newInstruction.ruleId!; /* eslint-enable @typescript-eslint/no-non-null-assertion */
      // check if there's already a pending add rule for this rule
      existingAddRule = getExistingAddRuleInstruction(ruleId, pendingSemanticPatch);
      existingAddRuleWithMeasuredRollout = getExistingAddRuleWithMeasuredRolloutInstruction(
        ruleId,
        pendingSemanticPatch,
      );
      const commonAddRuleProperties = existingAddRule
        ? getCommonAddRuleProperties(existingAddRule)
        : existingAddRuleWithMeasuredRollout
          ? getCommonAddRuleProperties(existingAddRuleWithMeasuredRollout)
          : undefined;

      if (commonAddRuleProperties) {
        // Updating the variation or rollout on a rule that's pending being added.
        const newAddRule = {
          ...commonAddRuleProperties,
          kind: RuleInstructionKind.ADD_RULE as const,
          variationId: newInstruction.variationId,
          rolloutWeights: newInstruction.rolloutWeights,
          rolloutBucketBy: newInstruction.rolloutBucketBy,
          rolloutContextKind: newInstruction.rolloutContextKind,
          experimentAllocation: newInstruction.experimentAllocation,
        };
        return pendingSemanticPatch
          .delete(makeUpdateRuleKey(ruleId, RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT))
          .delete(makeUpdateRuleKey(ruleId, RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT_V2))
          .set(makeUpdateRuleKey(ruleId, RuleInstructionKind.ADD_RULE), newAddRule);
      }

      // Updating the variation on an existing rule.
      return pendingSemanticPatch
        .delete(makeUpdateRuleKey(ruleId, RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT))
        .delete(makeUpdateRuleKey(ruleId, RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT_V2))
        .set(makeUpdateRuleKey(ruleId, RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT), newInstruction);
    }

    case RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT_V2: {
      ruleId = newInstruction.ruleId;
      existingAddRule = getExistingAddRuleInstruction(ruleId, pendingSemanticPatch);
      existingAddRuleWithMeasuredRollout = getExistingAddRuleWithMeasuredRolloutInstruction(
        ruleId,
        pendingSemanticPatch,
      );
      const commonAddRuleProperties = existingAddRule
        ? getCommonAddRuleProperties(existingAddRule)
        : existingAddRuleWithMeasuredRollout
          ? getCommonAddRuleProperties(existingAddRuleWithMeasuredRollout)
          : undefined;
      if (commonAddRuleProperties) {
        // Updating the variation or rollout on a rule that's pending being added.
        const newAddRuleWithMeasuredRolloutV2 = {
          ...commonAddRuleProperties,
          ...newInstruction,
          kind: RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT_V2 as const,
        };
        return pendingSemanticPatch
          .delete(makeUpdateRuleKey(newInstruction.ruleId, RuleInstructionKind.ADD_RULE))
          .delete(makeUpdateRuleKey(newInstruction.ruleId, RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT))
          .delete(makeUpdateRuleKey(newInstruction.ruleId, RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT_V2))
          .set(
            makeUpdateRuleKey(newInstruction.ruleId, RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT_V2),
            newAddRuleWithMeasuredRolloutV2,
          );
      }

      return pendingSemanticPatch
        .delete(makeUpdateRuleKey(newInstruction.ruleId, RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT))
        .delete(makeUpdateRuleKey(newInstruction.ruleId, RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT_V2))
        .set(
          makeUpdateRuleKey(newInstruction.ruleId, RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT_V2),
          newInstruction,
        );
    }

    case RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT: {
      ruleId = newInstruction.ruleId;
      existingAddRule = getExistingAddRuleInstruction(ruleId, pendingSemanticPatch);
      existingAddRuleWithMeasuredRollout = getExistingAddRuleWithMeasuredRolloutInstruction(
        ruleId,
        pendingSemanticPatch,
      );
      const commonAddRuleProperties = existingAddRule
        ? getCommonAddRuleProperties(existingAddRule)
        : existingAddRuleWithMeasuredRollout
          ? getCommonAddRuleProperties(existingAddRuleWithMeasuredRollout)
          : undefined;
      if (commonAddRuleProperties) {
        // Updating the variation or rollout on a rule that's pending being added.
        const newAddRuleWithMeasuredRollout = {
          ...commonAddRuleProperties,
          ...newInstruction,
          kind: RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT as const,
        };
        return pendingSemanticPatch
          .delete(makeUpdateRuleKey(newInstruction.ruleId, RuleInstructionKind.ADD_RULE))
          .set(
            makeUpdateRuleKey(newInstruction.ruleId, RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT),
            newAddRuleWithMeasuredRollout,
          );
      }

      return pendingSemanticPatch
        .delete(makeUpdateRuleKey(newInstruction.ruleId, RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT))
        .set(
          makeUpdateRuleKey(newInstruction.ruleId, RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT),
          newInstruction,
        );
    }
    case RuleInstructionKind.REMOVE_RULE:
      ruleId = newInstruction.ruleId;
      existingAddRule = getExistingAddRuleInstruction(ruleId, pendingSemanticPatch);
      existingAddRuleWithMeasuredRollout = getExistingAddRuleWithMeasuredRolloutInstruction(
        ruleId,
        pendingSemanticPatch,
      );

      // Remove all pending instructions that have to do with the rule we're going to remove.
      let result = pendingSemanticPatch.filter(
        (_, key) => !key.startsWith([InstructionCategory.RULES, ruleId].join('|')),
      );
      const reorderRulesKey = makeReorderRulesKey();
      const reorderRulesIns = result.get(reorderRulesKey) as ReorderRulesSemanticInstruction | undefined;
      if (reorderRulesIns && reorderRulesIns.ruleIds.includes(ruleId)) {
        const reorderRulesWithoutDeletedRule: ReorderRulesSemanticInstruction = {
          ...reorderRulesIns,
          ruleIds: reorderRulesIns.ruleIds.filter((r) => r !== ruleId),
        };

        result =
          reorderRulesWithoutDeletedRule.ruleIds.length > 1
            ? result.set(reorderRulesKey, reorderRulesWithoutDeletedRule)
            : result.delete(reorderRulesKey);
      }

      if (replaceId) {
        //this is for editing scheduled changes. We want to replace a rule id from the dropdown.
        const updatedResult = result.delete(makeUpdateRuleKey(replaceId, RuleInstructionKind.REMOVE_RULE));
        return updatedResult.set(makeUpdateRuleKey(ruleId, RuleInstructionKind.REMOVE_RULE), newInstruction);
      }
      if (existingAddRule) {
        // Removing a rule that's pending being added (so just remove the pending
        // instruction that would add it).
        return result.delete(makeUpdateRuleKey(ruleId, RuleInstructionKind.ADD_RULE));
      } else if (existingAddRuleWithMeasuredRollout) {
        // Removing a rule that's pending being added (so just remove the pending
        // instruction that would add it).
        return result.delete(makeUpdateRuleKey(ruleId, RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT));
      } else {
        // Removing an existing rule.
        return result.set(makeUpdateRuleKey(ruleId, RuleInstructionKind.REMOVE_RULE), newInstruction);
      }
    case RuleInstructionKind.REORDER_RULES:
      return pendingSemanticPatch.set(makeReorderRulesKey(), newInstruction);
    case RuleInstructionKind.UPDATE_RULE_DESCRIPTION:
      ruleId = newInstruction.ruleId;
      existingAddRule = getExistingAddRuleInstruction(ruleId, pendingSemanticPatch);
      existingAddRuleWithMeasuredRollout = getExistingAddRuleWithMeasuredRolloutInstruction(
        ruleId,
        pendingSemanticPatch,
      );
      if (existingAddRule) {
        // Updating the description on a rule that's pending being added.
        existingAddRule = { ...existingAddRule, description: newInstruction.description };
        return pendingSemanticPatch.set(makeUpdateRuleKey(ruleId, RuleInstructionKind.ADD_RULE), existingAddRule);
      }
      if (existingAddRuleWithMeasuredRollout) {
        // Updating the description on a rule that's pending being added.
        existingAddRuleWithMeasuredRollout = {
          ...existingAddRuleWithMeasuredRollout,
          description: newInstruction.description,
        };
        return pendingSemanticPatch.set(
          makeUpdateRuleKey(ruleId, RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT),
          existingAddRuleWithMeasuredRollout,
        );
      }
      return pendingSemanticPatch.set(
        makeUpdateRuleKey(ruleId, RuleInstructionKind.UPDATE_RULE_DESCRIPTION),
        newInstruction,
      );
    default:
      // Unrecognized instruction, we should never be here.
      return pendingSemanticPatch;
  }
}

export function makeRuleKey(instruction: SemanticRuleInstructionType) {
  if (instruction.kind === ClauseInstructionKind.UPDATE_CLAUSE) {
    const ruleId = instruction.ruleId;
    const clauseId = instruction.clauseId;
    return makeUpdateClauseKey(ruleId, clauseId);
  }
  if (instruction.kind === RuleInstructionKind.REORDER_RULES) {
    return makeReorderRulesKey();
  }
  if (
    instruction.kind === ClauseInstructionKind.ADD_VALUES_TO_CLAUSE ||
    instruction.kind === ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE
  ) {
    return makeClauseValuesKey(instruction.ruleId, instruction.clauseId, instruction.kind);
  }
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  return makeUpdateRuleKey(
    instruction.ruleId!,
    instruction.kind,
  ); /* eslint-enable @typescript-eslint/no-non-null-assertion */
}

export function makeUpdateRuleKey(ruleId: string, instructionKind: AllFlagInstructionKinds) {
  return [InstructionCategory.RULES, ruleId, instructionKind].join('|');
}

export function makeReorderRulesKey(): string {
  return [InstructionCategory.RULES, RuleInstructionKind.REORDER_RULES].join('|');
}

export function getExistingAddRuleInstruction(
  ruleId: string,
  pendingSemanticPatch: InstructionsType,
): AddRuleSemanticInstruction | undefined {
  const ruleKey = makeUpdateRuleKey(ruleId, RuleInstructionKind.ADD_RULE);
  return pendingSemanticPatch.get(ruleKey) as AddRuleSemanticInstruction;
}

export function getExistingAddRuleWithMeasuredRolloutInstruction(
  ruleId: string,
  pendingSemanticPatch: InstructionsType,
): AddRuleWithMeasuredRolloutSemanticInstruction | undefined {
  const instructionTypes = [
    RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT,
    RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT_V2,
  ];

  return instructionTypes
    .map((type) => pendingSemanticPatch.get(makeUpdateRuleKey(ruleId, type)))
    .find((instruction) => instruction !== undefined) as AddRuleWithMeasuredRolloutSemanticInstruction | undefined;
}

function getCommonAddRuleProperties(
  instruction: AddRuleSemanticInstruction | AddRuleWithMeasuredRolloutSemanticInstruction,
) {
  const { _id, _key, clauses, clause, trackEvents, description, clauseId, ruleId, beforeRuleId, ref } = instruction;
  return { _id, _key, clauses, clause, trackEvents, description, clauseId, ruleId, beforeRuleId, ref };
}

export const getRollout = (
  flag: Flag,
  rw?: Map<string, number>,
  bucketBy?: string,
  experimentData?: ExperimentRollout,
  contextKind?: string,
) => {
  let pendingRolloutWeights = List();
  let hasRolloutError = false;
  if (rw) {
    pendingRolloutWeights = flag.variations.map((d: Variation, i: number) => ({
      variation: i,
      weight: rw.get(d._id),
    }));
    //we allow experiment rollouts to have weights less than 100000
    hasRolloutError = sumBy(pendingRolloutWeights.toJS(), (v) => v.weight) !== 100000 && !experimentData;
  }
  let rollout = pendingRolloutWeights.size
    ? createRollout({ variations: pendingRolloutWeights, bucketBy, contextKind })
    : undefined;
  if (experimentData) {
    rollout = createRollout({ experimentAllocation: experimentData, variations: pendingRolloutWeights, bucketBy });
  }
  return { rollout, hasRolloutError, pendingRolloutWeights };
};

export function convertRuleToAddRuleSemanticInstruction(flag: Flag, rule: ImmutableRule): AddRuleSemanticInstruction {
  let addRuleInstruction = makeAddRuleInstruction(rule);

  const variations = flag.getVariations();
  if (rule.variation !== undefined) {
    const ruleVariation = variations.get(rule.variation);
    addRuleInstruction = { ...addRuleInstruction, variationId: ruleVariation?._id };
  } else if (rule.rollout) {
    const ruleRollout = convertRolloutToSemanticInstructionRollout(variations, rule.rollout);
    addRuleInstruction = {
      ...addRuleInstruction,
      rolloutWeights: ruleRollout.weights,
      rolloutBucketBy: ruleRollout.bucketBy,
      rolloutContextKind: ruleRollout.contextKind,
      experimentAllocation: ruleRollout.experimentAllocation,
    };
  }

  return addRuleInstruction;
}
