import { useCallback } from 'react';
import { Metric, useMetricsByKeys } from '@gonfalon/metrics';
import { useFlagMeasuredRolloutConfiguration, useUpdateFlagMeasuredRolloutConfiguration } from '@gonfalon/rest-api';
import { SnackbarQueue, ToastQueue } from '@launchpad-ui/components';

export function useMeasuredRolloutMetrics(projectKey: string, flagKey: string, onSuccess?: () => void) {
  const { mutateAsync: putConfig } = useUpdateFlagMeasuredRolloutConfiguration();

  const { data: flagMeasuredRolloutConfiguration } = useFlagMeasuredRolloutConfiguration({
    projectKey,
    flagKey,
  });

  const metricKeys = flagMeasuredRolloutConfiguration?.metrics?.map((metric) => metric.key) ?? [];
  const { metrics, isPending, isFetching } = useMetricsByKeys(metricKeys);

  const handleRemove = useCallback(
    async () =>
      putConfig(
        { projectKey, flagKey, body: { metricKeys: [] } },
        {
          onSuccess: () => {
            ToastQueue.success('Configuration removed');
          },
          onError() {
            SnackbarQueue.error({ description: 'Failed to delete configuration.' });
          },
        },
      ),
    [putConfig, projectKey, flagKey],
  );

  const handleSave = useCallback(
    async (data: { metrics: Metric[] }) => {
      await putConfig(
        {
          projectKey,
          flagKey,
          body: {
            metricKeys: data.metrics.map((metric) => metric.key) ?? [],
          },
        },
        {
          onSuccess: () => {
            ToastQueue.success('Configuration added');
            onSuccess?.();
          },
          onError() {
            SnackbarQueue.error({ description: 'Failed to create configuration.' });
          },
        },
      );
    },
    [putConfig, projectKey, flagKey],
  );

  return {
    metrics,
    isPending,
    isFetching,
    handleSave,
    handleRemove,
  };
}
