import { VariationType } from 'utils/flagUtils';

import { VariationValue } from './variationInstructionListItemUtils';

export type AddVariationInstructionEntryProps = {
  variationType: VariationType;
  name?: string;
  description?: string;
  value: string;
};

export function AddVariationInstructionEntry({
  variationType,
  value,
  name,
  description,
}: AddVariationInstructionEntryProps) {
  const labelFlexBasis = description ? '6.25rem' : '3.75rem';

  return (
    <div className="VariationInstructionEntry">
      <ul className="ChangeList">
        {name ? (
          <li className="ChangeEntry">
            <span className="ChangeEntry-label" style={{ flexBasis: labelFlexBasis }}>
              Name:
            </span>
            <span className="MetadataValue">{name}</span>
          </li>
        ) : null}
        {description ? (
          <li className="ChangeEntry">
            <span className="ChangeEntry-label" style={{ flexBasis: labelFlexBasis }}>
              Description:
            </span>
            <span className="MetadataValue">{description}</span>
          </li>
        ) : null}
        <li className="ChangeEntry">
          <span className="ChangeEntry-label" style={{ flexBasis: labelFlexBasis }}>
            Value:
          </span>
          <span>
            <VariationValue value={value} variationType={variationType} />
          </span>
        </li>
      </ul>
    </div>
  );
}
