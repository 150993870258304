import { isImmutable } from 'immutable';
import { Alert, AlertProps } from 'launchpad';

import type { ImmutableServerError } from 'utils/httpUtils';

type StatusError = {
  [key: string]: string;
};

type Error = {
  message?: string;
  status?: string;
};

type ErrorAlertProps = AlertProps & {
  error?: ImmutableServerError | object | null;
};

const statusErrors: StatusError = {};

const unexpectedError = 'An unexpected error has occurred. Please try again later.';

const formatError = (error?: ImmutableServerError | object | null) => {
  if (!error) {
    return unexpectedError;
  }

  const err: Error = isImmutable(error) ? error.toJS() : error;

  if (err.message) {
    return err.message;
  } else if (err.status && statusErrors[err.status]) {
    return statusErrors[err as string];
  } else {
    return unexpectedError;
  }
};

export function ErrorAlert(props: ErrorAlertProps) {
  const { error, ...rest } = props;

  return (
    <Alert {...rest} kind="error">
      {formatError(error)}
    </Alert>
  );
}
