import { createContext, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useProjectKey } from '@gonfalon/router';
import { ProgressBar } from '@launchpad-ui/components';
import invariant from 'tiny-invariant';

import { fetchProject as fetchProjectAction } from 'actions/projects';
import { projectEntitiesSelector } from 'reducers/projects';
import { Project } from 'utils/projectUtils';
import { ready } from 'utils/reduxUtils';

export const useRedux = () => {
  const projKey = useProjectKey();
  const dispatch = useDispatch();
  const fetchProject = () => dispatch(fetchProjectAction(projKey));
  const project = useSelector(projectEntitiesSelector).get(projKey);
  const isReady = ready(project);

  return {
    project,
    isReady,
    fetchProject,
  };
};

const ManageProjectContext = createContext<{ project: Project } | undefined>(undefined);

export function ManageProjectContextProvider({ children }: { children: React.ReactNode }) {
  const deps = useRedux();

  useEffect(() => {
    deps.fetchProject();
  }, [deps.isReady]);

  if (!deps.project) {
    return <ProgressBar aria-label="Loading…" isIndeterminate />;
  }

  return <ManageProjectContext.Provider value={{ project: deps.project }}>{children}</ManageProjectContext.Provider>;
}

export function useManageProjectContext() {
  const context = useContext(ManageProjectContext);
  invariant(context, 'useManageProjectContext must be used within a ManageProjectProvider');
  return context;
}
