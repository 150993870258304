import { createTrackerForCategory } from '@gonfalon/analytics';
import { approvalsNotificationListMaxLimit, isApprovalByTeamEnabled } from '@gonfalon/dogfood-flags';
import { DateFormat } from '@gonfalon/format';
import { toFlagPendingTargetingChanges, toFlagTargeting, toFlagTargetingConflict, toHref } from '@gonfalon/navigator';
// eslint-disable-next-line no-restricted-imports
import { fromJS, getIn, List, Record } from 'immutable';

import { IntegrationConfig } from 'components/PendingChanges/ApprovalIntegrationFields';
import { useProfileEntity } from 'reducers/profile';
import { useCurrentEnvironmentKey } from 'reducers/projects';
import { Member } from 'utils/accountUtils';
import { isValidResourceConfirmation } from 'utils/confirmationUtils';
import {
  isChecked,
  isFalse,
  isLength,
  isNotEmpty,
  isNotZero,
  isValidNotifyMembersList,
  isValidNotifyReviewersCount,
  validateRecord,
} from 'utils/validationUtils';

import { RuleInstructionKind } from './instructions/rules/types';
import { makeSemanticInstruction } from './instructions/shared/helpers';
import { SemanticInstruction } from './instructions/shared/types';
import { ApprovalRequest, ApprovalRequestConflict } from './approvalsUtils';
import { lastUsedTzid, toFormattedString } from './dateUtils';
import { Flag } from './flagUtils';
import { CreateFunctionInput } from './immutableUtils';
import { ScheduledChange } from './scheduledChangesUtils';
import { Team } from './teamsUtils';
import { WorkflowSemanticInstruction } from './workflowUtils';

const MINIMUM_DESCRIPTION_CHAR = 1;
const MAXIMUM_DESCRIPTION_CHAR = 5000;

export const trackPendingChangesEvent = createTrackerForCategory('Pending Changes');

export enum ApprovalOption {
  APPLY_CHANGES_WITHOUT_APPROVAL = 'APPLY_CHANGES_WITHOUT_APPROVAL',
  REQUEST_APPROVAL = 'REQUEST_APPROVAL',
}

export enum WorkflowNotificationKind {
  REDIRECT = 'redirect',
  LINK_WORKFLOW = 'link-workflow',
  LINK_PENDING_CHANGES_DRAWER = 'link-pending-changes-drawer',
}

export enum WorkflowKind {
  DEFAULT = 'default',
  APPROVAL_REQUEST = 'approval-request',
  SCHEDULED_CHANGE = 'scheduled-change',
  CUSTOM = 'custom',
}

type NestedWorkflowProps = {
  instructions: List<WorkflowSemanticInstruction>;
  notFoundText: string;
  executionDate: number;
  title: string;
};

export class NestedWorkflow extends Record<NestedWorkflowProps>({
  instructions: List(),
  notFoundText: '',
  title: '',
  executionDate: 0,
}) {}

export const createNestedWorkflow = (props: CreateFunctionInput<NestedWorkflowProps> = {}) => {
  const nestedWorkflow = props instanceof NestedWorkflow ? props : new NestedWorkflow(fromJS(props));
  return nestedWorkflow.update('instructions', (vs) =>
    vs.size > 0 ? vs.map((v) => makeSemanticInstruction(getIn(v, ['kind'], ''), v)) : List(),
  );
};

export const DELETE_SCHEDULED_CHANGE_TITLE = 'Delete scheduled change';

export function getScheduledChangeDeletedNotFoundText(appliedDate: number) {
  return `Scheduled change was deleted on ${toFormattedString(
    appliedDate,
    lastUsedTzid(),
    DateFormat.MM_DD_YYYY_H_MM_A,
  )}.`;
}

export const createOperationOnWorkflowFromScheduledChange = (
  scheduledFlagChange: ScheduledChange | undefined,
  approvalRequest: ApprovalRequest,
) => {
  //once we add different workflows, we can refactor this to be more generic instead of only focused on deleting scheduled changes
  let nestedWorkflow;
  const title = DELETE_SCHEDULED_CHANGE_TITLE;
  if (scheduledFlagChange) {
    nestedWorkflow = createNestedWorkflow({
      instructions: scheduledFlagChange.instructions,
      executionDate: scheduledFlagChange.executionDate,
      title,
    });
  }
  if (!scheduledFlagChange) {
    const notFoundReason: ApprovalRequestConflict | undefined = approvalRequest.conflicts.first();
    nestedWorkflow = createNestedWorkflow({
      notFoundText:
        notFoundReason?.get('reason') ||
        (approvalRequest.appliedDate
          ? getScheduledChangeDeletedNotFoundText(approvalRequest.appliedDate)
          : 'Scheduled change not found.'),
      title,
    });
  }
  return nestedWorkflow;
};

export type PendingChangesFormValueType = string | number | Array<Member | Team> | boolean | IntegrationConfig;

export type PendingChangesFormRecordProps = {
  isInitialized: boolean;
  isScheduleSelected: boolean;
  isApprovalSelected: boolean;
  isApplyWithoutApprovalSelected: boolean;
  isInviteMemberSelected: boolean;
  areReviewersRequired: boolean;
  isCommentRequired: boolean;
  isConfirmationRequired: boolean;
  resourceKey: string;
  resourceName: string;
  isAcknowledgeConflictsRequired: boolean;
  isLoadingConflicts: boolean;
  executionDate: number;
  description: string;
  notifyMembers: Member[];
  notifyTeams: Team[];
  reviewers: Array<Member | Team>;
  acknowledgeConflicts: boolean;
  resourceConfirmation: string;
  comment: string;
  integrationConfig: IntegrationConfig;
};

export class PendingChangesFormRecord extends Record<PendingChangesFormRecordProps>({
  isInitialized: false,
  isScheduleSelected: false,
  isApprovalSelected: false,
  isInviteMemberSelected: false,
  isApplyWithoutApprovalSelected: false,
  areReviewersRequired: false,
  isCommentRequired: false,
  isConfirmationRequired: false,
  resourceKey: '',
  resourceName: '',
  isAcknowledgeConflictsRequired: false,
  isLoadingConflicts: false,
  executionDate: 0,
  description: '',
  notifyMembers: [],
  notifyTeams: [],
  reviewers: [],
  acknowledgeConflicts: false,
  resourceConfirmation: '',
  comment: '',
  integrationConfig: {},
}) {
  validate() {
    const predicates = [];
    if (this.isScheduleSelected) {
      predicates.push(isNotZero('executionDate'));
    }
    if (this.isApprovalSelected) {
      predicates.push(isNotEmpty('description'));
      predicates.push(isLength(MINIMUM_DESCRIPTION_CHAR, MAXIMUM_DESCRIPTION_CHAR)('description'));
      if (this.areReviewersRequired) {
        if (isApprovalByTeamEnabled()) {
          predicates.push(isNotEmpty('reviewers'));
          predicates.push(
            isValidNotifyReviewersCount(
              approvalsNotificationListMaxLimit(),
              this.notifyTeams,
              this.notifyMembers,
            )('reviewers'),
          );
        } else {
          predicates.push(isNotEmpty('notifyMembers'));
          predicates.push(isValidNotifyMembersList(approvalsNotificationListMaxLimit())('notifyMembers'));
        }
      }
    } else {
      if (this.isAcknowledgeConflictsRequired) {
        predicates.push(isChecked('acknowledgeConflicts'));
      }
      if (this.isCommentRequired) {
        predicates.push(isNotEmpty('comment'));
      }
      if (this.isConfirmationRequired) {
        predicates.push(isNotEmpty('resourceConfirmation'));
        predicates.push(isValidResourceConfirmation(this.resourceKey, this.resourceName)('resourceConfirmation'));
      }
    }
    predicates.push(isFalse('isLoadingConflicts'));

    return validateRecord(this, ...predicates);
  }
}

export function createPendingChangesForm(
  props: Partial<PendingChangesFormRecordProps> | PendingChangesFormRecord = {},
) {
  return props instanceof PendingChangesFormRecord ? props : new PendingChangesFormRecord(fromJS(props));
}

export function getReviewerType(members: Member[]) {
  if (members.every((m) => m.canReview)) {
    return 'Reviewer';
  } else if (members.every((m) => !m.canReview)) {
    return 'Viewer';
  }
  return 'Both';
}

export function useCheckAccessForFlagInCurrentEnvironment(flag: Flag) {
  const envKey = useCurrentEnvironmentKey();
  const profile = useProfileEntity();
  return flag.checkAccess({ envKey, profile });
}

export function useApprovalsAccessDecisionForFlagInCurrentEnvironment(flag: Flag) {
  const checkAccess = useCheckAccessForFlagInCurrentEnvironment(flag);
  return checkAccess('createApprovalRequest');
}

export function useUpdateScheduledChangesAccessForFlagInCurrentEnvironment(flag: Flag) {
  const checkAccess = useCheckAccessForFlagInCurrentEnvironment(flag);
  return checkAccess('updateScheduledChanges');
}

export function usePendingChangesAccessDecisions(flag: Flag) {
  const checkAccess = useCheckAccessForFlagInCurrentEnvironment(flag);
  return {
    createApprovalRequest: checkAccess('createApprovalRequest'),
    updateScheduledChanges: checkAccess('updateScheduledChanges'),
  } as const;
}

export const getNavigationPromptAllowList = (projKey: string, envKey: string, flagKey: string) =>
  new Set([
    toHref(toFlagPendingTargetingChanges({ environmentKey: envKey, flagKey, projectKey: projKey })),
    toHref(toFlagTargeting({ environmentKey: envKey, flagKey, projectKey: projKey })),
    toHref(toFlagTargetingConflict({ environmentKey: envKey, flagKey, projectKey: projKey, conflictIndex: '*' })),
  ]);

export enum MigrationErrorIssue {
  RULE_ADDED = 'ruleAdded',
  RULE_REMOVED = 'ruleRemoved',
  EDIT_NEW_READ_RULE = 'editNewReadRule',
  EDIT_OLD_ONLY_READ_RULE = 'editOldOnlyReadRule',
  REORDER_RULE_UP = 'reorderRuleUp',
  REORDER_RULE_DOWN = 'reorderRuleDown',
  BACKFILL_REQUIRED = 'backfillRequired',
  DUALWRITE_SKIPPED = 'dualwriteSkipped',
}

export type MigrationErrorResponse = {
  causingRuleId: string;
  affectedRuleIds: string[];
  issue: MigrationErrorIssue;
  oldSystemAffected?: boolean;
};

const migrationActions: { [k: string]: string } = {
  ruleAdded: 'Adding cohort',
  ruleRemoved: 'Removing cohort',
  editNewReadRule: 'Editing cohort',
  editOldOnlyReadRule: 'Editing cohort',
  reorderRuleUp: 'Reordering cohort',
  reorderRuleDown: 'Reordering cohort',
};

const getMigrationErrorMessageContent = (
  issue: MigrationErrorIssue,
  causingRuleName: string,
  affectedRuleNames: string[],
  oldSystemAffected?: boolean,
) => {
  const stringAffectedRuleNames = affectedRuleNames.join(', ');
  switch (issue) {
    case MigrationErrorIssue.RULE_ADDED:
      if (oldSystemAffected) {
        return (
          <>
            <b>{causingRuleName}</b> may cause traffic from <b>{stringAffectedRuleNames}</b> to go to{' '}
            <b>{causingRuleName}</b>, which may read from the old system after the migration has completed for that
            traffic.
          </>
        );
      }
      return (
        <>
          <b>{causingRuleName}</b> may cause traffic from <b>{stringAffectedRuleNames}</b> to go to{' '}
          <b>{causingRuleName}</b>, which may read from the new system before the data is consistent with the old
          system.
        </>
      );
    case MigrationErrorIssue.RULE_REMOVED:
      return (
        <>
          <b>{causingRuleName}</b> may cause traffic to enter <b>{stringAffectedRuleNames}</b>.
        </>
      );
    case MigrationErrorIssue.EDIT_NEW_READ_RULE:
      return (
        <>
          <b>{causingRuleName}</b> may cause traffic from <b>{stringAffectedRuleNames}</b> to go to{' '}
          <b>{causingRuleName}</b>.
        </>
      );
    case MigrationErrorIssue.EDIT_OLD_ONLY_READ_RULE:
      return (
        <>
          <b>{causingRuleName}</b>, may cause traffic to enter <b>{stringAffectedRuleNames}</b>.
        </>
      );
    case MigrationErrorIssue.REORDER_RULE_UP:
      return (
        <>
          <b>{causingRuleName}</b> may cause traffic from <b>{stringAffectedRuleNames}</b> to go to{' '}
          <b>{causingRuleName}</b>.
        </>
      );
    case MigrationErrorIssue.REORDER_RULE_DOWN:
      return (
        <>
          <b>{causingRuleName}</b> may cause traffic to enter <b>{stringAffectedRuleNames}</b>.
        </>
      );
    case MigrationErrorIssue.BACKFILL_REQUIRED:
      if (oldSystemAffected) {
        return (
          <>
            <b>{stringAffectedRuleNames}</b> will have new traffic reading from the old system. Ensure that you have
            backfilled the old system.
          </>
        );
      }
      return (
        <>
          <b>{stringAffectedRuleNames}</b> will have new traffic reading from the new system. Ensure that you have
          backfilled the new system.
        </>
      );
    case MigrationErrorIssue.DUALWRITE_SKIPPED:
      return (
        <>
          <b>{stringAffectedRuleNames}</b> will have new traffic reading from the new system before writes have occurred
          in that system.
        </>
      );
    default:
      return null;
  }
};

export const migrationTrafficDescription: { [k: string]: string } = {
  true: 'This traffic may read from the old system after the migration has completed for that traffic.',
  false: 'This traffic may read from the new system before data is consistent with the old system.',
};

export const getReadableMigrationErrors = (
  errorResponse: MigrationErrorResponse,
  causingRuleName: string,
  affectedRuleNames: string[],
  index: number,
) => {
  const isTrafficReadingError =
    errorResponse.issue === MigrationErrorIssue.BACKFILL_REQUIRED ||
    errorResponse.issue === MigrationErrorIssue.DUALWRITE_SKIPPED;
  const prefix = isTrafficReadingError ? '' : migrationActions[errorResponse.issue];
  const content = getMigrationErrorMessageContent(
    errorResponse.issue,
    causingRuleName,
    affectedRuleNames,
    errorResponse.oldSystemAffected,
  );
  const postfix =
    errorResponse.issue === MigrationErrorIssue.RULE_ADDED
      ? ''
      : migrationTrafficDescription[String(!!errorResponse.oldSystemAffected)];
  return (
    <span key={`${errorResponse.issue}-${index}-content`}>
      {prefix} {content} {postfix}
    </span>
  );
};

export const getRuleNameForMigrationError = (
  ruleId: string,
  environmentKey: string,
  flag: Flag,
  updatedFlag?: Flag,
  instructionList?: SemanticInstruction[],
) => {
  if (ruleId === 'fallthrough') {
    return 'Default cohort';
  }
  const renameInstructions =
    instructionList &&
    instructionList.filter((instruction) => instruction.kind === RuleInstructionKind.UPDATE_RULE_DESCRIPTION);
  const updatedName =
    renameInstructions &&
    renameInstructions.find((instruction) => Boolean(instruction.ruleId) && instruction.ruleId === ruleId)?.description;
  const ruleIndex = flag.getRuleIndex(environmentKey, ruleId);
  const ruleDescription = flag.getRuleById(environmentKey, ruleId)?.description;
  const originalNameOrUpdatedName = updatedName && updatedName.length ? updatedName : ruleDescription;
  let ruleName =
    originalNameOrUpdatedName && originalNameOrUpdatedName.length
      ? originalNameOrUpdatedName
      : `Cohort ${ruleIndex + 1}`;

  const updatedRuleIndex = updatedFlag && updatedFlag.getRuleIndex(environmentKey, ruleId);
  const updatedRuleDescription = updatedFlag && updatedFlag.getRuleById(environmentKey, ruleId)?.description;

  if (updatedRuleIndex === -1 && ruleIndex === -1) {
    ruleName = 'Cohort not found';
  } else if (!isNaN(Number(updatedRuleIndex)) && updatedRuleIndex !== -1) {
    const updatedNameOrDescription = updatedName && updatedName.length ? updatedName : updatedRuleDescription;
    ruleName =
      updatedNameOrDescription && updatedNameOrDescription.length
        ? updatedNameOrDescription
        : `Cohort ${Number(updatedRuleIndex) + 1}`;
  }
  return ruleName;
};
