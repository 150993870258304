import { halfwayIA } from '@gonfalon/dogfood-flags';

import { Breadcrumbs } from 'routers/Breadcrumbs';

type FlagBreadcrumbsProps = {
  flagName?: string;
};

export const FlagBreadcrumbs = ({ flagName: label = '' }: FlagBreadcrumbsProps) => (
  <Breadcrumbs
    breadcrumbs={[
      {
        path: halfwayIA() ? '/projects/:projectKey/flags' : '/:projKey/:envKey/features',
        element: 'Feature flags',
        children: [
          { path: 'compare/*', element: 'Compare' },
          {
            path: ':flagKey/*',
            element: label,
            children: [
              { path: 'workflows/:workflowId', element: 'Workflow' },
              { path: 'approvals/:approvalId', element: 'Approval request' },
            ],
          },
        ],
      },
    ]}
  />
);
