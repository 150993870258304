import { type EnvironmentAware } from './types';

export function makeEnvironmentAwareSearchParamsParser<T extends Object>(
  parse: (searchParams: URLSearchParams) => T,
): (searchParams: URLSearchParams) => EnvironmentAware<T> {
  return (searchParams: URLSearchParams) => {
    const parsed = parse(searchParams);
    return {
      ...parsed,
      selectedEnvironmentKey: searchParams.get('selected-env') ?? '',
    };
  };
}
