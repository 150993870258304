import { useCallback, useMemo } from 'react';
import { useStorageSlot } from '@gonfalon/browser-storage';
import { cacheFlagFiltersAndSortOrdering } from '@gonfalon/dogfood-flags';

import { ensureSortParam } from './ensureSortParam';
import { isDefaultSortOrdering } from './isDefaultSortOrdering';
import { type SortOrdering } from './types';

export const cacheKey = 'flag-list-sort-ordering';

export function useCachedFlagListSortOrdering() {
  const [rawValue, slot] = useStorageSlot(cacheKey, {
    storageType: 'sessionStorage',
    isEnabled: cacheFlagFiltersAndSortOrdering(),
  });

  const cached = useMemo(() => {
    if (!rawValue) {
      return undefined;
    }

    if (isDefaultSortOrdering(rawValue)) {
      return undefined;
    }

    try {
      return ensureSortParam(rawValue);
    } catch (error) {
      return undefined;
    }
  }, [rawValue]);

  const put = useCallback(
    (value?: SortOrdering | undefined) => {
      if (isDefaultSortOrdering(value)) {
        slot.remove();
        return;
      }

      try {
        slot.set(ensureSortParam(value));
      } catch (error) {
        slot.remove();
      }
    },
    [rawValue],
  );

  const value = useMemo(
    () => ({
      value: cached,
      put,
    }),
    [cached, put],
  );

  return value;
}
