import {
  type SegmentSemanticInstruction,
  AddBigSegmentIncludedTargetsInstruction,
  RemoveBigSegmentIncludedTargetsInstruction,
  UpdateClauseInstruction,
  UpdateRuleDescriptionInstruction,
  UpdateRuleRolloutInstruction,
} from '@gonfalon/openapi';

function findInstructionIndex(
  originalInstructions: SegmentSemanticInstruction[],
  updatedInstruction: SegmentSemanticInstruction,
) {
  return originalInstructions.findIndex((instruction) => {
    if (['addExpiringTarget', 'updateExpiringTarget', 'removeExpiringTarget'].includes(instruction.kind)) {
      return (
        'contextKind' in updatedInstruction &&
        'contextKind' in instruction &&
        updatedInstruction.contextKind === instruction.contextKind &&
        'value' in updatedInstruction &&
        'value' in instruction &&
        updatedInstruction.value === instruction.value
      );
    }

    if (updatedInstruction.kind === 'updateRuleDescription') {
      return (instruction as UpdateRuleDescriptionInstruction).ruleId === updatedInstruction.ruleId;
    }

    // allow for multiple instructions of the same kind as long as they're modifying different rules
    if ('ruleId' in instruction && 'ruleId' in updatedInstruction) {
      return instruction.kind === updatedInstruction.kind && instruction.ruleId === updatedInstruction.ruleId;
    }

    return instruction.kind === updatedInstruction.kind;
  });
}

function handleUpdateClauseInstruction(
  originalInstructions: SegmentSemanticInstruction[],
  updatedInstruction: UpdateClauseInstruction,
) {
  // for a given rule, an updateClause instruction supersedes addValuesToClause and removeValuesFromClause instructions
  // remove the addValuesToClause and removeValuesFromClause for that rule and retain all other instructions
  return originalInstructions.filter((instruction) => {
    if (instruction.kind === 'addValuesToClause' || instruction.kind === 'removeValuesFromClause') {
      return instruction.ruleId !== updatedInstruction.ruleId;
    } else {
      return true;
    }
  });
}

function handleUpdateRuleRolloutInstruction(
  updatedInstruction: UpdateRuleRolloutInstruction,
  existingInstruction?: SegmentSemanticInstruction,
): UpdateRuleRolloutInstruction {
  if (existingInstruction?.kind === 'updateRuleRolloutAndContextKind') {
    if (!existingInstruction.resetRollout && !updatedInstruction.resetRollout) {
      return { ...existingInstruction, ...updatedInstruction };
    }
  }

  return updatedInstruction;
}

function handleBigSegmentIncludedTargets(
  updatedInstruction: AddBigSegmentIncludedTargetsInstruction | RemoveBigSegmentIncludedTargetsInstruction,
  existingInstruction?: SegmentSemanticInstruction,
): AddBigSegmentIncludedTargetsInstruction | RemoveBigSegmentIncludedTargetsInstruction {
  if (existingInstruction?.kind === updatedInstruction.kind) {
    const existingValues = new Set(existingInstruction.values);
    const mergedValues = existingValues.union(new Set(updatedInstruction.values));

    return { ...updatedInstruction, values: Array.from(mergedValues) };
  }

  return updatedInstruction;
}

export function makeUpdatedInstructions(
  originalInstructions: SegmentSemanticInstruction[],
  updatedInstruction: SegmentSemanticInstruction,
): SegmentSemanticInstruction[] {
  let updatedInstructions = [...originalInstructions];
  const insertionIdx = findInstructionIndex(originalInstructions, updatedInstruction);
  const instructionExists = insertionIdx !== -1;

  let instructionToAdd = { ...updatedInstruction };
  const existingInstruction = originalInstructions[insertionIdx];
  if (updatedInstruction.kind === 'updateRuleRolloutAndContextKind') {
    instructionToAdd = handleUpdateRuleRolloutInstruction(updatedInstruction, existingInstruction);
  }

  if (
    updatedInstruction.kind === 'addBigSegmentIncludedTargets' ||
    updatedInstruction.kind === 'removeBigSegmentIncludedTargets'
  ) {
    instructionToAdd = handleBigSegmentIncludedTargets(updatedInstruction, existingInstruction);
  }

  if ('values' in instructionToAdd && instructionToAdd.values.length === 0) {
    // remove instruction if there are no values to update
    updatedInstructions.splice(insertionIdx, 1);
  } else {
    updatedInstructions = instructionExists
      ? originalInstructions.toSpliced(insertionIdx, 1, instructionToAdd)
      : originalInstructions.concat([instructionToAdd]);
  }

  if (instructionToAdd.kind === 'updateClause') {
    updatedInstructions = handleUpdateClauseInstruction(updatedInstructions, instructionToAdd);
  }

  return updatedInstructions;
}
