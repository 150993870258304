import nullthrows from 'nullthrows';

import { TreatmentQuantileResult, TreatmentResult } from './types';

export function normalizeTreatmentResults(results: TreatmentResult | TreatmentQuantileResult) {
  const { relativeDifferences, ...rest } = results;
  const valueInterval = getValueInterval(results);

  return {
    ...rest,
    valueInterval: {
      lower: nullthrows(valueInterval?.lower),
      upper: nullthrows(valueInterval?.upper),
    },
    relativeDifferences: nullthrows(
      relativeDifferences?.map((difference) => ({
        fromTreatmentId: nullthrows(difference.fromTreatmentId),
        lower: nullthrows(difference.lower),
        upper: nullthrows(difference.upper),
        estimate: difference.estimate,
      })),
    ),
  };
}

export function getValueInterval(results: TreatmentResult | TreatmentQuantileResult) {
  if ('credibleInterval' in results) {
    return results.credibleInterval;
  } else if ('percentileInterval' in results) {
    return results.percentileInterval;
  }

  throw new Error('Invalid treatment results');
}
