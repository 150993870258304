import { List } from 'immutable';

import { TagKind } from 'components/tagFilter/types';
import { ImmutableServerError } from 'utils/httpUtils';
import { GenerateActionType } from 'utils/reduxUtils';

export const fetchTags = (prefix: string | null, kind?: TagKind, archived?: boolean) =>
  ({
    type: 'tags/FETCH_TAGS',
    prefix,
    kind,
    archived,
  }) as const;
export const fetchTagsFailed = (error: ImmutableServerError) =>
  ({
    type: 'tags/FETCH_TAGS_FAILED',
    error,
  }) as const;
export const fetchTagsDone = (tags: List<string>) =>
  ({
    type: 'tags/FETCH_TAGS_DONE',
    tags,
  }) as const;

const TagActionCreators = {
  fetchTags,
  fetchTagsFailed,
  fetchTagsDone,
};

export type TagsAction = GenerateActionType<typeof TagActionCreators>;
