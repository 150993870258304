import { internalEnvironmentList } from '@gonfalon/rest-api';
import { useQuery } from '@tanstack/react-query';

import { useFavoriteEnvironmentIds } from './useFavoriteEnvironmentIds';

/**
 * Load favorite environments for a project.
 */
export function useFavoriteEnvironmentsForProject(projectKey: string) {
  const ids = useFavoriteEnvironmentIds();

  const environments = useQuery({
    ...internalEnvironmentList({ projectKey, query: { id: ids } }),
    enabled: ids.length > 0,
  });

  return { ids: new Set(ids), environments: environments.data?.items ?? [] } as const;
}
