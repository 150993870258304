import { useEffect, useState } from 'react';
import { useDebounce } from '@gonfalon/async';
import { ResourceSpecifier, resourceSpecifierFromString, toReadableString } from '@gonfalon/resource-specifiers';
import classNames from 'clsx';
import { Markdown } from 'launchpad';
import { noop } from 'lodash';

import { validateName } from './nameValidator';

import styles from './styles.module.css';

export type PolicyResourceProps = {
  negate?: boolean;
  resourceId?: string;
};

/* eslint-disable import/no-default-export */
export default function PolicyResource({ negate, resourceId }: PolicyResourceProps) {
  const [resource, setResource] = useState<ResourceSpecifier | null>(null);
  const [isValid, setIsValid] = useState(false);
  const [isNameValid, setIsNameValid] = useState(false);

  const debounceResource = useDebounce(async (debounceResourceId) => {
    setResource(null);
    setIsValid(false);
    setIsNameValid(false);

    const { val, ok } = resourceSpecifierFromString(debounceResourceId);
    setIsValid(ok);
    if (ok && 'type' in val) {
      setResource(val);
      const _isNameValid = await validateName(val);
      setIsNameValid(_isNameValid || false);
    }
  }, 500);

  useEffect(() => {
    if (!resourceId) {
      return;
    }
    async function parseAndValidate() {
      await debounceResource(resourceId);
    }
    parseAndValidate().then(noop).catch(noop);
  }, [resourceId]);

  if (!resourceId) {
    return null;
  }
  const renderInvalidMessageShown = () => {
    const validResource = resource && isValid;
    if (!validResource) {
      return 'Invalid resource type';
    }
    if (
      ('project' in resource && resource.project.name.includes('*')) ||
      ('environment' in resource && resource.environment.project.name.includes('*'))
    ) {
      return 'Using the all (*) operator can result in an invalid policy. Please make sure that all nested resources exist before saving.';
    }
    if (!isNameValid) {
      return `Resource key ${'name' in resource && `${resource.name}`} does not exist.`;
    }
  };

  return (
    <span
      className={classNames(styles.statementResources, {
        [styles.statementResourcesInvalid]: !isValid,
        [styles.statementResourcesWarning]: isValid && !isNameValid,
      })}
    >
      {negate && isValid && <span className={styles.statementNegation}>Except for</span>}
      {resource && <Markdown source={toReadableString(resource)} />}
      {renderInvalidMessageShown()}
    </span>
  );
}
