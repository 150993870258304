// transformed by jscodeshift

import type { IconProps } from '@launchpad-ui/icons';
import { Icon, IconName } from '@launchpad-ui/icons';
import cx from 'clsx';

import { FlagStatusKind } from 'utils/flagStatusUtils';

import styles from './FlagStatusIcon.module.css';

const statusIcons = {
  [FlagStatusKind.ACTIVE]: 'status-active',
  [FlagStatusKind.INACTIVE]: 'status-inactive',
  [FlagStatusKind.LAUNCHED]: 'status-launched',
  [FlagStatusKind.NEW]: 'status-new',
};

type FlagStatusIconProps = {
  status: FlagStatusKind;
  className?: string;
  size?: IconProps['size'];
};

/* eslint-disable import/no-default-export */
export default function FlagStatusIcon({ status, className, size = 'small' }: FlagStatusIconProps) {
  const name = statusIcons[status] as IconName;
  return <Icon name={name} size={size} className={cx([styles[status]], styles.flagStatusIcon, className)} />;
}
