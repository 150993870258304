import { useMemo, useState } from 'react';

export type PaginatedCollectionArguments<T> = {
  collection: T[];
  limit: number;
  offset: number;
  totalCount: number;
};

export function usePaginatedCollection<T>({
  collection,
  offset: initialOffset = 0,
  limit = 20,
  totalCount: initialTotal = 0,
}: PaginatedCollectionArguments<T>) {
  const [offset, setOffset] = useState(+initialOffset);
  const totalCount = initialTotal || collection.length;
  const items = collection.slice(+offset, +offset + limit);
  const { pageCount, previousPageOffset, nextPageOffset, lastPageOffset } = getPaginatedCollectionOffsets({
    totalCount,
    limit,
    offset,
  });
  const hasPreviousPage = offset > 0;
  const hasNextPage = offset < lastPageOffset;

  function gotoPreviousPage() {
    const updatedOffset = Math.max(0, previousPageOffset);
    setOffset(updatedOffset);
    return offset;
  }

  function gotoNextPage() {
    const updatedOffset = Math.min(nextPageOffset, lastPageOffset);
    setOffset(updatedOffset);
    return offset;
  }

  function gotoFirstPage() {
    setOffset(0);
    return 0;
  }

  function gotoLastPage() {
    setOffset(lastPageOffset);
    return lastPageOffset;
  }

  function updateOffset(newOffset: number) {
    setOffset(newOffset);
  }

  function handlePageChange(direction: string) {
    let newOffset = 0;
    if (direction === 'next') {
      newOffset = gotoNextPage();
    } else if (direction === 'prev') {
      newOffset = gotoPreviousPage();
    } else if (direction === 'last') {
      newOffset = gotoLastPage();
    } else {
      newOffset = gotoFirstPage();
    }
    return newOffset;
  }

  return useMemo(
    () => ({
      items,
      limit,
      lastPageOffset,
      offset,
      previousPageOffset,
      nextPageOffset,
      totalCount,
      pageCount,
      handlePageChange,
      hasPreviousPage,
      hasNextPage,
      gotoFirstPage,
      gotoPreviousPage,
      gotoNextPage,
      gotoLastPage,
      updateOffset,
    }),
    [collection, offset, limit, items],
  );
}

export function getPaginatedCollectionOffsets({
  totalCount = 0,
  limit = 20,
  offset = 0,
}: {
  totalCount?: number;
  limit?: number;
  offset?: number;
} = {}) {
  const pageCount = Math.ceil(totalCount / limit) || 0;
  const previousPageOffset = Math.max(0, offset - limit);
  const lastPageOffset = (pageCount - 1) * limit;
  const nextPageOffset = Math.min(offset + limit, lastPageOffset);

  return {
    pageCount,
    previousPageOffset,
    nextPageOffset,
    lastPageOffset,
  } as const;
}
