import { List, Map, OrderedSet, Set } from 'immutable';
import { AnyAction } from 'redux';
import { createSelector } from 'reselect';

// eslint-disable-next-line import/no-namespace
import * as actionTypes from 'actionTypes/metrics';
import { GlobalState } from 'reducers';
import { createRequestReducerByKey } from 'reducers/createRequestReducer';
import registry from 'reducers/registry';
import { Goal } from 'utils/goalUtils';
import { PaginationType } from 'utils/paginationUtils';
import { RequestAction } from 'utils/requestUtils';

import paginate from './paginate';

export function metricEntities(state: Map<string, Goal> = Map(), action: AnyAction): Map<string, Goal> {
  if (action.response && action.response.hasIn(['entities', 'metrics'])) {
    return state.merge(action.response.getIn(['entities', 'metrics']));
  }

  if (action.type === actionTypes.RECEIVE_METRIC) {
    return state.set(action.metricKey, action.metric);
  }

  if (action.type === actionTypes.UPDATE_METRIC_DONE) {
    return state.set(action.metric.key, action.metric);
  }
  return state;
}

export const metricPagination = paginate({
  types: [actionTypes.REQUEST_METRICS, actionTypes.REQUEST_METRICS_FAILED, actionTypes.RECEIVE_METRICS],
  mapActionToKey: () => 'all',
});

export const metricDeletions = (state: Set<string> = Set(), action: AnyAction) => {
  if (action.type === actionTypes.DELETE_METRIC_DONE) {
    return state.add(action.metric.key);
  }

  return state;
};

export const metricRequestsByKey = createRequestReducerByKey(
  [actionTypes.REQUEST_METRIC, actionTypes.RECEIVE_METRIC, actionTypes.REQUEST_METRIC_FAILED],
  (action: RequestAction) => action.metricKey,
);

export const metricEntitiesSelector = (state: GlobalState) => state.metricEntities;
export const metricDeletionSelector = (state: GlobalState) => state.metricDeletions;
export const metricRequestsByKeySelector = (state: GlobalState) => state.metricRequestsByKey;
export const metricPaginationSelector = (state: GlobalState) =>
  state.metricPagination.get('all', Map() as PaginationType);
export const isMetricListReadySelector = createSelector(metricPaginationSelector, (pagination) => {
  if (!pagination.get('lastFetched')) {
    return false;
  }
  return pagination.get('pageCount') === 0 ? !pagination.get('isFetching') : true;
});
export const metricListSelector = createSelector(
  metricEntitiesSelector,
  metricPaginationSelector,
  metricDeletionSelector,
  (entities, pagination, deleted) => {
    if (!pagination) {
      return List();
    }

    const ids = pagination.get('ids', OrderedSet());

    return ids
      .filterNot((id: string) => deleted.has(id))
      .map((id: string) => entities.get(id))
      .toList();
  },
);

export const metricSelector = (state: GlobalState, props: { metricKey: string }) =>
  metricEntitiesSelector(state).get(props.metricKey);
export const metricRequestSelector = (state: GlobalState, props: { metricKey: string }) =>
  metricRequestsByKeySelector(state).get(props.metricKey);

registry.addReducers({ metricDeletions, metricEntities, metricPagination, metricRequestsByKey });
