// eslint-disable-next-line no-restricted-imports
import { fromJS } from 'immutable';
import { AnyAction } from 'redux';
import { createSelector } from 'reselect';

import archiveActionTypes from 'actionTypes/archive';
import flagActionTypes from 'actionTypes/flags';
// eslint-disable-next-line import/no-namespace
import * as segmentActionTypes from 'actionTypes/segments';
import { GlobalState } from 'reducers';
import { Flag } from 'utils/flagUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { Role } from 'utils/roleUtils';
import { ResourceKind } from 'utils/saveButtonUtils';
import { Segment } from 'utils/segmentUtils';
import { User } from 'utils/userUtils';

import registry from './registry';

type UpdateCommentState = ImmutableMap<{
  key: string;
  comment: string;
}>;

const initialUpdateCommentState: UpdateCommentState = fromJS({
  key: '',
  comment: '',
});

type Resource = Flag | Segment | User | Role;

function getResourceIdentifier(resource: Resource, resourceKind: ResourceKind) {
  if ('key' in resource) {
    return resource.key;
  }
  if (resourceKind === ResourceKind.USER) {
    return resource.attributes.key;
  }
  return '';
}

export function updateComment(state: UpdateCommentState = initialUpdateCommentState, action: AnyAction) {
  switch (action.type) {
    case 'comments/EDIT_CHANGE_DESCRIPTION':
      return state.merge({ key: getResourceIdentifier(action.resource, action.resourceKind), comment: action.comment });
    case flagActionTypes.CREATE_FLAG_DONE:
    case flagActionTypes.UPDATE_FLAG_DONE:
    case flagActionTypes.UPDATE_FLAG_GOALS_DONE:
    case flagActionTypes.UPDATE_PROJECT_FLAG_SETTINGS_DONE:
    case flagActionTypes.UPDATE_ENV_FLAG_SETTINGS_DONE:
    case flagActionTypes.UPDATE_RULE_EXCLUSION_DONE:
    case archiveActionTypes.ARCHIVE_FLAG_DONE:
    case archiveActionTypes.RESTORE_FLAG_DONE:
    case segmentActionTypes.CREATE_SEGMENT_DONE:
    case segmentActionTypes.UPDATE_SEGMENT_TARGETING_DONE:
    case segmentActionTypes.UPDATE_SEGMENT_SETTINGS_DONE:
    case 'approvals/CREATE_APPROVAL_REQUEST_DONE':
    case 'scheduledChanges/CREATE_FLAG_SCHEDULED_CHANGE_DONE':
      return initialUpdateCommentState;
    default:
      return state;
  }
}

const updateCommentSelector = (state: GlobalState) => state.updateComment;

export const makeChangeDescriptionSelector = () =>
  createSelector(
    updateCommentSelector,
    (_: GlobalState, props: { resource?: Resource }) => props.resource,
    (updateCommentResult, resource) => {
      let resourceKey: string | undefined = '';
      if (resource) {
        // If we have a resource, it should be one of `resourceKind`. If it has a `key` property use that (note that the
        // `key` does not need to exist, the Record just needs to have that property).  Otherwise it should be a `User`
        // and we can get the key off of the `attributes` property
        resourceKey = 'key' in resource ? resource.key : resource.attributes.key;
      }
      return updateCommentResult.get('key') === resourceKey ? updateCommentResult.get('comment') : '';
    },
  );

registry.addReducers({
  updateComment,
});
