import { Dialog, Heading } from 'react-aria-components';
import { IconButton } from '@launchpad-ui/components';

import styles from './HelpDialog.module.css';
export type HelpDialogProps = {
  title: string;
  message: string;
};
export function HelpDialog({ title, message }: HelpDialogProps) {
  return (
    <Dialog className={styles.dialog}>
      {({ close }) => (
        <>
          <div className={styles.header}>
            <Heading slot="title">{title}</Heading>
            <IconButton aria-label="Close dialog" variant="minimal" icon="cancel" size="small" onPress={close} />
          </div>
          <div className={styles.help}>
            <p className={styles.message}>{message}</p>
          </div>
        </>
      )}
    </Dialog>
  );
}
