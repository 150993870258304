import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { type SegmentRule } from '@gonfalon/openapi';
import { useProjectKey } from '@gonfalon/router';
import { fromJS } from 'immutable';

import { RuleInstructionDescription } from 'components/InstructionList/RuleInstructionDescription';
import { createAggregateRule } from 'utils/instructions/rules/aggregateHelpers';

type SegmentRuleInstructionDescriptionProps = {
  rule?: SegmentRule;
};
export function SegmentRuleInstructionDescription({ rule }: SegmentRuleInstructionDescriptionProps) {
  const envKey = useSelectedEnvironmentKey();
  const projKey = useProjectKey();
  const aggregateRule = rule ? createAggregateRule(fromJS(rule)) : undefined;

  return (
    <RuleInstructionDescription
      projKey={projKey}
      envKey={envKey}
      rule={aggregateRule}
      variations={fromJS([])}
      showServeVariation={false}
    />
  );
}
