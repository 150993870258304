import { To } from 'react-router-dom';
import { getExperimentListPageSize } from '@gonfalon/dogfood-flags';
import { isEmpty } from '@gonfalon/es6-utils';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, Record } from 'immutable';
import qs from 'qs';

import { SuccessCriteria } from 'components/FlagExperiments/types';
import { ImmutableMap } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';

import { createVariation, Variation } from './flagUtils';

type ExperimentFiltersType = {
  limit?: number;
  offset?: number;
  query?: string;
  sort?: string;
  status?: string;
};

export type ExperimentApiOptions = {
  pathOnSuccess?: To;
  baselineIdx?: number;
  currentEnvKey: string;
  eventLocation: string;
  pathToFlag: string;
};

export class ExperimentFilters extends Record<ExperimentFiltersType>({
  limit: -1,
  offset: 0,
  query: '',
  sort: '',
  status: '',
}) {
  isEmpty() {
    return isEmpty(this.query) && isEmpty(this.status);
  }

  toFrontendExperimentsQuery() {
    const q = {} as ExperimentFiltersType;
    if (this.limit && this.limit > 0 && (this.offset || this.limit !== getExperimentListPageSize())) {
      q.limit = this.limit;
    }
    if (this.offset) {
      q.offset = this.offset;
    }
    if (!isEmpty(this.query)) {
      q.query = this.query;
    }
    if (!isEmpty(this.sort)) {
      q.sort = this.sort;
    }
    if (!isEmpty(this.status)) {
      q.status = this.status;
    }

    return q;
  }

  toQueryString() {
    return qs.stringify(this.toFrontendExperimentsQuery(), { indices: false });
  }

  toBackendQueryString() {
    const q = {} as ExperimentFiltersType;

    if (this.query && this.query !== '') {
      q.query = this.query;
    }
    if (this.status && this.status !== '') {
      q.status = this.status;
    }
    if (this.sort && this.sort !== '') {
      q.sort = this.sort;
    }
    if (this.limit && this.limit > 0) {
      q.limit = this.limit;
    }
    if (this.offset) {
      q.offset = this.offset;
    }
    return qs.stringify(q, { indices: false, addQueryPrefix: true });
  }
}

export function createExperimentFilters(props: ExperimentFiltersType) {
  return new ExperimentFilters(fromJS(props));
}

export function createExperimentFiltersFromQuery(queryMap: ExperimentFiltersType) {
  const limit = queryMap ? queryMap.limit : '';
  const offset = queryMap ? queryMap.offset : '';
  const query = queryMap ? queryMap.query : '';
  const status = queryMap ? queryMap.status : '';
  const sort = queryMap ? queryMap.sort : '';

  const props = {
    limit: limit || getExperimentListPageSize(),
    offset: offset || 0,
    query: query || '',
    status: status || '',
    sort: sort || '',
    kind: 'experiment',
  };

  return createExperimentFilters(props);
}

export function createExperimentFiltersFromBackendQueryString(queryString: string) {
  const backendParams = qs.parse(queryString, { ignoreQueryPrefix: true });
  const props = {
    query: backendParams.query,
    status: backendParams.status,
    limit: parseInt(backendParams.limit, 10),
    offset: parseInt(backendParams.offset, 10),
  };
  return createExperimentFilters(props);
}

type ExperimentFlagType = {
  archived: boolean;
  key: string;
  links: ImmutableMap<{ parent: Link; self: Link }>;
  name: string;
  site: Map<string, string>;
  variations: List<Variation>;
};

type ExperimentMetricType = {
  description: string;
  isNumeric: boolean;
  key: string;
  kind: string;
  lastModified: { date: string };
  name: string;
  tags: string[] | null;
  successCriteria: SuccessCriteria | null;
  _access: Map<string, string>;
  _creationDate: number;
  _id: string;
  _links: ImmutableMap<{ parent: Link; self: Link }>;
};

export type ExperimentType = {
  _flag: ExperimentFlag;
  _id?: string;
  _metric: ExperimentMetric;
  _startDate?: number;
  _status: string;
  _stopDate?: number;
  _baselineIdx: number;
  _envKey?: string;
};

export class ExperimentFlag extends Record<ExperimentFlagType>({
  archived: false,
  key: '',
  links: Map(),
  name: '',
  site: Map(),
  variations: List.of(createVariation(), createVariation()),
}) {
  siteLink() {
    return this.site.get('href');
  }
}

export class ExperimentMetric extends Record<ExperimentMetricType>({
  description: '',
  isNumeric: false,
  key: '',
  kind: '',
  lastModified: { date: '' },
  name: '',
  tags: [''],
  successCriteria: null,
  _access: Map(),
  _creationDate: 0,
  _id: '',
  _links: Map(),
}) {}

export class Experiment extends Record<ExperimentType>({
  _id: '',
  _startDate: undefined,
  _stopDate: undefined,
  _status: '',
  _flag: new ExperimentFlag(),
  _metric: new ExperimentMetric(),
  _baselineIdx: -1,
  _envKey: '',
}) {}

export function createExperiment(props: ExperimentType) {
  let experiment = props instanceof Experiment ? props : new Experiment(props);
  experiment = experiment.withMutations((e) => {
    e.update('_flag', (flag) => new ExperimentFlag(flag));
    e.update('_metric', (metric) => new ExperimentMetric(metric));
  });
  return experiment;
}

const statusNames: { [key: string]: 'Recording' | 'Paused' | 'Not started' } = {
  recording: 'Recording',
  paused: 'Paused',
  notStarted: 'Not started',
};
export const getStatusName = (status: string) => statusNames[status];

export enum Status {
  RECORDING = 'Recording',
  NOT_RECORDING = 'Not Recording',
  NOT_STARTED = 'Not Started',
  ARCHIVED = 'Archived Flag',
}
