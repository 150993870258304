import { Modal } from 'launchpad';

import { ContactUsForm, ContactUsFormProps } from './ContactUsForm';

import styles from './styles/ContactUsForm.module.css';

export type ContactUsFormModalProps = Omit<ContactUsFormProps, 'canManagePlans' | 'profile' | 'subscription'> & {
  isOpen?: boolean;
  onCancel?(): void;
};

export function ContactUsModal({
  isOpen,
  onCancel,
  productInterest,
  requestedFeature,
  headerText,
}: ContactUsFormModalProps) {
  if (!isOpen) {
    return null;
  }

  return (
    <Modal className={styles.modal} onCancel={onCancel} theme="dark">
      <ContactUsForm requestedFeature={requestedFeature} productInterest={productInterest} headerText={headerText} />
    </Modal>
  );
}
