import { ModernSubscription } from '../../types';

import { getTrialDaysRemaining } from './getTrialDaysRemaining';
import { hasSubscription } from './hasSubscription';
import { isExpiredTrialWithNoPlan } from './isExpiredTrialWithNoPlan';

export function getTrialStatusInfo(modernSubscription: ModernSubscription) {
  return {
    isTrialExpired: isExpiredTrialWithNoPlan(modernSubscription),
    trialDaysRemaining: getTrialDaysRemaining(modernSubscription),
    isSubscribed: hasSubscription(modernSubscription),
  };
}
