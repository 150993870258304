import { getCodeReferenceListPageSize } from '@gonfalon/dogfood-flags';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, OrderedMap } from 'immutable';
import { combineReducers } from 'redux';

import { FlagCodeReferencesAction } from 'actions/flagCodeReferences';
import { FormAction } from 'actions/forms';
import { GlobalState } from 'reducers';
import registry from 'reducers/registry';
import { querySelector } from 'reducers/router';
import {
  Branch,
  CodeStatisticsForFlag,
  createCodeReferenceFiltersFromQuery,
  createRepositoryFormRecord,
  Extinction,
  Repository,
  RepositoryFormRecord,
} from 'utils/codeRefs/codeRefsUtils';
import { createFormState } from 'utils/formUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { createRequest } from 'utils/requestUtils';

export type FetchCodeStatistics = ImmutableMap<{
  lastFetched?: number;
  isFetching: boolean;
  error: ImmutableServerError;
  entities: OrderedMap<string, List<CodeStatisticsForFlag>>;
}>;
const initialCodeStatistics: FetchCodeStatistics = fromJS({
  lastFetched: null,
  isFetching: false,
  error: null,
  entities: {},
});

type Collapse = ImmutableMap<{
  status: Map<string, Map<string, List<boolean>>>;
  collapseAllToggle: boolean;
}>;
const initialCollapse: Collapse = fromJS({
  status: {},
  collapseAllToggle: true,
});

type PaginationType = Map<string, number>;
const initialPagination: PaginationType = Map<string, number>();

type FetchRefsForFlag = ImmutableMap<{
  lastFetched: number;
  isFetching: boolean;
  error: ImmutableServerError;
  defaultBranchExtinctions: Map<string, List<Extinction>>;
  extinctionsByBranch: Map<string, Map<string, List<Extinction>>>;
  repos: Map<string, Repository>;
}>;
type FetchAllCodeRefs = Map<string, FetchRefsForFlag>;
const initialFetchAllCodeRefs: FetchAllCodeRefs = Map();

const initializeFormState = (state = createRepositoryFormRecord()) => createFormState<RepositoryFormRecord>(state);

export const repositoryFormKey = 'repositoryForm';

const createBranchStateKey = (flagKey: string, repoName: string, branchName: string) => [
  flagKey,
  'repos',
  repoName,
  'branches',
  branchName,
];

const refs = (state = initialFetchAllCodeRefs, action: FlagCodeReferencesAction) => {
  switch (action.type) {
    case 'codeRefs/REQUEST_FLAG_CODE_REFERENCES':
      return state.setIn([action.flagKey, 'isFetching'], true);
    case 'codeRefs/RECEIVE_FLAG_CODE_REFERENCES':
      return state.update(action.flagKey, (refsState) =>
        refsState.merge({
          isFetching: false,
          lastFetched: action.timestamp,
          repos: action.repos,
          defaultBranchExtinctions: action.defaultBranchExtinctions,
        }),
      );
    case 'codeRefs/REQUEST_FLAG_CODE_REFERENCES_FAILED':
      return state.update(action.flagKey, (refsState) =>
        refsState.merge({
          isFetching: false,
          lastFetched: action.timestamp,
          error: action.error,
        }),
      );
    case 'codeRefs/RECEIVE_BRANCH_CODE_REFERENCES':
      return state.withMutations((s) => {
        let ss = s;
        if (action.extinctions) {
          ss = ss.mergeDeep({
            [action.flagKey]: fromJS({ extinctionsByBranch: { [action.branch.name]: action.extinctions } }),
          });
        }

        return ss.updateIn([action.flagKey, 'repos', action.repoName], (repo) =>
          repo.setIn(
            ['branches', action.branch.name],
            action.branch.applyCommitUrlTemplate(repo.commitUrlTemplate).applyHunkUrlTemplate(repo.hunkUrlTemplate),
          ),
        );
      });
    default:
      return state;
  }
};

export function repositoryForm(state = initializeFormState(), action: FlagCodeReferencesAction | FormAction) {
  switch (action.type) {
    case 'codeRefs/EDIT_REPOSITORY':
      return state.trackField(action.field).revalidate(action.repository);
    case 'codeRefs/UPDATE_REPOSITORY':
      return state.submitting();
    case 'codeRefs/UPDATE_REPOSITORY_DONE':
      return state.submitted(action.repository.toForm());
    case 'codeRefs/UPDATE_REPOSITORY_FAILED':
      return state.submitFailed(state.modified, action.error);
    case 'forms/INITIALIZE':
      if (action.model !== repositoryFormKey) {
        return state;
      }
      return initializeFormState(action.initialState as RepositoryFormRecord);
    case 'forms/DESTROY':
      if (action.model !== repositoryFormKey) {
        return state;
      }
      return initializeFormState();
    default:
      return state;
  }
}

export function flagCodeStatistics(state = initialCodeStatistics, action: FlagCodeReferencesAction) {
  switch (action.type) {
    case 'codeRefs/REQUEST_CODE_STATISTICS':
      return state.set('isFetching', true);
    case 'codeRefs/REQUEST_CODE_STATISTICS_FAILED':
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        error: action.error,
      });
    case 'codeRefs/RECEIVE_CODE_STATISTICS':
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        entities: action.flagStatistics,
      });
    default:
      return state;
  }
}

const branchRequests = (state = Map(), action: FlagCodeReferencesAction) => {
  switch (action.type) {
    case 'codeRefs/REQUEST_BRANCH_CODE_REFERENCES': {
      const { flagKey, repoName, branchName } = action;
      return state.setIn(createBranchStateKey(flagKey, repoName, branchName), createRequest().start());
    }
    case 'codeRefs/RECEIVE_BRANCH_CODE_REFERENCES': {
      const { flagKey, repoName, branchName } = action;
      return state.updateIn(createBranchStateKey(flagKey, repoName, branchName), (req) => req.done(action));
    }
    case 'codeRefs/REQUEST_BRANCH_CODE_REFERENCES_FAILED': {
      const { flagKey, repoName, branchName } = action;
      return state.updateIn(createBranchStateKey(flagKey, repoName, branchName), (req) => req.failed(action));
    }
    default:
      return state;
  }
};

const pagination = (state = initialPagination, action: FlagCodeReferencesAction) => {
  switch (action.type) {
    case 'codeRefs/RECEIVE_FLAG_CODE_REFERENCES':
      return action.repos.reduce((acc, repo) => acc.set(repo.id, 0), initialPagination);
    case 'codeRefs/LOAD_MORE_CODE_REFERENCES':
      return state.update(action.repoId, (s: number) => s + getCodeReferenceListPageSize());
    case 'codeRefs/RESET_PAGINATION':
      return state.map(() => 0);
    default:
      return state;
  }
};

const collapse = (state = initialCollapse, action: FlagCodeReferencesAction) => {
  switch (action.type) {
    case 'codeRefs/RECEIVE_FLAG_CODE_REFERENCES':
      return state.set('collapseAllToggle', true).set(
        'status',
        action.repos.reduce((acc, repo) => acc.set(repo.id, repo.generateCollapsedState(false)), Map()),
      );
    case 'codeRefs/RECEIVE_BRANCH_CODE_REFERENCES':
      return state
        .set('collapseAllToggle', true)
        .setIn(['status', action.repoName, action.branch.name], action.branch.generateCollapsedState(false));
    case 'codeRefs/TOGGLE_COLLAPSED':
      return state.updateIn(['status', action.repo, action.branch, action.refIdx], (collapsed) => !collapsed);
    case 'codeRefs/COLLAPSE_ALL':
      const collapseAllToggle = state.get('collapseAllToggle');
      return state
        .update('status', (status) => status.map((repo) => repo.map((branch) => branch.map(() => collapseAllToggle))))
        .set('collapseAllToggle', !collapseAllToggle);
    default:
      return state;
  }
};

// combineReducer
export const codeReferences = combineReducers({
  collapse,
  pagination,
  refs,
  branchRequests,
  repositoryForm,
});

// selectors
export const flagCodeStatisticsSelector = (state: GlobalState) => state.flagCodeStatistics;

export const codeReferencesSelector = (state: GlobalState) => state.codeReferences;

export const repositoryFormSelector = (state: GlobalState) => codeReferencesSelector(state).repositoryForm;

export const flagReferenceCollapseSelector = (state: GlobalState, repo: string, branch: string, idx: number) =>
  codeReferencesSelector(state).collapse.getIn(['status', repo, branch, idx]);

export const flagReferenceCollapseAllToggleSelector = (state: GlobalState) =>
  codeReferencesSelector(state).collapse.get('collapseAllToggle');

export const flagReferencesSelector = (state: GlobalState, flagKey: string) =>
  codeReferencesSelector(state).refs.get(flagKey);

export const flagReferencesFiltersSelector = (state: GlobalState) =>
  createCodeReferenceFiltersFromQuery(querySelector(state));

export const paginationSelector = (state: GlobalState) => codeReferencesSelector(state).pagination;

export const branchRequestsByFlagKeySelector = (state: GlobalState, flagKey: string) =>
  codeReferencesSelector(state).branchRequests.getIn([flagKey, 'repos']);

export const branchRequestSelector = (state: GlobalState, flagKey: string, repo: string, branch: Branch) =>
  codeReferencesSelector(state).branchRequests.getIn([flagKey, 'repos', repo, 'branches', branch]);

registry.addReducers({ codeReferences, flagCodeStatistics });
