import { OrderedMap } from 'immutable';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import { PayloadFiltersAction } from 'actions/payloadFilters';
import { GlobalState } from 'reducers';
import { createRequestReducer } from 'reducers/createRequestReducer';
import paginate from 'reducers/paginate';
import registry from 'reducers/registry';
import { searchSelector } from 'reducers/router';
import { createPayloadFilter, createPayloadFilterFiltersFromQuery, PayloadFilter } from 'utils/payloadFilterUtils';

export function payloadFiltersEntity(
  state: OrderedMap<string, PayloadFilter> = OrderedMap(),
  action: PayloadFiltersAction,
) {
  switch (action.type) {
    case 'payloadFilters/REQUEST_PAYLOAD_FILTERS_DONE':
      return action.response.getIn(['entities', 'payloadFilters']);
    case 'payloadFilters/UPDATE_PAYLOAD_FILTER_DONE':
      return state.set(action.response.key, action.response);
    default:
      return state;
  }
}

const requestFilters = createRequestReducer([
  'payloadFilters/REQUEST_PAYLOAD_FILTERS',
  'payloadFilters/REQUEST_PAYLOAD_FILTERS_DONE',
  'payloadFilters/REQUEST_PAYLOAD_FILTERS_FAILED',
]);

export function payloadFilterEntity(state: PayloadFilter = createPayloadFilter(), action: PayloadFiltersAction) {
  switch (action.type) {
    case 'payloadFilters/REQUEST_PAYLOAD_FILTER_DONE':
    case 'payloadFilters/UPDATE_PAYLOAD_FILTER_DONE':
      return action.response;
    default:
      return state;
  }
}
const requestFilter = createRequestReducer([
  'payloadFilters/REQUEST_PAYLOAD_FILTER',
  'payloadFilters/REQUEST_PAYLOAD_FILTER_DONE',
  'payloadFilters/REQUEST_PAYLOAD_FILTER_FAILED',
]);

const payloadFiltersPagination = paginate({
  types: [
    'payloadFilters/REQUEST_PAYLOAD_FILTERS',
    'payloadFilters/REQUEST_PAYLOAD_FILTERS_FAILED',
    'payloadFilters/REQUEST_PAYLOAD_FILTERS_DONE',
  ],
  invalidateTypes: ['payloadFilters/CREATE_PAYLOAD_FILTER_DONE', 'payloadFilters/UPDATE_PAYLOAD_FILTER_DONE'],
  mapActionToKey: () => 'all',
});

export const payloadFilters = combineReducers({
  payloadFiltersEntity,
  requestFilters,
  payloadFilterEntity,
  requestFilter,
  pagination: payloadFiltersPagination,
});

export const payloadFiltersSelector = (state: GlobalState) => state.payloadFilters.payloadFiltersEntity;
export const payloadFiltersRequestSelector = (state: GlobalState) => state.payloadFilters.requestFilters;
export const payloadFiltersPaginationSelector = (state: GlobalState) => state.payloadFilters.pagination.get('all');

export const payloadFilterSelector = (state: GlobalState) => state.payloadFilters.payloadFilterEntity;
export const payloadFilterRequestSelector = (state: GlobalState) => state.payloadFilters.requestFilter;

export const payloadFiltersFilterSelector = createSelector(searchSelector, (query) =>
  createPayloadFilterFiltersFromQuery(query),
);

registry.addReducers({ payloadFilters });
