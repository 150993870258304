import React from 'react';
import { enableReleaseGuardianRefreshedUI } from '@gonfalon/dogfood-flags';
import { unitsToIncludeInTimeDisplay } from '@gonfalon/measured-rollouts';
import { formatDuration, intervalToDuration } from 'date-fns';
import { isImmutable } from 'immutable';

import { Cell, Grid, GridLayoutValue } from 'components/ui/grid';
import Variation from 'components/Variation';
import { MeasuredRolloutSemanticInstructionV2 } from 'utils/instructions/shared/types';

import styles from './MeasuredRolloutRuleInstructionDescription.module.css';

interface Props {
  trafficAllocation?: number;
  ruleName: string;
  monitoringWindowMilliseconds?: number;
  randomizationUnit?: string;
  rollback?: boolean;
  semanticInstruction?: MeasuredRolloutSemanticInstructionV2;
  variationName: string;
  variationColor: string;
}

export const MeasuredRolloutRuleInstructionDescription = ({
  trafficAllocation = 0,
  ruleName,
  monitoringWindowMilliseconds = 0,
  randomizationUnit,
  rollback,
  semanticInstruction,
  variationName,
  variationColor,
}: Props) => {
  const duration = intervalToDuration({
    start: 0,
    end: monitoringWindowMilliseconds,
  });

  const formattedDuration =
    formatDuration(duration, {
      delimiter: ', ',
      format: unitsToIncludeInTimeDisplay(duration),
    }) || '0 seconds';

  if (enableReleaseGuardianRefreshedUI()) {
    const GridRow = ({ label, value }: { label: string; value: React.ReactNode }) => (
      <Grid layout={GridLayoutValue.ONE_OF_TWO} gap="2">
        <Cell>{label}:</Cell>
        <Cell>{value}</Cell>
      </Grid>
    );

    const instructions = isImmutable(semanticInstruction?.metrics)
      ? semanticInstruction?.metrics?.toJS()
      : semanticInstruction?.metrics;

    return (
      <Grid direction="column" gap="1">
        <GridRow label="Target by" value={<code>{randomizationUnit}</code>} />
        <GridRow label="Rollout duration" value={<strong>{formattedDuration}</strong>} />
        {instructions &&
          instructions?.length > 0 &&
          instructions.map((metric, index) => (
            <GridRow
              key={index}
              label={metric.metricKey}
              value={
                <>
                  {metric.regressionThreshold ? (
                    <span>
                      <strong>{metric.regressionThreshold * 100}%</strong> threshold,&nbsp;
                    </span>
                  ) : (
                    ''
                  )}
                  auto rollback <strong>{metric.onRegression?.rollback ? 'on' : 'off'}</strong>
                </>
              }
            />
          ))}
      </Grid>
    );
  } else {
    return (
      <div>
        <p>
          Serve{' '}
          <span className={styles.variationWrapper}>
            <Variation
              value={variationName}
              color={variationColor}
              className={styles.measuredRolloutInstructionVariation}
            />
          </span>
          to <strong>{trafficAllocation / 1000}%</strong> of <strong>{ruleName}</strong> traffic
        </p>
        {randomizationUnit && (
          <p>
            Allocate traffic by <code>{randomizationUnit}</code>
          </p>
        )}
        <p>
          Monitor release metrics for <strong>{formattedDuration}</strong>
        </p>
        {rollback && <p>Automatically roll back if a regression is detected</p>}
      </div>
    );
  }
};
