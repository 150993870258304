const userAgent = window.navigator.userAgent;
const platform = window.navigator.platform;

const isIEUpTo10 = /MSIE \d/.test(userAgent);
const isIE11Up = /Trident\/(?:[7-9]|\d{2,})\..*rv:(\d+)/.exec(userAgent);
const isEdge = /Edge\/(\d+)/.exec(userAgent);
export const isIE = isIEUpTo10 || isIE11Up || isEdge;
export const isWebkit = !isEdge && /WebKit\//.test(userAgent);
export const isChrome = !isEdge && /Chrome\//.test(userAgent);
export const isFirefox = !isEdge && /Firefox\//.test(userAgent);
export const isSafari = /Apple Computer/.test(navigator.vendor);
export const phantom = /PhantomJS/.test(userAgent);

export const isIos = !isEdge && /AppleWebKit/.test(userAgent) && /Mobile\/\w+/.test(userAgent);
export const isAndroid = /Android/.test(userAgent);
export const isMac = isIos || /Mac/.test(platform);
export const isChromeOS = /\bCrOS\b/.test(userAgent);
export const isWindows = /win/i.test(platform);
