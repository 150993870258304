import { Modal, ModalOverlay } from '@launchpad-ui/components';

import { ShortcutsDialog } from '../internal';
import { Shortcut } from '../types';

import styles from './ShortcutsModal.module.css';

export function ShortcutsModal({ shortcutType }: { shortcutType: Shortcut['type'] }) {
  return (
    <ModalOverlay>
      <Modal className={styles.modal}>
        <ShortcutsDialog shortcutType={shortcutType} />
      </Modal>
    </ModalOverlay>
  );
}
