import { Map } from 'immutable';
import { AnyAction } from 'redux';

import actionTypes from 'actionTypes/policies';
import { GlobalState } from 'reducers';
import registry from 'reducers/registry';
import { ImmutableMap } from 'utils/immutableUtils';
import { PolicyActions } from 'utils/policyUtils';

export type PoliciesState = ImmutableMap<{
  lastFetched?: number;
  isFetching: boolean;
  entities: PolicyActions;
  error: Error;
}>;

export function policyActions(
  state: PoliciesState = Map({
    entities: {},
    lastFetched: null,
    isFetching: false,
    error: null,
  }),
  action: AnyAction,
) {
  switch (action.type) {
    case actionTypes.REQUEST_POLICY_ACTIONS:
      return state.set('isFetching', true);
    case actionTypes.REQUEST_POLICY_ACTIONS_FAILED:
      return state.merge({
        isFetching: false,
        error: action.error,
      });
    case actionTypes.REQUEST_POLICY_ACTIONS_DONE:
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        entities: action.response.getIn(['entities', 'policyActions']),
      });
    default:
      return state;
  }
}

export const policyActionsSelector = (state: GlobalState) => state.policyActions;

registry.addReducers({ policyActions });
