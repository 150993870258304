import { useEffect, useRef } from 'react';
import type { HotkeysEvent, KeyHandler } from 'hotkeys-js';
import hotkeys from 'hotkeys-js';

export function useHotkey(hotkey: string, callback: KeyHandler) {
  const savedCallback = useRef<KeyHandler | null>(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function handler(keyboardEvent: KeyboardEvent, hotkeysEvent: HotkeysEvent) {
      savedCallback.current && savedCallback.current(keyboardEvent, hotkeysEvent);
    }

    hotkeys(hotkey, handler);

    return () => hotkeys.unbind(hotkey, handler);
  }, [hotkey, callback]);
}
