import { useMemo } from 'react';
import { useFollowPreferences } from '@gonfalon/rest-api';

const FIVE_MINUTES = 1000 * 60 * 5;

/**
 * Load favorite environment IDs for a project.
 */
export function useFavoriteEnvironmentIds() {
  const query = useFollowPreferences({}, { staleTime: FIVE_MINUTES });

  const ids = useMemo(() => {
    const inner = new Set<string>();

    const resources = query.data?.resources ?? [];

    if (resources.length === 0) {
      return [];
    }

    for (const resource of resources) {
      if (resource.resourceKind === 'environment' && resource.id !== undefined) {
        inner.add(resource.id);
      }
    }

    return Array.from(inner);
  }, [query.data]);

  return ids;
}
