import { EnvironmentMarker } from 'components/EnvironmentMarker';
import withProjectAndEnvironment from 'components/withProjectAndEnvironment';
import { Environment } from 'utils/environmentUtils';

type CurrentEnvironmentProps = {
  environment?: Environment;
  className?: string;
  omitName?: boolean;
};

function CurrentEnvironment({ environment, className, omitName }: CurrentEnvironmentProps) {
  return environment ? <EnvironmentMarker className={className} env={environment} omitName={omitName} /> : null;
}

/* eslint-disable import/no-default-export */
export default withProjectAndEnvironment(CurrentEnvironment);
