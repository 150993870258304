import { List } from 'immutable';

import { Clause as ImmutableClause } from 'utils/clauseUtils';
import { ExperimentRollout, Rollout, Variation } from 'utils/flagUtils';

import {
  AddClausesSemanticInstruction,
  AddValuesToClauseSemanticInstruction,
  ClauseInstructionKind,
  RemoveClausesSemanticInstruction,
  RemoveValuesFromClauseSemanticInstruction,
  UpdateClauseSemanticInstruction,
} from '../clauses/types';
import {
  MeasuredRolloutSemanticInstruction,
  SemanticInstructionForKind,
  SemanticInstructionProgressiveRolloutConfiguration,
} from '../shared/types';

export enum RuleInstructionKind {
  ADD_RULE = 'addRule',
  ADD_RULE_WITH_MEASURED_ROLLOUT = 'addRuleWithMeasuredRollout',
  ADD_RULE_WITH_MEASURED_ROLLOUT_V2 = 'addRuleWithMeasuredRolloutV2',
  REMOVE_RULE = 'removeRule',
  REORDER_RULES = 'reorderRules',
  REPLACE_RULES = 'replaceRules',
  UPDATE_RULE_VARIATION_OR_ROLLOUT = 'updateRuleVariationOrRollout',
  UPDATE_RULE_WITH_MEASURED_ROLLOUT = 'updateRuleWithMeasuredRollout',
  UPDATE_RULE_WITH_MEASURED_ROLLOUT_V2 = 'updateRuleWithMeasuredRolloutV2',
  UPDATE_RULE_DESCRIPTION = 'updateRuleDescription',
  STOP_MEASURED_ROLLOUT_ON_RULE = 'stopMeasuredRolloutOnRule',
}

/* Aggregate rule groups all of the different instructions that might exist on a single rule.
   These instructions include:
     - remove clauses
     - add clauses
     - update clause
     - update rule variation or rollout
     - add rule
*/
export type AggregateRule = {
  _id?: string;
  _key?: string;
  clauses: Array<ImmutableClause | null>;
  clause?: ImmutableClause | null;
  variationId?: string;
  trackEvents: boolean;
  rolloutWeights?: { [key: string]: number };
  rolloutBucketBy?: string;
  rolloutContextKind?: string;
  experimentAllocation?: ExperimentRollout;
  description?: string;
  clauseId?: string;
  kind?: string;
  ruleId: string;
};

export type AddRuleSemanticInstruction = AggregateRule & {
  kind: RuleInstructionKind.ADD_RULE;
  beforeRuleId?: string;
  ruleId: string;
  ref: string;
  progressiveRolloutConfiguration?: never;
};

export type AddRuleWithMeasuredRolloutSemanticInstruction = Omit<
  AggregateRule,
  'variationId' | 'rolloutWeights' | 'rolloutBucketBy' | 'rolloutContextKind' | 'experimentAllocation'
> &
  MeasuredRolloutSemanticInstruction & {
    kind: RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT;
    beforeRuleId?: string;
    ruleId: string;
    ref: string;
  };

export type AddRuleWithMeasuredRolloutV2SemanticInstruction = Omit<
  AggregateRule,
  'variationId' | 'rolloutWeights' | 'rolloutBucketBy' | 'rolloutContextKind' | 'experimentAllocation'
> &
  MeasuredRolloutSemanticInstruction & {
    kind: RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT_V2;
    beforeRuleId?: string;
    ruleId: string;
    ref: string;
  };

export type AddRuleWithProgressiveRolloutSemanticInstruction = Omit<
  AddRuleSemanticInstruction,
  'progressiveRolloutConfiguration'
> & {
  progressiveRolloutConfiguration: SemanticInstructionProgressiveRolloutConfiguration;
  rolloutContextKind: string;
  clause?: never;
  variationId?: never;
  rolloutWeights?: never;
  rolloutBucketBy?: never;
  experimentAllocation?: never;
  clauseId?: never;
};

export type RemoveRuleSemanticInstruction = {
  kind: RuleInstructionKind.REMOVE_RULE;
  ruleId: string;
};

export type ReorderRulesSemanticInstruction = {
  kind: RuleInstructionKind.REORDER_RULES;
  ruleIds: string[];
};

export type ReplaceRulesSemanticInstruction = {
  kind: RuleInstructionKind.REPLACE_RULES;
  rules: AggregateRule[];
};

export type UpdateRuleVariationOrRolloutSemanticInstruction = {
  kind: RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT;
  contextKind?: string;
  ruleId?: string;
  ref?: string;
  rolloutWeights?: { [key: string]: number };
  rolloutBucketBy?: string;
  rolloutContextKind?: string;
  variationId?: string;
  experimentAllocation?: ExperimentRollout;
  progressiveRolloutConfiguration?: never;
};

export type StopMeasuredRolloutOnRule = {
  kind: RuleInstructionKind.STOP_MEASURED_ROLLOUT_ON_RULE;
  ruleId: string;
  finalVariationId: string;
  comment: string;
};

export type UpdateRuleWithMeasuredRolloutSemanticInstruction = MeasuredRolloutSemanticInstruction & {
  kind: RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT;
  ruleId: string;
};

export type UpdateRuleWithMeasuredRolloutV2SemanticInstruction = MeasuredRolloutSemanticInstruction & {
  kind: RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT_V2;
  ruleId: string;
};

export type UpdateRuleWithProgressiveRolloutSemanticInstruction = {
  kind: RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT;
  progressiveRolloutConfiguration: SemanticInstructionProgressiveRolloutConfiguration;
  rolloutContextKind: string;
  contextKind?: never;
  rolloutWeights?: never;
  rolloutBucketBy?: never;
  variationId?: never;
  experimentAllocation?: never;
} & ({ ruleId: string; ref?: never } | { ref: string; ruleId?: never });

export type SemanticRuleDescriptionInstruction = {
  kind: RuleInstructionKind.UPDATE_RULE_DESCRIPTION;
  ruleId: string;
  description: string;
};

export type SemanticRuleInstructionType =
  | AddRuleSemanticInstruction
  | AddRuleWithMeasuredRolloutSemanticInstruction
  | AddRuleWithMeasuredRolloutV2SemanticInstruction
  | AddRuleWithProgressiveRolloutSemanticInstruction
  | RemoveRuleSemanticInstruction // x
  | ReorderRulesSemanticInstruction // x
  | AddClausesSemanticInstruction
  | RemoveClausesSemanticInstruction // x
  | UpdateClauseSemanticInstruction
  | AddValuesToClauseSemanticInstruction
  | RemoveValuesFromClauseSemanticInstruction
  | UpdateRuleVariationOrRolloutSemanticInstruction
  | UpdateRuleWithMeasuredRolloutSemanticInstruction
  | UpdateRuleWithMeasuredRolloutV2SemanticInstruction
  | UpdateRuleWithProgressiveRolloutSemanticInstruction
  | StopMeasuredRolloutOnRule
  | SemanticRuleDescriptionInstruction;

export type SemanticInstructionRolloutWeights = { [s: string]: number };
export type SemanticInstructionRollout = {
  contextKind: string | undefined;
  bucketBy: string | undefined;
  weights: SemanticInstructionRolloutWeights;
  experimentAllocation: ExperimentRollout | undefined;
};

export function convertRolloutToSemanticInstructionRollout(
  variations: List<Variation>,
  rollout?: Rollout,
): SemanticInstructionRollout {
  const weights: SemanticInstructionRolloutWeights = {};
  rollout?.get('variations').forEach((weightedVariation) => {
    if (!weightedVariation._untracked) {
      const index = weightedVariation.get('variation');
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      const id = variations.get(index)!._id; /* eslint-enable @typescript-eslint/no-non-null-assertion */
      weights[id] = weightedVariation.get('weight');
    }
  });

  return {
    experimentAllocation: rollout?.experimentAllocation,
    bucketBy: rollout?.bucketBy,
    contextKind: rollout?.contextKind,
    weights,
  };
}

export type DiscardableCustomRuleInstruction = SemanticInstructionForKind<
  | RuleInstructionKind.ADD_RULE
  | RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT
  | RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT_V2
  | RuleInstructionKind.UPDATE_RULE_DESCRIPTION
  | RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT
  | RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT
  | RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT_V2
  | RuleInstructionKind.REORDER_RULES
  | ClauseInstructionKind.ADD_CLAUSES
  | ClauseInstructionKind.ADD_VALUES_TO_CLAUSE
  | ClauseInstructionKind.REMOVE_CLAUSES
  | ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE
  | ClauseInstructionKind.UPDATE_CLAUSE
>;
