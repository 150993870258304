import { useMemo } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-aria-components';
import { Diff, JsonSnippet } from '@gonfalon/launchpad-experimental';
import { useSuspenseAuditLogEntry } from '@gonfalon/rest-api';
import clsx from 'clsx';

import { hasDiff } from '../hasDiff';
import { hasMerge } from '../hasMerge';
import { hasPatch } from '../hasPatch';
import { hasTriggerBody } from '../hasTriggerBody';
import { isCreation } from '../isCreation';
import { isDeletion } from '../isDeletion';

import styles from './AuditLogEntryDetails.module.css';

export function AuditLogEntryDetails({ id, compact }: { id: string; compact?: boolean }) {
  const { data } = useSuspenseAuditLogEntry({ id });

  const tabs = useMemo(() => {
    const tabs_ = [];

    if (Boolean(isCreation(data) && data.currentVersion)) {
      tabs_.push({
        name: 'Current version',
        id: 'current',
        json: [data?.currentVersion],
      });
    }

    if (Boolean(isDeletion(data) && data.previousVersion && !data.currentVersion)) {
      tabs_.push({
        name: 'Previous version',
        id: 'previous',
        json: [data?.previousVersion],
      });
    }

    if (hasPatch(data)) {
      tabs_.push({
        name: 'Patch',
        id: 'patch',
        json: [data?.delta],
      });
    }

    if (hasMerge(data)) {
      tabs_.push({
        name: 'Merge',
        id: 'merge',
        json: [data?.merge],
      });
    }

    if (hasDiff(data)) {
      tabs_.push({
        name: 'Diff',
        id: 'diff',
        json: [data?.previousVersion, data?.currentVersion],
      });
    }

    if (hasTriggerBody(data)) {
      tabs_.push({
        name: 'Trigger body',
        id: 'triggerBody',
        json: [data?.triggerBody],
      });
    }

    return tabs_;
  }, [data]);

  return (
    <Tabs className={clsx(styles.tabs, { [styles.compact]: compact })}>
      <TabList className={styles.tabList} aria-label="Audit log entry details">
        {tabs.map((tab) => (
          <Tab className={styles.tab} id={tab.id} key={tab.id}>
            {tab.name}
          </Tab>
        ))}
      </TabList>
      {tabs.map((tab) => (
        <TabPanel key={tab.id} className={styles.tabPanel} id={tab.id}>
          {tab.json.length === 2 && (
            <Diff
              onlyShowDifferences
              comparison="json"
              a={tab.json[0] as Object}
              b={tab.json[1] as Object}
              hasBorders={false}
            />
          )}
          {tab.json.length === 1 && <JsonSnippet object={tab.json[0] as Object} withCopyButton />}
        </TabPanel>
      ))}
    </Tabs>
  );
}
