import { Draft } from 'immer';
// eslint-disable-next-line no-restricted-imports
import { fromJS, getIn, List, Map } from 'immutable';
import { isPlainObject } from 'lodash';
import { useWorkflowBuilderTemplateReport } from 'queries/workflowTemplateQueries';

import { CustomWorkflowType } from 'components/CustomWorkflows/types';
import { Clause as ImmutableClause } from 'utils/clauseUtils';
import {
  createCustomWorkflowApprovalCondition,
  createCustomWorkflowScheduleCondition,
  CustomWorkflowConditionKindType,
  CustomWorkflowDefaultCondition,
} from 'utils/customWorkflowUtils';
import { RuleInstructionKind } from 'utils/instructions/rules/types';
import { makeSemanticInstruction } from 'utils/instructions/shared/helpers';
import { TargetsInstructionKind } from 'utils/instructions/targets/types';
import { ScheduleKind } from 'utils/scheduledChangesUtils';
import { WorkflowTemplateReport, WorkflowTemplateStage } from 'utils/workflowTemplateUtils';

import {
  createWorkflowBuilderStep,
  useWorkflowBuilderState,
  WorkflowBuilderCondition,
  WorkflowBuilderInstruction,
  WorkflowBuilderInstructionsContainerKind,
  WorkflowBuilderStateType,
  WorkflowBuilderStep,
  WorkflowBuilderUpdateTargetsSemanticInstructionsContainer,
} from '../utils';

export function convertWorkflowTemplateReportToWorkflowBuilderState({
  name,
  description,
  workflowTemplateReport,
}: {
  name: string;
  description?: string;
  workflowTemplateReport: WorkflowTemplateReport;
}): WorkflowBuilderStateType {
  return {
    name,
    description,
    stages: workflowTemplateReport.stages.map((stage) => {
      const steps: WorkflowBuilderStep[] = [];

      stage.conditions.forEach((templateCondition) => {
        let condition: WorkflowBuilderCondition;

        if (templateCondition.kind === CustomWorkflowConditionKindType.APPROVAL) {
          condition = createCustomWorkflowApprovalCondition(fromJS(templateCondition));
        } else if (templateCondition.kind === CustomWorkflowConditionKindType.SCHEDULE) {
          condition = createCustomWorkflowScheduleCondition(templateCondition);
        } else {
          condition = new CustomWorkflowDefaultCondition();
        }

        steps.push(createWorkflowBuilderStep(condition));
      });

      let addTargetsInstructionContainer = new WorkflowBuilderUpdateTargetsSemanticInstructionsContainer();
      let addTargetsInstructionContainerIndex = -1;

      stage.action.instructions.forEach((ins, index) => {
        // we need to collate all "addTargets" instructions underneath a single "container" step
        if (ins.kind === TargetsInstructionKind.ADD_TARGETS) {
          // if this is the first "addTargets" instruction, note its index in the stage,
          // as we will insert the container at this index
          if (addTargetsInstructionContainerIndex === -1) {
            addTargetsInstructionContainerIndex = stage.conditions.length + index;
          }

          // add this instruction the list of instructions within the container,
          // but only it has a variationId, because or else the instruction will not be completable via the UI,
          // as we only show form fields for targets for variations the target flag actually has
          if (ins.contextKind && ins.variationId && ins.values.length > 0) {
            addTargetsInstructionContainer = addTargetsInstructionContainer.update('instructions', (instructions) =>
              instructions.push(ins as Draft<typeof ins>),
            );
          }
          // or else, add the instruction to the array of steps as usual
        } else {
          steps.push({
            ...createWorkflowBuilderStep(makeSemanticInstruction(ins.kind, fromJS(ins)) as WorkflowBuilderInstruction),
            key: ins._id,
          });
        }
      });

      if (addTargetsInstructionContainerIndex !== -1) {
        steps.splice(addTargetsInstructionContainerIndex, 0, createWorkflowBuilderStep(addTargetsInstructionContainer));
      }

      return {
        key: stage._id,
        name: stage.name,
        steps,
      };
    }),
    templateKey: workflowTemplateReport.templateKey,
    type: CustomWorkflowType.TEMPLATE,
  };
}

export function useIsWorkflowBuilderTemplateField(
  props:
    | {
        stepKey: string;
        fieldPath: string;
      }
    | {
        stepKey: string;
        matchesFieldPath: (path: string) => boolean;
      },
) {
  const { data: templateReport } = useWorkflowBuilderTemplateReport();

  return (
    !!templateReport &&
    templateReport.meta.parameters.some(
      (parameter) =>
        parameter._id === props.stepKey &&
        ('fieldPath' in props ? parameter.path === props.fieldPath : props.matchesFieldPath(parameter.path)),
    )
  );
}

export function useWorkflowTemplateNameAndDescription() {
  const { data: templateReport } = useWorkflowBuilderTemplateReport();
  if (!templateReport) {
    return null;
  }
  return { name: templateReport.name, description: templateReport.description };
}

// returns true if any step in any stage has a template parameter whose value (via "path") is nullish (or an empty list/array, or rollout weights don't equal 100%, etc)
// for certain cases, the template parameter becomes irrelevant and we ignore it
export function useDoesWorkflowHaveEmptyTemplateField() {
  const { data: templateReport } = useWorkflowBuilderTemplateReport();
  return useWorkflowBuilderState((state) => {
    // if there is no template report, bail immediately
    if (!templateReport) {
      return false;
    }

    for (const stage of state.stages) {
      for (const step of stage.steps) {
        if (
          step.content.kind === WorkflowBuilderInstructionsContainerKind.ADD_TARGETS_STEP_INSTRUCTIONS_CONTAINER &&
          step.content.instructions.size === 0 &&
          templateReport.stages.some(templateStageHasContextTargets({ stageKey: stage.key }))
        ) {
          return true;
        }

        const emptyTemplateParameter = templateReport.meta.parameters.find((parameter) => {
          // if the parameter does not pertain to this workflow step, skip it
          if (parameter._id !== step.key) {
            return false;
          }

          const path = parameter.path.slice(1).split('/');
          const key = path[path.length - 1];
          const value = getIn(step.content, path, '');

          // if this is for the values of a rule clause, only check if the values are empty if the clause is the same as the parameter
          if (step.content.kind === RuleInstructionKind.ADD_RULE && path[0] === 'clauses' && key === 'values') {
            // @ts-expect-error "k" is not an index type here, but it's ok
            const clause = path.slice(0, 2).reduce((result, k) => result[k], step.content) as
              | ImmutableClause
              | undefined;

            if (
              clause &&
              clause.op === parameter.default.ruleClause?.op &&
              clause.attribute === parameter.default.ruleClause?.attribute &&
              clause.negate === parameter.default.ruleClause?.negate
            ) {
              return List.isList(value) && value.size === 0;
            } else {
              return false;
            }
          }

          // if rule variationId is a parameter, but the user has selected to serve a percentage rollout,
          // this should not count as an empty template field
          if (
            step.content.kind === RuleInstructionKind.ADD_RULE &&
            key === 'variationId' &&
            step.content.rolloutWeights
          ) {
            return false;
          }

          // if a schedule was an absolute date in the template, but is now a relative schedule,
          // it should not count as an empty template field
          if (
            step.content.kind === CustomWorkflowConditionKindType.SCHEDULE &&
            key === 'executionDate' &&
            step.content.scheduleKind === ScheduleKind.RELATIVE
          ) {
            return false;
          }

          // array is empty
          if (Array.isArray(value)) {
            return value.length === 0;
          }

          // Immutable.js List is empty
          if (List.isList(value)) {
            return value.size === 0;
          }

          // Immutable.js rollout weights are incomplete (set rule rollout)
          if (Map.isMap(value) && key === 'rolloutWeights') {
            return [...(value as Map<string, number>).values()].reduce((sum, weight) => sum + weight, 0) !== 100000;
          }

          // JS rollout weights are incomplete (set rule rollout)
          if (isPlainObject(value) && key === 'rolloutWeights') {
            return (
              Object.values(value as { [key: string]: number }).reduce((sum, weight) => sum + weight, 0) !== 100000
            );
          }

          return !value;
        });

        // if we found an empty template parameter, we're done, so return true and break out of the loops
        if (emptyTemplateParameter) {
          return true;
        }
      }
    }

    return false;
  });
}

export function useIsWorkflowFromTemplate() {
  return !!useWorkflowBuilderTemplateReport().data;
}

export const templateStageHasContextTargets =
  ({ stageKey }: { stageKey: string }) =>
  (templateStage: WorkflowTemplateStage) =>
    templateStage._id === stageKey &&
    templateStage.action.instructions.some((ins) => ins.kind === TargetsInstructionKind.ADD_TARGETS);

export function useDoesTemplateStageHaveContextTargets({ stageKey }: { stageKey: string }) {
  const { data: templateReport } = useWorkflowBuilderTemplateReport();
  return !!templateReport?.stages.some(templateStageHasContextTargets({ stageKey }));
}
