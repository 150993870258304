import { z } from 'zod';

export const schema = z.object({
  showAllActions: z.boolean().optional(),
});

export type ChangeHistoryPreferences = z.infer<typeof schema>;

export const changeHistoryItemSchema = schema.keyof();

export type ChangeHistoryItem = z.infer<typeof changeHistoryItemSchema>;

export const defaultPreferences: ChangeHistoryPreferences = {
  showAllActions: false,
};
