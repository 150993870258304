import nullthrows from 'nullthrows';

import { getRemainingSeats as getLegacySusbcriptionRemainingSeats } from '../internal/legacySubscription';
import { getRemainingSeats as getModernSubscriptionRemainingSeats } from '../internal/modernSubscription';
import { Subscription, SubscriptionUsage } from '../types';

import { isModernSubscription } from './isModernSubscription';

export function getRemainingSeats(subscription: Subscription, subscriptionUsage?: SubscriptionUsage) {
  if (isModernSubscription(subscription)) {
    return getModernSubscriptionRemainingSeats(
      subscription,
      nullthrows(subscriptionUsage, 'subscriptionUsage is required for modern subscriptions'),
    );
  }

  return getLegacySusbcriptionRemainingSeats(subscription);
}
