import { createConstantNamespace } from 'utils/reduxUtils';

const ns = createConstantNamespace('flagLinks');

/* eslint-disable import/no-default-export */
export default {
  REQUEST_FLAG_LINKS: ns('REQUEST_FLAG_LINKS'),
  REQUEST_FLAG_LINKS_DONE: ns('REQUEST_FLAG_LINKS_DONE'),
  REQUEST_FLAG_LINKS_FAILED: ns('REQUEST_FLAG_LINKS_FAILED'),
  CREATE_FLAG_LINK: ns('CREATE_FLAG_LINK'),
  CREATE_FLAG_LINK_DONE: ns('CREATE_FLAG_LINK_DONE'),
  CREATE_FLAG_LINK_FAILED: ns('CREATE_FLAG_LINK_FAILED'),
  DELETE_FLAG_LINK: ns('DELETE_FLAG_LINK'),
  DELETE_ALL_FLAG_LINKS: ns('DELETE_ALL_FLAG_LINKS'),
  DELETE_FLAG_LINK_DONE: ns('DELETE_FLAG_LINK_DONE'),
  DELETE_FLAG_LINK_FAILED: ns('DELETE_FLAG_LINK_FAILED'),
};
