import { constants } from 'utils/reduxUtils';

/* eslint-disable import/no-default-export */
export default constants('members', [
  'REQUEST_MEMBERS',
  'REQUEST_MEMBERS_FAILED',
  'RECEIVE_MEMBERS',
  'REQUEST_MEMBER',
  'REQUEST_MEMBER_FAILED',
  'RECEIVE_MEMBER',
  'REQUEST_MEMBERS_BY_TEAM',
  'REQUEST_MEMBERS_BY_TEAM_FAILED',
  'RECEIVE_MEMBERS_BY_TEAM',
  'DELETE_MEMBER',
  'DELETE_MEMBER_DONE',
  'DELETE_MEMBER_FAILED',
  'UPDATE_MEMBER',
  'UPDATE_MEMBER_DONE',
  'UPDATE_MEMBER_FAILED',
  'BULK_REPLACE_MEMBERS_ROLES',
  'BULK_REPLACE_MEMBERS_ROLES_DONE',
  'BULK_REPLACE_MEMBERS_ROLES_FAILED',
  'INVITE_MEMBERS',
  'INVITE_MEMBERS_BATCH_DONE',
  'INVITE_MEMBERS_BATCH_FAILED',
  'INVITE_MEMBERS_DONE',
  'INVITE_MEMBERS_FAILED',
  'BULK_INVITE_MEMBERS_FAILED',
  'EDIT_INVITE',
  'EDIT_BULK_INVITE',
  'EDIT_MEMBER',
  'FILTER_BY_TEXT',
  'RESEND_INVITE',
  'RESEND_INVITE_DONE',
  'RESEND_INVITE_FAILED',
  'SEND_MFA_ENABLE_REQUEST',
  'SEND_MFA_ENABLE_REQUEST_DONE',
  'SEND_MFA_ENABLE_REQUEST_FAILED',
  'SEND_MFA_RECOVERY_CODE',
  'SEND_MFA_RECOVERY_CODE_DONE',
  'SEND_MFA_RECOVERY_CODE_FAILED',
  'EDIT_NEW_TEAM_INVITE_REQUEST',
  'REQUEST_TEAM_INVITE',
  'REQUEST_TEAM_INVITE_FAILED',
  'REQUEST_TEAM_INVITE_DONE',
  'ADD_MEMBER_TO_TEAMS',
  'ADD_MEMBER_TO_TEAMS_DONE',
  'ADD_MEMBER_TO_TEAMS_FAILED',
  'BULK_ADD_MEMBERS_TO_TEAMS',
  'BULK_ADD_MEMBERS_TO_TEAMS_DONE',
  'BULK_ADD_MEMBERS_TO_TEAMS_FAILED',
]);
