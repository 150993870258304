// eslint-disable-next-line no-restricted-imports
import { useSelector } from 'react-redux';
import { enableSelfServeUBBWithAnnualCommits, enableSelfServeUsageBasedBilling } from '@gonfalon/dogfood-flags';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map } from 'immutable';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import { BillingAction } from 'actions/billing';
import { GlobalState } from 'reducers';
import { BulkMemberInvite, Member } from 'utils/accountUtils';
import {
  billingIntervals,
  createLegacySubscription,
  createLimitPlans,
  createPlanLimits,
  createPlanList,
  createSubscription,
  createSubscriptionChangeFromSelection,
  createSubscriptionPrice,
  createSubscriptionUsage,
  findSelectedPlan,
  LegacyPlan,
  LegacySubscription,
  limitNames,
  LimitPlans,
  PaymentCard,
  PlanLimits,
  PlanList,
  planTypes,
  Subscription,
  SubscriptionPrice,
} from 'utils/billingUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import { createImmutableState, ImmutableMap } from 'utils/immutableUtils';

import { createRequestReducer } from './createRequestReducer';
import registry from './registry';

type MemberAction =
  | { type: 'members/INVITE_MEMBERS_DONE'; invites: BulkMemberInvite }
  | { type: 'members/DELETE_MEMBER_DONE'; member: Member };

type LegacyPlansState = ImmutableMap<{
  entities: List<LegacyPlan>;
  lastFetched: number | null;
  isFetching: boolean;
}>;

export function legacyPlans(
  state: LegacyPlansState = createImmutableState({
    entities: List(),
    lastFetched: null,
    isFetching: false,
  }),
  action: BillingAction,
) {
  switch (action.type) {
    case 'billing/REQUEST_LEGACY_PLANS':
      return state.set('isFetching', true);
    case 'billing/REQUEST_LEGACY_PLANS_FAILED':
      return state.set('isFetching', false);
    case 'billing/REQUEST_LEGACY_PLANS_DONE':
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        entities: action.legacyPlans,
      });
    default:
      return state;
  }
}

export const plans = combineReducers({
  request: createRequestReducer([
    'billing/REQUEST_PLANS',
    'billing/REQUEST_PLANS_DONE',
    'billing/REQUEST_PLANS_FAILED',
  ]),
  entities: (state: PlanList = createPlanList(), action: BillingAction) => {
    switch (action.type) {
      case 'billing/REQUEST_PLANS_DONE':
        return action.plans;
      default:
        return state;
    }
  },
});

export const limitPlans = combineReducers({
  request: createRequestReducer([
    'billing/REQUEST_LIMIT_PLANS',
    'billing/REQUEST_LIMIT_PLANS_DONE',
    'billing/REQUEST_LIMIT_PLANS_FAILED',
  ]),
  entities: (state: LimitPlans = createLimitPlans(), action: BillingAction) => {
    switch (action.type) {
      case 'billing/RESET_SELECTION':
        return createLimitPlans();
      case 'billing/REQUEST_LIMIT_PLANS_DONE':
        return action.limitPlans;
      default:
        return state;
    }
  },
});

export function selectedLimitPlans(state: PlanLimits = createPlanLimits(), action: BillingAction) {
  switch (action.type) {
    case 'billing/RESET_SELECTION':
    case 'billing/RESET_LIMITS':
      return createPlanLimits();
    case 'billing/CHANGE_PLAN_LIMIT':
      return state.set(action.key, action.value);
    default:
      return state;
  }
}

export const selectedBillingPeriod = (state: billingIntervals | null = null, action: BillingAction) => {
  switch (action.type) {
    case 'billing/RESET_SELECTION':
      return null;
    case 'billing/SET_BILLING_PERIOD':
      return action.billingPeriod;
    default:
      return state;
  }
};

export function activePlan(state: planTypes | null = null, action: BillingAction) {
  switch (action.type) {
    case 'billing/CHANGE_ACTIVE_PLAN':
      return action.activePlan;
    case 'billing/RESET_SELECTION':
      return null;
    default:
      return state;
  }
}

const paymentCardEntity = (state: PaymentCard | null = null, action: BillingAction) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case 'billing/REQUEST_PAYMENT_CARD_DONE':
    case 'billing/SAVE_PAYMENT_CARD_DONE':
    case 'billing/POST_ADDED_CARD_DONE':
      return action.card || state;
    default:
      return state;
  }
};

export const paymentCard = combineReducers({
  fetchRequest: createRequestReducer([
    'billing/REQUEST_PAYMENT_CARD',
    'billing/REQUEST_PAYMENT_CARD_DONE',
    'billing/REQUEST_PAYMENT_CARD_FAILED',
  ]),
  updateRequest: createRequestReducer([
    'billing/SAVE_PAYMENT_CARD',
    'billing/SAVE_PAYMENT_CARD_DONE',
    'billing/SAVE_PAYMENT_CARD_FAILED',
  ]),
  entity: paymentCardEntity,
});

type LegacySubscriptionState = ImmutableMap<{
  entity: LegacySubscription;
  isFetching: boolean;
  lastFetched: number | null;
  legacySubscriptionError: ImmutableServerError | null;
  legacySubscriptionLoadError: ImmutableServerError | null;
}>;

export function legacySubscription(
  state: LegacySubscriptionState = createImmutableState({
    entity: createLegacySubscription(),
    lastFetched: null,
    isFetching: false,
    legacySubscriptionError: null,
    legacySubscriptionLoadError: null,
  }),
  action: BillingAction | MemberAction,
) {
  switch (action.type) {
    case 'billing/REQUEST_LEGACY_SUBSCRIPTION':
      return state.merge({
        isFetching: true,
      });
    case 'billing/REQUEST_LEGACY_SUBSCRIPTION_DONE':
      return state.withMutations((st) => {
        st.merge({
          entity: action.legacySubscription,
          isFetching: false,
          lastFetched: action.timestamp,
        });
      });
    case 'billing/REQUEST_LEGACY_SUBSCRIPTION_FAILED':
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        legacySubscriptionLoadError: action.error,
      });
    case 'billing/PURCHASE_NEW_SEATS_DONE':
      if (state.get('entity')) {
        return state.updateIn(
          ['entity', '_usage', 'memberCount'],
          (memberCount: number) => memberCount + action.numberOfNewSeatsPurchased,
        );
      } else {
        return state;
      }
    case 'members/INVITE_MEMBERS_DONE':
    case 'billing/SUBSCRIBE_AND_INVITE_DONE':
      if (state.get('entity')) {
        return state.updateIn(
          ['entity', '_usage', 'memberCount'],
          (memberCount: number) => memberCount + action.invites.successfulEmails.size,
        );
      } else {
        return state;
      }
    case 'members/DELETE_MEMBER_DONE':
      if (state.get('entity')) {
        return state.updateIn(['entity', '_usage', 'memberCount'], (memberCount: number) => memberCount - 1);
      } else {
        return state;
      }
    default:
      return state;
  }
}

type paymentSecretInitialStateType = ImmutableMap<{
  secret: string;
  error: ImmutableServerError | string;
}>;

const paymentSecretInitialState: paymentSecretInitialStateType = Map({ secret: '', error: undefined });

export const paymentSecret = (state = paymentSecretInitialState, action: BillingAction) => {
  if (action.type === 'billing/REQUEST_PAYMENT_INTENT_DONE') {
    return state.merge({ secret: action.secret });
  } else if (action.type === 'billing/REQUEST_PAYMENT_INTENT_FAILED') {
    return state.merge({ error: action.error });
  }
  return state;
};

export const paymentSecretRequest = createRequestReducer([
  'billing/REQUEST_PAYMENT_INTENT',
  'billing/REQUEST_PAYMENT_INTENT_DONE',
  'billing/REQUEST_PAYMENT_INTENT_FAILED',
]);

const subscriptionEntity = (state = createSubscription(), action: BillingAction) => {
  switch (action.type) {
    case 'billing/REQUEST_SUBSCRIPTION_DONE':
    case 'billing/SUBSCRIBE_DONE':
    case 'billing/SUBSCRIBE_AND_INVITE_DONE':
    case 'billing/EXTEND_TRIAL_END_DATE_DONE':
    case 'billing/CREATE_SUBSCRIPTION_CAMPAIGN_DONE':
    case 'billing/PURCHASE_NEW_SEATS_DONE':
      return action.subscription;
    default:
      return state;
  }
};

const subscriptionUsageEntity = (state = createSubscriptionUsage(), action: BillingAction | MemberAction) => {
  switch (action.type) {
    case 'billing/REQUEST_SUBSCRIPTION_USAGE_DONE':
      return action.subscriptionUsage;

    //  TODO: separate this logic
    case 'members/INVITE_MEMBERS_DONE':
    case 'billing/SUBSCRIBE_AND_INVITE_DONE':
      if (state) {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        return state.update(limitNames.SEATS, (memberCount) => memberCount + action.invites.successfulEmails.size);
      } else {
        return state;
      }
    case 'members/DELETE_MEMBER_DONE':
      if (state) {
        return state.update(limitNames.SEATS, (memberCount) => memberCount - 1);
      } else {
        return state;
      }
    default:
      return state;
  }
};

export const selectedLimit = (state: PlanLimits = createPlanLimits(), action: BillingAction) => {
  switch (action.type) {
    case 'billing/SELECT_LIMIT':
      return state.set(action.key, action.value);
    default:
      return state;
  }
};

export const subscription = combineReducers({
  fetchRequest: createRequestReducer([
    'billing/REQUEST_SUBSCRIPTION',
    'billing/REQUEST_SUBSCRIPTION_DONE',
    'billing/REQUEST_SUBSCRIPTION_FAILED',
  ]),
  updateRequest: createRequestReducer(['billing/SUBSCRIBE', 'billing/SUBSCRIBE_DONE', 'billing/SUBSCRIBE_FAILED']),
  campaignRequest: createRequestReducer([
    'billing/CREATE_SUBSCRIPTION_CAMPAIGN',
    'billing/CREATE_SUBSCRIPTION_CAMPAIGN_DONE',
    'billing/CREATE_SUBSCRIPTION_CAMPAIGN_FAILED',
  ]),
  purchaseNewSeatsRequest: createRequestReducer([
    'billing/PURCHASE_NEW_SEATS_START',
    'billing/PURCHASE_NEW_SEATS_DONE',
    'billing/PURCHASE_NEW_SEATS_FAILED',
  ]),
  entity: subscriptionEntity,
});

export const subscriptionPrice = combineReducers({
  request: createRequestReducer([
    'billing/PREVIEW_SUBSCRIPTION_PRICE',
    'billing/PREVIEW_SUBSCRIPTION_PRICE_DONE',
    'billing/PREVIEW_SUBSCRIPTION_PRICE_FAILED',
  ]),
  price: (
    state: {
      current: SubscriptionPrice | undefined;
      selected: SubscriptionPrice | undefined;
    } = {
      current: undefined,
      selected: undefined,
    },
    action: BillingAction,
  ) => {
    switch (action.type) {
      case 'billing/PREVIEW_SUBSCRIPTION_PRICE_DONE':
        const subscriptionPriceArgs = action.response.get('prices')?.set('promoCode', action.promoCode);
        const price = createSubscriptionPrice(subscriptionPriceArgs);
        return state.current === undefined && state.selected === undefined
          ? { current: price, selected: price }
          : { ...state, selected: price };
      case 'billing/SUBSCRIBE_DONE':
        return { ...state, current: state.selected };
      default:
        return state;
    }
  },
  creditDue: (state: SubscriptionPrice | undefined | null = null, action: BillingAction) => {
    switch (action.type) {
      case 'billing/PREVIEW_SUBSCRIPTION_PRICE_DONE':
        return createSubscriptionPrice(action.response.get('creditDue'));
      default:
        return state;
    }
  },
  proration: (
    state: { time: number; price: SubscriptionPrice; renewalTime: number } = {
      time: 0,
      price: createSubscriptionPrice(),
      renewalTime: 0,
    },
    action: BillingAction,
  ) => {
    switch (action.type) {
      case 'billing/PREVIEW_SUBSCRIPTION_PRICE_DONE':
        return {
          time: action.response.get('prorationTime'),
          renewalTime: action.response.get('renewalTime'),
          price: createSubscriptionPrice(action.response.get('proratedPrices')),
        };
      default:
        return state;
    }
  },
});

export const postingCard = createRequestReducer([
  'billing/POST_ADDED_CARD',
  'billing/POST_ADDED_CARD_DONE',
  'billing/POST_ADDED_CARD_FAILED',
]);

export const subscriptionUsage = combineReducers({
  fetchRequest: createRequestReducer([
    'billing/REQUEST_SUBSCRIPTION_USAGE',
    'billing/REQUEST_SUBSCRIPTION_USAGE_DONE',
    'billing/REQUEST_SUBSCRIPTION_USAGE_FAILED',
  ]),
  entity: subscriptionUsageEntity,
});

export const invoices = (
  state = fromJS({
    entities: {},
    lastFetched: null,
    isFetching: false,
  }),
  action: BillingAction,
) => {
  switch (action.type) {
    case 'billing/REQUEST_INVOICES':
      return state.set('isFetching', true);
    case 'billing/REQUEST_INVOICES_FAILED':
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        error: action.error,
      });
    case 'billing/REQUEST_INVOICES_DONE':
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        entities: action.response.getIn(['entities', 'invoices']),
        error: null,
      });
    default:
      return state;
  }
};

export const paymentIntentSelector = (state: GlobalState) => state.paymentSecret;
export const paymentIntentRequestSelector = (state: GlobalState) => state.paymentSecretRequest;
export const subscriptionSelector = (state: GlobalState) => state.subscription;
export const subscriptionFetchRequestSelector = (state: GlobalState) => subscriptionSelector(state).fetchRequest;
export const subscriptionEntitySelector = (state: GlobalState) => subscriptionSelector(state).entity;
export const subscriptionLimitSelector = (state: GlobalState) => subscriptionEntitySelector(state).get('_limits');
export const eventsPublishedLimitSelector = (state: GlobalState) =>
  subscriptionLimitSelector(state).get(limitNames.EVENTS_PUBLISHED);
export const eventsReceivedLimitSelector = (state: GlobalState) =>
  subscriptionLimitSelector(state).get(limitNames.EVENTS_RECEIVED);
export const clientMAULimitSelector = (state: GlobalState) =>
  subscriptionLimitSelector(state).get(limitNames.MONTHLY_ACTIVE_USERS);

export const subscriptionUsageSelector = (state: GlobalState) => state.subscriptionUsage;
export const subscriptionUsageFetchRequestSelector = (state: GlobalState) =>
  subscriptionUsageSelector(state).fetchRequest;
export const subscriptionUsageEntitySelector = (state: GlobalState) => subscriptionUsageSelector(state).entity;
export const eventsPublishedSubscriptionUsageSelector = (state: GlobalState) =>
  subscriptionUsageEntitySelector(state).get(limitNames.EVENTS_PUBLISHED);
export const eventsReceivedSubscriptionUsageSelector = (state: GlobalState) =>
  subscriptionUsageEntitySelector(state).get(limitNames.EVENTS_RECEIVED);
export const clientMAUSubscriptionUsageSelector = (state: GlobalState) =>
  subscriptionUsageEntitySelector(state).get(limitNames.MONTHLY_ACTIVE_USERS);

export const legacySubscriptionSelector = (state: GlobalState) => state.legacySubscription;

export const legacyPlansSelector = (state: GlobalState) => state.legacyPlans;

export const subscriptionPriceSelector = (state: GlobalState) => state.subscriptionPrice;

export const plansSelector = (state: GlobalState) => state.plans;
export const planEntitiesSelector = (state: GlobalState) => plansSelector(state).entities;

export const limitPlansSelector = (state: GlobalState) => state.limitPlans;

export const limitPlanEntitiesSelector = (state: GlobalState) => limitPlansSelector(state).entities;

export const paymentCardSelector = (state: GlobalState) => state.paymentCard;

export const invoiceListSelector = (state: GlobalState) => state.invoices;

export const selectedLimitPlansSelector = (state: GlobalState) => state.selectedLimitPlans;

export const activePlanTypeSelector = (state: GlobalState) => state.activePlan;

export const postCardSelector = (state: GlobalState) => state.postingCard;

export const selectedBillingPeriodSelector = (state: GlobalState) => state.selectedBillingPeriod;

export const selectedLimitSelector = (state: GlobalState) => state.selectedLimit;

export const purchaseNewSeatsRequestSelector = (state: GlobalState) =>
  subscriptionSelector(state).purchaseNewSeatsRequest;

export const activePlanSelector = createSelector(
  subscriptionEntitySelector,
  activePlanTypeSelector,
  planEntitiesSelector,
  subscriptionUsageEntitySelector,
  limitPlanEntitiesSelector,
  (sub, activePlanType, planEntities, usage, limitPlanEntities) => {
    const defaultToFoundation = enableSelfServeUsageBasedBilling() || enableSelfServeUBBWithAnnualCommits();
    // If usage based billing is on and it's a trial, the default selected plan for the PlanPicker should be Foundation.
    if ((sub.isTrial() && !activePlanType && defaultToFoundation) || (sub.isEnterpriseTrial() && !activePlanType)) {
      return planEntities.getFoundationPlan();
    } else {
      return findSelectedPlan({
        planType: activePlanType || sub.planType,
        plans: planEntities,
        usage,
        limitPlans: limitPlanEntities,
      });
    }
  },
);

export const selectedSubscriptionSelector = createSelector(
  subscriptionEntitySelector,
  activePlanSelector,
  limitPlanEntitiesSelector,
  selectedLimitPlansSelector,
  selectedBillingPeriodSelector,
  subscriptionUsageEntitySelector,
  createSubscriptionChangeFromSelection,
);

export const useSubscription = () => useSelector(subscriptionSelector);
export const useSubscriptionEntity = (): Subscription => useSelector(subscriptionSelector).entity;
export const useSelectedSubscription = () => useSelector(selectedSubscriptionSelector);
export const useSelectedSubscriptionPrice = () => useSelector(subscriptionPriceSelector);
export const useSelecetedPurchaseNewSeatsRequest = () => useSelector(purchaseNewSeatsRequestSelector);

registry.addReducers({
  activePlan,
  invoices,
  legacyPlans,
  legacySubscription,
  limitPlans,
  paymentCard,
  plans,
  selectedBillingPeriod,
  selectedLimitPlans,
  subscription,
  subscriptionPrice,
  subscriptionUsage,
  paymentSecret,
  paymentSecretRequest,
  postingCard,
  selectedLimit,
});
