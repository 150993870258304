import { Component } from 'react';

import PolicyStatementActions from 'components/policyEditor/policyStatementEditor/PolicyStatementActions';
import PolicyStatementEditorButtons from 'components/policyEditor/policyStatementEditor/PolicyStatementEditorButtons';
import PolicyStatementEffect from 'components/policyEditor/policyStatementEditor/PolicyStatementEffect';
import PolicyStatementResources from 'components/policyEditor/policyStatementEditor/PolicyStatementResources';
import ResourceFinderPrompt from 'components/ResourceFinderPrompt';
import { WithFormEnhancerProps } from 'components/withForm';
import NavigationPrompt from 'routers/NavigationPrompt';
import {
  getFullResourceString,
  parseResourceType,
  PolicyActions,
  PolicyStatement as PolicyStatementType,
  replaceResourceStringId,
} from 'utils/policyUtils';
import { trackResourceFinderResourceClicked, trackResourceFinderViewed } from 'utils/roleUtils';

import styles from './styles.module.css';

export type PolicyStatementEditorProps = WithFormEnhancerProps<PolicyStatementType> & {
  customEffects?: { allow: string; deny: string };
  hideActions?: boolean;
  canRemove?: boolean;
  onCancel(shouldRemove: boolean): void;
  onRemove(): void;
  policyActions?: PolicyActions;
  shouldFocusOnMount?: boolean;
  statement: PolicyStatementType;
};

/* eslint-disable import/no-default-export */
export default class PolicyStatementEditor extends Component<PolicyStatementEditorProps> {
  resourcesInput?: HTMLInputElement | null;
  textSelection?: HTMLInputElement | null;
  state = {
    resourceTypeAtCursor: '',
    showResourceFinder: false,
  };

  componentDidMount() {
    if (this.props.shouldFocusOnMount) {
      this.resourcesInput?.focus();
    }
  }

  render() {
    const {
      canRemove,
      modified,
      onChange,
      onRemove,
      onSubmit,
      policyActions,
      isDirtyAndUnsaved,
      customEffects,
      hideActions,
    } = this.props;
    const { resourceTypeAtCursor, showResourceFinder } = this.state;
    const { actions, effect, negateActions, negateResources, resources } = modified.toJS();
    const isAllActionsSelected = actions[0] === '*';
    const resourceType = parseResourceType(resources);

    return (
      <>
        <NavigationPrompt shouldBlock={isDirtyAndUnsaved} />
        <div className={`${styles.statement} ${styles.statementForm}`}>
          <PolicyStatementResources
            handleResourceFinderClick={this.handleResourceFinderClick}
            negateResources={negateResources}
            onChange={onChange}
            resources={resources}
            resourcesInput={this.resourcesInput}
            setResourcesInput={this.setResourcesInput}
            setTextSelection={this.setTextSelection}
            showResourceFinder={this.showResourceFinder}
          />
          <PolicyStatementEffect customEffects={customEffects} effect={effect} onChange={onChange} />
          {!hideActions && (
            <PolicyStatementActions
              actions={actions}
              isAllActionsSelected={isAllActionsSelected}
              negateActions={negateActions}
              onChange={this.props.onChange}
              policyActions={policyActions}
              resourceType={resourceType}
            />
          )}
          <PolicyStatementEditorButtons
            canRemove={canRemove}
            onCancel={this.props.onCancel}
            onRemove={onRemove}
            onSubmit={onSubmit}
            statement={this.props.statement}
          />
          {showResourceFinder && (
            <ResourceFinderPrompt
              resourceType={resourceTypeAtCursor}
              onValue={(id: string, type: string, projKey?: string) =>
                this.handleResourceFinderValue(id, type, projKey, resourceType)
              }
              onCancel={() => this.closeResourceFinder()}
              onClearResourceType={this.handleClearResourceType}
            />
          )}
        </div>
      </>
    );
  }

  handleClearResourceType = () => {
    this.setState({ resourceTypeAtCursor: '' });
  };

  handleResourceFinderClick = () => {
    trackResourceFinderViewed();
    this.showResourceFinder();
  };

  closeResourceFinder = () => {
    delete this.textSelection;
    this.setState({
      showResourceFinder: false,
    });
  };

  showResourceFinder = () => {
    let resourceString = this.resourcesInput?.value;
    if (this.textSelection) {
      const { selectionStart } = this.textSelection;
      if (selectionStart) {
        // get the text up to the cursor
        resourceString = resourceString?.slice(0, selectionStart);
      }
    }
    this.setState({
      // split and reverse to properly handle cases like `proj/test:env/*:flag/* proj/`
      resourceTypeAtCursor: resourceString && parseResourceType(resourceString.split(' ').reverse()),
      showResourceFinder: true,
    });
  };

  handleResourceFinderValue = (id: string, type: string, projKey?: string, currentValueResourceType?: string) => {
    trackResourceFinderResourceClicked();
    const { onChange } = this.props;
    const currentValue = this.resourcesInput?.value;
    const valueUpToCursor = this.textSelection?.selectionStart
      ? currentValue?.slice(0, this.textSelection.selectionStart)
      : currentValue;
    let replacementValue;
    // if no resource string exists in the input, or the inputted resource string type does not match the newly selected type
    if (!valueUpToCursor || (!this.textSelection && currentValueResourceType && currentValueResourceType !== type)) {
      replacementValue = getFullResourceString(id, type, projKey);
    } else if (this.textSelection) {
      const { selectionStart, selectionEnd } = this.textSelection;
      replacementValue =
        selectionStart &&
        selectionEnd &&
        `${currentValue?.slice(0, selectionStart)}${id}${currentValue?.slice(selectionEnd)}`;
    } else if (currentValue) {
      // if a resource string already exists before opening the resource picker, we need to handle the case where a user has already entered an id, key, or * for that resource and replace that value with the selected value
      replacementValue = replaceResourceStringId(currentValue, id);
    }
    replacementValue && onChange('resources', replacementValue.split(' '));

    this.closeResourceFinder();
  };

  setResourcesInput = (input: HTMLInputElement) => {
    this.resourcesInput = input;
  };

  setTextSelection = (selectionStart: number | null, selectionEnd: number | null) => {
    this.textSelection = { selectionStart, selectionEnd } as HTMLInputElement;
  };
}
