import { addHours } from 'date-fns';
// eslint-disable-next-line no-restricted-imports
import { fromJS, OrderedMap } from 'immutable';
import { normalize, schema } from 'normalizr';

import { AccessToken, AccessTokenForm, createAccessToken as createAccessTokenRecord } from 'utils/accessTokenUtils';
import { convertMapToOrderedMap } from 'utils/collectionUtils';
import http, { jsonToImmutable, jsonToImmutableError } from 'utils/httpUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';
import { createJsonPatch } from 'utils/patchUtils';

type NormalizedAccessTokens = ImmutableMap<{
  items: AccessToken[];
  _links: { self: { href: string; type: string } };
}>;

export const createAccessToken = async (token: AccessTokenForm) =>
  http
    .post('/api/v2/tokens', {
      body: token.toRep(),
    })
    .then(jsonToImmutable)
    .then(createAccessTokenRecord)
    .catch(jsonToImmutableError);

const accessTokens = new schema.Entity('accessTokens', {}, { idAttribute: '_id' });

type AccessTokensReponse = ImmutableMap<{
  entities: ImmutableMap<{
    accessTokens: OrderedMap<string, AccessToken>;
  }>;
  result: ImmutableMap<{
    _links: ImmutableMap<{ self: Link }>;
    items: string[];
  }>;
}>;

export const getAllAccessTokens = async (url?: string): Promise<AccessTokensReponse> =>
  http
    .get(`${url || '/api/v2/tokens'}?showAll=1`, {
      headers: { 'Ld-Api-Version': '20220603' },
    })
    .then(async (res) =>
      res.json().then((data) => {
        const normalized: NormalizedAccessTokens = fromJS(normalize(data, { items: [accessTokens] }));
        return normalized.withMutations((map) => {
          map.updateIn(['entities', 'accessTokens'], (tokens) =>
            convertMapToOrderedMap(tokens, normalized.getIn(['result', 'items'])),
          );
          map.updateIn(['entities', 'accessTokens'], (tokens) =>
            tokens ? tokens.map(createAccessTokenRecord) : OrderedMap(),
          );
        });
      }),
    )
    .catch(jsonToImmutableError);

export const updateAccessToken = async (oldToken: AccessTokenForm, newToken: AccessTokenForm) => {
  const patch = createJsonPatch(oldToken.toRep(), newToken.toRep());
  return http
    .patch(oldToken.selfLink(), {
      body: patch,
    })
    .then(jsonToImmutable)
    .then(createAccessTokenRecord)
    .catch(jsonToImmutableError);
};

export const resetAccessToken = async (token: AccessToken, expiry?: number) => {
  const baseUrl = `${token.selfLink()}/reset`;
  const url = expiry ? `${baseUrl}?expiry=${addHours(new Date(), expiry).valueOf()}` : baseUrl;

  return http.post(url).then(jsonToImmutable).then(createAccessTokenRecord).catch(jsonToImmutableError);
};

export const deleteAccessToken = async (token: AccessToken) =>
  http.delete(token.selfLink()).catch(jsonToImmutableError);
