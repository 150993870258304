import type { UseSuspenseQueryResult } from '@tanstack/react-query';

import { disabledSuspenseQueryData } from './internal/disabledQuery';

/**
 *
 * This function is a TypeScript "type guard" that will narrow a given `UseSuspenseQueryResult`
 * to exclude the fixed disabled query object, as conditionally included by `conditionalSuspenseQuery`.
 *
 * This type guard can be used both to assert the type of the query as well as conditionally execute code,
 * such as rendering a React component.
 *
 * @example
 * function MyComponent() {
 *  const [resultOne, resultTwo] = useSuspenseQueries({
 *    queries: [queryOne, conditionalSuspenseQuery({ query: queryTwo, enabled: isFeatureTwoEnabled() })],
 *  });
 *
 *  return (
 *    <>
 *      <FeatureOne data={resultOne.data} />
 *      {isSuspenseQueryEnabled(resultTwo) && <FeatureTwo data={resultTwo.data} />}
 *    </>
 *  );
 * }
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSuspenseQueryEnabled<T extends UseSuspenseQueryResult<any, any>>(
  query: T,
): query is Exclude<T, { data: typeof disabledSuspenseQueryData }> {
  return query.data !== disabledSuspenseQueryData;
}
