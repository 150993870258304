import { ErrorMessage } from '@amberflo/uikit/types';
import { type AnalyticEventData } from '@gonfalon/analytics';
import { amberfloMeterDatePickerType, amberfloServerConnectionMinutesMeterAPIName } from '@gonfalon/dogfood-flags';
import { startOfDay, startOfHour, startOfMonth, subDays, subMonths } from 'date-fns';

import { trackAmberfloEvent } from 'utils/analyticsUtils';
import Logger from 'utils/logUtils';
import { trackUsageChartError } from 'utils/usageUtils';

export type AmberfloMeterName =
  | 'ClientsideContexts'
  | 'ExperimentationKeys'
  | 'DataExportEvent'
  | 'ExperimentUnit'
  | 'ActiveServersideUsers'
  | 'ClientConnectionMinutes'
  | 'ServerSDKConnectionMinutes'
  | 'ServerConnectionMinutes';

export const amberfloMeter: { [key: string]: AmberfloMeterName } = {
  // Flag this between current (old) and new ServerSDKConnectionMinutes
  SERVER_CONNECTION_MINUTES: amberfloServerConnectionMinutesMeterAPIName() as AmberfloMeterName, // aka "Hosts"
  CLIENT_SIDE_CONTEXTS: 'ClientsideContexts', // aka "Contexts"
  EXPERIMENTATION_KEYS: 'ExperimentationKeys',
  DATA_EXPORT_EVENTS: 'DataExportEvent',

  // Believed to be currently unused:
  EXPERIMENTATION_UNITS: 'ExperimentUnit',
  SERVER_SIDE_USERS: 'ActiveServersideUsers',
  CLIENT_SIDE_CONNECTIONS: 'ClientConnectionMinutes',
};

export enum amberfloDimension {
  ENVIRONMENT_ID = 'environmentId',
  CONTEXT_KEY = 'contextKey',
  SDK_NAME = 'sdkName',
  SDK_TYPE = 'sdkType',
  CONTEXT_KIND = 'contextKind',
  USER_AGENT = 'userAgent',
  DEVICE_TYPE = 'deviceType',
  RELAY_ID = 'relayId',
  RELAY_VERSION = 'relayVersion',
}

export const amberfloDimensionNames = {
  [amberfloDimension.ENVIRONMENT_ID]: 'Project/Environment',
  [amberfloDimension.CONTEXT_KEY]: 'Context key',
  [amberfloDimension.SDK_NAME]: 'SDK name',
  [amberfloDimension.SDK_TYPE]: 'SDK type',
  [amberfloDimension.CONTEXT_KIND]: 'Context kind',
  [amberfloDimension.USER_AGENT]: 'User agent',
  [amberfloDimension.DEVICE_TYPE]: 'Device',
  [amberfloDimension.RELAY_ID]: 'Relay id',
  [amberfloDimension.RELAY_VERSION]: 'Relay version',
};

const meterTypes = amberfloMeterDatePickerType();

export const trackAmberfloChartEvent = ({
  event,
  productName,
  component,
  attributes,
  error,
}: {
  event?: string;
  productName?: string;
  component: string;
  attributes?: AnalyticEventData;
  error?: ErrorMessage;
}) => {
  try {
    if (error) {
      trackUsageChartError({
        error: error?.errorMessage || 'Unknown error',
        productName,
        component,
        pathName: window.location.pathname,
      });
    } else {
      trackAmberfloEvent(event, {
        ...attributes,
        productName,
        url: window.location.href,
        pathName: window.location.pathname,
        component,
      });
    }
    // Error is thrown when segment analytics is not available (e.g. in FE only mode)
    // eslint-disable-next-line no-empty
  } catch (e) {}
};

// default start date is the start of the current month
export const defaultStartDate = startOfMonth(new Date()).getTime();
// default end date is the start of the current hour
export const defaultEndDate = startOfHour(new Date()).getTime();
// annual start date is the start of the previous month one year prior (12 months inclusive of current month)
export const annualStartDate: number = startOfMonth(subMonths(new Date(), 11)).getTime();

export const datePickerTypeForMeter = (meterName?: string) => {
  if (meterName) {
    return meterTypes[meterName] ? meterTypes[meterName] : 'month';
  } else {
    return 'date-range';
  }
};

export function getStandardDatesUTC() {
  function convertStartOfLocalDateToStartOfUTCDate(local: Date): Date {
    return new Date(Date.UTC(local.getUTCFullYear(), local.getUTCMonth(), local.getUTCDate(), 0, 0, 0, 0));
  }
  const now = new Date();

  // Get start of important dates local time
  const startofCurrentMonthLocal = startOfMonth(now);
  const startOfLastMonthLocal = startOfMonth(subMonths(now, 1));
  const startOf14DaysAgoLocal = startOfDay(subDays(now, 14));

  // Convert starts of important dates local to start of dates UTC and return epoch in seconds
  const startOfCurrentMonthUTCMS = convertStartOfLocalDateToStartOfUTCDate(startofCurrentMonthLocal).getTime();
  const startOfLastMonthUTCMS = convertStartOfLocalDateToStartOfUTCDate(startOfLastMonthLocal).getTime();
  const startOf14DaysAgoUTCMS = convertStartOfLocalDateToStartOfUTCDate(startOf14DaysAgoLocal).getTime();

  // start of current hour is the same in local and UTC time
  const startOfCurrentHourUTCMS = startOfHour(now).getTime();

  return {
    startOfCurrentMonthUTCMS,
    startOfLastMonthUTCMS,
    startOf14DaysAgoUTCMS,
    startOfCurrentHourUTCMS,
  };
}

export const amberfloLogger = new Logger('amberflo');
