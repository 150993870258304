import { isPlainObject } from '@gonfalon/es6-utils';

import { type ProjectContext } from './projectContext';

// Adapted from https://github.com/TanStack/query/blob/4d37cfc733744501b0b4423c721cc038206994e8/packages/query-core/src/utils.ts#L177
export function hashProjectContext(context: ProjectContext): string {
  const contextKey = [context.projectKey, context.selectedEnvironmentKey, ...context.environmentKeys];
  return JSON.stringify(contextKey, (_, val) =>
    isPlainObject(val)
      ? Object.keys(val)
          .sort()
          .reduce((result, key) => {
            // eslint-disable-next-line no-param-reassign
            result[key] = val[key];
            return result;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          }, {} as any)
      : val,
  );
}
