import { useRandomizationSettings } from '@gonfalon/rest-api';
import { useProjectKey } from '@gonfalon/router';

export function useRandomizationUnitDisplayValue(randomizationUnitKey: string) {
  const projectKey = useProjectKey();
  const { data: randomizationSettings } = useRandomizationSettings({ projectKey });
  const matchedRandomizationUnit = randomizationSettings?.randomizationUnits?.find(
    (unit) => unit.randomizationUnit === randomizationUnitKey,
  );
  return matchedRandomizationUnit?._displayName ?? matchedRandomizationUnit?.randomizationUnit;
}
