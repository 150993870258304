import { CredibleIntervalTimeSeries, ExperimentTimeSeriesResults } from './types';
export function normalizeTimeSeriesTreatmentResults(res: ExperimentTimeSeriesResults): CredibleIntervalTimeSeries {
  return (res.results ?? []).map((result) => ({
    treatmentName: result.treatmentName,
    treatmentId: result.treatmentId,
    values: (result.data ?? [])
      .sort((a, b) => (new Date(a.timestamp || 0) > new Date(b.timestamp || 0) ? 1 : -1))
      .map((value) => ({
        timestamp: new Date(value.timestamp || 0).getTime(),
        pointEstimate: typeof value.mean !== 'undefined' ? value.mean : NaN,
        lowerBound: typeof value.credibleInterval?.lower !== 'undefined' ? value.credibleInterval?.lower : NaN,
        upperBound: typeof value.credibleInterval?.upper !== 'undefined' ? value.credibleInterval?.upper : NaN,
        traffic: Number(value.traffic),
        mean: Number(value.mean),
        data: {
          relativeDifferences:
            value.relativeDifferences && value.relativeDifferences.length
              ? value.relativeDifferences?.map((diff) => ({
                  fromTreatmentId: diff.fromTreatmentId || '',
                  lower: typeof diff.lower !== 'undefined' ? diff.lower : NaN,
                  upper: typeof diff.upper !== 'undefined' ? diff.upper : NaN,
                  estimate: typeof diff.estimate !== 'undefined' ? diff.estimate : NaN,
                }))
              : [],
        },
      })),
  }));
}
