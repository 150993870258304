import { useMemo } from 'react';
import { useMatches } from 'react-router-dom';
import { z } from 'zod';

import { type SelectedEnvironment } from '../types';

const schema = z.object({
  EnvironmentFilters: z.function().args(z.any()).returns(z.any()),
});

/**
 * Render environment-specific filters for the current route.
 *
 * A route defines its filters via its `handle`.
 */
export function EnvironmentFilters({ environment }: { environment: SelectedEnvironment }) {
  const matches = useMatches();
  const currentRoute = matches.at(-1);

  const RouteFilters = useMemo(() => {
    if (currentRoute === undefined) {
      return () => null;
    }

    const parse = schema.safeParse(currentRoute.handle);
    if (!parse.success) {
      return () => null;
    }

    return parse.data.EnvironmentFilters;
  }, [currentRoute]);

  return <RouteFilters data-slot="environment-filter-trigger" environment={environment} />;
}
