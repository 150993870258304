import { components, MultiValueProps } from 'react-select';
import { find } from '@gonfalon/es6-utils';
import { CustomSelect, OptionProps } from '@gonfalon/launchpad-experimental';
import { FormGroup, Label } from 'launchpad';

import { PolicyActionAndDescription, PolicyActions } from 'utils/policyUtils';

type PolicyStatementActionsProps = {
  actions: string[];
  isAllActionsSelected: boolean;
  negateActions: boolean;
  onChange: (key: 'actions', value: string[]) => void;
  policyActions?: PolicyActions;
  resourceType: string;
};

const PolicyStatementActions = ({
  actions,
  isAllActionsSelected,
  negateActions,
  onChange,
  policyActions,
  resourceType,
}: PolicyStatementActionsProps) => {
  const allActionsOption = { value: '*', label: 'All actions' };

  const actionsForResourceType =
    resourceType && policyActions
      ? policyActions
          .getIn([resourceType, 'items'])
          .toJS()
          .map((t: PolicyActionAndDescription) => ({
            value: t.action,
            label: t.action,
            description: t.description,
          }))
      : [];

  const actionOptions = isAllActionsSelected
    ? [allActionsOption]
    : actions.reduce(
        (currentOptions: Array<{ value: string; label: string; description: string }>, action: string) => {
          if (action !== '*' && action.indexOf('*') !== -1) {
            currentOptions.push({
              value: action,
              label: action,
              description: `Actions matching "${action}"`,
            });
          } else if (!find(currentOptions, { value: action })) {
            currentOptions.push({
              value: action,
              label: action,
              description: '',
            });
          }
          return currentOptions;
        },
        [allActionsOption, ...actionsForResourceType],
      );

  const MultiValueLabel = (multiValueLabelProps: MultiValueProps<OptionProps>) => (
    <components.MultiValueLabel {...multiValueLabelProps}>{multiValueLabelProps.data.label}</components.MultiValueLabel>
  );

  return (
    <FormGroup>
      <Label htmlFor="PolicyStatement-Actions">
        {negateActions ? 'Specific actions to exclude' : 'Choose actions to allow or deny'}
      </Label>
      <CustomSelect
        id="PolicyStatement-Actions"
        blurInputOnSelect
        isClearable
        isMulti
        noOptionsMessage={() => (isAllActionsSelected ? 'All actions are already selected' : 'No actions found')}
        onChange={(values) => {
          const valuesArray = values as OptionProps[];
          const modifiedValues = valuesArray?.map((v) => v.value);
          // if list of actions contains all actions delimiter, get rid of the rest of the actions because they're redundant
          modifiedValues
            ? onChange('actions', modifiedValues.indexOf('*') !== -1 ? ['*'] : valuesArray?.map((v) => v.value))
            : onChange('actions', []);
        }}
        value={
          actions
            ? actions.map((action: string) => ({
                value: action,
                label: action === '*' ? 'All actions' : action,
              }))
            : ''
        }
        options={actionOptions}
        customComponents={{
          MultiValueLabel,
        }}
        // @ts-expect-error this prop should be refactored to use "formatOptionLabel", with "getOptionLabel" returning a string version of the label
        getOptionLabel={(option: OptionProps & { description: string }) => {
          const label = option.label;
          if (option && 'description' in option) {
            return `${label}: ${option.description}`;
          }
          return label;
        }}
        placeholder="Select actions…"
        formatCreateLabel={(val: string) => `Actions matching "${val}"`}
      />
    </FormGroup>
  );
};

/* eslint-disable import/no-default-export */
export default PolicyStatementActions;
