import { useMemo } from 'react';
import { useMatches } from 'react-router-dom';
import { z } from 'zod';

const filtersSchema = z.object({
  shortcutType: z.enum(['flags', 'contexts', 'segments', 'flag']),
});

export function useRouteShortcutType() {
  const matches = useMatches();

  const routeShortcutType = useMemo(() => {
    // Extract shortcut type from the deepest route first.
    const reversedMatches = [...matches].reverse();

    for (const match of reversedMatches) {
      const parse = filtersSchema.safeParse(match.handle);
      if (parse.success) {
        return parse.data.shortcutType;
      }
    }
    return undefined;
  }, [matches]);

  return routeShortcutType;
}
