import { HTMLAttributes } from 'react';
import cx from 'clsx';

import styles from './PageNav.module.css';

export const PageNav = ({ children, className, ...rest }: HTMLAttributes<HTMLDivElement>) => (
  <div className={cx(styles.pageNav, className)} {...rest}>
    {children}
  </div>
);
