import { Link } from 'react-router-dom';
import { useIsInNewIA } from '@gonfalon/ia-migration';

export function MaybeLink({ content, url }: { content: string; url?: string }) {
  const isInNewIa = useIsInNewIA();
  if (!url) {
    return <span>{content}</span>;
  }

  if (isInNewIa) {
    return <Link to={url}>{content}</Link>;
  }

  return <a href={url}>{content}</a>;
}
