import { useCallback, useMemo, useState } from 'react';
import { trackMetricsCancelClicked, trackMetricsSaveClicked, trackMetricsSelected } from '@gonfalon/measured-rollouts';
import { Metric } from '@gonfalon/metrics';
import { Button, Dialog, Heading, IconButton, Modal, ModalOverlay } from '@launchpad-ui/components';
import { Alert } from 'launchpad';

import { MeasuredRolloutMetricsForm } from '../MeasuredRolloutMetricsForm';
import { useMeasuredRolloutMetrics } from '../useMeasuredRolloutMetrics';

import styles from './MeasuredRolloutMetricsModal.module.css';

export interface MeasuredRolloutMetricsModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSave?: () => void;
  projectKey: string;
  flagKey: string;
}

export function MeasuredRolloutMetricsModal({
  onSave,
  setIsOpen,
  isOpen,
  projectKey,
  flagKey,
}: MeasuredRolloutMetricsModalProps) {
  const { metrics, isFetching, isPending, handleSave } = useMeasuredRolloutMetrics(projectKey, flagKey, onSave);
  if (isPending || isFetching) {
    return null;
  }

  return <MetricsRolloutModal isOpen={isOpen} setIsOpen={setIsOpen} defaultMetrics={metrics} handleSave={handleSave} />;
}

type MetricsRolloutModalProps = Omit<MeasuredRolloutMetricsModalProps, 'onSave' | 'projectKey' | 'flagKey'> & {
  defaultMetrics?: Metric[];
  handleSave: (data: { metrics: Metric[] }) => Promise<void>;
};

function MetricsRolloutModal({ isOpen, setIsOpen, handleSave, defaultMetrics }: MetricsRolloutModalProps) {
  const [metrics, setMetrics] = useState<Metric[]>(defaultMetrics ?? []);
  const [isSaving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const isEdit = defaultMetrics && defaultMetrics.length > 0;
  const isReady = metrics.length > 0 || (defaultMetrics && defaultMetrics.length > 0 && metrics.length === 0);

  const handleMetricsChange = useCallback((m: Metric[]) => {
    trackMetricsSelected({ metrics: m.map((metric) => metric.name) });
    setMetrics(m);
  }, []);

  const onSavePress = useCallback(async () => {
    if (!isReady) {
      return;
    }

    setSaving(true);

    try {
      trackMetricsSaveClicked({
        metrics: metrics.map((m) => m.name),
        previousMetrics: metrics?.map((m) => m.name),
      });

      await handleSave({ metrics });
      setIsOpen(false);
    } catch (err) {
      setErrorMessage(err instanceof Error ? err.message : 'There was a problem saving this configuration');
    } finally {
      setSaving(false);
    }
  }, [isReady, metrics, handleSave, setIsOpen]);

  const handleCancel = useCallback(() => {
    trackMetricsCancelClicked();
    setIsOpen(false);
  }, [setIsOpen]);

  const enableButton = useMemo(() => {
    if (!isReady) {
      return false;
    }
    const metricListChanged =
      !metrics.every((metric) => defaultMetrics?.find((dm) => dm.key === metric.key)) ||
      metrics.length !== defaultMetrics?.length;

    return metricListChanged;
  }, [isReady, metrics, defaultMetrics]);

  return (
    <ModalOverlay isOpen={isOpen} onOpenChange={setIsOpen}>
      <Modal isOpen={isOpen} onOpenChange={setIsOpen}>
        <Dialog>
          <div slot="header">
            <Heading slot="title">{isEdit ? 'Edit metrics' : 'Add metrics'}</Heading>
            <IconButton aria-label="close" icon="cancel" size="small" variant="minimal" onPress={handleCancel} />
          </div>
          <div slot="body" className={styles.modalBody}>
            {errorMessage && <Alert kind="error">{errorMessage}</Alert>}
            <MeasuredRolloutMetricsForm metrics={metrics} onMetricsChange={handleMetricsChange} isDisabled={isSaving} />
          </div>
          <div slot="footer">
            <Button variant="minimal" onPress={handleCancel} isDisabled={isSaving}>
              Cancel
            </Button>
            <Button variant="primary" onPress={onSavePress} isDisabled={!enableButton || isSaving}>
              Save
            </Button>
          </div>
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
}
