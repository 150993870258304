import { MemberAvatar, memberInitials } from '@gonfalon/members';
import type { IconProps } from '@launchpad-ui/icons';

import { AuditLogEntryRep } from '../types';

import { IntegrationAvatar } from './IntegrationAvatar';

type EntryAvatarProps = {
  entry: AuditLogEntryRep;
  size?: IconProps['size'];
  className?: string;
};

export function EntryAvatar({ entry, size, className }: EntryAvatarProps) {
  const integrationAvatarUrl = entry.subject?.avatarUrl;
  const member = entry.member;

  return integrationAvatarUrl ? (
    <IntegrationAvatar url={integrationAvatarUrl} size={size} className={className} />
  ) : (
    <MemberAvatar
      size={size}
      email={member?.email}
      initials={memberInitials({
        firstName: member?.firstName,
        lastName: member?.lastName,
      })}
      className={className}
    />
  );
}
