import { type GetFeatureFlagsQueryParams } from '@gonfalon/openapi';

import { parseFlagListFilterParamFromAPI } from './parseFlagListFilterParamFromAPI';
import { parseNumber } from './parseNumber';
import { parseSort } from './parseSort';

export function parseFlagListParamsFromAPI(searchParams: URLSearchParams) {
  const params: GetFeatureFlagsQueryParams = {};
  params.env = searchParams.getAll('env');
  params.limit = parseNumber(searchParams.get('limit'));
  params.offset = parseNumber(searchParams.get('offset'));
  params.sort = parseSort(searchParams.get('sort'));
  params.filter = parseFlagListFilterParamFromAPI(searchParams.get('filter') ?? '');
  return params;
}
