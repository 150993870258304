import { Dispatch } from 'redux';

import { load, save } from 'sources/AccountLocalStorage';
import { GenerateActionType } from 'utils/reduxUtils';

enum LocalStorageKeys {
  STARTED_MODAL_DISMISSED = 'enterpriseCampaignStartedModalDismissed',
}

export enum EnterpriseCampaignModalsActionTypes {
  SET_STARTED_MODAL_DISMISSED = 'enterpriseCampaignModals/SET_ENTERPRISE_CAMPAIGN_STARTED_MODAL_DISMISSED',
  GET_STARTED_MODAL_DISMISSED = 'enterpriseCampaignModals/GET_ENTERPRISE_CAMPAIGN_STARTED_MODAL_DISMISSED',
}

const loadFromCache = (key: LocalStorageKeys) => load().get(key);
const saveToCache = (key: LocalStorageKeys, value: boolean) => save(load().set(key, value));

const getDismissedAction = (type: EnterpriseCampaignModalsActionTypes, dismissed: boolean) =>
  ({
    type,
    dismissed,
  }) as const;

const setDismissedAction = (type: EnterpriseCampaignModalsActionTypes) =>
  ({
    type,
    dismissed: true,
  }) as const;

// ENTERPRISE CAMPAIGN *STARTED* MODAL
export const setEnterpriseCampaignStartedModalDismissed = () =>
  setDismissedAction(EnterpriseCampaignModalsActionTypes.SET_STARTED_MODAL_DISMISSED);

export const getEnterpriseCampaignStartedModalDismissed = (dismissed: boolean) =>
  getDismissedAction(EnterpriseCampaignModalsActionTypes.GET_STARTED_MODAL_DISMISSED, dismissed);

export function saveEnterpriseCampaignStartedModalDismissed() {
  return (dispatch: Dispatch) => {
    saveToCache(LocalStorageKeys.STARTED_MODAL_DISMISSED, true);
    return dispatch(setEnterpriseCampaignStartedModalDismissed());
  };
}

export function fetchEnterpriseCampaignStartedModalDismissed() {
  return (dispatch: Dispatch) => {
    const dismissed = loadFromCache(LocalStorageKeys.STARTED_MODAL_DISMISSED);
    return dispatch(getEnterpriseCampaignStartedModalDismissed(dismissed));
  };
}

const EnterpriseCampaignModalsActionCreators = {
  setEnterpriseCampaignStartedModalDismissed,
  getEnterpriseCampaignStartedModalDismissed,
};

export type EnterpriseCampaignModalsAction = GenerateActionType<typeof EnterpriseCampaignModalsActionCreators>;
