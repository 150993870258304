type ApplicationSupportChipProps = {
  supported?: boolean;
};

import { Chip } from 'launchpad';

import styles from './styles/ApplicationSupportChip.module.css';

export const ApplicationSupportChip = ({ supported }: ApplicationSupportChipProps) => {
  if (supported) {
    return (
      <Chip size="small" className={styles.supported}>
        Supported
      </Chip>
    );
  }
  return <Chip size="small">Unsupported</Chip>;
};
