// eslint-disable-next-line no-restricted-imports
import { fromJS, isImmutable, OrderedMap } from 'immutable';
import { normalize, schema } from 'normalizr';

import { convertMapToOrderedMap } from 'utils/collectionUtils';
import { FormRecord } from 'utils/formUtils';
import { createGoal as createMetricRecord, Goal } from 'utils/goalUtils';
import http, { jsonToImmutable, jsonToImmutableError } from 'utils/httpUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';
import Logger from 'utils/logUtils';
import { createJsonPatch } from 'utils/patchUtils';

const metricSchema = new schema.Entity('metrics', {}, { idAttribute: 'key' });
const logger = Logger.get('MetricsApi');

export type MetricsResponse = ImmutableMap<{
  entities: ImmutableMap<{
    metrics: OrderedMap<string, Goal>;
  }>;
  result: ImmutableMap<{
    _links: ImmutableMap<{ self: Link }>;
    items: string[];
  }>;
}>;

export const getAllMetrics = async (projKey: string, url?: string, expand?: string[]): Promise<MetricsResponse> => {
  try {
    let endpoint = url || `/api/v2/metrics/${projKey}`;

    if (expand) {
      endpoint = `${endpoint}?expand=${expand.join(',')}`;
    }

    const response = await http.get(endpoint, { beta: true });
    const responseJson = await response.json();
    const normalized = normalize(responseJson, { items: [metricSchema] });
    const immutable = fromJS(normalized);
    return immutable.withMutations((map: MetricsResponse) => {
      map.updateIn(['entities', 'metrics'], (metrics) => convertMapToOrderedMap(metrics, normalized.result.items));
      map.updateIn(['entities', 'metrics'], (metrics) => (metrics ? metrics.map(createMetricRecord) : OrderedMap()));
    });
  } catch (e) {
    logger.error('getAllMetrics error', isImmutable(e) ? e.toJS() : e);
    throw e;
  }
};

export const getMetric = async (projKey: string, metricKey: string, expand: string[] = []) => {
  try {
    let endpoint = `/api/v2/metrics/${projKey}/${metricKey}`;
    if (expand.length > 0) {
      endpoint = `${endpoint}?expand=${expand.join(',')}`;
    }
    const response = await http.get(endpoint, { beta: true }).then(jsonToImmutable, jsonToImmutableError);
    return createMetricRecord(response);
  } catch (e) {
    logger.error('getMetric error', isImmutable(e) ? e.toJS() : e);
    throw e;
  }
};

export const createMetric = async (projKey: string, metric: FormRecord<Goal>) => {
  try {
    const response = await http
      .post(`/api/v2/metrics/${projKey}`, {
        body: JSON.stringify(metric.toRep()),
        beta: true,
      })
      .then(jsonToImmutable, jsonToImmutableError);
    return createMetricRecord(response);
  } catch (e) {
    logger.error('createMetric error', isImmutable(e) ? e.toJS() : e);
    throw e;
  }
};

export const updateMetric = async (original: FormRecord<Goal>, updated: FormRecord<Goal>) => {
  const patch = createJsonPatch(original, updated, { propFilter: false });
  if (Array.isArray(patch)) {
    patch.unshift({
      op: 'test',
      path: '/_version',
      value: original.getVersion(),
    });
  }
  const endpoint = original.selfLink();

  try {
    const response = await http
      .patch(endpoint, { body: JSON.stringify(patch), beta: true })
      .then(jsonToImmutable, jsonToImmutableError);
    return createMetricRecord(response);
  } catch (e) {
    logger.error('updateMetric error', isImmutable(e) ? e.toJS() : e);
    throw e;
  }
};

export const deleteMetric = async (metric: Goal) => {
  const endpoint = metric.selfLink();
  try {
    return await http.delete(endpoint, { beta: true });
  } catch (e) {
    logger.error('deleteMetric error', isImmutable(e) ? e.toJS() : e);
    throw e;
  }
};
