import nullthrows from 'nullthrows';

import { createFormatter } from './internal/createFormatter';
import { findPrecision } from './internal/findPrecision';
import { CredibleInterval } from './types';

export function createValueFormatter(intervals: CredibleInterval[], asPercent?: boolean) {
  const smallestDifference = Math.min(
    ...intervals.map(({ lower, upper }) => Math.abs(nullthrows(upper) - nullthrows(lower))),
  );
  const precision = findPrecision(smallestDifference);
  return createFormatter({ precision, asPercent });
}
