import { ComponentProps, Key } from 'react';
import { Path, useNavigate, useSearchParams } from 'react-router-dom';
import { arrayMoveAfter, arrayMoveBefore } from '@gonfalon/collections';
import {
  updateURLSearchParamsWithProjectContext,
  useOptimisticProjectContext,
  useProjectContext,
} from '@gonfalon/context';
import { InternalEnvironment } from '@gonfalon/environments';
import invariant from 'tiny-invariant';

import { AddEnvironmentDialogTrigger, EnvironmentSelector } from '../EnvironmentSelector';
import { type SelectedEnvironment } from '../types';
import { useEnvironmentMode } from '../useEnvironmentMode';
import { useEnvironmentMenuItems } from '../useEnvironmentSettingsMenuItem';

export function EnvironmentController() {
  const currentContext = useProjectContext();
  const optimisticContext = useOptimisticProjectContext();
  const { context, project, environments } = optimisticContext ?? currentContext;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const settingsMenuItems = useEnvironmentMenuItems();
  const hasSingleEnvironmentRoute = useEnvironmentMode() === 'single';

  const handleSelectEnvironment = (environment: SelectedEnvironment) => {
    const nextList = [...environments, environment];
    const nextParams = updateURLSearchParamsWithProjectContext(searchParams, {
      projectKey: context.projectKey,
      environmentKeys: new Set(nextList.map((env) => env.key)),
      selectedEnvironmentKey: environment.key,
    });
    navigate({ search: nextParams.toString() }, { state: nextList });
  };

  const handleRemoveEnvironment = (key: Key) => {
    const removedIndex = environments.findIndex((it) => it.key === key);

    let nextSelectedKey = context.selectedEnvironmentKey;
    if (context.selectedEnvironmentKey === key) {
      const previousEnvironment = environments.at(Math.abs(removedIndex - 1)) ?? environments[0];
      nextSelectedKey = previousEnvironment.key;
    }

    const nextList = environments.filter((it) => it.key !== key);
    const nextParams = updateURLSearchParamsWithProjectContext(searchParams, {
      projectKey: context.projectKey,
      environmentKeys: new Set(nextList.map((env) => env.key)),
      selectedEnvironmentKey: nextSelectedKey,
    });
    setSearchParams(nextParams);
  };

  const handleReplaceEnvironment = (sourceEnvironmentKey: Key, targetEnvironment: InternalEnvironment) => {
    const sourceIndex = environments.findIndex((it) => it.key === sourceEnvironmentKey);

    let nextSelectedKey = context.selectedEnvironmentKey;
    if (environments[sourceIndex].key === context.selectedEnvironmentKey) {
      nextSelectedKey = targetEnvironment.key;
    }

    const nextList = [...environments];
    nextList[sourceIndex] = targetEnvironment;

    const nextParams = updateURLSearchParamsWithProjectContext(searchParams, {
      projectKey: context.projectKey,
      environmentKeys: new Set(nextList.map((env) => env.key)),
      selectedEnvironmentKey: nextSelectedKey,
    });
    setSearchParams(nextParams);
  };

  const handleReorderEnvironments = (key: Key, position: 'before' | 'after', otherKeys: Set<Key>) => {
    const target = environments.find((it) => it.key === key);

    const others: SelectedEnvironment[] = [];
    for (const otherKey of otherKeys) {
      const other = environments.find((it) => it.key === otherKey);
      if (other) {
        others.push(other);
      }
    }

    invariant(target, 'Could not find environment that is being re-ordered');

    let nextList: SelectedEnvironment[];
    if (position === 'after') {
      nextList = arrayMoveAfter(environments, others, target);
    } else {
      nextList = arrayMoveBefore(environments, others, target);
    }

    let didChange = false;
    for (const [index, item] of environments.entries()) {
      if (item !== nextList[index]) {
        didChange = true;
        break;
      }
    }

    if (!didChange) {
      return;
    }

    const nextParams = updateURLSearchParamsWithProjectContext(searchParams, {
      projectKey: context.projectKey,
      environmentKeys: new Set(nextList.map((env) => env.key)),
      selectedEnvironmentKey: context.selectedEnvironmentKey ?? nextList[0].key,
    });
    setSearchParams(nextParams);
  };

  const handleEnvironmentSelectionChange = (key: string) => {
    if (!hasSingleEnvironmentRoute) {
      return;
    }

    const nextParams = updateURLSearchParamsWithProjectContext(searchParams, {
      projectKey: context.projectKey,
      environmentKeys: context.environmentKeys,
      selectedEnvironmentKey: key,
    });
    setSearchParams(nextParams);
  };

  return (
    <EnvironmentControl
      key={project.key}
      selectionMode={hasSingleEnvironmentRoute ? 'single' : 'multiple'}
      settingsMenuItems={settingsMenuItems}
      projectKey={project.key}
      environments={environments}
      selectedKey={context.selectedEnvironmentKey}
      onAddEnvironment={handleSelectEnvironment}
      onRemoveEnvironment={handleRemoveEnvironment}
      onReorderEnvironments={handleReorderEnvironments}
      onSelectionChange={handleEnvironmentSelectionChange}
      onReplaceEnvironment={handleReplaceEnvironment}
    />
  );
}

function EnvironmentControl({
  selectionMode,
  settingsMenuItems,
  projectKey,
  environments,
  selectedKey,
  onAddEnvironment,
  onRemoveEnvironment,
  onReplaceEnvironment,
  onReorderEnvironments,
  onSelectionChange,
}: {
  selectionMode: ComponentProps<typeof EnvironmentSelector>['selectionMode'];
  settingsMenuItems?: { header: string; items: Array<{ label: string; to?: Path; onAction?: () => void }> };
  projectKey: string;
  selectedKey: string | undefined;
  environments: SelectedEnvironment[];
  onAddEnvironment(environment: SelectedEnvironment): void;
  onRemoveEnvironment(key: Key): void;
  onReplaceEnvironment(sourceEnvironmentKey: Key, targetEnvironment: InternalEnvironment): void;
  onReorderEnvironments(key: Key, position: 'before' | 'after', otherKeys: Set<Key>): void;
  onSelectionChange(key: string): void;
}) {
  const handleSelectEnvironment = (environment: SelectedEnvironment) => {
    onAddEnvironment(environment);
  };

  const handleRemoveEnvironment = (key: Key) => {
    onRemoveEnvironment(key);
  };

  const handleReorderEnvironments = (key: Key, position: 'before' | 'after', otherKeys: Set<Key>) => {
    onReorderEnvironments(key, position, otherKeys);
  };

  const handleEnvironmentSelectionChange = (v: 'all' | Set<Key>) => {
    if (v === 'all' || v.size === 0) {
      return;
    }

    const nextSelectedKey = String(Array.from(v)[0]);
    onSelectionChange(nextSelectedKey);
  };

  const handleReplaceEnvironment = (sourceEnvironmentKey: Key, targetEnvironment: InternalEnvironment) => {
    onReplaceEnvironment(sourceEnvironmentKey, targetEnvironment);
  };

  return (
    <EnvironmentSelector
      projectKey={projectKey}
      selectionMode={selectionMode}
      settingsMenuItems={settingsMenuItems}
      items={environments}
      selectedKeys={selectedKey ? new Set([selectedKey]) : undefined}
      excludedIds={new Set(environments.map((it) => it._id))}
      onRemove={handleRemoveEnvironment}
      onReplace={handleReplaceEnvironment}
      onReorder={handleReorderEnvironments}
      onSelectionChange={handleEnvironmentSelectionChange}
      addButton={
        <AddEnvironmentDialogTrigger
          projectKey={projectKey}
          excludedIds={new Set(environments.map((it) => it._id))}
          onSelect={handleSelectEnvironment}
        />
      }
    />
  );
}
