import { QuietErrorBoundary } from '@gonfalon/error-boundaries';

import { EnvironmentResourceV2 } from './EnvironmentResourceV2';
import { MaybeLink } from './MaybeLink';
enum resourceKinds {
  PROJECT = 'project',
  ENVIRONMENT = 'environment',
  FLAG = 'flag',
  EXPERIMENT = 'experiment',
}

export function Resource({ name, id, kind, siteLink }: { name: string; kind: string; siteLink?: string; id: string }) {
  if (kind === resourceKinds.ENVIRONMENT) {
    return (
      <QuietErrorBoundary ignoredStatuses={[404]}>
        <EnvironmentResourceV2 id={id} resourceName={name} />
      </QuietErrorBoundary>
    );
  }

  return <MaybeLink content={name} url={siteLink} />;
}
