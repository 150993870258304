import { ReactNode } from 'react';
import classNames from 'clsx';

import './styles/FormHint.css';

type FormHintProps = {
  children: ReactNode;
  className?: string;
  id?: string;
  name?: string;
};

export function FormHint(props: FormHintProps) {
  const { className, ...other } = props;
  const classes = classNames('Form-hint', className);

  return (
    <div {...other} className={classes}>
      {props.children}
    </div>
  );
}
