export enum FlagVariationsInstructionKind {
  ADD_VARIATION = 'addVariation',
  REMOVE_VARIATION = 'removeVariation',
  UPDATE_VARIATION = 'updateVariation',
  UPDATE_DEFAULT_VARIATION = 'updateDefaultVariation',
}

export type AddVariationSemanticInstruction = {
  kind: FlagVariationsInstructionKind.ADD_VARIATION;
  value: unknown;
  name?: string;
  description?: string;
  originalAIValue?: string;
};

export function makeAddVariationSemanticInstruction(
  value: unknown,
  name?: string,
  description?: string,
  originalAIValue?: string,
): AddVariationSemanticInstruction {
  const instruction: AddVariationSemanticInstruction = {
    kind: FlagVariationsInstructionKind.ADD_VARIATION,
    value,
  };

  if (name !== undefined) {
    instruction.name = name;
  }

  if (description !== undefined) {
    instruction.description = description;
  }

  if (originalAIValue !== undefined) {
    instruction.originalAIValue = originalAIValue;
  }

  return instruction;
}

export type RemoveVariationSemanticInstruction = {
  kind: FlagVariationsInstructionKind.REMOVE_VARIATION;
  variationId: string;
};

export function makeRemoveVariationSemanticInstruction(variationId: string): RemoveVariationSemanticInstruction {
  return {
    kind: FlagVariationsInstructionKind.REMOVE_VARIATION,
    variationId,
  };
}

export type UpdateVariationSemanticInstruction = {
  kind: FlagVariationsInstructionKind.UPDATE_VARIATION;
  variationId: string;
  value?: unknown;
  name?: string;
  description?: string;
};

export function makeUpdateVariationSemanticInstruction(
  variationId: string,
  value?: unknown,
  name?: string,
  description?: string,
): UpdateVariationSemanticInstruction {
  const instruction: UpdateVariationSemanticInstruction = {
    kind: FlagVariationsInstructionKind.UPDATE_VARIATION,
    variationId,
  };

  if (value !== undefined) {
    instruction.value = value;
  }

  if (name !== undefined) {
    instruction.name = name;
  }

  if (description !== undefined) {
    instruction.description = description;
  }

  return instruction;
}

export type UpdateDefaultVariationSemanticInstruction = {
  kind: FlagVariationsInstructionKind.UPDATE_DEFAULT_VARIATION;
  onVariationValue?: string;
  offVariationValue?: string;
};

export function makeUpdateDefaultVariationSemanticInstruction(
  onVariationValue?: string,
  offVariationValue?: string,
): UpdateDefaultVariationSemanticInstruction {
  const instruction: UpdateDefaultVariationSemanticInstruction = {
    kind: FlagVariationsInstructionKind.UPDATE_DEFAULT_VARIATION,
  };

  if (onVariationValue !== undefined) {
    instruction.onVariationValue = onVariationValue;
  }

  if (offVariationValue !== undefined) {
    instruction.offVariationValue = offVariationValue;
  }

  return instruction;
}

export type VariationSemanticInstruction =
  | AddVariationSemanticInstruction
  | RemoveVariationSemanticInstruction
  | UpdateVariationSemanticInstruction
  | UpdateDefaultVariationSemanticInstruction;
