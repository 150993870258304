import { isEqual, isNil } from '@gonfalon/es6-utils';
import { FormVariationBase } from 'ia-poc/services/flag-creation/types';
import { FlagCreationErrorMessages } from 'ia-poc/services/flag-creation/utils';
import { isImmutable, List, Map, Record } from 'immutable';

import { Flag, FlagConfiguration, Rule, Variation, VariationType } from 'utils/flagUtils';

import { ClauseValidation, validateClause } from './clauses';
import { minLength } from './errors';
import {
  FallthroughValidation,
  MeasuredRolloutConfigValidation,
  PrerequisiteValidation,
  RolloutValidation,
  ThresholdsType,
  UserTargetsValidation,
  validateFallthrough,
  validateMeasuredRolloutConfig,
  validatePrerequisite,
  validateRollout,
  validateUserTargets,
  validateVariation,
  variationDuplicateNames,
  variationDuplicateValues,
  variationOrRollout,
  variationTypeMismatch,
} from './targeting';

type TargetingRuleValidationProps = {
  clauses: Map<string, ClauseValidation>;
  rollout: RolloutValidation;
  measuredRolloutConfig: MeasuredRolloutConfigValidation;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  root: Map<any, any>;
  duplicateDescription: string;
};

export class TargetingRuleValidation extends Record<TargetingRuleValidationProps>({
  clauses: Map(),
  rollout: new RolloutValidation(),
  measuredRolloutConfig: new MeasuredRolloutConfigValidation(),
  root: Map(),
  duplicateDescription: '',
}) {
  isEmpty() {
    return (
      this.measuredRolloutConfig.isEmpty() &&
      this.rollout.isEmpty() &&
      this.root.isEmpty() &&
      this.clauses.isEmpty() &&
      !this.duplicateDescription
    );
  }

  isInvalid() {
    return (
      (!this.clauses?.isEmpty() && this.getIn(['clauses', 'items'])?.some((c: ClauseValidation) => c.isInvalid())) ||
      this.measuredRolloutConfig.isInvalid() ||
      this.rollout.isInvalid() ||
      this.duplicateDescription
    );
  }

  isIncomplete() {
    return (
      (!this.clauses?.isEmpty() && this.getIn(['clauses', 'items'])?.some((c: ClauseValidation) => c.isIncomplete())) ||
      this.getIn(['root', 'type']) === 'variationOrRollout' ||
      this.measuredRolloutConfig.isIncomplete() ||
      this.rollout.isIncomplete()
    );
  }

  getMessage() {
    if (this.isEmpty()) {
      return;
    }

    if (this.duplicateDescription) {
      return this.duplicateDescription;
    }

    if (this.isIncomplete()) {
      if (this.getIn(['root', 'type']) === 'variationOrRollout') {
        return 'You must either select a variation to serve or set a percentage rollout';
      }

      if (this.measuredRolloutConfig.isIncomplete()) {
        return this.measuredRolloutConfig.getMessage();
      }
    }
  }
}

type FlagConfigurationValidationProps = {
  fallthrough: FallthroughValidation;
  userTargets: UserTargetsValidation;
  prerequisites: Map<string, PrerequisiteValidation>;
  rules: Map<string, TargetingRuleValidation>;
};

export class FlagConfigurationValidation extends Record<FlagConfigurationValidationProps>({
  fallthrough: new FallthroughValidation(),
  userTargets: new UserTargetsValidation(),
  prerequisites: Map(),
  rules: Map(),
}) {
  isEmpty() {
    return this.fallthrough.isEmpty() && this.prerequisites.isEmpty() && this.rules.isEmpty();
  }

  isInvalid() {
    return (
      this.fallthrough.isInvalid() ||
      this.userTargets.isAlert() ||
      this.prerequisites.some((r) => r.isInvalid()) ||
      this.rules.some((r) => r.isInvalid())
    );
  }

  isIncomplete() {
    return (
      this.fallthrough.isIncomplete() ||
      this.prerequisites.some((r) => r.isIncomplete()) ||
      this.rules.some((r) => r.isIncomplete())
    );
  }

  isValid() {
    return !this.isInvalid() && !this.isIncomplete();
  }
}

export function validateFlagConfiguration(
  config: FlagConfiguration,
  variations: List<Variation>,
  { userTargetAlertThreshold, userTargetWarningThreshold }: ThresholdsType = {},
) {
  let validation = new FlagConfigurationValidation();

  for (const prereq of config.prerequisites) {
    const result = validatePrerequisite(prereq);
    if (!result.isEmpty()) {
      validation = validation.setIn(['prerequisites', prereq._key], result);
    }
  }

  for (const rule of config.rules) {
    const result = validateRule(rule, config.rules);
    if (!result.isEmpty()) {
      validation = validation.setIn(['rules', rule._key], result);
    }
  }

  const userTargetsResult = validateUserTargets(config.targets, {
    userTargetAlertThreshold,
    userTargetWarningThreshold,
  });
  if (!userTargetsResult.isEmpty()) {
    validation = validation.set('userTargets', userTargetsResult);
  }

  const fallthroughResult = validateFallthrough(config.fallthrough, variations);
  if (!fallthroughResult.isEmpty()) {
    validation = validation.set('fallthrough', fallthroughResult);
  }

  return validation;
}

export const validateFlagVariations = (flag: Flag, variationType: VariationType) => {
  let validation = Map();

  if (variationType) {
    if (flag.getVariationType() !== variationType && flag.getPotentialVariationType() !== variationType) {
      validation = validation.set('root', variationTypeMismatch(flag.variations, variationType));
    }
  }

  if (!flag.hasHomogeneousVariationTypes()) {
    validation = validation.set('root', variationTypeMismatch(flag.variations, undefined));
  }

  if (
    flag.variations.some((variationA, index) =>
      flag.variations
        .slice(index + 1)
        .some((variationB) =>
          isEqual(
            isImmutable(variationA.value) ? variationA.value.toJS() : variationA.value,
            isImmutable(variationB.value) ? variationB.value.toJS() : variationB.value,
          ),
        ),
    )
  ) {
    validation = validation.set('root', variationDuplicateValues(flag.variations, flag.variations));
  }

  //Empty strings for names are fine, but any non-empty string must be unique
  const names = flag.variations.map((v) => v.name).filter((v) => v !== '');
  if (names.toSet().size !== names.size) {
    validation = validation.set('root', variationDuplicateNames(flag.variations, flag.variations));
  }

  for (const variation of flag.variations) {
    const result = validateVariation(variation);
    if (!result.isEmpty()) {
      validation = validation.set(variation._key, result);
    }
  }

  return validation;
};

export function validateRule(rule: Rule, allRules?: List<Rule>) {
  let validation = new TargetingRuleValidation();

  if (rule.clauses.isEmpty()) {
    validation = validation.setIn(['clauses', 'root'], minLength(1, rule.clauses.size));
  }

  if ((isNil(rule.variation) && isNil(rule.rollout)) || (!isNil(rule.variation) && !isNil(rule.rollout))) {
    validation = validation.set('root', variationOrRollout(rule.variation, rule.rollout));
  }

  for (const clause of rule.clauses) {
    const result = validateClause(clause, rule.clauses);
    if (!result.isEmpty()) {
      validation = validation.setIn(['clauses', 'items', clause._key], result);
    }
  }

  if (rule.rollout) {
    const result = validateRollout(rule.rollout, undefined, undefined);
    if (!result.isEmpty()) {
      validation = validation.set('rollout', result);
    }
  }

  if (rule.measuredRolloutConfig) {
    const result = validateMeasuredRolloutConfig(rule.measuredRolloutConfig);
    if (!result.isEmpty()) {
      validation = validation.set('measuredRolloutConfig', result);
    }
  }

  if (allRules) {
    const hasDuplicates = allRules.filter(({ description }) => description && description === rule.description);
    if (hasDuplicates.size > 1) {
      validation = validation.set('duplicateDescription', 'duplicate description');
    }
  }

  return validation;
}

export const checkAllVariationNamesUnique = (currentVariations: FormVariationBase[]) => {
  const nameSet = new Set();

  for (const variation of currentVariations) {
    if (!variation.name) {
      continue;
    }

    if (nameSet.has(variation.name)) {
      return FlagCreationErrorMessages.UNIQUE_VARIATION_NAMES;
    }

    nameSet.add(variation.name);
  }

  return true;
};
