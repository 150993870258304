// eslint-disable-next-line no-restricted-imports
import { fromJS, Map, OrderedMap } from 'immutable';
import { normalize, schema } from 'normalizr';

import { convertMapToOrderedMap } from 'utils/collectionUtils';
import http, { jsonToImmutableError, middleware as mw } from 'utils/httpUtils';
import { createPayloadFilter, PayloadFilter, PayloadFilterFilters } from 'utils/payloadFilterUtils';

const payloadFiltersEntity = new schema.Entity('payloadFilters', {}, { idAttribute: 'key' });

export const getPayloadFilters = async (projKey: string, filters: PayloadFilterFilters) => {
  const queryString = filters.toBackendQueryString();
  return http
    .get(`/api/v2/projects/${projKey}/payload-filters${queryString}`, { headers: { 'LD-API-Version': 'beta' } })
    .then(async (res) =>
      res.json().then((responseJson) => {
        const data = normalize(responseJson, { items: [payloadFiltersEntity] });
        return fromJS(data).withMutations((map: Map<string, PayloadFilter>) => {
          map.updateIn(['entities', 'payloadFilters'], (f: Map<string, PayloadFilter>) =>
            convertMapToOrderedMap(f, data.result.items),
          );
          map.updateIn(['entities', 'payloadFilters'], (payloadFilters: Map<string, PayloadFilter>) =>
            payloadFilters ? payloadFilters.map(createPayloadFilter) : OrderedMap(),
          );
        });
      }),
    )
    .catch(jsonToImmutableError);
};

export async function getPayloadFilter(projKey: string, filterKey: string): Promise<PayloadFilter> {
  return http
    .get(`/api/v2/projects/${projKey}/payload-filters/${filterKey}`, { headers: { 'LD-API-Version': 'beta' } })
    .then(mw.json)
    .then(createPayloadFilter)
    .catch(jsonToImmutableError);
}

export async function postPayloadFilter(payloadFilter: PayloadFilter, projKey: string): Promise<PayloadFilter> {
  return http
    .post(`/api/v2/projects/${projKey}/payload-filters`, { headers: { 'LD-API-Version': 'beta' }, body: payloadFilter })
    .then(mw.json)
    .then(createPayloadFilter)
    .catch(jsonToImmutableError);
}

export async function patchPayloadFilter(
  projKey: string,
  original: PayloadFilter,
  modified: PayloadFilter,
): Promise<PayloadFilter> {
  const key = original.key;
  return http
    .patch(`/api/v2/projects/${projKey}/payload-filters/${key}`, {
      headers: { 'LD-API-Version': 'beta', 'If-Match': original.etag },
      body: modified.toJS(),
    })
    .then((response) => {
      const updatedETag = response.headers.get('etag');
      if (updatedETag) {
        return modified.set('etag', updatedETag);
      }
      return modified;
    })
    .catch(jsonToImmutableError);
}
