import { isEqual } from '@gonfalon/es6-utils';

import type { UIFlagFilter } from '../types';

import { defaultFlagFilter } from './defaultFlagFilter';

export function isDefaultFlagFilterValue<K extends keyof UIFlagFilter>(name: K, value: UIFlagFilter[K]) {
  if (value === undefined) {
    return false;
  }

  return isEqual(value, defaultFlagFilter[name]);
}
