import { type ProjectContext } from './projectContext';

export function isProjectContextValid(context: ProjectContext) {
  return (
    context.projectKey.length > 0 &&
    context.environmentKeys.size > 0 &&
    context.selectedEnvironmentKey.length > 0 &&
    context.environmentKeys.has(context.selectedEnvironmentKey)
  );
}
