export enum TagKind {
  FLAG = 'flag',
  SEGMENT = 'segment',
  METRIC = 'metric',
  PROJECT = 'project',
  ENVIRONMENT = 'environment',
  RELEASE_PIPELINE = 'release-pipeline',
}

export enum TagFilterType {
  SELECT = 'select',
  APPLIED = 'appliedFilter',
  FILTER = 'filter',
}
