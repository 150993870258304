import { AriaRole } from 'react';
import { isString } from '@gonfalon/es6-utils';
import classNames from 'clsx';

import {
  AlignValue,
  AlignValueObject,
  DirectionValue,
  DirectionValueObject,
  GapValue,
  GapValueObject,
  GridLayoutValue,
  GridLayoutValueObject,
  JustifyValue,
  JustifyValueObject,
} from './types';

import './styles/Grid.css';

export type GridProps = {
  layout?: GridLayoutValue | GridLayoutValueObject;
  align?: AlignValue | AlignValueObject;
  justify?: JustifyValue | JustifyValueObject;
  direction?: DirectionValue | DirectionValueObject;
  gap?: GapValue | GapValueObject;
  className?: string;
  children?: React.ReactNode;
  role?: AriaRole;
};

export function Grid({
  className,
  gap = '4',
  layout,
  align,
  justify,
  direction = 'row',
  children,
  ...otherProps
}: GridProps) {
  let directionClass;
  if (isString(direction)) {
    directionClass = `Grid--${direction}`;
  } else {
    directionClass = classNames(
      direction.mobile ? `xs-Grid--${direction.mobile}` : '',
      direction.tablet ? `sm-Grid--${direction.tablet}` : '',
      direction.desktop ? `md-Grid--${direction.desktop}` : '',
      direction.wide ? `lg-Grid--${direction.wide}` : '',
    );
  }

  let gapClass;
  if (isString(gap)) {
    gapClass = `Grid--gap-${gap}`;
  } else {
    gapClass = classNames(
      gap.mobile ? `xs-Grid--gap-${gap.mobile}` : '',
      gap.tablet ? `sm-Grid--gap-${gap.tablet}` : '',
      gap.desktop ? `md-Grid--gap-${gap.desktop}` : '',
      gap.wide ? `lg-Grid--gap-${gap.wide}` : '',
    );
  }

  let layoutClass;
  if (layout) {
    if (isString(layout)) {
      layoutClass = `Grid--${layout}`;
    } else {
      layoutClass = classNames(
        layout.mobile ? `xs-Grid--${layout.mobile}` : '',
        layout.tablet ? `sm-Grid--${layout.tablet}` : '',
        layout.desktop ? `md-Grid--${layout.desktop}` : '',
        layout.wide ? `lg-Grid--${layout.wide}` : '',
      );
    }
  }

  let alignClass;
  if (align) {
    if (isString(align)) {
      alignClass = `Grid--${align}`;
    } else {
      alignClass = classNames(
        align.mobile ? `xs-Grid--${align.mobile}` : '',
        align.tablet ? `sm-Grid--${align.tablet}` : '',
        align.desktop ? `md-Grid--${align.desktop}` : '',
        align.wide ? `lg-Grid--${align.wide}` : '',
      );
    }
  }

  let justifyClass;
  if (justify) {
    if (isString(justify)) {
      justifyClass = `Grid--${justify}`;
    } else {
      justifyClass = classNames(
        justify.mobile ? `xs-Grid--${justify.mobile}` : '',
        justify.tablet ? `sm-Grid--${justify.tablet}` : '',
        justify.desktop ? `md-Grid--${justify.desktop}` : '',
        justify.wide ? `lg-Grid--${justify.wide}` : '',
      );
    }
  }

  const classes = classNames('Grid', className, layoutClass, gapClass, alignClass, justifyClass, directionClass);

  return (
    <div className={classes} {...otherProps}>
      {children}
    </div>
  );
}
