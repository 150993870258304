import { forwardRef } from 'react';
import type { TextareaAutosizeProps } from 'react-textarea-autosize';
import TextareaAutosize from 'react-textarea-autosize';
import cx from 'clsx';

import { createFieldErrorId } from 'utils/formUtils';

import './styles/Form.css';

const classes = (c?: string) => cx('FormInput', c);

function onKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
  if (e.key === 'ArrowRight' || e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
    e.stopPropagation();
  }
  if (e.key === 'Escape') {
    e.nativeEvent.stopImmediatePropagation();
  }
}

export const AutosizeTextArea = forwardRef<HTMLTextAreaElement, TextareaAutosizeProps>(
  ({ className, ...props }: Omit<TextareaAutosizeProps, 'ref'>, ref) => (
    <TextareaAutosize
      {...props}
      ref={ref}
      className={classes(className)}
      aria-describedby={props['aria-describedby'] || createFieldErrorId(props.id)}
      onKeyDown={onKeyDown}
    />
  ),
);
