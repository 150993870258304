import { createStorageKey } from './createStorageKey';
import { dispatchStorageEvent } from './dispatchStorageEvent';
import { getBrowserStorage } from './getBrowserStorage';
import { noopStorageSlot, StorageSlot } from './StorageSlot';
import { defaultStorageType, StorageType } from './StorageType';

export function createStorageSlot(
  key: string,
  { storageType, isEnabled }: { storageType?: StorageType; isEnabled?: boolean } = { storageType: defaultStorageType },
): StorageSlot {
  if (isEnabled === false) {
    return noopStorageSlot;
  }

  const storageKey = createStorageKey(key);
  const storage = getBrowserStorage(storageType);
  if (storage === undefined) {
    return noopStorageSlot;
  }

  return {
    get: () => {
      try {
        return storage.getItem(storageKey);
      } catch (err) {
        return null;
      }
    },
    set: (newValue) => {
      try {
        const oldValue = storage.getItem(storageKey);
        storage.setItem(storageKey, newValue);
        dispatchStorageEvent({
          key: storageKey,
          oldValue,
          newValue,
          storage,
        });
      } catch (err) {
        // noop
      }
    },
    remove: () => {
      try {
        const oldValue = storage.getItem(storageKey);
        storage.removeItem(storageKey);
        dispatchStorageEvent({ key: storageKey, oldValue, newValue: null, storage });
      } catch (err) {
        // noop
      }
    },
    subscribe: (onChange) => {
      try {
        const listener = (event: StorageEvent) => {
          if (event.storageArea === storage && event.key === storageKey) {
            onChange(event);
          }
        };
        window.addEventListener('storage', listener);
        return () => window.removeEventListener('storage', listener);
      } catch (err) {
        return () => {};
      }
    },
  };
}
