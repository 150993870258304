import { constants } from 'utils/reduxUtils';

/* eslint-disable import/no-default-export */
export default constants('archive', [
  'REQUEST_FLAG_QUERIED_ENVS',
  'REQUEST_FLAG_QUERIED_ENVS_FAILED',
  'RECEIVE_FLAG_QUERIED_ENVS',
  'SET_PRODUCTION_ENVIRONMENTS',
  'GET_PRODUCTION_ENVIRONMENTS',
  'REQUEST_PRODUCTION_ENVIRONMENTS',
  'REQUEST_FLAG_STATUSES_QUERIED_ENVS',
  'REQUEST_FLAG_STATUSES_QUERIED_ENVS_FAILED',
  'RECEIVE_FLAG_STATUSES_QUERIED_ENVS',
  'ARCHIVE_FLAG',
  'ARCHIVE_FLAG_DONE',
  'ARCHIVE_FLAG_FAILED',
  'RESTORE_FLAG',
  'RESTORE_FLAG_DONE',
  'RESTORE_FLAG_FAILED',
  'DESTROY_ARCHIVE_FORM',
]);
