import { enableSelfServeUBBWithAnnualCommits } from '@gonfalon/dogfood-flags';
import nullthrows from 'nullthrows';

import { ModernSubscription, PlanTypes } from '../../types';

// Plan types that can be used when fetching for limits.
// Important in the case of trials, where there aren't limits defined for
// STANDARD_TRIAL2021 and instead we use the limits defined for PROFESSIONAL2021 plans.
export function getPlanTypeForLimits(modernSubscription: ModernSubscription) {
  const planType = nullthrows(modernSubscription.planType);
  switch (planType) {
    case PlanTypes.STANDARD_TRIAL2021:
      return PlanTypes.PROFESSIONAL2021;
    case PlanTypes.STARTER2021:
    case PlanTypes.PROFESSIONAL2021:
    case PlanTypes.FOUNDATION2023:
      if (enableSelfServeUBBWithAnnualCommits()) {
        return planType;
      }
    // eslint-disable-next-line no-fallthrough
    default:
      return PlanTypes.PROFESSIONAL;
  }
}
