import { Flag } from 'utils/flagUtils';
import { GenerateActionType } from 'utils/reduxUtils';
import { Role } from 'utils/roleUtils';
import { ResourceKind } from 'utils/saveButtonUtils';
import { Segment } from 'utils/segmentUtils';
import { User } from 'utils/userUtils';

export const editChangeDescription = (
  resourceKind: ResourceKind,
  resource: Flag | User | Segment | Role,
  comment: string,
) =>
  ({
    type: 'comments/EDIT_CHANGE_DESCRIPTION',
    resourceKind,
    resource,
    comment,
  }) as const;

const CommentsActionCreators = {
  editChangeDescription,
};

export type CommentsAction = GenerateActionType<typeof CommentsActionCreators>;
