import { createContext, ReactNode, useContext } from 'react';

import { useEnablePostExperimentationExperience } from '../hooks/useEnablePostExperimentationExperience';

type PostExperimentationExperienceContextType = {
  enablePostExperimentationExperience: boolean;
  isPostExperimentationExperienceReady: boolean;
};

const PostExperimentationExperienceContext = createContext<PostExperimentationExperienceContextType>({
  enablePostExperimentationExperience: false,
  isPostExperimentationExperienceReady: false,
});

/**
 * Hook allows for ease-of-use of the PostExperimentationExperienceContext
 */
export const usePostExperimentationExperienceContext = () => useContext(PostExperimentationExperienceContext);

/**
 * Provider utilizes the useEnablePostExperimentationExperience hook to determine
 * whether to enable the post-experimentation experience and provides that to all children in the tree.
 * Wraps the routes in the ExperimentationContainer, as well as the flag experiment routes in appRoutes.
 */
export const PostExperimentationExperienceProvider = ({ children }: { children: ReactNode }) => {
  const { enablePostExperimentationExperience, isPostExperimentationExperienceReady } =
    useEnablePostExperimentationExperience();

  return (
    <PostExperimentationExperienceContext.Provider
      value={{ enablePostExperimentationExperience, isPostExperimentationExperienceReady }}
    >
      {children}
    </PostExperimentationExperienceContext.Provider>
  );
};
