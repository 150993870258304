import { hasSubscription as hasLegacySubscription } from '../internal/legacySubscription';
import { hasSubscription as hasModernSubscription } from '../internal/modernSubscription';
import { Subscription } from '../types';

import { isModernSubscription } from './isModernSubscription';

export function hasSubscription(subscription: Subscription) {
  if (isModernSubscription(subscription)) {
    return hasModernSubscription(subscription);
  }

  return hasLegacySubscription(subscription);
}
