// eslint-disable-next-line no-restricted-imports
import { fromJS, Map, OrderedMap, Record } from 'immutable';
import { normalize, schema } from 'normalizr';

import { convertMapToOrderedMap } from 'utils/collectionUtils';
import http, { jsonToImmutable, jsonToImmutableError } from 'utils/httpUtils';
import { Link } from 'utils/linkUtils';
import Logger from 'utils/logUtils';
import { createTeamsBulkAddMembersResponse } from 'utils/teamsBulkAddMembersUtils';
import {
  createTeam as createTeamRecord,
  createTeamCustomRoleSummaryItem as createTeamCustomRoleSummaryItemRecord,
  createTeamMaintainerSummary,
  Team,
  TeamCustomRolesFilters,
  TeamCustomRoleSummary,
  TeamForm,
  TeamProps,
  TeamsFilters,
  TeamsSemanticPatchInstructions,
} from 'utils/teamsUtils';

const logger = Logger.get('TeamsAPI');

const teamsEntity = new schema.Entity(
  'teams',
  {},
  {
    idAttribute: 'key',
  },
);

const teamCustomRolesEntity = new schema.Entity('teamCustomRoles', {}, { idAttribute: 'key' });

const url = '/api/v2/teams';

export type teamExpandOptions = 'maintainers' | 'members' | 'projects' | 'roles';

export const getTeams = async (filters: TeamsFilters, expandOptions?: teamExpandOptions[]) => {
  const expandString = expandOptions?.length ? `&expand=${expandOptions?.join(',')}` : '';
  const queryString = `${filters.toBackendQueryString()}${expandString}`;
  try {
    const response = await http.get(`${url}${queryString}`, {
      // pin to version 3 of the API until we fix usage to not request limits above 100
      headers: { 'Ld-Api-Version': '20220603' },
    });
    const responseJson = await response.json();
    const normalized = normalize(responseJson, { items: [teamsEntity] });
    const immutable = fromJS(normalized);
    return immutable.withMutations((map: Map<string, OrderedMap<string, Team>>) => {
      map.updateIn(['entities', 'teams'], (teams) => convertMapToOrderedMap(teams, normalized.result.items));
      map.updateIn(['entities', 'teams'], (teams) => (teams ? teams.map(createTeamRecord) : OrderedMap()));
    });
  } catch (e) {
    throw e;
  }
};

export type LoadMoreTeamMaintainersArgs = {
  teamKey: string;
  nextPageLink?: Link;
};

export const getTeamMaintainers = async ({ teamKey, nextPageLink }: LoadMoreTeamMaintainersArgs) => {
  const nextPageOfMaintainers = nextPageLink?.get('href') || `/api/v2/teams/${teamKey}/maintainers`;

  const nextPage = await http.get(nextPageOfMaintainers, { beta: true }).then(jsonToImmutable, jsonToImmutableError);

  return createTeamMaintainerSummary(nextPage);
};

export const getTeam = async (teamKey: string) => {
  try {
    const response = (await http
      .get(`${url}/${teamKey}?expand=maintainers`, { beta: true })
      .then(jsonToImmutable, jsonToImmutableError)) as Partial<TeamProps>;
    return createTeamRecord(response);
  } catch (e) {
    throw e;
  }
};

export const postTeam = async (team: TeamForm) => {
  try {
    const response = await http
      .post(`${url}?expand=maintainers,roles`, {
        beta: true,
        body: team.toRep(),
      })
      .then(jsonToImmutable, jsonToImmutableError);
    return createTeamRecord(response);
  } catch (e) {
    logger.error('createTeam error', (e as Record<Error>)?.toJS() ?? e);
    throw e;
  }
};

export const patchTeam = async (teamKey: string, instructions: TeamsSemanticPatchInstructions[]) => {
  try {
    const response = await http
      .patch(`${url}/${teamKey}?expand=maintainers,roles`, {
        beta: true,
        body: { instructions },
      })
      .then(jsonToImmutable, jsonToImmutableError);
    return createTeamRecord(response);
  } catch (e) {
    logger.error('patchTeam error', (e as Record<Error>)?.toJS() ?? e);
    throw e;
  }
};

export async function deleteTeam(team: Team): Promise<void> {
  return http.delete(`${url}/${team.key}`, { beta: true }).catch(jsonToImmutableError);
}

export const postTeamMembers = async (teamKey: string, file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  return http
    .post(`${url}/${teamKey}/members`, { beta: true, body: formData })
    .then(jsonToImmutable, jsonToImmutableError)
    .then(createTeamsBulkAddMembersResponse)
    .catch((e) => {
      logger.error('postTeamMembers error', (e as Record<Error>)?.toJS() ?? e);
      throw e;
    });
};

export const getTeamCustomRoles = async (teamKey: string, filters: TeamCustomRolesFilters) => {
  const queryString = `${filters.toBackendQueryString()}`;
  try {
    const response = await http.get(`${url}/${teamKey}/roles${queryString}`, { beta: true });
    const responseJson = await response.json();
    const normalized = normalize(responseJson, { items: [teamCustomRolesEntity] });
    const immutable = fromJS(normalized);
    return immutable.withMutations((map: Map<string, OrderedMap<string, TeamCustomRoleSummary>>) => {
      map.updateIn(['entities', 'teamCustomRoles'], (roles) => convertMapToOrderedMap(roles, normalized.result.items));
      map.updateIn(['entities', 'teamCustomRoles'], (roles) =>
        roles ? roles.map(createTeamCustomRoleSummaryItemRecord) : OrderedMap(),
      );
    });
  } catch (e) {
    throw e;
  }
};
