export enum TargetsInstructionKind {
  ADD_TARGETS = 'addTargets',
  REMOVE_TARGETS = 'removeTargets',
  REPLACE_TARGETS = 'replaceTargets',
}

export type AddTargetsSemanticInstruction = {
  kind: TargetsInstructionKind.ADD_TARGETS;
  contextKind: string;
  values: string[];
  variationId: string;
};

export type RemoveTargetsSemanticInstruction = {
  kind: TargetsInstructionKind.REMOVE_TARGETS;
  contextKind: string;
  values: string[];
  variationId: string;
};

export type UpdateTargetsSemanticInstruction = AddTargetsSemanticInstruction | RemoveTargetsSemanticInstruction;

export type ReplaceTargetsSemanticInstruction = {
  kind: TargetsInstructionKind.REPLACE_TARGETS;
  contextKind: string;
  targets: Array<{ variationId: string; values: string[]; contextKind: string }>;
};
