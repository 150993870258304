import { createTrackerForCategory } from '@gonfalon/analytics';
// eslint-disable-next-line no-restricted-imports
import { Collection, fromJS, get, List, Map, OrderedMap, Record } from 'immutable';
import nullthrows from 'nullthrows';
import invariant from 'tiny-invariant';

import {
  createWeightedVariation,
  ExperimentRollout,
  Rollout,
  sortFallThroughVariations,
  Variation,
} from 'utils/flagUtils';
import { makeSemanticInstruction } from 'utils/instructions/shared/helpers';
import {
  AllFlagInstructionKinds,
  FlagConflictKind,
  InstructionIndexToConflictsInfo,
  SemanticInstruction,
} from 'utils/instructions/shared/types';
import { max, min, minLength } from 'utils/validation/errors';
import { FlagConfigurationValidation, TargetingRuleValidation } from 'utils/validation/flags';
import { invalidAttribute, RolloutValidation } from 'utils/validation/targeting';

import {
  AddClausesSemanticInstruction,
  ClauseInstructionKind,
  UpdateClauseSemanticInstruction,
} from './instructions/clauses/types';
import { createAggregateRule } from './instructions/rules/aggregateHelpers';
import {
  AddRuleSemanticInstruction,
  AggregateRule,
  RuleInstructionKind,
  SemanticRuleDescriptionInstruction,
  UpdateRuleVariationOrRolloutSemanticInstruction,
} from './instructions/rules/types';
import { validateClause } from './validation/clauses';
import { createClause } from './clauseUtils';
import { CreateFunctionInput, ImmutableMap } from './immutableUtils';

export enum ScheduleKind {
  ABSOLUTE = 'absolute',
  RELATIVE = 'relative',
}

export enum WaitDurationUnit {
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'calendarDay',
  WEEK = 'calendarWeek',
}

export class ScheduledChange extends Record({
  _id: '',
  _version: 0,
  executionDate: 0,
  instructions: List(),
  _maintainerId: '',
  _creationDate: 0,
  conflicts: List(),
  _links: {
    self: {
      href: '',
      type: '',
    },
  },
}) {
  getCreationDate() {
    return this._creationDate;
  }
  getExecutionDate() {
    return this.executionDate;
  }

  getInstructionKinds() {
    return Object.values(this.instructions.toJS()).map((ins) => ins.kind);
  }

  getInstructions() {
    return this.instructions;
  }

  getID() {
    return this._id;
  }

  getMaintainerId() {
    return this._maintainerId;
  }
}

export function createScheduledChange(props: CreateFunctionInput<ScheduledChange> = {}) {
  const scheduledFlagChanges = props instanceof ScheduledChange ? props : new ScheduledChange(fromJS(props));
  return scheduledFlagChanges.update('instructions', (vs) =>
    vs.size > 0 ? vs.map((v) => makeSemanticInstruction(get(v, 'kind', ''), v)) : List(),
  );
}

export const getScheduledChangesForInstructionKinds = ({
  scheduledChanges,
  instructions,
}: {
  scheduledChanges?: FlagConfigScheduledChangesState;
  instructions: AllFlagInstructionKinds[];
}): List<ScheduledChange> | null => {
  if (!scheduledChanges) {
    return null;
  }
  const scheduledChangesList = List(
    scheduledChanges
      .valueSeq()
      .filter((scheduledChange) =>
        instructions.some((targetIns) => scheduledChange.getInstructionKinds().includes(targetIns)),
      ),
  );
  return scheduledChangesList.size > 0 ? scheduledChangesList : null;
};

export const handleRolloutLogicForScheduledChanges = (
  r: Rollout,
  weight: number,
  variations: List<Variation>,
  index?: number,
) => {
  if (!r.variations.size) {
    return r.update('variations', (vs) => {
      variations.forEach((v, i) => {
        // eslint-disable-next-line  no-param-reassign
        vs = vs.push(
          createWeightedVariation({
            variation: i,
            weight: index === i ? weight : 0,
          }),
        );
      });
      //Then sort the list of fallthrough variations
      return sortFallThroughVariations(vs);
    });
  } else {
    return r.updateIn(['variations', index], (wv) => wv.set('weight', weight));
  }
};

export const getInstructionIndexToConflictsInfoForScheduledChanges = (
  conflictingChanges: ConflictingChangesState,
): InstructionIndexToConflictsInfo => {
  const instructionIndexToConflictInfo: InstructionIndexToConflictsInfo = {};

  const conflicts = conflictingChanges.get('conflicts');
  if (!conflicts) {
    return instructionIndexToConflictInfo;
  }

  conflicts.forEach((instruction, index) => {
    const conflictsListForInstruction = instruction.get('conflicts');
    if (conflictsListForInstruction.size === 0) {
      return;
    }
    const instructionConflictsInfo = makeScheduledChangesConflictInfo(conflictsListForInstruction);
    const summaryConflictKind = instructionConflictsInfo.every(
      (c) => c.conflictKind === FlagConflictKind.PENDING_CHANGES_WILL_FAIL,
    )
      ? FlagConflictKind.PENDING_CHANGES_WILL_FAIL
      : FlagConflictKind.PROPOSED_SCHEDULED_CHANGES_WILL_FAIL;

    instructionIndexToConflictInfo[index] = {
      conflicts: instructionConflictsInfo,
      summaryConflictKind,
    };
  });

  return instructionIndexToConflictInfo;
};

export type ConflictsList = List<ConflictReason>;
export type ConflictReason = ImmutableMap<{
  _id: string;
  reason: string;
  pendingChangeWillFail: boolean;
}>;

const makeScheduledChangesConflictInfo = (conflictsListForInstruction: ConflictsList) => {
  const conflictsInfoList: Array<{
    conflictReason: string;
    conflictKind: FlagConflictKind;
  }> = [];

  conflictsListForInstruction.forEach((conflict) => {
    const conflictReason = conflict.get('reason');
    const conflictKind = conflict.get('pendingChangeWillFail')
      ? FlagConflictKind.PENDING_CHANGES_WILL_FAIL
      : FlagConflictKind.PROPOSED_SCHEDULED_CHANGES_WILL_FAIL;
    conflictsInfoList.push({ conflictReason, conflictKind });
  });

  return conflictsInfoList;
};

export const mergeRulesAndClauses = (
  groupedRules: Collection.Keyed<string, Collection<number, SemanticInstruction>>,
) => {
  let rules: List<AggregateRule> = List([]);
  groupedRules.valueSeq().forEach((ruleAndInstruction) => {
    const rule = createAggregateRule();

    const groupedByInstruction = ruleAndInstruction.valueSeq().groupBy((item) => get(item, 'kind', ''));
    const addRule = groupedByInstruction.get(RuleInstructionKind.ADD_RULE) as
      | Collection<number, AddRuleSemanticInstruction>
      | undefined;

    const addRuleDescription = groupedByInstruction.get(RuleInstructionKind.UPDATE_RULE_DESCRIPTION)?.toList() as
      | List<SemanticRuleDescriptionInstruction>
      | undefined;

    const updateClause = groupedByInstruction.get(ClauseInstructionKind.UPDATE_CLAUSE)?.toList() as
      | List<UpdateClauseSemanticInstruction>
      | undefined;
    const addClauses = groupedByInstruction.get(ClauseInstructionKind.ADD_CLAUSES)?.toList() as
      | List<AddClausesSemanticInstruction>
      | undefined;
    const updateRuleVariation = groupedByInstruction
      .get(RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT)
      ?.toArray() as UpdateRuleVariationOrRolloutSemanticInstruction[] | undefined;

    let mergedRules = rule;
    if (addRule) {
      mergedRules = {
        ...mergedRules,
        ...addRule.first(),
        clauses: addRule.first<AddRuleSemanticInstruction>().clauses,
      };
    }
    if (updateClause) {
      mergedRules = {
        ...mergedRules,
        clauses: [...mergedRules.clauses, ...updateClause.map((d) => d.clause)],
        ruleId: nullthrows(updateClause.get(0)).ruleId,
        kind: nullthrows(updateClause.get(0)).kind,
      };
    }
    if (addClauses) {
      mergedRules = {
        ...mergedRules,
        clauses: [
          ...mergedRules.clauses,
          ...addClauses
            .first<AddClausesSemanticInstruction>()
            .clauses.map((clause) => createClause(clause as Parameters<typeof createClause>[0])),
        ],
        ruleId: nullthrows(addClauses.get(0)).ruleId,
        kind: nullthrows(addClauses.get(0)).kind,
      };
    }
    if (updateRuleVariation) {
      nullthrows(updateRuleVariation[0]);
      mergedRules = {
        ...mergedRules,
        ...updateRuleVariation[0],
        rolloutWeights: updateRuleVariation[0].rolloutWeights,
        kind: updateRuleVariation[0].kind,
      };
    }
    if (addRuleDescription) {
      mergedRules = {
        ...mergedRules,
        description: nullthrows(addRuleDescription.get(0)).description,
        kind: nullthrows(addRuleDescription.get(0)).kind,
        ruleId: nullthrows(addRuleDescription.get(0)).ruleId,
      };
    }

    rules = rules.push(createAggregateRule(mergedRules));
  });
  return rules;
};

export type ScheduleFetchType = {
  instructions: SemanticInstruction[];
  executionDate: number;
  existingScheduledChangeId?: string;
};
export type SchedulePostType = ScheduleFetchType & {
  comment?: string;
};

export type ScheduledPatchType = SchedulePostType & {
  workflowID: string;
};

export type ScheduledFlagChangesOptions = { shouldRefetchScheduledFlagChanges?: boolean; shouldNotify?: boolean };

export const trackScheduleFlagChangesEvent = createTrackerForCategory('Schedule Changes');
export const trackScheduledConflictsEvent = createTrackerForCategory('ScheduledConflicts');

/**
 * Returns true if the conflict stored at the specified index in the ConflictingChangesState has some conflicts
 */
export const hasConflictsAtIndex = (conflictingChangesState: ConflictingChangesState, index: number) =>
  conflictingChangesState.getIn(['conflicts', index, 'conflicts'])?.size > 0;

/**
 * Returns true if the conflicting changes state has a conflict
 */
export const hasConflicts = (conflictingChangesState: ConflictingChangesState) => {
  const list = conflictingChangesState.get('conflicts');
  if (list) {
    return list.some((v, index) => hasConflictsAtIndex(conflictingChangesState, index));
  }
  return false;
};

export function validateRolloutInstruction(
  rolloutWeights: Map<string, number>,
  rolloutBucketBy?: string,
  experimentAllocation?: ExperimentRollout,
) {
  let validation = new RolloutValidation();

  const total = rolloutWeights.reduce((sum, w) => sum + w, 0);

  if (total > 100000) {
    validation = validation.set('root', max(100000, total));
  }

  if (total < 100000 && !experimentAllocation) {
    validation = validation.set('root', min(100000, total));
  }

  if (experimentAllocation && total < 0) {
    validation = validation.set('root', min(0, total));
  }

  if (rolloutBucketBy === 'secondary') {
    validation = validation.set('bucketBy', invalidAttribute(rolloutBucketBy));
  }

  return validation;
}

export function validateRuleInstructions(rule: AggregateRule) {
  let validation = new TargetingRuleValidation();
  if (rule.clauses.length === 0 && rule.kind !== RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT) {
    validation = validation.setIn(['clauses', 'root'], minLength(1, rule.clauses.length));
  }

  for (const clause of rule.clauses) {
    invariant(clause, 'clause must be truthy');
    const result = validateClause(clause);
    if (!result.isEmpty()) {
      validation = validation.setIn(['clauses', 'items', clause?._key], result);
    }
  }

  if (rule.rolloutWeights) {
    const result = validateRolloutInstruction(
      Map(rule.rolloutWeights),
      rule.rolloutBucketBy,
      rule.experimentAllocation,
    );
    if (!result.isEmpty()) {
      validation = validation.set('rollout', result);
    }
  }

  return validation;
}

export function validateScheduleFlagInstructions(rules: List<AggregateRule>) {
  let validation = new FlagConfigurationValidation();

  for (const rule of rules) {
    const result = validateRuleInstructions(rule);
    if (!result.isEmpty()) {
      validation = validation.setIn(['rules', rule.ruleId], result);
    }
  }

  return validation;
}

export const hasExistingScheduledChangesConflicts = (scheduledFlagChanges: FlagConfigScheduledChangesState) =>
  scheduledFlagChanges
    .valueSeq()
    .map((d) => d.conflicts)
    .flatten(1)
    .toJS().length > 0;

export type FlagConfigScheduledChangesState = OrderedMap<string, ScheduledChange>;

export type InstructionConflictsList = List<
  ImmutableMap<{
    kind: AllFlagInstructionKinds;
    conflicts: ConflictsList;
  }>
>;

export type ConflictingChangesState = ImmutableMap<{
  conflicts: InstructionConflictsList | null;
  isLoading: boolean;
  error: Error;
}>;
