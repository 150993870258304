import { Ref, useRef } from 'react';
import cx from 'clsx';
import { Tooltip } from 'launchpad';

import { VariationColor } from 'components/VariationColor';
import styles from 'stylesheets/components/Variation.module.css';

export type VariationProps = {
  value: string;
  name?: string;
  color: string;
  description?: string;
  inlineDescription?: boolean;
  className?: string;
  variationDisplayClassName?: string;
  variationDisplayRef?: Ref<HTMLSpanElement | undefined>;
  onClick?(): void;
  disabled?: boolean;
};

type VariationContentProps = VariationProps & {
  displayValue: string | JSX.Element;
};

function VariationContent({
  value,
  name,
  color,
  description,
  inlineDescription,
  className,
  variationDisplayClassName,
  variationDisplayRef,
  displayValue,
  disabled,
  onClick,
  ...props
}: VariationContentProps) {
  const ref = useRef(null);
  const handleOnClick = () => onClick && onClick();
  function onKeyDown(event: React.KeyboardEvent<HTMLSpanElement>) {
    if (event.key === 'Enter' && onClick) {
      onClick();
    }
  }

  const variationInfo = (
    <>
      {color && <VariationColor className={styles.color} size="small" fill={color} />}
      <span
        ref={variationDisplayRef as Ref<HTMLSpanElement>}
        data-test-id={`variation-display-${displayValue}`}
        className={cx('Variation-display', variationDisplayClassName)}
      >
        {name || displayValue}
      </span>
    </>
  );

  return onClick || description ? (
    // We can't use react-aria's useButton here because it interferes with event propagation.
    // We're required to explicitly set tab-index, role, and onClick to ensure all child elements are clickable.
    <>
      <span
        {...props}
        tabIndex={0}
        role="button"
        aria-label="Variation"
        onClick={handleOnClick}
        onKeyDown={onKeyDown}
        ref={ref}
        className={className}
      >
        {variationInfo}
      </span>
      {inlineDescription && description && <div className={styles.description}>{description}</div>}
    </>
  ) : (
    <>
      <span className={className} ref={ref}>
        {variationInfo}
      </span>
      {inlineDescription && description && <div className={styles.description}>{description}</div>}
    </>
  );
}

/* eslint-disable import/no-default-export */
export default function Variation(props: VariationProps) {
  const { value, description, inlineDescription, className } = props;
  const classes = cx(styles.variation, className);
  const displayValue = value === '' ? <span className="u-very-subtle">(empty string)</span> : value;
  const content = <VariationContent {...props} className={classes} displayValue={displayValue} />;

  if (inlineDescription) {
    return content;
  }

  if (description) {
    return (
      <Tooltip targetClassName={styles.descriptionPopover} placement="top" target={content} content={description} />
    );
  }

  return content;
}
