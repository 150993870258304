import { createSearchParams } from 'react-router-dom';
import { createStorageSlot } from '@gonfalon/browser-storage';
import { cacheFlagFiltersAndSortOrdering } from '@gonfalon/dogfood-flags';

import { parseUIFlagFilterFromBrowser } from './parseUIFlagFilterFromBrowser';
import { cacheKey } from './useCachedFlagListFilters';

const slot = createStorageSlot(cacheKey, {
  storageType: 'sessionStorage',
  isEnabled: cacheFlagFiltersAndSortOrdering(),
});

export function readCachedFlagListFilters() {
  const raw = slot.get();

  if (!raw) {
    return {};
  }

  const params = createSearchParams(raw);

  return parseUIFlagFilterFromBrowser(params);
}
