import { useAccountContext, useSelectedEnvironmentKey } from '@gonfalon/context';
import { checkFlagConfigurationAccess, FeatureFlag } from '@gonfalon/flags';

import type { MeasuredRollout } from './types';

export const useStopMeasuredRolloutAccess = ({
  flag,
  measuredRollout,
}: {
  flag: FeatureFlag;
  measuredRollout: MeasuredRollout;
}) => {
  const { profile } = useAccountContext();
  const environmentKey = useSelectedEnvironmentKey();
  return checkFlagConfigurationAccess(
    profile,
    measuredRollout.design.ruleIdOrFallthrough === 'fallthrough'
      ? 'stopMeasuredRolloutOnFallthrough'
      : 'stopMeasuredRolloutOnRule',
    flag,
    environmentKey,
  ).isAllowed;
};
