import classNames from 'clsx';

import './styles.css';

type ModuleHeaderProps = {
  className?: string;
  children?: React.ReactNode;
};

/* eslint-disable import/no-default-export */
export default function ModuleHeader({ className, children, ...props }: ModuleHeaderProps) {
  return (
    <div {...props} className={classNames('Module-header', className)}>
      {children}
    </div>
  );
}
