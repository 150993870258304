import { LimitPlan, Tier } from '../../types';

export function getLimitPlanUpperLimit(limitPlan: LimitPlan) {
  const tiers: Tier[] = limitPlan.tiers ?? [{ limit: 0 }];
  const lastTier: Tier | undefined = tiers[tiers.length - 1];

  if (!lastTier) {
    return 0;
  }

  if (lastTier.limit !== undefined) {
    return lastTier.limit;
  }

  return 0;
}
