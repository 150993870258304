import { enableEnterpriseSeatExpansions as enableEnterpriseSeatExpansionsFlag } from '@gonfalon/dogfood-flags';
import nullthrows from 'nullthrows';

import { LimitPlan, ModernSubscription, SubscriptionUsage } from '../../types';

import { canMakeSelfServeChanges } from './canMakeSelfServeChanges';
import { getLimitPlanUpperLimit } from './getLimitPlanUpperLimit';
import { getRemainingSeats } from './getRemainingSeats';
import { getSeatLimit } from './getSeatLimit';
import { is2021Plan } from './is2021Plan';
import { isModernEnterprisePlan } from './isModernEnterprisePlan';
import { isPlanUpgradeNeededToAddSeats } from './isPlanUpgradeNeededToAddSeats';

export function getSeatAvailability(
  subscription: ModernSubscription,
  subscriptionUsage: SubscriptionUsage,
  numberOfSeatsToAdd: number,
  seatsLimitPlan: LimitPlan,
) {
  // They have paid for this many seats
  const subscriptionSeatLimit = getSeatLimit(subscription);

  // Their plan allows for this many seats:
  const planSeatLimit = getLimitPlanUpperLimit(seatsLimitPlan);

  const numberOfUnusedButPaidForSeats = getRemainingSeats(subscription, subscriptionUsage);

  // Of the seats they've already paid for, they have this many still available
  const numberOfNewSeatsLeft = numberOfUnusedButPaidForSeats - numberOfSeatsToAdd;

  // If numberOfUnusedButPaidForSeats === -1, they have unlimited seats, so don't need to purchase any new seats
  const planHasUnlimitedSeats = numberOfUnusedButPaidForSeats === -1;

  // If they don't have unlimited seats, they might need to purchase some so they can invite everyone
  const numberOfNewSeatsToPurchase = planHasUnlimitedSeats ? 0 : Math.max(0, numberOfNewSeatsLeft * -1);

  // The current amount of seats in the subscription plus the additional new seats that need to be purchased
  const totalNewSeats = subscriptionSeatLimit + numberOfNewSeatsToPurchase;

  const isPlanUpgradeNeeded = isPlanUpgradeNeededToAddSeats(
    numberOfNewSeatsToPurchase,
    planHasUnlimitedSeats,
    planSeatLimit,
    subscriptionSeatLimit,
    nullthrows(subscription.planType),
  );

  const canRenderUpsell =
    is2021Plan(subscription) &&
    !isPlanUpgradeNeeded &&
    numberOfNewSeatsToPurchase > 0 &&
    canMakeSelfServeChanges(subscription);

  const enableEnterpriseSeatExpansions = enableEnterpriseSeatExpansionsFlag();
  const canRenderRequestSeatsUpsell =
    enableEnterpriseSeatExpansions && isModernEnterprisePlan(subscription) && numberOfNewSeatsToPurchase > 0;

  return {
    totalNewSeats,
    numberOfNewSeatsToPurchase,
    canRenderUpsell,
    canRenderRequestSeatsUpsell,
    numberOfNewSeatsLeft,
    planHasUnlimitedSeats,
    isPlanUpgradeNeeded,
    planSeatLimit,
  };
}
