import { assetBase, assetPath } from '@gonfalon/constants';
import { enableEnterpriseSeatExpansions as isEnterpriseSeatExpansionsEnabled } from '@gonfalon/dogfood-flags';
import cx from 'clsx';
import { Modal, ModalBody, ModalHeader } from 'launchpad';

import { useSelector } from 'hooks/useSelector';
import { createRequestSeatFormSelector } from 'reducers/account';

import RequestSeatsFormContainer from './RequestSeatsFormContainer';

import styles from './styles/RequestSeatsModal.module.css';

export type RequestSeatsModalProps = {
  onCancel(): void;
};

const useRedux = () => {
  const formState = useSelector(createRequestSeatFormSelector);
  const enableEnterpriseSeatExpansions = isEnterpriseSeatExpansionsEnabled();
  return {
    formSubmitted: formState.isFormSubmitted(),
    enableEnterpriseSeatExpansions,
  };
};
export const RequestSeatsModal = ({ onCancel }: RequestSeatsModalProps) => {
  const { formSubmitted, enableEnterpriseSeatExpansions } = useRedux();

  if (!enableEnterpriseSeatExpansions) {
    return null;
  }

  const getTitle = () => {
    if (formSubmitted) {
      return <p className={styles.Text}>Request submitted!</p>;
    }
    return <p className={styles.Text}>It's time for an upgrade!</p>;
  };

  const getDescription = () => {
    if (formSubmitted) {
      return (
        <p className={styles.Text}>
          Thank you for submitting your request. An expert will be in touch with you shortly.
        </p>
      );
    }
    return (
      <>
        <p className={styles.Text}>
          You've reached the maximum number of members. Add seats today to make sure no one misses out.
        </p>
        <p className={cx(styles.Text, styles.topMargin)}>Fill out the form, and we'll be in touch.</p>
      </>
    );
  };

  return (
    <Modal className={styles.Modal} onCancel={onCancel}>
      <div className={styles.Content}>
        <img
          className={styles.RequestSeatsGraphic}
          alt="Upgrade seats dark mode"
          src={`${assetBase()}/${assetPath()}/img/upsells/upgrade-seats-dark-mode.png`}
        />
        <div>
          <ModalHeader
            withCloseButton
            title={
              <>
                <h2 className={styles.modalTitle}>{getTitle()}</h2>
              </>
            }
            description={getDescription()}
          ></ModalHeader>
          <ModalBody>{!formSubmitted && <RequestSeatsFormContainer />}</ModalBody>
        </div>
      </div>
    </Modal>
  );
};
