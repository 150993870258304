import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useDispatch, useSelector } from 'react-redux';
import {
  areWorkflowTemplatesEnabled as areWorkflowTemplatesEnabledPredicate,
  isWorkflowBuilderEnabled as isWorkflowBuilderEnabledPredicate,
  isWorkflowBuilderUpsellEnabled as isWorkflowBuilderUpsellEnabledPredicate,
} from '@gonfalon/dogfood-flags';
import { List } from 'immutable';
import nullthrows from 'nullthrows';

import {
  fetchCustomWorkflowByIdAction,
  fetchCustomWorkflowsByStatus as fetchCustomWorkflowsByStatusAction,
} from 'actions/customWorkflows';
import { useMembers } from 'hooks/useMembers';
import { GlobalState } from 'reducers';
import {
  activeCustomWorkflowsListSelector,
  completedCustomWorkflowsListSelector,
  customWorkflowByIdRequestSelector,
  customWorkflowByIdSelector,
  customWorkflowsCurrentFlagSelector,
  customWorkflowsListSelector,
  customWorkflowsRequestSelector,
} from 'reducers/customWorkflows';
import { useCurrentEnvironmentEntity } from 'reducers/projects';
import { AccessDecision } from 'utils/accessUtils';
import {
  CustomWorkflow,
  CustomWorkflowExecutionStatusType,
  useUpdateCustomWorkflowsAccessDecision,
} from 'utils/customWorkflowUtils';
import { Flag, FlagConfiguration } from 'utils/flagUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import { ready, serverError } from 'utils/reduxUtils';

export interface CustomWorkflowsContextValue {
  projKey: string;
  envKey: string;
  workflowId?: string;
  flag: Flag;
  flagConfiguration: FlagConfiguration;
  doesFlagDefaultRuleHaveExperiment: boolean;
  isWorkflowBuilderEnabled: boolean;
  isWorkflowBuilderUpsellEnabled: boolean;
  areWorkflowTemplatesEnabled: boolean;
  isReady: boolean;
  error: ImmutableServerError | null;
  updateCustomWorkflowsAccess: AccessDecision;
  customWorkflowsState: List<CustomWorkflow>;
  activeCustomWorkflowsState: List<CustomWorkflow>;
  completedCustomWorkflowsState: List<CustomWorkflow>;
  customWorkflowByIdState: CustomWorkflow | undefined;
}

const CustomWorkflowsContext = createContext<CustomWorkflowsContextValue | undefined>(undefined);

type CustomWorkflowsContextProviderProps = {
  projKey: string;
  envKey?: string;
  flag: Flag;
  workflowId?: string;
  children: ReactNode;
};

const useRedux = ({
  projKey,
  paramEnvKey,
  flagKey,
  workflowId,
}: {
  projKey: string;
  paramEnvKey?: string;
  flagKey: string;
  workflowId?: string;
}) => {
  const currentEnv = useCurrentEnvironmentEntity();
  const envKey = paramEnvKey || currentEnv.key;

  const customWorkflowsRequest = useSelector(customWorkflowsRequestSelector);
  const customWorkflowsState = useSelector(customWorkflowsListSelector);
  const completedCustomWorkflowsState = useSelector(completedCustomWorkflowsListSelector);
  const activeCustomWorkflowsState = useSelector(activeCustomWorkflowsListSelector);
  const currentFlag = useSelector(customWorkflowsCurrentFlagSelector);
  const customWorkflowByIdRequest = useSelector(customWorkflowByIdRequestSelector);
  const customWorkflowByIdState = useSelector((state: GlobalState) =>
    customWorkflowByIdSelector(state, { id: workflowId ?? '' }),
  );

  const isAllWorkflowsRequestReady = ready(
    customWorkflowsRequest.get(CustomWorkflowExecutionStatusType.ACTIVE),
    customWorkflowsRequest.get(CustomWorkflowExecutionStatusType.COMPLETED),
  );
  const isReady = Boolean(workflowId) ? ready(customWorkflowByIdRequest) : isAllWorkflowsRequestReady;

  const allWorkflowsRequestErrors = serverError(
    customWorkflowsRequest.get(CustomWorkflowExecutionStatusType.ACTIVE),
    customWorkflowsRequest.get(CustomWorkflowExecutionStatusType.COMPLETED),
  );
  const error = Boolean(workflowId) ? serverError(customWorkflowByIdRequest) : allWorkflowsRequestErrors;

  const dispatch = useDispatch();

  const fetchCustomWorkflowsByStatus = (status?: CustomWorkflowExecutionStatusType) => {
    dispatch(fetchCustomWorkflowsByStatusAction({ projKey, envKey, flagKey, status }));
  };
  const fetchCustomWorkflowById = (id: string) => {
    dispatch(fetchCustomWorkflowByIdAction({ projKey, envKey, flagKey, workflowId: id }));
  };

  return {
    isReady,
    error,
    envKey,
    customWorkflowsState,
    currentFlag,
    activeCustomWorkflowsState,
    completedCustomWorkflowsState,
    customWorkflowByIdState,
    fetchCustomWorkflowsByStatus,
    fetchCustomWorkflowById,
  };
};

export function CustomWorkflowsContextProvider({
  projKey,
  envKey: paramEnvKey,
  flag,
  workflowId,
  children,
}: CustomWorkflowsContextProviderProps) {
  const {
    isReady,
    error,
    envKey,
    customWorkflowsState,
    currentFlag,
    customWorkflowByIdState,
    activeCustomWorkflowsState,
    completedCustomWorkflowsState,
    fetchCustomWorkflowsByStatus,
    fetchCustomWorkflowById,
  } = useRedux({
    projKey,
    paramEnvKey,
    flagKey: flag.key,
    workflowId,
  });
  const updateCustomWorkflowsAccess = useUpdateCustomWorkflowsAccessDecision({ flag, envKey });

  const { isReady: areMembersReady } = useMembers({ limit: undefined });

  const isWorkflowBuilderEnabled = isWorkflowBuilderEnabledPredicate();
  const isWorkflowBuilderUpsellEnabled = isWorkflowBuilderUpsellEnabledPredicate();
  const areWorkflowTemplatesEnabled = areWorkflowTemplatesEnabledPredicate();

  const contextValue = useMemo(
    () => ({
      projKey,
      envKey,
      flag,
      flagConfiguration: flag.getConfiguration(envKey),
      doesFlagDefaultRuleHaveExperiment: !!flag.getConfiguration(envKey).fallthrough.rollout?.experimentAllocation,
      isWorkflowBuilderEnabled,
      isWorkflowBuilderUpsellEnabled,
      areWorkflowTemplatesEnabled,
      isReady: isReady && areMembersReady,
      error,
      workflowId,
      updateCustomWorkflowsAccess,
      customWorkflowsState,
      activeCustomWorkflowsState,
      completedCustomWorkflowsState,
      customWorkflowByIdState,
      fetchCustomWorkflowById,
    }),
    [
      isReady && areMembersReady,
      error,
      customWorkflowsState,
      activeCustomWorkflowsState,
      completedCustomWorkflowsState,
      customWorkflowByIdState,
      updateCustomWorkflowsAccess,
      flag,
      projKey,
      envKey,
      workflowId,
      isWorkflowBuilderEnabled,
      isWorkflowBuilderUpsellEnabled,
      areWorkflowTemplatesEnabled,
    ],
  );

  const fetchCustomWorkflows = () => {
    fetchCustomWorkflowsByStatus(CustomWorkflowExecutionStatusType.ACTIVE);
    fetchCustomWorkflowsByStatus(CustomWorkflowExecutionStatusType.COMPLETED);
  };

  useEffect(() => {
    if (!isWorkflowBuilderEnabled) {
      return;
    }

    if (isReady) {
      if (!workflowId && currentFlag !== flag.key) {
        fetchCustomWorkflows();
      }
    } else {
      if (workflowId) {
        fetchCustomWorkflowById(workflowId);
      } else {
        fetchCustomWorkflows();
      }
    }
  }, [isReady, flag, isWorkflowBuilderEnabled]);

  return <CustomWorkflowsContext.Provider value={contextValue}>{children}</CustomWorkflowsContext.Provider>;
}

export function useCustomWorkflowsContext() {
  const context = useContext(CustomWorkflowsContext);
  return nullthrows(context, 'useCustomWorkflowsContext must be used within a CustomWorkflowsContextProvider');
}
