import { chunk } from '@gonfalon/es6-utils';
import { isStringArray } from 'ia-poc/services/data-model/flags/type-utils';
import { List, Map } from 'immutable';
import { AnyAction } from 'redux';
import { ofType, StateObservable } from 'redux-observable';
import { ThunkAction } from 'redux-thunk';
import { defer, Observable, of } from 'rxjs';
import { catchError, concatMap, concatWith, filter, map, retryWhen, scan, switchMap } from 'rxjs/operators';
import invariant from 'tiny-invariant';

import { BillingAction, subscribeAndInviteDone } from 'actions/billing';
import { MemberAction, sendBulkInviteBatchDone, sendBulkInviteDone, sendBulkInviteFailed } from 'actions/members';
import actionTypes from 'actionTypes/members';
import registry from 'epics/registry';
import { GlobalDispatch, GlobalState } from 'reducers';
import { inviteMembers as defaultInviteMembersAPI } from 'sources/AccountAPI';
import { createBulkMemberInvite, Member } from 'utils/accountUtils';

export const MAX_MEMBERS_PER_BATCH = 50;

export type InviteMembersAPI = (
  invites: List<{
    email: string;
    role: string;
    customRoles: List<string>;
    teamKeys: List<string>;
  }>,
) => Promise<List<Member>>;

export const inviteMembersBatch = (
  action$: Observable<MemberAction | BillingAction>,
  _: StateObservable<GlobalState>,
  { inviteMembersAPI }: { inviteMembersAPI: InviteMembersAPI } = { inviteMembersAPI: defaultInviteMembersAPI },
) => {
  const retryingInviteMembers = retryingInviteMembersAPI(inviteMembersAPI);

  return action$.pipe(
    ofType(actionTypes.INVITE_MEMBERS),
    filter((action) => !action.invites.isEmpty()),
    switchMap((action) => {
      const chunks = chunk(action.invites.toRep().toArray(), MAX_MEMBERS_PER_BATCH);

      return of(...chunks).pipe(
        // Call the server for each batch (retrying logic contained in retryingInviteMembersAPI below)
        // Each batch will correspond to one BulkInviteBatchDone.
        concatMap((members) => retryingInviteMembers(members)),
        // Add sentinel value to the end to be mapped to a BulkInviteDone after all of the batches.
        concatWith(of({ successfulMembers: null, invalidMembers: null })),
        // Build up a list of events to return. Each intermediate value is the events up to that point.
        scan(
          (events, { successfulMembers, invalidMembers }) => {
            if (successfulMembers !== null || invalidMembers !== null) {
              return events.concat([sendBulkInviteBatchDone(successfulMembers, invalidMembers)]);
            } else {
              // Sentinel value… (successfulMembers === null && invalidMembers == null)
              // Collect successful and failed emails from all previous batches
              const { successfulEmails, invalidEmails } = events.reduce(
                (accumulator, event) => {
                  if (event.type !== 'members/INVITE_MEMBERS_BATCH_DONE') {
                    return accumulator;
                  }

                  return {
                    successfulEmails: accumulator.successfulEmails.concat(event.members.map((member) => member.email)),
                    invalidEmails: accumulator.invalidEmails.mergeDeep(
                      event.failedMembers.map((members) => members.map((member) => member.email)),
                    ),
                  };
                },
                {
                  successfulEmails: List<string>(),
                  invalidEmails: Map<number, List<string>>(),
                },
              );

              // Create a BulkInviteDone, and maybe navigate if we had a complete success.
              let event: AnyAction | ThunkAction<void, GlobalState, undefined, AnyAction>;
              if (action.options.onCloseModal && invalidEmails.size === 0) {
                event = (dispatch: GlobalDispatch) => {
                  action.options.onCloseModal?.();
                  // when member invite is dispatched after updating the subscription, we need to dispatch a different done action to render the correct notification
                  if (action.options.inviteAndSubscribe) {
                    return dispatch(
                      subscribeAndInviteDone(
                        createBulkMemberInvite({
                          emails: successfulEmails,
                          successfulEmails,
                          failedEmails: invalidEmails,
                        }),
                        action.options,
                        // @ts-expect-error We always provide a subscription in this flow.
                        action.subscription,
                      ),
                    );
                  }
                  return dispatch(
                    sendBulkInviteDone(
                      createBulkMemberInvite({
                        emails: successfulEmails,
                        successfulEmails,
                        failedEmails: invalidEmails,
                      }),
                      action.options,
                    ),
                  );
                };
              } else {
                const failedEmailList = invalidEmails.valueSeq().flatten(true).toOrderedMap();
                if (action.options.inviteAndSubscribe) {
                  event = subscribeAndInviteDone(
                    createBulkMemberInvite({
                      // when emails fail, we keep only the unsuccessful invites in the email field in order to clear the successful emails from the form
                      emails: failedEmailList,
                      successfulEmails,
                      failedEmails: invalidEmails,
                    }),
                    action.options,
                    // @ts-expect-error We always provide a subscription in this flow.
                    action.subscription,
                  );
                } else {
                  event = sendBulkInviteDone(
                    createBulkMemberInvite({
                      emails: failedEmailList,
                      successfulEmails,
                      failedEmails: invalidEmails,
                    }),
                    action.options,
                  );
                }
              }

              /**
               * `scan` is similar to `reduce`: think of it as reducing an observable
               * value over time (instead of an array). In this case, the items represent
               * results of inviting batches of members. Each result contains members for
               * emails that succeeded, and emails for those that failed.
               *
               * Note: we insert an artificial event -- a "sentinel" -- so that we know
               * when we've reached the end of the batched results.
               *
               * 1. `events` starts off empty
               * 2. for each result item,
               *    - if it is not the sentinel item
               *        - convert each result item to a 'members/INVITE_MEMBERS_BATCH_DONE' action
               *    - otherwise
               *        - reduce all 'members/INVITE_MEMBERS_BATCH_DONE' actions into a single action
               *          with the list of members who were invited, and failed emails
               *        - generate a 'members/INVITE_MEMBERS_DONE` action
               * 3. (after scan() has completed: pick out the `members/INVITE_MEMBERS_DONE` action
               *    to be dispatched by redux-observable.
               * 4. if there were failures, dispatch 'members/BULK_INVITE_MEMBERS_FAILED'
               */
              // @ts-expect-error The type of `events` changes over time. See comment above.
              return events.concat([event]);
            }
          },
          [] as Array<
            | ReturnType<typeof sendBulkInviteBatchDone>
            | ReturnType<typeof sendBulkInviteDone>
            | ReturnType<typeof subscribeAndInviteDone>
          >,
        ),
        // Emit the latest event for each value.
        map((events) => events[events.length - 1]),
        catchError((error) => of(sendBulkInviteFailed(action.invites, error))),
      );
    }),
  );
};

function isFixableError(errCode: string) {
  return (
    errCode === 'email_already_exists_in_account' ||
    errCode === 'email_taken_in_different_account' ||
    errCode === 'duplicate_emails'
  );
}

function retryingInviteMembersAPI(inviteMembers: InviteMembersAPI) {
  return (
    members: Array<{
      email: string;
      role: string;
      customRoles: List<string>;
      teamKeys: List<string>;
    }>,
  ) => {
    let validMembers = List(members);
    let invalidMembers = Map<
      number,
      List<{
        email: string;
        role: string;
        customRoles: List<string>;
        teamKeys: List<string>;
      }>
    >();
    return defer(async () =>
      validMembers.size === 0
        ? Promise.resolve({
            successfulMembers: List<Member>(),
            invalidMembers,
          })
        : inviteMembers(validMembers).then((successfulMembers) => ({
            successfulMembers,
            invalidMembers,
          })),
    ).pipe(
      retryWhen((errors) =>
        errors.pipe(
          map((err) => {
            if (isFixableError(err.get('code')) && err.get('invalid_emails').size > 0) {
              // We got an error that we might be able to deal with by removing some invalid emails.
              const initialSize = validMembers.size;
              const invalidEmails = err.get('invalid_emails').toArray();
              invariant(isStringArray(invalidEmails), 'Expected invalidEmails to be array of strings');

              invalidMembers = invalidMembers.set(
                err.get('code'),
                validMembers.filter((member) => invalidEmails.includes(member.email)),
              );
              validMembers = validMembers.filter((member) => !invalidEmails.includes(member.email));

              if (validMembers.size === initialSize) {
                // We were not able to reduce the list of emails based on the
                // error. We know the request will still error and we can't really
                // do anything about it, so just give up and pass on the error.
                throw err;
              }
            } else if (err.get('code') === 'rate_limited') {
              invalidMembers = invalidMembers.set(err.get('code'), validMembers);
              validMembers = List();
            } else {
              // Unfixable or unrecognized error.
              throw err;
            }
            return null;
          }),
        ),
      ),
    );
  };
}

registry.addEpics([inviteMembersBatch]);
