import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';
import { Label } from 'launchpad';

import { PendingChangesDatePopover } from 'components/PendingChanges/PendingChangesDatePopover';
import Restrict from 'components/Restrict';

import { usePendingChangesContext } from './PendingChangesContext';

import styles from './PendingChangesActions.module.css';

export type PendingChangesActionsProps = {
  hideScheduleOption?: boolean;
  hideApprovalOption?: boolean;
  isScheduleSelected: boolean;
  executionDate?: number;
  isApprovalSelected: boolean;
  onEditPendingChangesForm: (field: string, value: number | boolean) => void;
};

export function PendingChangesActions({
  hideScheduleOption = false,
  hideApprovalOption = false,
  isScheduleSelected,
  executionDate,
  isApprovalSelected,
  onEditPendingChangesForm,
}: PendingChangesActionsProps) {
  const { approvalAccess, scheduleAccess, hasExpiringTargetChanges } = usePendingChangesContext();

  const renderActionIcon = ({ isOpen }: { isOpen: boolean }) =>
    isOpen ? <Icon name="minus" size="medium" /> : <Icon name="add" size="medium" />;

  const handleOpeningScheduler = () => {
    if (!scheduleAccess.isAllowed) {
      return;
    }
    if (isScheduleSelected) {
      onEditPendingChangesForm('executionDate', 0);
    }
    onEditPendingChangesForm('isScheduleSelected', !isScheduleSelected);
  };

  const handleOpeningRequestReview = () => {
    if (!approvalAccess.isAllowed) {
      return;
    }
    onEditPendingChangesForm('isApprovalSelected', !isApprovalSelected);
  };

  return (
    <section
      className={cx(styles.container, {
        [styles.approvalOptionallySelected]: isApprovalSelected && !hideApprovalOption,
      })}
    >
      {!hideScheduleOption && (
        <div className="u-flex-column">
          <Restrict
            isRestricted={!scheduleAccess.isAllowed}
            tooltip={scheduleAccess.getActionReason()}
            willDisable
            tooltipOptions={{ targetClassName: styles.restricted }}
          >
            <button
              id="schedule-accordion"
              type="button"
              data-test-id="schedule-accordion-btn"
              className={styles.actionRow}
              onClick={handleOpeningScheduler}
              name="canScheduleChanges"
              aria-expanded={isScheduleSelected}
              aria-controls="schedule-region"
            >
              Schedule
              {renderActionIcon({
                isOpen: isScheduleSelected,
              })}
            </button>
          </Restrict>
          {isScheduleSelected && (
            <div id="schedule-region" className="u-ml-m" role="region" aria-labelledby="schedule-accordion">
              <Label htmlFor="future-date-picker">Apply changes at future date</Label>
              <PendingChangesDatePopover
                id="future-date-picker"
                disabled={hasExpiringTargetChanges}
                date={executionDate ? new Date(executionDate) : undefined}
                isScheduledChange
                showTitle={false}
                onSetDate={(date: number) => {
                  onEditPendingChangesForm('executionDate', date);
                }}
                useLongDate
              />
            </div>
          )}
        </div>
      )}
      {!hideApprovalOption && (
        <Restrict
          tooltip={approvalAccess.getActionReason()}
          isRestricted={!approvalAccess.isAllowed}
          willDisable
          tooltipOptions={{ targetClassName: styles.restricted }}
        >
          <button
            id="approval-accordion"
            type="button"
            className={cx(styles.actionRow, {
              [styles.approvalOptionallySelected]: isApprovalSelected,
            })}
            onClick={handleOpeningRequestReview}
            name="canRequestApproval"
            aria-expanded={isApprovalSelected}
            aria-controls="approval-region"
          >
            Request approval
            {renderActionIcon({
              isOpen: isApprovalSelected,
            })}
          </button>
        </Restrict>
      )}
    </section>
  );
}
