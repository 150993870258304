import { useEffect } from 'react';
import { connect } from 'react-redux';
import { ProgressBar } from '@launchpad-ui/components';

import { fetchFlagIfNeeded } from 'actions/flags';
import { PrerequisiteEntry } from 'components/InstructionList/PrerequisiteEntry';
import { GlobalDispatch, GlobalState } from 'reducers';
import { flagByKeyForProjectSelector, flagRequestByKeyForProjectSelector } from 'reducers/flags';
import { currentProjectKeySelector } from 'reducers/projects';
import { Flag } from 'utils/flagUtils';
import { FlagPrerequisitesInstructionKind } from 'utils/instructions/prerequisites/types';
import { ready } from 'utils/reduxUtils';

type PrerequisiteEntryContainerProps = {
  fetchFlagForProjectAndEnvironment(flagKey: string, projKey: string, envKey: string): void;
  flagReady: boolean;
  prerequisiteFlag: Flag;
  instructionKind: FlagPrerequisitesInstructionKind;
  flagKey: string;
  projKey: string;
  envKey: string;
  variationId?: string;
};

export function PrerequisiteEntryContainer({
  fetchFlagForProjectAndEnvironment,
  flagReady,
  flagKey,
  projKey,
  envKey,
  instructionKind,
  variationId,
  prerequisiteFlag,
}: PrerequisiteEntryContainerProps) {
  useEffect(() => {
    if (!flagReady) {
      fetchFlagForProjectAndEnvironment(flagKey, projKey, envKey);
    }
  }, []);
  if (!flagReady) {
    return <ProgressBar aria-label="Loading…" isIndeterminate />;
  }
  return (
    <PrerequisiteEntry
      instructionKind={instructionKind}
      variationId={variationId}
      prerequisiteFlag={prerequisiteFlag}
    />
  );
}

type PrerequisiteEntryContainerOwnProps = Pick<PrerequisiteEntryContainerProps, 'flagKey' | 'projKey'>;

const mapStateToProps = (
  state: GlobalState,
  { flagKey, projKey = currentProjectKeySelector(state) }: PrerequisiteEntryContainerOwnProps,
) => {
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  const flag = flagByKeyForProjectSelector(state, {
    flagKey,
    projKey,
  }).entity!; /* eslint-enable @typescript-eslint/no-non-null-assertion */
  const flagRequest = flagRequestByKeyForProjectSelector(state, { flagKey, projKey });
  const flagReady = ready(flagRequest);
  return {
    flagReady,
    prerequisiteFlag: flag,
  };
};
const mapDispatchToProps = (dispatch: GlobalDispatch) => ({
  fetchFlagForProjectAndEnvironment: async (flagKey: string, projKey: string, envKey: string) =>
    dispatch(fetchFlagIfNeeded(projKey, flagKey, envKey)),
});

/* eslint-disable import/no-default-export */
export default connect(mapStateToProps, mapDispatchToProps)(PrerequisiteEntryContainer);
