// eslint-disable-next-line no-restricted-imports
import { fromJS, Map, Record } from 'immutable';

import { AccessChecks, allowDecision, createAccessDecision } from 'utils/accessUtils';
import { Member } from 'utils/accountUtils';
import { Flag, VariationValue } from 'utils/flagUtils';
import { CreateFunctionInput, ImmutableMap } from 'utils/immutableUtils';
import { UpdateTargetsSemanticInstruction } from 'utils/instructions/targets/types';
import { Link } from 'utils/linkUtils';
import { createUserAttributes, UserAttributes } from 'utils/userAttributeUtils';

import { UpdateUserTargetsSemanticInstruction } from './instructions/userTargets/types';

type UserProps = {
  _access?: AccessChecks;
  _links: ImmutableMap<{
    parent: Link;
    self: Link;
    settings: Link;
    site: Link;
  }>;
  environmentId?: string;
  lastPing?: number;
  attributes: UserAttributes;
};

export class User extends Record<UserProps>({
  _links: Map(),
  _access: undefined,
  environmentId: undefined,
  lastPing: undefined,
  attributes: new UserAttributes(),
}) {
  selfLink() {
    return this.getIn(['_links', 'self', 'href']);
  }

  siteLink() {
    const href = this.getIn(['_links', 'site', 'href']);
    return href ? encodeURI(href) : null; // HACK: encode uri because react router history decodes them automatically and there's no way around that
  }

  exists() {
    return !!this.siteLink();
  }

  settingsLink() {
    return this._links.getIn(['settings', 'href']);
  }

  getKey() {
    return this.attributes.key;
  }

  checkAccess({ profile }: { profile: Member }) {
    const access = this.get('_access');

    if (profile.isReader()) {
      return () =>
        createAccessDecision({
          isAllowed: false,
          appliedRoleName: 'Reader',
        });
    }

    if (profile.isAdmin() || profile.isWriter() || profile.isOwner() || !access || !access.get('denied')) {
      return allowDecision;
    }

    return (action: string) => {
      const deniedAction = access?.get('denied').find((v) => v.get('action') === action);
      if (deniedAction) {
        const reason = deniedAction.get('reason');
        const roleName = reason && reason.get('role_name');
        return createAccessDecision({
          isAllowed: false,
          appliedRoleName: roleName,
        });
      }
      return createAccessDecision({ isAllowed: true });
    };
  }
}

type UserSettingsProps = {
  _links: ImmutableMap<{
    self: Link;
  }>;
  reason?: ImmutableMap<{
    kind: string;
    ruleID?: string;
    prerequisiteKey?: string;
    errorKind?: string;
  }>;
  _value?: VariationValue;
  setting: VariationValue;
  comment?: string;
};

export class UserSettings extends Record<UserSettingsProps>({
  _links: Map(),
  _value: undefined,
  reason: Map(),
  setting: null,
  comment: undefined,
}) {
  selfLink() {
    return this._links.getIn(['self', 'href']);
  }
  getReasonKind() {
    return this.reason ? this.reason.get('kind') : undefined;
  }
  getReasonRuleID() {
    return this.reason ? this.reason.get('ruleID') : undefined;
  }
  getReasonPreRequisiteKey() {
    return this.reason ? this.reason.get('prerequisiteKey') : undefined;
  }
  getErrorKind() {
    return this.reason ? this.reason.get('errorKind') : undefined;
  }
}

type IncomingUserProps = CreateFunctionInput<
  Omit<UserProps, 'attributes'> & {
    user: UserAttributes;
  }
>;

export function transformUserProps(props: IncomingUserProps | ImmutableMap<IncomingUserProps>) {
  const { user: attributes, ...otherProps } = 'toObject' in props ? props.toObject() : props;

  return {
    ...otherProps,
    attributes,
  };
}

export function createUser(props: CreateFunctionInput<User> = {}) {
  if (props instanceof User) {
    return props;
  }

  return new User(fromJS(props).update('attributes', createUserAttributes));
}

export function createUserSettings(props?: CreateFunctionInput<UserSettings>) {
  return props instanceof UserSettings ? props : new UserSettings(fromJS(props));
}

export type SemanticInstructionsForUser = {
  flag: Flag;
  instructions: UpdateUserTargetsSemanticInstruction[] | UpdateTargetsSemanticInstruction[];
};

export type SemanticInstructionsForKind = {
  flag: Flag;
  instructions: UpdateTargetsSemanticInstruction[];
};

export type SemanticInstructionsByFlagKeyType = {
  [flagKey: string]: SemanticInstructionsForUser;
};

export type SemanticInstructionsByFlagKeyTypeKind = {
  [flagKey: string]: SemanticInstructionsForKind;
};
