import { createSearchParamsHook } from '@gonfalon/router';

import { type UIFlagProjectFilter } from './flagProjectFilters';
import { isEnvironmentFilter } from './isEnvironmentFilter';
import { isProjectFilter } from './isProjectFilter';
import { parseUIFlagFilterFromBrowser } from './parseUIFlagFilterFromBrowser';
import { serializeUIFlagFilterForBrowser } from './serializeUIFlagFilterForBrowser';

export const useFlagListProjectFiltersSearchParams = createSearchParamsHook<UIFlagProjectFilter>({
  parse: (searchParams) => {
    const filter = parseUIFlagFilterFromBrowser(searchParams);
    const projectFilter = structuredClone(filter);

    for (const fk of Object.keys(filter)) {
      if (!isProjectFilter(fk)) {
        if (isEnvironmentFilter(fk)) {
          delete projectFilter[fk];
        }
      }
    }

    return projectFilter;
  },
  serialize: (filter) => {
    const projectOnly = structuredClone(filter);
    for (const fk of Object.keys(projectOnly)) {
      if (!isProjectFilter(fk)) {
        // @ts-expect-error @alexis is fixing this correctly in a follow-up PR
        delete projectOnly[fk];
      }
    }

    return serializeUIFlagFilterForBrowser(projectOnly);
  },
});
