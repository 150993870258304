import { assertNever } from '@gonfalon/types';

export const enum KeyboardShortcut {
  TOGGLE_PROJECT_SIDEBAR = '[',
  TOGGLE_PROJECT_ENTITY_SIDEBAR = ']',
}

export function getKeyboardShortcutLabel(shortcut: KeyboardShortcut) {
  switch (shortcut) {
    case KeyboardShortcut.TOGGLE_PROJECT_SIDEBAR:
      return '[';
    case KeyboardShortcut.TOGGLE_PROJECT_ENTITY_SIDEBAR:
      return ']';
    default:
      assertNever(shortcut);
  }
}
