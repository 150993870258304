import { OptionProps } from '@gonfalon/launchpad-experimental';
import { List } from 'immutable';

import DocumentationLink from 'components/DocumentationLink';
import { createMember, Member, MemberTeams } from 'utils/accountUtils';
import { createTeam, sortTeams, Team } from 'utils/teamsUtils';
import { getDocumentationUrl } from 'utils/urlUtils';

export const getTeamOrMemberSubset = (items: List<Team | Member>, numberOfItemsToInclude = 5) =>
  items.slice(0, numberOfItemsToInclude);

export const getSortedSubsetOfTeams = (teams: List<Team>, numberOfItemsToInclude = 5): List<Team> => {
  const sortedTeams = teams.sort((a, b) => sortTeams(a, b));
  return sortedTeams.slice(0, numberOfItemsToInclude);
};

export const isMember = (maintainer: Member | Team | {} | null) =>
  maintainer && (maintainer instanceof Member || Object.keys(maintainer).includes('_id'));

export const isTeam = (maintainer: Member | Team | {} | null) =>
  maintainer && (maintainer instanceof Team || Object.keys(maintainer).includes('key'));

export const isTeamOrMember = (teamOrMember: Member | Team | {} | null) => ({
  isMember: isMember(teamOrMember),
  isTeam: isTeam(teamOrMember),
});

const teamsWithMembers = (teams: List<Team>): List<Team> =>
  teams.filter((team) => team?.members?.totalCount && team.members.totalCount > 0);

// Return a subset of teams the profile is a member of
export const getSuggestedTeamsSubset = (profile: Member, teams: List<Team>): List<Team> => {
  // The profile doesn't actually list the whole team, just a "MemberTeams" object with some data
  const profileMemberTeams: List<MemberTeams> = profile.teams || List();
  if (!profileMemberTeams || profileMemberTeams.size === 0) {
    return List();
  }
  const profileTeams: Team[] = [];
  profileMemberTeams.forEach((teamSummary) => {
    const name = teamSummary.get('name');
    const team = teams.find((t) => t.name === name);
    if (team) {
      profileTeams.push(team);
    }
  });
  const listOfProfileTeams = List<Team>(profileTeams);
  return getSortedSubsetOfTeams(listOfProfileTeams, 4);
};

// Get a subset of Member / Team suggestions
// - If there's a current Flag Maintainer, should list them first
// - List the profile second
// - If the profile is the flag maintainer, don't list them twice
// - Then list a subset of teams
export const getSuggestedSubset = (
  currentMaintainer: Member | Team | null,
  profile: Member,
  suggestedTeams: List<Team>,
  shouldShowProfile: boolean = true,
) => {
  const defaultSubset = shouldShowProfile
    ? List([profile, ...suggestedTeams].slice(0, 5))
    : List([...suggestedTeams].slice(0, 5));
  if (!currentMaintainer) {
    return defaultSubset;
  }
  if (isMember(currentMaintainer)) {
    return profile._id === (currentMaintainer as Member)._id
      ? defaultSubset
      : List([currentMaintainer, profile, ...suggestedTeams].slice(0, 5));
  }
  if (isTeam(currentMaintainer) && shouldShowProfile) {
    const filteredTeams = suggestedTeams.filter((t) => t.key !== (currentMaintainer as Team).key);
    return List([currentMaintainer, profile, ...filteredTeams].slice(0, 5));
  } else if (isTeam(currentMaintainer) && !shouldShowProfile) {
    const filteredTeams = suggestedTeams.filter((t) => t.key !== (currentMaintainer as Team).key);
    return List([currentMaintainer, ...filteredTeams].slice(0, 5));
  }
  return defaultSubset;
};

export const getTeamsSubset = (teams: List<Team>, teamsToFilterOut: List<Team>, value?: string | null) => {
  if (!teams || teams.size === 0) {
    return List();
  }
  const teamsWithAtLeastOneMember = teamsWithMembers(teams);
  const teamKeysToFilter = [...teamsToFilterOut.map((team) => team?.key), value];
  const filteredTeams = teamsWithAtLeastOneMember.filter((team) => !teamKeysToFilter.includes(team.get('key')));
  return getSortedSubsetOfTeams(filteredTeams);
};

export const sortMembers = (members: List<Member>) =>
  members.sort((a, b) => (a.getDisplayName().toUpperCase() > b.getDisplayName().toUpperCase() ? 1 : -1));

export const getMembersSubset = (profile: Member, members: List<Member>, value?: string | null) => {
  const membersMinusProfile = members.filter((member) => member._id !== profile._id);
  const membersToSort = value ? membersMinusProfile.filter((member) => member._id !== value) : membersMinusProfile;
  const sortedMembers = sortMembers(membersToSort);
  return sortedMembers.slice(0, 5);
};

// Note: Not currently used while we work through some a11y questions
export const getDefaultTeamOptions = (profile: Member, teams: List<Team>) => {
  if (!teams || teams.size === 0) {
    return [
      {
        label: (
          <>
            No teams exist.{' '}
            <DocumentationLink
              component="SelectTeamOrMember-EmptyTeamsState"
              href={getDocumentationUrl('home/account/teams')}
              text="Learn about teams"
            />
          </>
        ),
      },
    ];
  }
};

export const getCurrentMaintainerFromMembersList = (members: List<Member>, value?: string) =>
  members.find((m) => m._id === value);

export const getCurrentMaintainerFromTeamsList = (teams: List<Team>, value?: string) =>
  teams.find((t) => t.key === value);

// When given a set of members and teams, find the
export const getCurrentMaintainerFromTeamsOrMembersList = (
  members: List<Member>,
  teams: List<Team>,
  value?: string,
) => {
  if (!`${value}`?.length) {
    return null;
  }
  const maintainerMember = getCurrentMaintainerFromMembersList(members, value);
  if (maintainerMember) {
    return maintainerMember;
  }
  const teamMaintainer = getCurrentMaintainerFromTeamsList(teams, value);
  if (teamMaintainer) {
    return teamMaintainer;
  }
  return null;
};

export const getDefaultOptionsForSelectTeamOrMember = ({
  members,
  profile,
  teams = List(),
  value,
  shouldShowProfile,
}: {
  members: List<Member>;
  profile: Member;
  teams?: List<Team>;
  value?: string;
  shouldShowProfile?: boolean;
}): NestedTeamOrMemberOptionsType[] => {
  const currentMaintainer = getCurrentMaintainerFromTeamsOrMembersList(members, teams, value);
  const suggestedTeams = getSuggestedTeamsSubset(profile, teams);
  const suggestedSubset = getSuggestedSubset(currentMaintainer, profile, suggestedTeams, shouldShowProfile);
  const suggestedOptionsList = suggestedSubset.map((item) => {
    if (!item) {
      return {} as TeamOrMemberOptionType;
    }
    return item instanceof Member ? generateMemberOption(item) : generateTeamOption(item);
  });
  const suggestedOptions = suggestedOptionsList.toArray();

  const teamSubset = getTeamsSubset(teams, suggestedTeams, value);
  const teamOptions = teamSubset.map((team) => generateTeamOption(team)).toArray();

  const memberSubset = getMembersSubset(profile, members, value);
  const memberOptions = memberSubset.map((member) => generateMemberOption(member)).toArray();
  const optionsList: NestedTeamOrMemberOptionsType[] = [];

  if (suggestedOptions.length) {
    optionsList.push({ label: 'Suggested', options: suggestedOptions });
  }
  if (teamOptions?.length) {
    optionsList.push({ label: 'Teams', options: teamOptions });
  }
  if (memberOptions.length) {
    optionsList.push({ label: 'Members', options: memberOptions });
  }
  return optionsList;
};

export const getMembersMatchingString = (input: string, members: List<Member>) => {
  const lowerCasedInput = input.toLowerCase();
  const filteredList = members.filter((member) => {
    if (member.firstName.toLowerCase().includes(lowerCasedInput)) {
      return true;
    }
    if (member.email.toLowerCase().includes(lowerCasedInput)) {
      return true;
    }
    if (member.lastName.toLowerCase().includes(lowerCasedInput)) {
      return true;
    }
    if (`${member.firstName} ${member.lastName}`.toLowerCase().includes(lowerCasedInput)) {
      return true;
    }
    return false;
  });
  return filteredList;
};

export const getTeamsMatchingString = (input: string, teams: List<Team>) => {
  const lowerCasedInput = input.toLowerCase();
  const filteredList = teams.filter((team) => {
    if (team.name.toLowerCase().includes(lowerCasedInput)) {
      return true;
    }
    if (team.key.includes(lowerCasedInput)) {
      return true;
    }
    return false;
  });
  return filteredList;
};

export const getTeamsAndMembersMatchingString = ({
  input,
  members,
  teams = List(),
}: {
  input: string;
  members: List<Member>;
  teams?: List<Team>;
}): { filteredMembers: List<Member>; filteredTeams: List<Team> } => ({
  filteredMembers: getMembersMatchingString(input, members),
  filteredTeams: teams?.size ? getTeamsMatchingString(input, teams) : List([]),
});

export type TeamOrMemberOptionType = OptionProps & { kind: 'member' | 'team' } & (
    | { member?: undefined; team?: Team }
    | { member?: Member; team?: undefined }
  );

export type NestedTeamOrMemberOptionsType = {
  label: 'Suggested' | 'Members' | 'Teams';
  options: TeamOrMemberOptionType[];
};

export const generateMemberOption = (member: Member): TeamOrMemberOptionType => ({
  kind: 'member',
  label: member instanceof Member ? member.getDisplayName() : createMember(member).getDisplayName(),
  member,
  value: member?._id,
});

export const generateTeamOption = (team: Team): TeamOrMemberOptionType => ({
  kind: 'team',
  label: team instanceof Team ? team.name : createTeam(team).name,
  team,
  value: team?.key,
});

export const getOptionsForSelectTeamOrMemberWithInput = ({
  input,
  members,
  teams = List(),
}: {
  input: string;
  members: List<Member>;
  teams?: List<Team>;
}) => {
  const teamsWithAtLeastOneMember = teams?.size ? teamsWithMembers(teams) : List([]);
  const { filteredMembers, filteredTeams } = getTeamsAndMembersMatchingString({
    input,
    members,
    teams: teamsWithAtLeastOneMember,
  });

  const teamOptions = filteredTeams.map((team) => generateTeamOption(team));
  const memberOptions = filteredMembers.map((member) => generateMemberOption(member));

  const options = [...memberOptions, ...teamOptions];
  const sortedOptions = options.sort((a, b) => (`${a?.label}`.toUpperCase() > `${b?.label}`.toUpperCase() ? 1 : -1));
  return sortedOptions;
};

export const getOptionsForSelectTeamOrMember = ({
  input,
  members,
  profile,
  teams,
  value,
  shouldShowProfile,
}: {
  input: string;
  members: List<Member>;
  profile: Member;
  teams?: List<Team>;
  value?: string;
  shouldShowProfile?: boolean;
}): TeamOrMemberOptionType[] | NestedTeamOrMemberOptionsType[] => {
  const options = input?.length
    ? getOptionsForSelectTeamOrMemberWithInput({ input, members, teams }) // Filtered list of teams and members
    : getDefaultOptionsForSelectTeamOrMember({ members, profile, teams, value, shouldShowProfile }); // segmented list of teams and members
  return options;
};

export const findSelectedTeamOrMemberFromFlattenedOptions = (
  selectedOptionValue: string,
  collection: TeamOrMemberOptionType[],
) => {
  const selected = collection.find((option) => option?.value === selectedOptionValue);
  return selected;
};

export const findSelectedTeamOrMemberFromNestedOptions = (
  selectedOptionValue: string,
  collection: NestedTeamOrMemberOptionsType[],
) => {
  const flattenedOptions = collection.flatMap((group) => group.options);
  const selected = findSelectedTeamOrMemberFromFlattenedOptions(selectedOptionValue, flattenedOptions);
  return selected;
};

// This converts the passed-in value into the option to render in the
// select dropdown. We look through the list of options to see if we can
// find one with the same `value` as the `value` we pass in as a prop.
// Sometimes, because we're dealing with nested options, we flatten the
// list of options and look through that list to see if we can find it.
export const findSelectedTeamOrMemberFromOptions = (
  selectedOptionValue: string,
  options: TeamOrMemberOptionType[] | NestedTeamOrMemberOptionsType[],
) => {
  let selectedTeamOrMemberOption = findSelectedTeamOrMemberFromFlattenedOptions(
    selectedOptionValue,
    options as TeamOrMemberOptionType[],
  );
  if (selectedTeamOrMemberOption) {
    return selectedTeamOrMemberOption;
  }
  selectedTeamOrMemberOption = findSelectedTeamOrMemberFromNestedOptions(
    selectedOptionValue,
    options as NestedTeamOrMemberOptionsType[],
  );
  if (selectedTeamOrMemberOption) {
    return selectedTeamOrMemberOption;
  }
};
