import { isExpiredTrialWithNoPlan as isLegacyExpiredTrialWithNoPlan } from '../internal/legacySubscription';
import { isExpiredTrialWithNoPlan as isModernExpiredTrialWithNoPlan } from '../internal/modernSubscription';
import { Subscription } from '../types';

import { isModernSubscription } from './isModernSubscription';

export function isExpiredTrialWithNoPlan(subscription: Subscription) {
  if (isModernSubscription(subscription)) {
    return isModernExpiredTrialWithNoPlan(subscription);
  }

  return isLegacyExpiredTrialWithNoPlan(subscription);
}
