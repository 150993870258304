import { accessesIncludesAction } from './accessesIncludesAction';
import { accessesStartsWith } from './accessesStartsWith';
import { titleStartsWith } from './titleStartsWith';
import { AuditLogEntryRep } from './types';

export function isCreation(AuditLogEntry: AuditLogEntryRep) {
  if (accessesIncludesAction(AuditLogEntry, 'updateScheduledChanges') && titleStartsWith(AuditLogEntry, 'scheduled')) {
    return true;
  }
  if (accessesIncludesAction(AuditLogEntry, 'updateFeatureWorkflows') && titleStartsWith(AuditLogEntry, 'added')) {
    return true;
  }
  if (
    accessesIncludesAction(AuditLogEntry, 'addReleasePipeline') &&
    (titleStartsWith(AuditLogEntry, 'added') || titleStartsWith(AuditLogEntry, 'created'))
  ) {
    return true;
  }
  if (
    accessesIncludesAction(AuditLogEntry, 'autoProvisionDomainMatchedMember') ||
    accessesIncludesAction(AuditLogEntry, 'approveDomainMatchedMember')
  ) {
    return true;
  }
  return accessesStartsWith(AuditLogEntry, 'create', 'clone');
}
