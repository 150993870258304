import cx from 'clsx';

import './styles.css';

type CardContentProps = {
  className?: string;
  children: React.ReactNode;
};

export function CardContent({ children, className }: CardContentProps) {
  return <div className={cx('Card-content', className)}>{children}</div>;
}
