import { matchFlags, matchFlagTargeting } from '@gonfalon/navigator';
import { AnyAction } from 'redux';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, tap } from 'rxjs/operators';

import flagActionTypes from 'actionTypes/flags';
import registry from 'epics/registry';
import { GlobalState } from 'reducers';
import { currentEnvironmentSelector } from 'reducers/projects';
import { pathnameSelector } from 'reducers/router';
import { EventLocation } from 'utils/analyticsUtils';
import { trackFlagListEvent, trackFlagTargetingEvent } from 'utils/flagUtils';
import {
  OnOffInstructionKind,
  TurnFlagOffSemanticInstruction,
  TurnFlagOnSemanticInstruction,
} from 'utils/instructions/onOff/types';
import { InstructionCategory, SemanticInstruction } from 'utils/instructions/shared/types';

function isToggleInstruction(
  instruction: SemanticInstruction,
): instruction is TurnFlagOffSemanticInstruction | TurnFlagOnSemanticInstruction {
  return (
    instruction.kind === OnOffInstructionKind.TURN_FLAG_OFF || instruction.kind === OnOffInstructionKind.TURN_FLAG_ON
  );
}

function isToggleAction(action: AnyAction) {
  return action.instructions.some(isToggleInstruction);
}

export function trackFlagToggleClickEvents(action$: Observable<AnyAction>, state$: StateObservable<GlobalState>) {
  return action$.pipe(
    ofType(flagActionTypes.CHANGE_FLAG_CONFIGURATION, 'pendingChanges/SET_FLAG_CONFIGURATION_INSTRUCTIONS'),
    tap((action) => {
      const pathname = pathnameSelector(state$.value);
      const environment = currentEnvironmentSelector(state$.value).get('entity');

      const isFlagDashboard = matchFlags(pathname);
      const isFlagTargeting = matchFlagTargeting(pathname);

      let location: EventLocation;
      if (isFlagDashboard) {
        location = EventLocation.FLAG_LIST;
      } else if (isFlagTargeting) {
        location = EventLocation.FLAG_TARGETING;
      } else {
        return;
      }

      let track;
      if (isFlagDashboard) {
        track = trackFlagListEvent;
      } else if (isFlagTargeting) {
        track = trackFlagTargetingEvent;
      } else {
        return;
      }

      let on: boolean;
      switch (action.type) {
        case flagActionTypes.CHANGE_FLAG_CONFIGURATION:
          if (!isToggleAction(action)) {
            return;
          }

          on = action.flag.isOn(environment.key);
          break;
        case 'pendingChanges/SET_FLAG_CONFIGURATION_INSTRUCTIONS':
          // The toggle on the dashboard triggers this action, not the other one, and we don't care about this action
          // on the targeting page because the UX is different there, and we potentially trigger _both_ actions.
          if (!isFlagDashboard || action.instructions.isEmpty()) {
            return;
          }

          const onOffInstruction = action.instructions.get(InstructionCategory.ON_OFF);

          if (!onOffInstruction) {
            return;
          }

          on = onOffInstruction.kind === 'turnFlagOn';
          break;
        default:
          return;
      }

      track('Toggle Button Clicked', {
        toggleActioned: on ? 'on' : 'off',
        location,
      });
    }),
    ignoreElements(),
  );
}

export function trackFlagToggleUpdateEvents(action$: Observable<AnyAction>, state$: StateObservable<GlobalState>) {
  return action$.pipe(
    ofType(flagActionTypes.UPDATE_FLAG),
    tap(({ oldFlag, newFlag }) => {
      const pathname = pathnameSelector(state$.value);
      const environment = currentEnvironmentSelector(state$.value).get('entity');

      const isFlagDashboard = matchFlags(pathname);
      const isFlagTargeting = matchFlagTargeting(pathname);

      // We only care about "on" changes, and ignore irrelevant flag changes
      if (oldFlag.isOn(environment.key) === newFlag.isOn(environment.key)) {
        return;
      }

      let location: EventLocation;
      if (isFlagDashboard) {
        location = EventLocation.FLAG_LIST;
      } else if (isFlagTargeting) {
        location = EventLocation.FLAG_TARGETING;
      } else {
        return;
      }

      let track;
      if (isFlagDashboard) {
        track = trackFlagListEvent;
      } else if (isFlagTargeting) {
        track = trackFlagTargetingEvent;
      } else {
        return;
      }

      track('Feature Flag Toggled', {
        toggleActioned: newFlag.isOn(environment.key) ? 'on' : 'off',
        location,
      });
    }),
    ignoreElements(),
  );
}

registry.addEpics([trackFlagToggleClickEvents, trackFlagToggleUpdateEvents]);
