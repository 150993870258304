import { AnyAction, combineReducers } from 'redux';

import actionTypes from 'actionTypes/sso';
import { GlobalState } from 'reducers';
import registry from 'reducers/registry';
import { SamlAppDetails, SamlConfig } from 'utils/accountUtils';
import { createRequest } from 'utils/requestUtils';

const request = (state = createRequest(), action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_SAML_CONFIG:
      return state.start();
    case actionTypes.GET_SAML_CONFIG_DONE:
      return state.done(action);
    case actionTypes.GET_SAML_CONFIG_FAILED:
      return state.failed(action);
    default:
      return state;
  }
};

const entity = (state: SamlConfig | null = null, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_SAML_CONFIG_DONE:
    case actionTypes.CREATE_SAML_CONFIG_DONE:
    case actionTypes.UPDATE_SAML_CONFIG_DONE:
      return action.config;
    case actionTypes.DELETE_SAML_CONFIG_DONE:
      return null;
    case actionTypes.SET_IS_MANAGING_TEAMS_DONE:
      return state !== null ? state.mergeDeep({ isManagingTeams: action.isManagingTeams }) : null;
    default:
      return state;
  }
};

const samlAppDetailsEntity = (state = SamlAppDetails, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_SAML_APP_DETAILS_DONE:
      return action.config;
    default:
      return state;
  }
};

const samlAppDetailsRequest = (state = createRequest(), action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_SAML_APP_DETAILS:
      return state.start();
    case actionTypes.GET_SAML_APP_DETAILS_DONE:
      return state.done(action);
    case actionTypes.GET_SAML_APP_DETAILS_FAILED:
      return state.failed(action);
    default:
      return state;
  }
};

export const samlConfig = combineReducers({
  request,
  entity,
});

export const samlAppDetails = combineReducers({
  entity: samlAppDetailsEntity,
  request: samlAppDetailsRequest,
});

export const samlConfigSelector = (state: GlobalState) => state.samlConfig;
export const samlAppDetailsSelector = (state: GlobalState) => state.samlAppDetails;

registry.addReducers({ samlConfig, samlAppDetails });
