import { GetState, GlobalDispatch } from 'reducers';
import { followPreferencesEntitySelector } from 'reducers/followPreferences';
// eslint-disable-next-line import/no-namespace
import * as AccountAPI from 'sources/AccountAPI';
import { FollowPreferences } from 'utils/accountUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import { resourceKinds } from 'utils/resourceUtils';

export function createFollowResourceActionCreator<
  Request extends string,
  RequestDone extends string,
  RequestFailed extends string,
>(
  resourceKind: resourceKinds,
  request: (
    resourceId: string,
    isFollowed: boolean,
    preferences: FollowPreferences,
  ) => Readonly<{ type: Request; resourceId: string; isFollowed: boolean; preferences: FollowPreferences }>,
  requestDone: (
    resourceId: string,
    isFollowed: boolean,
    preferences: FollowPreferences,
  ) => Readonly<{ type: RequestDone; resourceId: string; isFollowed: boolean; preferences: FollowPreferences }>,
  requestFailed: (
    resourceId: string,
    isFollowed: boolean,
    preferences: FollowPreferences,
    error: ImmutableServerError,
  ) => Readonly<{
    type: RequestFailed;
    resourceId: string;
    isFollowed: boolean;
    preferences: FollowPreferences;
    error: ImmutableServerError;
  }>,
) {
  return (resourceId: string, isFollowed: boolean) => (dispatch: GlobalDispatch, getState: GetState) => {
    const preferences = followPreferencesEntitySelector(getState()) as FollowPreferences;
    const nextPreferences = preferences.updateResource(resourceId, resourceKind, isFollowed);

    dispatch(request(resourceId, isFollowed, nextPreferences));
    AccountAPI.updateFollowPreferences(preferences, nextPreferences).then(
      (updatedPreferences) => dispatch(requestDone(resourceId, isFollowed, updatedPreferences)),
      (error) => dispatch(requestFailed(resourceId, !isFollowed, preferences, error)),
    );
  };
}
