import { findIndex } from '@gonfalon/es6-utils';
import { CustomSelect, OptionProps } from '@gonfalon/launchpad-experimental';
import { stringifyValue } from '@gonfalon/types';
import { getVariationName } from 'ia-poc/services/flag-creation/utils';
import { List } from 'immutable';

import Variation from 'components/Variation';
import { colorVariation, Flag as FlagType, Variation as VariationType } from 'utils/flagUtils';

type OptionTypes = {
  value: string;
  label: string;
  description: string;
  color: string;
  key: string;
};

type FlagVariationSelectType = {
  className?: string;
  disabled?: boolean;
  clearable?: boolean;
  placeholder?: string;
  variations?: List<VariationType>;
  value?: VariationType | null;
  noMarker: boolean;
  onChange: (v?: VariationType) => void;
  isFlagOn: boolean;
  isInPortal?: boolean;
  flag?: FlagType;
  showOffVariationText?: boolean;
  ariaLabel?: string;
  id?: string;
};

type SelectedValueProp = {
  value: string;
  label: string;
  description: string;
  color: boolean | string;
  key: string;
};

const FlagVariationSelect = ({
  variations = List(),
  value = null,
  noMarker = false,
  onChange,
  isFlagOn,
  flag,
  showOffVariationText = false,
  disabled,
  ...props
}: FlagVariationSelectType) => {
  const renderValue = (option: OptionTypes) => (
    <Variation name={option.label} value={option.value} color={option.color} description={option.description} />
  );

  const options = variations
    .map((v, i) => ({
      value: v.getValueString(),
      label: v.name || v.getValueString(),
      description: v.description,
      color: !noMarker && colorVariation(i),
      key: v._key,
    }))
    .toJS();

  let selectedValue: SelectedValueProp | undefined;

  if (value && flag) {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    // The value must exist since it is being referenced in the flag configuration.
    const foundValue = flag.findVariationByValue(
      value.value,
    )!; /* eslint-enable @typescript-eslint/no-non-null-assertion */
    const { value: val, _key: key, name, description } = foundValue;
    const variationValue = stringifyValue(val);
    const variationLabel = name || variationValue;
    const index = findIndex(variations.toJS(), (v) => v._key === key);
    selectedValue = {
      value: variationValue,
      label: isFlagOn || !showOffVariationText ? variationLabel : `${variationLabel} - off variation`,
      description,
      color: !noMarker && colorVariation(index),
      key,
    };
  }

  return (
    <CustomSelect
      {...props}
      disabled={disabled}
      formatOptionLabel={(option) => renderValue(option as OptionTypes)}
      getOptionLabel={(option: OptionTypes) =>
        getVariationName(
          variations.toJS(),
          variations.findIndex((v) => v._key === option.key),
        )
      }
      value={selectedValue}
      options={options}
      onChange={(option: OptionProps) =>
        onChange(option?.value ? variations.find((v) => v._key === option.key) : option?.value)
      }
    />
  );
};

/* eslint-disable import/no-default-export */
export default FlagVariationSelect;
