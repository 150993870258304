import { cloneElement, useRef } from 'react';
import { useId, VisuallyHidden } from 'react-aria';
import type { IconProps } from '@launchpad-ui/icons';
import { Icon } from '@launchpad-ui/icons';
import classNames from 'clsx';
import type { PopoverPlacement } from 'launchpad';
import { Tooltip } from 'launchpad';

import styles from 'stylesheets/components/HelpTooltip.module.css';

export type HelpTooltipProps = {
  placement?: PopoverPlacement;
  className?: string;
  size?: IconProps['size'];
  children?: string | JSX.Element;
  hoverCloseDelay?: number;
  restrictWidth?: boolean;
  enforceFocus?: boolean;
  labelText?: string;
  iconClassName?: string;
};

function HelpTooltipComponent({
  children,
  placement = 'bottom',
  className,
  size = 'small',
  hoverCloseDelay,
  restrictWidth,
  enforceFocus,
  labelText,
  iconClassName,
  ...props
}: HelpTooltipProps) {
  const elemRef = useRef<HTMLSpanElement>(null);
  const ariaButtonId = useId();

  const parseContent = (toolTipContent: JSX.Element | string | undefined, ariaId: string) => {
    if (!toolTipContent) {
      return toolTipContent;
    }
    if (typeof toolTipContent === 'string') {
      return (
        <div role="tooltip" id={ariaId}>
          {toolTipContent}
        </div>
      );
    }
    return cloneElement(toolTipContent, { role: 'tooltip', id: ariaId });
  };

  return (
    <Tooltip
      className="Tooltip"
      content={parseContent(children, ariaButtonId)}
      placement={placement}
      hoverCloseDelay={hoverCloseDelay}
      restrictWidth={restrictWidth}
      enforceFocus={enforceFocus}
    >
      <span
        {...props}
        aria-describedby={ariaButtonId}
        ref={elemRef}
        className={classNames(styles.helpTooltip, className)}
        tabIndex={0}
        role="button"
      >
        <VisuallyHidden elementType="span">{labelText ?? 'More Information'}</VisuallyHidden>
        <Icon name="info-circle" className={classNames(styles.helpIcon, iconClassName)} size={size} />
      </span>
    </Tooltip>
  );
}

/* eslint-disable import/no-default-export */
export default function HelpTooltip(props: HelpTooltipProps) {
  return <HelpTooltipComponent {...props} />;
}
