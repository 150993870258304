import { Space } from '../theme';

export enum GridLayoutValue {
  FIT = 'fit',
  FLEX = 'flex',
  FULL = 'full',
  AUTO = 'auto',
  ONE_OF_TWO = '1of2',
  ONE_OF_THREE = '1of3',
  ONE_OF_FOUR = '1of4',
  TWO_OF_THREE = '2of3',
}

export enum CellLayoutValue {
  AUTO = 'auto',
  ONE_OF_TWO = '1of2',
  ONE_OF_THREE = '1of3',
  TWO_OF_THREE = '2of3',
  ONE_OF_FOUR = '1of4',
  TWO_OF_FOUR = '2of4',
  THREE_OF_FOUR = '3of4',
  ONE_OF_TWELVE = '1of12',
  TWO_OF_TWELVE = '2of12',
  THREE_OF_TWELVE = '3of12',
  FOUR_OF_TWELVE = '4of12',
  FIVE_OF_TWELVE = '5of12',
  SIX_OF_TWELVE = '6of12',
  SEVEN_OF_TWELVE = '7of12',
  EIGHT_OF_TWELVE = '8of12',
  NINE_OF_TWELVE = '9of12',
  TEN_OF_TWELVE = '10of12',
  ELEVEN_OF_TWELVE = '11of12',
  TWELVE_OF_TWELVE = '12of12',
}

type responsiveValueObject<T> = {
  mobile?: T;
  tablet?: T;
  desktop?: T;
  wide?: T;
};

export type AlignValueObject = responsiveValueObject<AlignValue>;

export type CellLayoutValueObject = responsiveValueObject<CellLayoutValue>;

export type JustifyValueObject = responsiveValueObject<JustifyValue>;

export type GridLayoutValueObject = responsiveValueObject<GridLayoutValue>;

export enum AlignValue {
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom',
  BASELINE = 'baseline',
}

export enum JustifyValue {
  START = 'start',
  CENTER = 'center',
  END = 'end',
  BETWEEN = 'between',
  AROUND = 'around',
}

export enum GridDirection {
  ROW = 'row',
  ROW_REVERSE = 'row-reverse',
  COLUMN = 'column',
  COLUMN_REVERSE = 'column-reverse',
}

export type DirectionValue = 'row' | 'column';

export type DirectionValueObject = responsiveValueObject<DirectionValue>;

export type GapValue = Space;

export type GapValueObject = responsiveValueObject<Space>;
