import { useSearchParams } from 'react-router-dom';
import { currentEnvironment } from '@gonfalon/bootstrap-data';
import { halfwayIA } from '@gonfalon/dogfood-flags';
import { useParam } from '@gonfalon/router';
import nullthrows from 'nullthrows';

/**
 * Returns the "current" environment key from either the…
 * - url search params: this is the new way of obtaining the current environment key
 * - route params: this is the legacy way of obtaining the current environment key
 * - server: this is the absolute fallback during #proj-halfway-ia
 *
 * Note: This module will end up living somewhere else once we're further along the migration.
 */
export function useSelectedEnvironmentKey(): string;
export function useSelectedEnvironmentKey(options: { optional: boolean }): string | undefined;
export function useSelectedEnvironmentKey({ optional }: { optional?: boolean } = {}): string | undefined {
  const envKey = useParam('envKey', { optional: true });
  const environmentKey = useParam('environmentKey', { optional: true });
  const fromRouteParams = envKey || environmentKey;

  const [searchParams] = useSearchParams();

  const environmentFromServer = currentEnvironment();

  if (!halfwayIA()) {
    // in the old shell, we expect the environment to be specified in the route params (with a fallback from the server)
    const envRouteParamOrFallback = fromRouteParams || environmentFromServer?.key;
    return optional
      ? envRouteParamOrFallback || undefined
      : nullthrows(envRouteParamOrFallback, 'Expected environment key to be defined for v1 URL scheme');
  }

  // if we're in the new shell, we may also have one or more `env` search params but the one we care about is `selected-env`
  const selectedEnvParam = searchParams.get('selected-env');
  return optional
    ? selectedEnvParam || undefined
    : nullthrows(selectedEnvParam, 'Expected selected environment key to be defined');
}
