import { cloneElement, ComponentProps, ComponentPropsWithoutRef, ComponentRef, forwardRef, ReactElement } from 'react';
import { GroupProps, NumberField as NumberField_, NumberFieldProps } from 'react-aria-components';
import { Group, IconButton, IconButtonProps, Input } from '@launchpad-ui/components';
import clsx from 'clsx';

import styles from './NumberField.module.css';

/** Note: `NumberField`'s `onChange` prop will return `NaN` when the field is cleared. */
export const NumberField = forwardRef<ComponentRef<typeof NumberField_>, NumberFieldProps>((props, ref) => (
  <NumberField_
    {...props}
    className={clsx(styles.numberField, props.className)}
    formatOptions={{
      maximumFractionDigits: 20, // default value is 3, 20 is the maximum
      useGrouping: false, // removes all separators
      ...(props.formatOptions ?? {}),
    }}
    ref={ref}
  />
));
NumberField.displayName = 'NumberField';

export const NumberFieldGroup = forwardRef<ComponentRef<typeof Group>, GroupProps>((props, ref) => (
  <Group {...props} className={clsx(styles.group, props.className)} ref={ref} />
));
NumberFieldGroup.displayName = 'NumberFieldGroup';

export const NumberFieldInput = forwardRef<ComponentRef<typeof Input>, ComponentPropsWithoutRef<typeof Input>>(
  (props, ref) => <Input {...props} className={clsx(styles.input, props.className)} ref={ref} />,
);
NumberFieldInput.displayName = 'NumberFieldInput';

export const NumberFieldButton = forwardRef<
  ComponentRef<typeof IconButton>,
  Omit<IconButtonProps, 'size' | 'variant'> & { slot: 'increment' | 'decrement' }
>((props, ref) => (
  <IconButton {...props} className={clsx(styles.button, props.className)} ref={ref} size="small" variant="minimal" />
));
NumberFieldButton.displayName = 'NumberFieldButton';

export const NumberFieldIncrement = forwardRef<
  ComponentRef<typeof NumberFieldButton>,
  Omit<ComponentPropsWithoutRef<typeof NumberFieldButton>, 'aria-label' | 'icon' | 'slot'> &
    Partial<Pick<ComponentPropsWithoutRef<typeof NumberFieldButton>, 'aria-label' | 'icon'>>
>((props, ref) => (
  <NumberFieldButton
    {...props}
    aria-label={props['aria-label'] ?? 'increment'}
    className={clsx(styles.increment, props.className)}
    icon={props.icon ?? 'chevron-up'}
    ref={ref}
    slot="increment"
  />
));
NumberFieldIncrement.displayName = 'NumberFieldIncrement';

export const NumberFieldDecrement = forwardRef<
  ComponentRef<typeof NumberFieldButton>,
  Omit<ComponentPropsWithoutRef<typeof NumberFieldButton>, 'aria-label' | 'icon' | 'slot'> &
    Partial<Pick<ComponentPropsWithoutRef<typeof NumberFieldButton>, 'aria-label' | 'icon'>>
>((props, ref) => (
  <NumberFieldButton
    {...props}
    aria-label={props['aria-label'] ?? 'decrement'}
    className={clsx(styles.decrement, props.className)}
    icon={props.icon ?? 'chevron-down'}
    ref={ref}
    slot="decrement"
  />
));
NumberFieldDecrement.displayName = 'NumberFieldDecrement';

export function NumberFieldLegacyStyles({
  children,
}: {
  children: ReactElement<ComponentProps<typeof NumberField>, typeof NumberField>;
}) {
  return cloneElement(children, { className: clsx(children.props.className, styles.legacy) });
}
