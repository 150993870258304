import { type GetFeatureFlagsQueryParams } from '@gonfalon/openapi';

import { parseUIFlagFilterFromAPI } from './parseUIFlagFilterFromAPI';
import { type UIFlagListView } from './types';

export function parseUIFlagListViewFromAPI(api: GetFeatureFlagsQueryParams) {
  const ui: UIFlagListView = {};
  ui.limit = api.limit;
  ui.offset = api.offset;
  ui.sort = api.sort;

  if (api.filter) {
    ui.filter = parseUIFlagFilterFromAPI(api.filter);
  }

  return ui;
}
