import cx from 'clsx';
import type { Change } from 'diff';
import { diffJson, diffLines, diffWords } from 'diff';

import 'stylesheets/components/Diff.css';

const comparisonFunction = {
  words: diffWords,
  lines: diffLines,
  json: diffJson,
};

type DiffProps = {
  a: string | object;
  b: string | object;
  comparison: 'words' | 'lines' | 'json';
  hasBorders: boolean;
  onlyShowDifferences?: boolean;
};

type ComparisonType = typeof diffWords & typeof diffLines & typeof diffJson;

export function Diff({ a, b, comparison, hasBorders = true, onlyShowDifferences = false }: DiffProps) {
  const compare = comparisonFunction[comparison] as ComparisonType;
  const changes: Change[] = compare(a, b);

  const result = changes.map(({ value, added, removed }, index) => {
    let node;

    if (removed) {
      node = <del key={index}>{value}</del>;
    } else if (added) {
      node = <ins key={index}>{value}</ins>;
    } else {
      if (!onlyShowDifferences) {
        node = <span key={index}>{value}</span>;
      }
    }

    return node;
  });

  return <pre className={cx('Diff', { 'Diff--borders': !hasBorders })}>{result}</pre>;
}
