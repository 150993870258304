import { Children } from 'react';
import cx from 'clsx';

import { Space } from '../theme';

import styles from './Inline.module.css';

export type InlineProps = {
  gap?: Space;
  alignX?: 'left' | 'center' | 'right';
  alignY?: 'top' | 'center' | 'bottom';
  children?: React.ReactNode;
};

export function Inline({ gap = '0', alignX = 'left', alignY = 'center', children, ...props }: InlineProps) {
  return (
    <div {...props} className={cx(styles.inline, styles[`gap-${gap}`])}>
      <div className={cx(styles.container, styles[`alignX-${alignX}`], styles[`alignY-${alignY}`])}>
        {Children.map(
          children,
          (child, index) =>
            child && (
              <div key={index} className={styles.item}>
                {child}
              </div>
            ),
        )}
      </div>
    </div>
  );
}
