import { DateFormat } from '@gonfalon/format';
import { type PopoverPlacement, Tooltip } from '@launchpad-ui/core';
import { format as formatWithoutTimeZone, parseISO } from 'date-fns';
import { format as formatWithTimeZone } from 'date-fns-tz';

import { relativeDateString } from '../relativeDateString';

import styles from './Time.module.css';

type TimeProps = {
  /**
   * The date to be rendered. May be a date object, unix millis timestamp,
   * or ISO string.
   */
  datetime: Date | number | string | undefined | null;

  /**
   * A date format string
   * https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
   * https://date-fns.org/v2.6.0/docs/format
   */
  dateFormat?: DateFormat;

  /**
   * When true, time will be rendered as a relative date (e.g. "3 minutes ago")
   */
  fromNow?: boolean;

  /**
   * This will add a postfix otherwise "ago" will be used for relative dates
   */
  postfix?: string;

  /**
   * When fromNow and fuzzySeconds are both true, any time less than a minute
   * from the current time will be rendered as "Less than a minute ago".
   */
  fuzzySeconds?: boolean;

  /**
   * When fromNow and fuzzyMinutes are both true, any time greater than a minute but
   * less than an hour from the current time will be rendered as "Less than an hour ago".
   */
  fuzzyMinutes?: boolean;

  /**
   * Disables the absolute timestamp tooltip.
   */
  notooltip?: boolean;

  tooltipOptions?: {
    placement?: PopoverPlacement;
    rootElementStyle?: object;
    targetClassName?: string;
  };

  children?: React.ReactNode | ((formatted: string) => React.ReactNode);
  className?: string;
  includeTimeZone?: boolean;
};

export function Time({
  datetime,
  dateFormat = DateFormat.MM_DD_YYYY,
  fromNow,
  notooltip,
  children,
  fuzzyMinutes,
  fuzzySeconds,
  postfix,
  includeTimeZone,
  tooltipOptions,
  ...rest
}: TimeProps) {
  const format = includeTimeZone ? formatWithTimeZone : formatWithoutTimeZone;
  let date = datetime;
  if (!datetime) {
    return null;
  }
  if (typeof datetime === 'string') {
    date = parseISO(datetime);
  }

  let formatted;
  if (fromNow) {
    let dateString = relativeDateString(date as Date | number);
    if (fuzzyMinutes && dateString !== 0 && dateString.includes('minute')) {
      dateString = 'Less than an hour';
    } else if (fuzzySeconds && dateString !== 0 && dateString.includes('second')) {
      dateString = 'Less than a minute';
    }
    const ending = postfix ?? 'ago';
    formatted = `${dateString} ${ending}`;
  } else {
    formatted = format(date as Date | number, dateFormat);
  }
  let content;

  if (children) {
    content = typeof children === 'function' ? children(formatted) : children;
  } else {
    content = formatted;
  }

  const base = (
    <time {...rest} dateTime={format(date as Date | number, 'yyyy-MM-dd h:mm:ss')}>
      {content}
    </time>
  );

  if (notooltip) {
    return base;
  } else {
    return (
      <Tooltip {...tooltipOptions} content={format(date as Date | number, DateFormat.DDDD_MMMM_D_YYY_H_MM_A)}>
        <span role="button" tabIndex={0} className={styles.content}>
          {base}
        </span>
      </Tooltip>
    );
  }
}
