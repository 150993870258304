import { isDefaultFlagFilterValue } from './internal/isDefaultFlagFilterValue';
import { flagEnvironmentFilters } from './flagEnvironmentFilters';
import { type UIFlagFilter } from './types';

export function countActiveEnvironmentFilters(filters: UIFlagFilter | undefined) {
  if (filters === undefined) {
    return 0;
  }

  let count = 0;

  for (const key of flagEnvironmentFilters) {
    const value = filters[key];

    if (value === undefined) {
      continue;
    }

    if (isDefaultFlagFilterValue(key, value)) {
      continue;
    }

    if (typeof value === 'string') {
      if (value !== '') {
        count++;
        continue;
      }
    }

    if (Array.isArray(value)) {
      if (value.length > 0) {
        count++;
        continue;
      }
    }

    if (value !== undefined) {
      count++;
      continue;
    }

    count++;
  }

  return count;
}
