import { isProjectFilter } from './isProjectFilter';
import { type UIFlagFilter } from './types';

export function clearProjectFilters(filter: UIFlagFilter) {
  const cleared = structuredClone(filter);

  for (const key of Object.keys(filter)) {
    if (isProjectFilter(key)) {
      cleared[key] = undefined;
    }
  }

  return cleared;
}
