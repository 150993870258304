import { MeasuredRollout } from './types';

export function getMeasuredRolloutRegressions(measuredRollout: MeasuredRollout, includeDismissed = false) {
  const statuses = ['regressed'];

  if (includeDismissed) {
    statuses.push('regression_dismissed');
  }

  return measuredRollout.design.metrics && measuredRollout.state.metricStatuses
    ? measuredRollout.design.metrics.filter((m) => statuses.includes(measuredRollout.state.metricStatuses[m.key] ?? ''))
    : [];
}
