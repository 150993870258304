import { useContext, useEffect } from 'react';
import { FetchStatus } from '@tanstack/react-query';

import { ExperimentsContext } from '../providers/ExperimentsProvider';
import { ExperimentStatus, ExperimentV2 } from '../types';

export type ExperimentsByFlagKeyState = {
  experiments: ExperimentV2[];
  isLoading: boolean;
  refetch: () => void;
  fetchStatus: FetchStatus;
};

export function useExperimentsByFlagKey(flagKey: string, expStatus?: ExperimentStatus): ExperimentsByFlagKeyState {
  const context = useContext(ExperimentsContext);

  if (!context) {
    throw new Error('useExperimentsByFlagKey must be used within a ExperimentsProvider');
  }

  useEffect(() => {
    context.setFlagKey(flagKey);
  }, [flagKey]);

  const experiments = expStatus
    ? context.experiments.filter((exp) => exp.currentIteration.status === expStatus)
    : context.experiments;

  return { experiments, isLoading: context.isLoading, refetch: context.refetch, fetchStatus: context.fetchStatus };
}

/* eslint-disable import/no-default-export */
export default useExperimentsByFlagKey;
