import { AnyAction, combineReducers } from 'redux';

import envActionTypes from 'actionTypes/environments';
import actionTypes from 'actionTypes/profile';
import { GlobalState } from 'reducers';
import { createRequestReducer } from 'reducers/createRequestReducer';
import { createFollowPreferences } from 'utils/accountUtils';

import registry from './registry';

const request = createRequestReducer([
  actionTypes.REQUEST_FOLLOW_PREFERENCES,
  actionTypes.REQUEST_FOLLOW_PREFERENCES_DONE,
  actionTypes.REQUEST_FOLLOW_PREFERENCES_FAILED,
]);

const entity = (state = createFollowPreferences(), action: AnyAction) => {
  switch (action.type) {
    case actionTypes.REQUEST_FOLLOW_PREFERENCES_DONE:
    case envActionTypes.FOLLOW_ENVIRONMENT_DONE:
      return action.preferences;
    default:
      return state;
  }
};

export const followPreferences = combineReducers({
  request,
  entity,
});

registry.addReducers({ followPreferences });

export const followPreferencesBaseSelector = (state: GlobalState) => state.followPreferences;

export const followPreferencesRequestSelector = (state: GlobalState) => followPreferencesBaseSelector(state).request;

export const followPreferencesEntitySelector = (state: GlobalState) => followPreferencesBaseSelector(state).entity;
