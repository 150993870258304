import { useState } from 'react';
import { DialogTrigger } from '@launchpad-ui/components';

import { LegacyMeasuredRolloutMetricsButton, MeasuredRolloutMetricsModal } from './MeasuredRolloutMetrics';

export function LegacyManageFlagMeasuredRolloutMetricsButton({
  projectKey,
  flagKey,
}: {
  projectKey: string;
  flagKey: string;
}) {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <DialogTrigger>
        <LegacyMeasuredRolloutMetricsButton projectKey={projectKey} flagKey={flagKey} setModalOpen={setModalOpen} />
        <MeasuredRolloutMetricsModal
          isOpen={isModalOpen}
          setIsOpen={setModalOpen}
          projectKey={projectKey}
          flagKey={flagKey}
        />
      </DialogTrigger>
    </>
  );
}
