import { useMemo } from 'react';
import { useMatches } from 'react-router-dom';
import { z } from 'zod';

const orderingSchema = z.object({
  Ordering: z.function().returns(z.any()),
});

/**
 * Render the ordering options for the current route.
 *
 * A route defines its ordering options via its `handle`.
 */
export function Ordering() {
  const matches = useMatches();

  const RouteOrdering = useMemo(() => {
    const reversedMatches = [...matches].reverse();

    for (const match of reversedMatches) {
      const parse = orderingSchema.safeParse(match.handle);
      if (parse.success) {
        return parse.data.Ordering;
      }
    }
    return undefined;
  }, [matches]);

  if (RouteOrdering === undefined) {
    return null;
  }

  return (
    <div>
      <RouteOrdering />
    </div>
  );
}
