import { ReactNode } from 'react';
import classNames from 'clsx';
import { Radio, RadioProps } from 'launchpad';

import styles from './FeatureCardButton.module.css';

export type FeatureCardButtonProps = RadioProps & {
  label: string | JSX.Element;
  subText?: ReactNode;
  imgSrc?: string;
  altText?: string;
  isSmallFormat?: boolean;
  hideSubtext?: boolean;
  className?: string;
};

export const FeatureCardButton = ({
  label,
  subText,
  imgSrc,
  altText,
  id,
  value,
  checked,
  disabled = false,
  isSmallFormat = false,
  hideSubtext = disabled,
  className,
  ...rest
}: FeatureCardButtonProps) => (
  // Normally you'd want to pass the 'ref' attribute from the React Hook form field
  // but we can't do that because RadioGroup and Radio don't do a forward ref.
  // RHF uses the ref for focus management.
  <>
    {isSmallFormat ? (
      <>
        <Radio
          id={id}
          className={styles.hideRadio}
          labelClassName={classNames(styles.smallFeatureCard, { [styles.disabledCard]: disabled }, className)}
          checked={checked}
          {...rest}
          value={value}
          readOnly={disabled}
        >
          <label htmlFor={id} className={styles.smallLabelContainer}>
            {imgSrc && <img src={imgSrc} alt={altText} />}
            {label}
          </label>
        </Radio>
      </>
    ) : (
      <Radio
        id={id}
        className={styles.hideRadio}
        labelClassName={classNames(styles.featureCard, { [styles.disabledCard]: disabled }, className)}
        checked={checked}
        {...rest}
        value={value}
        readOnly={disabled}
      >
        <label htmlFor={id} className={styles.labelContainer}>
          {imgSrc && <img src={imgSrc} alt={altText} />}
          {label}
        </label>
        <div className={classNames(styles.subtext, { [styles.hide]: hideSubtext })}>{subText}</div>
      </Radio>
    )}
  </>
);
