import { createTrackerForCategory } from '@gonfalon/analytics';

import { AnalyticAttributes } from './analyticsUtils';

export const trackCampaign = createTrackerForCategory('CampaignTrial');

export const trackEnterpriseCampaignCtaClicked = (upsellPage: string, analyticAttributes: AnalyticAttributes) =>
  trackCampaign('Try Enterprise CTA Clicked', { ...analyticAttributes, upsellPage });

export const trackEnterpriseCampaignCancelClicked = (analyticAttributes: AnalyticAttributes) =>
  trackCampaign('Start Enterprise Campaign Cancel Clicked', analyticAttributes);

export const trackEnterpriseCampaignConfirmClicked = (analyticAttributes: AnalyticAttributes) =>
  trackCampaign('Start Enterprise Campaign Confirm Clicked', analyticAttributes);
