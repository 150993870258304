import { createTrackerForCategory } from '@gonfalon/analytics';

import { AnalyticAttributes } from 'utils/analyticsUtils';

export const trackContactUs = createTrackerForCategory('Contact Us');

export const trackContactSalesLinkClicked = (analyticsAttributes: AnalyticAttributes) =>
  trackContactUs('Contact Us Clicked', { ...analyticsAttributes });

export const trackContactSalesFormSubmitted = (attr: { url: string; path: string }) =>
  trackContactUs('Contact Us Form Submitted', attr);
