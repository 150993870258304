import { isSeatLimitEnforced as isSeatLimitEnforcedFlag } from '@gonfalon/dogfood-flags';

import { ModernSubscription, SubscriptionUsage } from '../../types';

import { getSeatLimit } from './getSeatLimit';

export function isSeatLimitReached(subscription: ModernSubscription, subscriptionUsage: SubscriptionUsage) {
  const isSeatLimitEnforced = isSeatLimitEnforcedFlag();
  const seatLimit = getSeatLimit(subscription);

  if (!isSeatLimitEnforced) {
    return false;
  }

  return (
    seatLimit > 0 &&
    subscriptionUsage?.SeatCount !== undefined &&
    subscriptionUsage?.SeatCount > 0 &&
    subscriptionUsage?.SeatCount >= seatLimit
  );
}
