import {
  enableAppsAndAppVersions,
  enableFilterFlagsByCreationDate,
  getFlagListPageSize,
  isABTestingEnabled,
  isCodeReferencesEnabled,
  isDataExportEnabled,
  isDisableCodeReferencesWithOverrideEnabled,
  isFlagStatusEnabled,
  isFollowFlagsEnabledOnDashboard,
} from '@gonfalon/dogfood-flags';
import { has, isEmpty } from '@gonfalon/es6-utils';
import { serializeFlagListFilterParam } from '@gonfalon/openapi';
import { type GetFeatureFlagsQueryParams } from '@gonfalon/openapi';
import { capitalize } from '@gonfalon/strings';
import { closestTo, startOfDay, subDays, subHours } from 'date-fns';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, Record as ImmutableRecord, Set } from 'immutable';
import qs from 'qs';

import { FilterKind, FilterValue } from 'components/ui/multipleFilters/types';
import { getDateNow } from 'utils/dateUtils';
import { scalarValue } from 'utils/filterUtils';
import ContextKindsEvaluatedFilter from 'utils/flagFilters/contextKindsEvaluatedFilter';
import ContextKindTargetedFilter from 'utils/flagFilters/contextKindTargetedFilter';
import CreationDateFlagFilter, { CreationDateRange, isCreationDateRange } from 'utils/flagFilters/creationDateFilter';
import StatusFilter from 'utils/flagFilters/statusFilter';
import TagsFilter from 'utils/flagFilters/tagsFilter';
import TargetingFilter from 'utils/flagFilters/targetingFilter';
import TypeFilter from 'utils/flagFilters/typeFilter';
import { FlagListQueryParam } from 'utils/flagFilters/types';
import { CreateFunctionInput, ImmutableMap } from 'utils/immutableUtils';

import ApplicationEvaluatedFilter from './flagFilters/applicationEvaluatedFilter';
import SegmentTargetedFilter from './flagFilters/segmentTargetedFilter';
import { StateFilter } from './flagFilters/stateFilter';

// Parsing query parameters gives an object where all properties have type `string | string[] | undefined`.
// Even if we only care about a single value, we need to defend against the case where
// a query param is provided multiple times, and parsed as an array. Similarly for arrays. In either case,
// we need to be specific.
//
// `scalarValue` and `arrayValue` help with the conversion.

const arrayValue = (v: string | string[] | undefined) => (typeof v === 'string' ? [v] : v);

type FlagFiltersRecord = {
  tags: Set<string>;
  settings: List<NonNullable<NonNullable<GetFeatureFlagsQueryParams['filter']>['excludeSettings']>[number]>;
  sort: string;
  archived: boolean;
  offset: number;
  limit: number;
  q?: string;
  type?: NonNullable<GetFeatureFlagsQueryParams['filter']>['type'];
  maintainerId?: string;
  maintainerTeamKey?: string;
  hasExperiment?: boolean;
  hasDataExport?: boolean;
  evaluated?: EvaluatedRanges;
  status?: string;
  sdkAvailability?: string;
  filterEnv?: string;
  targeting: string;
  codeReferences?: CodeReferencesFlagFilter;
  followerId?: string;
  creationDate?: CreationDateRange;
  contextKindTargeted?: string;
  segmentTargeted?: string;
  contextKindsEvaluated: Set<string>;
  applicationEvaluated?: string;
  state?: NonNullable<GetFeatureFlagsQueryParams['filter']>['state'];
};

export type CodeReferencesFlagFilter = ImmutableMap<{
  min?: number;
  max?: number;
}>;

// `Partial` makes everything optional, and `Omit` allows us to re-specify a few props
export type FlagFilterProps = Omit<Partial<FlagFiltersRecord>, 'tags' | 'settings' | 'contextKindsEvaluated'> & {
  tags?: string[];
  settings?: Array<NonNullable<NonNullable<GetFeatureFlagsQueryParams['filter']>['excludeSettings']>[number]>;
  contextKindsEvaluated?: string[];
};

export type FlagFiltersQuery = Partial<{
  tag: string | string[];
  settings: string | string[];
  sort: string;
  archived: string;
  offset: string;
  limit: string;
  q: string;
  type: string;
  maintainerId: string;
  maintainerTeamKey: string;
  hasExperiment: string;
  hasDataExport: string;
  evaluated: string;
  status: string;
  sdkAvailability: string;
  filterEnv: string;
  targeting: string;
  codeReferences: string;
  followerId: string;
  creationDate: string;
  contextKindTargeted: string;
  segmentTargeted: string;
  contextKindsEvaluated: string[];
  applicationEvaluated: string;
  state: string;
}>;

export enum FlagSortOrder {
  ARCHIVED_ASC = 'archivedDate',
  ARCHIVED_DESC = '-archivedDate',
  NAME = 'name',
  NAME_REVERSE = '-name',
  NEWEST_FIRST = '-creationDate',
  OLDEST_FIRST = 'creationDate',
  TARGETING_MODIFIED_OLDEST_FIRST = 'targetingModifiedDate',
  TARGETING_MODIFIED_NEWEST_FIRST = '-targetingModifiedDate',
}

const createdSortFilters = {
  [FlagSortOrder.NEWEST_FIRST]: 'Created newest',
  [FlagSortOrder.OLDEST_FIRST]: 'Created oldest',
};

const archivedSortFilters = {
  '-archivedDate': 'Archived newest',
  archivedDate: 'Archived oldest',
};

const nameSortFilters = {
  [FlagSortOrder.NAME]: 'Name A-Z',
  [FlagSortOrder.NAME_REVERSE]: 'Name Z-A',
};

const targetingModifiedSortFilterOptions = {
  [FlagSortOrder.TARGETING_MODIFIED_NEWEST_FIRST]: 'Targeting modified newest',
  [FlagSortOrder.TARGETING_MODIFIED_OLDEST_FIRST]: 'Targeting modified oldest',
};

const sortFilterDisplayNames: Record<FlagSortOrder, string> = {
  ...createdSortFilters,
  ...archivedSortFilters,
  ...nameSortFilters,
  ...targetingModifiedSortFilterOptions,
};

export const getSortFilterOptions = () => ({
  ...createdSortFilters,
  ...nameSortFilters,
  ...targetingModifiedSortFilterOptions,
});

export const initialFlagSortOrder = () => FlagSortOrder.NEWEST_FIRST;

export class FlagFilters extends ImmutableRecord<FlagFiltersRecord>({
  q: '',
  sort: '',
  tags: Set(),
  type: undefined,
  maintainerId: '',
  maintainerTeamKey: '',
  hasExperiment: undefined,
  hasDataExport: undefined,
  evaluated: undefined,
  status: '',
  sdkAvailability: '',
  filterEnv: '',
  settings: List(),
  archived: false,
  offset: 0,
  limit: -1,
  targeting: '',
  codeReferences: undefined,
  followerId: '',
  creationDate: undefined,
  contextKindTargeted: '',
  segmentTargeted: '',
  contextKindsEvaluated: Set(),
  applicationEvaluated: '',
  state: undefined,
}) {
  getFilters(): [
    CreationDateFlagFilter,
    StatusFilter,
    TagsFilter,
    TargetingFilter,
    TypeFilter,
    ContextKindTargetedFilter,
    ContextKindsEvaluatedFilter,
    SegmentTargetedFilter,
    ApplicationEvaluatedFilter,
    StateFilter,
  ] {
    return [
      new CreationDateFlagFilter(this.creationDate),
      new StatusFilter(this.status),
      new TagsFilter(this.tags),
      new TargetingFilter(this.targeting),
      new TypeFilter(this.type),
      new ContextKindTargetedFilter(this.contextKindTargeted),
      new ContextKindsEvaluatedFilter(this.contextKindsEvaluated),
      new SegmentTargetedFilter(this.segmentTargeted),
      new ApplicationEvaluatedFilter(this.applicationEvaluated),
      new StateFilter(this.state),
    ];
  }

  isEmpty() {
    return isEmpty(this.q) && this.settings.isEmpty() && this.areFiltersEmpty();
  }

  toQuery(flagCount: number = 0, omitPaginationParams: boolean = false, alwaysIncludeSort: boolean = false) {
    let ret: FlagFiltersQuery = {};

    const validFilters = removeInvalidFilters(this);

    if (!isEmpty(this.q)) {
      ret.q = this.q;
    }

    if (isABTestingEnabled() && this.hasExperiment !== undefined) {
      ret.hasExperiment = this.hasExperiment.toString();
    }

    if (
      !isDisableCodeReferencesWithOverrideEnabled() &&
      isCodeReferencesEnabled() &&
      this.codeReferences !== undefined
    ) {
      if (this.codeReferences?.get('min') === 1) {
        ret.codeReferences = 'true';
      } else if (this.codeReferences?.get('max') === 0) {
        ret.codeReferences = 'false';
      }
    }

    if (isDataExportEnabled() && this.hasDataExport !== undefined) {
      ret.hasDataExport = this.hasDataExport.toString();
    }

    if (validFilters.evaluated) {
      ret.evaluated = validFilters.evaluated;
    }

    if (!isEmpty(this.maintainerId)) {
      ret.maintainerId = this.maintainerId;
    }

    if (!isEmpty(this.maintainerTeamKey)) {
      ret.maintainerTeamKey = this.maintainerTeamKey;
    }

    if (!isEmpty(this.followerId)) {
      ret.followerId = this.followerId;
    }

    if (!isEmpty(this.settings)) {
      ret.settings = this.settings.toArray();
    }

    if (!isEmpty(validFilters.sdkAvailability)) {
      ret.sdkAvailability = validFilters.sdkAvailability;
    }

    if (!isEmpty(this.filterEnv)) {
      ret.filterEnv = this.filterEnv;
    }

    if (!isEmpty(this.sort) && (this.sort !== initialFlagSortOrder() || alwaysIncludeSort)) {
      ret.sort = this.sort;
    }

    if (this.archived) {
      ret.archived = this.archived.toString();
    }

    if (!isEmpty(this.contextKindTargeted)) {
      ret.contextKindTargeted = this.contextKindTargeted;
    }

    if (!isEmpty(this.segmentTargeted)) {
      ret.segmentTargeted = this.segmentTargeted;
    }

    if (!isEmpty(this.applicationEvaluated)) {
      ret.applicationEvaluated = this.applicationEvaluated;
    }

    if (!isEmpty(this.state)) {
      ret.state = this.state;
    }

    const filters = validFilters.getFilters();
    filters.forEach((filter) => {
      if (filter.isEnabled() && !filter.isEmpty()) {
        ret = {
          ...ret,
          [filter.queryParamName]: filter.toQuery(),
        };
      }
    });

    if (omitPaginationParams) {
      return ret;
    }

    if (this.limit !== -1 && (this.limit !== getFlagListPageSize() || !!this.offset)) {
      ret.limit = this.limit.toString();
    }

    if (this.offset && flagCount > this.offset) {
      ret.offset = this.offset.toString();
    }

    return ret;
  }

  areFiltersEmpty() {
    const filters = this.getFilters();
    for (const filter of filters) {
      if (filter.isEnabled() && !filter.isEmpty()) {
        return false;
      }
    }

    return (
      (isEmpty(this.sort) || this.sort === initialFlagSortOrder()) &&
      isEmpty(this.maintainerId) &&
      isEmpty(this.maintainerTeamKey) &&
      isEmpty(this.followerId) &&
      isEmpty(this.sdkAvailability) &&
      this.evaluated === undefined &&
      (isDataExportEnabled() ? this.hasDataExport === undefined : true) &&
      (isABTestingEnabled() ? this.hasExperiment === undefined : true) &&
      (!isDisableCodeReferencesWithOverrideEnabled() && isCodeReferencesEnabled()
        ? this.codeReferences === undefined
        : true)
    );
  }

  getActiveFilters() {
    let ret: FlagFilterProps = {};

    if (!isEmpty(this.maintainerId)) {
      ret.maintainerId = this.maintainerId;
    }

    if (!isEmpty(this.maintainerTeamKey)) {
      ret.maintainerTeamKey = this.maintainerTeamKey;
    }

    if (!isEmpty(this.followerId)) {
      ret.followerId = this.followerId;
    }

    if (!isEmpty(this.sdkAvailability)) {
      ret.sdkAvailability = this.sdkAvailability;
    }

    if (this.evaluated !== undefined) {
      ret.evaluated = this.evaluated;
    }

    if (isABTestingEnabled() && this.hasExperiment !== undefined) {
      ret.hasExperiment = this.hasExperiment;
    }

    if (
      !isDisableCodeReferencesWithOverrideEnabled() &&
      isCodeReferencesEnabled() &&
      this.codeReferences !== undefined
    ) {
      ret.codeReferences = this.codeReferences;
    }

    if (isDataExportEnabled() && this.hasDataExport !== undefined) {
      ret.hasDataExport = this.hasDataExport;
    }

    if (!isEmpty(this.state)) {
      ret.state = this.state;
    }

    const filters = this.getFilters();

    filters.forEach((filter) => {
      if (filter.isEnabled() && !filter.isEmpty()) {
        ret = {
          ...ret,
          [filter.backendQueryParamName]: filter.toQuery(),
        };
      }
    });

    return ret;
  }

  getAllActiveFiltersExcludingPagination() {
    const activeFilters = this.getActiveFilters();
    if (!isEmpty(this.sort)) {
      activeFilters.sort = this.sort;
    }

    if (!isEmpty(this.q)) {
      activeFilters.q = this.q;
    }

    return activeFilters;
  }

  toQueryString(flagCount: number = 0, omitPaginationParams: boolean = false, alwaysIncludeSort: boolean = false) {
    return qs.stringify(this.toQuery(flagCount, omitPaginationParams, alwaysIncludeSort), { indices: false });
  }

  toBackendQueryString() {
    let params: {
      applicationEvaluated?: string;
      creationDate?: CreationDateRange;
      status?: string;
      tags?: string[];
      targeting?: string;
      type?: 'permanent' | 'temporary';
      contextKindsEvaluated?: string[];
    } = {};
    const filters = this.getFilters();
    filters.forEach((filter) => {
      if (filter.isEnabled() && !filter.isEmpty()) {
        params = {
          ...params,
          [filter.backendQueryParamName]: filter.toQuery(),
        };
      }
    });

    const filter = constructFilterParam(
      {
        q: this.q,
        sdkAvailability: this.sdkAvailability,
        filterEnv: this.filterEnv,
        maintainerId: this.maintainerId,
        maintainerTeamKey: this.maintainerTeamKey,
        followerId: this.followerId,
        hasExperiment: this.hasExperiment,
        hasDataExport: this.hasDataExport,
        evaluated: this.evaluated,
        targeting: this.targeting,
        settings: this.settings.toArray(),
        codeReferences: this.codeReferences,
        segmentTargeted: this.segmentTargeted,
        ...params,
      },
      getDateNow(),
    );
    const q: {
      filter?: string;
      limit?: number;
      offset?: number;
      sort?: string;
    } = {};
    if (filter !== '') {
      q.filter = filter;
    }
    if (this.limit > 0) {
      q.limit = this.limit;
    }
    if (this.offset > 0) {
      q.offset = this.offset;
    }
    if (this.sort) {
      q.sort = this.sort;
    }
    return qs.stringify(q, { indices: false });
  }

  clearFilter(filterCleared: keyof FlagFilterProps) {
    const updatedFilters = this.delete(filterCleared);
    return clearFilterEnvIfNeeded(updatedFilters);
  }

  maybeAddFilterEnv(envKey: string) {
    if (
      !(
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        this.getFilter(FilterKind.STATUS)!.isEmpty()
      ) /* eslint-enable @typescript-eslint/no-non-null-assertion */ ||
      this.evaluated !== undefined ||
      this.hasDataExport !== undefined ||
      !isEmpty(this.targeting) ||
      !isEmpty(this.followerId)
    ) {
      return this.set(AdditionalFilterOption.ENVIRONMENT, envKey);
    }
    return this;
  }

  getFilter(kind: FilterKind) {
    const filters = this.getFilters();

    return filters.find((f) => f.kind === kind);
  }

  getFilterData(kind: FilterKind) {
    const filter = this.getFilter(kind);
    if (!filter) {
      return undefined;
    }
    return filter.getFilterData();
  }

  getOptions(kind: FilterKind) {
    const filter = this.getFilter(kind);
    if (!filter) {
      return [];
    }
    return filter.getOptions();
  }

  getFilterValueDisplayName(filterKind: FilterDisplayType, filterValue?: FilterValue) {
    const filter = this.getFilter(filterKind as FilterKind);
    if (filter) {
      return filter.getFilterValueDisplayName(filterValue);
    }
    switch (filterKind) {
      case FilterKind.SDKAVAILABILITY:
        return flagSdkOptions[filterValue as FlagSDKOptionsType];
      case FilterKind.CODE_REFERENCES:
        const value = filterValue as CodeReferencesFlagFilter;
        return value?.get('min') !== undefined ? 'Yes' : 'No';
      case FilterKind.DATA_EXPORT:
      case FilterKind.EXPERIMENT:
        return filterValue ? 'Yes' : 'No';
      case FilterKind.EVALUATED:
        return evaluatedOptions[filterValue as EvaluatedRanges];
      case AdditionalFilterOption.SORT:
        return sortFilterDisplayNames[filterValue as FlagSortOrder];
      default:
        return filterValue;
    }
  }
}

export const clearFilterEnvIfNeeded = (filters: FlagFilters) => {
  let updatedFilters = filters;
  if (
    updatedFilters.status === '' &&
    updatedFilters.hasDataExport === undefined &&
    updatedFilters.evaluated === undefined &&
    updatedFilters.creationDate === undefined &&
    updatedFilters.targeting === '' &&
    updatedFilters.followerId === ''
  ) {
    updatedFilters = updatedFilters.delete(AdditionalFilterOption.ENVIRONMENT);
  }
  return updatedFilters;
};

/* Note: This function removes bad values if the user edits the filter query string manually.
  We are removing filters with specific enum value. Boolean filters automatically evaluate to false if
  a bad value is given. The tags filter works with any value so is not included here, similarly the
  maintainerId (or maintainerTeamKey) must be a valid id (or key), so a bad value will be applied,
  but the user can then chose a validId/key from the filter dropdown in the toolbar.
*/
export const removeInvalidFilters = (filters: FlagFilters) => {
  let updatedFilters = filters;

  if (!isEmpty(updatedFilters.evaluated) && !has(evaluatedOptions, updatedFilters.evaluated || '')) {
    updatedFilters = updatedFilters.clearFilter(FilterKind.EVALUATED);
  }

  if (!isEmpty(updatedFilters.sdkAvailability) && !has(flagSdkOptions, updatedFilters.sdkAvailability || '')) {
    updatedFilters = updatedFilters.clearFilter(FilterKind.SDKAVAILABILITY);
  }

  filters.getFilters().forEach((filter) => {
    if (filter.isInvalidFilter()) {
      updatedFilters = updatedFilters.clearFilter(filter.kind);
    }
  });
  return updatedFilters;
};

export function createFlagFilters(props: CreateFunctionInput<FlagFilters> = {}) {
  const filters = props instanceof FlagFilters ? props : new FlagFilters(fromJS(props));
  return filters.withMutations((f) => {
    f.update('tags', (ts) => ts.toSet());
    f.update('contextKindsEvaluated', (ts) => ts.toSet());
  });
}

function isEvaluatedRange(v: string): v is EvaluatedRanges {
  return v in evaluatedOptions;
}

export function createFlagFiltersFromSearchParams(searchParams: URLSearchParams) {
  const tags = arrayValue(searchParams.getAll(FlagListQueryParam.TAG));
  const sort = scalarValue(searchParams.get('sort') || initialFlagSortOrder());
  const maintainerId = scalarValue(searchParams.get('maintainerId') || undefined);
  const maintainerTeamKey = scalarValue(searchParams.get('maintainerTeamKey') || undefined);
  const followerId = scalarValue(searchParams.get('followerId') || undefined);
  const hasDataExport = scalarValue(searchParams.get('hasDataExport') || undefined);
  const hasExperiment = scalarValue(searchParams.get('hasExperiment') || undefined);
  const evaluated = scalarValue(searchParams.get('evaluated') || undefined);
  const settings = arrayValue(searchParams.getAll('settings'));
  const archived = scalarValue(searchParams.get('state') || undefined) === 'archived';
  const limit = scalarValue(searchParams.get('limit') || undefined);
  const offset = scalarValue(searchParams.get('offset') || undefined);
  const q = scalarValue(searchParams.get('q') || '');
  const type = scalarValue(searchParams.get('type') || '');
  const status = scalarValue(searchParams.get(FlagListQueryParam.STATUS) || '');
  const sdkAvailability = scalarValue(searchParams.get('sdkAvailability') || '');
  const filterEnv = scalarValue(searchParams.get('filterEnv') || '');
  const contextKindTargeted = scalarValue(searchParams.get('contextKindTargeted') || '');
  const contextKindsEvaluated = arrayValue(searchParams.getAll(FlagListQueryParam.CONTEXT_KINDS_EVALUATED));
  const targeting = scalarValue(searchParams.get(FlagListQueryParam.TARGETING) || '');
  const codeReferences = scalarValue(searchParams.get('codeReferences') || '');
  const creationDate = scalarValue(searchParams.get(FlagListQueryParam.CREATION_DATE) || undefined);
  const applicationEvaluated = scalarValue(searchParams.get(FlagListQueryParam.APPLICATION_EVALUATED) || undefined);
  const state = scalarValue(searchParams.get(FlagListQueryParam.STATE) || undefined);

  const props: FlagFilterProps = {
    q,
    tags: tags?.filter((s: string) => !isEmpty(s)),
    type: typeValue(type),
    settings: excludedSettingsValue(settings),
    status,
    sdkAvailability,
    filterEnv,
    contextKindTargeted,
    contextKindsEvaluated: contextKindsEvaluated?.filter((s: string) => !isEmpty(s)),
    targeting,
    applicationEvaluated,
    state: stateValue(state),
    limit: limit ? parseInt(limit, 10) : getFlagListPageSize(),
    offset: offset ? parseInt(offset, 10) : 0,
  };

  if (sort) {
    props.sort = Array.isArray(sort) ? sort[0] : sort;
  }

  if (archived) {
    props.archived = archived;
  }

  if (maintainerId) {
    props.maintainerId = maintainerId;
  }

  if (maintainerTeamKey) {
    props.maintainerTeamKey = maintainerTeamKey;
  }

  if (followerId) {
    props.followerId = followerId;
  }

  if (hasExperiment) {
    props.hasExperiment = hasExperiment === 'true';
  }

  if (hasDataExport) {
    props.hasDataExport = hasDataExport === 'true';
  }

  if (codeReferences) {
    if (codeReferences === 'true') {
      props.codeReferences = Map({ min: 1 });
    } else if (codeReferences === 'false') {
      props.codeReferences = Map({ max: 0 });
    }
  }

  if (evaluated && isEvaluatedRange(evaluated)) {
    props.evaluated = evaluated;
  }

  if (creationDate && isCreationDateRange(creationDate)) {
    props.creationDate = creationDate;
  }

  if (targeting) {
    props.targeting = targeting;
  }

  return createFlagFilters(props);
}

export function createFlagFiltersFromBackendQueryString(queryString: string) {
  const backendParams = qs.parse(queryString, { ignoreQueryPrefix: true });
  const filter = parseFilterParam(backendParams.filter, getDateNow());
  const props = {
    archived: backendParams.archived,
    limit: backendParams.limit,
    offset: backendParams.offset,
    sort: backendParams.sort,
    ...filter,
  };
  return createFlagFilters(props);
}

export function parseFilterParam(filterParam = '', currentDate = getDateNow()) {
  const filterParts = filterParam.split(',');
  let result: FlagFilterProps = {
    q: '',
    tags: [],
    type: undefined,
    status: '',
    sdkAvailability: '',
    filterEnv: '',
    maintainerId: '',
    maintainerTeamKey: '',
    followerId: '',
    hasExperiment: undefined,
    hasDataExport: undefined,
    evaluated: undefined,
    targeting: '',
    settings: [],
    codeReferences: undefined,
    creationDate: undefined,
    contextKindTargeted: '',
    contextKindsEvaluated: [],
    applicationEvaluated: '',
    state: undefined,
  };

  const flagFilters = createFlagFilters();
  const filters = flagFilters.getFilters();

  filterParts.forEach((p) => {
    const keyValue = p.split(':');
    const [key, value] = keyValue;
    for (const filter of filters) {
      if (key === filter.kind) {
        result = {
          ...result,
          [key]: filter.parseFilterParam(keyValue, currentDate),
        };
      }
    }
    if (keyValue.length === 2) {
      switch (key) {
        case 'query':
          result.q = decodeURIComponent(value);
          break;
        case 'sdkAvailability':
        case 'filterEnv':
        case 'contextKindTargeted':
        case 'maintainerId':
        case 'maintainerTeamKey':
        case 'followerId':
          result[key] = value;
          break;
        case 'hasExperiment':
        case 'hasDataExport':
          value === 'true' ? (result[key] = true) : (result[key] = false);
          break;
        case 'excludeSettings':
          result.settings = excludedSettingsValue(value.split('+'));
          break;
        // TODO SC-151038: syntax will change to be like `evaluated`
        case `${FilterKind.CODE_REFERENCES}.min`:
          result[FilterKind.CODE_REFERENCES] = Map({ min: 1 });
          break;
        case `${FilterKind.CODE_REFERENCES}.max`:
          result[FilterKind.CODE_REFERENCES] = Map({ max: 0 });
          break;
        default:
          break;
      }
    }

    // keyValue length of evaluated is 3 due to extra ':' in date object so it must be handled separately
    if (keyValue[0] === 'evaluated') {
      const [, , /* ignore key and {"after" */ val] = keyValue;
      const evaluatedAfterDate = val.replace('}', '');
      result.evaluated = getEvaluatedDateAsString(evaluatedAfterDate, currentDate);
    }
  });
  return result;
}

export function constructFilterParam(flagFilterProps: FlagFilterProps = {}, currentDate = getDateNow()) {
  const { evaluated, creationDate, settings = [] } = flagFilterProps;

  const flagFilters = createFlagFilters(flagFilterProps);

  const evaluatedValue = evaluated ? { after: getEvaluatedDates(currentDate)[evaluated] } : undefined;
  const creationDateValue = new CreationDateFlagFilter(creationDate).toBackendQueryValue(currentDate);

  return serializeFlagListFilterParam({
    codeReferences: flagFilters.codeReferences?.toJS(),
    contextKindsEvaluated: flagFilters.contextKindsEvaluated.toArray(),
    contextKindTargeted: flagFilters.contextKindTargeted,
    creationDate: creationDateValue,
    evaluated: evaluatedValue,
    excludeSettings: excludedSettingsValue(settings),
    filterEnv: flagFilters.filterEnv,
    followerId: flagFilters.followerId,
    hasDataExport: flagFilters.hasDataExport,
    hasExperiment: flagFilters.hasExperiment,
    maintainerId: flagFilters.maintainerId,
    maintainerTeamKey: flagFilters.maintainerTeamKey,
    query: flagFilters.q,
    sdkAvailability: sdkAvailabilityValue(flagFilters.sdkAvailability),
    segmentTargeted: flagFilters.segmentTargeted,
    status: statusValue(flagFilters.status),
    targeting: targetingValue(flagFilters.targeting),
    tags: flagFilters.tags.toArray(),
    type: typeValue(flagFilters.type),
    state: stateValue(flagFilters.state),
  });
}

export function typeValue(value: string | undefined) {
  switch (value) {
    case 'permanent':
    case 'temporary':
      return value;
    default:
      return undefined;
  }
}

export function targetingValue(value: string | undefined) {
  switch (value) {
    case 'on':
    case 'off':
      return value;
    default:
      return undefined;
  }
}

export function statusValue(value: string | undefined) {
  switch (value) {
    case 'new':
    case 'inactive':
    case 'active':
    case 'launched':
      return value;
    default:
      return undefined;
  }
}

export function stateValue(value: string | undefined) {
  switch (value) {
    case 'live':
    case 'archived':
    case 'deprecated':
      return value;
    default:
      return undefined;
  }
}

function excludedSettingsValue(value: string[] | undefined) {
  if (value === undefined || value.length === 0) {
    return undefined;
  }

  function assertValidSettingValues(
    v: string[],
  ): asserts v is Array<NonNullable<NonNullable<GetFeatureFlagsQueryParams['filter']>['excludeSettings']>[number]> {
    for (const setting of v) {
      if (!['prerequisites', 'on', 'targets', 'rules', 'fallthrough', 'offVariation'].includes(setting)) {
        throw new Error('Invalid setting value');
      }
    }
  }

  try {
    assertValidSettingValues(value);
    return value;
  } catch (e) {
    return undefined;
  }
}

function sdkAvailabilityValue(value: string | undefined) {
  switch (value) {
    case 'anyClient':
    case 'server':
    case 'mobile':
    case 'client':
      return value;
    default:
      return undefined;
  }
}

/**
 * A representation of a filter kind and the flag that controls it.
 */
type FlagFilterControl = {
  kind: FilterKind;
  flag?: () => boolean;
};

export type FlagFilterSelectOption = {
  value: FilterKind;
  label: string;
};

/**
 * Unsorted list of flag filters with their corresponding flags.
 */
const flagFilterSelectOptions: FlagFilterControl[] = [
  { kind: FilterKind.TAGS },
  { kind: FilterKind.MAINTAINER },
  { kind: FilterKind.STATUS, flag: isFlagStatusEnabled },
  { kind: FilterKind.CREATION_DATE, flag: enableFilterFlagsByCreationDate },
  { kind: FilterKind.FOLLOWER, flag: isFollowFlagsEnabledOnDashboard },
  { kind: FilterKind.EVALUATED },
  { kind: FilterKind.TYPE },
  { kind: FilterKind.TARGETING },
  {
    kind: FilterKind.CODE_REFERENCES,
    flag: () => !isDisableCodeReferencesWithOverrideEnabled() && isCodeReferencesEnabled(),
  },
  { kind: FilterKind.EXPERIMENT, flag: isABTestingEnabled },
  { kind: FilterKind.DATA_EXPORT, flag: () => isDataExportEnabled() },
  { kind: FilterKind.SDKAVAILABILITY },
  { kind: FilterKind.CONTEXT_KIND_TARGETED },
  { kind: FilterKind.CONTEXT_KINDS_EVALUATED },
  { kind: FilterKind.APPLICATION_EVALUATED, flag: enableAppsAndAppVersions },
];

export const buildFlagFilterSelectOption = (kind: FilterKind) => ({ value: kind, label: getFilterDisplayName(kind) });

//** Sorts flag filters alphabetically by the `label` property */

export const sortFlagFilterSelectOptions = (options: FlagFilterSelectOption[]) => {
  options.sort((currOpt: FlagFilterSelectOption, nextOpt: FlagFilterSelectOption) =>
    currOpt.label.localeCompare(nextOpt.label),
  );
  return options;
};

export const getFlagFilterSelectOptions = () => {
  const options: FlagFilterSelectOption[] = [];

  for (const { kind, flag } of flagFilterSelectOptions) {
    if (!flag || flag()) {
      options.push(buildFlagFilterSelectOption(kind));
      continue;
    }
  }

  return sortFlagFilterSelectOptions(options);
};

export const flagSdkOptions: { [key: string]: string } = {
  anyClient: 'All client-side SDKs',
  client: 'SDKs using Client-side ID',
  mobile: 'SDKs using Mobile key',
  server: 'No client-side SDKs',
};

type FlagSDKOptionsType = keyof typeof flagSdkOptions;

export const evaluatedOptions = {
  'last-60-minutes': 'Last 60 minutes',
  'last-24-hours': 'Last 24 hours',
  'last-7-days': 'Last 7 days',
  'last-14-days': 'Last 14 days',
  'last-30-days': 'Last 30 days',
  'last-60-days': 'Last 60 days',
};

export type EvaluatedRanges = keyof typeof evaluatedOptions;

/**
 * These are considered search options or filters, but not FilterKinds.
 */
export enum AdditionalFilterOption {
  QUERY = 'q',
  SORT = 'sort',
  ENVIRONMENT = 'filterEnv',
}

export type FilterDisplayType = FilterKind | AdditionalFilterOption;

const flagFilterDisplayNames: Record<FilterDisplayType, string> = {
  [FilterKind.EVALUATED]: 'Evaluated',
  [FilterKind.CODE_REFERENCES]: 'Has code references',
  [FilterKind.DATA_EXPORT]: 'Data Export',
  [FilterKind.EXPERIMENT]: 'Has experiment',
  [FilterKind.MAINTAINER]: 'Maintainer',
  [FilterKind.MAINTAINER_TEAM]: 'Maintainer',
  [FilterKind.FOLLOWER]: 'Followed',
  [AdditionalFilterOption.QUERY]: 'Search',
  [AdditionalFilterOption.SORT]: 'Sort',
  [FilterKind.STATUS]: 'Status',
  [FilterKind.SDKAVAILABILITY]: 'Client SDK availability',
  [FilterKind.TAGS]: 'Tag(s)',
  [FilterKind.TYPE]: 'Type',
  [FilterKind.TARGETING]: 'Targeting',
  [FilterKind.CREATION_DATE]: 'Created',
  [AdditionalFilterOption.ENVIRONMENT]: 'Environment',
  [FilterKind.CONTEXT_KIND_TARGETED]: 'Context kind targeted',
  [FilterKind.CONTEXT_KINDS_EVALUATED]: 'Context kind(s) evaluated',
  [FilterKind.SEGMENT_TARGETED]: 'Segment targeted',
  [FilterKind.APPLICATION_EVALUATED]: 'Application evaluated',
  [FilterKind.STATE]: 'State',
};

export const getFilterDisplayName = (filterKind: FilterDisplayType) => flagFilterDisplayNames[filterKind];

export const flagFilterValueInputAriaLabels: Record<FilterKind, string> = {
  [FilterKind.EVALUATED]: 'Select time last evaluated',
  [FilterKind.CODE_REFERENCES]: 'Select code reference value',
  [FilterKind.DATA_EXPORT]: 'Select data export value',
  [FilterKind.EXPERIMENT]: 'Select experiment value',
  [FilterKind.MAINTAINER]: 'Select maintainer',
  [FilterKind.MAINTAINER_TEAM]: 'Select maintainer',
  [FilterKind.FOLLOWER]: 'Select follower',
  [FilterKind.STATUS]: 'Select status value',
  [FilterKind.SDKAVAILABILITY]: 'Select client SDK availability',
  [FilterKind.TAGS]: 'Select tags',
  [FilterKind.TYPE]: 'Select flag type value',
  [FilterKind.TARGETING]: 'Select targeting value',
  [FilterKind.CREATION_DATE]: 'Select creation date',
  [FilterKind.CONTEXT_KIND_TARGETED]: 'Select context kind',
  [FilterKind.SEGMENT_TARGETED]: 'Select segment',
  [FilterKind.CONTEXT_KINDS_EVALUATED]: 'Select context kind(s) evaluated',
  [FilterKind.APPLICATION_EVALUATED]: 'Select application evaluated',
  [FilterKind.STATE]: 'Select state',
};

export const getFlagFilterValueInputAriaLabel = (selectedFilter: FilterKind) =>
  flagFilterValueInputAriaLabels[selectedFilter];

export const getEvaluatedOptions = (filters?: FlagFilters) =>
  Object.entries(evaluatedOptions).map(([value, label]) => ({
    label,
    name: label,
    value,
    filterOption: FilterKind.EVALUATED,
    isChecked: filters && filters.evaluated === value,
  }));

export const getExperimentOptions = (filters?: FlagFilters) => [
  {
    label: 'Yes',
    name: 'Yes',
    value: true,
    filterOption: FilterKind.EXPERIMENT,
    isChecked: filters && filters.hasExperiment === true,
  },
  {
    label: 'No',
    name: 'No',
    value: false,
    filterOption: FilterKind.EXPERIMENT,
    isChecked: filters && filters.hasExperiment === false,
  },
];

export const getCodeRefsTrackableValue = (value?: FilterValue) => {
  const val = value as Map<string, number>;
  const max = val?.get('max') ?? -1;
  const min = val?.get('min') ?? -1;
  if (min > -1) {
    return 'some';
  } else if (max === 0) {
    return 'none';
  } else {
    return 'undetermined';
  }
};

export const getCodeRefsOptions = (filters?: FlagFilters) => [
  {
    label: 'Yes',
    name: 'Yes',
    value: Map({ min: 1 }),
    filterOption: FilterKind.CODE_REFERENCES,
    isChecked: (filters?.codeReferences?.get('min') ?? 0) >= 1,
  },
  {
    label: 'No',
    name: 'No',
    value: Map({ max: 0 }),
    filterOption: FilterKind.CODE_REFERENCES,
    isChecked: filters?.codeReferences?.get('max') === 0,
  },
];

export const getDataExportOptions = (filters?: FlagFilters) => [
  {
    label: 'Enabled',
    name: 'Enabled',
    value: true,
    filterOption: FilterKind.DATA_EXPORT,
    isChecked: filters && filters.hasDataExport === true,
  },
  {
    label: 'Disabled',
    name: 'Disabled',
    value: false,
    filterOption: FilterKind.DATA_EXPORT,
    isChecked: filters && filters.hasDataExport === false,
  },
];

export type FilterOptions = {
  [filterKeys: string]: string;
};

export const getFilterKindOptions = (filterKind: FilterKind, filterOptions: FilterOptions) =>
  Object.entries(filterOptions).map(([key, val]) => ({
    label: capitalize(val),
    value: key,
    filterOption: filterKind,
  }));

export type FlagFilterDataInterface = {
  operators: string[];
  options?: Array<{ label: string; value: FilterValue; filterOption: string }>;
  defaultValue?: FilterValue;
};

/**
 * This has the option data for FilterRow
 *
 * There should be one per filter kind that is not implemented as a {@link FlagFilterInterface}
 */
export const flagFilterData: Record<string, FlagFilterDataInterface> = {
  // tags, maintainer, contextKindTargeted, and contextKindsEvaluated have their own containers to fetch data for their options
  maintainerId: { operators: ['is'] },
  maintainerTeamKey: { operators: ['is'] },
  followerId: { operators: ['by'] },
  hasExperiment: { operators: ['is'], options: getExperimentOptions(), defaultValue: true },
  [FilterKind.CODE_REFERENCES]: {
    operators: ['is'],
    options: getCodeRefsOptions(),
    defaultValue: Map({ min: 1 }),
  },
  hasDataExport: {
    operators: ['is'],
    options: getDataExportOptions(),
    defaultValue: true,
  },
  evaluated: { operators: ['in'], options: getEvaluatedOptions(), defaultValue: 'last-60-minutes' },
  sdkAvailability: {
    operators: ['is'],
    options: getFilterKindOptions(FilterKind.SDKAVAILABILITY, flagSdkOptions),
    defaultValue: 'anyClient',
  },
};

type EvaluatedDates = {
  'last-60-minutes': number;
  'last-24-hours': number;
  'last-7-days': number;
  'last-14-days': number;
  'last-30-days': number;
  'last-60-days': number;
};

export function getEvaluatedDates(datetime: Date | number): EvaluatedDates {
  const baselineDate = startOfDay(datetime);
  return {
    'last-60-minutes': subHours(datetime, 1).getTime(),
    'last-24-hours': subHours(datetime, 24).getTime(),
    // anything > 1 day is pinned to start of day
    'last-7-days': subDays(baselineDate, 7).getTime(),
    'last-14-days': subDays(baselineDate, 14).getTime(),
    'last-30-days': subDays(baselineDate, 30).getTime(),
    'last-60-days': subDays(baselineDate, 60).getTime(),
  };
}

export function getEvaluatedDateAsString(evaluatedAfterDate: string, currentDate: Date | number) {
  const evaluatedDate = parseInt(evaluatedAfterDate, 10);
  const evaluatedDates: EvaluatedDates = getEvaluatedDates(currentDate);
  // the unix milliseconds value sent from the backend will be slightly older than the current time so we need to look up by the closest date
  const closestDateToEvalDate = closestTo(evaluatedDate, Object.values(evaluatedDates))?.getTime();
  return (Object.keys(evaluatedDates) as Array<keyof EvaluatedDates>).find(
    (key) => evaluatedDates[key] === closestDateToEvalDate,
  );
}
