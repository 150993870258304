import { ClauseInstructionKind } from 'utils/instructions/clauses/types';
import { ExpiringTargetsInstructionKind } from 'utils/instructions/expiringTargets/types';
import { MembersAndTeamsInstructionKind } from 'utils/instructions/membersAndTeams/types';
import { OnOffInstructionKind } from 'utils/instructions/onOff/types';
import { FlagPrerequisitesInstructionKind } from 'utils/instructions/prerequisites/types';
import { ProgressiveRolloutInstructionKind } from 'utils/instructions/progressive-rollouts/types';
import { filterOutAggregateRules } from 'utils/instructions/rules/aggregateHelpers';
import { AggregateRule, RuleInstructionKind } from 'utils/instructions/rules/types';
import {
  FlagConflictKind,
  InstructionIndexToConflictsInfo,
  SemanticInstruction,
} from 'utils/instructions/shared/types';
import { TargetsInstructionKind } from 'utils/instructions/targets/types';
import { UserTargetsInstructionKind } from 'utils/instructions/userTargets/types';
import { FlagVariationsInstructionKind } from 'utils/instructions/variations/types';

export const RULE_NOT_FOUND = 'rule not found';
export const VARIATION_NOT_FOUND = 'variation not found';
export const TARGET_DISPLAY_LIMIT = 4;

export enum InstructionListCategory {
  TARGETING = 'Targeting',
  PREREQUISITES = 'Prerequisites',
  INDIVIDUAL_TARGETS = 'Individual targets',
  INDIVIDUAL_USERS = 'Individual users',
  RULES = 'Rules',
  DEFAULT_RULE = 'Default rule',
  OFF_VARIATION = 'Off variation',
  VARIATIONS = 'Variations',
  DEFAULT_VARIATION = 'Default variation for new environments',
  PROGRESSIVE_ROLLOUT = 'Progressive rollout',
  MEMBERS_AND_TEAMS = 'Members and teams', // not used in instruction list component, included for type completeness
}

export enum InstructionListSubCategory {
  REORDER_RULES = 'Reorder rules',
  EDIT_RULE = 'Edit rule',
}

export enum InstructionListChangeKind {
  ADD = 'add',
  CHANGE = 'change',
  REMOVE = 'remove',
  REORDER = 'reorder',
  DEFAULT = 'default',
  DECIMAL = 'decimal',
  NONE = 'none',
}

export const InstructionKindToInstructionListCategory: {
  [Kind in SemanticInstruction['kind']]: InstructionListCategory;
} = {
  [UserTargetsInstructionKind.ADD_USER_TARGETS]: InstructionListCategory.INDIVIDUAL_USERS,
  [UserTargetsInstructionKind.REMOVE_USER_TARGETS]: InstructionListCategory.INDIVIDUAL_USERS,
  [UserTargetsInstructionKind.REPLACE_USER_TARGETS]: InstructionListCategory.INDIVIDUAL_USERS,
  [TargetsInstructionKind.ADD_TARGETS]: InstructionListCategory.INDIVIDUAL_TARGETS,
  [TargetsInstructionKind.REMOVE_TARGETS]: InstructionListCategory.INDIVIDUAL_TARGETS,
  [TargetsInstructionKind.REPLACE_TARGETS]: InstructionListCategory.INDIVIDUAL_TARGETS,
  [OnOffInstructionKind.TURN_FLAG_ON]: InstructionListCategory.TARGETING,
  [OnOffInstructionKind.TURN_FLAG_OFF]: InstructionListCategory.TARGETING,
  [OnOffInstructionKind.UPDATE_FALLTHROUGH_VARIATION_OR_ROLLOUT]: InstructionListCategory.DEFAULT_RULE,
  [OnOffInstructionKind.STOP_MEASURED_ROLLOUT_ON_FALLTHROUGH]: InstructionListCategory.DEFAULT_RULE,
  [OnOffInstructionKind.UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT]: InstructionListCategory.DEFAULT_RULE,
  [OnOffInstructionKind.UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT_V2]: InstructionListCategory.DEFAULT_RULE,
  [RuleInstructionKind.ADD_RULE]: InstructionListCategory.RULES,
  [RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT]: InstructionListCategory.RULES,
  [RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT_V2]: InstructionListCategory.RULES,
  [RuleInstructionKind.STOP_MEASURED_ROLLOUT_ON_RULE]: InstructionListCategory.RULES,
  [RuleInstructionKind.REMOVE_RULE]: InstructionListCategory.RULES,
  [RuleInstructionKind.REORDER_RULES]: InstructionListCategory.RULES,
  [RuleInstructionKind.REPLACE_RULES]: InstructionListCategory.RULES,
  // these rules appear in the instruction list component under an "Edit Rule" sub-heading
  // the root heading for these instructions is "Rules"
  [ClauseInstructionKind.ADD_CLAUSES]: InstructionListCategory.RULES,
  [ClauseInstructionKind.REMOVE_CLAUSES]: InstructionListCategory.RULES,
  [ClauseInstructionKind.UPDATE_CLAUSE]: InstructionListCategory.RULES,
  [ClauseInstructionKind.ADD_VALUES_TO_CLAUSE]: InstructionListCategory.RULES,
  [ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE]: InstructionListCategory.RULES,
  [RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT]: InstructionListCategory.RULES,
  [RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT]: InstructionListCategory.RULES,
  [RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT_V2]: InstructionListCategory.RULES,
  [RuleInstructionKind.UPDATE_RULE_DESCRIPTION]: InstructionListCategory.RULES,
  // =====================================================================================
  [OnOffInstructionKind.UPDATE_OFF_VARIATION]: InstructionListCategory.OFF_VARIATION,
  [FlagPrerequisitesInstructionKind.ADD_PREREQUISITE]: InstructionListCategory.PREREQUISITES,
  [FlagPrerequisitesInstructionKind.UPDATE_PREREQUISITE]: InstructionListCategory.PREREQUISITES,
  [FlagPrerequisitesInstructionKind.REMOVE_PREREQUISITE]: InstructionListCategory.PREREQUISITES,
  [FlagPrerequisitesInstructionKind.REPLACE_PREREQUISITES]: InstructionListCategory.PREREQUISITES,
  [ExpiringTargetsInstructionKind.ADD_EXPIRE_USER_TARGET_DATE]: InstructionListCategory.INDIVIDUAL_USERS,
  [ExpiringTargetsInstructionKind.REMOVE_EXPIRE_USER_TARGET_DATE]: InstructionListCategory.INDIVIDUAL_USERS,
  [ExpiringTargetsInstructionKind.UPDATE_EXPIRE_USER_TARGET_DATE]: InstructionListCategory.INDIVIDUAL_USERS,
  [ExpiringTargetsInstructionKind.ADD_EXPIRE_TARGET_DATE]: InstructionListCategory.INDIVIDUAL_TARGETS,
  [ExpiringTargetsInstructionKind.REMOVE_EXPIRE_TARGET_DATE]: InstructionListCategory.INDIVIDUAL_TARGETS,
  [ExpiringTargetsInstructionKind.UPDATE_EXPIRE_TARGET_DATE]: InstructionListCategory.INDIVIDUAL_TARGETS,
  [FlagVariationsInstructionKind.ADD_VARIATION]: InstructionListCategory.VARIATIONS,
  [FlagVariationsInstructionKind.UPDATE_VARIATION]: InstructionListCategory.VARIATIONS,
  [FlagVariationsInstructionKind.REMOVE_VARIATION]: InstructionListCategory.VARIATIONS,
  [FlagVariationsInstructionKind.UPDATE_DEFAULT_VARIATION]: InstructionListCategory.DEFAULT_VARIATION,
  [ProgressiveRolloutInstructionKind.STOP_PROGRESSIVE_ROLLOUT]: InstructionListCategory.PROGRESSIVE_ROLLOUT,
  [MembersAndTeamsInstructionKind.ADD_MEMBERS]: InstructionListCategory.MEMBERS_AND_TEAMS, // not used in instruction list component, included for type completeness
  [MembersAndTeamsInstructionKind.REMOVE_MEMBERS]: InstructionListCategory.MEMBERS_AND_TEAMS, // not used in instruction list component, included for type completeness
  [MembersAndTeamsInstructionKind.ADD_TEAM_KEYS]: InstructionListCategory.MEMBERS_AND_TEAMS, // not used in instruction list component, included for type completeness
} as const;

const instructionCategoryListOrder = [
  InstructionListCategory.TARGETING,
  InstructionListCategory.PREREQUISITES,
  InstructionListCategory.INDIVIDUAL_TARGETS,
  InstructionListCategory.INDIVIDUAL_USERS,
  InstructionListCategory.RULES,
  InstructionListCategory.DEFAULT_RULE,
  InstructionListCategory.OFF_VARIATION,
  InstructionListCategory.VARIATIONS,
  InstructionListCategory.DEFAULT_VARIATION,
  InstructionListCategory.MEMBERS_AND_TEAMS,
] as const;

export function makeInstructionCategoryList(instructions: SemanticInstruction[]) {
  const uniqueCategories = new Set(instructions.map((ins) => InstructionKindToInstructionListCategory[ins.kind]));
  return instructionCategoryListOrder.filter((category) => uniqueCategories.has(category));
}

export const makeInstructionElemsByCategory = () =>
  Object.values(InstructionListCategory).reduce(
    (accum, category) => ({
      ...accum,
      [category]: [],
    }),
    {} as {
      [category in InstructionListCategory]: JSX.Element[];
    },
  );

export const makeInstructionElemsByRuleId = (instructions: SemanticInstruction[]) =>
  instructions.reduce(
    (accum, ins) => {
      if (filterOutAggregateRules(ins.kind)) {
        const ruleId = (ins as AggregateRule).ruleId;
        return { ...accum, [ruleId]: [] };
      }
      return accum;
    },
    {} as { [ruleId: string]: JSX.Element[] },
  );

/**
 * Maps through a list of semantic instruction
 * Pushes back instructions that have conflicts for the given conflict kind and undefined for those that do not
 * We are mapping instead of filtering these instructions in order to preserve the original instruction index
 * This index is used to craft the URL linking to individual conflict views
 */
export const filterInstructionsForConflict = ({
  instructions,
  instructionIndexToConflictsInfo,
  conflictKind,
}: {
  instructions: SemanticInstruction[];
  instructionIndexToConflictsInfo: InstructionIndexToConflictsInfo;
  conflictKind: FlagConflictKind;
}) =>
  instructions.map((instruction, instructionIndex) => {
    const hasConflictForKind = instructionIndexToConflictsInfo[instructionIndex]?.summaryConflictKind === conflictKind;
    return hasConflictForKind ? instruction : undefined;
  });
