import { UISegmentsListView } from './types';

export function serializeUISegmentsForBrowser(ui: UISegmentsListView) {
  const searchParams = new URLSearchParams();

  if (ui.sort) {
    searchParams.set('sort', ui.sort);
  }
  if (ui.query) {
    searchParams.set('query', ui.query);
  }
  if (ui.keys) {
    ui.keys.forEach((key) => searchParams.append('keys', key));
  }
  if (ui.tags) {
    const nextTags = new Set(ui.tags);
    Array.from(nextTags).forEach((tag) => searchParams.append('tags', tag));
  }
  if (ui.kind) {
    searchParams.set('kind', ui.kind);
  }
  return searchParams;
}
