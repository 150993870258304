import { isSeatLimitEnforced as isSeatLimitEnforcedFlag } from '@gonfalon/dogfood-flags';

import { PlanTypes } from '../../types';

export function isPlanUpgradeNeededToAddSeats(
  numOfNewSeatsToPurchase: number,
  planHasUnlimitedSeats: boolean,
  planSeatLimit: number,
  subscriptionSeatLimit: number,
  subscriptionPlanType: PlanTypes,
) {
  const isSeatLimitEnforced = isSeatLimitEnforcedFlag();

  if (!isSeatLimitEnforced || planHasUnlimitedSeats || numOfNewSeatsToPurchase <= 0) {
    return false;
  }

  // If they invited everyone in the form (in addition to people already on their team) they’d use this many seats:
  const numberOfTotalSeatsAfterPurchase = subscriptionSeatLimit + numOfNewSeatsToPurchase;

  // They have this many seats available before they need to upgrade to the next level:
  const seatsAvailableToPurchaseOnCurrentPlan = planSeatLimit - numberOfTotalSeatsAfterPurchase;

  return (
    [PlanTypes.STARTER2021, PlanTypes.PROFESSIONAL2021].includes(subscriptionPlanType) &&
    seatsAvailableToPurchaseOnCurrentPlan < 0
  );
}
