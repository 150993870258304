import { isFunction } from '@gonfalon/es6-utils';
import { AnyAction, Reducer } from 'redux';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = (...args: any[]) => any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const safeInvoke = (fn?: AnyFunction, ...args: any[]) => {
  if (isFunction(fn)) {
    return fn(...args);
  }
};
// safeInvoke checks for us if handlers is a valid
// function or not
export const chainEventHandlers =
  (...handlers: Array<AnyFunction | undefined>) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (event: any) => {
    handlers.forEach((h) => safeInvoke(h, event));
  };

/**
 * Reduce a state value given a reducer function and a sequence of actions.
 *
 * @param  {(state, action) => state} reducer
 * @param  {...{type, ...props}} actions
 * @return {Object}
 */
export function applyUpdates<S, A extends AnyAction>(reducer: Reducer<S, A>, ...actions: A[]) {
  return actions.reduce((prevState, action) => reducer(prevState, action), reducer(undefined, { type: null } as A));
}

/**
 * Similar to applyUpdates, but allows the caller to specify the initial state
 *
 * @param  {(state, action) => state} reducer
 * @param  {Object} initialState
 * @param  {...{type, ...props}} actions
 * @return {Object}
 */
export const applyUpdatesWithInitialState = (
  reducer: Reducer,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialState: Record<string, any>,
  ...actions: AnyAction[]
) => actions.reduce((prevState, action) => reducer(prevState, action), initialState);
