/**
 * Move the value from one index to another, and return a new copy
 * of the array.
 */
export function arrayMove<T>(array: T[], from: number, to: number) {
  const copy = array.slice();

  copy.splice(to < 0 ? copy.length + to : to, 0, copy.splice(from, 1)[0]);

  return copy;
}
/**
 * Move a set of values before a specific value, and return a new copy
 * of the array.
 */
export function arrayMoveBefore<T>(
  array: T[],
  values: Iterable<T>,
  other: T,
  { compare }: { compare: (t1: T, t2: T) => boolean } = { compare: (t1, t2) => t1 === t2 },
) {
  const to = array.findIndex((item) => compare(item, other));
  if (to === -1) {
    return array;
  }

  const indices = Array.from(values)
    .map((value) => array.findIndex((item) => compare(item, value)))
    .sort();

  return moveValues(array, indices, to);
}

/**
 * Move a set of values after a specific value, and return a new copy
 * of the array.
 */
export function arrayMoveAfter<T>(
  array: T[],
  values: Iterable<T>,
  other: T,
  { compare }: { compare: (t1: T, t2: T) => boolean } = { compare: (t1, t2) => t1 === t2 },
) {
  const to = array.findIndex((item) => compare(item, other));
  if (to === -1) {
    return array;
  }

  const indices = Array.from(values)
    .map((value) => array.findIndex((item) => compare(item, value)))
    .sort();

  return moveValues(array, indices, to + 1);
}

// From https://github.com/adobe/react-spectrum/blob/main/packages/%40react-stately/data/src/useListData.ts
function moveValues<T>(array: T[], indices: number[], to: number) {
  let anchor = to - indices.filter((index) => index < to).length;

  const moves = indices.map((index) => ({
    from: index,
    to: anchor++,
  }));

  // Shift later from indices down if they have a larger index
  for (let i = 0; i < moves.length; i++) {
    const a = moves[i].from;
    for (let j = i; j < moves.length; j++) {
      const b = moves[j].from;

      if (b > a) {
        moves[j].from--;
      }
    }
  }

  // Interleave the moves so they can be applied one by one rather than all at once
  for (let i = 0; i < moves.length; i++) {
    const a = moves[i];
    for (let j = moves.length - 1; j > i; j--) {
      const b = moves[j];

      if (b.from < a.to) {
        a.to++;
      } else {
        b.from++;
      }
    }
  }

  const copy = array.slice();

  for (const move of moves) {
    copy.splice(move.to, 0, copy.splice(move.from, 1)[0]);
  }

  return copy;
}
