import { ShortcutsModal, trackShortcutViewed, useRouteShortcutType } from '@gonfalon/shortcuts';
import { DialogTrigger, IconButton, Tooltip, TooltipTrigger } from '@launchpad-ui/components';

export function ShortcutsTrigger() {
  const routeShortcutType = useRouteShortcutType();

  if (!routeShortcutType) {
    return null;
  }

  let shortcutDescription: string;
  switch (routeShortcutType) {
    case 'flags':
      shortcutDescription = 'Save shortcut to these flags';
      break;
    case 'contexts':
      shortcutDescription = 'Save shortcut to these contexts';
      break;
    case 'segments':
      shortcutDescription = 'Save shortcut to these segments';
      break;
    case 'flag':
      shortcutDescription = 'Save shortcut to this flag';
      break;
    default:
      shortcutDescription = 'Save shortcut to this page';
  }

  return (
    <DialogTrigger>
      <TooltipTrigger>
        <IconButton
          onPress={() => trackShortcutViewed()}
          size="medium"
          variant="minimal"
          aria-label={shortcutDescription}
          icon="bolt"
        />
        <Tooltip>{shortcutDescription}</Tooltip>
      </TooltipTrigger>
      <ShortcutsModal shortcutType={routeShortcutType} />
    </DialogTrigger>
  );
}
