import { useContext } from 'react';
import invariant from 'tiny-invariant';

import { SidebarContext } from './SidebarContext';

export function useSidebarContext() {
  const state = useContext(SidebarContext);
  invariant(state, 'useSidebarContext must be used inside of a SidebarContext provider');
  return state;
}
