import { Day, Hour, Minute } from './durationUnits';
import type { MonitoringDuration } from './types';

export function monitoringDurationToMilliseconds(monitoringDuration: MonitoringDuration) {
  const { value, unit } = monitoringDuration;
  switch (unit) {
    case 'minutes':
      return value * Minute;
    case 'hours':
      return value * Hour;
    case 'days':
      return value * Day;
    default:
      throw new Error(`Unknown monitoring duration unit: ${unit}`);
  }
}
