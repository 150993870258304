import { Link } from 'react-router-dom';
import { capitalize } from '@gonfalon/strings';

import { ContextItemsEntities } from 'components/Contexts/types';
import {
  contextHasName,
  getContextDisplayName,
  getContextItemSiteLink,
  makeKindKeyStringFromProps,
} from 'components/Contexts/utils/contextTargetingUtils';
import { VARIATION_NOT_FOUND } from 'components/InstructionList/instructionListUtils';
import Variation from 'components/Variation';
import { colorVariation, Flag } from 'utils/flagUtils';
import { TargetsInstructionKind } from 'utils/instructions/targets/types';
import { UserTargetsInstructionKind } from 'utils/instructions/userTargets/types';

import './styles.css';

export const handleDisplayTargetKeys = ({
  contextItemsByKindKey,
  contextKind,
  limitedTargets,
  projKey,
  envKey,
}: {
  contextItemsByKindKey: ContextItemsEntities;
  contextKind: string;
  limitedTargets: string[];
  projKey: string;
  envKey: string;
}) => (
  <>
    {limitedTargets.length ? (
      limitedTargets.map((targetKey, i) => {
        const contextItem = contextItemsByKindKey[makeKindKeyStringFromProps(contextKind, targetKey)];
        const display =
          contextItem && contextHasName(contextItem.context) ? (
            <span>{getContextDisplayName(contextItem.context)}</span>
          ) : (
            <code>{targetKey}</code>
          );

        return (
          <span key={i}>
            {contextItem ? <Link to={getContextItemSiteLink(contextItem, projKey, envKey)}>{display}</Link> : display}
            {i < limitedTargets.length - 1 ? ', ' : ''}
          </span>
        );
      })
    ) : (
      <span>all users</span>
    )}
  </>
);

export type TargetEntryProps = {
  instructionKind:
    | TargetsInstructionKind.ADD_TARGETS
    | TargetsInstructionKind.REMOVE_TARGETS
    | TargetsInstructionKind.REPLACE_TARGETS
    | UserTargetsInstructionKind.ADD_USER_TARGETS
    | UserTargetsInstructionKind.REMOVE_USER_TARGETS
    | UserTargetsInstructionKind.REPLACE_USER_TARGETS;
  contextKind: string;
  targetKeys: string[];
  variationId: string;
  flag: Flag;
  contextItemsByKindKey: ContextItemsEntities;
  targetLimit: number;
  projKey: string;
  envKey: string;
};

export const TargetEntry = ({
  instructionKind,
  contextKind,
  targetKeys,
  variationId,
  flag,
  targetLimit,
  contextItemsByKindKey,
  projKey,
  envKey,
}: TargetEntryProps) => {
  let verb = '';
  let preposition = '';
  switch (instructionKind) {
    case TargetsInstructionKind.ADD_TARGETS:
      verb = 'Add';
      preposition = 'to';
      break;
    case TargetsInstructionKind.REMOVE_TARGETS:
      verb = 'Remove';
      preposition = 'from';
      break;
    case TargetsInstructionKind.REPLACE_TARGETS:
      preposition = 'to';
      if (targetKeys.length === 0) {
        verb = 'Remove';
        preposition = 'from';
      }
      break;
    default:
      break;
  }
  const variationIdx = flag.getVariationIndexFromId(variationId);
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  // We found the variation index by its ID so it's safe to assert it exists
  const variationDisplay = flag
    .getVariations()
    .get(variationIdx)!
    .getDisplay(); /* eslint-enable @typescript-eslint/no-non-null-assertion */
  const extraTargets = Math.max(targetKeys.length - targetLimit, 0);
  const extraTargetsText = extraTargets ? `, and ${extraTargets} more` : '';
  const limitedTargets = targetKeys.slice(0, targetLimit);
  const ariaLabel = `${verb} ${
    limitedTargets.length ? limitedTargets.join(', ') : `all ${contextKind} targets`
  }${extraTargetsText} ${preposition} variation ${variationDisplay}`;

  return (
    <span aria-label={ariaLabel}>
      {`${verb} `}
      {handleDisplayTargetKeys({ contextItemsByKindKey, contextKind, limitedTargets, projKey, envKey })}
      {extraTargetsText}
      {` ${preposition}`}
      {variationIdx > -1 ? (
        <Variation className="u-ml-s" color={colorVariation(variationIdx)} value={variationDisplay} />
      ) : (
        <span>{capitalize(VARIATION_NOT_FOUND)}</span>
      )}{' '}
      for <span>{contextKind}</span>
    </span>
  );
};
