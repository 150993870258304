import { type GetFeatureFlagsQueryParams } from '@gonfalon/openapi';

import { serializeUIFlagFilterForAPI } from './serializeUIFlagFilterForAPI';
import { type UIFlagListView } from './types';

export function serializeUIFlagListViewForAPI(ui: UIFlagListView) {
  const api: GetFeatureFlagsQueryParams = {};
  api.limit = ui.limit;
  api.offset = ui.offset;
  api.sort = ui.sort;
  api.compare = ui.compare;
  api.summary = ui.summary;

  const uiFilter = ui.filter;
  if (uiFilter !== undefined) {
    api.filter = serializeUIFlagFilterForAPI(uiFilter);
  }

  return api;
}
