import { contextAttributeValuesLimit } from '@gonfalon/dogfood-flags';
import {
  and,
  anyOf,
  contains,
  equals,
  exists,
  notEquals,
  or,
  parenthesized,
  QueryFilter,
  queryfilterToString,
  startsWith,
} from '@gonfalon/queryfilter';

import { updateFlagSemantically } from 'sources/FlagAPI';
import { USER_CONTEXT_KIND } from 'utils/constants';
import { FlagFilters } from 'utils/flagFilterUtils';
import http, { middleware } from 'utils/httpUtils';
import { SemanticInstructionsByFlagKeyType } from 'utils/userUtils';

import { ContextAttributesResponse, ContextTargetKindKey } from '../types';
import { LDContext } from '../types/LDContext';
import { augmentResolveContextsResponse, makeKindKeyStringFromKindKey } from '../utils/contextTargetingUtils';

export const fetchFlags = async (
  projectKey: string,
  environmentKey: string,
  pagination: string,
  flagFilters?: FlagFilters,
) => {
  const filters = pagination ? `${pagination}` : `${flagFilters?.toBackendQueryString()}`;
  return http
    .get(`/api/v2/flags/${projectKey}?env=${environmentKey}&${filters}`)
    .then(middleware.json)
    .catch(middleware.jsonError);
};

export const fetchFlagStatuses = async (projectKey: string, environmentKey: string) =>
  http.get(`/api/v2/flag-statuses/${projectKey}/${environmentKey}`).then(middleware.json).catch(middleware.jsonError);

export const fetchContextFlags = async (projectKey: string, environmentKey: string, context?: LDContext) =>
  http
    .post(`/api/v2/projects/${projectKey}/environments/${environmentKey}/flags/evaluate`, {
      body: JSON.stringify(context),
    })
    .then(middleware.json)
    .catch(middleware.jsonError);

export const fetchContextInstances = async (
  projectKey: string,
  environmentKey: string,
  id: string,
  kind: string,
  key: string,
  instanceSelected: boolean,
) => {
  // This url opens a single multi- context from the context instance list
  if (instanceSelected && id && id.length > 0) {
    const url = new URL(
      `/api/v2/projects/${projectKey}/environments/${environmentKey}/context-instances/${id}`,
      location.origin,
    );
    const searchParams = new URLSearchParams();
    searchParams.append('limit', '1');
    searchParams.append('includeTotalCount', 'true');
    url.search = searchParams.toString();
    return http.get(url.toString()).then(middleware.json).catch(middleware.jsonError);
  }

  const url = new URL(
    `/api/v2/projects/${projectKey}/environments/${environmentKey}/context-instances/search`,
    location.origin,
  );
  const searchParams = new URLSearchParams();
  searchParams.append('filter', encodeURIComponent(queryfilterToString(contains('kindkeys', [`${kind}:${key}`]))));
  searchParams.append('includeTotalCount', 'true');
  searchParams.append('sort', '-ts');
  url.search = searchParams.toString();

  return http.post(url.toString()).then(middleware.json).catch(middleware.jsonError);
};

export const fetchContexts = async (
  projectKey: string,
  environmentKey: string,
  kind: string,
  key: string,
  id?: string,
  applicationId?: string,
) => {
  const url = new URL(
    `/api/v2/projects/${projectKey}/environments/${environmentKey}/contexts/${kind}/${key}`,
    location.origin,
  );
  const matches: QueryFilter[] = [];
  if (id) {
    matches.push(equals('id', id));
  }

  if (applicationId) {
    matches.push(notEquals('applicationid', applicationId));
  }

  const searchParams = new URLSearchParams();
  searchParams.append('includeTotalCount', 'true');
  if (matches.length > 0) {
    searchParams.append('filter', encodeURIComponent(queryfilterToString(and(matches))));
  }
  url.search = searchParams.toString();

  return http.get(url.toString()).then(middleware.json).catch(middleware.jsonError);
};

export const deleteSingleContext = async (projectKey: string, environmentKey: string, id: string) =>
  http
    .delete(
      `/api/v2/projects/${projectKey}/environments/${environmentKey}/context-instances/${id}?includeTotalCount=true`,
    )
    .catch(middleware.jsonError);

export const patchFlags = async (
  projectKey: string,
  semanticInstructionsByFlagKey?: SemanticInstructionsByFlagKeyType,
  envKey?: string,
  comment?: string,
) => {
  if (!semanticInstructionsByFlagKey) {
    return;
  }
  const updates = Object.values(semanticInstructionsByFlagKey).map(async (el) => {
    const { instructions, flag } = el;
    return updateFlagSemantically(projectKey, flag, envKey || '', instructions, { comment });
  });

  return Promise.all(updates);
};

export const fetchContextList = async (projectKey: string, environmentKey: string, kind: string, prefix: string) => {
  const url = new URL(`/api/v2/projects/${projectKey}/environments/${environmentKey}/contexts/search`, location.origin);
  const searchParams = new URLSearchParams();
  const matches: QueryFilter[] = [];
  matches.push(equals('kind', kind));

  if (prefix) {
    let orList = [startsWith('*./name', prefix), startsWith('key', prefix)];
    if (kind === USER_CONTEXT_KIND) {
      orList = [
        ...orList,
        startsWith('user.lastName', prefix),
        startsWith('user.firstName', prefix),
        startsWith('user.email', prefix),
      ];
    }
    matches.push(parenthesized(or(orList)));
  }

  searchParams.append('filter', encodeURIComponent(queryfilterToString(and(matches))));
  searchParams.append('limit', '20');
  searchParams.append('sort', '-ts');
  url.search = searchParams.toString();

  return http.post(url.toString()).then(middleware.json).catch(middleware.jsonError);
};

export const fetchContextAttributes = async (projectKey: string, environmentKey: string, prefix?: string) => {
  const url = new URL(
    `/api/v2/projects/${projectKey}/environments/${environmentKey}/context-attributes`,
    location.origin,
  );
  const searchParams = new URLSearchParams();
  if (prefix) {
    searchParams.append('filter', encodeURIComponent(queryfilterToString(startsWith('name', prefix))));
  }
  searchParams.append('includeTotalCount', 'true');
  url.search = searchParams.toString();

  return http.get(url.toString()).then(middleware.json).catch(middleware.jsonError);
};

export const fetchContextAttributesByKind = async (
  projectKey: string,
  environmentKey: string,
  kind: string,
  prefix?: string,
): Promise<ContextAttributesResponse> => {
  const url = new URL(
    `/api/v2/projects/${projectKey}/environments/${environmentKey}/context-attributes`,
    location.origin,
  );
  const searchParams = new URLSearchParams();
  const matches: QueryFilter[] = [];
  if (kind) {
    matches.push(equals('kind', kind));
  }
  if (prefix) {
    matches.push(startsWith('name', prefix));
  }
  searchParams.append('filter', encodeURIComponent(queryfilterToString(and(matches))));
  url.search = searchParams.toString();

  return http.get(url.toString()).then(middleware.json).catch(middleware.jsonError);
};

export const fetchContextAttributeValues = async (
  projectKey: string,
  environmentKey: string,
  kind: string,
  attributeKey: string,
  prefix?: string,
) => {
  const url = new URL(
    `/api/v2/projects/${projectKey}/environments/${environmentKey}/context-attributes/${encodeURIComponent(
      attributeKey,
    )}`,
    location.origin,
  );
  const searchParams = new URLSearchParams();
  const matches: QueryFilter[] = [];
  matches.push(equals('kind', kind));
  if (prefix) {
    matches.push(startsWith('value', prefix));
  }
  searchParams.append('filter', encodeURIComponent(queryfilterToString(and(matches))));
  searchParams.append('limit', contextAttributeValuesLimit().toString());
  url.search = searchParams.toString();
  return http.get(url.toString()).then(middleware.json).catch(middleware.jsonError);
};

export const resolveContexts = async (
  projectKey: string,
  environmentKey: string,
  contextTargetKindKeysOrEmails: ContextTargetKindKey[],
  sort?: string,
  searchEmails: boolean = false,
) => {
  const url = new URL(`/api/v2/projects/${projectKey}/environments/${environmentKey}/contexts/search`, location.origin);
  const matches: QueryFilter[] = [];
  const searchParams = new URLSearchParams();
  if (!contextTargetKindKeysOrEmails.length) {
    return Promise.resolve([]);
  }

  const kindKeyFilter = anyOf('kindkey', contextTargetKindKeysOrEmails.map(makeKindKeyStringFromKindKey));

  const hasAllUserKindKeys = contextTargetKindKeysOrEmails.every((el) => el.kind === USER_CONTEXT_KIND);
  if (searchEmails && hasAllUserKindKeys) {
    const emailFilters = contextTargetKindKeysOrEmails.map((el) => equals('user.email', el.key));
    matches.push(or([kindKeyFilter, ...emailFilters]));
  } else {
    matches.push(kindKeyFilter);
  }

  const filter = queryfilterToString(and(matches));
  searchParams.append('filter', encodeURIComponent(filter));
  searchParams.append('limit', contextTargetKindKeysOrEmails.length.toString());
  searchParams.append('sort', sort || '-ts');
  url.search = searchParams.toString();

  return http
    .post(url.toString())
    .then(middleware.json)
    .then((data) => augmentResolveContextsResponse(data))
    .catch(middleware.jsonError);
};

export const fetchContextKinds = async (projectKey: string) =>
  http
    .get(`/api/v2/projects/${projectKey}/context-kinds`, {
      beta: true,
    })
    .then(middleware.json)
    .catch(middleware.jsonError);

export type ContextKindRequest = {
  description: string;
  hideInTargeting?: boolean;
  name: string;
  version?: number;
};

export const updateContextKindKey = async (
  projectKey: string,
  contextKindKey: string,
  contextKindRequest: ContextKindRequest,
) =>
  http.put(`/api/v2/projects/${projectKey}/context-kinds/${contextKindKey}`, {
    body: JSON.stringify(contextKindRequest),
    beta: true,
  });

export const fetchContextsWithNames = async (
  projectKey: string,
  environmentKey: string,
  missingNamesKindKeys: string[],
) => {
  const url = new URL(`/api/v2/projects/${projectKey}/environments/${environmentKey}/contexts/search`, location.origin);
  const matches: QueryFilter[] = [];
  matches.push(anyOf('kindkey', missingNamesKindKeys));
  matches.push(exists('*.name', true));

  const filter = queryfilterToString(and(matches));

  const searchParams = new URLSearchParams();
  searchParams.set('includeTotalCount', String(true));
  url.search = searchParams.toString();

  return http
    .post(url.toString(), {
      body: {
        filter,
        sort: '-ts',
      },
    })
    .then(middleware.json)
    .catch(middleware.jsonError);
};
