import { ChangeEvent, FocusEvent, FormEvent, useEffect } from 'react';
import { Label, RequiredAsterisk, TextArea, TextField } from 'launchpad';

import { editSeatRequestField, requestEnterpriseSeats } from 'actions/account';
import { useFormActions } from 'actions/forms';
import { SubmitButton } from 'components/ui/buttons';
import { FieldError, Form, FormGroup } from 'components/ui/forms';
import { useDispatch } from 'hooks/useDispatch';
import { useSelector } from 'hooks/useSelector';
import { createRequestSeatFormSelector } from 'reducers/account';
import { profileSelector } from 'reducers/profile';
import { RequestSeatsFormType } from 'utils/accountUtils';
import { FormContext } from 'utils/formUtils';

import styles from './styles/RequestSeatsFormContainer.module.css';

const useRedux = () => {
  const formState = useSelector(createRequestSeatFormSelector);
  const profile = useSelector(profileSelector).get('entity');
  const { onInitializeForm, onDestroyForm, onBlur } = useFormActions('createRequestSeatsForm');
  const dispatch = useDispatch();
  const onChange = (field: keyof RequestSeatsFormType, value: string | number) =>
    dispatch(editSeatRequestField(field, value));
  const modified = formState.modified;

  const handleSubmit = async (event: FormEvent<EventTarget>) => {
    event.preventDefault();
    await dispatch(requestEnterpriseSeats(modified));
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    onBlur(event.target.name);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(event.target.name as keyof RequestSeatsFormType, event.target.value);
  };

  return {
    initialize: onInitializeForm,
    destroyForm: onDestroyForm,
    handleBlur,
    formState,
    handleChange,
    modified,
    profile,
    handleSubmit,
  };
};

export function RequestSeatsFormContainer() {
  const { initialize, destroyForm, handleChange, handleBlur, formState, modified, profile, handleSubmit } = useRedux();

  useEffect(() => {
    initialize({
      memberFirstName: profile.firstName,
      memberLastName: profile.lastName,
      memberEmail: profile.email,
      numberOfSeats: 10,
      description: '',
    });

    return () => {
      destroyForm();
    };
  }, []);

  return (
    <FormContext.Provider value={{ formState }}>
      <Form onSubmit={handleSubmit}>
        <div className={styles.FormGroupRow}>
          <FormGroup name="firstName">
            <Label htmlFor="firstName">
              First name <RequiredAsterisk />
            </Label>
            <TextField
              id="firstName"
              name="firstName"
              onBlur={handleBlur}
              value={modified.memberFirstName}
              required
              onChange={handleChange}
            />
            <FieldError name="firstName" />
          </FormGroup>
          <FormGroup name="lastName">
            <Label htmlFor="lastName">
              Last name <RequiredAsterisk />
            </Label>
            <TextField
              id="lastName"
              name="lastName"
              onBlur={handleBlur}
              value={modified.memberLastName}
              required
              onChange={handleChange}
            />
            <FieldError name="lastName" />
          </FormGroup>
        </div>
        <FormGroup name="memberEmail">
          <Label htmlFor="memberEmail">
            Email <RequiredAsterisk />
          </Label>
          <TextField
            id="memberEmail"
            name="memberEmail"
            onBlur={handleBlur}
            value={modified.memberEmail}
            required
            onChange={handleChange}
          />
          <FieldError name="memberEmail" />
        </FormGroup>
        <FormGroup name="numberOfSeats">
          <Label htmlFor="numberOfSeats">
            Number of seats <RequiredAsterisk />
          </Label>
          <TextField
            id="numberOfSeats"
            min={1}
            type="number"
            required
            name="numberOfSeats"
            onBlur={handleBlur}
            value={modified.numberOfSeats}
            onChange={handleChange}
          />
          <FieldError name="numberOfSeats" />
        </FormGroup>
        <FormGroup name="description">
          <Label htmlFor="description">
            Description <RequiredAsterisk />
          </Label>
          <TextArea id="description" name="description" rows={3} value={modified.description} onChange={handleChange} />
        </FormGroup>
        <FormGroup className={styles.submitButtonContainer}>
          <SubmitButton className={styles.submitButton} loadingText="Submitting request">
            Submit
          </SubmitButton>
        </FormGroup>
      </Form>
    </FormContext.Provider>
  );
}

/* eslint-disable import/no-default-export */
export default RequestSeatsFormContainer;
