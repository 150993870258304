import { List } from 'immutable';

import { getFallthroughRollout } from 'actions/pendingChanges';
import { Clause as ImmutableClause, createClause } from 'utils/clauseUtils';
import {
  createRollout,
  Flag,
  idOrKey,
  Rollout,
  Rule,
  Rule as RuleRecord,
  Variation,
  WeightedVariation,
} from 'utils/flagUtils';
import { toJS } from 'utils/immutableUtils';
import { ClauseInstructionKind } from 'utils/instructions/clauses/types';
import { SemanticInstruction } from 'utils/instructions/shared/types';

import {
  AddRuleSemanticInstruction,
  AddRuleWithProgressiveRolloutSemanticInstruction,
  AggregateRule,
  ReorderRulesSemanticInstruction,
  RuleInstructionKind,
} from './types';

export const convertRuleToAggregateRule = (rule: RuleRecord, variations: List<Variation>): AggregateRule => {
  const clauses = rule.clauses.map((clause: ImmutableClause) => createClause(clause));
  const aggregateRule = createAggregateRule({ ...rule.toJS(), ruleId: rule._id, clauses: clauses.toJS() });
  if (rule.rollout) {
    const rolloutBucketBy = rule.rollout?.bucketBy;
    const rolloutWeights = getFallthroughRollout(createRollout(rule.rollout), variations).weights;
    const experimentAllocation = (rule.rollout as unknown as Rollout).experimentAllocation;
    const rolloutContextKind = rule.rollout?.contextKind;
    return {
      ...aggregateRule,
      rolloutBucketBy,
      rolloutContextKind,
      rolloutWeights,
      experimentAllocation,
    };
  }

  if (rule.variation !== undefined) {
    //rule.variation is an index of the list of variations
    return {
      ...aggregateRule,
      variationId: variations.get(rule.variation)?._id,
    };
  }
  return aggregateRule;
};

export const convertAggregateRuleToRule = (aggregateRule: AggregateRule, variations: List<Variation>) => {
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  let rule = new RuleRecord({
    _key: aggregateRule._key,
    _id: aggregateRule.ruleId,
    trackEvents: aggregateRule.trackEvents,
    description: aggregateRule.description,
    clauses: List(aggregateRule.clauses.filter((c): c is ImmutableClause => c !== null).map(createClause)),
  }); /* eslint-enable @typescript-eslint/no-non-null-assertion */
  if (aggregateRule.variationId) {
    const variation = variations.findIndex((v) => v._id === aggregateRule.variationId);
    rule = rule.set('variation', variation);
  }
  if (aggregateRule.rolloutWeights) {
    const weightedVariations = List(
      Object.entries(aggregateRule.rolloutWeights).map(([variationId, value]) => {
        const variationIndex = variations.findIndex((v) => v._id === variationId);
        return new WeightedVariation({ variation: variationIndex, weight: value });
      }),
    );
    const rollout = createRollout({
      variations: weightedVariations,
      bucketBy: aggregateRule.rolloutBucketBy,
      contextKind: aggregateRule.rolloutContextKind,
      experimentAllocation: aggregateRule.experimentAllocation,
    });
    rule = rule.set('rollout', rollout);
  }

  return rule;
};

export const createAggregateRule = (props: Partial<AggregateRule> = {}): AggregateRule => {
  const jsProps = toJS(props);
  return {
    _key: '',
    _id: '',
    clause: undefined,
    clauseId: '',
    trackEvents: false,
    ruleId: '',
    variationId: '',
    rolloutWeights: undefined,
    rolloutBucketBy: undefined,
    rolloutContextKind: undefined,
    description: undefined,
    kind: '',
    experimentAllocation: undefined,
    ...jsProps,
    clauses: (jsProps.clauses?.filter((c): c is ImmutableClause => c !== null) ?? []).map(createClause),
  };
};

export const filterOutAggregateRules = (instructionKind: string) =>
  instructionKind === ClauseInstructionKind.ADD_CLAUSES ||
  instructionKind === RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT ||
  instructionKind === RuleInstructionKind.STOP_MEASURED_ROLLOUT_ON_RULE ||
  instructionKind === ClauseInstructionKind.REMOVE_CLAUSES ||
  instructionKind === ClauseInstructionKind.UPDATE_CLAUSE ||
  instructionKind === RuleInstructionKind.UPDATE_RULE_DESCRIPTION ||
  instructionKind === ClauseInstructionKind.ADD_VALUES_TO_CLAUSE ||
  instructionKind === ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE;

export const sortRulesByTargetingOrder = ({
  flag,
  envKey,
  instructions,
}: {
  flag: Flag;
  envKey: string;
  instructions: SemanticInstruction[];
}): Array<AggregateRule | undefined> => {
  const sortedRules: Array<AggregateRule | undefined> = [];
  let reorderRulesInstruction: ReorderRulesSemanticInstruction | undefined;
  const addRuleIns: Array<AddRuleSemanticInstruction | AddRuleWithProgressiveRolloutSemanticInstruction> = [];
  // Since the user was able to re-order rules, so we must have a list of rules for this environment
  const existingRules = flag.getRules(envKey) || List<Rule>();

  instructions.forEach((ins) => {
    if (ins.kind === RuleInstructionKind.REORDER_RULES) {
      reorderRulesInstruction = ins;
    }
    if (ins.kind === RuleInstructionKind.ADD_RULE) {
      addRuleIns.push(ins);
    }
  });

  if (!reorderRulesInstruction) {
    return existingRules.map((r: Rule) => convertRuleToAggregateRule(r, flag.variations)).toArray();
  }

  reorderRulesInstruction.ruleIds.forEach((ruleId) => {
    let rule: AggregateRule | undefined;
    existingRules.forEach((existingRule: Rule) => {
      if (ruleId === idOrKey(existingRule)) {
        rule = convertRuleToAggregateRule(existingRule, flag.variations);
      }
    });
    sortedRules.push(rule);
  });

  addRuleIns.forEach((ins) => {
    const { beforeRuleId } = ins;
    if (!beforeRuleId) {
      sortedRules.push(toJS(ins) as AggregateRule);
      return;
    }
    const beforeRuleIdx = sortedRules.findIndex((rule) => rule && beforeRuleId === idOrKey(rule));
    beforeRuleIdx > -1
      ? sortedRules.splice(beforeRuleIdx, 0, toJS(ins) as AggregateRule)
      : sortedRules.push(toJS(ins) as AggregateRule);
  });
  return sortedRules;
};
