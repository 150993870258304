import { ContextAttributesName, ContextAttributesNames, ContextAttributesResponse } from '../types';
import { LDContext } from '../types/LDContext';

/**
 * Converts a literal to a ref string.
 * @param value
 * @returns An escaped literal which can be used as a ref.
 */
function toRefString(value: string): string {
  return `/${value.replace(/~/g, '~0').replace(/\//g, '~1')}`;
}

/**
 * Produce a literal from a ref component.
 * @param ref
 * @returns A literal version of the ref.
 */
function unescape(ref: string): string {
  return ref.indexOf('~') ? ref.replace(/~1/g, '/').replace(/~0/g, '~') : ref;
}

function getComponents(reference: string): string[] {
  const referenceWithoutPrefix = reference.startsWith('/') ? reference.substring(1) : reference;
  return referenceWithoutPrefix.split('/').map((component) => unescape(component));
}

function isLiteral(reference: string): boolean {
  return !reference.startsWith('/');
}

function validate(reference: string): boolean {
  return !reference.match(/\/\/|(^\/.*~[^0|^1])|~$/);
}

export function processContextAttributes(contextAttributes: ContextAttributesResponse) {
  contextAttributes.items.forEach((contextAttributeItem) => {
    // eslint-disable-next-line no-param-reassign
    contextAttributeItem.names = processContextAttributesNames(contextAttributeItem.names);
  });
}

export function processContextAttributesNames(names: ContextAttributesName[]): ContextAttributesName[] {
  return names
    .map((name) => {
      const ar = new AttributeReference(name.name);
      return {
        ...name,
        name: ar.toString(),
      };
    })
    .filter((name) => name !== null);
}

/* eslint-disable import/no-default-export */
export default class AttributeReference {
  public readonly isValid;

  /**
   * When redacting attributes this name can be directly added to the list of
   * redactions.
   */
  public readonly redactionName;

  /**
   * For use as invalid references when deserializing Flag/Segment data.
   */
  public static readonly invalidReference = new AttributeReference('');

  private readonly components: string[];

  /**
   * Take an attribute reference string, or literal string, and produce
   * an attribute reference.
   *
   * Legacy user objects would have been created with names not
   * references. So, in that case, we need to use them as a component
   * without escaping them.
   *
   * e.g. A user could contain a custom attribute of `/a` which would
   * become the literal `a` if treated as a reference. Which would cause
   * it to no longer be redacted.
   * @param refOrLiteral The attribute reference string or literal string.
   * @param literal it true the value should be treated as a literal.
   */
  public constructor(refOrLiteral: string, literal: boolean = false) {
    if (!literal) {
      this.redactionName = refOrLiteral;
      if (refOrLiteral === '' || refOrLiteral === '/' || !validate(refOrLiteral)) {
        this.isValid = false;
        this.components = [];
        return;
      }

      if (isLiteral(refOrLiteral)) {
        this.components = [refOrLiteral];
      } else if (refOrLiteral.indexOf('/', 1) < 0) {
        this.components = [unescape(refOrLiteral.slice(1))];
      } else {
        this.components = getComponents(refOrLiteral);
      }
      // The items inside of '_meta' are not intended to be addressable.
      // Excluding it as a valid reference means that we can make it non-addressable
      // without having to copy all the attributes out of the context object
      // provided by the user.
      if (this.components[0] === '_meta') {
        this.isValid = false;
      } else {
        this.isValid = true;
      }
    } else {
      const literalVal = refOrLiteral;
      this.components = [literalVal];
      this.isValid = literalVal !== '';
      // Literals which start with '/' need escaped to prevent ambiguity.
      this.redactionName = literalVal.startsWith('/') ? toRefString(literalVal) : literalVal;
    }
  }

  public toString(): string {
    if (this.depth > 1) {
      return `/${this.components.join('/')}`;
    } else {
      return this.getComponent(0);
    }
  }
  public get(target: LDContext) {
    const { components, isValid } = this;
    if (!isValid) {
      return undefined;
    }

    let current = target;

    // This doesn't use a range based for loops, because those use generators.
    // See `no-restricted-syntax`.
    // It also doesn't use a collection method because this logic is more
    // straightforward with a loop.
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let index = 0; index < components.length; index += 1) {
      const component = components[index];
      if (
        current !== null &&
        current !== undefined &&
        // See https://eslint.org/docs/rules/no-prototype-builtins
        Object.prototype.hasOwnProperty.call(current, component) &&
        typeof current === 'object'
      ) {
        current = current[component];
      } else {
        return undefined;
      }
    }
    return current;
  }

  public getComponent(depth: number) {
    return this.components[depth];
  }

  public get depth() {
    return this.components.length;
  }

  public get isKind(): boolean {
    return this.components.length === 1 && this.components[0] === 'kind';
  }

  public compare(other: AttributeReference) {
    return this.depth === other.depth && this.components.every((value, index) => value === other.getComponent(index));
  }
}
// method for mergeContextAttributes
export function mergeContextAttributes(
  attributesList: ContextAttributesNames[],
  contextAttributes: ContextAttributesNames[],
) {
  let mergedAttributesList: ContextAttributesNames[] = [];
  if (contextAttributes.length > 0) {
    // if contextAttributes is not empty, merge attributesList and contextAttributes
    mergedAttributesList = attributesList.map((item) => {
      const contextAttribute = contextAttributes.find((attr) => attr.kind === item.kind);
      if (contextAttribute) {
        const names = contextAttribute.names.concat(item.names);
        const uniqueNames = names.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);
        return {
          kind: item.kind,
          names: uniqueNames,
        };
      } else {
        return item;
      }
    });
  } else {
    // if contextAttributes is empty, return attributesList
    mergedAttributesList = attributesList;
  }
  return mergedAttributesList;
}
