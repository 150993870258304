import { typedObjectKeys } from '@gonfalon/types';
import { closestTo, startOfDay, subMonths, subWeeks, subYears } from 'date-fns';

import { type CreationDate } from './types';

export function creationDateToReferenceDate(timestamp: number, referenceTimestamp: number) {
  const referenceDate = startOfDay(referenceTimestamp);
  const optionsToDate: Record<CreationDate, number> = {
    '1-week-ago': subWeeks(referenceDate, 1).getTime(),
    '1-month-ago': subMonths(referenceDate, 1).getTime(),
    '2-months-ago': subMonths(referenceDate, 2).getTime(),
    '3-months-ago': subMonths(referenceDate, 3).getTime(),
    '6-months-ago': subMonths(referenceDate, 6).getTime(),
    '1-year-ago': subYears(referenceDate, 1).getTime(),
  };

  const closestDateToEvalDate = closestTo(timestamp, Object.values(optionsToDate))?.getTime();
  return typedObjectKeys(optionsToDate).find((key) => optionsToDate[key] === closestDateToEvalDate);
}
