import type { ButtonProps } from 'launchpad';
import { Button } from 'launchpad';

import { Account } from 'utils/accountUtils';
import { getComponentAnalytics } from 'utils/analyticsUtils';
import { trackContactSalesLinkClicked } from 'utils/contactSalesUtils';

export type ContactUsButtonProps = {
  onOpenModal: () => void;
  account?: Account;
  featureKind?: string;
  isEnterpriseOnlyFeature: boolean;
  isAddOnFeature: boolean;
  componentForAnalyticTracking: string;
  contactSales?: boolean;
  className?: string;
  isLink?: boolean;
  isTrialBanner?: boolean;
  cta?: string;
  buttonKind?: ButtonProps['kind'];
  buttonSize?: ButtonProps['size'];
};

const ContactUsButton = ({
  onOpenModal,
  componentForAnalyticTracking,
  contactSales,
  className,
  isLink,
  isTrialBanner,
  cta = 'Contact us',
  buttonSize,
  buttonKind = 'primary',
}: ContactUsButtonProps) => {
  const componentType: 'button' | 'link' = isLink ? 'link' : 'button';

  const handleTracking = () =>
    trackContactSalesLinkClicked(
      getComponentAnalytics({
        component: componentForAnalyticTracking,
        type: componentType,
        cta,
      }),
    );

  const handleContactUs = () => {
    onOpenModal();
    handleTracking();
  };
  return (
    <>
      {isLink ? (
        <Button kind="link" onClick={handleContactUs} asChild>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a tabIndex={0} role="button">
            {isTrialBanner ? 'get in touch.' : cta}
          </a>
        </Button>
      ) : (
        <Button kind={buttonKind} size={buttonSize} className={className} onClick={handleContactUs}>
          {contactSales ? 'Contact sales' : cta}
        </Button>
      )}
    </>
  );
};

/* eslint-disable import/no-default-export */
export default ContactUsButton;
