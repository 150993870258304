import { constants } from 'utils/reduxUtils';

/* eslint-disable import/no-default-export */
export default constants('sso', [
  'GET_SAML_CONFIG',
  'GET_SAML_CONFIG_DONE',
  'GET_SAML_CONFIG_FAILED',
  'GET_SAML_APP_DETAILS',
  'GET_SAML_APP_DETAILS_DONE',
  'GET_SAML_APP_DETAILS_FAILED',
  'CREATE_SAML_CONFIG',
  'CREATE_SAML_CONFIG_DONE',
  'CREATE_SAML_CONFIG_FAILED',
  'UPDATE_SAML_CONFIG',
  'UPDATE_SAML_CONFIG_DONE',
  'UPDATE_SAML_CONFIG_FAILED',
  'DELETE_SAML_CONFIG',
  'DELETE_SAML_CONFIG_DONE',
  'DELETE_SAML_CONFIG_FAILED',
  'SET_IS_MANAGING_TEAMS',
  'SET_IS_MANAGING_TEAMS_DONE',
  'SET_IS_MANAGING_TEAMS_FAILED',
]);
