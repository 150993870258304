import { useLocation, useNavigate } from 'react-router-dom';
import { bulkAddMembersSuccessOrErrorMessage } from '@gonfalon/members';
import { toMembers } from '@gonfalon/navigator';
import { GetMemberQueryParams } from '@gonfalon/openapi';
import { List, Map, OrderedMap, OrderedSet, Set } from 'immutable';

import { useDispatch } from 'hooks/useDispatch';
import { GetState, GlobalDispatch, GlobalState } from 'reducers';
import { membersListKey, membersPaginationByKeySelector } from 'reducers/members';
import {
  addMemberToTeams as addMemberToTeamsAPI,
  approveUnverifiedMemberRequest as approveUnverifiedMemberRequestAPI,
  bulkAddAllMembersToTeams as bulkAddAllMembersToTeamsAPI,
  bulkAddMembersToTeams as bulkAddMembersToTeamsAPI,
  bulkReplaceAllMembersRoles as bulkReplaceAllMembersRolesAPI,
  bulkReplaceMembersRoles as bulkReplaceMembersRolesAPI,
  deleteMember as deleteMemberAPI,
  getMember as getMemberAPI,
  getMembers as getMembersAPI,
  requestTeamInvite as requestTeamInviteAPI,
  resendInvite as resendInviteAPI,
  sendMFAEnableRequest as sendMFAEnableRequestAPI,
  sendMFARecoveryCode as sendMFARecoveryCodeAPI,
  suggestInviteMembers as suggestInviteMembersAPI,
  updateMember as updateMemberAPI,
} from 'sources/AccountAPI';
import {
  BulkMemberInvite,
  Member,
  NewJoinTeamRequest,
  NormalizedMembersCollectionResponse,
  SuggestInviteMembers,
} from 'utils/accountUtils';
import { Subscription } from 'utils/billingUtils';
import { FormRecord, FormState } from 'utils/formUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import Logger from 'utils/logUtils';
import { bulkReplaceMembersRolesSuccessOrErrorMessage, MemberFilters } from 'utils/memberUtils';
import { PaginationType } from 'utils/paginationUtils';
import { GenerateActionType } from 'utils/reduxUtils';
import { RoleName } from 'utils/roleUtils';
const logger = Logger.get('MemberActions');

function shouldFetchMembers(state: GlobalState, filter?: MemberFilters) {
  const key = membersListKey(filter);
  const pagination = membersPaginationByKeySelector(key)(state) || Map();
  const isFetching = (pagination as PaginationType).get('isFetching');
  const error = (pagination as PaginationType).get('error');
  const lastFetched = (pagination as PaginationType).get('lastFetched');

  return !isFetching && !error && !lastFetched;
}

function fetchMembersIfNeeded(filter?: MemberFilters) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchMembers(getState(), filter)) {
      return dispatch(fetchMembers(filter));
    }
  };
}

const requestMembers = (filter?: MemberFilters) => ({ type: 'members/REQUEST_MEMBERS', filter }) as const;
const requestMembersDone = (response: NormalizedMembersCollectionResponse, filter?: MemberFilters) =>
  ({ type: 'members/RECEIVE_MEMBERS', response, filter }) as const;
const requestMembersFailed = (error: ImmutableServerError) =>
  ({ type: 'members/REQUEST_MEMBERS_FAILED', error }) as const;

function fetchMembers(filter?: MemberFilters) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestMembers(filter));
    return getMembersAPI(filter)
      .then((response) => dispatch(requestMembersDone(response, filter)))
      .catch((error) => dispatch(requestMembersFailed(error)));
  };
}

function fetchMembersByTeamIfNeeded(filter: MemberFilters) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchMembers(getState(), filter)) {
      return dispatch(fetchMembersByTeam(filter));
    }
  };
}

const requestMembersByTeam = (filter: MemberFilters) => ({ type: 'members/REQUEST_MEMBERS_BY_TEAM', filter }) as const;
const requestMembersByTeamDone = (response: OrderedMap<string, Member>, filter: MemberFilters) =>
  ({ type: 'members/RECEIVE_MEMBERS_BY_TEAM', response, filter }) as const;
const requestMembersByTeamFailed = (error: ImmutableServerError) =>
  ({ type: 'members/REQUEST_MEMBERS_BY_TEAM_FAILED', error }) as const;

function fetchMembersByTeam(filter: MemberFilters) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestMembersByTeam(filter));
    try {
      const response = await getMembersAPI(filter);
      return dispatch(requestMembersByTeamDone(response, filter));
    } catch (error) {
      dispatch(requestMembersByTeamFailed(error as ImmutableServerError));
    }
  };
}

const requestMember = (memberId: string) => ({ type: 'members/REQUEST_MEMBER', memberId }) as const;
const requestMemberDone = (member: Member) => ({ type: 'members/RECEIVE_MEMBER', member }) as const;
const requestMemberFailed = (error: ImmutableServerError) =>
  ({ type: 'members/REQUEST_MEMBER_FAILED', error }) as const;

function fetchMember(memberId: string, params?: GetMemberQueryParams) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestMember(memberId));
    return getMemberAPI(memberId, { params })
      .then((member) => dispatch(requestMemberDone(member)))
      .catch((error) => dispatch(requestMemberFailed(error)));
  };
}

const bulkReplaceMembersRolesAction = (memberIds: string[], customRoleKeys: string[], role: RoleName) =>
  ({ type: 'members/BULK_REPLACE_MEMBERS_ROLES', memberIds, customRoleKeys, role }) as const;
const bulkReplaceMembersRolesDoneAction = (message: string, memberIDs: string[], role: RoleName) =>
  ({ type: 'members/BULK_REPLACE_MEMBERS_ROLES_DONE', successMessage: message, memberIDs, role }) as const;
const bulkReplaceMembersRolesDoneWithErrors = (message: string) =>
  ({ type: 'members/BULK_REPLACE_MEMBERS_ROLES_DONE', errorsMessage: message }) as const;

function bulkReplaceMembersRoles(memberIds: string[], customRoleKeys: string[], role: RoleName, filter: MemberFilters) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(bulkReplaceMembersRolesAction(memberIds, customRoleKeys, role));
    return bulkReplaceMembersRolesAPI(memberIds, customRoleKeys, role, filter).then((response) => {
      const { members, errors, warnings } = response;
      const { successMessage, errorsMessage } = bulkReplaceMembersRolesSuccessOrErrorMessage({
        memberCount: members?.length,
        customRoleCount: customRoleKeys?.length,
        role,
        warningsCount: warnings?.length,
        errorsCount: errors?.length,
      });
      if (successMessage) {
        dispatch(bulkReplaceMembersRolesDoneAction(successMessage, members, role));
      }
      if (errorsMessage) {
        dispatch(bulkReplaceMembersRolesDoneWithErrors(errorsMessage));
      }
    });
  };
}

const bulkReplaceAllMembersRolesAction = (
  ignoredMemberIDs: string[],
  customRoleKeys: string[],
  role: RoleName,
  filters: MemberFilters,
) => ({ type: 'members/BULK_REPLACE_ALL_MEMBERS_ROLES', ignoredMemberIDs, customRoleKeys, role, filters }) as const;

function bulkReplaceAllMembersRoles(
  ignoredMemberIDs: string[],
  customRoleKeys: string[],
  role: RoleName,
  filters: MemberFilters,
) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(bulkReplaceAllMembersRolesAction(ignoredMemberIDs, customRoleKeys, role, filters));
    return bulkReplaceAllMembersRolesAPI(ignoredMemberIDs, customRoleKeys, role, filters).then((response) => {
      const { members, errors, warnings } = response;
      const { successMessage, errorsMessage } = bulkReplaceMembersRolesSuccessOrErrorMessage({
        memberCount: members?.length,
        customRoleCount: customRoleKeys?.length,
        role,
        warningsCount: warnings?.length,
        errorsCount: errors?.length,
      });
      if (successMessage) {
        dispatch(bulkReplaceMembersRolesDoneAction(successMessage, members, role));
      }
      if (errorsMessage) {
        dispatch(bulkReplaceMembersRolesDoneWithErrors(errorsMessage));
      }
    });
  };
}

const updateMemberAction = (oldMember: Member, newMember: Member) =>
  ({
    type: 'members/UPDATE_MEMBER',
    oldMember,
    newMember,
  }) as const;
const updateMemberDoneAction = (member: Member) => ({ type: 'members/UPDATE_MEMBER_DONE', member }) as const;
const updateMemberFailedAction = (newMember: Member, error: ImmutableServerError) =>
  ({
    type: 'members/UPDATE_MEMBER_FAILED',
    member: newMember,
    error,
  }) as const;

export function useUpdateMember() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  return async (oldMember: Member, newMember: Member) => {
    dispatch(updateMemberAction(oldMember, newMember));
    return updateMemberAPI(oldMember, newMember)
      .then((updated) => {
        dispatch(updateMemberDoneAction(updated));
      })
      .catch((error) => {
        if (error.get('status') === 403) {
          navigate(toMembers({ search: location.search }));
        }
        dispatch(updateMemberFailedAction(newMember, error));
      });
  };
}

const deleteMemberAction = (member: Member) => ({ type: 'members/DELETE_MEMBER', member }) as const;
const deleteMemberDoneAction = (member: Member) => ({ type: 'members/DELETE_MEMBER_DONE', member }) as const;
const deleteMemberFailedAction = (member: Member, error: ImmutableServerError) =>
  ({ type: 'members/DELETE_MEMBER_FAILED', member, error }) as const;

export function useDeleteMember() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  return async (member: Member) => {
    dispatch(deleteMemberAction(member));
    return deleteMemberAPI(member)
      .then(() => {
        navigate(toMembers({ search: location.search }));
        dispatch(deleteMemberDoneAction(member));
      })
      .catch((error) => dispatch(deleteMemberFailedAction(member, error)));
  };
}

const editInvite = (field: string, value: string) => ({ type: 'members/EDIT_INVITE', field, value }) as const;

const editBulkInvite = (
  field: 'emails' | 'customRoles' | 'role',
  value: List<string> | string | OrderedSet<string> | RoleName,
  seatsLeft: number = 0,
) => ({ type: 'members/EDIT_BULK_INVITE', field, value, seatsLeft }) as const;

function inviteMembersAction(
  invites: FormRecord<BulkMemberInvite>,
  options: {
    pathOnSuccess?: string;
    onCloseModal?: () => void;
    inviteAndSubscribe?: boolean;
  },
  subscription?: Subscription,
) {
  return { type: 'members/INVITE_MEMBERS', invites, options, subscription } as const;
}

function sendBulkInvite(
  invites: FormRecord<BulkMemberInvite>,
  options: {
    pathOnSuccess?: string;
    onCloseModal?: () => void;
    inviteAndSubscribe?: boolean;
  },
  subscription?: Subscription,
) {
  return inviteMembersAction(invites, options, subscription);
}

const inviteMembersBatchDone = (
  members: List<Member>,
  failedMembers: Map<
    number,
    List<{
      email: string;
      role: string;
      customRoles: List<string>;
      teamKeys: List<string>;
    }>
  >,
) => ({ type: 'members/INVITE_MEMBERS_BATCH_DONE', members, failedMembers }) as const;

const sendBulkInviteBatchDone = (
  members: List<Member>,
  failedMembers: Map<
    number,
    List<{
      email: string;
      role: string;
      customRoles: List<string>;
      teamKeys: List<string>;
    }>
  >,
) => inviteMembersBatchDone(members, failedMembers);

const sendBulkInviteFailed = (invites: FormRecord<BulkMemberInvite>, error: ImmutableServerError) =>
  ({ type: 'members/BULK_INVITE_MEMBERS_FAILED', invites, error }) as const;

const sendBulkInviteDone = (invites: FormRecord<BulkMemberInvite>, options: { inviteAndSubscribe?: boolean }) =>
  ({ type: 'members/INVITE_MEMBERS_DONE', invites, options }) as const;

const resendInviteAction = (member: Member) => ({ type: 'members/RESEND_INVITE', member }) as const;
const resendInviteDoneAction = (member: Member) => ({ type: 'members/RESEND_INVITE_DONE', member }) as const;
const resendInviteFailedAction = (member: Member, error: ImmutableServerError) =>
  ({ type: 'members/RESEND_INVITE_FAILED', member, error }) as const;

function resendInvite(member: Member) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(resendInviteAction(member));
    return resendInviteAPI(member)
      .then(() => dispatch(resendInviteDoneAction(member)))
      .catch((error) => dispatch(resendInviteFailedAction(member, error)));
  };
}

const editMember = (field: string, value: string) => ({ type: 'members/EDIT_MEMBER', field, value }) as const;

const sendMFAEnableRequestAction = (member: Member) => ({ type: 'members/SEND_MFA_ENABLE_REQUEST', member }) as const;
const sendMFAEnableRequestDoneAction = (member: Member) =>
  ({ type: 'members/SEND_MFA_ENABLE_REQUEST_DONE', member }) as const;
const sendMFAEnableRequestFailedAction = (member: Member, error: ImmutableServerError) =>
  ({ type: 'members/SEND_MFA_ENABLE_REQUEST_FAILED', member, error }) as const;

function sendMFAEnableRequest(member: Member) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(sendMFAEnableRequestAction(member));
    return new Promise((resolve, reject) => {
      sendMFAEnableRequestAPI(member).then(
        () => {
          dispatch(sendMFAEnableRequestDoneAction(member));
          resolve(member);
        },
        (error) => {
          dispatch(sendMFAEnableRequestFailedAction(member, error));
          reject(error);
        },
      );
    });
  };
}

const sendMFARecoveryCodeAction = (member: Member) => ({ type: 'members/SEND_MFA_RECOVERY_CODE', member }) as const;
const sendMFARecoveryCodeDoneAction = (member: Member) =>
  ({ type: 'members/SEND_MFA_RECOVERY_CODE_DONE', member }) as const;
const sendMFARecoveryCodeFailedAction = (member: Member, error: ImmutableServerError) =>
  ({ type: 'members/SEND_MFA_RECOVERY_CODE_FAILED', member, error }) as const;

function sendMFARecoveryCode(member: Member) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(sendMFARecoveryCodeAction(member));
    return new Promise((resolve, reject) => {
      sendMFARecoveryCodeAPI(member).then(
        () => {
          dispatch(sendMFARecoveryCodeDoneAction(member));
          resolve(member);
        },
        (error) => {
          dispatch(sendMFARecoveryCodeFailedAction(member, error));
          reject(error);
        },
      );
    });
  };
}

type FieldTypes = 'firstName' | 'lastName' | 'username' | 'adminEmail';

const editNewTeamInviteRequestAction = (field: FieldTypes, value: string, formState: FormState<NewJoinTeamRequest>) =>
  ({ type: 'members/EDIT_NEW_TEAM_INVITE_REQUEST', field, account: formState.modified.set(field, value) }) as const;

function editNewTeamInviteRequest(field: FieldTypes, value: string) {
  return (dispatch: GlobalDispatch, getState: GetState) => {
    const formState = getState().requestTeamInviteForm.get('form');
    return dispatch(editNewTeamInviteRequestAction(field, value, formState));
  };
}

const requestTeamInviteAction = (teamInviteRequest: FormRecord<NewJoinTeamRequest>) =>
  ({ type: 'members/REQUEST_TEAM_INVITE', teamInviteRequest }) as const;
const requestTeamInviteDoneAction = (teamInviteRequest: FormRecord<NewJoinTeamRequest>) =>
  ({ type: 'members/REQUEST_TEAM_INVITE_DONE', teamInviteRequest }) as const;
const requestTeamInviteFailedAction = (
  teamInviteRequest: FormRecord<NewJoinTeamRequest>,
  error: ImmutableServerError,
) => ({ type: 'members/REQUEST_TEAM_INVITE_FAILED', teamInviteRequest, error }) as const;

function requestTeamInvite(teamInviteRequest: FormRecord<NewJoinTeamRequest>) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestTeamInviteAction(teamInviteRequest));
    return requestTeamInviteAPI(teamInviteRequest)
      .then(() => dispatch(requestTeamInviteDoneAction(teamInviteRequest)))
      .catch((error) => {
        logger.error(`Error requesting team invite: ${error}`);
        return dispatch(requestTeamInviteFailedAction(teamInviteRequest, error));
      });
  };
}

const addMemberToTeamsAction = (memberId: string, teamKeys: string[]) =>
  ({ type: 'members/ADD_MEMBER_TO_TEAMS', memberId, teamKeys }) as const;
const addMemberToTeamsDoneAction = (member: Member, teamKeys: string[]) =>
  ({ type: 'members/ADD_MEMBER_TO_TEAMS_DONE', member, teamKeys }) as const;
const addMemberToTeamsFailedAction = (memberId: string, teamKeys: string[], error: ImmutableServerError) =>
  ({ type: 'members/ADD_MEMBER_TO_TEAMS_FAILED', memberId, teamKeys, error }) as const;

function addMemberToTeams(memberId: string, teamKeys: string[]) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(addMemberToTeamsAction(memberId, teamKeys));
    return addMemberToTeamsAPI(memberId, teamKeys)
      .then((member) => dispatch(addMemberToTeamsDoneAction(member, teamKeys)))
      .catch((error) => dispatch(addMemberToTeamsFailedAction(memberId, teamKeys, error)));
  };
}

const bulkAddMembersToTeamsAction = (memberIds: string[], teamKeys: string[]) =>
  ({
    type: 'members/BULK_ADD_MEMBERS_TO_TEAMS',
    memberIds,
    teamKeys,
  }) as const;

const bulkAddMembersToTeamsDone = (message: string) =>
  ({
    type: 'members/BULK_ADD_MEMBERS_TO_TEAMS_DONE',
    successMessage: message,
  }) as const;

const bulkAddMembersToTeamsDoneWithErrors = (message: string) =>
  ({
    type: 'members/BULK_ADD_MEMBERS_TO_TEAMS_DONE',
    errorsMessage: message,
  }) as const;

function bulkAddMembersToTeams(memberIds: string[], teamKeysToAdd: string[], filter: MemberFilters) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(bulkAddMembersToTeamsAction(memberIds, teamKeysToAdd));
    return bulkAddMembersToTeamsAPI(memberIds, teamKeysToAdd, filter).then((response) => {
      const { memberIDs, teamKeys, errors, warnings } = response;
      const { successMessage, errorsMessage } = bulkAddMembersSuccessOrErrorMessage({
        memberCount: memberIDs?.length,
        teamCount: teamKeys?.length,
        warningsCount: warnings?.length,
        errorsCount: errors?.length,
      });
      if (successMessage) {
        dispatch(bulkAddMembersToTeamsDone(successMessage));
      }
      if (errorsMessage) {
        dispatch(bulkAddMembersToTeamsDoneWithErrors(errorsMessage));
      }
    });
  };
}

const bulkAddAllMembersToTeamsAction = (ignoredMemberIDs: string[], teamKeys: string[], filters: MemberFilters) =>
  ({ type: 'members/BULK_REPLACE_ALL_MEMBERS_ROLES', ignoredMemberIDs, teamKeys, filters }) as const;

const bulkAddAllMembersToTeams =
  (ignoredMemberIDs: string[], teamKeysToAdd: string[], filters: MemberFilters) => async (dispatch: GlobalDispatch) =>
    bulkAddAllMembersToTeamsAPI(ignoredMemberIDs, teamKeysToAdd, filters).then((response) => {
      const { memberIDs, teamKeys, errors, warnings } = response;
      dispatch(bulkAddAllMembersToTeamsAction(ignoredMemberIDs, teamKeys, filters));
      const { successMessage, errorsMessage } = bulkAddMembersSuccessOrErrorMessage({
        memberCount: memberIDs?.length,
        teamCount: teamKeys?.length,
        warningsCount: warnings?.length,
        errorsCount: errors?.length,
      });
      if (successMessage) {
        dispatch(bulkAddMembersToTeamsDone(successMessage));
      }
      if (errorsMessage) {
        dispatch(bulkAddMembersToTeamsDoneWithErrors(errorsMessage));
      }
    });

const approveUnverifiedMemberRequestStart = () =>
  ({
    type: 'members/APPROVE_UNVERIFIED_MEMBER_REQUEST_START',
  }) as const;

const approveUnverifiedMemberRequestDone = () =>
  ({
    type: 'members/APPROVE_UNVERIFIED_MEMBER_REQUEST_DONE',
  }) as const;

const approveUnverifiedMemberRequestFailed = (error: ImmutableServerError) =>
  ({
    type: 'members/APPROVE_UNVERIFIED_MEMBER_REQUEST_FAILED',
    error,
  }) as const;

function approveUnverifiedMemberRequest(
  token: string,
  selectedRole: RoleName,
  customRoles: Set<string>,
  teamKeys: string[],
) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(approveUnverifiedMemberRequestStart());

    try {
      // create member with default reader role
      const member = await approveUnverifiedMemberRequestAPI(token);

      if (selectedRole !== RoleName.READER) {
        const updatedMemberWithRoleChange =
          selectedRole === RoleName.CUSTOM
            ? member.set('customRoles', customRoles)
            : member.set('customRoles', Set()).set('role', selectedRole);

        await updateMemberAPI(member, updatedMemberWithRoleChange);
      }

      if (teamKeys.length > 0) {
        await addMemberToTeamsAPI(member._id, teamKeys);
      }

      // refresh the members list
      await dispatch(fetchMembers());

      dispatch(approveUnverifiedMemberRequestDone());
    } catch (error) {
      dispatch(approveUnverifiedMemberRequestFailed(error as ImmutableServerError));
    }
  };
}

const editSuggestInviteMembers = (field: 'emails', value: OrderedSet<string>) =>
  ({ type: 'members/EDIT_SUGGEST_INVITE_MEMBERS', field, value }) as const;

const sendSuggestInviteMembersStart = (suggestedInvites: FormRecord<SuggestInviteMembers>) =>
  ({ type: 'members/SEND_SUGGEST_INVITE_MEMBERS', suggestedInvites }) as const;

const sendSuggestInviteMembersDone = (suggestedInvites: FormRecord<SuggestInviteMembers>) =>
  ({ type: 'members/SEND_SUGGEST_INVITE_MEMBERS_DONE', suggestedInvites }) as const;

const sendSuggestInviteMembersFailed = (
  suggestedInvites: FormRecord<SuggestInviteMembers>,
  error: ImmutableServerError,
) => ({ type: 'members/SEND_SUGGEST_INVITE_MEMBERS_FAILED', suggestedInvites, error }) as const;

function sendSuggestInviteMembers(formRecord: FormRecord<SuggestInviteMembers>) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(sendSuggestInviteMembersStart(formRecord));

    try {
      await suggestInviteMembersAPI(formRecord.emails.toArray());
      dispatch(sendSuggestInviteMembersDone(formRecord));
    } catch (error) {
      dispatch(sendSuggestInviteMembersFailed(formRecord, error as ImmutableServerError));
    }
  };
}

export {
  addMemberToTeams,
  bulkAddAllMembersToTeams,
  bulkAddMembersToTeams,
  bulkReplaceAllMembersRoles,
  bulkReplaceMembersRoles,
  editBulkInvite,
  editInvite,
  editMember,
  editNewTeamInviteRequest,
  fetchMember,
  fetchMembers,
  fetchMembersByTeam,
  fetchMembersByTeamIfNeeded,
  fetchMembersIfNeeded,
  requestTeamInvite,
  resendInvite,
  sendBulkInvite,
  sendBulkInviteBatchDone,
  sendBulkInviteDone,
  sendBulkInviteFailed,
  sendMFAEnableRequest,
  sendMFARecoveryCode,
  approveUnverifiedMemberRequest,
  editSuggestInviteMembers,
  sendSuggestInviteMembers,
  sendSuggestInviteMembersStart,
  sendSuggestInviteMembersDone,
  sendSuggestInviteMembersFailed,
};

const MemberActionCreators = {
  addMemberToTeamsAction,
  addMemberToTeamsDoneAction,
  addMemberToTeamsFailedAction,
  bulkAddMembersToTeamsAction,
  bulkAddMembersToTeamsDone,
  bulkAddMembersToTeamsDoneWithErrors,
  bulkReplaceAllMembersRolesAction,
  bulkReplaceMembersRolesAction,
  bulkReplaceMembersRolesDoneAction,
  deleteMemberAction,
  deleteMemberDoneAction,
  deleteMemberFailedAction,
  editBulkInvite,
  editNewTeamInviteRequestAction,
  inviteMembersAction,
  inviteMembersBatchDone,
  requestMember,
  requestMemberDone,
  requestMemberFailed,
  requestMembers,
  requestMembersByTeam,
  requestMembersByTeamDone,
  requestMembersByTeamFailed,
  requestMembersDone,
  requestMembersFailed,
  requestTeamInviteAction,
  requestTeamInviteDoneAction,
  requestTeamInviteFailedAction,
  sendBulkInviteDone,
  sendBulkInviteFailed,
  sendMFAEnableRequestAction,
  sendMFAEnableRequestDoneAction,
  sendMFAEnableRequestFailedAction,
  sendMFARecoveryCodeAction,
  sendMFARecoveryCodeDoneAction,
  sendMFARecoveryCodeFailedAction,
  updateMemberAction,
  updateMemberDoneAction,
  updateMemberFailedAction,
  approveUnverifiedMemberRequestStart,
  approveUnverifiedMemberRequestDone,
  approveUnverifiedMemberRequestFailed,
  editSuggestInviteMembers,
  sendSuggestInviteMembersStart,
  sendSuggestInviteMembersDone,
  sendSuggestInviteMembersFailed,
};

export type MemberAction = GenerateActionType<typeof MemberActionCreators>;
