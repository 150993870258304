import { Duration } from 'date-fns';

export function unitsToIncludeInTimeDisplay(interval: Duration) {
  if (!!interval.years) {
    return ['years' as const, 'months' as const];
  } else if (!!interval.months) {
    return ['months' as const, 'weeks' as const];
  } else if (!!interval.weeks) {
    return ['weeks' as const, 'days' as const];
  } else if (!!interval.days) {
    return ['days' as const, 'hours' as const];
  } else if (!!interval.hours) {
    return ['hours' as const, 'minutes' as const];
  } else if (!!interval.minutes) {
    return ['minutes' as const, 'seconds' as const];
  } else if (!!interval.seconds) {
    return ['seconds' as const];
  }

  return undefined;
}

export function unitsToIncludeInTimeLeftDisplay(interval: Duration) {
  if (!!interval.years) {
    return ['years' as const];
  } else if (!!interval.months) {
    return ['months' as const];
  } else if (!!interval.weeks) {
    return ['weeks' as const];
  } else if (!!interval.days) {
    return ['days' as const];
  } else if (!!interval.hours) {
    return ['hours' as const];
  } else if (!!interval.minutes) {
    return ['minutes' as const];
  } else if (!!interval.seconds) {
    return ['seconds' as const];
  }

  return undefined;
}

export function addOneToHighestUnit(interval: Duration) {
  if (!!interval.years) {
    return { ...interval, years: interval.years + 1 };
  }
  if (!!interval.months) {
    return { ...interval, months: interval.months + 1 };
  }
  if (!!interval.weeks) {
    return { ...interval, weeks: interval.weeks + 1 };
  }
  if (!!interval.days) {
    return { ...interval, days: interval.days + 1 };
  }
  if (!!interval.hours) {
    return { ...interval, hours: interval.hours + 1 };
  }
  if (!!interval.minutes) {
    return { ...interval, minutes: interval.minutes + 1 };
  }
  if (!!interval.seconds) {
    return { ...interval, seconds: interval.seconds + 1 };
  }

  return interval;
}
