import { Cell, Grid, GridProps } from 'components/ui/grid';

/* eslint-disable import/no-default-export */
export default function GridSkeleton(props: GridProps) {
  return <Grid {...props} aria-label="loading" aria-valuetext="loading" role="progressbar" />;
}

export function SkeletonCells({ cells, className, testId }: { cells: number; className?: string; testId?: string }) {
  return (
    <>
      {Array.from({ length: cells }, (_, key) => (
        <Cell key={key} className={className} testId={testId}>
          <SkeletonCellContent />
        </Cell>
      ))}
    </>
  );
}

export function SkeletonCellContent() {
  return <span className="block SkeletonApp--loading SkeletonApp-table-row--loading" />;
}
