import { Key } from 'react-aria';
import { arrayMoveAfter, arrayMoveBefore } from '@gonfalon/collections';
import { shortcutsList, useCreateShortcut, useDeleteShortcut, useUpdateShortcutOrdering } from '@gonfalon/rest-api';
import { ToastQueue } from '@launchpad-ui/components';
import { useSuspenseQueries } from '@tanstack/react-query';

import { transformShortcutForUI } from './internal/transformShortcutForUI';
import { Shortcut } from './types';

export const useShortcutData = ({ projectKey }: { projectKey: string }) => {
  const [shortcutsQuery] = useSuspenseQueries({
    queries: [shortcutsList({ projectKey })],
  });

  const shortcuts = shortcutsQuery.data.items.map((shortcut) => transformShortcutForUI(shortcut));

  const { mutate: deleteShortcutMutation } = useDeleteShortcut();

  const { mutate: createShortcutMutation } = useCreateShortcut();

  const { mutate: reorderShortcutsMutation } = useUpdateShortcutOrdering();

  const createShortcut = (shortcut: Shortcut) => {
    createShortcutMutation(
      {
        body: {
          ...shortcut,
          context: {
            ...shortcut.context,
            environmentKeys: Array.from(shortcut.context.environmentKeys),
          },
        },
      },
      {
        onError: () => {
          ToastQueue.error('An error occurred while creating the shortcut');
        },
      },
    );
  };

  const deleteShortcut = (shortcutKey: Key) => {
    deleteShortcutMutation(
      { projectKey, shortcutKey: shortcutKey as string },
      {
        onError() {
          ToastQueue.error('An error occurred while deleting the shortcut');
        },
      },
    );
  };

  const reorderShortcut = (shortcutKey: Key, position: 'before' | 'after', otherKeys: Set<Key>) => {
    const nextShortcuts = shortcuts;
    if (nextShortcuts.length === 1) {
      return;
    }

    const target = nextShortcuts.find((it) => it.key === shortcutKey);
    const others: typeof shortcuts = [];
    for (const otherKey of otherKeys) {
      const other = nextShortcuts.find((it) => it.key === otherKey);
      if (other) {
        others.push(other);
      }
    }
    const nextList =
      position === 'after'
        ? arrayMoveAfter(nextShortcuts, others, target)
        : arrayMoveBefore(nextShortcuts, others, target);

    let didChange = false;
    for (const [index, item] of nextShortcuts.entries()) {
      if (item !== nextList[index]) {
        didChange = true;
        break;
      }
    }

    if (!didChange) {
      return;
    }

    const shortcutKeys = nextList.map((item) => item?.key).filter(Boolean) as string[];

    reorderShortcutsMutation(
      {
        projectKey,
        body: {
          shortcutKeys,
        },
      },
      {
        onError: () => {
          ToastQueue.error('An error occurred while reordering the shortcuts');
        },
      },
    );
  };

  return {
    shortcuts,
    createShortcut,
    reorderShortcut,
    deleteShortcut,
  };
};
