import { flushSync } from 'react-dom';
import { To, useNavigate } from 'react-router-dom';
import { noop } from '@gonfalon/es6-utils';
import { toSegments } from '@gonfalon/navigator';
import { SnackbarQueue } from '@launchpad-ui/components';
import { fromJS, List, Map, OrderedMap, Set } from 'immutable';

import { addSegmentRule as addFlagSegmentRule, modifySegmentRule } from 'actions/flags';
import { useDispatch } from 'hooks/useDispatch';
import { GetState, GlobalDispatch, GlobalState } from 'reducers';
import {
  ContextTargetingExpirationUpdates,
  ExpiringContextTargetsByContextKindAndKey,
} from 'reducers/expiringContextTargets';
import {
  BigSegmentFileMetaData,
  segmentListRequestSelector,
  segmentTargetingManagerSelector,
  shouldFetchSegmentRelatedFlagsSelector,
  shouldFetchSegmentSelector,
} from 'reducers/segments';
import { reportAccesses } from 'sources/AccountAPI';
import {
  createSegment as segmentAPICreateSegment,
  deleteSegment as segmentAPIDeleteSegment,
  getBigSegmentFileMetaData as segmentAPIGetBigSegmentFileMetaData,
  getBigSegmentTarget as segmentAPIGetBigSegmentTarget,
  getSegmentByKey as segmentAPIGetSegmentByKey,
  getSegments as segmentAPIGetSegments,
  postSegmentExport as segmentAPIPostSegmentExport,
  updateBigSegmentIndividualTargets as segmentAPIUpdateBigSegmentIndividualTargets,
  updateSegment as segmentAPIUpdateSegment,
  uploadCsvToSegment as segmentAPIUploadCsvToSegment,
} from 'sources/SegmentAPI';
import { accessResponseDeniesUpdateTags, makeResourceSpec } from 'utils/accessUtils';
import { Clause } from 'utils/clauseUtils';
import {
  getExpiringTargetKeys,
  getPendingExpiringTargetKeys,
  SegmentVariationTypes,
} from 'utils/expiringContextTargetsUtils';
import { Rule, Target, Variation } from 'utils/flagUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import Logger from 'utils/logUtils';
import { GenerateActionType } from 'utils/reduxUtils';
import {
  BigSegmentIndividualTargetUpdates,
  BigSegmentIndividualTargetUpdatesField,
  BigSegmentTarget,
  BigSegmentUploadMode,
  createSegmentRule,
  idOrKey,
  Segment,
  SegmentRule,
} from 'utils/segmentUtils';
import { User } from 'utils/userUtils';

import {
  SEGMENT_EXPORT_FAILED,
  SEGMENT_EXPORT_STARTED,
  UPLOAD_CSV_TO_SEGMENT_IMPORT_STARTED,
  UPLOAD_CSV_TO_SEGMENT_MODAL_CLOSED,
  UPLOAD_CSV_TO_SEGMENT_MODAL_OPENED,
  UPLOAD_CSV_TO_SEGMENT_UPLOAD_FAILED,
  UPLOAD_CSV_TO_SEGMENT_UPLOAD_STARTED,
} from '../actionTypes/segments';

import { deleteContextTargetingExpiration, undoContextTargetingExpiration } from './expiringContextTargets';

const logger = new Logger('actions/segments');

type UserEntitiesRep = OrderedMap<string, User>;
type NestedLinksRep = Map<string, string>;
type LinksRep = Map<string, NestedLinksRep>;

export const filterSegmentsText = (text: string) =>
  ({
    type: 'segments/FILTER_SEGMENTS_TEXT',
    text,
  }) as const;

export const filterSegmentsType = (segmentType: string) =>
  ({
    type: 'segments/FILTER_SEGMENTS_TYPE',
    segmentType,
  }) as const;

export const editSegment = (field: string, value: string | string[] | Set<string> | boolean) =>
  ({
    type: 'segments/EDIT_SEGMENT',
    field,
    value,
  }) as const;

export const createSegmentStart = (segment: Segment) =>
  ({
    type: 'segments/CREATE_SEGMENT',
    segment,
  }) as const;

export const createSegmentDone = (segment: Segment) =>
  ({
    type: 'segments/CREATE_SEGMENT_DONE',
    segment,
  }) as const;

export const createSegmentFailed = (segment: Segment, error: ImmutableServerError) =>
  ({
    type: 'segments/CREATE_SEGMENT_FAILED',
    segment,
    error,
  }) as const;

export const useCreateSegment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (projKey: string, envKey: string, segment: Segment) => {
    dispatch(createSegmentStart(segment));
    return segmentAPICreateSegment(projKey, envKey, segment).then(
      (created) => {
        navigate(created.siteLink());
        dispatch(createSegmentDone(created));
      },
      (error) => {
        if (error.get('status') === 403) {
          navigate(toSegments({ projectKey: projKey, environmentKey: envKey }));
        }

        dispatch(createSegmentFailed(segment, error));
      },
    );
  };
};

export const deleteSegmentStart = (segment: Segment) =>
  ({
    type: 'segments/DELETE_SEGMENT',
    segment,
  }) as const;

export const deleteSegmentDone = (segment: Segment) =>
  ({
    type: 'segments/DELETE_SEGMENT_DONE',
    segment,
  }) as const;

export const deleteSegmentFailed = (segment: Segment, error: ImmutableServerError) =>
  ({
    type: 'segments/DELETE_SEGMENT_FAILED',
    segment,
    error,
  }) as const;

export const useDeleteSegment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (segment: Segment, options: { pathOnSuccess: To } = { pathOnSuccess: '' }) => {
    dispatch(deleteSegmentStart(segment));
    return segmentAPIDeleteSegment(segment).then(
      () => {
        options.pathOnSuccess && navigate(options.pathOnSuccess);
        dispatch(deleteSegmentDone(segment));
      },
      (error) => {
        dispatch(deleteSegmentFailed(segment, error));
        SnackbarQueue.error({ description: error.get('message') });
      },
    );
  };
};

export const fetchSegmentsStart = () =>
  ({
    type: 'segments/REQUEST_SEGMENTS',
  }) as const;

export type FetchSegmentsResponse = ImmutableMap<{
  entities: Map<string, UserEntitiesRep>;
  result: Map<string, LinksRep>;
}>;
export const fetchSegmentsDone = (response: FetchSegmentsResponse, managedSegmentKey: string) =>
  ({
    type: 'segments/REQUEST_SEGMENTS_DONE',
    response,
    managedSegmentKey,
  }) as const;

export const fetchSegmentsFailed = (error: ImmutableServerError) =>
  ({
    type: 'segments/REQUEST_SEGMENTS_FAILED',
    error,
  }) as const;

const requestBigSegmentStatus = () =>
  ({
    type: 'segments/REQUEST_BIG_SEGMENT_STATUS',
  }) as const;

const requestBigSegmentStatusDone = (userKey: string, target: BigSegmentTarget) =>
  ({
    type: 'segments/REQUEST_BIG_SEGMENT_STATUS_DONE',
    userKey,
    target,
  }) as const;

const requestBigSegmentStatusFailed = (userKey: string, error: ImmutableServerError) =>
  ({
    type: 'segments/REQUEST_BIG_SEGMENT_STATUS_FAILED',
    userKey,
    error,
  }) as const;

export const getBigSegmentTarget = (segment: Segment, userKey: string) => async (dispatch: GlobalDispatch) => {
  dispatch(requestBigSegmentStatus());
  try {
    const target = await segmentAPIGetBigSegmentTarget(segment, userKey);
    dispatch(requestBigSegmentStatusDone(userKey, target));
  } catch (error) {
    dispatch(requestBigSegmentStatusFailed(userKey, error as ImmutableServerError));
  }
};

const fetchSegments =
  (projKey: string, envKey: string, queryString?: string, signal?: AbortSignal) =>
  async (dispatch: GlobalDispatch, getState: GetState) => {
    dispatch(fetchSegmentsStart());
    return segmentAPIGetSegments(projKey, envKey, queryString, signal).then(
      (response) =>
        dispatch(fetchSegmentsDone(response, segmentTargetingManagerSelector(getState()).getIn(['original', 'key']))),
      (error) => dispatch(fetchSegmentsFailed(error)),
    );
  };

const shouldFetchSegments = (state: GlobalState) => segmentListRequestSelector(state).shouldFetch();

const fetchSegmentsIfNeeded =
  (projKey: string, envKey: string, queryString?: string, forceRefresh?: boolean, signal?: AbortSignal) =>
  async (dispatch: GlobalDispatch, getState: GetState) =>
    new Promise((resolve) => {
      resolve(
        (forceRefresh || shouldFetchSegments(getState())) &&
          dispatch(fetchSegments(projKey, envKey, queryString, signal)),
      );
    });

export { fetchSegmentsIfNeeded as fetchSegments };

export const fetchSegmentByKeyStart = (segmentKey: string) =>
  ({
    type: 'segments/REQUEST_SEGMENT_BY_KEY',
    segmentKey,
  }) as const;

export const fetchSegmentByKeyDone = (segmentKey: string, segment: Segment) =>
  ({
    type: 'segments/REQUEST_SEGMENT_BY_KEY_DONE',
    segmentKey,
    segment,
  }) as const;

export const fetchSegmentByKeyFailed = (segmentKey: string, error: ImmutableServerError) =>
  ({
    type: 'segments/REQUEST_SEGMENT_BY_KEY_FAILED',
    segmentKey,
    error,
  }) as const;

const fetchSegmentByKey = (projKey: string, envKey: string, segmentKey: string) => async (dispatch: GlobalDispatch) => {
  dispatch(fetchSegmentByKeyStart(segmentKey));
  return new Promise((resolve, reject) => {
    segmentAPIGetSegmentByKey(projKey, envKey, segmentKey).then(
      (segment) => {
        dispatch(fetchSegmentByKeyDone(segmentKey, segment));
        resolve(segment);
      },
      (error) => {
        dispatch(fetchSegmentByKeyFailed(segmentKey, error));
        reject(error);
      },
    );
  });
};

export { fetchSegmentByKey as reFetchSegmentByKey };

const shouldFetchSegmentByKey = (state: GlobalState, segmentKey: string) =>
  shouldFetchSegmentSelector(state, { segmentKey });

const fetchSegmentByKeyIfNeeded =
  (projKey: string, envKey: string, segmentKey: string) => async (dispatch: GlobalDispatch, getState: GetState) =>
    new Promise((resolve) => {
      resolve(
        shouldFetchSegmentByKey(getState(), segmentKey) && dispatch(fetchSegmentByKey(projKey, envKey, segmentKey)),
      );
    });

export { fetchSegmentByKeyIfNeeded as fetchSegmentByKey };

const editSegmentSettingsType = (field: string, value: string | string[] | Set<string>) =>
  ({
    type: 'segments/EDIT_SEGMENT_SETTINGS',
    field,
    value,
  }) as const;

const checkAccessResource = (willRemoveEditingAbility: boolean) => ({
  type: 'segments/CHECK_ACCESS_RESOURCE',
  willRemoveEditingAbility,
});

export function editSegmentSettings(
  projectKey: string,
  environmentKey: string,
  field: string,
  value: string | string[] | Set<string>,
  options: { segmentKey?: string; oldSegment?: Segment; isAccessWarningEnabled?: boolean } = {},
) {
  const { segmentKey, oldSegment, isAccessWarningEnabled } = options;
  return (dispatch: GlobalDispatch) => {
    dispatch(editSegmentSettingsType(field, value));

    if (isAccessWarningEnabled && field === 'tags' && segmentKey && oldSegment) {
      const newResourceSpec = makeResourceSpec(projectKey, environmentKey, 'segment', segmentKey, value);
      const oldAccessDenied = oldSegment.getIn(['_access', 'denied']);

      reportAccesses(newResourceSpec).then((accesses) => {
        const willRemoveEditingAbility = accessResponseDeniesUpdateTags(accesses, newResourceSpec, oldAccessDenied);
        dispatch(checkAccessResource(willRemoveEditingAbility));
      }, noop);
    }
  };
}

export function discardAllPendingChanges(original: Segment) {
  return (dispatch: GlobalDispatch) => {
    const updated = original;
    dispatch(commitDiscardedPendingChanges(updated));
  };
}

export function discardIncludedTargetPendingChanges(original: Segment, modified: Segment) {
  return (dispatch: GlobalDispatch) => {
    const updated = modified.set('included', original.included).set('includedContexts', original.includedContexts);
    dispatch(commitDiscardedPendingChanges(updated));
  };
}

export function discardExcludedTargetPendingChanges(original: Segment, modified: Segment) {
  return (dispatch: GlobalDispatch) => {
    const updated = modified.set('excluded', original.excluded).set('excludedContexts', original.excludedContexts);
    dispatch(commitDiscardedPendingChanges(updated));
  };
}

export function discardRulePendingChanges(original: Segment, modified: Segment, rule: SegmentRule, ruleIndex: number) {
  return (dispatch: GlobalDispatch) => {
    // if it's a new rule, just delete it
    const isNewRule = rule._id === '';
    if (isNewRule) {
      const updated = modified.deleteRule(rule);
      dispatch(commitDiscardedPendingChanges(updated));
      return;
    }

    // reset the rule back to it's original state
    const ruleId = idOrKey(rule);
    const originalRule = original.rules.find((r) => idOrKey(r) === ruleId);

    const updated = modified.setIn(['rules', ruleIndex], originalRule);
    dispatch(commitDiscardedPendingChanges(updated));
  };
}

export const commitDiscardedPendingChanges = (segment: Segment) => ({
  type: 'segments/COMMIT_DISCARDED_PENDING_CHANGES',
  segment,
});

export const initializeSegmentTargetingManager = (segment: Segment) =>
  ({
    type: 'segments/INITIALIZE_SEGMENT_TARGETING_MANAGER',
    segment,
  }) as const;

export const destroySegmentTargetingManager = (segment: Segment) =>
  ({
    type: 'segments/DESTROY_SEGMENT_TARGETING_MANAGER',
    segment,
  }) as const;

export const resetSegmentTargetingManager = (segment: Segment) =>
  ({
    type: 'segments/RESET_SEGMENT_TARGETING_MANAGER',
    segment,
  }) as const;

export const setIncludedTargets = (segment: Segment, targets: List<string>, contextKind: string = 'user') =>
  ({
    type: 'segments/CHANGE_SEGMENT_TARGETING',
    segment: segment.setIncludedTargets(targets, contextKind),
  }) as const;

export const setExcludedTargets = (segment: Segment, targets: List<string>, contextKind: string = 'user') =>
  ({
    type: 'segments/CHANGE_SEGMENT_TARGETING',
    segment: segment.setExcludedTargets(targets, contextKind),
  }) as const;

export const clearTargets = (segment: Segment) =>
  ({
    type: 'segments/CHANGE_SEGMENT_TARGETING',
    segment,
  }) as const;

export const clearIncludedSegmentTargets = (segment: Segment, contextsToClear: string[]) => {
  let updatedSegment = segment;
  contextsToClear.forEach((contextKind) => {
    updatedSegment = updatedSegment.setIncludedTargets(List(), contextKind);
  });
  return (dispatch: GlobalDispatch) => {
    dispatch(clearTargets(updatedSegment));
  };
};

export const clearExcludedSegmentTargets = (segment: Segment, contextsToClear: string[]) => {
  let updatedSegment = segment;
  contextsToClear.forEach((contextKind) => {
    updatedSegment = updatedSegment.setExcludedTargets(List(), contextKind);
  });
  return (dispatch: GlobalDispatch) => {
    dispatch(clearTargets(updatedSegment));
  };
};

export const clearAllSegmentTargetsAndExpirations =
  ({
    segment,
    variationType,
    contextsToClear,
    expiringTargetsOnSegment,
    targetingExpirationUpdates,
  }: {
    segment: Segment;
    variationType: SegmentVariationTypes;
    contextsToClear: string[];
    expiringTargetsOnSegment?: ExpiringContextTargetsByContextKindAndKey;
    targetingExpirationUpdates?: ContextTargetingExpirationUpdates;
  }) =>
  (dispatch: GlobalDispatch) => {
    // Remove pending expiring target updates
    contextsToClear.forEach((contextKind) => {
      const pendingExpiringContextKeys = getPendingExpiringTargetKeys(
        contextKind,
        segment.key,
        variationType,
        targetingExpirationUpdates,
      );
      pendingExpiringContextKeys.forEach((contextKey) =>
        dispatch(
          undoContextTargetingExpiration({
            contextKind,
            contextKey,
            segmentKey: segment.key,
            variationId: variationType,
          }),
        ),
      );
    });

    // Remove expiring targets
    contextsToClear.forEach((contextKind) => {
      const expiringContextKeys = getExpiringTargetKeys(contextKind, variationType, expiringTargetsOnSegment);
      expiringContextKeys.forEach((contextKey) =>
        dispatch(
          deleteContextTargetingExpiration({
            contextKind,
            contextKey,
            segmentKey: segment.key,
            variationId: variationType,
          }),
        ),
      );
    });

    variationType === SegmentVariationTypes.INCLUDED
      ? dispatch(clearIncludedSegmentTargets(segment, contextsToClear))
      : dispatch(clearExcludedSegmentTargets(segment, contextsToClear));
  };

export const addSegmentRule = (segment: Segment, rule?: SegmentRule, atIndex?: number) =>
  ({
    type: 'segments/CHANGE_SEGMENT_TARGETING',
    segment: segment.addRule(rule, atIndex),
  }) as const;

export const deleteSegmentRule = (segment: Segment, rule: SegmentRule) =>
  ({
    type: 'segments/CHANGE_SEGMENT_TARGETING',
    segment: segment.deleteRule(rule),
  }) as const;

export const duplicateSegmentRule = (segment: Segment, rule: SegmentRule) =>
  ({
    type: 'segments/CHANGE_SEGMENT_TARGETING',
    segment: segment.duplicateRule(rule),
  }) as const;

export const addSegmentRuleClause = (segment: Segment, rule: SegmentRule, clause?: Clause) =>
  ({
    type: 'segments/CHANGE_SEGMENT_TARGETING',
    segment: segment.addRuleClause(rule, clause),
  }) as const;

export const deleteSegmentRuleClause = (segment: Segment, rule: SegmentRule, clause: Clause) =>
  ({
    type: 'segments/CHANGE_SEGMENT_TARGETING',
    segment: segment.deleteRuleClause(rule, clause),
  }) as const;

export const editSegmentRuleClause = (segment: Segment, rule: SegmentRule, clause: Clause) =>
  ({
    type: 'segments/CHANGE_SEGMENT_TARGETING',
    segment: segment.editRuleClause(rule, clause),
  }) as const;

export const changeRuleRollout = (segment: Segment, rule: SegmentRule, weight: number) =>
  ({
    type: 'segments/CHANGE_SEGMENT_TARGETING',
    segment: segment.setRuleRollout(rule, weight),
  }) as const;

export const changeRuleBucket = (segment: Segment, rule: SegmentRule, attr: string) =>
  ({
    type: 'segments/CHANGE_SEGMENT_TARGETING',
    segment: segment.setRuleBucket(rule, attr),
  }) as const;

export const changeRuleRolloutContextKind = (segment: Segment, rule: SegmentRule, contextKind: string) =>
  ({
    type: 'segments/CHANGE_SEGMENT_TARGETING',
    segment: segment.setRuleRolloutContextKind(rule, contextKind),
  }) as const;

export const changeRuleIndex = (segment: Segment, ruleKey: string, index: number) =>
  ({
    type: 'segments/CHANGE_SEGMENT_TARGETING',
    segment: segment.setRuleIndex(ruleKey, index),
  }) as const;

export const changeRuleDescription = (segment: Segment, rule: SegmentRule, description: string) => ({
  type: 'segments/CHANGE_SEGMENT_TARGETING',
  segment: segment.setRuleDescription(rule, description),
});

const updateSegmentBase =
  (actionType: string[]) =>
  (oldSegment: Segment, newSegment: Segment, comment: string = '') => {
    const [requestType, successType, failureType] = actionType;

    return async (dispatch: GlobalDispatch) => {
      dispatch({ type: requestType, segment: newSegment });
      return new Promise((resolve: (segment: Segment) => void, reject) => {
        segmentAPIUpdateSegment(oldSegment, newSegment, comment)
          .then((segment) => {
            dispatch({ type: successType, segment });
            resolve(segment);
          })
          .catch((error) => {
            dispatch({ type: failureType, segment: newSegment, error });
            reject(error);
          });
      });
    };
  };

export const updateSegmentSettings = updateSegmentBase([
  'segments/UPDATE_SEGMENT_SETTINGS',
  'segments/UPDATE_SEGMENT_SETTINGS_DONE',
  'segments/UPDATE_SEGMENT_SETTINGS_FAILED',
]);

export const updateSegmentTargeting = updateSegmentBase([
  'segments/UPDATE_SEGMENT_TARGETING',
  'segments/UPDATE_SEGMENT_TARGETING_DONE',
  'segments/UPDATE_SEGMENT_TARGETING_FAILED',
]);

export const fetchRelatedFlagsForSegmentStart = (segmentKey: string) =>
  ({
    type: 'segments/REQUEST_SEGMENT_RELATED_FLAGS',
    segmentKey,
  }) as const;

export const fetchRelatedFlagsForSegmentDone = (segmentKey: string, segment: Segment) =>
  ({
    type: 'segments/REQUEST_SEGMENT_RELATED_FLAGS_DONE',
    segmentKey,
    segment,
  }) as const;

export const fetchRelatedFlagsForSegmentFailed = (segmentKey: string, error: ImmutableServerError) =>
  ({
    type: 'segments/REQUEST_SEGMENT_RELATED_FLAGS_FAILED',
    segmentKey,
    error,
  }) as const;

export const fetchRelatedFlagsForSegment =
  (projKey: string, envKey: string, segmentKey: string) => (dispatch: GlobalDispatch, getState: GetState) => {
    if (!shouldFetchSegmentRelatedFlagsSelector(getState(), { segmentKey })) {
      return;
    }

    dispatch(fetchRelatedFlagsForSegmentStart(segmentKey));

    segmentAPIGetSegmentByKey(projKey, envKey, segmentKey).then(
      (segment) => dispatch(fetchRelatedFlagsForSegmentDone(segmentKey, segment)),
      (error) => dispatch(fetchRelatedFlagsForSegmentFailed(segmentKey, error)),
    );
  };

export const convertToSegmentDone = (segment: Segment) =>
  ({
    type: 'segments/CONVERT_TO_SEGMENT_DONE',
    segment,
  }) as const;

const startBigSegmentImport = () =>
  ({
    type: UPLOAD_CSV_TO_SEGMENT_IMPORT_STARTED,
  }) as const;

const generateConvertToSegmentComment = (flagKey: string) =>
  `Segment created via "Convert to segment" from the ${flagKey} flag`;

export const convertRulesToSegment =
  (projKey: string, envKey: string, flagKey: string, segment: Segment, rule: Rule, onCancel: () => void) =>
  async (dispatch: GlobalDispatch) => {
    dispatch(createSegmentStart(segment));

    try {
      const created = await segmentAPICreateSegment(projKey, envKey, segment);
      const newClauses = rule.clauses.map((c) => c.set('_id', ''));
      let updated = created.set('rules', List([createSegmentRule({ clauses: newClauses })]));
      updated = await segmentAPIUpdateSegment(created, updated, generateConvertToSegmentComment(flagKey));

      dispatch(modifySegmentRule(updated.key, rule));
      dispatch(createSegmentDone(updated));
      onCancel();
      dispatch(convertToSegmentDone(updated));
    } catch (error) {
      logger.error(`Error converting rules to segment: ${error}`);
      dispatch(createSegmentFailed(segment, error as ImmutableServerError));
    }
  };

export const convertTargetsToSegment =
  (
    projKey: string,
    envKey: string,
    flagKey: string,
    segment: Segment,
    variation: Variation,
    onCancel: () => void,
    allTargets: List<Target>,
  ) =>
  async (dispatch: GlobalDispatch) => {
    dispatch(createSegmentStart(segment));

    try {
      const created: Segment = await segmentAPICreateSegment(projKey, envKey, segment);

      let updated: Segment = created;
      allTargets?.forEach((target) => {
        updated = updated.setIncludedTargets(target.values, target.contextKind);
      });

      updated = await segmentAPIUpdateSegment(created, updated, generateConvertToSegmentComment(flagKey));

      flushSync(() => {
        dispatch(addFlagSegmentRule(updated.key, variation));
      });

      flushSync(() => {
        dispatch(createSegmentDone(updated));
      });
      flushSync(() => {
        onCancel();
      });
      flushSync(() => {
        dispatch(convertToSegmentDone(updated));
      });
    } catch (error) {
      logger.error(`Error converting individual targets to segment: ${error}`);
      dispatch(createSegmentFailed(segment, error as ImmutableServerError));
    }
  };

export const uploadCsvToSegmentUploadStarted = () =>
  ({
    type: UPLOAD_CSV_TO_SEGMENT_UPLOAD_STARTED,
  }) as const;

export const openUploadCsvToSegmentModal = () =>
  ({
    type: UPLOAD_CSV_TO_SEGMENT_MODAL_OPENED,
  }) as const;

export const closeUploadCsvToSegmentModal = () =>
  ({
    type: UPLOAD_CSV_TO_SEGMENT_MODAL_CLOSED,
  }) as const;

export const uploadCsvToSegmentUploadFailed = (error: ImmutableServerError) =>
  ({
    type: UPLOAD_CSV_TO_SEGMENT_UPLOAD_FAILED,
    error,
  }) as const;

const getBigSegmentImportId = (response: Response) => {
  const locationHeader = response.headers?.get('Location');

  if (locationHeader) {
    return locationHeader.split('/imports/')[1];
  }

  return undefined;
};

export const uploadCsvToSegment =
  (
    projKey: string,
    envKey: string,
    segmentKey: string,
    file: File,
    uploadMode: BigSegmentUploadMode = BigSegmentUploadMode.REPLACE,
    createApprovalRequest?: (importId: string) => void,
  ) =>
  async (dispatch: GlobalDispatch) => {
    dispatch(uploadCsvToSegmentUploadStarted());
    await segmentAPIUploadCsvToSegment(projKey, envKey, segmentKey, file, uploadMode)
      .then((res) => {
        const importId = getBigSegmentImportId(res);
        if (createApprovalRequest && importId) {
          createApprovalRequest(importId);
        } else {
          dispatch(startBigSegmentImport());
          dispatch(fetchSegmentByKey(projKey, envKey, segmentKey)).catch((error) => {
            logger.error(`Error fetching segment after import request submitted: ${error}`);
          });
        }
      })
      .catch((error) => {
        logger.error(`Uploading csv to segment failed: ${error}`);
        dispatch(uploadCsvToSegmentUploadFailed(error));
      });
  };

export const fetchBigSegmentFileMetaData =
  (projKey: string, envKey: string, segmentKey: string, exportKey: string) => async (dispatch: GlobalDispatch) => {
    dispatch({ type: 'segments/REQUEST_BIG_SEGMENT_EXPORT_FILE_METADATA' });
    await segmentAPIGetBigSegmentFileMetaData(projKey, envKey, segmentKey, exportKey)
      .then((res) => {
        const data: BigSegmentFileMetaData = {
          fileName: res.get('id'),
          fileGeneratedBy: res.get('initiator').get('name'),
          fileGeneratedOn: new Date(res.get('creationTime')),
          fileSize: res.get('size'),
          downloadLink: res.get('_links').get('file').get('href'),
        };
        dispatch({ type: 'segments/RECEIVE_BIG_SEGMENT_EXPORT_FILE_METADATA', exportKey, data });
      })
      .catch((error) => {
        dispatch({ type: 'segments/REQUEST_BIG_SEGMENT_EXPORT_FILE_METADATA_FAILED', error });
      });
  };

export const bigSegmentExportStarted = () =>
  ({
    type: SEGMENT_EXPORT_STARTED,
  }) as const;

export const bigSegmentExportFailed = (error: ImmutableServerError) =>
  ({
    type: SEGMENT_EXPORT_FAILED,
    error,
  }) as const;

export const exportSegmentAsCsv =
  (projKey: string, envKey: string, segmentKey: string) => async (dispatch: GlobalDispatch) => {
    await segmentAPIPostSegmentExport(projKey, envKey, segmentKey)
      .then(() => {
        dispatch(bigSegmentExportStarted());
      })
      .catch((error) => {
        dispatch(bigSegmentExportFailed(error));
      });
  };

export const updateBigSegmentTargetsStart = () =>
  ({
    type: 'segments/UPDATE_BIG_SEGMENT_INDIVIDUAL_TARGETS',
  }) as const;

export const bigSegmentIndividualTargetsApprovalCreated = () =>
  ({
    type: 'segments/BIG_SEGMENT_INDIVIDUAL_TARGETS_APPROVAL_CREATED',
  }) as const;

export const bigSegmentIndividualTargetsApprovalFailed = (error?: Error) =>
  ({
    type: 'segments/BIG_SEGMENT_INDIVIDUAL_TARGETS_APPROVAL_FAILED',
    error: fromJS(error),
  }) as const;

export const updateBigSegmentTargetsDone = (segmentKey: string, updates: BigSegmentIndividualTargetUpdates) =>
  ({
    type: 'segments/UPDATE_BIG_SEGMENT_INDIVIDUAL_TARGETS_DONE',
    segmentKey,
    updates,
  }) as const;

export const updateBigSegmentTargetsFailed = (error: ImmutableServerError) =>
  ({
    type: 'segments/UPDATE_BIG_SEGMENT_INDIVIDUAL_TARGETS_FAILED',
    error,
  }) as const;

export const updateBigSegmentIndividualTargets =
  ({
    projKey,
    envKey,
    segmentKey,
    updates,
  }: {
    projKey: string;
    envKey: string;
    segmentKey: string;
    updates: BigSegmentIndividualTargetUpdates;
  }) =>
  async (dispatch: GlobalDispatch) => {
    dispatch(updateBigSegmentTargetsStart());
    await segmentAPIUpdateBigSegmentIndividualTargets({ projKey, envKey, segmentKey, updates })
      .then(() => {
        dispatch(updateBigSegmentTargetsDone(segmentKey, updates));
      })
      .catch((error) => {
        dispatch(updateBigSegmentTargetsFailed(error));
      });
  };

export const editBigSegmentIndividualTargets = (
  form: BigSegmentIndividualTargetUpdates,
  field: BigSegmentIndividualTargetUpdatesField,
  targetKey: string,
) => {
  const value = form.updateTargetsList(field, targetKey)[field];
  return {
    field,
    value,
    type: 'segments/EDIT_BIG_SEGMENT_INDIVIDUAL_TARGETS',
  } as const;
};

const SegmentsActionCreators = {
  filterSegmentsText,
  filterSegmentsType,
  editSegment,
  createSegmentStart,
  createSegmentDone,
  createSegmentFailed,
  deleteSegmentStart,
  deleteSegmentDone,
  deleteSegmentFailed,
  fetchSegmentsStart,
  fetchSegmentsDone,
  fetchSegmentsFailed,
  fetchSegmentByKeyStart,
  fetchSegmentByKeyDone,
  fetchSegmentByKeyFailed,
  initializeSegmentTargetingManager,
  destroySegmentTargetingManager,
  resetSegmentTargetingManager,
  setIncludedTargets,
  setExcludedTargets,
  addSegmentRule,
  deleteSegmentRule,
  addSegmentRuleClause,
  deleteSegmentRuleClause,
  editSegmentRuleClause,
  changeRuleRollout,
  changeRuleBucket,
  changeRuleIndex,
  fetchRelatedFlagsForSegmentStart,
  fetchRelatedFlagsForSegmentDone,
  fetchRelatedFlagsForSegmentFailed,
  convertToSegmentDone,
  startBigSegmentImport,
  uploadCsvToSegmentUploadFailed,
  closeUploadCsvToSegmentModal,
  openUploadCsvToSegmentModal,
  bigSegmentExportStarted,
  editBigSegmentIndividualTargets,
  updateBigSegmentTargetsStart,
  updateBigSegmentTargetsDone,
  updateBigSegmentTargetsFailed,
};
export type SegmentsAction = GenerateActionType<typeof SegmentsActionCreators>;
