import { LegacySubscription } from '../../types';

import { getTrialDaysRemaining } from './getTrialDaysRemaining';
import { hasSubscription } from './hasSubscription';
import { isExpiredTrialWithNoPlan } from './isExpiredTrialWithNoPlan';

export function getTrialStatusInfo(legacySubscription: LegacySubscription) {
  return {
    isTrialExpired: isExpiredTrialWithNoPlan(legacySubscription),
    trialDaysRemaining: getTrialDaysRemaining(legacySubscription),
    isSubscribed: hasSubscription(legacySubscription),
  };
}
