import { createConstantNamespace } from 'utils/reduxUtils';

const ns = createConstantNamespace('usage');

export const REQUEST_USAGE_DATA = ns('REQUEST_USAGE_DATA');
export const REQUEST_USAGE_DATA_DONE = ns('REQUEST_USAGE_DATA_DONE');
export const REQUEST_USAGE_DATA_FAILED = ns('REQUEST_USAGE_DATA_FAILED');

export const REQUEST_SDK_VERSIONS = ns('REQUEST_SDK_VERSIONS');
export const REQUEST_SDK_VERSIONS_DONE = ns('REQUEST_SDK_VERSIONS_DONE');
export const REQUEST_SDK_VERSIONS_FAILED = ns('REQUEST_SDK_VERSIONS_FAILED');

export const REQUEST_MAU_SDKS = ns('REQUEST_MAU_SDKS');
export const REQUEST_MAU_SDKS_DONE = ns('REQUEST_MAU_SDKS_DONE');
export const REQUEST_MAU_SDKS_FAILED = ns('REQUEST_MAU_SDKS_FAILED');

export const REQUEST_RELAY_METRICS = ns('REQUEST_RELAY_METRICS');
export const REQUEST_RELAY_METRICS_DONE = ns('REQUEST_RELAY_METRICS_DONE');
export const REQUEST_RELAY_METRICS_FAILED = ns('REQUEST_RELAY_METRICS_FAILED');
