import { useState } from 'react';
import { useFocus, useFocusVisible, useHover } from 'react-aria';
import { Breadcrumb as Breadcrumb_, Breadcrumbs as Breadcrumbs_ } from 'react-aria-components';
import { useMatches } from 'react-router-dom';
import { useTextOverflow } from '@gonfalon/dom';
import { Link, Tooltip, TooltipTrigger } from '@launchpad-ui/components';
import { z } from 'zod';

import styles from './Breadcrumbs.module.css';

const breadcrumbSchema = z.object({
  breadcrumb: z.union([z.string(), z.function().args(z.any()).returns(z.string())]),
});

type Item = {
  // id is used by react-aria-components under the hood
  // eslint-disable-next-line react/no-unused-prop-types
  id: string;
  text: string;
  href: string;
};

export function Breadcrumbs({}: {}) {
  const matches = useMatches();

  const crumbs: Item[] = [];
  for (const match of matches) {
    const parse = breadcrumbSchema.safeParse(match.handle);
    if (!parse.success) {
      continue;
    }

    const breadcrumb = parse.data.breadcrumb;
    let text;
    if (typeof breadcrumb === 'function') {
      if (!match.data) {
        continue;
      }
      text = breadcrumb({ data: match.data });
    } else {
      text = breadcrumb;
    }
    crumbs.push({
      id: match.id,
      text,
      href: match.pathname,
    });
  }

  return (
    <Breadcrumbs_ items={crumbs} className={styles.breadcrumbs}>
      {(item) => <Breadcrumb {...item} />}
    </Breadcrumbs_>
  );
}

function Breadcrumb(item: Item) {
  const { ref, hasOverflow, props: overflowProps } = useTextOverflow<HTMLSpanElement>();
  const { hoverProps, isHovered } = useHover({});
  const [isFocused, setFocused] = useState(false);
  const { focusProps } = useFocus({
    onFocus: () => setFocused(true),
    onBlur: () => setFocused(false),
  });
  const { isFocusVisible } = useFocusVisible();

  return (
    <Breadcrumb_ className={styles.crumb}>
      <TooltipTrigger isDisabled={!hasOverflow} isOpen={hasOverflow && (isHovered || (isFocusVisible && isFocused))}>
        {/* @ts-expect-error react-aria-components and react-aria are in disagreement about the event type here, but it's legit. */}
        <Link href={item.href} {...focusProps}>
          <span {...overflowProps} {...hoverProps} ref={ref}>
            {item.text}
          </span>
        </Link>
        <Tooltip triggerRef={ref} placement="bottom start">
          {item.text}
        </Tooltip>
      </TooltipTrigger>
    </Breadcrumb_>
  );
}
