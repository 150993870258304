import { getTrialStatusInfo as getLegacyTrialStatusInfo } from '../internal/legacySubscription';
import { getTrialStatusInfo as getModernTrialStatusInfo } from '../internal/modernSubscription';
import { Subscription } from '../types';

import { isModernSubscription } from './isModernSubscription';

export function getTrialStatusInfo(subscription: Subscription) {
  if (isModernSubscription(subscription)) {
    return getModernTrialStatusInfo(subscription);
  }

  return getLegacyTrialStatusInfo(subscription);
}
