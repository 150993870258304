import { type ForwardedRef, forwardRef } from 'react';
import { type ButtonProps, Button as Button_, composeRenderProps } from 'react-aria-components';
import clsx from 'clsx';

import styles from './TextButton.module.css';

type Props = Omit<ButtonProps, 'disabled'> & {
  size?: 'small' | 'body';
};

export const TextButton = forwardRef(
  ({ className, children, size, ...props }: Props, ref: ForwardedRef<HTMLButtonElement>) => (
    <Button_
      {...props}
      className={composeRenderProps(className, (c) =>
        clsx(c, styles.button, {
          [styles.small]: size === 'small',
          [styles.body]: size === 'body',
        }),
      )}
      ref={ref}
    >
      {children}
    </Button_>
  ),
);
