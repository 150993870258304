import { Component as ReactComponent, ComponentType } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';

import CouldNotConnect from 'components/CouldNotConnect';
import { currentEnvironmentSelector, currentProjectSelector } from 'reducers/projects';
import { Environment } from 'utils/environmentUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import { Project } from 'utils/projectUtils';
import { ready } from 'utils/reduxUtils';

const mapStateToProps = createSelector(currentProjectSelector, currentEnvironmentSelector, (project, environment) => {
  const doNotFetch = project.get('doNotFetch') || environment.get('doNotFetch');
  return {
    localIsReady: ready(environment) || !!doNotFetch,
    project: project.get('entity'),
    environment: environment.get('entity'),
    error: project.get('error') || environment.get('error'),
  };
});

type WithProjectAndEnvironmentProps = {
  localIsReady: boolean;
  project?: Project;
  environment?: Environment;
  error?: ImmutableServerError;
};

type EnhancerProps = {
  project: Project;
  environment: Environment;
};

type ErrorComponentProps = {
  error: ImmutableServerError;
};

export const withProjectAndEnvironment = <PassedComponentProps extends object = {}>(
  Component: ComponentType<PassedComponentProps & EnhancerProps>,
  ErrorComponent: ComponentType<ErrorComponentProps>,
) =>
  class WithProjectAndEnvironment extends ReactComponent<PassedComponentProps & WithProjectAndEnvironmentProps> {
    render() {
      const { localIsReady: isReady, project, environment, error, ...props } = this.props;
      const FinalErrorComponent = ErrorComponent || CouldNotConnect;

      if (!isReady) {
        return null;
      }

      return error ? (
        <FinalErrorComponent error={error} />
      ) : (
        <Component
          {...(props as PassedComponentProps)}
          project={
            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            project! /* eslint-enable @typescript-eslint/no-non-null-assertion */
          }
          environment={
            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            environment! /* eslint-enable @typescript-eslint/no-non-null-assertion */
          }
        />
      );
    }
  };

const defaultExport: <P = {}>(
  c: ComponentType<P>,
  e?: ComponentType<ErrorComponentProps>,
) => ComponentType<Omit<P, keyof EnhancerProps>> = compose(connect(mapStateToProps), withProjectAndEnvironment);
/* eslint-disable import/no-default-export */
export default defaultExport;
