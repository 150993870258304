import { GetEventActivityByProjectQueryParams } from '@gonfalon/openapi';
import { getMetricEventsQuery } from '@gonfalon/rest-api';
import { useSuspenseQuery } from '@tanstack/react-query';

export const useSuspenseEventActivityByProj = (projectKey: string, query: GetEventActivityByProjectQueryParams) =>
  useSuspenseQuery(
    getMetricEventsQuery({
      projectKey,
      query,
    }),
  );
