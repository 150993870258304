import { schemas } from '@gonfalon/openapi';
import { QueryFunctionContext } from '@tanstack/react-query';

import httpUtils from 'utils/httpUtils';

type TagCollection = schemas['TagCollection'];

export const getTagsQuery = (kind = 'flag', limit = 50, prefix = '', staleTime: number = Infinity) => ({
  queryKey: ['tags', kind, prefix, limit],
  queryFn: async ({ signal }: QueryFunctionContext) => {
    const url = new URL('api/v2/tags', location.origin);

    const searchParams = new URLSearchParams();
    searchParams.set('pre', prefix);
    searchParams.set('kind', kind);
    searchParams.set('limit', String(limit));

    url.search = searchParams.toString();

    return (await httpUtils.get(url.toString(), { signal })).json() as Promise<TagCollection>;
  },
  staleTime,
});
