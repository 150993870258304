import { constants } from 'utils/reduxUtils';

/**
 * Action type to be used when an experiment is either created or updated through the new experiment flow
 */
const EXPERIMENT_SAVED_DONE = 'EXPERIMENT_SAVED_DONE';

/**
 * Action type to be used when an experiment save or update fails through the new experiment flow
 */
const EXPERIMENT_SAVED_FAILED = 'EXPERIMENT_SAVED_FAILED';

/**
 * Action type to be used when an experiment is successfully archived
 */
const EXPERIMENT_ARCHIVE_DONE = 'EXPERIMENT_ARCHIVE_DONE';

/**
 * Action type to be used when an experiment fails to archive
 */
const EXPERIMENT_ARCHIVE_FAILED = 'EXPERIMENT_ARCHIVE_FAILED';

/**
 * Action type to be used when an experiment is successfully restored
 */
const EXPERIMENT_RESTORE_DONE = 'EXPERIMENT_RESTORE_DONE';

/**
 * Action type to be used when an experiment fails to restore
 */
const EXPERIMENT_RESTORE_FAILED = 'EXPERIMENT_RESTORE_FAILED';

/* eslint-disable import/no-default-export */
/**
 * Actions are related to the new experimentation flow
 */
export default constants('experimentation', [
  EXPERIMENT_SAVED_DONE,
  EXPERIMENT_SAVED_FAILED,
  EXPERIMENT_ARCHIVE_DONE,
  EXPERIMENT_ARCHIVE_FAILED,
  EXPERIMENT_RESTORE_DONE,
  EXPERIMENT_RESTORE_FAILED,
]);
