import { OrderedMap } from 'immutable';

import { Member } from 'utils/accountUtils';
import { GenerateActionType } from 'utils/reduxUtils';

export const saveSelectedMembersAction = (members: OrderedMap<string, Member>) =>
  ({ type: 'selectMember/SELECT_MEMBERS', members }) as const;

export const selectMemberSearchAction = (searchValue: string) =>
  ({ type: 'selectMember/SEARCH', searchValue }) as const;

export const loadSelectedMembersAction = (members: OrderedMap<string, Member>) =>
  ({
    type: 'selectMember/LOADED',
    members,
  }) as const;

const SelectMemberActionCreators = {
  loadSelectedMembersAction,
  saveSelectedMembersAction,
  selectMemberSearchAction,
};

export type SelectMemberAction = GenerateActionType<typeof SelectMemberActionCreators>;
