import { type EnvironmentAware } from './types';

export function makeEnvironmentAwareSearchParamsSerializer<T extends Object>(
  serialize: (t: T) => URLSearchParams,
): (t: EnvironmentAware<T>) => URLSearchParams {
  return (t: EnvironmentAware<T>) => {
    const searchParams = serialize(t);
    if (t.selectedEnvironmentKey) {
      searchParams.set('selected-env', t.selectedEnvironmentKey);
    }
    return searchParams;
  };
}
