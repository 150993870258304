import { InviteMembersModalAction } from 'actions/inviteMembersModal';
import { InviteMembersModalProps } from 'components/inviteMembers/InviteMembersModal';
import { GlobalState } from 'reducers';
import registry from 'reducers/registry';

type InviteMembersModalState = {
  showModal: boolean;
  props: InviteMembersModalProps;
};

export const initialState: InviteMembersModalState = {
  showModal: false,
  props: { referrer: '' },
};

export function inviteMembersModal(state: InviteMembersModalState = initialState, action: InviteMembersModalAction) {
  switch (action.type) {
    case 'inviteMembersModal/OPEN_MODAL':
      return { ...state, showModal: true, props: action.props };
    case 'inviteMembersModal/CLOSE_MODAL':
      return { ...state, showModal: false, props: { referrer: '' } };
    default:
      return state;
  }
}

export const inviteMembersModalSelector = (state: GlobalState) => state.inviteMembersModal;

registry.addReducers({ inviteMembersModal });
