import nullthrows from 'nullthrows';

import { isSeatLimitReached as isLegacySubscriptionSeatLimitReached } from '../internal/legacySubscription';
import { isSeatLimitReached as isModernSubscriptionSeatLimitReached } from '../internal/modernSubscription';
import { Subscription, SubscriptionUsage } from '../types';

import { isModernSubscription } from './isModernSubscription';

export function isSeatLimitReached(subscription: Subscription, subscriptionUsage?: SubscriptionUsage) {
  if (isModernSubscription(subscription)) {
    return isModernSubscriptionSeatLimitReached(
      subscription,
      nullthrows(subscriptionUsage, 'subscriptionUsage is required for modern subscriptions'),
    );
  }

  return isLegacySubscriptionSeatLimitReached(subscription);
}
