import { ReactNode } from 'react';
import cx from 'clsx';

import 'stylesheets/components/AppHeader.css';

type AppHeaderProps = {
  breadcrumbs?: ReactNode;
  presence?: ReactNode;
  className?: string;
  children: ReactNode;
  hasDescription?: boolean;
  isSeamless?: boolean;
};

/* eslint-disable import/no-default-export */
export default function AppHeader(props: AppHeaderProps) {
  const { breadcrumbs, presence, className, children, hasDescription, isSeamless = false } = props;
  const classes = cx(isSeamless ? 'AppHeader--seamless' : 'AppHeader', className, {
    'AppHeader--hasDescription': hasDescription && !breadcrumbs,
  });
  const shouldRenderTopline = Boolean(props.breadcrumbs || props.presence);

  return (
    <header className={classes}>
      {shouldRenderTopline && (
        <div className="AppHeader-topline">
          {breadcrumbs}
          {presence}
        </div>
      )}
      <div
        className={cx('AppHeader-main', { 'AppHeader-main--hasDescription': hasDescription })}
        data-test-id="app-header"
      >
        {children}
      </div>
    </header>
  );
}

export type SimpleAppHeaderProps = {
  title: string;
  breadcrumbs?(): JSX.Element;
  description?: string | JSX.Element;
  className?: string;
  isSeamless?: boolean;
};

export function SimpleAppHeader(props: SimpleAppHeaderProps) {
  const { title, breadcrumbs: Breadcrumbs, description, className, isSeamless = false } = props;
  return (
    <AppHeader hasDescription={!!description} className={className} isSeamless={isSeamless}>
      {Breadcrumbs && <Breadcrumbs />}
      <h1>{title}</h1>
      {description && <p>{description}</p>}
    </AppHeader>
  );
}
