import { evaluationDateSchema } from './types';

export function parseEvaluationDate(value: unknown) {
  const result = evaluationDateSchema.safeParse(value);

  if (!result.success) {
    return undefined;
  }

  return result.data;
}
