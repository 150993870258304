import { has, isEmpty } from '@gonfalon/es6-utils';
import { FlagFilterStateKind } from '@gonfalon/flags';
import { capitalize } from '@gonfalon/strings';

import { FilterKind, FilterValue } from 'components/ui/multipleFilters/types';

import FlagFilterInterface, { BackendQueryParamName, QueryParamName } from './flagFilterInterface';
import { FilterOperator, FlagListBackendQueryParam, FlagListQueryParam } from './types';

const flagStateOptionDisplayNames: Record<FlagFilterStateKind, string> = {
  live: 'Live flags (default)',
  deprecated: 'Deprecated flags',
  archived: 'Archived flags',
};

export const isStateFilter = (filterKind: string) => filterKind === FilterKind.STATE;

export class StateFilter implements FlagFilterInterface<string> {
  name: string;
  value?: string;
  kind: FilterKind;
  queryParamName: QueryParamName;
  backendQueryParamName: BackendQueryParamName;
  operators: string[];
  defaultValue: string;

  constructor(value?: string) {
    this.name = 'View';
    this.value = value;
    this.kind = FilterKind.STATE;
    this.queryParamName = FlagListQueryParam.STATE;
    this.backendQueryParamName = FlagListBackendQueryParam.STATE;
    this.operators = [FilterOperator.IS];
    this.defaultValue = 'live';
  }

  getFilterValueDisplayName(value?: FilterValue) {
    if (!value) {
      return capitalize(this.defaultValue);
    }

    return capitalize(value as string);
  }

  getDisplayName = () => this.name;

  getOptions() {
    return Object.entries(flagStateOptionDisplayNames).map(([value, label]) => {
      const isDefaultValue = value === 'live' && !this.value;
      return {
        label,
        name: label,
        value,
        filterOption: this.kind,
        isChecked: isDefaultValue || value === this.value,
      };
    });
  }

  isEnabled = () => true;

  isEmpty = () => isEmpty(this.value);

  toQuery = () => this.value;

  isInvalidFilter = () =>
    !this.isEmpty() &&
    !(
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      has(flagStateOptionDisplayNames, this.value!)
    ) /* eslint-enable @typescript-eslint/no-non-null-assertion */;

  clearFilter = () => (this.value = undefined);

  toBackendQuery() {
    if (this.isEmpty()) {
      return '';
    }
    return `state:${this.value}`;
  }

  parseFilterParam(queryParts: string[]) {
    const [, value] = queryParts;
    return value;
  }

  getFilterData() {
    return {
      operators: this.operators,
      options: this.getOptions(),
      defaultValue: this.defaultValue,
    };
  }
}
