import { noop } from '@gonfalon/es6-utils';
// eslint-disable-next-line no-restricted-imports
import { fromJS } from 'immutable';

import { GlobalDispatch } from 'reducers';
// eslint-disable-next-line import/no-namespace
import * as CustomWorkflowsAPI from 'sources/CustomWorkflowsAPI';
import { CustomWorkflowsResponseRecord } from 'sources/CustomWorkflowsAPI';
import { UrlProps } from 'sources/types/utils';
import { CustomWorkflow, CustomWorkflowExecutionStatusType, WorkflowsSortOrder } from 'utils/customWorkflowUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import { GenerateActionType } from 'utils/reduxUtils';

const requestCustomWorkflowsByStatus = (status: CustomWorkflowExecutionStatusType) =>
  ({ type: 'customWorkflows/REQUEST_CUSTOM_WORKFLOWS_BY_STATUS', status }) as const;

const receiveCustomWorkflowsByStatus = (
  response: CustomWorkflowsResponseRecord,
  status: CustomWorkflowExecutionStatusType,
  flagKey: string,
) => ({ type: 'customWorkflows/RECEIVE_CUSTOM_WORKFLOWS_BY_STATUS', response, status, flagKey }) as const;

const requestCustomWorkflowsByStatusFailed = (error: ImmutableServerError, status: CustomWorkflowExecutionStatusType) =>
  ({ type: 'customWorkflows/REQUEST_CUSTOM_WORKFLOWS_BY_STATUS_FAILED', error, status }) as const;

const requestCompletedCustomWorkflows = () => ({ type: 'customWorkflows/REQUEST_COMPLETED_CUSTOM_WORKFLOWS' }) as const;

const receiveCompletedCustomWorkflows = (response: CustomWorkflowsResponseRecord) =>
  ({ type: 'customWorkflows/RECEIVE_COMPLETED_CUSTOM_WORKFLOWS', response }) as const;

const requestCompletedCustomWorkflowsFailed = (error: ImmutableServerError) =>
  ({ type: 'customWorkflows/REQUEST_COMPLETED_CUSTOM_WORKFLOWS_FAILED', error }) as const;

const requestActiveCustomWorkflows = () => ({ type: 'customWorkflows/REQUEST_ACTIVE_CUSTOM_WORKFLOWS' }) as const;

const receiveActiveCustomWorkflows = (response: CustomWorkflowsResponseRecord) =>
  ({ type: 'customWorkflows/RECEIVE_ACTIVE_CUSTOM_WORKFLOWS', response }) as const;

const requestActiveCustomWorkflowsFailed = (error: ImmutableServerError) =>
  ({ type: 'customWorkflows/REQUEST_ACTIVE_CUSTOM_WORKFLOWS_FAILED', error }) as const;

export function fetchCustomWorkflowsByStatus({
  projKey,
  envKey,
  flagKey,
  status = CustomWorkflowExecutionStatusType.ACTIVE,
}: {
  projKey: string;
  envKey: string;
  flagKey: string;
  status?: CustomWorkflowExecutionStatusType;
}) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestCustomWorkflowsByStatus(status));
    const sort = status === CustomWorkflowExecutionStatusType.COMPLETED ? WorkflowsSortOrder.STOP_DATE_DESC : undefined;
    return CustomWorkflowsAPI.getCustomWorkflowsbyStatus(projKey, envKey, flagKey, status, sort)
      .then((response) => {
        dispatch(receiveCustomWorkflowsByStatus(response, status, flagKey));
      })
      .catch((error) => {
        dispatch(requestCustomWorkflowsByStatusFailed(error, status));
      });
  };
}

const requestCustomWorkflowById = () => ({ type: 'customWorkflows/REQUEST_CUSTOM_WORKFLOW_BY_ID' }) as const;
const receiveCustomWorkflowById = (response: CustomWorkflow) =>
  ({
    type: 'customWorkflows/RECEIVE_CUSTOM_WORKFLOW_BY_ID',
    response,
  }) as const;
const requestCustomWorkflowByIdFailed = (error: ImmutableServerError) =>
  ({
    type: 'customWorkflows/REQUEST_CUSTOM_WORKFLOW_BY_ID_FAILED',
    error,
  }) as const;

export const fetchCustomWorkflowByIdAction =
  ({ projKey, envKey, flagKey, workflowId }: UrlProps & { workflowId: string }) =>
  async (dispatch: GlobalDispatch) => {
    dispatch(requestCustomWorkflowById());
    try {
      const workflow = await CustomWorkflowsAPI.getCustomWorkflowById(projKey, envKey, flagKey, workflowId);
      return dispatch(receiveCustomWorkflowById(workflow));
    } catch (err) {
      return dispatch(requestCustomWorkflowByIdFailed(err as ImmutableServerError));
    }
  };

const createCustomWorkflowStart = (customWorkflow: CustomWorkflow) =>
  ({ type: 'customWorkflows/CREATE_CUSTOM_WORKFLOW', customWorkflow }) as const;

const createCustomWorkflowDone = (customWorkflow: CustomWorkflow) =>
  ({ type: 'customWorkflows/CREATE_CUSTOM_WORKFLOW_DONE', customWorkflow }) as const;

export const createCustomWorkflowNotify = (payload: { customWorkflow: CustomWorkflow } & UrlProps) =>
  ({ type: 'customWorkflows/CREATE_CUSTOM_WORKFLOW_DONE_NOTIFY', payload }) as const;

const createCustomWorkflowFailed = (customWorkflow: CustomWorkflow, error: ImmutableServerError) =>
  ({ type: 'customWorkflows/CREATE_CUSTOM_WORKFLOW_FAILED', customWorkflow, error }) as const;

// TODO: Use form state to create CustomWorkflow
export function createCustomWorkflow(projKey: string, flagKey: string, envKey: string, customWorkflow: CustomWorkflow) {
  return async (dispatch: GlobalDispatch): Promise<CustomWorkflow | void> => {
    dispatch(createCustomWorkflowStart(customWorkflow));
    return CustomWorkflowsAPI.createNewCustomWorkflow(projKey, flagKey, envKey, customWorkflow)
      .then((createdCustomWorkflow: CustomWorkflow) => {
        dispatch(createCustomWorkflowDone(createdCustomWorkflow));

        dispatch(
          fetchCustomWorkflowsByStatus({
            projKey,
            envKey,
            flagKey,
            status: CustomWorkflowExecutionStatusType.ACTIVE,
          }),
        ).catch(noop);

        return createdCustomWorkflow;
      })
      .catch((e) => {
        dispatch(
          createCustomWorkflowFailed(
            customWorkflow,
            fromJS({
              status: e.get('status'),
              // Pass through permission errors so that the user has some idea what's going on.
              message: e.get('status') === 403 ? e.get('message') : 'There was a problem creating your workflow.',
            }),
          ),
        );
      });
  };
}

const deleteCustomWorkflow = (customWorkflow: CustomWorkflow) =>
  ({ type: 'customWorkflows/DELETE_CUSTOM_WORKFLOW', customWorkflow }) as const;

const deleteCustomWorkflowDone = (customWorkflow: CustomWorkflow) =>
  ({ type: 'customWorkflows/DELETE_CUSTOM_WORKFLOW_DONE', customWorkflow }) as const;

const deleteCustomWorkflowFailed = (customWorkflow: CustomWorkflow, error: ImmutableServerError) =>
  ({ type: 'customWorkflows/DELETE_CUSTOM_WORKFLOW_FAILED', customWorkflow, error }) as const;

export function removeCustomWorkflow({
  projKey,
  flagKey,
  envKey,
  customWorkflow,
}: {
  projKey: string;
  flagKey: string;
  envKey: string;
  customWorkflow: CustomWorkflow;
}) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(deleteCustomWorkflow(customWorkflow));
    return CustomWorkflowsAPI.deleteCustomWorkflow(projKey, flagKey, envKey, customWorkflow.getId())
      .then(() => {
        dispatch(deleteCustomWorkflowDone(customWorkflow));
        if (customWorkflow._execution.status === CustomWorkflowExecutionStatusType.ACTIVE) {
          dispatch(
            fetchCustomWorkflowsByStatus({
              projKey,
              envKey,
              flagKey,
              status: CustomWorkflowExecutionStatusType.ACTIVE,
            }),
          ).catch(noop);
        } else {
          dispatch(
            fetchCustomWorkflowsByStatus({
              projKey,
              envKey,
              flagKey,
              status: CustomWorkflowExecutionStatusType.COMPLETED,
            }),
          ).catch(noop);
        }
      })
      .catch((error) => dispatch(deleteCustomWorkflowFailed(customWorkflow, error)));
  };
}

const CustomWorkflowsActionCreators = {
  requestCustomWorkflowsByStatus,
  receiveCustomWorkflowsByStatus,
  requestCustomWorkflowsByStatusFailed,
  requestCompletedCustomWorkflows,
  receiveCompletedCustomWorkflows,
  requestCompletedCustomWorkflowsFailed,
  requestActiveCustomWorkflows,
  receiveActiveCustomWorkflows,
  requestActiveCustomWorkflowsFailed,
  requestCustomWorkflowById,
  receiveCustomWorkflowById,
  requestCustomWorkflowByIdFailed,
  createCustomWorkflow: createCustomWorkflowStart,
  createCustomWorkflowDone,
  createCustomWorkflowFailed,
  deleteCustomWorkflow,
  deleteCustomWorkflowDone,
  deleteCustomWorkflowFailed,
};

export type CustomWorkflowsAction = GenerateActionType<typeof CustomWorkflowsActionCreators>;
