import { getSeatLimit as getLegacySubscriptionSeatLimit } from '../internal/legacySubscription';
import { getSeatLimit as getModernSubscriptionSeatLimit } from '../internal/modernSubscription';
import { Subscription } from '../types';

import { isModernSubscription } from './isModernSubscription';

export function getSeatLimit(subscription: Subscription) {
  if (isModernSubscription(subscription)) {
    return getModernSubscriptionSeatLimit(subscription);
  }

  return getLegacySubscriptionSeatLimit(subscription);
}
