import type { Selection } from 'react-aria-components';

import { ensureChangeHistoryItem } from './ensureChangeHistoryItem';
import { ChangeHistoryPreferences } from './schema';

export function parsePreferencesFromSelection(selection: Selection) {
  const preferences: ChangeHistoryPreferences = {};

  for (const item of selection) {
    try {
      preferences[ensureChangeHistoryItem(item)] = true;
    } catch (error) {
      // ignore unknown items
    }
  }

  return preferences;
}
