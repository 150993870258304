import { hasMeasuredRolloutRegressed } from './hasMeasuredRolloutRegressed';
import { MeasuredRollout } from './types';
export function hasMeasuredRolloutSummaryStatus(measuredRollout: MeasuredRollout) {
  switch (measuredRollout.state.status) {
    case 'monitoring':
      return true;
    case 'reverted':
    case 'manually_reverted':
    case 'monitoring_regressed':
      return hasMeasuredRolloutRegressed(measuredRollout);
    default:
      return false;
  }
}
