import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useDispatch, useSelector } from 'react-redux';
import { isSeatLimitReached } from '@gonfalon/billing';
import { hasLegacyPlan as _hasLegacyPlan } from '@gonfalon/constants';
import {
  enableEnterpriseSeatExpansions as enableEnterpriseSeatExpansionsContext,
  enableNewInviteMembersPermissionsLogic,
  isEmailVerificationRequired,
  isSeatLimitEnforced as _isSeatLimitEnforced,
  isSSOEnabled,
  isTeamsEnabled,
} from '@gonfalon/dogfood-flags';
import { List } from 'immutable';

import {
  fetchLegacySubscription as fetchLegacySubscriptionAction,
  fetchSubscription as fetchSubscriptionAction,
  fetchSubscriptionUsage as fetchSubscriptionUsageAction,
} from 'actions/billing';
import { fetchProfile as fetchProfileAction } from 'actions/profile';
import { fetchSamlConfig as fetchSamlConfigAction } from 'actions/sso';
import { legacySubscriptionSelector, subscriptionSelector, subscriptionUsageSelector } from 'reducers/billing';
import { bulkInvitedMembersSelector } from 'reducers/members';
import { profileSelector } from 'reducers/profile';
import { samlConfigSelector } from 'reducers/samlConfig';
import { Member } from 'utils/accountUtils';
import { ready, RequestState } from 'utils/reduxUtils';

const useRedux = () => {
  const legacySubscription = useSelector(legacySubscriptionSelector);
  const subscriptionUsage = useSelector(subscriptionUsageSelector);
  const subscription = useSelector(subscriptionSelector);
  const samlConfig = useSelector(samlConfigSelector);
  const profile = useSelector(profileSelector);
  const profileEntity = profile?.get('entity');
  const enableTeams = isTeamsEnabled();
  const emailVerificationRequired = isEmailVerificationRequired();
  const enableEnterpriseSeatExpansions = enableEnterpriseSeatExpansionsContext();
  const bulkInvitedMembers = useSelector(bulkInvitedMembersSelector);
  const invitedMembers = bulkInvitedMembers?.get('members');
  const dispatch = useDispatch();
  const fetchSubscriptionUsage = () => {
    dispatch(fetchSubscriptionUsageAction());
  };
  const fetchLegacySubscription = () => {
    dispatch(fetchLegacySubscriptionAction());
  };

  const fetchSubscription = () => {
    dispatch(fetchSubscriptionAction());
  };

  const fetchSamlConfig = () => dispatch(fetchSamlConfigAction());

  const fetchProfile = () => {
    dispatch(fetchProfileAction());
  };

  let resources: Array<RequestState | undefined> = [];
  if (isSSOEnabled()) {
    resources.push(samlConfig.request);
  }
  if (_isSeatLimitEnforced()) {
    if (_hasLegacyPlan()) {
      resources.push(legacySubscription);
    } else {
      resources = resources.concat([subscription.fetchRequest, subscriptionUsage.fetchRequest]);
    }
  }

  return {
    isReady: ready(...resources),
    isSeatLimitEnforced: _isSeatLimitEnforced(),
    hasLegacyPlan: !!_hasLegacyPlan(),
    profileEntity,
    samlConfig,
    legacySubscription,
    subscription,
    subscriptionUsage,
    enableTeams,
    invitedMembers,
    emailVerificationRequired,
    fetchSubscriptionUsage,
    fetchLegacySubscription,
    fetchSubscription,
    fetchSamlConfig,
    fetchProfile,
    enableEnterpriseSeatExpansions,
  };
};

export function useInviteMembersOption(): {
  canInviteMembers: boolean;
  invitedMembers?: List<Member>;
  expiredTrialOrCanceled: boolean;
  renderRequestSeats: boolean;
  shouldDisableButton: boolean;
} {
  const {
    isSeatLimitEnforced,
    hasLegacyPlan,
    isReady,
    samlConfig,
    legacySubscription,
    subscription,
    subscriptionUsage,
    invitedMembers,
    enableTeams,
    profileEntity,
    emailVerificationRequired,
    fetchSubscriptionUsage,
    fetchLegacySubscription,
    fetchSubscription,
    fetchSamlConfig,
    fetchProfile,
    enableEnterpriseSeatExpansions,
  } = useRedux();

  useEffect(() => {
    if (!isReady) {
      fetchSamlConfig();
      fetchProfile();
      if (isSeatLimitEnforced) {
        if (hasLegacyPlan) {
          fetchLegacySubscription();
        } else {
          fetchSubscription();
          fetchSubscriptionUsage();
        }
      }
    }
  }, []);

  const hasSSO = samlConfig?.entity?.isEnabled();

  const atSeatLimit = isSeatLimitReached(
    hasLegacyPlan ? legacySubscription?.get('entity').toJS() : subscription.entity.toJS(),
    subscriptionUsage.entity.toJS(),
  );

  const isEmailVerificationPending = emailVerificationRequired && !profileEntity?.isVerified();

  const isSubscriptionAnExpiredTrialOrCanceled =
    subscription?.entity?.isExpiredTrialOrCanceled() ||
    (hasLegacyPlan && legacySubscription?.get('entity')?.isExpiredTrialOrCanceled());

  const isEnterprise = subscription?.entity?.isEnterprise();

  const renderRequestSeats = isEnterprise && !!atSeatLimit && enableEnterpriseSeatExpansions;
  /*
   Users that can invite members:
   - Non SSO accounts
   - Verified profiles with admin rights
   - Accounts that have teams enabled
   ** For old plans only :
   - Accounts that have not reached seat limit
  */

  const profileCanInviteMembers = enableNewInviteMembersPermissionsLogic()
    ? profileEntity.hasCreateMemberRights(profileEntity)
    : profileEntity.hasAdminRights();

  const requiredConditionsForInviting = [
    profileCanInviteMembers,
    !hasSSO,
    !isEmailVerificationPending,
    !subscription?.entity.is2021Plan() ? !atSeatLimit : true,
    enableTeams,
  ];

  const shouldDisableButton = hasSSO || isSubscriptionAnExpiredTrialOrCanceled;

  return {
    renderRequestSeats,
    invitedMembers,
    shouldDisableButton,
    canInviteMembers: requiredConditionsForInviting.every((c) => !!c),
    expiredTrialOrCanceled: isSubscriptionAnExpiredTrialOrCanceled,
  };
}
