import { Component } from 'react';
import { connect } from 'react-redux';
import { CustomCreatable, StylesObject } from '@gonfalon/launchpad-experimental';

import { fetchTags } from 'actions/tags';
import { GlobalState } from 'reducers';
import { tagsSelector } from 'reducers/tags';

import { TagKind } from './tagFilter/types';

// NOTE: DO NOT USE THIS ON ITS OWN. USE THE SELECTTAGS COMPONENT.
// THIS IS JUST HERE TO ENSURE A SAFE CUTOVER BETWEEN THE NEW REACT QUERY STUFF
// AND THE OLD REDUX STUFF.

type TagOption = {
  value: string;
  label: string;
};

export type SelectTagsContainerProps = {
  value: string[];
  kind: TagKind;
  name: string;
  placeholder?: string;
  styles?: StylesObject | undefined;
  ariaLabel?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?(tags: string[]): void;
};

type SelectTagsProps = SelectTagsContainerProps & {
  completions?: string[];
  isLoading?: boolean;
  onInputChange?(q: string, kind: TagKind): void;
};

class SelectTags extends Component<SelectTagsProps> {
  componentDidMount() {
    this.handleInputChange('');
  }

  render() {
    const { value = [], completions = [], isLoading, ...props } = this.props;
    const options = completions.concat(value).map((t) => ({ value: t, label: t }));

    // joinValues and storing the value as a string rather than an array is
    // needed so that react-select can compare the values as it uses !=== on
    // the value prop, which does not work with arrays
    return (
      <CustomCreatable<TagOption>
        {...props}
        isMulti
        value={value.map((d) => ({ value: d, label: d }))}
        options={options}
        isLoading={isLoading}
        formatCreateLabel={this.formatCreateLabel}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
      />
    );
  }

  formatCreateLabel = (tag: string) => `Add tag "${tag}"`;

  handleInputChange = (input: string) => {
    if (!this.props.onInputChange) {
      return input;
    }

    this.props.onInputChange(input, this.props.kind);
    return input;
  };

  handleChange = (selectedTags: TagOption[]) => {
    const tags = new Set(selectedTags.map((t) => t.value.trim()));

    if (!this.props.onChange) {
      return;
    }

    this.props.onChange(Array.from(tags));
  };
}

const SelectTagsContainer = connect(
  (state: GlobalState) => {
    const tags = tagsSelector(state);
    const completions = tags.get('entities');
    const isLoading = tags.get('isFetching');
    return {
      completions: completions?.toArray() ?? [],
      isLoading,
    };
  },
  (dispatch) => ({
    onInputChange: (q: string, kind: TagKind) => {
      dispatch(fetchTags(q, kind));
    },
  }),
)(SelectTags);

export { SelectTagsContainer as OldSelectTagsComponent };
