import { createConstantNamespace } from 'utils/reduxUtils';

const ns = createConstantNamespace('segments');

export const EDIT_SEGMENT = ns('EDIT_SEGMENT');
export const CREATE_SEGMENT = ns('CREATE_SEGMENT');
export const CREATE_SEGMENT_DONE = ns('CREATE_SEGMENT_DONE');
export const CREATE_SEGMENT_FAILED = ns('CREATE_SEGMENT_FAILED');
export const REQUEST_SEGMENTS = ns('REQUEST_SEGMENTS');
export const REQUEST_SEGMENTS_DONE = ns('REQUEST_SEGMENTS_DONE');
export const REQUEST_SEGMENTS_FAILED = ns('REQUEST_SEGMENTS_FAILED');
export const FILTER_SEGMENTS_TEXT = ns('FILTER_SEGMENTS');
export const FILTER_SEGMENTS_TYPE = ns('FILTER_SEGMENT_TYPE');
export const DELETE_SEGMENT = ns('DELETE_SEGMENT');
export const DELETE_SEGMENT_DONE = ns('DELETE_SEGMENT_DONE');
export const DELETE_SEGMENT_FAILED = ns('DELETE_SEGMENT_FAILED');
export const REQUEST_SEGMENT_BY_KEY = ns('REQUEST_SEGMENT_BY_KEY');
export const REQUEST_SEGMENT_BY_KEY_DONE = ns('REQUEST_SEGMENT_BY_KEY_DONE');
export const REQUEST_SEGMENT_BY_KEY_FAILED = ns('REQUEST_SEGMENT_BY_KEY_FAILED');
export const EDIT_SEGMENT_SETTINGS = ns('EDIT_SEGMENT_SETTINGS');
export const UPDATE_SEGMENT_SETTINGS = ns('UPDATE_SEGMENT_SETTINGS');
export const UPDATE_SEGMENT_SETTINGS_DONE = ns('UPDATE_SEGMENT_SETTINGS_DONE');
export const UPDATE_SEGMENT_SETTINGS_FAILED = ns('UPDATE_SEGMENT_SETTINGS_FAILED');
export const UPDATE_SEGMENT_TARGETING = ns('UPDATE_SEGMENT_TARGETING');
export const UPDATE_SEGMENT_TARGETING_DONE = ns('UPDATE_SEGMENT_TARGETING_DONE');
export const UPDATE_SEGMENT_TARGETING_FAILED = ns('UPDATE_SEGMENT_TARGETING_FAILED');
export const INITIALIZE_SEGMENT_TARGETING_MANAGER = ns('INITIALIZE_SEGMENT_TARGETING_MANAGER');
export const DESTROY_SEGMENT_TARGETING_MANAGER = ns('DESTROY_SEGMENT_TARGETING_MANAGER');
export const RESET_SEGMENT_TARGETING_MANAGER = ns('RESET_SEGMENT_TARGETING_MANAGER');
export const CHANGE_SEGMENT_TARGETING = ns('CHANGE_SEGMENT_TARGETING');
export const REQUEST_SEGMENT_RELATED_FLAGS = ns('REQUEST_SEGMENT_RELATED_FLAGS');
export const REQUEST_SEGMENT_RELATED_FLAGS_DONE = ns('REQUEST_SEGMENT_RELATED_FLAGS_DONE');
export const REQUEST_SEGMENT_RELATED_FLAGS_FAILED = ns('REQUEST_SEGMENT_RELATED_FLAGS_FAILED');
export const CHECK_ACCESS_RESOURCE = ns('CHECK_ACCESS_RESOURCE');
export const REQUEST_BIG_SEGMENT_STATUS = ns('REQUEST_BIG_SEGMENT_STATUS');
export const REQUEST_BIG_SEGMENT_STATUS_DONE = ns('REQUEST_BIG_SEGMENT_STATUS_DONE');
export const REQUEST_BIG_SEGMENT_STATUS_FAILED = ns('REQUEST_BIG_SEGMENT_STATUS_FAILED');
export const CONVERT_TO_SEGMENT_DONE = ns('CONVERT_TO_SEGMENT_DONE');
export const UPLOAD_CSV_TO_SEGMENT_MODAL_OPENED = ns('UPLOAD_CSV_TO_SEGMENT_MODAL_OPENED');
export const UPLOAD_CSV_TO_SEGMENT_MODAL_CLOSED = ns('UPLOAD_CSV_TO_SEGMENT_MODAL_CLOSED');
export const UPLOAD_CSV_TO_SEGMENT_UPLOAD_STARTED = ns('UPLOAD_CSV_TO_SEGMENT_UPLOAD_STARTED');
export const UPLOAD_CSV_TO_SEGMENT_UPLOAD_FAILED = ns('UPLOAD_CSV_TO_SEGMENT_UPLOAD_FAILED');
export const UPLOAD_CSV_TO_SEGMENT_IMPORT_STARTED = ns('UPLOAD_CSV_TO_SEGMENT_IMPORT_STARTED');
export const SEGMENT_EXPORT_STARTED = ns('SEGMENT_EXPORT_STARTED');
export const SEGMENT_EXPORT_FAILED = ns('SEGMENT_EXPORT_FAILED');
