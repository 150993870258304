import { Suspense } from 'react';
import { useInView } from 'react-intersection-observer';
import { baseUri } from '@gonfalon/constants';
import { Time } from '@gonfalon/datetime';
import { enableChangeHistoryDetails } from '@gonfalon/dogfood-flags';
import { DateFormat } from '@gonfalon/format';
import { ProgressBar } from '@launchpad-ui/components';
import { Button, Markdown } from '@launchpad-ui/core';
import cx, { clsx } from 'clsx';

import { ALLOWED_TAGS, AuditLogEntryRep } from '../types';

import { AuditLogEntryDetails } from './AuditLogEntryDetails';
import { EntryApp } from './EntryApp';
import { EntryAvatar } from './EntryAvatar';
import { EntryTitle } from './EntryTitle';

import styles from './EntrySummary.module.css';

export type EntrySummaryProps = {
  className?: string;
  entry: AuditLogEntryRep;
  organizationName?: string;
  projectName?: string;
  dateFormat?: DateFormat;
  fromNow?: boolean;
  expansion?: 'all' | Set<string>;
  expandItem?: (id: string) => void;
};

export function EntrySummary({
  className,
  entry,
  organizationName,
  projectName,
  fromNow,
  dateFormat,
  expansion,
  expandItem,
}: EntrySummaryProps) {
  const { date, description, shortDescription } = entry;
  const appHost = baseUri();

  const renderProject = () => {
    if (!projectName) {
      return;
    }
    return projectName && <span> &mdash; {projectName}</span>;
  };

  const renderComment = () => {
    if (entry.comment) {
      return (
        <Markdown
          source={entry.comment}
          allowedTags={['a', '#text', 'p', 'li', 'ul', 'ol']}
          className={clsx('BlockQuote', `${styles.listItemText} u-fs-sm`)}
        />
      );
    }
    return <></>;
  };

  const renderToken = () => {
    const token = entry.token;
    return (
      token && (
        <span>
          {' '}
          &mdash;{' '}
          <span>
            Using the token "{token.name}" (…{token.ending})
          </span>
        </span>
      )
    );
  };

  const renderApp = () => {
    const app = entry.app;
    return (
      app && (
        <span>
          {' '}
          &mdash;{' '}
          <span>
            <EntryApp app={app} />
          </span>
        </span>
      )
    );
  };

  const renderDescription = () => (
    <>
      {renderComment()}
      {shortDescription && (
        <Markdown
          source={shortDescription}
          allowedTags={ALLOWED_TAGS}
          baseUri={appHost}
          className={`${styles.listItemText} u-fs-sm`}
        />
      )}
      {description && !shortDescription && (
        <Markdown
          source={description}
          allowedTags={ALLOWED_TAGS}
          baseUri={appHost}
          className={`${styles.listItemText} u-fs-sm`}
        />
      )}
    </>
  );

  return (
    <div className={cx(styles.entrySummary, 'u-flex', 'u-flex-top', className)}>
      <EntryAvatar entry={entry} size="medium" className="u-mr-m" />
      <div className={`${styles.auditChanges} fs-exclude u-mw-100`} data-test-id="audit-log-entry">
        <EntryTitle entry={entry} organizationName={organizationName} />
        <div className={`${styles.listItemText} u-very-subtle u-fs-sm u-lh-sm`}>
          <Time className={styles.listItemText} fromNow={fromNow} dateFormat={dateFormat} datetime={date} />
          {renderProject()}
          {entry.token && renderToken()}
          {entry.app && renderApp()}
          {enableChangeHistoryDetails() && (
            <Button kind="link" className={styles.viewDetails} onClick={() => expandItem && expandItem(entry._id)}>
              {expansion === 'all' || expansion?.has(entry._id) ? 'Hide' : 'View'} details
            </Button>
          )}
        </div>
        {!enableChangeHistoryDetails() ? (
          <>{renderDescription()}</>
        ) : (
          <>
            {Boolean(expansion === 'all' || expansion?.has(entry._id)) && (
              <>
                {renderDescription()}
                <LazyAuditLogEntryDetails id={entry._id} />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function LazyAuditLogEntryDetails({ id }: { id: string }) {
  const { ref, inView } = useInView();

  return (
    <li className={styles.auditLogEntry} ref={ref}>
      {inView ? (
        <Suspense fallback={<ProgressBar isIndeterminate />}>
          <AuditLogEntryDetails compact id={id} />
        </Suspense>
      ) : (
        <></>
      )}
    </li>
  );
}
