import { FeatureFlagConfig, getVariationFillColor, Rule } from '@gonfalon/flags';
import { OptionProps } from '@gonfalon/launchpad-experimental';
import { schemas } from '@gonfalon/openapi';
import { getVariationName } from 'ia-poc/services/flag-creation/utils';
import { produce } from 'immer';

import { VariationList } from 'components/SelectDefaultVariations';
import Variation from 'components/Variation';

import { coerceToType, stringifyValue, ValueType } from './type-utils';

export type VariationApiType = schemas['Variation'];
type VariationOrRolloutRep = schemas['VariationOrRolloutRep'];

export type VariationValue = any;

export function forceVariationType(variation: VariationApiType, valueType: ValueType) {
  return produce(variation, (draft) => {
    draft.value = coerceToType(variation.value, valueType);
  });
}

export const colorVariation = (index: number) => getVariationFillColor(index);

export function getVariationValueString(variation: VariationApiType) {
  return stringifyValue(variation.value);
}

export function getVariationDisplay(variation: VariationApiType) {
  if (variation.name !== undefined && variation.name !== '') {
    return variation.name;
  } else {
    return getVariationValueString(variation);
  }
}

export function findVariationById(id: string, variations: VariationApiType[]) {
  return variations.find((variation) => variation._id === id);
}

export function getFallthroughRolloutPercentageForVariation(variationIndex: number, configuration: FeatureFlagConfig) {
  return configuration.fallthrough ? getRolloutPercentageForVariation(variationIndex, configuration.fallthrough) : 0;
}

export function getRolloutPercentageForVariation(variationIndex: number, rollout: Rule | VariationOrRolloutRep) {
  if (rollout.rollout === undefined && rollout.variation !== undefined && rollout.variation === variationIndex) {
    return 1;
  }

  if (rollout.variation === undefined && rollout.rollout !== undefined) {
    for (const weightedVariation of rollout.rollout.variations) {
      if (weightedVariation.variation === variationIndex) {
        return weightedVariation.weight / 100000;
      }
    }
  }

  return 0;
}

export function isVariationIncludedInRollout(variationIndex: number, rollout: Rule | VariationOrRolloutRep) {
  if (rollout.rollout === undefined && rollout.variation !== undefined && rollout.variation === variationIndex) {
    return true;
  }

  if (rollout.variation === undefined && rollout.rollout !== undefined) {
    const weight = rollout.rollout.variations.find(
      (weightedVariation) => weightedVariation.variation === variationIndex,
    );

    if (weight !== undefined && weight.weight > 0) {
      return true;
    }
  }

  return false;
}

export const variationOption = (option: OptionProps, variations: VariationList) => {
  const index: number = option.value;

  if (index === -1 || index >= variations.length) {
    return null;
  }

  const selectedVariation = variations[index];
  const name = getVariationName(variations, index);

  return (
    <Variation
      name={name}
      value={selectedVariation.value}
      color={colorVariation(index)}
      description={selectedVariation.description}
      inlineDescription={false}
    />
  );
};
