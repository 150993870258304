import type { schemas } from '@gonfalon/openapi';

export type ReleasePipeline = schemas['ReleasePipeline'];
export type CreateReleasePipelineInput = schemas['CreateReleasePipelineInput'];
export type ReleasePipelinePhase = ReleasePipeline['phases'][number];
export type ReleasePipelinePhaseAudience = ReleasePipelinePhase['audiences'][number];
export type ReleasePipelinePhaseAudienceWithEnvironment = ReleasePipelinePhaseAudience &
  Required<Pick<ReleasePipelinePhaseAudience, 'environment'>>;
export type Release = schemas['Release'];
export type ReleasePhase = NonNullable<schemas['Release']['phases']>[number];
export type ReleasePhaseAudience = ReleasePhase['_audiences'][number];
export type ReleaseProgression = schemas['ReleaseProgression'];

export type ReleaseTrackerPhaseStatus = 'active' | 'inactive' | 'complete' | 'released';

export enum ReleaseStrategy {
  IMMEDIATE_ROLLOUT = 'immediate-rollout',
  GUARDED_ROLLOUT = 'monitored-release',
  PROGRESSIVE_ROLLOUT = 'progressive-rollout',
}

export type ReleasePipelinePhaseAudienceTrackEventInfo = {
  segmentCount: number;
  requireApproval: boolean;
  releaseStrategy: string;
};
