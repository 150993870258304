// eslint-disable-next-line no-restricted-imports
import { useSearchParams } from 'react-router-dom';
import { hasLegacyPlan as hasLegacyPlanFlag } from '@gonfalon/constants';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import {
  enableNewPostExperimentationExperience,
  experimentPaginationLimit,
  isABTestingEnabled,
  isExperimentationApiEnabled,
  isPaginatedExperimentsDashboardEnabled,
} from '@gonfalon/dogfood-flags';
import { useProjectKey } from '@gonfalon/router';

import { useSubscription } from 'hooks/useSubscription';

import { useExperiments } from './useExperiments';
import { useHasLegacyExperiments } from './useHasLegacyExperiments';

/**
 * Hook determines whether to enable the post-experimentation experience
 * Post-experimentation = users who had experimentation access and no longer do
 *
 * Post-experimentation experience: banner & visible experiments/metrics tables on
 * "Overview" & "Metrics" and Flag->Experiments view.
 * User can't modify experiments but can stop existing running experiments.
 *
 * Fetches experiments and legacy experiments.
 * Evaluates the enable-new-post-experimentation-experience flag.
 */
export const useEnablePostExperimentationExperience = () => {
  const { isReady: isSubscriptionReady, entity: subscription } = useSubscription();

  const projKey = useProjectKey();
  const envKey = useSelectedEnvironmentKey();
  const [searchParams] = useSearchParams();

  const hasLegacyPlan = hasLegacyPlanFlag();
  const isNewPostExperimentationExperienceEnabled = enableNewPostExperimentationExperience();
  const enableExperimentation = isABTestingEnabled();

  const isEnterpriseCampaignExpired = !!subscription.getEnterpriseCampaign()?.isExpired();

  const { data: modernExperiments, status: experimentsRequestStatus } = useExperiments({
    projectKey: projKey,
    environmentKey: envKey,
    limit: Number(searchParams.get('limit') ?? experimentPaginationLimit()),
    offset: isPaginatedExperimentsDashboardEnabled() ? Number(searchParams.get('offset') ?? 0) : 0,
    enabled: isExperimentationApiEnabled(),
  });
  const modernExperimentsCount = modernExperiments ? modernExperiments.total_count : 0;

  const isRequestsReady = isSubscriptionReady && !['idle', 'loading'].includes(experimentsRequestStatus);

  const isPostExperimentationExperienceReady = isNewPostExperimentationExperienceEnabled ? isRequestsReady : true;
  const { hasLegacyExperiments, isLoading: isHasLegacyExperimentsLoading } = useHasLegacyExperiments();

  const disableCases = [
    hasLegacyPlan,
    !isHasLegacyExperimentsLoading && hasLegacyExperiments,
    enableExperimentation,
    !isNewPostExperimentationExperienceEnabled,
    modernExperimentsCount === 0,
  ];

  const enableCases = [isEnterpriseCampaignExpired, modernExperimentsCount > 0];

  const enablePostExperimentationExperience = () => {
    if (disableCases.some((v) => !!v)) {
      return false;
    }

    // disableCases must be false:
    // - user can't be on a legacy plan
    // - user can't have legacy experiments
    // - experimentation can't be enabled
    // - the flag must be enabled
    // - user must have modern experiments
    if (enableCases.some((v) => !!v)) {
      return true;
    }

    return false;
  };

  return {
    enablePostExperimentationExperience: enablePostExperimentationExperience(),
    isPostExperimentationExperienceReady,
  };
};
