import { InviteMembersModalProps } from 'components/inviteMembers/InviteMembersModal';
import { GenerateActionType } from 'utils/reduxUtils';

export const openInviteMembersModal = (props: InviteMembersModalProps) =>
  ({
    type: 'inviteMembersModal/OPEN_MODAL',
    props,
  }) as const;

export const closeInviteMembersModal = () =>
  ({
    type: 'inviteMembersModal/CLOSE_MODAL',
  }) as const;

const InviteMembersModalActionCreators = {
  openInviteMembersModal,
  closeInviteMembersModal,
};

export type InviteMembersModalAction = GenerateActionType<typeof InviteMembersModalActionCreators>;
