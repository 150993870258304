import { ChangeEvent, forwardRef, KeyboardEvent, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useSearchField } from 'react-aria';
import type { SearchFieldProps as AriaSearchFieldProps } from 'react-stately';
import { useSearchFieldState } from 'react-stately';
import { Icon } from '@launchpad-ui/icons';
import classNames from 'clsx';
import { TextFieldProps, Tooltip } from 'launchpad';

import { ClearButton } from './ClearButton';

import './styles/Form.css';
import './styles/SearchField.css';

export type SearchFieldProps = Omit<TextFieldProps, 'onChange' | 'value' | 'ref'> & {
  /**
   * When true, it will fill the outer container
   */
  isFullWidth?: boolean;
  /**
   * Displays the element when passed in to demonstrate a pending state
   */
  pendingIndicator?: JSX.Element;
  /**
   * Callback method with the value in the SearchField passed in
   */
  onChange(value: string): void;
  /**
   * The native autoFocus prop forces a page scroll to this search field.
   * If you don't want that scrolling behavior on focus, use manualAutoFocus instead.
   */
  manualAutoFocus?: boolean;
  /**
   * This is the value set in the text field when mounted
   */
  value?: string;
  /*
   * Called whenever the user clicks the "Clear Search" button
   */
  onClearSearch?(): void;

  testId?: string;
};

export type SearchFieldHandle = {
  /**
   * Returns the value currently in the search field
   */
  value: () => string | null;
  /**
   * Focuses the input element in the search field
   */
  focus: () => void;
  /**
   * Clears the search field and then focuses it
   */
  reset: () => void;
};

export type ReactAriaSearchFieldProps = Omit<SearchFieldProps & AriaSearchFieldProps, 'onKeyPress'> & {
  /**
   * @deprecated
   * This is dropped by react-aria which is used in SearchField for accessibility features.
   * {@link https://developer.mozilla.org/en-US/docs/Web/API/Document/keypress_event}
   */
  onKeyPress?: (e: KeyboardEvent) => void;
  /**
   * @deprecated
   * This is dropped by react-aria which is used in SearchField for accessibility features.
   * React-aria overrides react's default focusing behavior
   */
  autoFocus?: boolean;

  testId?: string;
};

export const ReactAriaSearchField = forwardRef<SearchFieldHandle, ReactAriaSearchFieldProps>((props, ref) => {
  const {
    manualAutoFocus,
    className,
    autoFocus,
    isFullWidth,
    testId,
    tiny = false,
    onChange,
    onClearSearch,
    pendingIndicator,
  } = props;
  const state = useSearchFieldState(props);

  const inputRef = useRef<HTMLInputElement>(null);
  const { inputProps, clearButtonProps } = useSearchField(props, state, inputRef);

  const [localValue, setLocalValue] = useState<undefined | string>(props.value);

  const reset = () => {
    const value = '';
    setLocalValue(value);
    onChange(value);
    inputRef?.current?.focus();
  };

  const focus = () => {
    inputRef?.current?.focus();
  };

  // This method is used outside to access the value in the search component
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const value = () => inputRef?.current?.value || null;

  useImperativeHandle(ref, () => ({
    focus,
    reset,
    value,
  }));

  useEffect(() => {
    setTimeout(() => {
      if (manualAutoFocus) {
        focus();
      }
    }, 0);
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setLocalValue(val);
    onChange(val);
  };

  /**
   * react-aria drops this prop so we are adding it in manually for backwards
   * compatibility.
   * @param event
   */
  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    props.onKeyPress?.(event);
  };

  /**
   * This has to be manually included because of an open issue with
   * keyDown events in react-aria
   * {@link https://github.com/adobe/react-spectrum/issues/2253}
   */
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    props.onKeyDown?.(event);
  };

  const classes = classNames('SearchField', className, {
    'SearchField--fullWidth': isFullWidth,
  });

  const inputClasses = classNames('FormInput', className, {
    'FormInput--tiny': tiny,
  });

  const clearButton = localValue ? (
    <Tooltip content="Clear search" targetClassName="ClearButtonTooltip-target">
      <ClearButton
        aria-label={clearButtonProps['aria-label'] as string}
        id={clearButtonProps.id}
        disabled={clearButtonProps.isDisabled}
        onClick={() => {
          onClearSearch?.();
          reset();
        }}
      />
    </Tooltip>
  ) : null;

  return (
    <div className={classes}>
      <input
        {...inputProps}
        className={inputClasses}
        type="search"
        ref={inputRef}
        autoFocus={autoFocus}
        value={localValue}
        onKeyPress={handleKeyPress}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        data-test-id={testId}
      />

      <Icon name="search" size="small" className="SearchField-icon" />
      <span className="SearchField-suffix-wrapper">
        {pendingIndicator ? (
          <div className="SearchField-pending-indicator">{pendingIndicator}</div>
        ) : (
          <span className="SearchField-clearButton">{clearButton}</span>
        )}
      </span>
    </div>
  );
});
ReactAriaSearchField.displayName = 'SearchField';
