import { experimentValueThreshold } from '@gonfalon/dogfood-flags';
import { formatNumber } from '@gonfalon/format';

export function formatExperimentValue({
  value,
  digits = 4,
  isPercent = false,
}: {
  value: number;
  digits?: number;
  isPercent?: boolean;
}): string {
  // handle very small numbers
  const threshold = experimentValueThreshold();
  if (value !== 0 && value < threshold) {
    return isPercent ? `<${threshold * 100}%` : `<${threshold}`;
  }

  // handle very big numbers
  if (value > 999999999) {
    return value.toExponential(digits);
  }

  return formatNumber(value, {
    style: isPercent ? 'percent' : 'decimal',
    minimumFractionDigits: isPercent ? 2 : digits,
    maximumFractionDigits: isPercent ? 2 : digits,
  });
}
