import { ReleasePipeline, ReleasePipelinePhaseAudienceWithEnvironment } from './types';

export function getReleasePipelineEnvironmentKeys(releasePipeline: ReleasePipeline) {
  return Array.from(
    new Set(
      releasePipeline.phases
        .map((p) =>
          p.audiences
            .filter((a): a is ReleasePipelinePhaseAudienceWithEnvironment => !!a.environment)
            .map((a) => a.environment.key)
            .flat(),
        )
        .flat(),
    ),
  );
}
