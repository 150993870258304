export enum ProgressiveRolloutInstructionKind {
  STOP_PROGRESSIVE_ROLLOUT = 'stopProgressiveRollout',
}

export type StopProgressiveRolloutSemanticInstruction = {
  kind: ProgressiveRolloutInstructionKind.STOP_PROGRESSIVE_ROLLOUT;
  ruleId: string;
  endVariationId?: string;
};

export type ProgressiveRolloutSemanticInstruction = StopProgressiveRolloutSemanticInstruction;
