import { Map, OrderedMap } from 'immutable';

import { SelectMemberAction } from 'actions/selectMember';
import { GlobalState } from 'reducers';
import registry from 'reducers/registry';
import { Member } from 'utils/accountUtils';

export const selectedMembersSelector = (state: GlobalState) =>
  state?.selectMemberReducer?.get('selectedMembers') as OrderedMap<string, Member>;
export const selectMemberIsSearching = (state: GlobalState) => Boolean(state?.selectMemberReducer?.get('isSearching'));
export const filteredMemberListSelector = (state: GlobalState) =>
  state?.selectMemberReducer?.get('members') as OrderedMap<string, Member>;
export const selectMemberSearchValueSelector: (state: GlobalState) => string | undefined = (state: GlobalState) =>
  state?.selectMemberReducer?.get('searchValue') as string;
export function selectMemberReducer(
  state = Map({
    isSearching: false,
    members: OrderedMap(),
    searchValue: '',
    selectedMembers: undefined,
  }),
  action: SelectMemberAction,
) {
  switch (action.type) {
    case 'selectMember/LOADED':
      return state.merge({ isSearching: false, members: action.members });
    case 'selectMember/SEARCH':
      return state.merge({
        isSearching: true,
        searchValue: action?.searchValue,
      });
    case 'selectMember/SELECT_MEMBERS':
      return state.merge({
        selectedMembers: action?.members,
      });
    default:
      return state;
  }
}

registry.addReducers({ selectMemberReducer });
