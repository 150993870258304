import { useEffect } from 'react';
import { hasLegacyPlan as hasLegacyPlanFlag } from '@gonfalon/constants';
import { noop } from '@gonfalon/es6-utils';

import {
  fetchLegacySubscription as fetchLegacySubscriptionAction,
  fetchSubscription as fetchModernSubscriptionAction,
} from 'actions/billing';
import { legacySubscriptionSelector, subscriptionSelector } from 'reducers/billing';
import { ready } from 'utils/reduxUtils';

import { useDispatch } from './useDispatch';
import { useSelector } from './useSelector';

export const useSubscription = () => {
  const dispatch = useDispatch();

  const hasLegacyPlan = hasLegacyPlanFlag();
  const legacySubscription = useSelector(legacySubscriptionSelector);
  const modernSubscription = useSelector(subscriptionSelector);

  const subscriptionEntity = hasLegacyPlan ? legacySubscription.get('entity') : modernSubscription.entity;
  const subscriptionRequest = hasLegacyPlan ? legacySubscription : modernSubscription.fetchRequest;

  const isReady = ready(subscriptionRequest);

  const fetchLegacySubscription = () => {
    dispatch(fetchLegacySubscriptionAction())?.catch(noop);
  };

  const fetchModernSubscription = () => {
    dispatch(fetchModernSubscriptionAction())?.catch(noop);
  };

  const fetchSubscription = () => {
    if (hasLegacyPlan) {
      fetchLegacySubscription();
      return;
    }

    fetchModernSubscription();
  };

  useEffect(() => {
    if (!isReady) {
      fetchSubscription();
    }
  }, [isReady]);

  return {
    isReady,
    entity: subscriptionEntity,
    hasLegacyPlan,
  };
};
