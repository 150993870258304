import { Avatar } from '@launchpad-ui/core';
import { type IconProps, Icon } from '@launchpad-ui/icons';

type IntegrationAvatarProps = {
  url?: string | null;
  size?: IconProps['size'];
  className?: string;
};

export function IntegrationAvatar({ url: passedUrl, className, size }: IntegrationAvatarProps) {
  const url = passedUrl ? passedUrl : '';

  return (
    <Avatar
      alt="A logo for this integration"
      url={url}
      size={size || undefined}
      defaultIcon={<Icon name="robot" />}
      className={className}
    />
  );
}
