import { hasPlan as hasLegacyPlan } from '../internal/legacySubscription';
import { hasPlan as hasModernPlan } from '../internal/modernSubscription';
import { Subscription } from '../types';

import { isModernSubscription } from './isModernSubscription';

export function hasPlan(subscription: Subscription) {
  if (isModernSubscription(subscription)) {
    return hasModernPlan(subscription);
  }

  return hasLegacyPlan(subscription);
}
