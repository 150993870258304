import { unionBy } from '@gonfalon/es6-utils';
import { List } from 'immutable';
import qs from 'qs';

import { createMemberSummary, MemberSummary } from 'utils/accountUtils';
import { Link } from 'utils/linkUtils';
import { Team, TeamMaintainerSummary } from 'utils/teamsUtils';

/**
 * Merge the latest batch of team maintainers with the already loaded items.
 *
 * @param state - The team state currently in the redux store.
 * @param action - The redux action that contains the newly loaded team maintainers.
 * @returns  The team that includes the newly loaded maintainers.
 */
export const handleRequestMoreTeamMaintainersDone = (state: Team, action: { summary: TeamMaintainerSummary }): Team => {
  const items = unionBy(state.maintainers.items.toJS(), action.summary.items.toJS(), '_id').map(createMemberSummary);
  const summary = action.summary.set('items', List(items)).set('_isFetching', false);
  return state.set('maintainers', summary);
};

/**
 * Add the maintainers to the team in the redux store.
 *
 * @param state - The team state currently in the redux store.
 * @param action - The redux action that triggered the maintainers to be added to the team.
 * @returns The team that includes the newly maintainers.
 */
export const handleAddMaintainersToTeamDone = (
  state: Team,
  action: {
    team: Team;
    maintainers: MemberSummary[];
  },
): Team => {
  if (state.maintainers.items.size > 0) {
    // state.maintainers is only set when the new teams api feature flag is enabled.
    // If the list has any items, go ahead and add the team maintainers to the list.
    const items = unionBy(state.maintainers.items.toJS(), action.maintainers, '_id').map(createMemberSummary);
    const totalCount = state.maintainers.totalCount + Math.abs(state.maintainers.totalCount - items.length);
    return state.setIn(['maintainers', 'items'], List(items)).setIn(['maintainers', 'totalCount'], totalCount);
  } else if (action.team) {
    // If the new teams api feature flag is disabled, replace the team with the patched team.
    return action.team;
  }

  return state;
};

/**
 * Remove the maintainer from the redux store.
 *
 * @param state - The team state currently in the redux store.
 * @param action - The redux action that triggered the user to be removed from state.
 */
export const handleRemoveMaintainerFromTeamDone = (state: Team, action: { team: Team; maintainerId: string }): Team => {
  if (state.maintainers.items.size > 0) {
    // state.maintainers is only set when the new teams api feature flag is enabled.
    // If the list has any items, go ahead and remove the maintainer from the list.
    const nextLink = decrementNextLinkOffsetByOne(state.maintainers);
    const items = state.maintainers.items.filter((maintainer) => action.maintainerId !== maintainer._id);
    const totalCount = state.maintainers.totalCount + Math.abs(state.maintainers.totalCount - items.size);
    return state
      .setIn(['maintainers', 'items'], items)
      .setIn(['maintainers', '_links', 'next'], nextLink)
      .setIn(['maintainers', 'totalCount'], totalCount);
  } else if (action.team) {
    // If the new teams api feature flag is disabled, replace the team with the patched team.
    return action.team;
  }

  return state;
};

// Decrement the next link offset by one.
//
// Why do we need to do this?
//   In the next link is present, the offset will be set to the number of maintainers that have already been loaded.
//   When a maintainers is removed from that list, the offset will now be 1 greater than the number of loaded entities.
//   This means that if a user proceeded to keep iterating from the old offset, the list would be off by 1.
//
//   To prevent this from happening, we must parse and decrement the offset by one.
//   Thus, ensuring that the pagination and list of items stay in sync.
const decrementNextLinkOffsetByOne = (summary: TeamMaintainerSummary): Link | undefined => {
  if (summary._links.next) {
    const href = summary._links.next.get('href');

    const [prefix, queryString] = href.split('?');
    const parsedQueryString = qs.parse(queryString);

    const updatedOffset = Number(parsedQueryString.offset) - 1;
    const updatedQueryString = qs.stringify({
      ...parsedQueryString,
      offset: updatedOffset,
    });

    return summary._links.next.set('href', `${prefix}?${updatedQueryString}`);
  }
};
