import { Map } from 'immutable';
import { AnyAction, combineReducers } from 'redux';
import { createSelector } from 'reselect';

import actionTypes from 'actionTypes/experiments';
import { GlobalState } from 'reducers';
import { createRequestReducer } from 'reducers/createRequestReducer';
import registry from 'reducers/registry';
import { searchSelector } from 'reducers/router';
import { createExperimentFiltersFromQuery, Experiment } from 'utils/experimentDashboardUtils';

import paginate from './paginate';

export const experimentsEntities = (
  state: Map<string, Experiment> = Map(),
  action: AnyAction,
): Map<string, Experiment> => {
  if (actionTypes.RECEIVE_EXPERIMENTS === action.type) {
    if (action.response && action.response.hasIn(['entities', 'experiments'])) {
      return action.response.getIn(['entities', 'experiments']);
    }
  }
  return state;
};

const request = createRequestReducer([
  actionTypes.REQUEST_EXPERIMENTS,
  actionTypes.RECEIVE_EXPERIMENTS,
  actionTypes.REQUEST_EXPERIMENTS_FAILED,
]);

const experimentsPagination = paginate({
  types: [actionTypes.REQUEST_EXPERIMENTS, actionTypes.REQUEST_EXPERIMENTS_FAILED, actionTypes.RECEIVE_EXPERIMENTS],
  mapActionToKey: () => 'all',
});

const experimentsSummaryCounts = (state: Map<string, number> = Map(), action: AnyAction) => {
  if (actionTypes.RECEIVE_EXPERIMENTS === action.type) {
    if (action.response && action.response.hasIn(['result', '_summaryCounts'])) {
      return action.response.getIn(['result', '_summaryCounts']);
    }
  }
  return state;
};

export const experiments = combineReducers({
  entities: experimentsEntities,
  pagination: experimentsPagination,
  request,
  summaryCounts: experimentsSummaryCounts,
});

export const experimentsSelector = (state: GlobalState) => state.experiments;

export const experimentEntitiesSelector = (state: GlobalState) => experimentsSelector(state).entities;
export const experimentPaginationSelector = (state: GlobalState) => experimentsSelector(state).pagination.get('all');
export const experimentRequestSelector = (state: GlobalState) => experimentsSelector(state).request;
export const experimentListSelector = (state: GlobalState) => experimentEntitiesSelector(state).toList();
export const experimentSummaryCountsSelector = (state: GlobalState) => experimentsSelector(state).summaryCounts;

export const experimentFilterSelector = createSelector(searchSelector, (query) =>
  createExperimentFiltersFromQuery(query),
);

registry.addReducers({ experiments });
