import { createTrackerForCategory } from '@gonfalon/analytics';
import { UIContextsView } from '@gonfalon/context-kinds';
import { UIFlagListView } from '@gonfalon/flag-filters';
import { UISegmentsListView } from '@gonfalon/segments';

import { Shortcut } from '../types';

interface ShortcutEventInfo {
  type: Shortcut['type'];
  name: Shortcut['name'];
  filters?: UISegmentsListView | UIContextsView | UIFlagListView;
}

function trackShortcutEvent(event: string, attributes?: ShortcutEventInfo) {
  createTrackerForCategory('Shortcuts')(event, attributes);
}

export function trackShortcutViewed() {
  trackShortcutEvent('Save shortcut viewed');
}

export function trackShortcutClicked({ type, name, filters }: ShortcutEventInfo) {
  trackShortcutEvent('Shortcut clicked', { type, name, filters });
}
