import { ReactNode } from 'react';
import { Icon } from '@launchpad-ui/icons';

import styles from './FieldError.module.css';

type Props = {
  children: ReactNode;
};

export function FieldError({ children }: Props) {
  return (
    <p className={styles.error}>
      <Icon name="alert-rhombus" size="small" /> {children}
    </p>
  );
}
