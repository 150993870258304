import { useState } from 'react';
import { useDebounce } from '@gonfalon/async';
import { SearchFieldProps as LPSearchFieldProps } from '@launchpad-ui/components';

import { SearchField } from './SearchField';

interface DebouncedSearchFieldProps extends LPSearchFieldProps {
  placeholder?: string | undefined;
}

export function DebouncedSearchField({ value, onChange, onClear, ...props }: DebouncedSearchFieldProps) {
  const [search, setSearch] = useState(value ?? '');

  const notifyChange = useDebounce(() => {
    onChange?.(search);
  }, 250);

  return (
    <SearchField
      {...props}
      value={search}
      onChange={(nextValue) => {
        setSearch(nextValue);
        notifyChange();
      }}
      onClear={() => {
        setSearch('');
        onClear?.();
      }}
    />
  );
}
