import { ReactNode } from 'react';

import { FlagContextProvider } from 'components/FlagContext';
import { ErrorAlert } from 'components/ui/alert/ErrorAlert';
import { useFlagByKey } from 'hooks/useFlagByKey';

export type FlagContextErrorWrapperProps = {
  flagKey: string;
  envKey: string;
  expandEvaluation?: boolean;
  children: ReactNode;
};
export function FlagContextErrorWrapper({ envKey, flagKey, expandEvaluation, children }: FlagContextErrorWrapperProps) {
  const expandEvaluationEnvKey = expandEvaluation ? envKey : undefined;
  const flagState = useFlagByKey(flagKey, expandEvaluationEnvKey);

  if (flagState.error !== null) {
    return <ErrorAlert error={flagState.error} />;
  }
  const flag = flagState.entity;

  let flagConfiguration;
  try {
    flagConfiguration = flag.getConfiguration(envKey);
  } catch (err) {
    flagConfiguration = null;
  }

  if (!flagConfiguration || (expandEvaluation && !flagConfiguration.evaluation)) {
    return <ErrorAlert />;
  }

  return (
    <FlagContextProvider flag={flag} flagConfiguration={flagConfiguration}>
      {children}
    </FlagContextProvider>
  );
}
