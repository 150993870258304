// eslint-disable-next-line no-restricted-imports
import { fromJS, Map, OrderedMap } from 'immutable';
import { normalize, schema } from 'normalizr';

import { LaunchDarklyIntegrationsManifest } from 'types/generated/integrationSchema';
import { convertMapToOrderedMap } from 'utils/collectionUtils';
import { Environment } from 'utils/environmentUtils';
import {
  createGoaltenderSubscription as createGoaltenderSubscriptionRecord,
  GoaltenderSubscription,
  GoaltenderSubscriptionType,
  IntegrationsManifestServed,
  ManifestRecord,
} from 'utils/goaltenderUtils';
import http, { jsonToImmutable, jsonToImmutableError } from 'utils/httpUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import {
  createDestination as createDestinationRecord,
  createSlackIncomingHook as createSlackIncomingHookRecord,
  Destination,
  getProjAndEnvFromSelfLink,
  SlackIncomingHook,
} from 'utils/integrationUtils';
import { createJsonPatch } from 'utils/patchUtils';
import { Project } from 'utils/projectUtils';
import { truncateString } from 'utils/stringUtils';
import { TriggerPatchKind, TriggerType } from 'utils/triggerUtils';

const destinations = new schema.Entity('destinations', {}, { idAttribute: '_id' });

export type DestinationResponse = ImmutableMap<{
  entities: ImmutableMap<{
    destinations: OrderedMap<string, Destination>;
  }>;
  result: ImmutableMap<{
    items: string[];
    totalCount: number;
  }>;
}>;

async function getAllDestinations(
  projects: OrderedMap<string, Project>,
  environments: OrderedMap<string, Environment>,
): Promise<DestinationResponse> {
  return http
    .get('/api/v2/destinations')
    .then(async (res) =>
      res.json().then((jsonRes) => {
        const data = normalize(jsonRes, { items: [destinations] });
        return fromJS(data).withMutations((map: DestinationResponse) => {
          map.updateIn(['entities', 'destinations'], (dest: ImmutableMap<Destination>) =>
            convertMapToOrderedMap(dest, data.result.items),
          );
          map.updateIn(['entities', 'destinations'], (dest: ImmutableMap<Destination>) =>
            dest
              ? dest.map(createDestinationRecord).map((destinationRecord) => {
                  const { proj, env } = getProjAndEnvFromSelfLink(projects, environments, destinationRecord.selfLink());
                  return destinationRecord
                    .update('environment', () => env as Environment)
                    .update('project', () => proj as Project);
                })
              : OrderedMap(),
          );
        });
      }),
    )
    .catch(jsonToImmutableError);
}

export const createDestination = async (destination: Destination): Promise<Destination> => {
  const projKey = destination.project.key;
  const envKey = destination.environment.key;
  return http
    .post(`/api/v2/destinations/${projKey}/${envKey}`, {
      body: destination.toRep(),
    })
    .then(jsonToImmutable)
    .then(createDestinationRecord)
    .then((props) => props.update('environment', () => destination.environment))
    .catch(jsonToImmutableError);
};

export const updateDestination = async (
  oldDestination: Destination,
  newDestination: Destination,
): Promise<Destination> => {
  const patch = createJsonPatch(oldDestination.toJS(), newDestination.toJS(), { shouldTestVersion: true });
  return http
    .patch(oldDestination.selfLink(), {
      body: patch,
    })
    .then(jsonToImmutable)
    .then(createDestinationRecord)
    .then((props) => props.update('environment', () => oldDestination.environment))
    .catch(jsonToImmutableError);
};

export const deleteDestination = async (destination: Destination): Promise<Response> =>
  http.delete(destination.selfLink()).catch(jsonToImmutableError);

export const sendDestinationTestEvent = async (destination: Destination): Promise<Response> =>
  http.post(`${destination.selfLink()}/test-event`).catch(jsonToImmutableError);

const slack = new schema.Entity('slack', {}, { idAttribute: '_id' });

export type SlackHookResponse = ImmutableMap<{
  entities: ImmutableMap<{
    slack: OrderedMap<string, SlackIncomingHook>;
  }>;
  result: ImmutableMap<{
    items: string[];
    totalCount: number;
  }>;
}>;

async function getAllSlackIncomingHooks(): Promise<SlackHookResponse> {
  return http
    .get('/api/v2/integrations/slack')
    .then(async (res) =>
      res.json().then((jsonRes) => {
        const data = normalize(jsonRes, { items: [slack] });
        return fromJS(data).withMutations((map: SlackHookResponse) => {
          map.updateIn(['entities', 'slack'], (entities: ImmutableMap<Destination>) =>
            convertMapToOrderedMap(entities, data.result.items),
          );
          map.updateIn(['entities', 'slack'], (entities: ImmutableMap<Destination>) =>
            entities ? entities.map(createSlackIncomingHookRecord) : OrderedMap(),
          );
        });
      }),
    )
    .catch(jsonToImmutableError);
}

async function createSlackIncomingHook(hook: SlackIncomingHook): Promise<SlackIncomingHook> {
  return http
    .post('/api/v2/integrations/slack', {
      body: hook,
    })
    .then(jsonToImmutable)
    .then(createSlackIncomingHookRecord)
    .catch(jsonToImmutableError);
}

interface IntegrationItem {
  selfLink(): string;
}

async function updateIntegration<T extends IntegrationItem>(oldIntegration: T, newIntegration: T): Promise<T> {
  const patch = createJsonPatch(oldIntegration, newIntegration);
  return http
    .patch(oldIntegration.selfLink(), {
      body: patch,
    })
    .then(jsonToImmutable, jsonToImmutableError);
}

async function updateSlackIncomingHook(
  oldHook: SlackIncomingHook,
  newHook: SlackIncomingHook,
): Promise<SlackIncomingHook> {
  return updateIntegration<SlackIncomingHook>(oldHook, newHook).then(createSlackIncomingHookRecord);
}

async function deleteSlackIncomingHook(hook: SlackIncomingHook): Promise<Response> {
  return http.delete(hook.selfLink()).catch(jsonToImmutableError);
}

async function getGoaltenderManifests(integrationKey?: string): Promise<OrderedMap<string, ManifestRecord>> {
  const path = integrationKey ? `/api/v2/integration-manifests/${integrationKey}` : '/api/v2/integration-manifests';
  return http
    .get(path)
    .then(async (res) =>
      res.json().then((jsonRes) => {
        if (integrationKey && jsonRes.key) {
          return fromJS(
            [jsonRes].reduce(
              (o: { [key: string]: IntegrationsManifestServed }, i: IntegrationsManifestServed) =>
                Object.assign(o, { [i.key]: i }),
              {},
            ),
          );
        }
        if (!jsonRes.items) {
          return Map();
        }
        return fromJS(
          jsonRes.items.reduce(
            (o: { [key: string]: IntegrationsManifestServed }, i: IntegrationsManifestServed) =>
              Object.assign(o, { [i.key]: i }),
            {},
          ),
        ).sort((a: ManifestRecord, b: ManifestRecord) => {
          if (a.get('name') === b.get('name')) {
            return 0;
          }
          return a.get('name') < b.get('name') ? -1 : 1;
        });
      }),
    )
    .catch(jsonToImmutableError);
}

async function createGoaltenderSubscription(subscription: GoaltenderSubscription): Promise<GoaltenderSubscription> {
  return http
    .post(subscription.parentLink(), {
      body: subscription,
    })
    .then(jsonToImmutable)
    .then((newSubscription) =>
      createGoaltenderSubscriptionRecord(newSubscription.merge({ _manifest: subscription.get('_manifest') })),
    )
    .catch(jsonToImmutableError);
}

async function getAllGoaltenderSubscriptions(): Promise<Array<{ key: string; items: GoaltenderSubscriptionType[] }>> {
  return http
    .get('/api/v2/integrations')
    .then(async (res) => res.json().then((jsonRes) => jsonRes.items))
    .catch(jsonToImmutableError);
}

export type GoaltenderManifestsAndSubscriptionRes = {
  manifests: OrderedMap<string, ManifestRecord>;
  subscriptions: Array<{
    kind: string;
    records: ImmutableMap<GoaltenderSubscription>;
  }>;
};

async function getAllGoaltenderManifestsAndSubscriptions(): Promise<GoaltenderManifestsAndSubscriptionRes> {
  return Promise.all([getGoaltenderManifests(), getAllGoaltenderSubscriptions()]).then(([manifests, integrations]) => {
    const subscriptions = integrations.map((integration) => {
      const kind = integration.key;
      const manifest = manifests.get(kind);
      const records = integration.items.map((sub) =>
        createGoaltenderSubscriptionRecord({
          ...sub,
          _manifest: manifest,
          _links: fromJS(sub._links),
          _status: fromJS(sub._status),
        }),
      );
      return {
        kind,
        records: fromJS(records),
      };
    });

    return {
      manifests,
      subscriptions,
    };
  });
}

async function getGoaltenderSubscriptions(
  manifest: ManifestRecord = {} as ManifestRecord,
): Promise<GoaltenderSubscription[]> {
  return http
    .get(`${manifest.getIn(['_links', 'subscriptions', 'href'])}`)
    .then(async (res) =>
      res.json().then((jsonRes) =>
        fromJS(
          jsonRes.items.map((sub: GoaltenderSubscriptionType) =>
            createGoaltenderSubscriptionRecord({
              ...sub,
              _links: fromJS(sub._links),
              _manifest: manifest,
              _status: fromJS(sub._status),
            }),
          ),
        ),
      ),
    )
    .catch(jsonToImmutableError);
}

async function updateGoaltenderSubscription(
  oldSubscription: GoaltenderSubscription,
  newSubscription: GoaltenderSubscription,
) {
  return updateIntegration<GoaltenderSubscription>(oldSubscription, newSubscription).then((sub) =>
    createGoaltenderSubscriptionRecord(sub.set('_manifest', oldSubscription.get('_manifest'))),
  );
}

async function deleteGoaltenderSubscription(subscription: GoaltenderSubscription): Promise<Response> {
  return http.delete(subscription.selfLink()).catch(jsonToImmutableError);
}

type GoaltenderTestEventRes = {
  success: boolean;
  errorMessage?: string;
  responseBody: string;
  timestamp: number;
  statusCode: number;
};

async function sendGoaltenderTestEvent(subscription: GoaltenderSubscription): Promise<GoaltenderTestEventRes> {
  return http
    .post(`${subscription.selfLink()}/validate`, { beta: true })
    .then(async (res) =>
      res.json().then((jsonRes) => {
        const { statusCode, error, responseBody, timestamp } = jsonRes;
        const result = { success: true, errorMessage: error, responseBody, timestamp, statusCode };

        // Status code 0 means the 3rd party server never responded (usually a time out).
        if (statusCode === 0) {
          result.success = false;
        } else if (statusCode >= 400) {
          result.success = false;
          // We only send the responseBody if `IncludeErrorResponseBody` is true for a given manifest - if it is enabled we can show the error
          result.errorMessage = `Status code: ${statusCode}${
            responseBody && error ? ` - ${truncateString(error, 120)}` : ''
          }`;
        }
        return result;
      }),
    )
    .catch(jsonToImmutableError);
}

async function deleteGoaltenderOAuthToken(integrationKey: string): Promise<Response> {
  return http.delete(`/trust/oauth/connect/${integrationKey}`).catch(jsonToImmutableError);
}

export type TriggerCreateArgs = {
  envKey: string;
  flagKey: string;
  projKey: string;
  kind: string;
  manifest: LaunchDarklyIntegrationsManifest;
};

async function createTrigger(triggerCreateArgs: TriggerCreateArgs): Promise<TriggerType> {
  const { envKey, flagKey, projKey, kind, manifest } = triggerCreateArgs;
  return http
    .post(`/api/v2/flags/${projKey}/${flagKey}/triggers/${envKey}`, {
      body: {
        instructions: [
          {
            kind,
          },
        ],
        integrationKey: manifest.key,
      },
    })
    .then(async (res) => res.json())
    .then((t) => {
      const enriched = t;
      enriched._manifest = manifest;
      enriched.isNew = true;
      return enriched;
    })
    .catch(jsonToImmutableError);
}

async function deleteTrigger(trigger: TriggerType): Promise<Response> {
  return http.delete(trigger._links.self.href, {
    beta: true,
  });
}

export type FlagInfo = { flagKey: string; envKey: string; projKey: string };

async function getTriggers(
  flagInfo: FlagInfo,
  triggerManifests: LaunchDarklyIntegrationsManifest[],
): Promise<TriggerType[]> {
  return http
    .get(`/api/v2/flags/${flagInfo.projKey}/${flagInfo.flagKey}/triggers/${flagInfo.envKey}`)
    .then(async (res) => res.json().then((jsonRes) => jsonRes.items))
    .then((triggers) =>
      triggers.map((trigger: TriggerType) => {
        const enriched = trigger;
        enriched._manifest = triggerManifests.find(
          (manifest) => manifest.key === enriched._integrationKey,
        ) as LaunchDarklyIntegrationsManifest;
        enriched.isNew = false;
        return enriched;
      }),
    )
    .catch(jsonToImmutableError);
}

async function patchTrigger(
  trigger: TriggerType,
  kind: string,
  triggerManifest: LaunchDarklyIntegrationsManifest,
): Promise<TriggerType> {
  return http
    .patch(trigger._links.self.href, {
      beta: true,
      body: {
        instructions: [
          {
            kind,
          },
        ],
      },
    })
    .then(async (res) => res.json())
    .then((triggerRes: TriggerType) => {
      const enriched = triggerRes;
      enriched._manifest = triggerManifest;
      enriched.isNew = kind === TriggerPatchKind.RESET_URL;
      return enriched;
    })
    .catch(jsonToImmutableError);
}

export type DynamicEnumOption = { label: string; value: string };

async function getDynamicEnumOptions(integrationKey: string, enumKey: string): Promise<DynamicEnumOption[]> {
  return http
    .get(`/api/v2/integration-manifests/${integrationKey}/dynamic-options/${enumKey}`, { beta: true })
    .then(async (res) => res.json())
    .then((jsonRes) => jsonRes.options)
    .catch(jsonToImmutableError);
}

type MappedMember = {
  id?: string;
  externalId: string;
  email: string;
  error?: string;
};

export type MappedApprovalMemberRes = {
  mapped: MappedMember[];
  unmapped: MappedMember[];
};

async function mapApprovalMembers(integrationKey: string): Promise<MappedMember[]> {
  return http
    .post(`/internal/integrations/${integrationKey}/capabilities/approval/map-members`)
    .then(async (res) => res.json())
    .then((jsonRes: MappedApprovalMemberRes) => jsonRes.mapped)
    .catch(jsonToImmutableError);
}

export {
  getAllSlackIncomingHooks,
  createSlackIncomingHook,
  updateSlackIncomingHook,
  deleteSlackIncomingHook,
  getAllDestinations,
  getGoaltenderManifests,
  createGoaltenderSubscription,
  getAllGoaltenderSubscriptions,
  getAllGoaltenderManifestsAndSubscriptions,
  getGoaltenderSubscriptions,
  updateGoaltenderSubscription,
  deleteGoaltenderSubscription,
  sendGoaltenderTestEvent,
  deleteGoaltenderOAuthToken,
  createTrigger,
  deleteTrigger,
  getTriggers,
  patchTrigger,
  getDynamicEnumOptions,
  mapApprovalMembers,
};
