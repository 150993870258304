import { closeSuggestInviteMembersModal, openSuggestInviteMembersModal } from 'actions/suggestInviteMembersModal';
import { SuggestInviteMembersModalProps } from 'components/inviteMembers/SuggestInviteMembersModal';
import { suggestInviteMembersModalSelector } from 'reducers/suggestInviteMembersModal';

import { useDispatch } from './useDispatch';
import { useSelector } from './useSelector';

export function useSuggestInviteMembersModal({ exposeProps }: { exposeProps?: boolean } = { exposeProps: false }) {
  const { showModal, props } = useSelector(suggestInviteMembersModalSelector);
  const dispatch = useDispatch();

  const openModal = (modalProps: SuggestInviteMembersModalProps) => dispatch(openSuggestInviteMembersModal(modalProps));
  const closeModal = () => {
    dispatch(closeSuggestInviteMembersModal());
    props?.onClose?.();
  };

  if (exposeProps) {
    return {
      openModal,
      closeModal,
      props,
      isModalOpen: showModal,
    };
  }

  return {
    openModal,
    closeModal,
    isModalOpen: showModal,
  };
}
