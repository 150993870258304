import { useMutation, useQueryClient } from '@tanstack/react-query';

import { restoreExperiment } from 'components/experimentation/common/api/experiment';
import { EXPERIMENTS_V2_KEY } from 'components/experimentation/common/hooks/useExperiment';

import { ServerErrorBody, ServerResponse } from '../api/api.types';

import { useAlerts } from './useAlerts';

type Props = {
  projKey: string;
  envKey: string;
  experimentKey: string;
};

export function useRestoreExperiment({ projKey, envKey, experimentKey }: Props) {
  const queryClient = useQueryClient();
  const { alertExperimentRestoreSucceeded, alertExperimentRestoreFailed } = useAlerts();

  return useMutation({
    mutationFn: async () => restoreExperiment(projKey, envKey, experimentKey),
    onSuccess: async () => {
      alertExperimentRestoreSucceeded();
      return queryClient.invalidateQueries({ queryKey: [EXPERIMENTS_V2_KEY, projKey, envKey, experimentKey] });
    },
    onError: async (error) => {
      const serverError = error as unknown as ServerResponse<ServerErrorBody>;
      const e = await serverError.json();
      alertExperimentRestoreFailed(e.code);
    },
  });
}
