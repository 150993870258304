import { closeInviteMembersModal, openInviteMembersModal } from 'actions/inviteMembersModal';
import { InviteMembersModalProps } from 'components/inviteMembers/InviteMembersModal';
import { inviteMembersModalSelector } from 'reducers/inviteMembersModal';

import { useDispatch } from './useDispatch';
import { useSelector } from './useSelector';

// The props returned from this hook are the props that were
// set on the global modal through redux. These are not the
// props that are to be passed to 'openModal'.
// If you are exposing them, you are doing so intentionally
// to read a value from the props.

// Lastly this hook should only be used directly if you need to check if the invite members modal is open, or if
// the functionality of InviteMembersControl does not satisfy your use case.
export function useInviteMembersModal({ exposeProps }: { exposeProps?: boolean } = { exposeProps: false }) {
  const { showModal, props } = useSelector(inviteMembersModalSelector);
  const dispatch = useDispatch();

  const openModal = (modalComponentProps: InviteMembersModalProps) =>
    dispatch(openInviteMembersModal(modalComponentProps));
  const closeModal = () => dispatch(closeInviteMembersModal());

  if (exposeProps) {
    return {
      openModal,
      closeModal,
      props,
      isModalOpen: showModal,
    };
  }

  return {
    openModal,
    closeModal,
    isModalOpen: showModal,
  };
}
