import { type GetFeatureFlagsQueryParams } from '@gonfalon/openapi';

import { creationDateRangeToDate } from './creationDateRangeToDate';
import { evaluatedDateRangeToDate } from './evaluatedDateRangeToDate';
import { type UIFlagFilter, baseLifecycleStatesSchema, staleStatesSchema } from './types';

export function serializeUIFlagFilterForAPI(uiFilter: UIFlagFilter) {
  const apiFilter: NonNullable<GetFeatureFlagsQueryParams['filter']> = {};
  apiFilter.query = uiFilter.query;
  apiFilter.hasDataExport = uiFilter.hasDataExport;
  apiFilter.hasExperiment = uiFilter.hasExperiment;
  apiFilter.maintainerId = uiFilter.maintainerId;
  apiFilter.maintainerTeamKey = uiFilter.maintainerTeamKey;
  apiFilter.tags = uiFilter.tags;
  apiFilter.type = uiFilter.type;
  apiFilter.sdkAvailability = uiFilter.sdkAvailability;
  apiFilter.filterEnv = uiFilter.filterEnv;
  apiFilter.status = uiFilter.status;
  // Since the UI contains all potential states
  const validAPIFilterState = baseLifecycleStatesSchema.safeParse(uiFilter.state);
  const validAPIFilterStateStates = staleStatesSchema.safeParse(uiFilter.state);
  apiFilter.state = validAPIFilterState.success ? validAPIFilterState.data : undefined;
  apiFilter.staleStates = validAPIFilterStateStates.success ? validAPIFilterStateStates.data : undefined;
  apiFilter.targeting = uiFilter.targeting;
  apiFilter.contextKindTargeted = uiFilter.contextKindTargeted;
  apiFilter.contextKindsEvaluated = uiFilter.contextKindsEvaluated;
  apiFilter.followerId = uiFilter.followerId;
  apiFilter.applicationEvaluated = uiFilter.applicationEvaluated;
  apiFilter.segmentTargeted = uiFilter.segmentTargeted;
  apiFilter.excludeSettings = uiFilter.excludeSettings;
  apiFilter.applicationEvaluated = uiFilter.applicationEvaluated;

  if (uiFilter.codeReferences !== undefined) {
    apiFilter.codeReferences = uiFilter.codeReferences ? { min: 1 } : { max: 0 };
  }

  const now = Date.now();

  if (uiFilter.evaluated !== undefined) {
    const evaluatedDate = evaluatedDateRangeToDate(uiFilter.evaluated, now);
    apiFilter.evaluated = evaluatedDate ? { after: evaluatedDate.getTime() } : undefined;
  }

  if (uiFilter.created !== undefined) {
    const createdDate = creationDateRangeToDate(uiFilter.created, now);
    apiFilter.creationDate = createdDate ? { before: createdDate.getTime() } : undefined;
  }

  return apiFilter;
}
