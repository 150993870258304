import { type Key, type ReactNode, ForwardedRef, forwardRef, useMemo } from 'react';
import { DropIndicator, GridList, GridListItem, GridListProps, Text, useDragAndDrop } from 'react-aria-components';
import { Path, useNavigate } from 'react-router-dom';
import { ProjectContext, useProjectContext } from '@gonfalon/context';
import { useScrollIntoViewOnMount } from '@gonfalon/dom';
import { EnvironmentMarker, InternalEnvironment } from '@gonfalon/environments';
import { noop } from '@gonfalon/es6-utils';
import { toEditProjectEnvironment } from '@gonfalon/navigator';
import { type JSONPatch, useUpdateFollowPreferences } from '@gonfalon/rest-api';
import { useThemeValue } from '@gonfalon/theme';
import {
  Header,
  IconButton,
  Menu,
  MenuItem,
  MenuTrigger,
  Popover,
  Section,
  SnackbarQueue,
  ToastQueue,
  Tooltip,
  TooltipTrigger,
} from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';
import nullthrows from 'nullthrows';
import { z } from 'zod';

import { useFavoriteEnvironmentIds } from '../EnvironmentController/useFavoriteEnvironmentIds';
import { EnvironmentFilters } from '../EnvironmentFilters';
import { type SelectedEnvironment } from '../types';

import { computeRingColor } from './computeRingColor';
import { ReplaceEnvironmentMenuTrigger } from './ReplaceEnvironmentMenuTrigger';

import styles from './EnvironmentSelector.module.css';

const mimetype = 'custom/launchdarkly';

const dragItemDataSchema = z.object({ id: z.string(), name: z.string(), color: z.string() });

function parseDragItemData(data: string) {
  return dragItemDataSchema.parse(JSON.parse(data));
}

const configurationActionSchema = z.enum([
  'copy-key',
  'copy-sdk',
  'copy-mobile',
  'copy-client-side',
  'edit-environment-entity',
  'configure-entity-in-environment',
  'compare-flags',
  'favorite',
]);
function assertConfigurationAction(action: unknown): asserts action is z.infer<typeof configurationActionSchema> {
  configurationActionSchema.parse(action);
}

function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text).then(noop, noop);
}

type EnvironmentSelectorProps = GridListProps<SelectedEnvironment> & {
  settingsMenuItems?: { header: string; items: Array<{ label: string; to?: Path; onAction?: () => void }> };
  projectKey: string;
  addButton?: ReactNode;
  excludedIds: Set<string>;
  onReorder: (environmentKey: Key, position: 'before' | 'after', otherKeys: Set<Key>) => void;
  onRemove: (environmentKey: Key) => void;
  onReplace: (sourceEnvironment: Key, targetEnvironment: InternalEnvironment) => void;
};

export function EnvironmentSelector({
  projectKey,
  children,
  selectionMode = 'single',
  settingsMenuItems,
  addButton,
  excludedIds,
  onReorder,
  onRemove,
  onReplace,
  ...props
}: EnvironmentSelectorProps) {
  const { context } = useProjectContext();
  const list = Array.from(props.items || []);

  const { dragAndDropHooks } = useDragAndDrop({
    acceptedDragTypes: ['text/plain', mimetype],
    getItems(keys) {
      return [...keys].map((key) => {
        const item = nullthrows(list.find((env) => env.key === key));
        return {
          'text/plain': item.key,
          [mimetype]: JSON.stringify({ id: item._id, name: item.name, color: item.color }),
        };
      });
    },
    onReorder(event) {
      if (event.target.dropPosition === 'on') {
        return;
      }

      onReorder(event.target.key, event.target.dropPosition, event.keys);
    },
    renderDropIndicator(target) {
      return <DropIndicator target={target} className={styles.dropIndicator} />;
    },
    renderDragPreview([item]) {
      const data = parseDragItemData(item[mimetype]);
      return (
        <div className={styles.dragPreview}>
          <EnvironmentMarker color={data.color} />
          {data.name}
        </div>
      );
    },
  });

  const { ref } = useScrollIntoViewOnMount<HTMLDivElement>();

  return (
    <div className={styles.environmentSelector}>
      <GridList
        {...props}
        layout="grid"
        className={styles.list}
        aria-label="Selected environments"
        dragAndDropHooks={dragAndDropHooks}
        selectionMode={selectionMode === 'single' ? 'single' : 'multiple'}
      >
        {(item) => (
          <GridListItem textValue={item.name} id={item.key} className={styles.item}>
            {({ isSelected }) => (
              <EnvironmentSelectorItem
                ref={ref}
                item={item}
                excludedIds={excludedIds}
                context={context}
                isSelected={isSelected}
                settingsMenuItems={settingsMenuItems}
                onRemove={list.length >= 2 ? onRemove : undefined}
                onReplace={onReplace}
              />
            )}
          </GridListItem>
        )}
      </GridList>
      {addButton ? <div className={styles.add}>{addButton}</div> : null}
    </div>
  );
}

type EnvironmentSelectorItemProps = {
  context: ProjectContext;
  item: SelectedEnvironment;
  excludedIds: Set<string>;
  isSelected: boolean;
  settingsMenuItems?: { header: string; items: Array<{ label: string; to?: Path; onAction?: () => void }> };
  onRemove?: (environmentKey: Key) => void;
  onReplace: (sourceEnvironment: Key, targetEnvironment: InternalEnvironment) => void;
};

const EnvironmentSelectorItem = forwardRef(
  (
    { item, excludedIds, context, isSelected, settingsMenuItems, onRemove, onReplace }: EnvironmentSelectorItemProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const navigate = useNavigate();
    const favorites = useFavoriteEnvironmentIds();
    const isFavorite = favorites.includes(item._id);

    const theme = useThemeValue();
    const ringColor = useMemo(() => computeRingColor(`#${item.color}`, theme ?? 'default'), [item.color, theme]);

    const { mutate } = useUpdateFollowPreferences();

    const updateFavorite = (instructions: JSONPatch) =>
      mutate(
        { body: instructions },
        {
          onSuccess() {
            ToastQueue.success(isFavorite ? 'Removed favorite' : 'Added favorite');
          },
          onError() {
            SnackbarQueue.error({ description: 'Failed to update tags' });
          },
        },
      );

    return (
      <div
        ref={isSelected ? ref : undefined}
        className={styles.environment}
        style={{
          // @ts-expect-error --environment-ring-color is not a known css property
          '--environment-ring-color': ringColor,
        }}
      >
        {/* @ts-expect-error RAC adds an aria label */}
        <IconButton slot="drag" variant="minimal" icon="grip-horiz" size="small" className={styles.drag} />

        <EnvironmentMarker className={styles.marker} color={item.color} />
        <div className={styles.name} data-text={item.name}>
          {item.name}
        </div>

        <TooltipTrigger>
          <ReplaceEnvironmentMenuTrigger
            className={styles.swap}
            projectKey={context.projectKey}
            excludedIds={new Set([item._id, ...Array.from(excludedIds)])}
            onSelect={(env) => {
              onReplace(item.key, env);
            }}
          />
          <Tooltip placement="bottom">Swap environment</Tooltip>
        </TooltipTrigger>

        <MenuTrigger>
          <TooltipTrigger>
            <IconButton
              aria-label="Environment configuration"
              variant="minimal"
              icon="more-vert"
              size="small"
              className={styles.actions}
            />
            <Tooltip placement="bottom">More environment actions</Tooltip>
          </TooltipTrigger>
          <Popover placement="bottom end">
            <Menu
              onAction={(action) => {
                assertConfigurationAction(action);
                switch (action) {
                  case 'edit-environment-entity':
                    navigate(
                      toEditProjectEnvironment({
                        projectKey: context.projectKey,
                        environmentKey: item.key,
                      }),
                    );
                    break;
                  case 'favorite':
                    if (isFavorite) {
                      const found: number[] = [];
                      for (const [index, id] of favorites.entries()) {
                        if (id === item._id) {
                          found.push(index);
                        }
                      }

                      if (found.length === 0) {
                        return;
                      }

                      const instructions = [];
                      for (const index of found) {
                        instructions.push({
                          op: 'remove',
                          path: `/resources/${index}`,
                        } as const);
                      }

                      updateFavorite(instructions);
                    } else {
                      if (favorites.findIndex((value) => item._id === value) !== -1) {
                        return;
                      }

                      updateFavorite([
                        {
                          op: 'add',
                          path: '/resources/-',
                          value: { id: item._id, resourceKind: 'environment' },
                        },
                      ]);
                    }
                    break;
                  default:
                    copyKey(action, item);
                    break;
                }
              }}
            >
              <Section>
                <Header>Copy…</Header>
                <MenuItem id="copy-key">
                  <Text slot="label">Environment key</Text>
                </MenuItem>
                <MenuItem id="copy-sdk">
                  <Text slot="label">SDK key</Text>
                </MenuItem>
                <MenuItem id="copy-mobile">
                  <Text slot="label">Mobile key</Text>
                </MenuItem>
                <MenuItem id="copy-client-side">
                  <Text slot="label">Client-side ID</Text>
                </MenuItem>
              </Section>
              <Section>
                <MenuItem id="favorite" className={styles.favoriteItem} data-favorite={isFavorite ? true : undefined}>
                  <Text slot="label">
                    {isFavorite ? (
                      <>
                        <Icon name="star" size="small" />
                        Remove from favorites
                      </>
                    ) : (
                      <>
                        <Icon name="star-outline" size="small" />
                        Add to favorites
                      </>
                    )}
                  </Text>
                </MenuItem>
              </Section>
              <Section>
                <MenuItem id="edit-environment-entity">
                  <Text slot="label">Edit environment</Text>
                </MenuItem>
              </Section>
              {settingsMenuItems !== undefined ? (
                <Section>
                  <Header>{settingsMenuItems.header}</Header>
                  {settingsMenuItems.items.map((menuItem) => {
                    let onAction = menuItem.onAction;
                    if (menuItem.to) {
                      onAction = () => {
                        const correctTo = {
                          ...menuItem.to,
                        };
                        navigate(correctTo);
                      };
                    }
                    return (
                      <MenuItem key={menuItem.label} id={menuItem.label} onAction={onAction}>
                        <Text slot="label">{menuItem.label}</Text>
                      </MenuItem>
                    );
                  })}
                </Section>
              ) : null}
            </Menu>
          </Popover>
        </MenuTrigger>
        <EnvironmentFilters environment={item} />
        {onRemove !== undefined ? (
          <TooltipTrigger>
            <IconButton
              aria-label="remove environment"
              icon="cancel"
              size="small"
              variant="minimal"
              className={styles.remove}
              onPress={() => {
                onRemove(item.key);
              }}
            />
            <Tooltip placement="bottom">Close environment</Tooltip>
          </TooltipTrigger>
        ) : null}
      </div>
    );
  },
);

function copyKey(action: z.infer<typeof configurationActionSchema>, item: SelectedEnvironment) {
  if (action === 'copy-key') {
    copyToClipboard(item.key);
    ToastQueue.success('Environment key copied to clipboard');
    return;
  }

  let key: string;
  if (action === 'copy-client-side') {
    key = item._id;
  } else if (action === 'copy-mobile') {
    key = item.mobileKey;
  } else if (action === 'copy-sdk') {
    key = item.apiKey;
  } else {
    return;
  }

  copyToClipboard(key);

  ToastQueue.success('SDK key copied to clipboard');
}
