import { useMatches } from 'react-router-dom';
import { z } from 'zod';

const environmentModeSchema = z.enum(['all', 'single', 'multiple']);

const environmentModeHandleSchema = z.object({
  environmentMode: environmentModeSchema.optional(),
});

type EnvironmentMode = z.infer<typeof environmentModeSchema>;

export function useEnvironmentMode() {
  const matches = useMatches();
  const reversedMatches = [...matches].reverse();

  let mode: EnvironmentMode | undefined;
  for (const match of reversedMatches) {
    const parse = environmentModeHandleSchema.safeParse(match.handle);
    if (parse.success) {
      if (parse.data.environmentMode !== undefined) {
        mode = parse.data.environmentMode;
        break;
      }
    }
  }

  return mode ?? 'all';
}
