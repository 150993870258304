import { SuggestInviteMembersModalAction } from 'actions/suggestInviteMembersModal';
import { SuggestInviteMembersModalProps } from 'components/inviteMembers/SuggestInviteMembersModal';
import { GlobalState } from 'reducers';
import registry from 'reducers/registry';

type SuggestInviteMembersModalState = {
  showModal: boolean;
  props: SuggestInviteMembersModalProps;
};

export const initialState: SuggestInviteMembersModalState = {
  showModal: false,
  props: { referrer: '' },
};

export function suggestInviteMembersModal(
  state: SuggestInviteMembersModalState = initialState,
  action: SuggestInviteMembersModalAction,
) {
  switch (action.type) {
    case 'suggestInviteMembersModal/OPEN_MODAL':
      return { ...state, showModal: true, props: action.props };
    case 'suggestInviteMembersModal/CLOSE_MODAL':
      return { ...state, showModal: false, props: { referrer: '' } };
    default:
      return state;
  }
}

export const suggestInviteMembersModalSelector = (state: GlobalState) => state.suggestInviteMembersModal;

registry.addReducers({ suggestInviteMembersModal });
