import { isArray, isBoolean, isEmpty, isEqual, isNil, isNumber, isPlainObject, isString } from '@gonfalon/es6-utils';
import { isJSONString } from '@gonfalon/types';

export enum ValueType {
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  STRING = 'string',
  JSON = 'json',
}

export function isTypedArray<T>(v: unknown, check: (v: unknown) => v is T): v is T[] {
  if (!Array.isArray(v)) {
    return false;
  }

  for (const item of v) {
    if (!check(item)) {
      return false;
    }
  }

  return true;
}

export function isStringArray(value: unknown): value is string[] {
  return isTypedArray(value, isString);
}

export function isValueTypeString(v: unknown): v is ValueType {
  return v === ValueType.BOOLEAN || v === ValueType.JSON || v === ValueType.NUMBER || v === ValueType.STRING;
}

export function nilFilter<T>(v: T | undefined): v is T {
  return v !== undefined;
}

export const isJSONValue = (v?: any) => isPlainObject(v) || isArray(v);

export const couldBeJSONValue = (v?: any) => isJSONString(v) || isJSONValue(v);

export const couldBeNumber = (v?: any) => isFinite(v) && !isNaN(parseFloat(v));

export const couldBeNumerical = (v: any) => couldBeNumber(v) && !(v.endsWith && v.endsWith('.'));

export const couldBeBoolean = (v?: any) => isBoolean(v) || v === 'true' || v === 'false' || v === '0' || v === '1';

export const areAllBooleans = (values: any[]) => !!values.length && values.every(isBoolean);

export const areAllNumbers = (values: any[]) => !!values.length && values.every(isNumber);

export const areAllStrings = (values: any[]) => !!values.length && values.every(isString);

export const areAllJSON = (values: any[]) => !!values.length && values.every(isJSONValue);

export const couldAllBeNumbers = (values: any[]) => values.every(couldBeNumber);

export const couldAllBeBooleans = (values: any[]) => values.every(couldBeBoolean);

export const couldAllBeJSON = (values: any[]) => values.every(couldBeJSONValue);

export function getAlternateType(v: any) {
  const type = typeof v;
  if (type !== 'string') {
    return 'string';
  }
  if (couldBeNumber(v)) {
    return 'number';
  }
  if (couldBeBoolean(v)) {
    return 'boolean';
  }
}

export function stringifyValue(val?: any): string {
  if (isNil(val)) {
    return 'null';
  }
  return isJSONValue(val) ? JSON.stringify(val) : val.toString();
}

export function coerceToType(value: any, newType?: 'number' | 'string' | 'boolean' | 'json') {
  if (typeof value === newType) {
    return value;
  }
  if (newType === 'string') {
    return stringifyValue(value);
  } else if (newType === 'number') {
    return couldBeNumber(value) ? +value : value;
  } else if (newType === 'boolean') {
    return couldBeBoolean(value) ? value === 'true' : value;
  } else if (newType === 'json' && isJSONString(value)) {
    return JSON.parse(value);
  }
  return value;
}

export function inferType(values: any[]) {
  const vs: string[] = values
    .filter((value) => isEmptyValue(value))
    .map((value) => {
      // if the value is an immutable Record or other object, return it (because toString will return undesirable results)
      if (value instanceof Object) {
        return value;
      }
      // otherwise try to reset everything to strings so the following checks work properly
      return value.toString ? value.toString() : value;
    });

  if (vs.length === 0) {
    return ValueType.STRING;
  }

  const booleans = new Set([true, false]);

  const asBooleans: Set<boolean> = new Set(
    vs
      .map((v) => {
        switch (v) {
          case 'true':
            return true;
          case 'false':
            return false;
          default:
            return undefined;
        }
      })
      .filter(nilFilter),
  );

  if (isEqual(new Set(vs), booleans) || isEqual(asBooleans, booleans)) {
    return ValueType.BOOLEAN;
  }

  if (vs.every(couldBeNumerical)) {
    return ValueType.NUMBER;
  }

  if (couldAllBeJSON(vs)) {
    return ValueType.JSON;
  }

  return ValueType.STRING;
}

export const isEmptyValue = (value: any) => !isBoolean(value) && !couldBeNumber(value) && isEmpty(value);

export function isHomogeneousArray(values: any[]) {
  const vs = values.filter((v) => isEmptyValue(v));
  return vs.every(isString) || vs.every(isFinite) || vs.every(isJSONValue) || vs.every(isBoolean);
}

export function detectType(values: any[]) {
  const vs = values.filter((value) => isEmptyValue(value)).map((value) => value);

  if (!vs.length) {
    return ValueType.STRING;
  }

  if (areAllBooleans(vs)) {
    return ValueType.BOOLEAN;
  }

  if (areAllNumbers(vs)) {
    return ValueType.NUMBER;
  }

  if (areAllJSON(vs)) {
    return ValueType.JSON;
  }

  return ValueType.STRING;
}
