import { Immutable } from 'immer';
import { List } from 'immutable';
import { normalize, schema } from 'normalizr';
import qs from 'qs';

import { CustomWorkflowStage } from 'utils/customWorkflowUtils';
import http, { jsonToImmutableError, middleware } from 'utils/httpUtils';
import { WorkflowTemplate, WorkflowTemplateSummary } from 'utils/workflowTemplateUtils';

export type WorkflowTemplateEntities = { [id: string]: WorkflowTemplate };
export type WorkflowTemplateSummaryEntities = { [id: string]: WorkflowTemplateSummary };
export type WorkflowTemplateIds = string[];
export type WorkflowTemplatesResponse = Immutable<{
  entities: { workflowTemplates?: WorkflowTemplateEntities };
  result: { items?: WorkflowTemplateIds };
}>;
export type WorkflowTemplateSummariesResponse = Immutable<{
  entities: { workflowTemplates?: WorkflowTemplateSummaryEntities };
  result: { items?: WorkflowTemplateIds };
}>;

const workflowTemplatesEntity = new schema.Entity('workflowTemplates', {}, { idAttribute: '_id' });

const BASE_URL = '/api/v2/templates';

export type WorkflowTemplatesSearchParams = { search?: string; summary?: boolean };

export async function getWorkflowTemplates(searchParams: {
  search?: string;
  summary: true;
}): Promise<WorkflowTemplateSummariesResponse>;
export async function getWorkflowTemplates(searchParams?: {
  search?: string;
  summary?: false;
}): Promise<WorkflowTemplatesResponse>;
export async function getWorkflowTemplates<T extends WorkflowTemplatesSearchParams>(
  searchParams?: T,
): Promise<T extends { summary: true } ? WorkflowTemplateSummariesResponse : WorkflowTemplatesResponse> {
  let url = BASE_URL;

  if (searchParams) {
    const finalSearchParams = Object.entries(searchParams).reduce(
      (params, [key, value]) => (value ? { ...params, [key]: value } : params),
      {} as WorkflowTemplatesSearchParams,
    );

    if (Object.keys(finalSearchParams).length) {
      url += `?${qs.stringify(finalSearchParams)}`;
    }
  }

  return http
    .get(url, { beta: true })
    .then(async (response) => {
      const rawJSON = await middleware.json(response);
      return normalize(rawJSON, { items: [workflowTemplatesEntity] });
    })
    .catch(async (error) =>
      jsonToImmutableError(error).then((e) => {
        throw e;
      }),
    );
}

export type CreateWorkflowTemplateBody = {
  name: string;
  description?: string;
  key: string;
} & (
  | { workflowId: string; projectKey?: never; environmentKey?: never; flagKey?: never; stages?: never }
  | {
      projectKey: string;
      environmentKey: string;
      flagKey: string;
      stages: List<CustomWorkflowStage>;
      workflowId?: never;
    }
);

export async function createNewWorkflowTemplateFromWorkflow(
  body: CreateWorkflowTemplateBody,
): Promise<WorkflowTemplate> {
  return http
    .post(BASE_URL, {
      beta: true,
      body: body.stages ? { ...body, stages: body.stages.map((stage) => stage.toRep()).toJS() } : body,
    })
    .then(async (response) => middleware.json(response))
    .catch(async (error) =>
      jsonToImmutableError(error).then((e) => {
        throw e;
      }),
    );
}
