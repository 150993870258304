import { useState } from 'react';
import { integrationConfigurationQuery } from '@gonfalon/rest-api';
import { ProgressBar } from '@launchpad-ui/components';
import { useQuery } from '@tanstack/react-query';

import DynamicGoaltenderFormVariable, {
  IntegrationFormContextProvider,
} from 'components/integrations/DynamicGoaltenderFormElement';
import { GoaltenderFormElementValue } from 'components/integrations/GoaltenderFormElement';
import { useCurrentEnvironmentEntity } from 'reducers/projects';
import { FormVariable, LaunchDarklyIntegrationsManifest } from 'types/generated/integrationSchema';

export type IntegrationConfig = Record<string, GoaltenderFormElementValue>;

type ApprovalIntegrationFieldsProps = {
  approvalServiceKind: string;
  manifest: LaunchDarklyIntegrationsManifest;
  onChange(newConfig: IntegrationConfig): void;
};

export function ApprovalIntegrationFields({ approvalServiceKind, manifest, onChange }: ApprovalIntegrationFieldsProps) {
  const environment = useCurrentEnvironmentEntity();
  const serviceKindConfigurationId = environment.approvalSettings?.serviceKindConfigurationId;
  const [config, setConfig] = useState<Record<string, GoaltenderFormElementValue>>({});

  const { data: integrationConfig, isFetched: integrationConfigFetched } = useQuery({
    ...integrationConfigurationQuery(
      // We are only enabling this query if we have an serviceKindConfigurationId
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      { integrationConfigurationId: serviceKindConfigurationId! },
    ),
    enabled: !!serviceKindConfigurationId,
  });

  const manifestFormVariables = manifest.capabilities?.approval?.approvalFormVariables;
  // TODO: Fix openAPI generated types to ensure key isn't optional
  // It looks like the actual definition is correct at vendor/github.com/launchdarkly/goaltender/manifest/manifest.go:FormVariable
  // The definition we get from the openApi generated types marks all fields as optional at packages/openapi/src/generated/openapi.ts:FormVariable
  const integrationConfigFormVariables: FormVariable[] | undefined = integrationConfig?.capabilityConfig?.approvals
    ?.additionalFormVariables as FormVariable[] | undefined;

  if (serviceKindConfigurationId && !integrationConfigFetched) {
    return <ProgressBar aria-label="Loading…" isIndeterminate />;
  }

  if (!manifestFormVariables) {
    return <></>;
  }
  return (
    <IntegrationFormContextProvider
      manifest={manifest}
      environment={environment}
      formVariableType="approvalFormVariables"
    >
      {manifestFormVariables.map((formVar) => (
        <DynamicGoaltenderFormVariable
          key={formVar.key}
          formVar={formVar}
          onChange={(_, value) => {
            config[formVar.key] = value;
            setConfig(config);
            onChange(config);
          }}
          value={undefined}
          disabled={false}
          integrationKey={approvalServiceKind}
          isRestricted={false}
        />
      ))}
      {integrationConfigFormVariables?.map((additionalFormVariable) => (
        <DynamicGoaltenderFormVariable
          key={additionalFormVariable.key}
          formVar={additionalFormVariable}
          onChange={(_, value) => {
            config[additionalFormVariable.key] = value;
            setConfig(config);
            onChange(config);
          }}
          value={undefined}
          disabled={false}
          integrationKey={approvalServiceKind}
          isRestricted={false}
        />
      ))}
    </IntegrationFormContextProvider>
  );
}
