import nullthrows from 'nullthrows';

import { ModernSubscription, PlanTypes } from '../../types';

export function is2021Plan(subscription: ModernSubscription) {
  return [
    PlanTypes.STARTER2021,
    PlanTypes.PROFESSIONAL2021,
    PlanTypes.STANDARD_TRIAL2021,
    PlanTypes.ENTERPRISE_TRIAL,
  ].includes(nullthrows(subscription.planType));
}
