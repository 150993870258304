import { mergeSearchParams } from '@gonfalon/router';

import { serializeUIFlagFilterForBrowser } from './serializeUIFlagFilterForBrowser';
import { UIFlagListView } from './types';

export function serializeUIFlagListViewForBrowser(ui: UIFlagListView) {
  let searchParams = new URLSearchParams();

  if ((ui.offset ?? 0) > 0) {
    searchParams.set('offset', String(ui.offset));
  }

  if (ui.sort) {
    searchParams.set('sort', ui.sort);
  }

  const filter = ui.filter;
  if (filter !== undefined) {
    const filterParams = serializeUIFlagFilterForBrowser(filter);
    searchParams = mergeSearchParams(searchParams, filterParams);
  }

  return searchParams;
}
