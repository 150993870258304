import { stringifyValue } from '@gonfalon/types';
import { List } from 'immutable';

import { Flag, Variation } from 'utils/flagUtils';
import { InstructionCategory, InstructionsType, SemanticInstruction } from 'utils/instructions/shared/types';
import { UserSettings } from 'utils/userUtils';

import {
  AddUserTargetsSemanticInstruction,
  RemoveUserTargetsSemanticInstruction,
  ReplaceUserTargetsSemanticInstruction,
  UpdateUserTargetsSemanticInstruction,
  UserTargetsInstructionKind,
} from './types';

export function makeUpdateUserTargetsKey(variationId: string, instructionKind: UserTargetsInstructionKind): string {
  return [InstructionCategory.USER_TARGETS, variationId, instructionKind].join('|');
}

export function makeAddUserTargetsInstruction(
  userKeys: string[],
  variationId: string,
): AddUserTargetsSemanticInstruction {
  return {
    kind: UserTargetsInstructionKind.ADD_USER_TARGETS,
    values: userKeys,
    variationId,
  };
}

export function makeRemoveUserTargetsInstruction(
  userKeys: string[],
  variationId: string,
): RemoveUserTargetsSemanticInstruction {
  return {
    kind: UserTargetsInstructionKind.REMOVE_USER_TARGETS,
    values: userKeys,
    variationId,
  };
}

export const getTargetsInstructionsFromUserSettings = (
  userKey: string,
  flag: Flag,
  originalUserSetting: UserSettings,
  targetVariation?: Variation,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let originalVariationValue: any = null;
  let isOriginalValueDefault = true;
  originalVariationValue = stringifyValue(originalUserSetting._value);
  if (originalUserSetting.setting !== null) {
    isOriginalValueDefault = false;
    originalVariationValue = stringifyValue(originalUserSetting.setting);
  }
  const newVariationValue = targetVariation ? stringifyValue(targetVariation.value) : null;
  if (originalVariationValue === newVariationValue && !isOriginalValueDefault) {
    return [];
  }

  const shouldAddToNewVariation = newVariationValue !== null;
  const shouldRemoveFromCurrVariation =
    (newVariationValue === null && !isOriginalValueDefault) || (shouldAddToNewVariation && !isOriginalValueDefault);

  const instructions = [];
  if (shouldRemoveFromCurrVariation) {
    const currVariationId = flag.variations.find((v) => stringifyValue(v.value) === originalVariationValue)?._id;
    if (currVariationId) {
      instructions.push(makeRemoveUserTargetsInstruction([userKey], currVariationId));
    }
  }
  if (shouldAddToNewVariation) {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    instructions.push(
      makeAddUserTargetsInstruction([userKey], targetVariation!._id),
    ); /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }
  return instructions;
};

// sortUserTargetInstructions puts all of the remove user target instructions before all of the
// add user target instructions. This ensures that if a user is moving from one variation to another,
// there won't be an error where we try to add the user before removing it from the current variation.
export function sortUserTargetInstructions(
  instructions: List<SemanticInstruction> | undefined,
): List<SemanticInstruction> {
  if (!instructions) {
    return List();
  }
  const removeUserTargets = instructions.filter((i) => i.kind === UserTargetsInstructionKind.REMOVE_USER_TARGETS);
  const addUserTargets = instructions.filter((i) => i.kind === UserTargetsInstructionKind.ADD_USER_TARGETS);
  return removeUserTargets.concat(addUserTargets);
}

// Visible for test
export function combineUserTargetsInstructions(
  newInstruction: UpdateUserTargetsSemanticInstruction,
  pendingSemanticPatch: InstructionsType,
): InstructionsType {
  const variationId = newInstruction.variationId;
  const addKey = makeUpdateUserTargetsKey(variationId, UserTargetsInstructionKind.ADD_USER_TARGETS);
  const removeKey = makeUpdateUserTargetsKey(variationId, UserTargetsInstructionKind.REMOVE_USER_TARGETS);
  let add = pendingSemanticPatch.get(addKey) as AddUserTargetsSemanticInstruction;
  let remove = pendingSemanticPatch.get(removeKey) as RemoveUserTargetsSemanticInstruction;

  if (newInstruction.kind === UserTargetsInstructionKind.ADD_USER_TARGETS) {
    if (!add) {
      add = makeAddUserTargetsInstruction([], variationId);
    }
    // Reverse to match order that the server will add.
    for (const value of newInstruction.values.reverse()) {
      if (remove && remove.values.includes(value)) {
        remove = { ...remove, values: remove.values.filter((target: string) => target !== value) };
      } else if (!add.values.includes(value)) {
        add = { ...add, values: [value, ...add.values] };
      }
    }
  } else if (newInstruction.kind === UserTargetsInstructionKind.REMOVE_USER_TARGETS) {
    if (!remove) {
      remove = makeRemoveUserTargetsInstruction([], variationId);
    }
    for (const value of newInstruction.values) {
      if (add && add.values.includes(value)) {
        add = { ...add, values: add.values.filter((target: string) => target !== value) };
      } else if (!remove.values.includes(value)) {
        remove = { ...remove, values: [value, ...remove.values] };
      }
    }
  }

  let result = pendingSemanticPatch;

  // clear old instructions
  result = result.delete(addKey).delete(removeKey);

  if (add && add.values.length > 0) {
    result = result.set(addKey, add);
  }
  if (remove && remove.values.length > 0) {
    result = result.set(removeKey, remove);
  }
  return result;
}

export function makeReplaceUserTargetsInstruction(
  targets: Array<{ variationId: string; values: string[] }>,
): ReplaceUserTargetsSemanticInstruction {
  return {
    kind: UserTargetsInstructionKind.REPLACE_USER_TARGETS,
    targets,
  };
}

export function hasEmptyUserTargets(instruction: ReplaceUserTargetsSemanticInstruction) {
  return instruction.targets.every((v) => v.values.length === 0);
}
