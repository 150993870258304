/* eslint-disable @typescript-eslint/no-empty-function */

declare global {
  /* eslint-disable no-var, @typescript-eslint/naming-convention */
  var __DEV__: boolean;
  var __TEST__: boolean;
  /* eslint-enable no-var, @typescript-eslint/naming-convention */
}

const warn = __DEV__
  ? function warn(message: string) {
      // eslint-disable-next-line no-console
      console.warn('Warning:', message);
    }
  : () => {};

export const warning = __DEV__
  ? function _warning(condition: unknown, message: string) {
      if (Boolean(condition)) {
        warn(message);
      }
    }
  : () => {};
