import { has, isEmpty } from '@gonfalon/es6-utils';

import { FilterKind, FilterValue } from 'components/ui/multipleFilters/types';
import FlagFilterInterface, { BackendQueryParamName, QueryParamName } from 'utils/flagFilters/flagFilterInterface';
import { FilterOperator, FlagListBackendQueryParam, FlagListQueryParam } from 'utils/flagFilters/types';

enum TargetingOption {
  ON = 'on',
  OFF = 'off',
}

const flagTargetingFilterOptions: Record<TargetingOption, string> = {
  on: 'On',
  off: 'Off',
};

const TARGETING_DISPLAY_NAME = 'Targeting';

class TargetingFilter implements FlagFilterInterface<string> {
  name: string;
  value?: string;
  kind: FilterKind;
  queryParamName: QueryParamName;
  backendQueryParamName: BackendQueryParamName;
  operators: string[];
  defaultValue: string;

  constructor(value?: string) {
    this.name = TARGETING_DISPLAY_NAME;
    this.value = value;
    this.kind = FilterKind.TARGETING;
    this.queryParamName = FlagListQueryParam.TARGETING;
    this.backendQueryParamName = FlagListBackendQueryParam.TARGETING;
    this.operators = [FilterOperator.IS];
    this.defaultValue = TargetingOption.ON;
  }

  getFilterValueDisplayName(value?: FilterValue) {
    if (!value) {
      return '';
    }
    return flagTargetingFilterOptions[value as TargetingOption];
  }

  getDisplayName = () => this.name;

  getOptions() {
    return Object.entries(flagTargetingFilterOptions).map(([value, label]) => ({
      label,
      name: label,
      value,
      filterOption: this.kind,
      isChecked: this.value === value,
    }));
  }

  isEnabled = () => true;

  isEmpty = () => isEmpty(this.value);

  toQuery = () => this.value;

  isInvalidFilter = () =>
    !this.isEmpty() &&
    !(
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      has(flagTargetingFilterOptions, this.value!)
    ) /* eslint-enable @typescript-eslint/no-non-null-assertion */;

  clearFilter = () => (this.value = undefined);

  toBackendQuery() {
    if (this.isEmpty()) {
      return '';
    }
    return `targeting:${this.value}`;
  }

  parseFilterParam(queryParts: string[]) {
    const [, value] = queryParts;
    return value;
  }

  getFilterData() {
    return {
      operators: this.operators,
      options: this.getOptions(),
      defaultValue: this.defaultValue,
    };
  }
}

/* eslint-disable import/no-default-export */
export default TargetingFilter;
