import { isCodeReferencesEnabled, isDisableCodeReferencesWithOverrideEnabled } from '@gonfalon/dogfood-flags';
import { isEmpty } from '@gonfalon/es6-utils';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, OrderedMap, Record } from 'immutable';
import qs from 'qs';

import { QueryParams } from 'reducers/router';
import { load, save } from 'sources/AccountLocalStorage';
import { allowDecision, createAccessDecision } from 'utils/accessUtils';
import { Member } from 'utils/accountUtils';
import {
  AllCodeRefStats,
  CodeReferencesFilterKind,
  CodeRefsFilter,
  Hunk,
  Repo,
  RepositoryForm,
  RepoStats,
  RepoUrlParts,
} from 'utils/codeRefs/types';
import { Links } from 'utils/linkUtils';
import { validateRecord } from 'utils/validationUtils';

type ReferenceType = {
  path: string;
  hint: string;
  hunks: List<HunkRecord>;
};

type BranchType = {
  _links: Links;
  name: string;
  head: string;
  syncTime: number;
  references: List<Reference>;
  sourceLink: string;
};

class RepoStatsRecord extends Record<RepoStats>({
  _links: Map({
    self: { self: { href: '', type: '' } },
  }),
  defaultBranch: '',
  enabled: false,
  name: '',
  sourceLink: 'string',
  version: 0,
  fileCount: 0,
  hunkCount: 0,
}) {}

const createRepoStatsRecord = (props = {}) => (props instanceof RepoStatsRecord ? props : new RepoStatsRecord(props));

class AllCodeRefStatsRecord extends Record<AllCodeRefStats>({
  _links: Map({ self: { href: '', type: '' } }),
  items: List(),
}) {}

export const createAllCodeRefStatsRecord = (props = {}) => {
  if (props instanceof AllCodeRefStatsRecord) {
    return props;
  } else {
    const result = new AllCodeRefStatsRecord(props);
    return result.withMutations((f) => {
      f.update('items', (vs) => vs.map(createRepoStatsRecord));
    });
  }
};

/**
 * Creates a repo url based on a commit or a hunk template.
 * @param urlTemplate Can be one of:
 *  - commitUrlTemplate "https://github.com/launchdarkly/SupportService/tree/${sha}",
 *  - hunkUrlTemplate "https://github.com/launchdarkly/SupportService/support-service/blob/${sha}/${filePath}#L${lineNumber}",
 * @param urlParts
 */
const createRepoUrl = (urlTemplate: string, urlParts: RepoUrlParts) => {
  let result = urlTemplate;

  Object.keys(urlParts).forEach((k) => {
    result = result.replace(`\$\{${k}\}`, `${urlParts[k]}`);
  });

  return result;
};

export class Repository extends Record<Repo>({
  id: '',
  _access: Map(),
  _links: Map(),
  defaultBranch: '',
  enabled: false,
  name: '',
  sourceLink: '',
  type: '',
  branches: OrderedMap(),
  commitUrlTemplate: '',
  hunkUrlTemplate: '',
  version: 0,
}) {
  checkAccess({ profile }: { profile: Member }) {
    const access = this.get('_access');

    if (profile.isReader()) {
      return () => createAccessDecision({ isAllowed: false, appliedRoleName: 'Reader' });
    }

    if (profile.isAdmin() || profile.isWriter() || profile.isOwner() || !access || !access.get('denied')) {
      return allowDecision;
    }

    return (action: string) => {
      const deniedAction = access.get('denied').find((v) => v.get('action') === action);
      if (deniedAction) {
        const reason = deniedAction.get('reason');
        const roleName = reason && reason.get('role_name');
        return createAccessDecision({
          isAllowed: false,
          appliedRoleName: roleName,
        });
      }
      return createAccessDecision({ isAllowed: true });
    };
  }

  getId() {
    return this.name;
  }

  hasReferences() {
    return this.branches.size > 0;
  }

  // Returns the default branch if it exists
  getDefaultDisplayBranch(): Branch | undefined {
    if (!this.branches.size) {
      return undefined;
    }
    const defaultBranch = this.branches.find((b) => b.name === this.defaultBranch);
    // We will get rid of returning first behavior after
    // when launch filter
    if (!isDisableCodeReferencesWithOverrideEnabled() && isCodeReferencesEnabled()) {
      return defaultBranch;
    }
    return defaultBranch || this.branches.first();
  }

  generateCollapsedState(collapsed: boolean) {
    return this.branches.reduce((acc, branch) => acc.set(branch.name, branch.generateCollapsedState(collapsed)), Map());
  }

  selfLink() {
    return this._links.getIn(['self', 'href']);
  }

  getFilteredBranch(branchFilter: string): Branch | undefined {
    if (branchFilter) {
      return this.branches?.find((b: Branch) => b.name === branchFilter);
    }
    return this.getDefaultDisplayBranch();
  }

  toForm() {
    return createRepositoryFormRecord({
      enabled: this.enabled,
    });
  }

  mergeForm(form: RepositoryFormRecord) {
    return this.withMutations((repository) =>
      repository.merge({
        enabled: form.enabled,
      }),
    );
  }
}

export class RepositoryFormRecord extends Record<RepositoryForm>({
  enabled: true,
}) {
  validate() {
    return validateRecord(this);
  }

  is(other: RepositoryFormRecord) {
    try {
      return this.enabled === other.enabled;
    } catch (e) {
      // could fail to parse as JSON
      return false;
    }
  }

  toRepository() {
    return createRepository({
      enabled: this.enabled,
    });
  }
}

export function createRepositoryFormRecord(props = {}) {
  return props instanceof RepositoryFormRecord ? props : new RepositoryFormRecord(props);
}

export class Extinction extends Record({
  revision: '',
  message: '',
  time: 0,
}) {}

export function createExtinction(props = {}) {
  return props instanceof Extinction ? props : new Extinction(fromJS(props));
}

export function applyCommitUrlTemplate(commitUrlTemplate: string, sha: string, branchName: string) {
  return createRepoUrl(commitUrlTemplate, { sha, branchName });
}

export class Branch extends Record<BranchType>({
  _links: Map(),
  name: '',
  head: '',
  syncTime: 0,
  references: List<Reference>(),
  sourceLink: '',
}) {
  hasReferences() {
    return this.references.size > 0;
  }

  hasContextLines() {
    // we expect every reference to have context lines if they're enabled,
    // but we use the `some` method to handle the (potential bug) edge case
    // where only some references don't have context lines
    return !this.references.some((ref) => ref.hunks && ref.hunks.size > 0 && !ref.hunks.find((hunk) => !!hunk.lines));
  }

  maybeApplyPagination(isPaginationEnabled: boolean, numItems: number) {
    return isPaginationEnabled ? this.update('references', (refs) => refs.take(numItems)) : this;
  }

  applyCommitUrlTemplate(commitUrlTemplate: string) {
    return this.set(
      'sourceLink',
      commitUrlTemplate ? applyCommitUrlTemplate(commitUrlTemplate, this.head, this.name) : '',
    );
  }

  generateCollapsedState(collapsed: boolean) {
    return this.references.map(() => collapsed);
  }

  applyHunkUrlTemplate(hunkUrlTemplate: string) {
    const newThis = this.update('references', (refs) =>
      refs.map((ref) =>
        ref.update('hunks', (hunks) =>
          hunks.map((hunk) =>
            hunk.set(
              'sourceLink',
              hunkUrlTemplate
                ? createRepoUrl(hunkUrlTemplate, {
                    sha: this.head,
                    filePath: ref.path,
                    lineNumber: hunk.linkLineNumber(),
                  })
                : '',
            ),
          ),
        ),
      ),
    );
    return newThis;
  }

  filterExtensions(extensions: List<string>) {
    if (!extensions.size) {
      return this;
    }
    return this.update('references', (refs) =>
      refs.filter((r) =>
        extensions.some((ext) => (ext === 'none' ? r.path.split('.').length === 1 : r.path.endsWith(ext))),
      ),
    );
  }
}

export class Reference extends Record<ReferenceType>({
  path: '',
  hint: '',
  hunks: List(),
}) {}

export class HunkRecord extends Record<Hunk>({
  startingLineNumber: 0,
  lines: '',
  projKey: '',
  flagKey: '',
  aliases: List(),
  sourceLink: '',
}) {
  linkLineNumber() {
    let idx = this.lines.split('\n').findIndex((line) => line.includes(this.flagKey));
    if (idx === -1) {
      // not found i.e.  no context lines
      idx = 0;
    }
    return idx + this.startingLineNumber;
  }
}

export function createRepository(props = {}) {
  const repo = props instanceof Repository ? props : new Repository(fromJS(props));
  return repo
    .update(
      'branches',
      (bs) =>
        bs &&
        bs.reduce((acc, curr) => {
          const currBranch = createBranch(curr);
          return acc.set(
            currBranch.name,
            currBranch.applyCommitUrlTemplate(repo.commitUrlTemplate).applyHunkUrlTemplate(repo.hunkUrlTemplate),
          );
        }, OrderedMap()),
    )
    .set('id', repo.getId());
}

export function createBranch(props = {}) {
  const branch = props instanceof Branch ? props : new Branch(fromJS(props));
  return branch.update('references', (rs) => rs && rs.map(createReference));
}

export function createReference(props = {}) {
  const reference = props instanceof Reference ? props : new Reference(fromJS(props));
  return reference.update('hunks', (hs) => hs && hs.map(createHunk));
}

export function createHunk(props = {}) {
  const hunk = props instanceof HunkRecord ? props : new HunkRecord(fromJS(props));
  // Remove only trailing newlines from hunk lines to make sure weird artifacts aren't added to the PrismJS Snippet
  // Remove all carriage returns because PrismJS doesn't like them
  return hunk.update('lines', (lines) => lines.replace(/[\r\n]+$/g, '').replace(/\r/g, ''));
}

export class CodeReferenceFilters extends Record<CodeRefsFilter>({
  q: '',
  repo: '',
  branch: '',
  extensions: List(),
  showEmpty: false,
}) {
  isEmpty() {
    return (
      isEmpty(this.q) && isEmpty(this.repo) && isEmpty(this.branch) && this.extensions.isEmpty() && !this.showEmpty
    );
  }

  toQuery() {
    const ret = {} as Omit<CodeRefsFilter, 'extensions'> & { extensions?: string[] };

    if (!isEmpty(this.repo)) {
      ret.repo = this.repo;
    }

    if (!isEmpty(this.branch)) {
      ret.branch = this.branch;
    }

    if (!this.extensions.isEmpty()) {
      ret.extensions = this.extensions.sort().toJS();
    }

    if (this.showEmpty) {
      ret.showEmpty = true;
    }

    return ret;
  }

  toQueryString() {
    return qs.stringify(this.toQuery(), { indices: false });
  }
}

export class CodeStatisticsForFlag extends Record<RepoStats>({
  _links: Map(),
  name: '',
  sourceLink: '',
  defaultBranch: '',
  enabled: false,
  version: 0,
  hunkCount: 0,
  fileCount: 0,
}) {
  getCodeReferenceCount() {
    return this.hunkCount;
  }
}

export function createCodeStatistics(props = List<Repository>()) {
  if (props.size > 0) {
    return props.map((stat) => new CodeStatisticsForFlag(fromJS(stat)));
  }
  return Map();
}

export function applyCodeReferenceRepoFilter(repos: Map<string, Repository>, repoFilter: string) {
  return repoFilter ? repos.filter((r) => r.id === repoFilter) : repos;
}

export function createCodeReferenceFilters(props = {}) {
  return props instanceof CodeReferenceFilters ? props : new CodeReferenceFilters(fromJS(props));
}

export function createCodeReferenceFiltersFromQuery(query: QueryParams<keyof CodeRefsFilter>) {
  const repo = query?.get(CodeReferencesFilterKind.REPO);
  const branch = query?.get(CodeReferencesFilterKind.BRANCH);
  let extensions = query?.get(CodeReferencesFilterKind.EXTENSIONS, []);
  extensions = typeof extensions === 'string' ? [extensions] : extensions;
  const showEmpty = !!query?.get(CodeReferencesFilterKind.SHOW_EMPTY);

  return createCodeReferenceFilters({
    repo,
    branch,
    extensions,
    showEmpty,
  });
}

const SHOW_EMPTY_REPOSITORIES = 'showEmptyCoderefsRepositories';

export const saveShowEmptyRepositoriesToCache = (showEmpty: boolean) =>
  save(load().set(SHOW_EMPTY_REPOSITORIES, showEmpty));

export const getShowEmptyRepositoriesFromCache = () => load().get(SHOW_EMPTY_REPOSITORIES);
