import { createStorageSlot } from '@gonfalon/browser-storage';
import { cacheFlagFiltersAndSortOrdering } from '@gonfalon/dogfood-flags';

import { ensureSortParam } from './ensureSortParam';
import { cacheKey } from './useCachedFlagListSortOrdering';

const slot = createStorageSlot(cacheKey, {
  storageType: 'sessionStorage',
  isEnabled: cacheFlagFiltersAndSortOrdering(),
});

export function readCachedFlagListSortOrdering() {
  try {
    const raw = slot.get();
    return ensureSortParam(raw);
  } catch (error) {
    slot.remove();
    return undefined;
  }
}
