import { IconButton, Tooltip, TooltipTrigger } from '@launchpad-ui/components';

import { getKeyboardShortcutLabel, KeyboardShortcut } from '../keyboardShortcuts';

import { useSidebarContext } from './useSidebarContext';

export function SidebarToggle() {
  const sidebar = useSidebarContext();

  return (
    <TooltipTrigger>
      <IconButton
        size="medium"
        variant="minimal"
        aria-label={sidebar.isVisible ? 'Hide sidebar' : 'Show sidebar'}
        icon={sidebar.isVisible ? 'sidebar-left-collapse' : 'sidebar-left-expand'}
        onPress={sidebar.toggle}
      />
      <Tooltip placement="bottom">
        {sidebar.isVisible ? (
          <>
            Hide sidebar<kbd>{getKeyboardShortcutLabel(KeyboardShortcut.TOGGLE_PROJECT_SIDEBAR)}</kbd>
          </>
        ) : (
          <>
            Show sidebar<kbd>{getKeyboardShortcutLabel(KeyboardShortcut.TOGGLE_PROJECT_SIDEBAR)}</kbd>
          </>
        )}
      </Tooltip>
    </TooltipTrigger>
  );
}
