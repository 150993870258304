import { useEffect, useState } from 'react';

export type PointerCoordinates = {
  xPosition: number;
  yPosition: number;
  leftRelativeXPosition: number;
  topRelativeYPosition: number;
};

/**
 * Track the current pointer position relative to the provided ref element.
 *
 * NOTE: The values from this hook are stateful, meaning updates will trigger a re-render.
 * It's designed to be used in components where you need to follow the mouse position and render based on it.
 * It is DANGEROUS to use this hook in a performance-critical path, as it will trigger re-renders on every mouse move.
 * i.e. Never to be used in something such as App.tsx or on components with a large tree.
 */
export const usePointerPosition = (ref: React.RefObject<HTMLElement>) => {
  const [isInBounds, setIsInBounds] = useState(false);
  const [coordinates, setCoordinates] = useState<PointerCoordinates | null>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const handlePointerMove = (e: PointerEvent) => {
      if (!ref.current) {
        return;
      }

      const bounds = ref.current.getBoundingClientRect();

      const xPosition = e.clientX;
      const yPosition = e.clientY;
      const leftRelativeXPosition = Math.abs(e.clientX - bounds.left);
      const topRelativeYPosition = Math.abs(e.clientY - bounds.top);

      const isWithinX = xPosition >= bounds.left && xPosition <= bounds.right;
      const isWithinY = yPosition >= bounds.top && yPosition <= bounds.bottom;
      const _isInBounds = isWithinX && isWithinY;

      const _coordinates = {
        xPosition,
        yPosition,
        leftRelativeXPosition,
        topRelativeYPosition,
      };

      setIsInBounds(_isInBounds);
      setCoordinates(_coordinates);
    };

    window.addEventListener('pointermove', handlePointerMove);

    return () => {
      window.removeEventListener('pointermove', handlePointerMove);
    };
  }, []);

  return { isInBounds, coordinates };
};
