import { useEffect } from 'react';
import { connect } from 'react-redux';

import { loadContextTarget as loadContextTargetAction } from 'actions/contexts';
import { ContextItemsEntities } from 'components/Contexts/types';
import { makeKindKeyStringFromProps } from 'components/Contexts/utils/contextTargetingUtils';
import { TargetEntry } from 'components/InstructionList/TargetEntry';
import { GlobalDispatch, GlobalState } from 'reducers';
import { contextByKindKeySelector, contextRequestByKindKeySelector } from 'reducers/contexts';
import { Flag } from 'utils/flagUtils';
import { TargetsInstructionKind } from 'utils/instructions/targets/types';
import { UserTargetsInstructionKind } from 'utils/instructions/userTargets/types';
import { ready } from 'utils/reduxUtils';

type TargetEntryContainerProps = {
  instructionKind:
    | TargetsInstructionKind.ADD_TARGETS
    | TargetsInstructionKind.REMOVE_TARGETS
    | TargetsInstructionKind.REPLACE_TARGETS
    | UserTargetsInstructionKind.ADD_USER_TARGETS
    | UserTargetsInstructionKind.REMOVE_USER_TARGETS
    | UserTargetsInstructionKind.REPLACE_USER_TARGETS;
  contextKind: string;
  targetKeys: string[];
  variationId: string;
  flag: Flag;
  loadContextTarget({
    contextKind,
    targetKey,
    projKey,
    envKey,
  }: {
    contextKind: string;
    targetKey: string;
    envKey: string;
    projKey: string;
  }): void;
  contextItemsByKindKey: ContextItemsEntities;
  targetLimit: number;
  isReady: boolean;
  projKey: string;
  envKey: string;
};

export function TargetEntryContainer({
  instructionKind,
  contextKind,
  targetKeys,
  variationId,
  flag,
  loadContextTarget,
  contextItemsByKindKey,
  targetLimit,
  isReady,
  projKey,
  envKey,
}: TargetEntryContainerProps) {
  const actualTargetLimit = targetLimit || targetKeys.length;
  useEffect(() => {
    if (!isReady) {
      targetKeys.slice(0, actualTargetLimit).forEach((targetKey) =>
        loadContextTarget({
          contextKind,
          targetKey,
          projKey,
          envKey,
        }),
      );
    }
  }, []);
  return (
    <TargetEntry
      instructionKind={instructionKind}
      contextKind={contextKind}
      targetKeys={targetKeys}
      variationId={variationId}
      flag={flag}
      contextItemsByKindKey={contextItemsByKindKey}
      projKey={projKey}
      envKey={envKey}
      targetLimit={actualTargetLimit}
    />
  );
}

type TargetEntryContainerOwnProps = Pick<
  TargetEntryContainerProps,
  'contextKind' | 'targetKeys' | 'projKey' | 'envKey'
>;
const mapStateToProps = (
  state: GlobalState,
  { contextKind, targetKeys, projKey, envKey }: TargetEntryContainerOwnProps,
) => {
  let isReady = true;
  const contextItemsByKindKey: ContextItemsEntities = {};
  targetKeys.forEach((key) => {
    const kindKey = makeKindKeyStringFromProps(contextKind, key);
    const request = contextRequestByKindKeySelector(state, { kindKey, projKey, envKey });
    isReady = isReady && ready(request);
    const contextItem = contextByKindKeySelector(state, { kindKey, projKey, envKey });
    contextItemsByKindKey[kindKey] = contextItem;
  });
  return {
    contextItemsByKindKey,
    isReady,
  };
};

const mapDispatchToProps = (dispatch: GlobalDispatch) => ({
  loadContextTarget: ({
    contextKind,
    targetKey,
    envKey,
    projKey,
  }: {
    contextKind: string;
    targetKey: string;
    envKey: string;
    projKey: string;
  }) =>
    dispatch(
      loadContextTargetAction({
        viewId: '',
        target: { kind: contextKind, key: targetKey },
        envKey,
        projKey,
      }),
    ),
});

/* eslint-disable import/no-default-export */
export default connect(mapStateToProps, mapDispatchToProps)(TargetEntryContainer);
