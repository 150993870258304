import type { DayModifiers, Matcher } from 'react-day-picker';
import { DayPicker } from 'react-day-picker';
import { Menu, MenuItem } from '@launchpad-ui/core';
import cx from 'clsx';
import { isBefore } from 'date-fns';

export type DateRangePickerProps = {
  dates: Array<Date | undefined>;
  onChange(val: Date | Array<Date | undefined>): void;
  enableCustomRange?: boolean;
  onClick?(): void;
  createShortcuts?: () => Array<{
    label: string;
    dateRange: Array<Date | undefined>;
  }>;
  disabledDays?: Matcher | Matcher[];
  label?: string;
};

export function DateRangePicker(props: DateRangePickerProps) {
  const { dates, enableCustomRange, createShortcuts, disabledDays, onChange } = props;
  const [from, to] = dates;
  const currentYear = new Date(Date.now()).getFullYear();
  const fromMonth = new Date(currentYear - 10, 0);
  const toMonth = new Date(currentYear + 10, 11);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleShortcutSelect = (dates: string) => {
    onChange(dates as unknown as Date[]);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const isSelectingFirstDay = (from: Date | undefined, to: Date | undefined, day: Date) => {
    const isBeforeFirstDay = from && isBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleDayChange = (dates: Array<Date | undefined>) => {
    onChange(dates);
  };

  const handleDayClick = (day: Date, modifiers: DayModifiers) => {
    if (modifiers.disabled) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const [from, to] = dates;
    if (isSelectingFirstDay(from, to, day)) {
      handleDayChange([day, undefined]);
    } else {
      handleDayChange([from, day]);
    }
  };

  return (
    <div className="DateRangePicker">
      {createShortcuts && (
        <div className={cx({ 'DateRangePicker-shortcuts': enableCustomRange })}>
          <Menu onSelect={handleShortcutSelect}>
            {createShortcuts().map(({ dateRange, label }, i) => (
              <MenuItem item={dateRange} key={i}>
                {label}
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}

      {enableCustomRange && (
        <div className="DateRangePicker-days">
          <DayPicker
            numberOfMonths={2}
            modifiers={{ start: from as Matcher, end: to as Matcher }}
            defaultMonth={from}
            selected={[from as Matcher, { from, to }]}
            captionLayout="dropdown"
            fromMonth={fromMonth}
            toMonth={toMonth}
            onDayClick={handleDayClick}
            disabled={disabledDays}
          />
        </div>
      )}
    </div>
  );
}
