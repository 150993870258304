import { PlanTypes, Subscription } from '../types';

import { isModernSubscription } from './isModernSubscription';

export function isUsageBasedBillingPlanOrTrial(subscription: Subscription): boolean {
  if (isModernSubscription(subscription)) {
    const { planType } = subscription;
    if (planType === null || planType === undefined) {
      return false;
    }
    return [PlanTypes.FOUNDATION2023, PlanTypes.FOUNDATION_TRIAL2023].includes(planType);
  }
  return false;
}
