import { UISegmentsListView } from './types';

export function parseUISegmentsViewFromBrowser(searchParams: URLSearchParams) {
  const ui: UISegmentsListView = {};

  if (searchParams.has('sort')) {
    ui.sort = searchParams.get('sort') as UISegmentsListView['sort'];
  }
  if (searchParams.has('query')) {
    ui.query = searchParams.get('query') as UISegmentsListView['query'];
  }
  if (searchParams.has('keys')) {
    ui.keys = searchParams.getAll('keys');
  }
  if (searchParams.has('tags')) {
    ui.tags = searchParams.getAll('tags');
  }
  if (searchParams.has('kind')) {
    ui.kind = searchParams.get('kind') as UISegmentsListView['kind'];
  }

  return ui;
}
