import { targetingSchema } from './types';

export function parseTargeting(value: unknown) {
  const result = targetingSchema.safeParse(value);

  if (!result.success) {
    return undefined;
  }

  return result.data;
}
