import { toCreateFlag, toFlags } from '@gonfalon/navigator';
import { Link, LinkButton } from '@launchpad-ui/components';

import Jumbo from 'components/Jumbo';

type FlagNotFoundProps = {
  projKey: string;
  envKey: string;
};

/* eslint-disable import/no-default-export */
export default function FlagNotFound({ projKey, envKey }: FlagNotFoundProps) {
  const action = (
    <div>
      <LinkButton variant="primary" href={toCreateFlag({ projectKey: projKey, environmentKey: envKey })}>
        Create flag
      </LinkButton>{' '}
      or go <Link href={toFlags({ projectKey: projKey })}>Back to the dashboard</Link>
    </div>
  );

  return (
    <Jumbo title="Flag not found" lead="Sorry, we can’t find the flag you’re looking for." actions={action} isError />
  );
}
