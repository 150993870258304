import { FormEvent } from 'react';
import { Button, ButtonGroup } from 'launchpad';

import { SubmitButton } from 'components/ui/buttons';
import { PolicyStatement } from 'utils/policyUtils';

import styles from '../styles.module.css';

type PolicyStatementEditorButtonsProps = {
  canRemove?: boolean;
  onCancel: (shouldRemove: boolean) => void;
  onRemove: () => void;
  onSubmit: (event: FormEvent<EventTarget>) => void;
  statement: PolicyStatement;
};

const PolicyStatementEditorButtons = ({
  canRemove,
  onCancel,
  onRemove,
  onSubmit,
  statement,
}: PolicyStatementEditorButtonsProps) => {
  const handleCancel = () => {
    // if we're canceling an initially empty statement, go ahead and remove it
    const shouldRemove = statement.isEmpty();
    onCancel(shouldRemove);
  };

  return (
    <div className={styles.editorActions}>
      <ButtonGroup>
        <Button onClick={handleCancel} size="tiny">
          Cancel
        </Button>
        <SubmitButton kind="primary" loadingText="Updating" onClick={onSubmit} size="tiny">
          Update statement
        </SubmitButton>
      </ButtonGroup>
      {canRemove && (
        <Button kind="destructive" onClick={onRemove} size="tiny">
          Remove
        </Button>
      )}
    </div>
  );
};

/* eslint-disable import/no-default-export */
export default PolicyStatementEditorButtons;
