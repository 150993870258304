import type { ComponentPropsWithRef, ReactNode } from 'react';
import clsx from 'clsx';

import styles from './CardGroup.module.css';

export type CardGroupProps = ComponentPropsWithRef<'div'> & {
  children: ReactNode;
};

export const CardGroup = ({ children }: CardGroupProps) => <div className={clsx(styles.CardGroup)}>{children}</div>;
