// eslint-disable-next-line no-restricted-imports
import { useSelector } from 'react-redux';
import { slackAppHost, userEmail, userId } from '@gonfalon/constants';
import { configurableDestinations } from '@gonfalon/dogfood-flags';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, OrderedMap } from 'immutable';
import { AnyAction, combineReducers } from 'redux';
import { createSelector } from 'reselect';

import {
  DestinationAction,
  GoaltenderSubAction,
  RepositoryAction,
  SlackHookAction,
  TriggersAction,
} from 'actions/integrations';
import actionTypes from 'actionTypes/integrations';
import { DestinationKinds } from 'components/integrations/constants';
import { GlobalState } from 'reducers';
import registry from 'reducers/registry';
import { getSlackAppWebhooks, webhooksSelector } from 'reducers/webhooks';
import { FlagInfo, SlackHookResponse } from 'sources/IntegrationAPI';
import { Repository } from 'utils/codeRefs/codeRefsUtils';
import {
  GoaltenderSubscription,
  IntegrationsManifestServed,
  LegacyIntegrationKind,
  manifestMapHasApprovalCapability,
  manifestMapHasEventsHookCapability,
  ManifestRecord,
  manifestUsesIntegrationConfiguration,
} from 'utils/goaltenderUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { Destination, SlackIncomingHook } from 'utils/integrationUtils';
import { ready } from 'utils/reduxUtils';
import { obscureTriggerURL, TriggerPatchKind, TriggerType } from 'utils/triggerUtils';
import { Webhook } from 'utils/webhookUtils';

const SLACK_APP_HOST = slackAppHost();

type RepositoryStateType = ImmutableMap<{
  entities: List<Repository>;
  lastFetched: number | null;
  isFetching: boolean;
  error?: Error;
}>;

const repositories = (
  state: RepositoryStateType = fromJS({
    entities: {},
    lastFetched: null,
    isFetching: false,
  }),
  action: RepositoryAction,
) => {
  switch (action.type) {
    case actionTypes.REQUEST_REPOSITORIES:
      return state.set('isFetching', true);
    case actionTypes.REQUEST_REPOSITORIES_FAILED:
      return state.merge({
        isFetching: false,
        error: action.error,
      });
    case actionTypes.REQUEST_REPOSITORIES_DONE:
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        entities: action.repos,
      });
    case actionTypes.UPDATE_REPOSITORY_DONE:
      return state.update('entities', (entities) =>
        entities.set(
          entities.findIndex((r) => r.id === action.repo.id),
          action.repo,
        ),
      );
    case actionTypes.DELETE_REPOSITORY_DONE:
      return state.update('entities', (entities) =>
        entities.delete(entities.findIndex((r) => r.id === action.repo.id)),
      );
    default:
      return state;
  }
};

type DestinationStateType = ImmutableMap<{
  entities: OrderedMap<string, Destination>;
  lastFetched: number | null;
  isFetching: boolean;
  error?: Error;
}>;

const destinations = (
  state: DestinationStateType = fromJS({
    entities: {},
    lastFetched: null,
    isFetching: false,
  }),
  action: DestinationAction,
) => {
  switch (action.type) {
    case actionTypes.REQUEST_DESTINATIONS:
      return state.set('isFetching', true);
    case actionTypes.REQUEST_DESTINATIONS_FAILED:
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        error: action.error,
      });
    case actionTypes.REQUEST_DESTINATIONS_DONE:
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        entities: action.response.getIn(['entities', 'destinations']),
      });
    case actionTypes.CREATE_DESTINATION_DONE:
    case actionTypes.UPDATE_DESTINATION_DONE:
      return state.update('entities', (entities) => entities.set(action.destination._id, action.destination));
    case actionTypes.DELETE_DESTINATION_DONE:
      return state.update('entities', (entities) => entities.delete(action.destination._id));
    default:
      return state;
  }
};

type SlackIncomingHookApiRes = { response: SlackHookResponse };

const slackIncomingHookEntities = (
  state = Map<string, SlackIncomingHook>(),
  action: SlackHookAction,
): Map<string, SlackIncomingHook> => {
  if (
    (action as SlackIncomingHookApiRes).response &&
    (action as SlackIncomingHookApiRes).response.hasIn(['entities', 'slack'])
  ) {
    return state.merge((action as SlackIncomingHookApiRes).response.getIn(['entities', 'slack']));
  }

  if (
    action.type === actionTypes.CREATE_SLACK_INCOMING_HOOK_DONE ||
    action.type === actionTypes.UPDATE_SLACK_INCOMING_HOOK_DONE
  ) {
    return state.set(action.hook._id, action.hook);
  }

  if (action.type === actionTypes.DELETE_SLACK_INCOMING_HOOK_DONE) {
    return state.delete(action.hook._id);
  }

  return state;
};

type IntegrationStateType = ImmutableMap<{
  slack: ImmutableMap<{
    lastFetched: number | null;
    isFetching: boolean;
    error?: Error | null;
  }>;
}>;

const integrationRequests = (
  state: IntegrationStateType = fromJS({
    slack: {
      isFetching: false,
      lastFetched: null,
      error: null,
    },
  }),
  action: SlackHookAction,
) => {
  switch (action.type) {
    case actionTypes.REQUEST_SLACK_INCOMING_HOOKS:
      return state.setIn(['slack', 'isFetching'], true);
    case actionTypes.REQUEST_SLACK_INCOMING_HOOKS_DONE:
      return state.update('slack', (s) =>
        s.merge({
          isFetching: false,
          lastFetched: action.timestamp,
        }),
      );
    case actionTypes.REQUEST_SLACK_INCOMING_HOOKS_FAILED:
      return state.update('slack', (s) =>
        s.merge({
          isFetching: false,
          lastFetched: action.timestamp,
          error: action.error,
        }),
      );
    default:
      return state;
  }
};

export type SavedSubscriptionState = ImmutableMap<{
  subscriptions: List<GoaltenderSubscription>;
  isFetching: boolean;
  lastFetched: number;
  timestamp: number;
  error?: Error;
}>;

export type SavedSubscriptionListState = OrderedMap<string, SavedSubscriptionState>;

type GoaltenderSubscriptionStateType = ImmutableMap<{
  subscriptions: SavedSubscriptionListState;
  isFetching: boolean;
  lastFetched: number;
  timestamp: number;
}>;

type GoaltenderStateType = ImmutableMap<{
  manifests: ImmutableMap<{
    isFetching: boolean;
    lastFetched: number;
    manifests: OrderedMap<string, ManifestRecord>;
    error?: Error;
  }>;
  subscriptions: GoaltenderSubscriptionStateType;
  isFetching: boolean;
  lastFetched: number;
  error?: Error;
}>;

const isFetchingSubscriptions = (goaltenderState: GoaltenderStateType) =>
  goaltenderState.get('subscriptions').some((sub) => !ready(sub));

const goaltender = (
  state: GoaltenderStateType = fromJS({
    manifests: { isFetching: false, lastFetched: 0, manifests: {} },
    subscriptions: { subscriptions: {}, isFetching: false, lastFetched: 0, timestamp: 0 },
    isFetching: false,
    lastFetched: null,
  }),
  action: GoaltenderSubAction,
) => {
  switch (action.type) {
    case actionTypes.REQUEST_GOALTENDER_MANIFESTS:
      return state.setIn(['manifests', 'isFetching'], true);
    case actionTypes.REQUEST_GOALTENDER_MANIFESTS_DONE:
      return state.update('manifests', (s) =>
        s.merge({
          manifests: action.response,
          isFetching: false,
          lastFetched: action.timestamp,
        }),
      );
    case actionTypes.REQUEST_GOALTENDER_SUBSCRIPTIONS_DONE_NO_CAPABILITIES:
      return state.set('subscriptions', fromJS({ isFetching: false, lastFetched: action.timestamp }));
    case actionTypes.REQUEST_GOALTENDER_MANIFESTS_FAILED:
      return state
        .update('manifests', (s) =>
          s.merge({
            isFetching: false,
            lastFetched: action.timestamp,
            error: action.error,
          }),
        )
        .set('subscriptions', fromJS({ isFetching: false, lastFetched: action.timestamp }));
    case actionTypes.REQUEST_GOALTENDER_SUBSCRIPTIONS:
      return state
        .set('isFetchingSubscriptions', true)
        .setIn(['subscriptions', 'isFetching'], true)
        .setIn(['subscriptions', 'subscriptions', action.kind, 'isFetching'], true);
    case actionTypes.REQUEST_GOALTENDER_SUBSCRIPTIONS_DONE:
      return state
        .updateIn(['subscriptions', 'subscriptions', action.kind], (s = fromJS({})) =>
          s.merge({
            subscriptions: action.response,
            manifest: state.getIn(['manifests', 'manifests', action.kind]),
            isFetching: false,
            lastFetched: action.timestamp,
          }),
        )
        .updateIn(['subscriptions'], (s) =>
          isFetchingSubscriptions(s) ? s : s.merge({ isFetching: false, lastFetched: action.timestamp }),
        );
    case actionTypes.REQUEST_GOALTENDER_SUBSCRIPTIONS_FAILED:
      return state
        .updateIn(['subscriptions', 'subscriptions', action.kind], (s) =>
          s.merge({
            isFetching: false,
            lastFetched: action.timestamp,
            error: action.error,
          }),
        )
        .updateIn(['subscriptions'], (s) =>
          isFetchingSubscriptions(s) ? s : s.merge({ isFetching: false, lastFetched: action.timestamp }),
        );
    case actionTypes.REQUEST_GOALTENDER_MANIFESTS_AND_SUBSCRIPTIONS:
      return state.setIn(['manifests', 'isFetching'], true).setIn(['subscriptions', 'isFetching'], true);
    case actionTypes.REQUEST_GOALTENDER_MANIFESTS_AND_SUBSCRIPTIONS_DONE:
      return state
        .updateIn(['manifests'], (s) =>
          s.merge({
            manifests: action.manifests,
            isFetching: false,
            lastFetched: action.timestamp,
          }),
        )
        .updateIn(['subscriptions'], (s) =>
          s.merge({
            subscriptions: fromJS(
              action.subscriptions.reduce(
                (results, sub) =>
                  Object.assign(results, {
                    [sub.kind]: {
                      subscriptions: sub.records,
                      manifest: action.manifests.get(sub.kind),
                      isFetching: false,
                      lastFetched: action.timestamp,
                    },
                  }),
                {},
              ),
            ),
            isFetching: false,
            lastFetched: action.timestamp,
          }),
        );
    case actionTypes.REQUEST_GOALTENDER_MANIFESTS_AND_SUBSCRIPTIONS_FAILED:
      return state
        .update('manifests', (s) =>
          s.merge({
            isFetching: false,
            lastFetched: action.timestamp,
            error: action.error,
          }),
        )
        .set('subscriptions', fromJS({ isFetching: false, lastFetched: action.timestamp, error: action.error }));
    case actionTypes.CREATE_GOALTENDER_SUBSCRIPTION_DONE:
      return state.updateIn(['subscriptions', 'subscriptions', action.kind], (s) =>
        s.merge({
          subscriptions: s.get('subscriptions').push(action.subscription),
        }),
      );
    case actionTypes.UPDATE_GOALTENDER_SUBSCRIPTION_DONE:
      const path = ['subscriptions', 'subscriptions', action.subscription._manifest.get('key'), 'subscriptions'];
      const subs = state.getIn(path);
      return state.updateIn(
        path.concat(subs.findIndex((r: GoaltenderSubscription) => r.get('_id') === action.subscription.get('_id'))),
        (s) => s.merge(action.subscription),
      );
    case actionTypes.DELETE_GOALTENDER_SUBSCRIPTION_DONE:
      return state.updateIn(
        ['subscriptions', 'subscriptions', action.subscription._manifest.get('key'), 'subscriptions'],
        (s) => s.delete(s.findIndex((r: GoaltenderSubscription) => r.get('_id') === action.subscription.get('_id'))),
      );
    case actionTypes.SEND_GOALTENDER_TEST_EVENT:
      return state.updateIn(
        ['subscriptions', 'subscriptions', action.subscription._manifest.get('key'), 'subscriptions'],
        (s) =>
          s.update(
            s.findIndex((r: GoaltenderSubscription) => r.get('_id') === action.subscription.get('_id')),
            (sub: GoaltenderSubscription) => sub.set('isValidating', true),
          ),
      );
    case actionTypes.SEND_GOALTENDER_TEST_EVENT_DONE:
    case actionTypes.SEND_GOALTENDER_TEST_EVENT_FAILED:
      return state.updateIn(
        ['subscriptions', 'subscriptions', action.subscription._manifest.get('key'), 'subscriptions'],
        (s) =>
          s.update(
            s.findIndex((r: GoaltenderSubscription) => r.get('_id') === action.subscription.get('_id')),
            (sub: GoaltenderSubscription) => {
              // status code 0 means request timed out
              if (!action.updateStats || action.statusCode === 0) {
                return sub.set('isValidating', false);
              }

              //update subscription status stats
              const successCount = sub._status.get('successCount') ?? 0;
              const lastSuccess = sub._status.get('lastSuccess');
              const errorCount = sub._status.get('errorCount') ?? 0;
              const lastError = sub._status.get('lastError');
              const errors = sub._status.get('errors') ?? List();

              const newError = {
                timestamp: action.timestamp,
                responseBody: action.responseBody,
                statusCode: action.statusCode,
              };

              const update = {
                isValidating: false,
                _status: {
                  successCount: action.success ? successCount + 1 : successCount,
                  lastSuccess: action.success ? action.timestamp : lastSuccess,
                  errorCount: !action.success ? errorCount + 1 : errorCount,
                  lastError: !action.success ? action.timestamp : lastError,
                  errors: !action.success ? [newError, ...errors.toJS()] : errors.toJS(),
                },
              };

              return sub.merge(fromJS(update));
            },
          ),
      );
    case actionTypes.DELETE_GOALTENDER_OAUTH_TOKEN_DONE:
      return state.updateIn(['manifests', 'manifests', action.integrationKey, 'oauth'], () => ({
        connected: false,
        member: null,
      }));
    case actionTypes.CREATE_GOALTENDER_OAUTH_TOKEN_DONE:
      return state.updateIn(['manifests', 'manifests', action.integrationKey, 'oauth'], () => ({
        connected: true,
        member: userId(),
        _member: { _id: userId(), email: userEmail() },
      }));
    case actionTypes.FETCH_GOALTENDER_DYNAMIC_ENUM_OPTIONS:
      return state.updateIn(
        ['subscriptions', 'subscriptions', action.integrationKey, 'dynamicEnumOptions', action.enumKey],
        () =>
          fromJS({
            isFetching: true,
          }),
      );
    case actionTypes.FETCH_GOALTENDER_DYNAMIC_ENUM_OPTIONS_DONE:
      return state.updateIn(
        ['subscriptions', 'subscriptions', action.integrationKey, 'dynamicEnumOptions', action.enumKey],
        () =>
          fromJS({
            isFetching: false,
            lastFetched: action.timestamp,
            options: action.dynamicEnumOptions.sort((leftValue, rightValue) =>
              leftValue.label.localeCompare(rightValue.label),
            ),
          }),
      );
    case actionTypes.FETCH_GOALTENDER_DYNAMIC_ENUM_OPTIONS_FAILED:
      return state.updateIn(
        ['subscriptions', 'subscriptions', action.integrationKey, 'dynamicEnumOptions', action.enumKey],
        () =>
          fromJS({
            isFetching: false,
            lastFetched: action.timestamp,
            error: action.error,
          }),
      );
    default:
      return state;
  }
};

type TriggerStateType = ImmutableMap<{
  triggers: List<ImmutableMap<TriggerType>>;
  flagEnv: FlagInfo;
  isFetching: boolean;
  lastFetched: number | null;
  isCreating: boolean;
}>;

const trigger = (
  state: TriggerStateType = fromJS({
    triggers: [],
    flagEnv: undefined,
    isFetching: false,
    lastFetched: null,
    isCreating: false,
  }),
  action: TriggersAction,
) => {
  switch (action.type) {
    case actionTypes.FETCH_TRIGGERS:
      return state.withMutations((s) => s.set('isFetching', true).set('flagEnv', action.flagEnv));
    case actionTypes.FETCH_TRIGGERS_DONE:
      return state.withMutations((s) =>
        s.set('isFetching', false).set('lastFetched', action.timestamp).set('triggers', fromJS(action.triggers)),
      );
    case actionTypes.FETCH_TRIGGERS_FAILED:
      return state.withMutations((s) =>
        s.set('isFetching', false).set('lastFetched', action.timestamp).set('error', action.error),
      );
    case actionTypes.CREATE_TRIGGER:
      return state.update('isCreating', () => true);
    case actionTypes.CREATE_TRIGGER_DONE:
      return state.withMutations((s) => {
        s.updateIn(['triggers'], (triggers) => triggers.push(fromJS(action.trigger)));
        s.update('isCreating', () => false);
      });
    case actionTypes.CREATE_TRIGGER_FAILED:
      return state.update('isCreating', () => false);
    case actionTypes.CLEAR_CREATED_TRIGGERS:
      const obscure = (t: ImmutableMap<TriggerType>) =>
        t.update('triggerURL', (url: string) => obscureTriggerURL(url)).set('isNew', false);
      return state.withMutations((s) => {
        s.update('triggers', (triggers) => triggers.map(obscure));
      });
    case actionTypes.DELETE_TRIGGER_DONE:
      return state.withMutations((s) =>
        s.update('triggers', (triggers) => triggers.filter((t) => t.get('_id') !== action.trigger._id)),
      );
    case actionTypes.DELETE_ALL_TRIGGERS:
      return state.withMutations((s) => s.update('triggers', () => List()));
    case actionTypes.PATCH_TRIGGER_DONE:
      return state.withMutations((s) => {
        const triggerIndex = s.get('triggers').findIndex((t) => t.get('_id') === action.trigger._id);
        return s.update('triggers', (triggers) =>
          triggers.update(triggerIndex, (existing) =>
            action.kind === TriggerPatchKind.RESET_URL
              ? Map(action.trigger)
              : Map(
                  Object.assign(action.trigger, {
                    isNew: existing.get('isNew'),
                    triggerURL: existing.get('triggerURL'),
                  }),
                ),
          ),
        );
      });
    default:
      return state;
  }
};

export const stickyIntegrationFilters = (state = null, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.SET_STICKY_INTEGRATION_FILTERS:
    case actionTypes.GET_STICKY_INTEGRATION_FILTERS:
      if (!action.stickySearchText) {
        return state;
      } else {
        return action.stickySearchText;
      }
    default:
      return state;
  }
};

export const stickyIntegrationFiltersSelector = (state: GlobalState) => state.integrations.filters;

export const integrations = combineReducers({
  slackIncomingHooks: slackIncomingHookEntities,
  destinations,
  repositories,
  requests: integrationRequests,
  goaltender,
  trigger,
  filters: stickyIntegrationFilters,
});

export const slackIncomingHooksRequestSelector = (state: GlobalState) => state.integrations.requests.get('slack');

export const slackIncomingHooksSelector = (state: GlobalState) => state.integrations.slackIncomingHooks;

type MatchedSlackHookParam = {
  match: {
    params: {
      slackHookId: string;
    };
  };
};

export const slackIncomingHookByIdSelector = (state: GlobalState, props: MatchedSlackHookParam) =>
  slackIncomingHooksSelector(state).get(props.match.params.slackHookId);

export const goaltenderManifestsSelector = (state: GlobalState) => state.integrations.goaltender.get('manifests');

export function useIntegrationManifests() {
  return useSelector(goaltenderManifestsSelector);
}

export const goaltenderSubscriptionsSelector = (state: GlobalState) =>
  state.integrations.goaltender.get('subscriptions');

export const msTeamsSubscriptionCountSelector = (state: GlobalState) =>
  goaltenderSubscriptionsSelector(state)?.get('subscriptions')?.get('msteams')?.get('subscriptions')?.size || 0;

export const msTeamsAppConfigurationCountSelector = (state: GlobalState) =>
  goaltenderSubscriptionsSelector(state)?.get('subscriptions')?.get('msteams-app')?.get('subscriptions')?.size || 0;

export const goaltenderDynamicEnumOptionsSelector = (state: GlobalState, integrationKey: string) =>
  goaltenderSubscriptionsSelector(state).getIn(['subscriptions', integrationKey, 'dynamicEnumOptions']);

type MatchedSubscriptionParam = {
  match: {
    params: {
      subscriptionId: string;
      kind: string;
    };
  };
};

export const goaltenderSubscriptionsByIdSelector = createSelector(
  goaltenderSubscriptionsSelector,
  (state: GlobalState, props: MatchedSubscriptionParam) => props.match.params,
  (subscriptions, params) => {
    const subs = subscriptions.getIn(['subscriptions', params.kind, 'subscriptions']);
    return subs ? subs.find((sub: GoaltenderSubscription) => sub._id === params.subscriptionId) : null;
  },
);

export const repositoriesSelector = (state: GlobalState) => state.integrations.repositories;

export const destinationsSelector = (state: GlobalState) => state.integrations.destinations;

type MatchedDestinationParam = {
  match: {
    params: {
      destinationId: string;
    };
  };
};

export const destinationByIdSelector = createSelector(
  destinationsSelector,
  (state: GlobalState, props: MatchedDestinationParam) => props.match.params.destinationId,
  (dest, destinationId) =>
    fromJS({
      lastFetched: dest.get('lastFetched'),
      isFetching: dest.get('isFetching'),
      entity: dest.getIn(['entities', destinationId]),
    }),
);

// This selector returns the integration manifests that have at least configured subscription
export const goaltenderConfiguredManifestsSelector = createSelector(
  goaltenderManifestsSelector,
  goaltenderSubscriptionsSelector,
  (manifests, subscriptions) => {
    if (!ready(subscriptions)) {
      return OrderedMap<string, ManifestRecord>();
    }
    if (!manifests || !manifests.has('manifests')) {
      return OrderedMap<string, ManifestRecord>();
    }
    return manifests.get('manifests').filter((manifest) => {
      if (manifestMapHasEventsHookCapability(manifest)) {
        const subArray = subscriptions.getIn(['subscriptions', manifest.get('key'), 'subscriptions'], false);
        return subArray && !subArray.isEmpty();
      } else if (manifestMapHasApprovalCapability(manifest)) {
        if (manifest.get('requiresOAuth')) {
          return manifest.getIn(['oauth', 'connected']);
        }

        if (manifestUsesIntegrationConfiguration(manifest.toJS() as IntegrationsManifestServed)) {
          return false;
        }

        return true;
      }

      return false;
    });
  },
);

// integrationsListSelector returns a flat list of all configured integrations
export const integrationsListSelector = createSelector(goaltenderSubscriptionsSelector, (subscriptions) =>
  ready(subscriptions)
    ? subscriptions
        .get('subscriptions')
        .filter((s) => s.has('subscriptions') && !s.get('subscriptions').isEmpty())
        .map((s) => s.get('subscriptions'))
        .toList()
        .flatten(1)
    : [],
);

// Return a list of integrations that have trigger capabilities. Output looks like { label: "Sample Integration", value: "sample-integration" }
export const goaltenderTriggerManifestsSelector = createSelector(goaltenderManifestsSelector, (manifests) => {
  if (!ready(manifests) || !manifests.has('manifests')) {
    return [];
  }

  return Object.values(
    manifests
      .get('manifests')
      .filter((m) => m.hasIn(['capabilities', 'trigger']))
      .toJS(),
  );
});

export const triggerSelector = (state: GlobalState) => state.integrations.trigger;

export type LegacyIntegrationType = {
  name?: string;
  kind?: string;
  type: string;
  isNew?: boolean;
  integrations?: List<Repository> | OrderedMap<string, Destination> | Map<string, Webhook> | List<SlackIncomingHook>;
};

export const legacyIntegrationsSelector = createSelector(
  webhooksSelector,
  destinationsSelector,
  repositoriesSelector,
  slackIncomingHooksSelector,
  (webhooks, dests, repos, slackIncomingHooks) =>
    [
      {
        type: LegacyIntegrationKind.SLACK,
        integrations: slackIncomingHooks.toList(),
      },
      {
        kind: 'slack',
        type: LegacyIntegrationKind.WEBHOOK,
        isNew: false,
        integrations: getSlackAppWebhooks(webhooks),
      },
      {
        type: LegacyIntegrationKind.WEBHOOK,
        integrations: webhooks
          ? webhooks.get('entities').filter((webhook) => !(!!SLACK_APP_HOST && webhook.url.startsWith(SLACK_APP_HOST)))
          : undefined,
      },
      {
        name: 'GitHub',
        kind: 'github',
        type: LegacyIntegrationKind.REPOSITORY,
        integrations: repos ? repos.get('entities').filter((repo) => repo.type === 'github') : undefined,
      },
      {
        name: 'Bitbucket',
        kind: 'bitbucket',
        type: LegacyIntegrationKind.REPOSITORY,
        integrations: repos ? repos.get('entities').filter((repo) => repo.type === 'bitbucket') : undefined,
      },
      {
        name: 'Git',
        kind: 'custom',
        type: LegacyIntegrationKind.REPOSITORY,
        integrations: repos ? repos.get('entities').filter((repo) => repo.type === 'custom') : undefined,
      },
      {
        name: 'GitLab',
        kind: 'gitlab',
        type: LegacyIntegrationKind.REPOSITORY,
        integrations: repos ? repos.get('entities').filter((repo) => repo.type === 'gitlab') : undefined,
      },
      {
        name: 'Amazon Kinesis',
        kind: DestinationKinds.Kinesis,
        type: LegacyIntegrationKind.DESTINATION,
        integrations: dests
          ? dests.get('entities').filter((dest) => dest.kind === DestinationKinds.Kinesis)
          : undefined,
      },
      {
        name: 'Google Cloud Pub/Sub',
        kind: DestinationKinds.Pubsub,
        type: LegacyIntegrationKind.DESTINATION,
        integrations: dests ? dests.get('entities').filter((dest) => dest.kind === DestinationKinds.Pubsub) : undefined,
      },
      //isDestinationKindEnabled is used so that mparticle doesn't display until it is released. Remove when it is released into production.
      configurableDestinations().includes('mparticle') && {
        name: 'mParticle',
        kind: DestinationKinds.MParticle,
        type: LegacyIntegrationKind.DESTINATION,
        integrations: dests
          ? dests.get('entities').filter((dest) => dest.kind === DestinationKinds.MParticle)
          : undefined,
      },
      configurableDestinations().includes('segment') && {
        name: 'Segment',
        kind: DestinationKinds.Segment,
        type: LegacyIntegrationKind.DESTINATION,
        integrations: dests
          ? dests.get('entities').filter((dest) => dest.kind === DestinationKinds.Segment)
          : undefined,
      },
      {
        name: 'Azure Event Hubs',
        kind: DestinationKinds.Azure,
        type: LegacyIntegrationKind.DESTINATION,
        integrations: dests ? dests.get('entities').filter((dest) => dest.kind === DestinationKinds.Azure) : undefined,
      },
      {
        name: 'Snowflake',
        kind: DestinationKinds.Snowflake,
        type: LegacyIntegrationKind.DESTINATION,
        integrations: dests
          ? dests.get('entities').filter((dest) => dest.kind === DestinationKinds.Snowflake)
          : undefined,
      },
    ] as LegacyIntegrationType[],
);

export const legacyConfiguredManifestsSelector = createSelector(
  goaltenderManifestsSelector,
  legacyIntegrationsSelector,
  (manifests, legacyIntegrationsContainer) => {
    if (!manifests || !manifests.has('manifests')) {
      return OrderedMap();
    }

    return manifests
      .get('manifests')
      .filter((m) => m.has('legacy') && hasLegacySubscription(m, legacyIntegrationsContainer));
  },
);

// Check if the legacy integration has a legacy subscription
function hasLegacySubscription(manifestMap: ManifestRecord, integrationContainer: LegacyIntegrationType[]) {
  const container = getLegacyIntegrationContainer(manifestMap, integrationContainer);
  return container && container.integrations && !container.integrations.isEmpty();
}

function getLegacyIntegrationContainer(manifestMap: ManifestRecord, containers: LegacyIntegrationType[]) {
  const key = manifestMap.get('key');
  const legacyKind = manifestMap.getIn(['legacy', 'kind']);

  // Special case since slack-app currently doesn't have a legacy property in ld-integrations
  if (key === 'slack-app') {
    return containers.filter((c) => c.type === LegacyIntegrationKind.WEBHOOK && c.kind === 'slack')[0];
  }
  switch (legacyKind) {
    case 'codeRefs':
      if (key === 'git') {
        return containers.filter((c) => c.type === LegacyIntegrationKind.REPOSITORY && c.kind === 'custom')[0];
      }
      return containers.filter((c) => c.type === LegacyIntegrationKind.REPOSITORY && c.kind === key)[0];
    case 'dataExport':
      return containers.filter((c) => c.type === LegacyIntegrationKind.DESTINATION && c.kind === key)[0];
    case 'slackWebhooks':
      return containers.filter((c) => c.type === LegacyIntegrationKind.SLACK)[0];
    case 'webhooks':
      return containers.filter((c) => c.type === LegacyIntegrationKind.WEBHOOK && c.kind !== 'slack')[0];
    default:
      return;
  }
}

registry.addReducers({ integrations });
