'use strict';

import Immutable from 'immutable';
import utils from './utils';
import lcs from './lcs';
import { path } from './path';

interface PatchOperations {
  op: 'replace' | 'remove';
  path: string;
  value?: any;
}

var concatPath = path.concat,
  escapePath = path.escape,
  op = utils.op,
  isMap = utils.isMap,
  isIndexed = utils.isIndexed;

var mapDiff = function (a: any, b: any, p?: string, options?: { removeEmptyFields?: boolean }) {
  var ops: PatchOperations[] = [];
  var path = p || '';

  if (Immutable.is(a, b) || (a == b) == null) {
    return ops;
  }

  var areLists = isIndexed(a) && isIndexed(b);
  var lastKey: string | null = null;
  var removeKey: string | null = null;

  if (a.forEach) {
    a.forEach(function (aValue: any, aKey: string) {
      if (b.has(aKey)) {
        if (isMap(aValue) && isMap(b.get(aKey))) {
          ops = ops.concat(mapDiff(aValue, b.get(aKey), concatPath(path, escapePath(aKey))));
        } else if (isIndexed(b.get(aKey)) && isIndexed(aValue)) {
          ops = ops.concat(sequenceDiff(aValue, b.get(aKey), concatPath(path, escapePath(aKey))));
        } else {
          var bValue = b.get ? b.get(aKey) : b;
          var areDifferentValues = aValue !== bValue;

          // If the new value is nullish, send the "remove" instruction.
          if (areDifferentValues && !bValue && options?.removeEmptyFields) {
            ops.push(op('remove', concatPath(path, escapePath(aKey)), bValue));
          } else if (areDifferentValues) {
            ops.push(op('replace', concatPath(path, escapePath(aKey)), bValue));
          }
        }
      } else {
        if (areLists) {
          removeKey = lastKey != null && lastKey + 1 === aKey ? removeKey : aKey;
          /* eslint-disable @typescript-eslint/no-non-null-assertion */
          ops.push(
            op('remove', concatPath(path, escapePath(removeKey!))),
          ); /* eslint-enable @typescript-eslint/no-non-null-assertion */
          lastKey = aKey;
        } else {
          ops.push(op('remove', concatPath(path, escapePath(aKey))));
        }
      }
    });
  }

  b.forEach(function (bValue: any, bKey: string) {
    if (a.has && !a.has(bKey)) {
      ops.push(op('add', concatPath(path, escapePath(bKey)), bValue));
    }
  });

  return ops;
};

var sequenceDiff = function (a: any, b: any, p?: string) {
  var ops: PatchOperations[] = [];
  var path = p || '';
  if (Immutable.is(a, b) || (a == b) == null) {
    return ops;
  }
  if ((a.count() + 1) * (b.count() + 1) >= 10000) {
    return mapDiff(a, b, p);
  }

  var lcsDiff = lcs.diff(a, b);

  var pathIndex = 0;

  lcsDiff.forEach(function (diff: any) {
    if (diff.op === '=') {
      pathIndex++;
    } else if (diff.op === '!=') {
      if (isMap(diff.val) && isMap(diff.newVal)) {
        var mapDiffs = mapDiff(diff.val, diff.newVal, concatPath(path, pathIndex));
        ops = ops.concat(mapDiffs);
      } else {
        ops.push(op('replace', concatPath(path, pathIndex), diff.newVal));
      }
      pathIndex++;
    } else if (diff.op === '+') {
      ops.push(op('add', concatPath(path, pathIndex), diff.val));
      pathIndex++;
    } else if (diff.op === '-') {
      ops.push(op('remove', concatPath(path, pathIndex)));
    }
  });

  return ops;
};

var primitiveTypeDiff = function (a: any, b: any, p: string) {
  var path = p || '';
  if (a === b) {
    return [];
  } else {
    return [op('replace', concatPath(path, ''), b)];
  }
};

var diff = function (a: any, b: any, p: any, options?: { removeEmptyFields?: boolean }) {
  if (Immutable.is(a, b)) {
    return Immutable.List();
  }
  if (a != b && (a == null || b == null)) {
    return Immutable.fromJS([op('replace', '/', b)]);
  }
  if (isIndexed(a) && isIndexed(b)) {
    return Immutable.fromJS(sequenceDiff(a, b));
  } else if (isMap(a) && isMap(b)) {
    return Immutable.fromJS(mapDiff(a, b, p, options));
  } else {
    return Immutable.fromJS(primitiveTypeDiff(a, b, p));
  }
};

export { diff };
