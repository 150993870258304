import { isTrialExpired as isLegacyTrialExpired } from '../internal/legacySubscription';
import { Subscription } from '../types';

import { isModernSubscription } from './isModernSubscription';

export function isTrialExpired(subscription: Subscription) {
  if (isModernSubscription(subscription)) {
    return;
  }

  return isLegacyTrialExpired(subscription);
}
