import { Modal, ModalBody, ModalHeader } from 'launchpad';

import { ResourceFinderContainer } from 'components/ResourceFinderContainer';

type ResourceFinderPromptProps = {
  onCancel: () => void;
  onClearResourceType: () => void;
  onValue: (id: string, type: string, projKey?: string) => void;
  resourceType: string;
};

const ResourceFinderPrompt = ({ onCancel, onClearResourceType, onValue, resourceType }: ResourceFinderPromptProps) => (
  <Modal onCancel={onCancel}>
    <ModalHeader title="Find a resource" />
    <ModalBody>
      <ResourceFinderContainer
        onClearResourceType={onClearResourceType}
        onValue={onValue}
        resourceType={resourceType}
      />
    </ModalBody>
  </Modal>
);

/* eslint-disable import/no-default-export */
export default ResourceFinderPrompt;
