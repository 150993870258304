import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'launchpad';

import { DestructiveButton } from 'components/ui/buttons';

export type NavigationPromptModalProps = {
  onCancelNavigation(): void;
  onConfirmNavigation(): void;
  title?: string;
  body?: string;
  cancelText?: string;
  confirmText?: string;
};

/* eslint-disable import/no-default-export */
export default function NavigationPromptModal({
  onCancelNavigation,
  onConfirmNavigation,
  title = 'You have unsaved changes',
  body = 'If you leave this page, you will lose any unsaved changes',
  cancelText = 'Return to editing',
  confirmText = 'Leave page',
}: NavigationPromptModalProps) {
  return (
    <Modal size="small" onCancel={onCancelNavigation}>
      <ModalHeader title={title} />
      <ModalBody>
        <p>{body}</p>
      </ModalBody>
      <ModalFooter
        primaryButton={<DestructiveButton onClick={onConfirmNavigation}>{confirmText}</DestructiveButton>}
        secondaryButton={<Button onClick={onCancelNavigation}>{cancelText}</Button>}
      />
    </Modal>
  );
}
