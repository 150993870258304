// eslint-disable-next-line no-restricted-imports
import { fromJS, Map, Record as ImmutableRecord } from 'immutable';

import { VariationValue } from 'utils/flagUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';

// TODO: deprecate this type as we move away from redux
// use FlagStatusType instead
export class FlagStatus extends ImmutableRecord<{
  _links: ImmutableMap<{
    parent: Link;
    self: Link;
  }>;
  name: string;
  lastRequested: string;
  default?: VariationValue;
  key?: string;
}>({
  _links: Map(),
  name: '',
  lastRequested: '',
  default: undefined,
  key: '',
}) {
  parentLink() {
    return this._links.getIn(['parent', 'href']);
  }

  getFlagKey() {
    if (this.key) {
      return this.key;
    }
    return this.parentLink().split('/').pop();
  }
}

export type FlagStatusType = {
  name: string;
  lastRequested?: string;
  default?: VariationValue;
};

export enum FlagStatusKind {
  NEW = 'new',
  ACTIVE = 'active',
  LAUNCHED = 'launched',
  INACTIVE = 'inactive',
}

// This is used in a few cases where we need to key into enum
export const flagStatusKinds: { [key: string]: FlagStatusKind } = {
  new: FlagStatusKind.NEW,
  active: FlagStatusKind.ACTIVE,
  launched: FlagStatusKind.LAUNCHED,
  inactive: FlagStatusKind.INACTIVE,
};

export const flagStatusKindDisplayNames: Record<FlagStatusKind, string> = {
  [FlagStatusKind.NEW]: 'New',
  [FlagStatusKind.ACTIVE]: 'Active',
  [FlagStatusKind.LAUNCHED]: 'Launched',
  [FlagStatusKind.INACTIVE]: 'Inactive',
};

export const getFlagStatusKindDisplayName = (s: FlagStatusKind) => flagStatusKindDisplayNames[s];

// Some status props have type string, so this is the best way to support
// those instances until they can be updated with FlagStatusKind
export const getFlagStatusKind = (s: string): FlagStatusKind | undefined => flagStatusKinds[s];

export function createFlagStatus(props = {}) {
  return props instanceof FlagStatus ? props : new FlagStatus(fromJS(props));
}
