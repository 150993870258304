import { Time } from '@gonfalon/datetime';

type TimeAgoDateProps = {
  datetime: Date | number | string | undefined | null;
};

//this function return the date formatted as X days ago
export function TimeAgoDate({ datetime }: TimeAgoDateProps) {
  return (
    <Time fromNow datetime={datetime}>
      {(formatted: string) => `${formatted}`}
    </Time>
  );
}
