import { List, Map } from 'immutable';
import { combineReducers } from 'redux';

import { AccountAction } from 'actions/account';
import { FormAction } from 'actions/forms';
import { GlobalState } from 'reducers';
import registry from 'reducers/registry';
import {
  Account,
  createNewRequestSeatsForm,
  createOwnerRecord,
  createSignupForm,
  OwnerInfo,
  RequestSeatsFormType,
} from 'utils/accountUtils';
import { createFormState } from 'utils/formUtils';
import { createImmutableState, ImmutableMap } from 'utils/immutableUtils';
import { RequestAction } from 'utils/requestUtils';

import 'epics/account';

const initialFormState = createImmutableState({
  form: createFormState(createSignupForm()),
  isUnverifiedMemberCreated: false,
});

export function createAccountForm(state = initialFormState, action: AccountAction | FormAction) {
  switch (action.type) {
    case 'account/EDIT_NEW_ACCOUNT':
      return state.update('form', (f) => f.trackField(action.field).revalidate(action.account));
    case 'account/CREATE_UNVERIFIED_MEMBER':
    case 'account/CREATE_ACCOUNT':
      return state.update('form', (f) => f.submitting());
    case 'account/CREATE_UNVERIFIED_MEMBER_FAILED':
    case 'account/CREATE_ACCOUNT_FAILED':
      return state.update('form', (f) => f.submitFailed(action.account, action.error));
    case 'account/CREATE_UNVERIFIED_MEMBER_DONE':
      return state.update('isUnverifiedMemberCreated', () => true);
    case 'forms/BLUR':
      if (action.model !== 'createAccountForm') {
        return state;
      }
      return state.update('form', (f) => f.handleBlur(action.field, f.modified));
    default:
      return state;
  }
}

type AccountEntityState = ImmutableMap<{
  entity?: Account;
  lastFetched: null | number;
  isFetching: boolean;
}>;

function accountEntity(
  state: AccountEntityState = createImmutableState({
    entity: undefined,
    lastFetched: null,
    isFetching: false,
  }),
  action: AccountAction,
) {
  switch (action.type) {
    case 'account/REQUEST_ACCOUNT':
      return state.set('isFetching', true);
    case 'account/REQUEST_ACCOUNT_DONE':
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        entity: action.account,
      });
    case 'account/UPDATE_ACCOUNT_DONE':
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        entity: action.account,
      });
    case 'account/DELETE_ACCOUNT_TOKEN_DONE':
      return state.setIn(['entity', 'tokens'], List());
    default:
      return state;
  }
}

export const account = combineReducers({
  entity: accountEntity,
});

export function apiVersions(state = Map(), action: AccountAction) {
  switch (action.type) {
    case 'account/REQUEST_API_VERSIONS':
      return state.set('isFetching', true);
    case 'account/REQUEST_API_VERSIONS_DONE':
      return state.set('isFetching', false).set('lastFetched', action.timestamp).set('versions', action.versions);
    case 'account/REQUEST_API_VERSIONS_FAILED':
      return state.set('isFetching', false);
    default:
      return state;
  }
}

const initialOwnerFormState = (owner = createOwnerRecord()) => createFormState(owner);

export function updateOwnerForm(state = initialOwnerFormState(), action: RequestAction) {
  if (action.model && action?.model !== 'updateOwnerForm') {
    return state;
  }
  switch (action.type) {
    case 'account/UPDATE_ACCOUNT_OWNER':
      return state.submitting();
    case 'account/UPDATE_ACCOUNT_OWNER_DONE':
      return state.submitted(action.promotedMember);
    case 'account/UPDATE_ACCOUNT_OWNER_FAILED':
      return state.submitFailed(action.promotedMember, action.error);
    case 'account/SELECT_ACCOUNT_OWNER':
      const { field, value } = action;
      const modified = state.modified.set(field, value);
      return state.trackField(field).revalidate(modified);
    case 'forms/BLUR':
      return state.handleBlur(action.field, state.modified);
    case 'forms/DESTROY':
      // Keep the form intact if we were redirected to beastmode escalation
      if (window.location.pathname === '/settings/escalate') {
        return state;
      }
      return initialOwnerFormState();
    case 'forms/INITIALIZE':
      if (action.model && action.model !== 'updateOwnerForm') {
        return state;
      }
      return initialOwnerFormState(action.initialState as OwnerInfo);
    default:
      return state;
  }
}

const initialSeatsRequestFormState = (request = createNewRequestSeatsForm()) => createFormState(request);

export function createRequestSeatsForm(state = initialSeatsRequestFormState(), action: AccountAction | FormAction) {
  const formKey = 'createRequestSeatsForm';
  switch (action.type) {
    case 'account/EDIT_SEAT_REQUEST_FIELD':
      return state.trackField(action.field).revalidate(action.seatsRequest);
    case 'account/CREATE_SEATS_REQUEST':
      return state.submitting();
    case 'account/CREATE_SEATS_REQUEST_FAILED':
      return state.submitFailed(action.seatsRequest, action.error);
    case 'account/CREATE_SEATS_REQUEST_DONE':
      return state.submitted(action.seatsRequest);
    case 'forms/BLUR':
      if (action.model !== formKey) {
        return state;
      }
      return state.handleBlur(action.field, state.modified);
    case 'forms/INITIALIZE':
      if (action.model !== formKey) {
        return state;
      }
      return initialSeatsRequestFormState(createNewRequestSeatsForm(action.initialState as RequestSeatsFormType));
    default:
      return state;
  }
}

export const updateOwnerFormSelector = (state: GlobalState) => state.updateOwnerForm;
export const apiVersionsSelector = (state: GlobalState) => state.apiVersions;
export const createAccountFormSelector = (state: GlobalState) => state.createAccountForm.get('form');
export const createRequestSeatFormSelector = (state: GlobalState) => state.createRequestSeatsForm;
export const isUnverifiedMemberCreatedSelector = (state: GlobalState) =>
  state.createAccountForm.get('isUnverifiedMemberCreated');
export const accountSelector = (state: GlobalState) => state.account.entity;

export const sessionConfigSelector = (state: GlobalState) => accountSelector(state).getIn(['entity', 'sessionConfig']);

registry.addReducers({
  account,
  apiVersions,
  createAccountForm,
  createRequestSeatsForm,
  updateOwnerForm,
});
