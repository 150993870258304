import { Map } from 'immutable';
import { AnyAction } from 'redux';

import { addPatchInstructions, removePatchInstructions, setPatchInstructions } from 'utils/instructions/shared/helpers';
import { InstructionsType, SemanticInstruction } from 'utils/instructions/shared/types';

import registry from './registry';

type PendingChangesAction =
  | {
      type: string;
      instructions: InstructionsType;
      newInstructions: SemanticInstruction[]; // can be array of instructions to add or remove
      addPatch?: boolean;
      replaceId?: string;
    }
  | AnyAction;

export const pendingFlagChangesInstructions = (state: InstructionsType = Map(), action: PendingChangesAction) => {
  if (action.type === 'pendingChanges/SET_FLAG_CONFIGURATION_INSTRUCTIONS') {
    return action.instructions;
  }
  if (action.type === 'pendingChanges/CHANGE_FLAG_CONFIGURATION_INSTRUCTIONS') {
    if (action.addPatch) {
      return addPatchInstructions(state, action.newInstructions, action.replaceId);
    }
    return setPatchInstructions(state, action.newInstructions);
  }
  if (action.type === 'pendingChanges/REMOVE_FLAG_CONFIGURATION_INSTRUCTION') {
    return removePatchInstructions(state, action.newInstructions);
  } else {
    return state;
  }
};

export const pendingFlagChangesInstructionsSelector = (state: $TSFixMe) => state.pendingFlagChangesInstructions;

registry.addReducers({ pendingFlagChangesInstructions });
