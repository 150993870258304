import {
  enableSelfServeUBBWithAnnualCommits,
  isGuardianEditionReverseTrialEnabled,
  isSelfServeFromEnterpriseTrialAllowed,
} from '@gonfalon/dogfood-flags';
import nullthrows from 'nullthrows';

import { isUsageBasedBillingPlanOrTrial } from '../../subscriptions';
import { ModernSubscription, PlanTypes, SubscriptionStates } from '../../types';

import { isModernEnterprisePlan } from './isModernEnterprisePlan';
import { isSelfServe } from './isSelfServe';

export function canMakeSelfServeChanges(modernSubscription: ModernSubscription) {
  const canSelfServe = isSelfServe(modernSubscription) && !modernSubscription._hasCustomLimits;

  // allow Enterprise trials the ability to self serve
  if (
    isSelfServeFromEnterpriseTrialAllowed() &&
    nullthrows(modernSubscription.planType) === PlanTypes.ENTERPRISE_TRIAL
  ) {
    return canSelfServe;
  } else if (
    // allow lapsed Enterprise trials the ability to self serve
    nullthrows(modernSubscription.planType) === PlanTypes.ENTERPRISE_TRIAL &&
    [SubscriptionStates.LAPSED_TRIAL, SubscriptionStates.GRACE_PERIOD_TRIAL].includes(
      modernSubscription.state as SubscriptionStates,
    )
  ) {
    return canSelfServe;
  }

  // allow self serve on usage based billing plans and trials
  if (isUsageBasedBillingPlanOrTrial(modernSubscription) && enableSelfServeUBBWithAnnualCommits()) {
    return true;
  }

  if (nullthrows(modernSubscription.planType) === PlanTypes.GUARDIAN_TRIAL2024) {
    // allow self serve if this is true
    return isGuardianEditionReverseTrialEnabled();
  }

  return !isModernEnterprisePlan(modernSubscription) && canSelfServe;
}
