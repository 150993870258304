import { Link, To } from 'react-router-dom';
import { enableMostRecentAuditLogHistory } from '@gonfalon/dogfood-flags';

type EntrySummaryClickableObjectProps = {
  to?: To;
  className?: string;
  title?: string;
  children: React.ReactNode;
  onClick?: () => void;
};

export const EntrySummaryClickableObject = ({
  to,
  title,
  className,
  children,
  onClick,
}: EntrySummaryClickableObjectProps) =>
  enableMostRecentAuditLogHistory() && to ? (
    <Link title={title} to={to} onClick={onClick}>
      {children}
    </Link>
  ) : (
    <span className={className}>{children}</span>
  );
