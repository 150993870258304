import { ClauseValue } from '@gonfalon/clauses';
import { pluralize } from '@gonfalon/strings';
import { List } from 'immutable';

import { Clause } from 'utils/clauseUtils';
import { ClauseInstructionKind } from 'utils/instructions/clauses/types';

import { ClauseInstructionDescription } from './ClauseInstructionDescription';

export type ClauseValueInstructionEntryProps = {
  projKey: string;
  envKey: string;
  clause: Clause;
  values: List<ClauseValue>;
  kind: ClauseInstructionKind.ADD_VALUES_TO_CLAUSE | ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE;
};

export const ClauseValueInstructionEntry = ({
  projKey,
  envKey,
  clause,
  values,
  kind,
}: ClauseValueInstructionEntryProps) => {
  let verb, preposition;
  if (kind === ClauseInstructionKind.ADD_VALUES_TO_CLAUSE) {
    verb = 'Add';
    preposition = 'to';
  } else if (kind === ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE) {
    verb = 'Remove';
    preposition = 'from';
  }
  return (
    <span data-test-id="clause-value-instruction">
      {verb} {pluralize('value', values.size)}{' '}
      {values.map((value, i) => (
        <span key={i}>
          {i ? ', ' : ''}
          <code>{`${value}`}</code>
        </span>
      ))}{' '}
      {preposition} clause <ClauseInstructionDescription projKey={projKey} envKey={envKey} clause={clause} />
    </span>
  );
};
