import { getVariationColorPalette } from '@gonfalon/flags';
import { scaleOrdinal } from 'd3-scale';
import tinycolor from 'tinycolor2';

import { MetadataType } from './TimeSeriesComponent';

// Todo: generate color algorithm for scale [ch16141]

export const greens = [
  { fill: '#F6FFBF' },
  { fill: '#CEFFBF' },
  { fill: '#91EBA3' },
  { fill: '#5FC29E' },
  { fill: '#379299' },
  { fill: '#2C669C' },
  { fill: '#1A446F' },
];

export const blueGreen = [
  { fill: '#BACEEB', stroke: '#3c65da' },
  { fill: '#DCF7F1', stroke: '#50e3c2' },
];

export const spectrumColors = [
  { fill: '#228be6' },
  { fill: '#38d9a9' },
  { fill: '#f76707' },
  { fill: '#15aabf' },
  { fill: '#ffd43b' },
  { fill: '#e03131' },
  { fill: '#1971c2' },
  { fill: '#a9e34b' },
  { fill: '#e40090' },
  { fill: '#7950f2' },
  { fill: '#fea00d' },
  { fill: '#6de17f' },
];

export const spectrumColorsStroke = [
  { stroke: '#228be6' },
  { stroke: '#38d9a9' },
  { stroke: '#f76707' },
  { stroke: '#15aabf' },
  { stroke: '#ffd43b' },
  { stroke: '#e03131' },
  { stroke: '#1971c2' },
  { stroke: '#a9e34b' },
  { stroke: '#e40090' },
  { stroke: '#7950f2' },
  { stroke: '#fea00d' },
  { stroke: '#6de17f' },
];

export const gradientColors = [
  { fill: '#1864ab' },
  { fill: '#276fad' },
  { fill: '#327aaf' },
  { fill: '#3b86b1' },
  { fill: '#4291b3' },
  { fill: '#499db5' },
  { fill: '#4ea9b7' },
  { fill: '#53b5b8' },
  { fill: '#58c1ba' },
  { fill: '#5ccdbb' },
  { fill: '#60d9bd' },
  { fill: '#63e6be' },
];

export const createColorObj = (hex: string) => ({
  stroke: hex,
  fill: tinycolor(hex).toString(),
  fillOpacity: 0.15,
});

const variationColorPalette = getVariationColorPalette();
export const variationColors = variationColorPalette.map(createColorObj);

export const createGreyscale = <T>(domain: T[]) => {
  const count = domain.length;
  const darkest = 50;
  const lightest = 205;
  const step = (lightest - darkest) / count;
  return domain.map((_: T, i: number) => {
    let val = 0;
    if (i === 0) {
      val = darkest;
    } else if (i === count - 1) {
      val = lightest;
    } else {
      val = darkest + step * i;
    }
    return createColorObj(tinycolor(`rgb(${val}, ${val}, ${val})`).toHexString());
  });
};

// domainMap is a function from possible domain values to indices, to allow for a consistent mapping.
export const createColorScale = <T>(
  colors: Array<{ fill?: string; stroke?: string }>,
  domainMap?: (data: T, index: number) => string,
) => {
  const scale = scaleOrdinal()
    .domain([...Object.keys(colors)])
    .range(colors);
  return (i: number, data: T) =>
    data && domainMap
      ? (scale(domainMap(data, i)) as { fill: string; stroke: string })
      : (scale(i.toString()) as { fill: string; stroke: string });
};

export const createVariationColorPalette = <T>(domainMap?: (data: T, index: number) => string) =>
  createColorScale(variationColors, domainMap);
export const createGreyscaleColorPalette = <T>(domain: T[], domainMap?: (data: T, index: number) => string) =>
  createColorScale(createGreyscale(domain), domainMap);

export const createSpectrumPalette = <T>(domainMap?: (data: T, index: number) => string) =>
  createColorScale(spectrumColors, domainMap);

export const createSpectrumPaletteStroke = <T>(domainMap?: (data: T, index: number) => string) =>
  createColorScale(spectrumColorsStroke, domainMap);

export const createGradientPalette = <T>(domainMap?: (data: T, index: number) => string) =>
  createColorScale(gradientColors, domainMap);

export const createGradientDomainMap = (itemCount: number) => (data: MetadataType, index: number) => {
  const colorCount = gradientColors.length;
  const step = Math.floor(colorCount / itemCount) || 1; // The step between colors is based on the total number of items, but is always at least 1
  const maxColorIndex = colorCount - 1;
  const distanceFromMaxIndex = (itemCount - index - 1) * step;
  return maxColorIndex - distanceFromMaxIndex;
};
