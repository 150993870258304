import { createTrackerForCategory } from '@gonfalon/analytics';
import { AnyRoute } from '@gonfalon/router';

export const trackEvent = createTrackerForCategory('Unsaved changes');

// Track which type of action was associated with the blocked navigation, see:
// https://github.com/remix-run/history/blob/dev/packages/history/index.ts#L6
type NavigationAction = 'POP' | 'PUSH' | 'REPLACE';

export const trackConfirmedNavigationWithUnsavedChanges = (routePattern?: AnyRoute, action?: NavigationAction) =>
  trackEvent('Navigation with unsaved changes confirmed', {
    routePattern,
    navigationAction: action,
  });
export const trackCanceledNavigationWithUnsavedChanges = (routePattern?: AnyRoute, action?: NavigationAction) =>
  trackEvent('Navigation with unsaved changes canceled', {
    routePattern,
    navigationAction: action,
  });
