// This is a map of currency units to their ISO codes
// This covers some basic cases that we expect to run into
// If we need extensive support, we should use a library like `currency.js` or `@formatjs/intl-unified-numberformat`
const CURRENCY_MAP = {
  usd: 'USD',
  $: 'USD',
  gbp: 'GBP',
  '£': 'GBP',
  eur: 'EUR',
  '€': 'EUR',
} as const;

type CurrencyUnit = keyof typeof CURRENCY_MAP;

export function formatValueAsCurrency(value: number | string, unit: CurrencyUnit) {
  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: CURRENCY_MAP[unit],
    currencyDisplay: 'narrowSymbol',
  };

  return new Intl.NumberFormat(navigator.language, options).format(Number(value));
}

export function isCurrency(unit: string): unit is CurrencyUnit {
  return unit in CURRENCY_MAP;
}
