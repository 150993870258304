import { typedObjectKeys } from '@gonfalon/types';

import type { UIFlagFilter } from '../types';

import { isDefaultFlagFilterValue } from './isDefaultFlagFilterValue';

export function clearDefaultFlagFilterValues(filter: UIFlagFilter) {
  const newFilter = structuredClone(filter);
  for (const key of typedObjectKeys(newFilter)) {
    if (isDefaultFlagFilterValue(key, newFilter[key])) {
      delete newFilter[key];
    }
  }
  return newFilter;
}
