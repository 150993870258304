import { ContextKindPayload } from 'components/Contexts/common/components/ContextKindEditModal';
import { SettingsPayload } from 'components/Contexts/common/components/ContextKindEditModal/types';
import { updateContextKindKey } from 'components/Contexts/common/contextAPI';
import {
  addRandomizationUnitToExperimentSettings,
  getProjectExperimentationSettings,
  removeRandomizationUnitFromExperimentSettings,
} from 'components/experimentation/common/api/experiment';
import { ExperimentationSettings } from 'components/experimentation/common/types';
import { contextKindsRequestSelector } from 'reducers/contextKinds';
import { getContextKindsForProject } from 'sources/ProjectAPI';

import { GetState, GlobalDispatch, GlobalState } from '../reducers';

export const fetchContextKindsForProject = (projectKey: string) => async (dispatch: GlobalDispatch) => {
  dispatch({ type: 'contextKinds/REQUEST_STARTED' });
  await getContextKindsForProject(projectKey)
    .then((response) => {
      dispatch({ type: 'contextKinds/REQUEST_FINISHED', contextKinds: response });
    })
    .catch((error) => {
      dispatch({ type: 'contextKinds/REQUEST_FAILED', contextKinds: error });
    });
};

const shouldFetchContextKinds = (state: GlobalState) => {
  const contextKinds = contextKindsRequestSelector(state);
  return !contextKinds.lastFetched && !contextKinds.isFetching;
};

export const fetchContextKindsIfNeeded =
  (projectKey: string) => async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchContextKinds(getState())) {
      return dispatch(fetchContextKindsForProject(projectKey));
    }
  };

export const fetchContextKindExperimentationSettings = (projectKey: string) => async (dispatch: GlobalDispatch) => {
  dispatch({ type: 'contextKinds/EXPERIMENTATION_SETTINGS_REQUEST_STARTED' });
  await getProjectExperimentationSettings(projectKey)
    .then((response) => {
      dispatch({ type: 'contextKinds/EXPERIMENTATION_SETTINGS_REQUEST_FINISHED', experimentationSettings: response });
    })
    .catch((error) => {
      dispatch({ type: 'contextKinds/EXPERIMENTATION_SETTINGS_REQUEST_FAILED', experimentationSettings: error });
    });
};

export const updateContextKindForExperimentation =
  (projectKey: string, randomizationUnit: SettingsPayload) => async (dispatch: GlobalDispatch) => {
    dispatch({ type: 'contextKinds/PUT_EXPERIMENTATION_SETTINGS_REQUEST_STARTED' });

    let updatedSettings: ExperimentationSettings;
    if (randomizationUnit.useForExperimentation === false) {
      updatedSettings = await removeRandomizationUnitFromExperimentSettings(
        projectKey,
        randomizationUnit.randomizationUnit,
      );
    } else {
      updatedSettings = await addRandomizationUnitToExperimentSettings(projectKey, {
        randomizationUnit: randomizationUnit.randomizationUnit,
        standardRandomizationUnit: randomizationUnit.standardRandomizationUnit,
        default: randomizationUnit.default,
      });
    }

    dispatch({ type: 'contextKinds/PUT_EXPERIMENTATION_SETTINGS_REQUEST_FINISHED' });
    dispatch({
      type: 'contextKinds/EXPERIMENTATION_SETTINGS_REQUEST_FINISHED',
      experimentationSettings: updatedSettings,
    });
  };

export const updateContextKindForProject =
  (
    projectKey: string,
    contextKindRequest: ContextKindPayload | undefined,
    randomizationUnit?: SettingsPayload | undefined,
  ) =>
  async (dispatch: GlobalDispatch) => {
    dispatch({ type: 'contextKinds/PUT_CONTEXT_REQUEST_STARTED' });
    try {
      if (contextKindRequest) {
        const { key } = contextKindRequest;
        await updateContextKindKey(projectKey, key, contextKindRequest);
        dispatch({ type: 'contextKinds/PUT_CONTEXT_REQUEST_FINISHED', modifiedContext: contextKindRequest });
      }

      if (randomizationUnit) {
        await dispatch(updateContextKindForExperimentation(projectKey, randomizationUnit));
      }
    } catch (error) {
      dispatch({ type: 'contextKinds/PUT_CONTEXT_REQUEST_FAILED', contextKinds: error });
    }
  };

export const archiveAndRestoreContext =
  (projectKey: string, contextKindRequest: ContextKindPayload | undefined, isArchived: boolean | undefined) =>
  async (dispatch: GlobalDispatch) => {
    try {
      if (contextKindRequest) {
        const { key } = contextKindRequest;
        await updateContextKindKey(projectKey, key, contextKindRequest);
        if (isArchived) {
          dispatch({ type: 'contextKinds/ARCHIVE_CONTEXT', modifiedContext: contextKindRequest });
        } else {
          dispatch({ type: 'contextKinds/RESTORE_CONTEXT', modifiedContext: contextKindRequest });
        }
      }
    } catch (error) {
      dispatch({ type: 'contextKinds/PUT_CONTEXT_REQUEST_FAILED', contextKinds: error });
    }
  };

export const handleContextPatchFlagResponse = (isSuccessful: boolean) => (dispatch: GlobalDispatch) => {
  if (isSuccessful) {
    dispatch({ type: 'contextKinds/PATCH_FLAG_RESPONSE_SUCCESS' });
  } else {
    dispatch({ type: 'contextKinds/PATCH_FLAG_RESPONSE_FAILED' });
  }
};

export const handleDeleteContextResponse = (isSuccessful: boolean) => (dispatch: GlobalDispatch) => {
  if (isSuccessful) {
    dispatch({ type: 'contextKinds/DELETE_CONTEXT_SUCCESS' });
  } else {
    dispatch({ type: 'contextKinds/DELETE_CONTEXT_FAILED' });
  }
};
