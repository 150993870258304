import { useEffect } from 'react';
import { connect } from 'react-redux';
import { ProgressBar } from '@launchpad-ui/components';

import { fetchAccount as fetchAccountAction } from 'actions/account';
import { GlobalState } from 'reducers';
import { accountSelector } from 'reducers/account';
import { subscriptionSelector } from 'reducers/billing';
import { profileSelector } from 'reducers/profile';
import { ready } from 'utils/reduxUtils';

import UpsellFeature, { UpsellFeatureProps } from './UpsellFeature';

export type UpsellFeatureContainerProps = UpsellFeatureProps & { fetchAccount(): void; isReady: boolean };

export const UpsellFeatureContainer = ({ fetchAccount, isReady, ...finalProps }: UpsellFeatureContainerProps) => {
  useEffect(() => {
    if (!isReady) {
      fetchAccount();
    }
  }, []);
  if (!isReady) {
    return (
      <div data-test-id="UpsellFeatureContainer-progress">
        <ProgressBar aria-label="Loading…" isIndeterminate />
      </div>
    );
  }
  return <UpsellFeature {...finalProps} />;
};

const mapStateToProps = (state: GlobalState) => {
  const profile = profileSelector(state).get('entity');
  const account = accountSelector(state);
  const accountEntity = account?.get('entity');
  const checkAccess = accountEntity?.checkAccess({ profile });
  const updateSubscriptionAccess = checkAccess && checkAccess('updateSubscription');
  const subscription = subscriptionSelector(state);
  const enterpriseCampaignActive = !!subscription.entity.getEnterpriseCampaign()?.isActive();

  return {
    isReady: ready(account),
    canManagePlans: updateSubscriptionAccess?.isAllowed || false,
    account: accountEntity,
    enterpriseCampaignActive,
  };
};

const mapDispatchToProps = {
  fetchAccount: fetchAccountAction,
};
/* eslint-disable import/no-default-export */
export default connect(mapStateToProps, mapDispatchToProps)(UpsellFeatureContainer);
