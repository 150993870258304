import { isDefaultFlagFilterValue } from './internal/isDefaultFlagFilterValue';
import { flagProjectFilters } from './flagProjectFilters';
import { type UIFlagFilter } from './types';

export function countActiveProjectFilters(filters: UIFlagFilter | undefined) {
  if (filters === undefined) {
    return 0;
  }

  let count = 0;

  for (const key of flagProjectFilters) {
    const value = filters[key];

    if (value === undefined) {
      continue;
    }

    if (isDefaultFlagFilterValue(key, value)) {
      continue;
    }

    if (typeof value === 'string') {
      if (value !== '') {
        count++;
        continue;
      }
    }

    if (Array.isArray(value)) {
      if (value.length > 0) {
        count++;
        continue;
      }
    }

    count++;
  }

  return count;
}
