import { forwardRef } from 'react';

import { ReactAriaSearchField, ReactAriaSearchFieldProps, SearchFieldHandle } from './SearchField';

export type { CompactTextFieldProps } from './CompactTextField';
export type { SearchFieldHandle } from './SearchField';
export type { RepeaterFieldItemProps } from './RepeaterField';

export { Form } from './Form';
export { FormGroup } from './FormGroup';
export { FormError } from './FormError';
export { FormHint } from './FormHint';
export { FieldSet } from './FieldSet';
export { FieldError } from './FieldError';
export { AutosizeTextArea } from './AutosizeTextArea';
export { IconField } from './IconField';
export { CompactTextField } from './CompactTextField';
export { RepeaterField } from './RepeaterField';
export { SwatchColorPicker } from './color';
export { FormField } from './FormField';

type MergedSearchFieldProps = ReactAriaSearchFieldProps;

const SearchField = forwardRef<SearchFieldHandle, MergedSearchFieldProps>((props, ref) => (
  <ReactAriaSearchField ref={ref} {...props} />
));
SearchField.displayName = 'SearchField';
export { SearchField, type MergedSearchFieldProps as SearchFieldProps };

export { ControlledSearchField } from './ControlledSearchField';
