import cx from 'clsx';

import './styles.css';

export type DataListProps = {
  className?: string;
  compact?: boolean;
  testId?: string;
  children?: React.ReactNode;
};

export function DataList({ className, compact, testId, children }: DataListProps) {
  return (
    <div className={cx('DataList', className, { 'DataList--compact': compact })} data-test-id={testId}>
      <div className="DataList-wrapper">
        <div className="DataList-content">{children}</div>
      </div>
    </div>
  );
}
