import { List, OrderedMap } from 'immutable';

import { AccessChecks } from 'utils/accessUtils';
import { Branch } from 'utils/codeRefs/codeRefsUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { Link, Links } from 'utils/linkUtils';

export enum CodeReferencesFilterKind {
  REPO = 'repo',
  BRANCH = 'branch',
  EXTENSIONS = 'extensions',
  SHOW_EMPTY = 'showEmpty',
}

export enum CodeReferencesRepoType {
  BITBUCKET = 'bitbucket',
  CUSTOM = 'custom',
  GITHUB = 'github',
  GITLAB = 'gitlab',
}

type RepoBase = {
  _links: Links;
  defaultBranch: string;
  enabled: boolean;
  name: string;
  sourceLink: string;
  version: number;
};

export type Repo = RepoBase & {
  _access: AccessChecks;
  id: string;
  type: string;
  branches: OrderedMap<string, Branch>;
  commitUrlTemplate: string;
  hunkUrlTemplate: string;
};

export type RepoStats = RepoBase & {
  hunkCount: number;
  fileCount: number;
};

export type RepoUrlParts = {
  [key: string]: string | number | undefined;
  sha: string;
  filePath?: string;
  lineNumber?: number;
  branchName?: string;
};

export type CodeRefsFilter = {
  q: string;
  repo: string;
  branch: string;
  extensions: List<string>;
  showEmpty: boolean;
};

export type Hunk = {
  startingLineNumber: number;
  lines: string;
  projKey: string;
  flagKey: string;
  aliases: List<string>;
  sourceLink: string;
};

export type AllCodeRefStats = {
  _links: ImmutableMap<{
    self: Link;
  }>;
  items: List<RepoStats>;
};

export type RepositoryForm = {
  enabled: boolean;
};
