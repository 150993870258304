import { createContext, ReactNode, useContext } from 'react';

import { usePendingChangesFeatureAccess } from 'hooks/usePendingChangesFeatureAccess';
import { useCurrentEnvironmentEntity } from 'reducers/projects';
import { AccessDecision, combineAccessDecisions, createAccessDecision } from 'utils/accessUtils';
import { Environment } from 'utils/environmentUtils';
import { createFlag, Flag } from 'utils/flagUtils';
import { usePendingChangesAccessDecisions } from 'utils/pendingChangesUtils';

export type PendingChangesContextValue = Readonly<{
  enableFilterByApprovers?: boolean;
  environment: Environment;
  flag: Flag;
  hasExpiringTargetChanges: boolean;
  approvalAccess: AccessDecision;
  scheduleAccess: AccessDecision;
}>;

export const PendingChangesContext = createContext<PendingChangesContextValue>({} as PendingChangesContextValue);

export function PendingChangesProvider({
  environment,
  flag = createFlag(),
  hasExpiringTargetChanges = false,
  children,
}: {
  environment?: Environment;
  flag?: Flag;
  hasExpiringTargetChanges?: boolean;
  children: ReactNode;
}) {
  const currentEnv = useCurrentEnvironmentEntity();
  const pendingChangesFeatureAccess = usePendingChangesFeatureAccess();
  const upgradeText = 'Upgrade your plan to use this feature';
  const { updateScheduledChanges } = usePendingChangesAccessDecisions(flag);

  const approvalAccess = createAccessDecision({
    isAllowed: pendingChangesFeatureAccess.approvals(),
    customMessage: upgradeText,
  });

  const scheduleAccess = combineAccessDecisions(
    createAccessDecision({
      isAllowed: pendingChangesFeatureAccess.scheduledChanges(),
      customMessage: upgradeText,
    }),
    updateScheduledChanges,
    createAccessDecision({
      isAllowed: !hasExpiringTargetChanges,
      customMessage: 'Cannot schedule to remove an individual context at a future date',
    }),
  );

  return (
    <PendingChangesContext.Provider
      value={{
        enableFilterByApprovers: pendingChangesFeatureAccess.filterByApprovers(),
        environment: environment || currentEnv,
        hasExpiringTargetChanges,
        approvalAccess,
        scheduleAccess,
        flag,
      }}
    >
      {children}
    </PendingChangesContext.Provider>
  );
}

export function usePendingChangesContext() {
  return useContext(PendingChangesContext);
}
