import { Card, CardGroup } from '@gonfalon/launchpad-experimental';
import { Stack } from '@launchpad-ui/core';

import styles from './Skeleton.module.css';

export function Skeleton() {
  return (
    <div className={styles.auditlog}>
      {[...Array(10)].map((_, i) => (
        <div key={i} className={styles.day}>
          <CardGroup>
            <Stack gap="2">
              <div className={styles.timestamp} />
              <Card>
                <div className={styles.fallback}>
                  <div className={styles.line} />
                  <div className={styles.line} />
                  <div className={styles.line} />
                </div>
              </Card>
            </Stack>
          </CardGroup>
        </div>
      ))}
    </div>
  );
}
