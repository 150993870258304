import { PlanTypes, Subscription } from '../types';

import { isModernSubscription } from './isModernSubscription';

export function isGuardianPlan(subscription: Subscription): boolean {
  if (isModernSubscription(subscription)) {
    const { planType } = subscription;
    if (planType === null || planType === undefined) {
      return false;
    }
    return [PlanTypes.GUARDIAN_TRIAL2024, PlanTypes.GUARDIAN2024, PlanTypes.GUARDIAN2024S].includes(planType);
  }
  return false;
}
