import { List, Map, OrderedMap } from 'immutable';

import { GetState, GlobalDispatch } from 'reducers';
import { repositoryFormSelector } from 'reducers/flagCodeReferences';
// eslint-disable-next-line import/no-namespace
import * as CodeReferencesAPI from 'sources/CodeReferencesAPI';
import {
  Branch,
  CodeStatisticsForFlag,
  Extinction,
  Repository,
  RepositoryFormRecord,
} from 'utils/codeRefs/codeRefsUtils';
import { RepositoryForm } from 'utils/codeRefs/types';
import { ImmutableServerError } from 'utils/httpUtils';
import { GenerateActionType } from 'utils/reduxUtils';

export const collapseAll = () => ({ type: 'codeRefs/COLLAPSE_ALL' }) as const;

export const toggleCollapsed = (repo: string, branch: string, refIdx: number) =>
  ({ type: 'codeRefs/TOGGLE_COLLAPSED', repo, branch, refIdx }) as const;

export const loadMoreCodeReferencesForRepo = (repoId: string) =>
  ({
    type: 'codeRefs/LOAD_MORE_CODE_REFERENCES',
    repoId,
  }) as const;

export const resetPagination = () =>
  ({
    type: 'codeRefs/RESET_PAGINATION',
  }) as const;

const requestCodeStatistics = (flagKey: string) =>
  ({
    type: 'codeRefs/REQUEST_CODE_STATISTICS',
    flagKey,
  }) as const;

const receiveCodeStatistics = (flagStatistics: OrderedMap<string, List<CodeStatisticsForFlag>>) =>
  ({
    type: 'codeRefs/RECEIVE_CODE_STATISTICS',
    flagStatistics,
  }) as const;

const requestCodeStatisticsFailed = (flagKey: string, error: ImmutableServerError) =>
  ({
    type: 'codeRefs/REQUEST_CODE_STATISTICS_FAILED',
    flagKey,
    error,
  }) as const;

const editRepositoryForm = (field: string, modifiedForm: RepositoryFormRecord) =>
  ({
    type: 'codeRefs/EDIT_REPOSITORY',
    field,
    repository: modifiedForm,
  }) as const;

export const updateRepositoryForm = (updatedRepository: Repository) =>
  ({
    type: 'codeRefs/UPDATE_REPOSITORY',
    repository: updatedRepository,
  }) as const;

export const updateRepositoryFormDone = (updatedRepository: Repository) =>
  ({
    type: 'codeRefs/UPDATE_REPOSITORY_DONE',
    repository: updatedRepository,
  }) as const;

export const updateRepositoryFormFailed = (updatedRepository: Repository, error: ImmutableServerError) =>
  ({
    type: 'codeRefs/UPDATE_REPOSITORY_FAILED',
    repository: updatedRepository,
    error,
  }) as const;

const requestCodeRefs = (flagKey: string) => ({ type: 'codeRefs/REQUEST_FLAG_CODE_REFERENCES', flagKey }) as const;
const receiveCodeRefs = (
  repos: OrderedMap<string, Repository>,
  defaultBranchExtinctions: Map<string, List<Extinction>>,
  flagKey: string,
) => ({ type: 'codeRefs/RECEIVE_FLAG_CODE_REFERENCES', repos, defaultBranchExtinctions, flagKey }) as const;
const requestCodeRefsFailed = (error: ImmutableServerError, flagKey: string) =>
  ({ type: 'codeRefs/REQUEST_FLAG_CODE_REFERENCES_FAILED', error, flagKey }) as const;

const requestBranchCodeRefs = (repoName: string, branchName: string, flagKey: string) =>
  ({
    type: 'codeRefs/REQUEST_BRANCH_CODE_REFERENCES',
    repoName,
    branchName,
    flagKey,
  }) as const;
const receiveBranchCodeRefs = (
  repoName: string,
  branchName: string,
  branch: Branch,
  extinctions: Map<string, Map<string, List<Extinction>>>,
  flagKey: string,
) =>
  ({
    type: 'codeRefs/RECEIVE_BRANCH_CODE_REFERENCES',
    repoName,
    branchName,
    branch,
    extinctions,
    flagKey,
  }) as const;
const requestBranchCodeRefsFailed = (
  error: ImmutableServerError,
  repoName: string,
  branchName: string,
  flagKey: string,
) =>
  ({
    type: 'codeRefs/REQUEST_BRANCH_CODE_REFERENCES_FAILED',
    error,
    repoName,
    branchName,
    flagKey,
  }) as const;

export const fetchBranchCodeReferences =
  (repoName: string, branchName: string, projectKey: string, flagKey: string) => async (dispatch: GlobalDispatch) => {
    dispatch(requestBranchCodeRefs(repoName, branchName, flagKey));
    return Promise.all([
      CodeReferencesAPI.getBranch(repoName, branchName, projectKey, flagKey),
      CodeReferencesAPI.getExtinctions(repoName, branchName, projectKey, flagKey),
    ])
      .then(([branch, extinctions]) => {
        dispatch(receiveBranchCodeRefs(repoName, branchName, branch, extinctions, flagKey));
      })
      .catch((error: ImmutableServerError) => {
        dispatch(requestBranchCodeRefsFailed(error, repoName, branchName, flagKey));
      });
  };

export const fetchFlagCodeReferences = (projectKey: string, flagKey: string) => async (dispatch: GlobalDispatch) => {
  dispatch(requestCodeRefs(flagKey));
  return Promise.all([
    CodeReferencesAPI.getRepositoriesForFlag(projectKey, flagKey),
    CodeReferencesAPI.getExtinctions('', '', projectKey, flagKey),
  ])
    .then(([repos, defaultBranchExtinctions]) => {
      dispatch(receiveCodeRefs(repos, defaultBranchExtinctions, flagKey));
    })
    .catch((error: ImmutableServerError) => {
      dispatch(requestCodeRefsFailed(error, flagKey));
    });
};

export const fetchCodeStatisticsForFlags =
  (projectKey: string, flagKey: string) => async (dispatch: GlobalDispatch) => {
    dispatch(requestCodeStatistics(flagKey));
    return CodeReferencesAPI.getRepositoryCount(projectKey, flagKey)
      .then((flagStatistics) => {
        dispatch(receiveCodeStatistics(flagStatistics));
      })
      .catch((error: ImmutableServerError) => {
        dispatch(requestCodeStatisticsFailed(flagKey, error));
      });
  };

export function editRepository(field: keyof RepositoryForm, value: boolean) {
  return (dispatch: GlobalDispatch, getState: GetState) => {
    const form = repositoryFormSelector(getState());
    const modified = form.modified.set(field, value);
    return dispatch(editRepositoryForm(field, modified));
  };
}

const FlagCodeReferencesActionCreators = {
  collapseAll,
  editRepositoryForm,
  updateRepositoryForm,
  updateRepositoryFormDone,
  updateRepositoryFormFailed,
  toggleCollapsed,
  loadMoreCodeReferencesForRepo,
  resetPagination,
  requestCodeRefs,
  receiveCodeRefs,
  requestCodeRefsFailed,
  requestBranchCodeRefs,
  receiveBranchCodeRefs,
  requestBranchCodeRefsFailed,
  requestCodeStatistics,
  receiveCodeStatistics,
  requestCodeStatisticsFailed,
};

export type FlagCodeReferencesAction = GenerateActionType<typeof FlagCodeReferencesActionCreators>;
