import { Icon } from '@launchpad-ui/icons';
import { Chip } from 'launchpad';

import MetricKind, { getMetricKindDisplay, MetricGroupKind } from 'utils/MetricKind';

type MetricLabelProps = {
  kind: MetricKind | MetricGroupKind;
  isNumeric?: boolean;
  unitAggregationType?: 'sum' | 'average';
};

const MetricLabel = ({ kind, isNumeric, unitAggregationType }: MetricLabelProps) => {
  const { displayIcon, displayName } = getMetricKindDisplay(kind, unitAggregationType, isNumeric);

  return (
    <Chip size="tiny" icon={<Icon name={displayIcon} />}>
      {displayName}
    </Chip>
  );
};

/* eslint-disable import/no-default-export */
export default MetricLabel;
