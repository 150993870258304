import { Icon } from '@launchpad-ui/icons';

import styles from './AllEnvironmentsMarker.module.css';

/* eslint-disable import/no-default-export */
export default function AllEnvironmentsMarker() {
  return (
    <>
      <Icon name="globe" className={styles.icon} size="medium" /> <span className={styles.label}>All environments</span>
    </>
  );
}
