import { disabledSuspenseQuery } from './internal/disabledQuery';

/**
 * Use this function within `useSuspenseQueries` when the execution of a query is conditional.
 * If the query is not enabled, a fixed query object is returned instead.
 *
 * This causes this query's data to be the union of `TData | 'disabled-suspense-query-data'`.
 *
 * To check if this query is enabled while also re-narrowing its data back to its original type,
 * pass the corresponding `UseSuspenseQueryResult` object returned from `useSuspenseQueries` into the
 * `isSuspsenseQueryEnabled` type guard.
 *
 * @example
 * const [resultOne, resultTwo] = useSuspenseQueries({
 *   queries: [
 *     queryOne,
 *     conditionalSuspenseQuery({ query: queryTwo, enabled: isQueryTwoEnabled() }),
 *   ],
 * });
 *
 * if (isSuspenseQueryEnabled(resultTwo)) {
 *  return resultTwo.data.will.be.inferred.properly;
 * }
 */
export function conditionalSuspenseQuery<T>({ query, enabled }: { query: T; enabled: boolean }) {
  return enabled ? query : disabledSuspenseQuery;
}
