import { chunk } from '@gonfalon/es6-utils';
import { ofType, StateObservable } from 'redux-observable';
import { from, Observable, of, timer } from 'rxjs';
import {
  bufferTime,
  catchError,
  concatMap,
  filter,
  groupBy,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  toArray,
} from 'rxjs/operators';

import {
  clearContextsResolutionResults,
  ContextOptionsAction,
  ContextResolutionAction,
  ContextTargetsAction,
  loadContextOptionsFailed,
  loadContextTargetBatchDone,
  loadContextTargetBatchFailed,
  receiveContextOptions,
  resolveContextsDone,
  resolveContextsFailed,
} from 'actions/contexts';
import { fetchContextList, fetchContextsWithNames, resolveContexts } from 'components/Contexts/common/contextAPI';
import { ContextItemsEntities, ContextTargetKindKey, ResolveContextsResponse } from 'components/Contexts/types';
import {
  augmentResolveContextsResponse,
  getContextsWithMissingNames,
  monkeyPatchContextItemsWithNames,
} from 'components/Contexts/utils/contextTargetingUtils';
import registry from 'epics/registry';
import { GlobalState } from 'reducers';
import { contextResolutionSelector } from 'reducers/contexts';
import { DEBOUNCE_MS } from 'utils/inputUtils';

const RESOLVE_TARGETS_MAX_LIMIT = 50;
const RESOLVE_CONTEXTS_MAX_LIMIT = 50;

export function loadContextTargetBatch(action$: Observable<ContextTargetsAction>) {
  return action$.pipe(
    ofType<ContextTargetsAction, 'contexts/LOAD_TARGET'>('contexts/LOAD_TARGET'),
    groupBy((action) => action.viewId),
    mergeMap((group) =>
      group.pipe(
        bufferTime(DEBOUNCE_MS, null, RESOLVE_TARGETS_MAX_LIMIT),
        filter((buffer) => buffer.length > 0),
        mergeMap((buffer) => {
          const viewId = group.key;
          const targets = buffer.map((action) => action.target);
          return from(resolveContexts(buffer[0].projKey, buffer[0].envKey, targets, '-ts')).pipe(
            switchMap((contextsResponse: ResolveContextsResponse) => {
              const missingContextNames = getContextsWithMissingNames(contextsResponse);

              if (!missingContextNames.length) {
                return of(
                  loadContextTargetBatchDone(viewId, targets, contextsResponse, buffer[0].projKey, buffer[0].envKey),
                );
              }

              return from(fetchContextsWithNames(buffer[0].projKey, buffer[0].envKey, missingContextNames)).pipe(
                map((contextsResponseWithNames) => {
                  if (contextsResponseWithNames.items.length === 0) {
                    return loadContextTargetBatchDone(
                      viewId,
                      targets,
                      contextsResponse,
                      buffer[0].projKey,
                      buffer[0].envKey,
                    );
                  } else {
                    const contextsWithNames = monkeyPatchContextItemsWithNames(
                      contextsResponse,
                      contextsResponseWithNames,
                    );
                    const entitesWithNames = augmentResolveContextsResponse(contextsWithNames);
                    return loadContextTargetBatchDone(
                      viewId,
                      targets,
                      entitesWithNames,
                      buffer[0].projKey,
                      buffer[0].envKey,
                    );
                  }
                }),
              );
            }),
            catchError((error) =>
              of(loadContextTargetBatchFailed(error, viewId, targets, buffer[0].projKey, buffer[0].envKey)),
            ),
          );
        }),
      ),
    ),
  );
}

export function fetchContextOptions(action$: Observable<ContextOptionsAction>) {
  return action$.pipe(
    ofType<ContextOptionsAction, 'contexts/SEARCH_CONTEXTS'>('contexts/SEARCH_CONTEXTS'),
    groupBy((action) => action.id),
    mergeMap((group) =>
      group.pipe(
        switchMap((action) =>
          timer(DEBOUNCE_MS).pipe(
            mergeMap(() => {
              const { projKey: projectKey, envKey: environmentKey, contextKind: kind, q: prefix } = action;
              return from(fetchContextList(projectKey, environmentKey, kind, prefix)).pipe(
                switchMap((contextsResponse: ResolveContextsResponse) => {
                  const missingContextNames = getContextsWithMissingNames(contextsResponse);

                  if (!missingContextNames.length) {
                    return of(
                      receiveContextOptions(action.id, action.q, contextsResponse, projectKey, environmentKey, kind),
                    );
                  }

                  return from(fetchContextsWithNames(projectKey, environmentKey, missingContextNames)).pipe(
                    map((contextsResponseWithNames) => {
                      if (contextsResponseWithNames.items.length === 0) {
                        return receiveContextOptions(
                          action.id,
                          action.q,
                          augmentResolveContextsResponse(contextsResponse),
                          projectKey,
                          environmentKey,
                          kind,
                        );
                      } else {
                        const contextsWithNames = monkeyPatchContextItemsWithNames(
                          contextsResponse,
                          contextsResponseWithNames,
                        );
                        return receiveContextOptions(
                          action.id,
                          action.q,
                          augmentResolveContextsResponse(contextsWithNames),
                          projectKey,
                          environmentKey,
                          kind,
                        );
                      }
                    }),
                  );
                }),
                catchError((error) =>
                  of(loadContextOptionsFailed(error, action.id, action.q, projectKey, environmentKey, kind)),
                ),
              );
            }),
          ),
        ),
      ),
    ),
  );
}

export function resolveContextsBatch(
  action$: Observable<ContextResolutionAction>,
  state$: StateObservable<GlobalState>,
) {
  return action$.pipe(
    ofType<ContextResolutionAction, 'contexts/RESOLVE_CONTEXTS'>('contexts/RESOLVE_CONTEXTS'),
    filter((action) => !action.targets.isEmpty()),
    switchMap((action) =>
      timer(DEBOUNCE_MS).pipe(
        takeUntil(action$.pipe(ofType('contexts/CLEAR_RESOLUTION_RESULTS'))),
        mergeMap(() => {
          const projKey = action.projKey;
          const envKey = action.envKey;
          const prevResolution = contextResolutionSelector(state$.value);
          const prevTargets = prevResolution.targets;
          const prevResults = prevResolution.results;
          const targets = action.targets;
          const searchEmails = action.searchEmails;
          const isSuperset = targets.isSuperset(prevTargets);
          const isSubset = targets.isSubset(prevTargets);
          let chunks: ContextTargetKindKey[][];

          if (isSuperset) {
            // new targets were added: request new ones
            const newTargets = targets.subtract(prevTargets).toArray();
            chunks = chunk(newTargets, RESOLVE_CONTEXTS_MAX_LIMIT);
          } else if (isSubset) {
            // targets were removed: no requests necessary
            chunks = [];
          } else {
            // different targets: request all of them
            chunks = chunk(targets.toArray(), RESOLVE_CONTEXTS_MAX_LIMIT);
          }

          return of(...chunks).pipe(
            concatMap((chunkItem) => from(resolveContexts(projKey, envKey, chunkItem, '-ts', searchEmails))),
            toArray(),
            mergeMap((responses: ResolveContextsResponse[]) => {
              const entities: ContextItemsEntities = responses.reduce(
                (acc, resp) => ({ ...acc, ...resp.entities }),
                {},
              );

              const contextsResponse = { ...responses[responses.length - 1], entities };
              const results = { ...prevResults, ...entities };
              const doneAction = resolveContextsDone(targets, results, contextsResponse, projKey, envKey);

              return of(doneAction);
            }),
            catchError((error) => of(resolveContextsFailed(error, targets, projKey, envKey))),
          );
        }),
      ),
    ),
  );
}

export function clearContextResolution(action$: Observable<ContextResolutionAction>) {
  return action$.pipe(
    ofType<ContextResolutionAction, 'contexts/RESOLVE_CONTEXTS'>('contexts/RESOLVE_CONTEXTS'),
    filter((action) => action.targets.isEmpty()),
    map((action) => clearContextsResolutionResults(action.projKey, action.envKey)),
  );
}

registry.addEpics([loadContextTargetBatch, fetchContextOptions, resolveContextsBatch, clearContextResolution]);
