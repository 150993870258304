import { isNumber } from '@gonfalon/es6-utils';
import { differenceInDays, Duration, intervalToDuration, parseISO } from 'date-fns';

import { getCampaignEndDate, isCampaignActive } from '../../campaigns';
import { ModernSubscription } from '../../types';

import { getEnterpriseCampaign } from './getEnterpriseCampaign';

export function getTrialDaysRemaining(modernSubscription: ModernSubscription) {
  const { trialEndDate } = modernSubscription;
  const enterpriseCampaign = getEnterpriseCampaign(modernSubscription);

  let duration: Duration | undefined;
  const start = new Date();
  let end = trialEndDate ? parseISO(trialEndDate) : undefined;

  if (enterpriseCampaign && isCampaignActive(enterpriseCampaign)) {
    end = getCampaignEndDate(enterpriseCampaign);
    duration = intervalToDuration({ end, start });
  } else if (end) {
    duration = intervalToDuration({ end, start });
  }

  let totalDays;
  if (end) {
    totalDays = differenceInDays(end, start);
  } else {
    totalDays = duration?.days;
  }

  if (!duration || !end || (end && end <= start)) {
    return 0;
  }
  if (isNumber(duration.hours) && duration.hours > 1) {
    return (totalDays || 0) + 1;
  }

  return totalDays || 1;
}
