import { createTrackerForCategory } from '@gonfalon/analytics';
import { isEmpty } from '@gonfalon/es6-utils';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, Record } from 'immutable';
import qs from 'qs';

import { AccessChecks, allowDecision, createAccessDecision } from './accessUtils';
import { Member } from './accountUtils';
import { CreateFunctionInput, ImmutableMap } from './immutableUtils';
import { Link } from './linkUtils';
import { isNotEmpty, validateRecord } from './validationUtils';

export type PayloadFilterRuleConditionProps = {
  kind?: 'string-match' | 'string-one-of' | 'superset';
  property?: 'flag-key' | 'flag-tags';
  regex?: string;
  values?: List<string>;
};

export class PayloadFilterRuleCondition extends Record<PayloadFilterRuleConditionProps>({
  kind: undefined,
  property: undefined,
  regex: undefined,
  values: undefined,
}) {
  getErrorMessage() {
    if (!this.kind) {
      return 'Select how flags will be matched for this rule';
    }
    if (this.kind === 'string-match' && !this.regex) {
      return 'Enter regex to complete this rule condition';
    }
    if (this.kind === 'string-one-of' && (!this.values || this.values?.isEmpty())) {
      return 'Select flags to be included in this payload filter';
    }
    if (this.kind === 'superset' && (!this.values || this.values?.isEmpty())) {
      return 'Select tags to complete this rule condition';
    }
    return undefined;
  }
}

export function createPayloadFilterRuleCondition(props?: Partial<PayloadFilterRuleConditionProps>) {
  return props instanceof PayloadFilterRuleCondition ? props : new PayloadFilterRuleCondition(fromJS(props));
}

export type PayloadFilterRuleProps = {
  action: string;
  condition: PayloadFilterRuleCondition;
};

export class PayloadFilterRule extends Record<PayloadFilterRuleProps>({
  action: '',
  condition: new PayloadFilterRuleCondition(),
}) {
  getErrorMessage() {
    if (!this.action) {
      return 'Select whether flags are included or excluded for this rule';
    }
    return this.condition.getErrorMessage();
  }
}

export function createPayloadFilterRule(props?: Partial<PayloadFilterRuleProps>) {
  let payloadFilterRule = props instanceof PayloadFilterRule ? props : new PayloadFilterRule(fromJS(props));
  payloadFilterRule = payloadFilterRule.withMutations((rule) =>
    rule.update('condition', createPayloadFilterRuleCondition),
  );
  return payloadFilterRule;
}

export type PayloadFilterValueType = string | boolean | List<PayloadFilterRule>;

export type PayloadFilterProps = {
  _access?: AccessChecks;
  _links: ImmutableMap<{
    self: Link;
    parent: Link;
  }>;
  name: string;
  key: string;
  description: string;
  etag: string;
  createdAt: string;
  updatedAt: string;
  enabled: boolean;
  archived: boolean;
  rules: List<PayloadFilterRule>;
};

export class PayloadFilter extends Record<PayloadFilterProps>({
  _access: undefined,
  _links: Map(),
  name: '',
  key: '',
  description: '',
  etag: '',
  createdAt: '',
  updatedAt: '',
  enabled: true,
  archived: false,
  rules: List(),
}) {
  validate() {
    return validateRecord(this, isNotEmpty('name'), isNotEmpty('key'));
  }
  areRulesValid() {
    return !this.rules?.some((rule) => !!rule.getErrorMessage());
  }
  checkAccess({ profile }: { profile: Member }) {
    const access = this._access;

    if (profile.isReader()) {
      return () => createAccessDecision({ isAllowed: false, appliedRoleName: 'Reader' });
    }

    if (profile.isWriter()) {
      return () => createAccessDecision({ isAllowed: false, appliedRoleName: 'Writer' });
    }

    if (profile.isAdminOrOwner() || !access || !access.get('denied')) {
      return allowDecision;
    }

    return (action?: string) => {
      const deniedAction = access.get('denied').find((v) => v.get('action') === action);
      if (deniedAction) {
        const reason = deniedAction.get('reason');
        const roleName = reason && reason.get('role_name');
        return createAccessDecision({
          isAllowed: false,
          appliedRoleName: roleName,
        });
      }
      return createAccessDecision({ isAllowed: true });
    };
  }
}

export function createPayloadFilter(props?: CreateFunctionInput<PayloadFilter>) {
  if (!props) {
    return new PayloadFilter();
  }
  let payloadFilter = props instanceof PayloadFilter ? props : new PayloadFilter(fromJS(props));
  payloadFilter = payloadFilter.withMutations((pf) => {
    pf.update('rules', (fr) => fr && fr.map(createPayloadFilterRule));
  });
  return payloadFilter;
}

// Filtering payload filters

//TODO: Replace with values from feature flag if using for controlling filter values
const pageSize = 25;

type PayloadFilterFiltersProps = {
  query: string;
  archived: string;
  limit: number;
  offset: number;
};
export class PayloadFilterFilters extends Record<PayloadFilterFiltersProps>({
  query: '',
  archived: '',
  limit: -1,
  offset: 0,
}) {
  toFrontendQueryString() {
    const q = {} as PayloadFilterFiltersProps;
    //TODO: Replace with values from feature flag if using for controlling filter values
    if (!isEmpty(this.query)) {
      q.query = this.query;
    }
    if (!isEmpty(this.archived)) {
      q.archived = this.archived;
    }
    if (this.limit && this.limit > 0 && (this.offset || this.limit !== pageSize)) {
      q.limit = this.limit;
    }
    if (this.offset) {
      q.offset = this.offset;
    }
    return q;
  }
  toQueryString() {
    return qs.stringify(this.toFrontendQueryString(), { indices: false });
  }
  toBackendQueryString() {
    const q: Partial<PayloadFilterFiltersProps> & { filter?: string } = {};
    const filterParts = [];

    if (this.query && this.query !== '') {
      filterParts.push(`query:${this.query}`);
    }

    if (this.archived && this.archived !== '') {
      filterParts.push(`archived:${this.archived}`);
    }

    if (filterParts.length) {
      q.filter = filterParts.join(',');
    }

    if (this.limit && this.limit > 0) {
      q.limit = this.limit;
    }
    if (this.offset) {
      q.offset = this.offset;
    }
    return qs.stringify(q, { indices: false, addQueryPrefix: true });
  }
  isQueryFilterEmpty() {
    return isEmpty(this.query);
  }
  withQuery(query: string) {
    return this.set('offset', 0).set('query', query);
  }
  withArchived(archived: string) {
    return this.set('offset', 0).set('archived', archived);
  }
  withPaginationReset() {
    return this.set('offset', 0);
  }
}

export function createPayloadFilterFilters(props?: Partial<PayloadFilterFiltersProps>) {
  return props instanceof PayloadFilterFilters ? props : new PayloadFilterFilters(fromJS(props));
}

export function createPayloadFilterFiltersFromQuery(queryMap: Partial<PayloadFilterFiltersProps>) {
  const limit = queryMap ? queryMap.limit : '';
  const offset = queryMap ? queryMap.offset : '';
  const query = queryMap ? queryMap.query : '';
  const archived = queryMap ? queryMap.archived : '';

  const props = {
    limit: limit || pageSize,
    offset: offset || 0,
    query: query || '',
    archived: archived || '',
  };

  return createPayloadFilterFilters(props);
}

export function createPayloadFilterFiltersFromBackendQueryString(queryString: string) {
  const backendParams = qs.parse(queryString, { ignoreQueryPrefix: true });
  const props = {
    query: backendParams.query,
    archived: backendParams.archived,
    limit: parseInt(backendParams.limit, 10),
    offset: parseInt(backendParams.offset, 10),
  };
  return createPayloadFilterFilters(props);
}

const trackPayloadFiltersEvent = createTrackerForCategory('PayloadFilters');

export const trackSubmitCreatePayloadFilterForm = () =>
  trackPayloadFiltersEvent('Create Payload Filter Form Submitted');
