import nullthrows from 'nullthrows';

import { getSeatAvailability as getLegacySubscriptionSeatAvailability } from '../internal/legacySubscription';
import { getSeatAvailability as getModernSubscriptionSeatAvailability } from '../internal/modernSubscription';
import { LimitPlan, Subscription, SubscriptionUsage } from '../types';

import { isModernSubscription } from './isModernSubscription';

export function getSeatAvailability(
  subscription: Subscription,
  numberOfSeatsToAdd: number,
  subscriptionUsage?: SubscriptionUsage,
  seatsLimitPlan?: LimitPlan,
) {
  if (isModernSubscription(subscription)) {
    return getModernSubscriptionSeatAvailability(
      subscription,
      nullthrows(subscriptionUsage, 'subscriptionUsage is required for modern subscriptions'),
      numberOfSeatsToAdd,
      nullthrows(seatsLimitPlan, 'seatsLimitPlan is required for modern subscriptions'),
    );
  }

  return getLegacySubscriptionSeatAvailability(subscription, numberOfSeatsToAdd);
}
