import { Campaigns } from '../../campaigns';
import { CampaignType } from '../../campaigns/types';
import { ModernSubscription } from '../../types';

export function getEnterpriseCampaign(modernSubscription: ModernSubscription) {
  const _campaigns = modernSubscription.campaigns as undefined | { [key: string]: CampaignType };
  // the campaign name gets transformed to lowercase
  if (_campaigns) {
    return _campaigns[Campaigns.ENTERPRISE];
  }

  return;
}
