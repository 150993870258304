import { ClauseValue, ClauseWithKey } from '@gonfalon/clauses';
import { isEqual } from '@gonfalon/es6-utils';
import nullthrows from 'nullthrows';

import { Clause as ImmutableClause, createClause } from 'utils/clauseUtils';
import { idOrKey } from 'utils/flagUtils';
import { toJS } from 'utils/immutableUtils';
import { InstructionCategory, InstructionsType } from 'utils/instructions/shared/types';

import {
  getExistingAddRuleInstruction,
  getExistingAddRuleWithMeasuredRolloutInstruction,
  makeRuleKey,
  makeUpdateRuleKey,
} from '../rules/helpers';

import {
  AddClausesSemanticInstruction,
  AddValuesToClauseSemanticInstruction,
  ClauseInstructionKind,
  RemoveClausesSemanticInstruction,
  RemoveValuesFromClauseSemanticInstruction,
  UpdateClauseSemanticInstruction,
} from './types';

export function makeAddClausesInstruction(ruleId: string, clauses: ClauseWithKey[]): AddClausesSemanticInstruction {
  return {
    kind: ClauseInstructionKind.ADD_CLAUSES,
    ruleId,
    clauses: toJS(clauses).map((c) => toJS(c)),
  };
}

export function makeRemoveClausesInstruction(ruleId: string, clauseIds: string[]): RemoveClausesSemanticInstruction {
  return {
    kind: ClauseInstructionKind.REMOVE_CLAUSES,
    ruleId,
    clauseIds,
  };
}

export function makeUpdateClauseInstruction(
  ruleId: string,
  clauseId: string,
  clause: ImmutableClause | null,
): UpdateClauseSemanticInstruction {
  return {
    kind: ClauseInstructionKind.UPDATE_CLAUSE,
    ruleId,
    clauseId,
    clause,
  };
}

export function makeAddValuesToClauseInstruction(
  ruleId: string,
  clauseId: string,
  values: ClauseValue[],
): AddValuesToClauseSemanticInstruction {
  return {
    ruleId,
    clauseId,
    values,
    kind: ClauseInstructionKind.ADD_VALUES_TO_CLAUSE,
  };
}
export function makeRemoveValuesFromClauseInstruction(
  ruleId: string,
  clauseId: string,
  values: ClauseValue[],
): RemoveValuesFromClauseSemanticInstruction {
  return {
    ruleId,
    clauseId,
    values,
    kind: ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE,
  };
}

export function combineUpdateClauseInstruction(
  newInstruction: UpdateClauseSemanticInstruction,
  pendingSemanticPatch: InstructionsType,
): InstructionsType {
  const ruleId = newInstruction.ruleId;
  let existingAddRule =
    getExistingAddRuleInstruction(ruleId, pendingSemanticPatch) ??
    getExistingAddRuleWithMeasuredRolloutInstruction(ruleId, pendingSemanticPatch);
  if (existingAddRule && existingAddRule.clauses) {
    // Updating a clause on a rule that's pending being added.
    existingAddRule = {
      ...existingAddRule,
      clauses: existingAddRule.clauses.map(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (clause) => (clause?._key === newInstruction.clauseId ? newInstruction.clause : clause)!,
      ),
    };
    return pendingSemanticPatch.set(makeUpdateRuleKey(ruleId, existingAddRule.kind), existingAddRule);
  }
  const existingAddClause = getExistingAddClausesInstruction(ruleId, pendingSemanticPatch);
  if (existingAddClause) {
    // Updating a clause that's pending being added on an existing rule.
    const updatedExistingAddClause = {
      ...existingAddClause,
      clauses: existingAddClause.clauses.map((clause) =>
        clause._key === newInstruction.clauseId ? nullthrows(newInstruction.clause).toJS() : clause,
      ),
    };

    if (isEqual(updatedExistingAddClause, existingAddClause)) {
      // if there have been no changes, an existing clause is being updated
      return pendingSemanticPatch.set(makeUpdateClauseKey(ruleId, newInstruction.clauseId), newInstruction);
    }
    return pendingSemanticPatch.set(
      makeUpdateRuleKey(ruleId, ClauseInstructionKind.ADD_CLAUSES),
      updatedExistingAddClause,
    );
  }

  // If there are any pending clause value instructions, combine them with this and
  // make sure to remove the clause value instructions.
  let updateInstruction = newInstruction;
  const addKey = makeClauseValuesKey(ruleId, newInstruction.clauseId, ClauseInstructionKind.ADD_VALUES_TO_CLAUSE);
  const add = pendingSemanticPatch.get(addKey) as AddValuesToClauseSemanticInstruction | undefined;
  if (add && updateInstruction.clause) {
    updateInstruction = { ...updateInstruction, clause: applyValuesInstructionToClause(add, updateInstruction.clause) };
  }
  const removeKey = makeClauseValuesKey(
    ruleId,
    newInstruction.clauseId,
    ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE,
  );
  const remove = pendingSemanticPatch.get(removeKey) as RemoveValuesFromClauseSemanticInstruction | undefined;
  if (remove && updateInstruction.clause) {
    updateInstruction = {
      ...updateInstruction,
      clause: applyValuesInstructionToClause(remove, updateInstruction.clause),
    };
  }

  // Updating an existing clause on an existing rule.
  return pendingSemanticPatch
    .delete(addKey)
    .delete(removeKey)
    .set(makeUpdateClauseKey(ruleId, newInstruction.clauseId), updateInstruction);
}

// This function is the extreme of dealing with these nested overlapping instructions. We're adding
// or removing values to or from a clause, which means that this could need to be merged with an
// existing add rule instruction, add clause instruction, update clause instruction, or previous
// add/remove clause instructions.
// TODO it would be nice if this didn't have to be so repetitive- maybe some of the code here could
// be shared with the code for merging in an "updateClause" instruction for example.
export function combineClauseValuesInstruction(
  newInstruction: AddValuesToClauseSemanticInstruction | RemoveValuesFromClauseSemanticInstruction,
  pendingSemanticPatch: InstructionsType,
): InstructionsType {
  // Check if this is on a new rule we're adding. If so, just update the pending add rule instruction.
  const ruleId = newInstruction.ruleId;
  let existingAddRule =
    getExistingAddRuleInstruction(ruleId, pendingSemanticPatch) ??
    getExistingAddRuleWithMeasuredRolloutInstruction(ruleId, pendingSemanticPatch);
  if (existingAddRule && existingAddRule.clauses) {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    // Updating a clause on a rule that's pending being added.
    existingAddRule = {
      ...existingAddRule,
      clauses: existingAddRule.clauses.map(
        (clause) =>
          (clause!._key === newInstruction.clauseId
            ? applyValuesInstructionToClause(newInstruction, clause!)
            : clause)!,
      ),
    };
    return pendingSemanticPatch.set(makeUpdateRuleKey(ruleId, existingAddRule.kind), existingAddRule);
  }

  // Check if this is on a new clause we're adding. If so, just update the pending add clauses instruction.
  const existingAddClause = getExistingAddClausesInstruction(ruleId, pendingSemanticPatch);
  if (existingAddClause && existingAddClause.clauses.find((clause) => clause._key === newInstruction.clauseId)) {
    // Updating a clause that's pending being added on an existing rule.
    const updatedExistingAddClause = {
      ...existingAddClause,
      clauses: existingAddClause.clauses.map((clause) =>
        clause._key === newInstruction.clauseId
          ? toJS(
              applyValuesInstructionToClause(
                newInstruction,
                createClause(clause as Parameters<typeof createClause>[0]),
              ),
            )
          : clause,
      ),
    };

    return pendingSemanticPatch.set(
      makeUpdateRuleKey(ruleId, ClauseInstructionKind.ADD_CLAUSES),
      updatedExistingAddClause,
    );
  }

  // Check if this is on a clause we're already updating. If so, just update the pending update clause instruction.
  let existingUpdateClauseInstruction = getExistingUpdateClauseInstruction(
    ruleId,
    newInstruction.clauseId,
    pendingSemanticPatch,
  );
  if (existingUpdateClauseInstruction && existingUpdateClauseInstruction.clause) {
    existingUpdateClauseInstruction = {
      ...existingUpdateClauseInstruction,
      clause: applyValuesInstructionToClause(newInstruction, existingUpdateClauseInstruction.clause),
    };

    return pendingSemanticPatch.set(
      makeUpdateClauseKey(ruleId, newInstruction.clauseId),
      existingUpdateClauseInstruction,
    );
  }

  // Check if there are existing add/remove clause values instructions
  let add = getExistingAddValuesToClauseInstruction(ruleId, newInstruction.clauseId, pendingSemanticPatch);
  let remove = getExistingRemoveValuesFromClauseInstruction(ruleId, newInstruction.clauseId, pendingSemanticPatch);
  if (newInstruction.kind === ClauseInstructionKind.ADD_VALUES_TO_CLAUSE) {
    const valuesToAdd = newInstruction.values.filter((v) => !remove || !remove.values.includes(v));
    if (!add) {
      add = { ...newInstruction, values: valuesToAdd };
    } else {
      add = { ...add, values: add.values.concat(valuesToAdd) };
    }
    if (remove) {
      remove = { ...remove, values: remove.values.filter((v) => !newInstruction.values.includes(v)) };
    }
  } else if (newInstruction.kind === ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE) {
    const valuesToRemove = newInstruction.values.filter((v) => !add || !add.values.includes(v));
    if (!remove) {
      remove = { ...newInstruction, values: valuesToRemove };
    } else {
      remove = { ...remove, values: remove.values.concat(valuesToRemove) };
    }
    if (add) {
      add = { ...add, values: add.values.filter((v) => !newInstruction.values.includes(v)) };
    }
  }

  let result = pendingSemanticPatch;

  // clear old instructions
  const addKey = makeClauseValuesKey(ruleId, newInstruction.clauseId, ClauseInstructionKind.ADD_VALUES_TO_CLAUSE);
  const removeKey = makeClauseValuesKey(
    ruleId,
    newInstruction.clauseId,
    ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE,
  );
  result = result.delete(addKey).delete(removeKey);

  if (add && add.values.length > 0) {
    result = result.set(addKey, add);
  }
  if (remove && remove.values.length > 0) {
    result = result.set(removeKey, remove);
  }
  return result;
}

function applyValuesInstructionToClause(
  instruction: AddValuesToClauseSemanticInstruction | RemoveValuesFromClauseSemanticInstruction,
  clause: ImmutableClause,
): ImmutableClause {
  switch (instruction.kind) {
    case ClauseInstructionKind.ADD_VALUES_TO_CLAUSE:
      return clause.update('values', (vs) => vs.concat(instruction.values).toSet().toList());
    case ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE:
      return clause.update('values', (vs) => vs.filter((v) => !instruction.values.includes(v)));
    default:
      return clause;
  }
}

export function combineAddClausesInstruction(
  newInstruction: AddClausesSemanticInstruction,
  pendingSemanticPatch: InstructionsType,
): InstructionsType {
  const ruleId = newInstruction.ruleId;
  let existingAddRule =
    getExistingAddRuleInstruction(ruleId, pendingSemanticPatch) ??
    getExistingAddRuleWithMeasuredRolloutInstruction(ruleId, pendingSemanticPatch);
  if (existingAddRule) {
    // Adding a clause to a rule that's pending being added
    existingAddRule = {
      ...existingAddRule,
      clauses: existingAddRule.clauses.concat(
        newInstruction.clauses.map((clause) => createClause(clause as Parameters<typeof createClause>[0])),
      ),
    };
    return pendingSemanticPatch.set(makeUpdateRuleKey(ruleId, existingAddRule.kind), existingAddRule);
  }
  let existingAddClause = getExistingAddClausesInstruction(ruleId, pendingSemanticPatch);
  if (existingAddClause) {
    // Adding a clause to an existing rule where there are already other clauses
    // pending being added so we need to combine the add clause instructions.
    existingAddClause = { ...existingAddClause, clauses: existingAddClause.clauses.concat(newInstruction.clauses) };
    return pendingSemanticPatch.set(makeUpdateRuleKey(ruleId, ClauseInstructionKind.ADD_CLAUSES), existingAddClause);
  }
  // Adding a clause to an existing rule, and this is the only add clause instruction.
  return pendingSemanticPatch.set(makeUpdateRuleKey(ruleId, ClauseInstructionKind.ADD_CLAUSES), newInstruction);
}

export function combineRemoveClausesInstruction(
  newInstruction: RemoveClausesSemanticInstruction,
  pendingSemanticPatch: InstructionsType,
  replaceId?: string,
): InstructionsType {
  const ruleId = newInstruction.ruleId;
  let existingAddRule =
    getExistingAddRuleInstruction(ruleId, pendingSemanticPatch) ??
    getExistingAddRuleWithMeasuredRolloutInstruction(ruleId, pendingSemanticPatch);
  if (existingAddRule) {
    // Removing a clause from a rule that's pending being added.
    existingAddRule = {
      ...existingAddRule,
      clauses: existingAddRule.clauses.filter((clause) => {
        const id = clause !== null ? idOrKey(clause) : undefined;
        return id !== undefined && !newInstruction.clauseIds.includes(id);
      }),
    };
    return pendingSemanticPatch.set(makeUpdateRuleKey(ruleId, existingAddRule.kind), existingAddRule);
  }

  let existingAddClause = getExistingAddClausesInstruction(ruleId, pendingSemanticPatch);
  let existingRemoveClause = pendingSemanticPatch.get(
    makeRuleKey(makeRemoveClausesInstruction(ruleId, [])),
  ) as RemoveClausesSemanticInstruction;
  let newRemoveClause = newInstruction;

  if (existingAddClause) {
    // Removing some clauses when there is a pending add clauses instruction.
    // Some clauses to remove may be existing, so we need to keep those in the remove instruction.
    newRemoveClause = {
      ...newRemoveClause,
      clauseIds: newRemoveClause.clauseIds.filter(
        (clauseId) => !nullthrows(existingAddClause).clauses.find((clause) => idOrKey(clause) === clauseId),
      ),
    };
    // Other clauses may be pending to be added, in which case we need to remove them from the pending
    // add clauses instruction.
    existingAddClause = {
      ...existingAddClause,
      clauses: existingAddClause.clauses.filter((clause) => {
        const id = clause !== null ? idOrKey(clause) : undefined;
        return id !== undefined && !newInstruction.clauseIds.includes(id);
      }),
    };
  }
  // Removing an existing clause from and existing rule.
  if (existingRemoveClause) {
    // We're already removing another clause from this rule, so combine the instructions.
    // Replace clause id in case of edits
    existingRemoveClause = {
      ...existingRemoveClause,
      clauseIds: existingRemoveClause.clauseIds.concat(newRemoveClause.clauseIds).filter((c) => c !== replaceId),
    };
  } else {
    existingRemoveClause = newRemoveClause;
  }

  // Now that everything is combined, set the updated old instructions in the pending patch.
  let result = pendingSemanticPatch;
  if (existingAddClause && existingAddClause.clauses.length > 0) {
    result = result.set(makeRuleKey(existingAddClause), existingAddClause);
  } else {
    result = result.delete(makeRuleKey(makeAddClausesInstruction(ruleId, [])));
  }
  if (existingRemoveClause.clauseIds.length > 0) {
    result = result.set(makeRuleKey(existingRemoveClause), existingRemoveClause);
  } else {
    result = result.delete(makeRuleKey(existingRemoveClause));
  }
  return result;
}

export function makeUpdateClauseKey(ruleId: string, clauseId: string): string {
  return [InstructionCategory.RULES, ruleId, clauseId, ClauseInstructionKind.UPDATE_CLAUSE].join('|');
}

export function makeClauseValuesKey(
  ruleId: string,
  clauseId: string,
  kind: ClauseInstructionKind.ADD_VALUES_TO_CLAUSE | ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE,
): string {
  return [InstructionCategory.RULES, ruleId, clauseId, kind].join('|');
}

function getExistingAddClausesInstruction(
  ruleId: string,
  pendingSemanticPatch: InstructionsType,
): AddClausesSemanticInstruction | undefined {
  const key = makeUpdateRuleKey(ruleId, ClauseInstructionKind.ADD_CLAUSES);
  return pendingSemanticPatch.get(key) as AddClausesSemanticInstruction;
}

function getExistingUpdateClauseInstruction(
  ruleId: string,
  clauseId: string,
  pendingSemanticPatch: InstructionsType,
): UpdateClauseSemanticInstruction | undefined {
  const key = makeUpdateClauseKey(ruleId, clauseId);
  return pendingSemanticPatch.get(key) as UpdateClauseSemanticInstruction;
}

function getExistingAddValuesToClauseInstruction(
  ruleId: string,
  clauseId: string,
  pendingSemanticPatch: InstructionsType,
): AddValuesToClauseSemanticInstruction | undefined {
  const key = makeClauseValuesKey(ruleId, clauseId, ClauseInstructionKind.ADD_VALUES_TO_CLAUSE);
  return pendingSemanticPatch.get(key) as AddValuesToClauseSemanticInstruction;
}

function getExistingRemoveValuesFromClauseInstruction(
  ruleId: string,
  clauseId: string,
  pendingSemanticPatch: InstructionsType,
): RemoveValuesFromClauseSemanticInstruction | undefined {
  const key = makeClauseValuesKey(ruleId, clauseId, ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE);
  return pendingSemanticPatch.get(key) as RemoveValuesFromClauseSemanticInstruction;
}
