import { SuggestInviteMembersModalProps } from 'components/inviteMembers/SuggestInviteMembersModal';
import { GenerateActionType } from 'utils/reduxUtils';

export const openSuggestInviteMembersModal = (props: SuggestInviteMembersModalProps) =>
  ({
    type: 'suggestInviteMembersModal/OPEN_MODAL',
    props,
  }) as const;

export const closeSuggestInviteMembersModal = () =>
  ({
    type: 'suggestInviteMembersModal/CLOSE_MODAL',
  }) as const;

const SuggestInviteMembersModalActionCreators = {
  openSuggestInviteMembersModal,
  closeSuggestInviteMembersModal,
};

export type SuggestInviteMembersModalAction = GenerateActionType<typeof SuggestInviteMembersModalActionCreators>;
