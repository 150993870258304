import { isAccessWarningEnabled } from '@gonfalon/dogfood-flags';
import { Alert } from 'launchpad';

type Props = {
  willRemoveEditingAbility?: boolean;
};

/* eslint-disable import/no-default-export */
export default function TagPermissionAlert({ willRemoveEditingAbility }: Props) {
  if (isAccessWarningEnabled() && willRemoveEditingAbility) {
    return (
      <Alert kind="error" className="u-mt-m">
        You've made changes to tags that will limit your ability to edit these settings
      </Alert>
    );
  } else {
    return null;
  }
}
