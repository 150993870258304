import { type GetFeatureFlagsQueryParams } from '@gonfalon/openapi';
import { z } from 'zod';

// This is the shape of our flag filters in the UI.
// They must be converted to the API format before being sent to the API.
export type UIFlagFilter = {
  applicationEvaluated?: string;
  codeReferences?: boolean;
  contextKindsEvaluated?: string[];
  contextKindTargeted?: string;
  created?: '1-week-ago' | '1-month-ago' | '2-months-ago' | '3-months-ago' | '6-months-ago' | '1-year-ago';
  evaluated?: 'last-60-minutes' | 'last-24-hours' | 'last-7-days' | 'last-14-days' | 'last-30-days' | 'last-60-days';
  excludeSettings?: Array<'prerequisites' | 'on' | 'targets' | 'rules' | 'fallthrough' | 'offVariation'>;
  filterEnv?: string;
  followerId?: string;
  hasDataExport?: boolean;
  hasExperiment?: boolean;
  maintainerId?: string;
  maintainerTeamKey?: string;
  query?: string;
  releasePipeline?: string;
  sdkAvailability?: 'anyClient' | 'server' | 'mobile' | 'client';
  segmentTargeted?: string;
  state?: FlagState;
  status?: 'new' | 'inactive' | 'active' | 'launched';
  tags?: string[];
  targeting?: 'on' | 'off';
  type?: 'permanent' | 'temporary';
};

export type UIFlagListView = Omit<GetFeatureFlagsQueryParams, 'env' | 'expand' | 'filter'> & {
  filter?: UIFlagFilter;
};

export const creationDateSchema = z.enum([
  '1-week-ago',
  '1-month-ago',
  '2-months-ago',
  '3-months-ago',
  '6-months-ago',
  '1-year-ago',
]);
export type CreationDate = z.infer<typeof creationDateSchema>;

export const evaluationDateSchema = z.enum([
  'last-60-minutes',
  'last-24-hours',
  'last-7-days',
  'last-14-days',
  'last-30-days',
  'last-60-days',
]);
export type EvaluationDate = z.infer<typeof evaluationDateSchema>;

export const sdkAvailabilitySchema = z.enum(['anyClient', 'client', 'mobile', 'server']);
export type SDKAvailability = z.infer<typeof sdkAvailabilitySchema>;

export const baseLifecycleStatesSchema = z.enum(['live', 'archived', 'deprecated']);
export type BaseLifecycleStates = z.infer<typeof baseLifecycleStatesSchema>;
export const staleStatesSchema = z.enum(['readyForCodeRemoval', 'readyToArchive']);
export type StaleLifecycleStates = z.infer<typeof staleStatesSchema>;

export const stateSchema = z.union([baseLifecycleStatesSchema, staleStatesSchema]);
export type FlagState = z.infer<typeof stateSchema>;

export const typeSchema = z.enum(['permanent', 'temporary']);
export type FlagType = z.infer<typeof typeSchema>;

export const targetingSchema = z.enum(['on', 'off']);
export type Targeting = z.infer<typeof targetingSchema>;

export const sortOrderingSchema = z.enum([
  'creationDate',
  '-creationDate',
  'key',
  '-key',
  'maintainerId',
  '-maintainerId',
  'name',
  '-name',
  'tags',
  '-tags',
  'targetingModifiedDate',
  '-targetingModifiedDate',
  'type',
  '-type',
]);
export type SortOrdering = z.infer<typeof sortOrderingSchema>;
