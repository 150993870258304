import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useDispatch } from 'react-redux';
import { getMemberListPageSize } from '@gonfalon/dogfood-flags';

import { fetchMembersIfNeeded as fetchMembersIfNeededAction } from 'actions/members';
import { membersByKeySelector } from 'reducers/members';
import { createMemberFilters } from 'utils/memberUtils';
import { ready } from 'utils/reduxUtils';

import { useSelector } from './useSelector';

type useMembersProps = {
  limit?: number;
  query?: string;
  enabled?: boolean;
};

export const useMembers = ({ limit, query, enabled = true }: useMembersProps = { limit: getMemberListPageSize() }) => {
  const paginate = limit !== undefined || query !== undefined;
  const filter = createMemberFilters({ limit, query });
  const key = paginate ? filter?.toFrontendMembersQueryString() : 'all';
  const members = useSelector(membersByKeySelector(key));
  const isReady = enabled ? ready(members) : true; // if enabled is false this is true
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isReady && enabled) {
      dispatch(fetchMembersIfNeededAction(paginate ? filter : undefined));
    }
  }, []);

  useEffect(() => {
    if (enabled) {
      dispatch(fetchMembersIfNeededAction(paginate ? filter : undefined));
    }
  }, [query]);

  return {
    isReady,
    members: members.get('entities'),
  } as const;
};
