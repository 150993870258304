import { To, useNavigate } from 'react-router-dom';
import { defer } from '@gonfalon/es6-utils';

import { useDispatch } from 'hooks/useDispatch';
import { useSelector } from 'hooks/useSelector';
import { GetState, GlobalDispatch } from 'reducers';
import { metricPaginationSelector } from 'reducers/metrics';
import { currentProjectKeySelector } from 'reducers/projects';
import {
  createMetric as createMetricAPI,
  deleteMetric as deleteMetricAPI,
  getAllMetrics,
  getMetric,
  updateMetric as updateMetricAPI,
} from 'sources/MetricsAPI';
import { FormRecord } from 'utils/formUtils';
import { Goal, GoalProps, URLMatcher, URLMatcherProps } from 'utils/goalUtils';
import { PaginationType } from 'utils/paginationUtils';
import { GenerateActionType } from 'utils/reduxUtils';

const requestMetrics = () => ({ type: 'metrics/REQUEST_METRICS' }) as const;
const receiveMetrics = (response: unknown) => ({ type: 'metrics/RECEIVE_METRICS', response }) as const;
const requestMetricsFailed = (error: unknown) => ({ type: 'metrics/REQUEST_METRICS_FAILED', error }) as const;

export function fetchMetrics(nextPageUrl?: string, expand?: string[]) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    dispatch(requestMetrics());
    try {
      const projKey = currentProjectKeySelector(getState());
      const response = await getAllMetrics(projKey, nextPageUrl, expand);
      return dispatch(receiveMetrics(response));
    } catch (error) {
      dispatch(requestMetricsFailed(error));
    }
  };
}

export function fetchPaginatedMetrics(nextPage?: boolean, expand?: string[]) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    const projKey = currentProjectKeySelector(getState());
    const pagination: PaginationType = metricPaginationSelector(getState());
    const isFetching = pagination.get('isFetching');
    const error = pagination.get('error');
    const nextPageUrl = pagination.get('nextPageUrl') || `/api/v2/metrics/${projKey}`;
    const pageCount = pagination.get('pageCount') || 0;
    if (isFetching || error || (pageCount > 0 && !nextPage)) {
      return;
    }

    return dispatch(fetchMetrics(nextPageUrl, expand));
  };
}

const requestMetric = (metricKey: string) => ({ type: 'metrics/REQUEST_METRIC', metricKey }) as const;
const receiveMetric = (metricKey: string, metric: Goal) =>
  ({ type: 'metrics/RECEIVE_METRIC', metricKey, metric }) as const;
const requestMetricFailed = (metricKey: string, error: unknown) =>
  ({ type: 'metrics/REQUEST_METRIC_FAILED', metricKey, error }) as const;

export function fetchMetric(metricKey: string, expand: string[]) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    dispatch(requestMetric(metricKey));
    try {
      const projKey = currentProjectKeySelector(getState());
      const metric = await getMetric(projKey, metricKey, expand);
      return dispatch(receiveMetric(metricKey, metric));
    } catch (error) {
      dispatch(requestMetricFailed(metricKey, error));
    }
  };
}

const createMetricStart = () => ({ type: 'metrics/CREATE_METRIC' }) as const;
const createMetricDone = (metric: unknown, isExperimentsDashboard: boolean, pathToCreateExperiment: string) =>
  ({
    type: 'metrics/CREATE_METRIC_DONE',
    metric,
    isExperimentsDashboard,
    pathToCreateExperiment,
  }) as const;
const createMetricFailed = (metric: FormRecord<Goal>, error: unknown) =>
  ({ type: 'metrics/CREATE_METRIC_FAILED', metric, error }) as const;

export function useCreateMetric() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const projKey = useSelector(currentProjectKeySelector);

  return async (
    metric: FormRecord<Goal>,
    options: { pathOnDone: string; pathToCreateExperiment: string; isExperimentsDashboard: boolean },
  ) => {
    dispatch(createMetricStart());
    try {
      const created = await createMetricAPI(projKey, metric);

      if (options.pathOnDone) {
        navigate(options.pathOnDone);
      }
      dispatch(createMetricDone(created, options.isExperimentsDashboard, options.pathToCreateExperiment));
      await dispatch(fetchMetrics());
    } catch (error) {
      dispatch(createMetricFailed(metric, error));
    }
  };
}

const updateMetricStart = () => ({ type: 'metrics/UPDATE_METRIC' }) as const;
const updateMetricDone = (metric: Goal) => ({ type: 'metrics/UPDATE_METRIC_DONE', metric }) as const;
const updateMetricFailed = (metric: FormRecord<Goal>, error: unknown) =>
  ({ type: 'metrics/UPDATE_METRIC_FAILED', metric, error }) as const;

export function useUpdateMetric() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (original: FormRecord<Goal>, modified: FormRecord<Goal>, options: { pathOnDone: To }) => {
    dispatch(updateMetricStart());

    try {
      const persisted = await updateMetricAPI(original, modified);

      if (options.pathOnDone) {
        // defer so that the form is no longer dirty when navigation
        // occurs so we don't get the "unsaved changes" prompt
        defer(() => navigate(options.pathOnDone));
      }

      dispatch(updateMetricDone(persisted));
    } catch (error) {
      dispatch(updateMetricFailed(modified, error));
    }
  };
}

const deleteMetricStart = (metric: Goal) => ({ type: 'metrics/DELETE_METRIC', metric }) as const;
const deleteMetricDone = (metric: Goal) => ({ type: 'metrics/DELETE_METRIC_DONE', metric }) as const;
const deleteMetricFailed = (metric: Goal, error: unknown) =>
  ({ type: 'metrics/DELETE_METRIC_FAILED', metric, error }) as const;

export function useDeleteMetric() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (metric: Goal, options: { pathOnDone: string }) => {
    dispatch(deleteMetricStart(metric));

    try {
      await deleteMetricAPI(metric);

      if (options.pathOnDone) {
        navigate(options.pathOnDone);
      }

      dispatch(deleteMetricDone(metric));
    } catch (error) {
      dispatch(deleteMetricFailed(metric, error));
    }
  };
}

export const editMetric = (field: keyof GoalProps, value: string) =>
  ({
    type: 'metrics/EDIT_METRIC',
    field,
    value,
  }) as const;

export const addURLMatcher = () =>
  ({
    type: 'metrics/ADD_URL_MATCHER',
  }) as const;

export const removeURLMatcher = (matcher: URLMatcher) =>
  ({
    type: 'metrics/REMOVE_URL_MATCHER',
    matcher,
  }) as const;

export const editURLMatcher = (
  matcher: URLMatcher,
  field: keyof URLMatcherProps,
  value: URLMatcherProps[keyof URLMatcherProps],
) =>
  ({
    type: 'metrics/EDIT_URL_MATCHER',
    matcher: matcher.set(field, value),
    field,
  }) as const;

const MetricsActionCreators = {
  requestMetrics,
  receiveMetrics,
  requestMetricsFailed,
  requestMetric,
  receiveMetric,
  requestMetricFailed,
  createMetricStart,
  createMetricDone,
  createMetricFailed,
  updateMetricStart,
  updateMetricDone,
  updateMetricFailed,
  deleteMetricStart,
  deleteMetricDone,
  deleteMetricFailed,
  editMetric,
  addURLMatcher,
  removeURLMatcher,
  editURLMatcher,
};

export type MetricsAction = GenerateActionType<typeof MetricsActionCreators>;
