import { type EnvironmentAware } from '@gonfalon/context';

import { clearEnvironmentFilters as clearEnvironmentFilters_ } from './clearEnvironmentFilters';
import { clearProjectFilters as clearProjectFilters_ } from './clearProjectFilters';
import { UIFlagEnvironmentFilter } from './flagEnvironmentFilters';
import { UIFlagProjectFilter } from './flagProjectFilters';
import { hasActiveFilters } from './hasActiveFilters';
import { SortOrdering, UIFlagListView } from './types';
import { useCachedFlagListFilters } from './useCachedFlagListFilters';
import { useCachedFlagListSortOrdering } from './useCachedFlagListSortOrdering';
import { useFlagListEnvironmentFiltersSearchParams } from './useFlagListEnvironmentFiltersSearchParams';
import { useFlagListProjectFiltersSearchParams } from './useFlagListProjectFiltersSearchParams';
import { useFlagListSearchParams } from './useFlagListSearchParams';

export function useFlagFiltering() {
  const filterCache = useCachedFlagListFilters();
  const sortOrderCache = useCachedFlagListSortOrdering();
  const [flagListParams, setFlagSearchParams, createSearchParams] = useFlagListSearchParams();
  const [projectFilters] = useFlagListProjectFiltersSearchParams();
  const [environmentFilters] = useFlagListEnvironmentFiltersSearchParams();

  const _updateFilters = (view: UIFlagListView) => {
    filterCache.put(view.filter);
    sortOrderCache.put(view.sort);
    setFlagSearchParams({ ...flagListParams, ...view });
  };

  const _updateFiltersAndResetPagination = (view: UIFlagListView) =>
    _updateFilters({ ...flagListParams, ...view, offset: 0 });

  const _updateFiltersWithEnvironmentAndResetPagination = (view: EnvironmentAware<UIFlagListView>) =>
    _updateFiltersAndResetPagination(view);

  const updateOffset = (offset: number | undefined) => _updateFilters({ offset });

  const updateOrdering = (sort: SortOrdering) => _updateFiltersAndResetPagination({ sort });

  const updateTextSearch = (query: string | undefined) =>
    _updateFiltersAndResetPagination({ ...flagListParams, filter: { ...flagListParams.filter, query } });

  const clearTextSearch = () => updateTextSearch(undefined);

  const updateEnvironmentFilters = (nextFilter: EnvironmentAware<UIFlagEnvironmentFilter>) =>
    _updateFiltersWithEnvironmentAndResetPagination({
      filter: { ...flagListParams.filter, ...nextFilter },
      selectedEnvironmentKey: nextFilter.selectedEnvironmentKey,
    });

  const clearEnvironmentFilters = () =>
    _updateFiltersAndResetPagination({
      filter: { ...flagListParams.filter, ...clearEnvironmentFilters_(flagListParams.filter ?? {}) },
    });

  const updateProjectFilters = (nextFilter: UIFlagProjectFilter) =>
    _updateFiltersAndResetPagination({ filter: { ...flagListParams.filter, ...nextFilter } });

  const createProjectFiltersSearchParams = (nextFilter: UIFlagProjectFilter) =>
    createSearchParams({ filter: { ...flagListParams.filter, ...nextFilter } });

  const clearProjectFilters = () =>
    _updateFiltersAndResetPagination({
      filter: { ...flagListParams.filter, ...clearProjectFilters_(flagListParams.filter ?? {}) },
    });

  const clearAllFilters = () =>
    _updateFiltersAndResetPagination({
      filter: {},
    });

  const hasActive = hasActiveFilters(flagListParams.filter);

  return {
    view: flagListParams,
    hasActiveFilters: hasActive,
    projectFilters,
    environmentFilters,
    updateProjectFilters,
    createProjectFiltersSearchParams,
    clearProjectFilters,
    updateEnvironmentFilters,
    clearEnvironmentFilters,
    updateTextSearch,
    clearTextSearch,
    updateOrdering,
    updateOffset,
    clearAllFilters,
  } as const;
}
