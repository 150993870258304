import { enableMostRecentAuditLogHistory } from '@gonfalon/dogfood-flags';
import { endOfDay, startOfDay, startOfToday, startOfYesterday, subDays, subMonths, subYears } from 'date-fns';

import { createShortcut } from './createShortcut';

export type dateType = Date | number;

export const today = new Date();

const modify = (fn: (val: dateType) => Date) => {
  const val = new Date(today);
  return fn(val);
};

const yesterday = modify(() => startOfYesterday());
const oneWeekAgo = modify((d: dateType) => subDays(d, 7));
const oneMonthAgo = modify((d: dateType) => subMonths(d, 1));
const threeMonthsAgo = modify((d: dateType) => subMonths(d, 3));
const sixMonthsAgo = modify((d: dateType) => subMonths(d, 6));
const oneYearAgo = modify((d: dateType) => subYears(d, 1));

export const createDefaultShortcuts = () => {
  const mostRecentShortcut = { label: 'Most recent', dateRange: [undefined, undefined] };

  const shortcuts = [
    ...(enableMostRecentAuditLogHistory() ? [mostRecentShortcut] : []),
    createShortcut('Today', [startOfToday(), endOfDay(today)]),
    createShortcut('Yesterday', [startOfDay(yesterday), endOfDay(yesterday)]),
    createShortcut('Last week', [startOfDay(oneWeekAgo), endOfDay(today)]),
    createShortcut('Last month', [startOfDay(oneMonthAgo), endOfDay(today)]),
    createShortcut('Last 3 months', [startOfDay(threeMonthsAgo), endOfDay(today)]),
    createShortcut('Last 6 months', [startOfDay(sixMonthsAgo), endOfDay(today)]),
    createShortcut('Last 12 months', [startOfDay(oneYearAgo), endOfDay(today)]),
  ];
  return shortcuts;
};
