import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { noop } from '@gonfalon/es6-utils';
import { ReplaySubject, Subject } from 'rxjs';

import GoaltenderFormElement, {
  GoaltenderFormElementProps,
  GoaltenderFormElementValue,
} from 'components/integrations/GoaltenderFormElement';
import {
  Conditions,
  DependsOn,
  EnvironmentFormVariables,
  LaunchDarklyIntegrationsManifest,
  VariableLocation,
} from 'types/generated/integrationSchema';
import { Environment } from 'utils/environmentUtils';

// these must match the enums values for `dependeeKeyLocation` defined in the integration manifest's definitions.json document
type FormVariableType = VariableLocation;

type FormChangeEvent = { fieldKey: string; newValue: GoaltenderFormElementValue; formValues: FormValues };

type FormContextValue = {
  formValues: FormValues;
  formEventEmitter: Subject<FormChangeEvent>;
  triggerFormChangedEvent: (changedFormKey: string, newValue: GoaltenderFormElementValue) => void;
};

type FormContextProps = {
  manifest: LaunchDarklyIntegrationsManifest;
  environment: Environment;
  formVariableType: FormVariableType;
  children: ReactNode;
};

export type DynamicFormVariableProps = Omit<GoaltenderFormElementProps, 'onChange'> & {
  onChange(key: string[], _: GoaltenderFormElementValue): void;
};

type DynamicFormActions = { hide: boolean };

type FormValues = { [key: string]: GoaltenderFormElementValue };

const getKeyName = (keyPrefix: FormVariableType, keyName: string) => `${keyPrefix}.${keyName}`;

/**
 * Takes the conditions for a form field and evalautes the conditions to determine if the conditions are met or not
 * @param conditions list of conditions to evaluate for a form field
 * @param value value to be used when evaluating conditions for a form field
 * @returns boolean result
 */
const isConditionsMet = (conditions: Conditions, value: GoaltenderFormElementValue) =>
  conditions.every((condition) => {
    if (!value) {
      return false;
    }

    switch (condition.operator) {
      case 'contains':
        return value.toString().toLowerCase().includes(condition.value.toLowerCase());
      case 'endsWith':
        return value.toString().endsWith(condition.value);
      case 'equalTo':
        return value === condition.value;
      case 'greaterThan':
        return +value > +condition.value;
      case 'greaterThanOrEqualTo':
        return +value >= +condition.value;
      case 'lessThan':
        return +value < +condition.value;
      case 'lessThanOrEqualTo':
        return +value <= +condition.value;
      case 'notEqual':
        return value !== condition.value;
      case 'startsWith':
        return value.toString().startsWith(condition.value);
      default:
        return false;
    }
  });

/**
 * Maps environment form variables keys to their set values from the environment service configuration
 * @param env project's environment
 * @param formVars integration manifest environment form variables
 * @returns map of form key and values
 */
const parseEnvironmentFormValues = (env?: Environment, formVars?: EnvironmentFormVariables): FormValues => {
  const values: FormValues = {};
  if (!formVars || !env) {
    return values;
  }

  for (const formVar of formVars) {
    values[getKeyName('environmentFormVariables', formVar.key)] =
      env.approvalSettings?.serviceConfig?.get(formVar.key) ?? undefined;
  }

  return values;
};

export const defaultContextValue: FormContextValue = {
  formValues: {},
  formEventEmitter: new Subject<FormChangeEvent>(),
  triggerFormChangedEvent: noop,
};

const IntegrationFormContext = createContext<FormContextValue>(defaultContextValue);

// Form context provider to orchestrate propagating form changes to child components
export function IntegrationFormContextProvider({
  manifest,
  environment,
  children,
  formVariableType,
}: FormContextProps) {
  const envFormVariables = manifest.capabilities?.approval?.environmentFormVariables;
  const envFormValues = parseEnvironmentFormValues(environment, envFormVariables);
  const [formValues, setFormValues] = useState<FormValues>(envFormValues);

  const formEventEmitter = new ReplaySubject<FormChangeEvent>(10);

  const triggerFormChangedEvent = useCallback((keyName: string, newValue: GoaltenderFormElementValue) => {
    const changedFormKey = getKeyName(formVariableType, keyName);
    const updatedFormValues = { ...formValues, [changedFormKey]: newValue };

    setFormValues(updatedFormValues);

    formEventEmitter.next({ fieldKey: changedFormKey, newValue, formValues: updatedFormValues });
  }, []);

  const contextValue = useMemo(() => ({ formValues, formEventEmitter, triggerFormChangedEvent }), [environment]);

  return <IntegrationFormContext.Provider value={contextValue}>{children}</IntegrationFormContext.Provider>;
}

/* eslint-disable import/no-default-export */
export default function DynamicGoaltenderFormElement({ formVar, onChange, ...props }: DynamicFormVariableProps) {
  const [formActions, setFormActions] = useState<DynamicFormActions>({ hide: false });
  const formContext = useContext(IntegrationFormContext);

  useEffect(() => {
    setFormActions({ ...formActions, hide: formVar.isHidden ?? false });

    // run any form initialization actions needed for this form element
    checkFieldDependencies('formInit', formContext.formValues);

    // subscribe to any form variable changes and check this element's dependencies for any action that needs to be taken
    const subscription = formContext.formEventEmitter.subscribe((event: FormChangeEvent) =>
      checkFieldDependencies('formChanged', event.formValues, event.fieldKey),
    );

    // Enums need need to be initialized with an initial value even if the default value isn't set. See static/ld/components/integrations/GoaltenderFormElement.tsx#L103
    if (formVar.type === 'enum') {
      formContext.triggerFormChangedEvent(
        formVar.key,
        props.value || formVar.defaultValue || (formVar.allowedValues && formVar.allowedValues[0]),
      ); // set initial value of field and trigger event
      if (props.value === undefined) {
        onChange(['config', formVar.key], formVar.defaultValue || (formVar.allowedValues && formVar.allowedValues[0]));
      }
    } else {
      formContext.triggerFormChangedEvent(formVar.key, props.value || formVar.defaultValue); // set initial value of field and trigger event
      if (props.value === undefined && formVar.defaultValue) {
        onChange(['config', formVar.key], formVar.defaultValue);
      }
    }

    return () => subscription.unsubscribe();
  }, []);

  const handleFormChanged = (keyPath: string[], value: GoaltenderFormElementValue) => {
    formContext.triggerFormChangedEvent(formVar.key, value);
    onChange(keyPath, value);
  };

  const checkFieldDependencies = (
    event: 'formInit' | 'formChanged',
    formValues: FormValues,
    changedFieldKey?: string,
  ) => {
    if (!formVar.dependsOn) {
      return;
    }

    let dependencies: DependsOn = formVar.dependsOn;
    if (event === 'formChanged') {
      // if event was triggered by form changes, check entries who's `dependsOn` variable key is the same as the variable that changed
      dependencies = formVar.dependsOn.filter((v) => getKeyName(v.variableLocation, v.variableKey) === changedFieldKey);
    }

    const results = dependencies.map((dep) => ({
      action: dep.action,
      conditionsMet: isConditionsMet(
        dep.conditions,
        String(formValues[getKeyName(dep.variableLocation, dep.variableKey)]),
      ),
    }));

    for (const result of results) {
      const updatedActions: DynamicFormActions = { ...formActions };

      if ((result.conditionsMet && result.action === 'hideField') || formVar.isHidden) {
        handleFormChanged(['config', formVar.key], formVar.defaultValue ?? undefined); // clear/reset the field
        updatedActions.hide = true;
      }

      if (result.conditionsMet && result.action === 'showField') {
        updatedActions.hide = false;
      }
      setFormActions(updatedActions);
    }
  };

  if (formActions.hide) {
    return <></>;
  }

  return (
    <GoaltenderFormElement
      key={formVar.key}
      formVar={formVar}
      onChange={handleFormChanged}
      value={props.value}
      disabled={props.disabled}
      integrationKey={props.integrationKey}
      isRestricted={props.isRestricted}
      dynamicEnumOptions={props.dynamicEnumOptions}
      formState={props.formState}
    />
  );
}
