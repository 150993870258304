export enum ExpiringTargetsInstructionKind {
  ADD_EXPIRE_USER_TARGET_DATE = 'addExpireUserTargetDate',
  UPDATE_EXPIRE_USER_TARGET_DATE = 'updateExpireUserTargetDate',
  REMOVE_EXPIRE_USER_TARGET_DATE = 'removeExpireUserTargetDate',
  ADD_EXPIRE_TARGET_DATE = 'addExpiringTarget',
  UPDATE_EXPIRE_TARGET_DATE = 'updateExpiringTarget',
  REMOVE_EXPIRE_TARGET_DATE = 'removeExpiringTarget',
}

export type UserTargetingExpirationInstruction = {
  kind: ExpiringTargetsInstructionKind;
  userKey: string;
  flagKey?: string;
  variationId?: string;
  value?: number;
  segmentKey?: string;
  targetType?: string;
  version?: number;
};

export type AddExpireUserTargetDateSemanticInstruction = UserTargetingExpirationInstruction & {
  kind: ExpiringTargetsInstructionKind.ADD_EXPIRE_USER_TARGET_DATE;
};

export type UpdateExpireUserTargetDateSemanticInstruction = UserTargetingExpirationInstruction & {
  kind: ExpiringTargetsInstructionKind.UPDATE_EXPIRE_USER_TARGET_DATE;
};

export type RemoveExpireUserTargetDateSemanticInstruction = UserTargetingExpirationInstruction & {
  kind: ExpiringTargetsInstructionKind.REMOVE_EXPIRE_USER_TARGET_DATE;
};

type ExpiringTargetsSemanticInstructionBase = {
  kind: ExpiringTargetsInstructionKind;
  contextKind: string;
  contextKey: string;
  flagKey?: string;
  variationId?: string;
  value?: number;
  segmentKey?: string;
  targetType?: string;
  version?: number;
};

export type AddExpireTargetDateSemanticInstruction = ExpiringTargetsSemanticInstructionBase & {
  kind: ExpiringTargetsInstructionKind.ADD_EXPIRE_TARGET_DATE;
};

export type UpdateExpireTargetDateSemanticInstruction = ExpiringTargetsSemanticInstructionBase & {
  kind: ExpiringTargetsInstructionKind.UPDATE_EXPIRE_TARGET_DATE;
};

export type RemoveExpireTargetDateSemanticInstruction = ExpiringTargetsSemanticInstructionBase & {
  kind: ExpiringTargetsInstructionKind.REMOVE_EXPIRE_TARGET_DATE;
};

export type SemanticExpiringUserTargetInstruction =
  | AddExpireUserTargetDateSemanticInstruction
  | UpdateExpireUserTargetDateSemanticInstruction
  | RemoveExpireUserTargetDateSemanticInstruction;

export type SemanticExpiringTargetInstruction =
  | AddExpireTargetDateSemanticInstruction
  | UpdateExpireTargetDateSemanticInstruction
  | RemoveExpireTargetDateSemanticInstruction;
