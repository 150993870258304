import { useEffect } from 'react';
import { formatCount, formatNumber } from '@gonfalon/format';
import { pluralize } from '@gonfalon/strings';
import { announce } from '@react-aria/live-announcer';
import classNames from 'clsx';

import './styles.css';
export type CounterHeaderProps = {
  resource: string;
  resourceCount: number;
  className?: string;
  shortForm?: boolean;
  truncate?: boolean;
};

export function CounterHeader({ resource, resourceCount, className, shortForm, truncate }: CounterHeaderProps) {
  useEffect(() => {
    if (resourceCount > 0) {
      announce(`${formatNumber(resourceCount)} ${pluralize(resource, resourceCount)}`, 'polite', 300);
    }
  }, [resourceCount]);
  return (
    <div className={classNames('CounterHeader', className)}>
      <div className={truncate ? 'CounterHeader-ellipse ' : 'u-pullright'}>
        {resourceCount > 0 && (
          <>
            <strong>{shortForm ? formatCount(resourceCount, false) : formatNumber(resourceCount)}</strong>{' '}
            {pluralize(resource, resourceCount)}
          </>
        )}
      </div>
    </div>
  );
}
