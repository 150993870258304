import { createContext } from 'react';
import { is, List, Map, Record, Set } from 'immutable';

import { ImmutableServerError } from 'utils/httpUtils';
import { ImmutableValidationResults, validateRecord } from 'utils/validationUtils';

export type FieldPath = string | string[];

function getPath(field: FieldPath) {
  return Array.isArray(field) ? field : [field];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ValidationFunction = (...args: any[]) => ImmutableValidationResults;

// This type represents any record that includes a validate() method, which we rely on
// for validating our forms.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormRecord<T extends Record<any>> = T & {
  validate?: ValidationFunction;
};

// This type defines the properties of our form state.
// (It's like SubscriptionType for our Subscription record.)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormStateType<T extends Record<any>> = {
  original: FormRecord<T>;
  modified: FormRecord<T>;
  formSubmitDisabled: boolean;
  formSubmitting: boolean;
  formSubmitted: boolean;
  serverError?: ImmutableServerError;
  validation: ImmutableValidationResults;
  fieldHits: Set<string>;
  fieldChanges: Set<string>;
  validate?: ValidationFunction;
};

// This type defines the interface of our form state.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormStateOps<T extends Record<any>> = Record<FormStateType<T>> & {
  isFormValid(): boolean;
  isValid(field: FieldPath): boolean;
  isDirty(field: FieldPath): boolean;
  isFormDirty(): boolean;
  isFormDirtyAndUnsaved(): boolean;
  isFormPristine(): boolean;
  isFormSubmitting(): boolean;
  isFormSubmitted(): boolean;
  hasServerError(): boolean;
  getServerError(): FormStateType<T>['serverError'];
  canSubmitForm(): boolean;
  canSubmitOptionalForm(): boolean;
  wasHit(field: FieldPath): boolean;
  wasChanged(field: FieldPath): boolean;
  getError(field: FieldPath): string;
  needsErrorFeedback(field: FieldPath): boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  revalidate(record: FormRecord<T>, ...args: any[]): FormState<T>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  revalidateIn(path: string, record: FormRecord<T>, ...args: any[]): FormState<T>;
  reset(record: FormRecord<T>): FormState<T>;
  clearFieldTracking(): FormState<T>;
  hitField(field: FieldPath): FormState<T>;
  trackField(field: FieldPath): FormState<T>;
  disableSubmit(): FormState<T>;
  enableSubmit(): FormState<T>;
  clearServerError(): FormState<T>;
  submitting(): FormState<T>;
  submitted(newRecord?: FormRecord<T>): FormState<T>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitFailed(record: FormRecord<T>, error: any, keepState?: boolean): FormState<T>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleBlur(field: FieldPath, record: FormRecord<T>, ...args: any[]): FormState<T>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormState<T extends Record<any> = Record<any>> = FormStateType<T> & FormStateOps<T>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class AnyFormRecord extends Record<any>({}) {
  validate() {
    return validateRecord(this);
  }
}

type FormContextType = {
  formState?: FormState;
};

export const FormContext = createContext<FormContextType>({});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createFormStateImpl<T extends Record<any>>() {
  return class FormStateImpl
    extends Record<FormStateType<T>>({
      // This hack is to get around the fact that original and modified should always be defined.
      // This is weird, but note there is no way to instantiate a form state where original / modified aren't the type we need,
      // since we only export `createFormState`, and `original` is required there.
      original: new AnyFormRecord() as unknown as FormRecord<T>,
      modified: new AnyFormRecord() as unknown as FormRecord<T>,
      formSubmitDisabled: false,
      formSubmitting: false,
      formSubmitted: false,
      serverError: undefined,
      validation: Map(),
      fieldHits: Set(),
      fieldChanges: Set(),
      validate: undefined,
    })
    implements FormState<T>
  {
    isFormValid() {
      return this.validation.isEmpty();
    }
    hasServerError() {
      return !!this.serverError;
    }
    getServerError() {
      return this.serverError;
    }
    isFormDirty() {
      return this.original?.equals ? !this.original.equals(this.modified) : !is(this.original, this.modified);
    }
    isFormDirtyAndUnsaved() {
      return !this.isFormSubmitting() && !this.isFormSubmitted() && this.isFormDirty();
    }
    // have any field changed ever?
    isFormPristine() {
      return this.fieldChanges.isEmpty();
    }
    isFormSubmitting() {
      return this.formSubmitting;
    }
    isFormSubmitted() {
      return this.formSubmitted;
    }
    canSubmitForm() {
      return this.canSubmitOptionalForm() && this.isFormDirty() && !this.isFormPristine();
    }
    canSubmitOptionalForm() {
      return !this.formSubmitDisabled && this.isFormValid() && !this.isFormSubmitting();
    }
    isValid(field: FieldPath) {
      return !this.validation.hasIn(getPath(field));
    }
    wasHit(field: FieldPath) {
      return this.fieldHits.contains(getPath(field).join('.'));
    }
    wasChanged(field: FieldPath) {
      return this.fieldChanges.contains(getPath(field).join('.'));
    }
    isDirty(field: FieldPath) {
      return this.original?.getIn(getPath(field)) !== this.modified?.getIn(getPath(field));
    }
    getError(field: FieldPath) {
      return this.validation.getIn(getPath(field), List()).first();
    }
    needsErrorFeedback(field: FieldPath) {
      return this.wasHit(field) && !this.isValid(field);
    }

    // mutators
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    revalidate(record: FormRecord<T>, ...args: any[]) {
      let validation: ImmutableValidationResults = Map();
      if (this.validate) {
        validation = this.validate(record, ...args);
      } else if (record.validate) {
        validation = record.validate(...args);
      }
      return this.set('modified', record).set('validation', validation);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    revalidateIn(path: string, record: FormRecord<T>, ...args: any[]) {
      let validation: ImmutableValidationResults = Map();
      if (this.validate) {
        validation = this.validate(record, ...args);
      } else if (record.validate) {
        validation = record.validate(...args);
      }
      return this.setIn(['modified'].concat(path), record).set('validation', validation);
    }
    reset(record: FormRecord<T>) {
      return this.merge({
        original: record,
        modified: record,
      });
    }
    clearFieldTracking() {
      return this.merge({
        fieldHits: Set(),
        fieldChanges: Set(),
      });
    }
    hitField(field: FieldPath) {
      return this.update('fieldHits', (hits) => hits.add(getPath(field).join('.')));
    }
    trackField(field: FieldPath) {
      return this.update('fieldChanges', (changes) => changes.add(getPath(field).join('.')));
    }
    disableSubmit() {
      return this.merge({
        formSubmitDisabled: true,
      });
    }
    enableSubmit() {
      return this.merge({
        formSubmitDisabled: false,
      });
    }
    clearServerError() {
      return this.delete('serverError');
    }
    submitting() {
      return this.merge({
        formSubmitted: false,
        formSubmitting: true,
      }).clearServerError();
    }
    submitted(newRecord?: FormRecord<T>) {
      let newState = this.merge({
        formSubmitting: false,
        formSubmitted: true,
      })
        .clearServerError()
        .clearFieldTracking();
      if (newRecord) {
        newState = newState.reset(newRecord);
      }
      return newState;
    }
    submitFailed(record: FormRecord<T>, error: ImmutableServerError, keepState: boolean) {
      let final = this.merge({
        formSubmitted: false,
        formSubmitting: false,
        serverError: error,
      });
      if (!keepState) {
        final = final.reset(record);
      }
      return final;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleBlur(field: FieldPath, record: FormRecord<T>, ...args: any[]) {
      return this.hitField(field).revalidate(record, ...args);
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createFormState<T extends Record<any>>(
  original: FormRecord<T>,
  customValidation?: { validate?: ValidationFunction },
  options?: object,
): FormState<T> {
  const { validate } = customValidation || {};
  const Impl = createFormStateImpl<T>();
  return new Impl({ original, modified: original, validate, ...options });
}

export const createFieldErrorId = (fieldIdentifier?: FieldPath) =>
  fieldIdentifier ? `${[...fieldIdentifier].join('')}-err` : undefined;
