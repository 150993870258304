import { variationRenderOptimization } from '@gonfalon/dogfood-flags';
import { getVariationStrokeColor } from '@gonfalon/flags';
import type { DiamondProps } from '@gonfalon/icons';
import { Diamond } from '@gonfalon/icons';
import cx from 'clsx';

import { createColor } from 'utils/colorUtils';

import styles from './VariationColor.module.css';

const isRenderOptimizationEnabled = variationRenderOptimization();

type OwnProps = {
  fill: string;
};

type VariationColorProps = DiamondProps & OwnProps;

export const VariationColor = ({
  fill,
  className,
  displayOuterStroke,
  size = 'small',
  strokeWidth = 2,
  stroke = isRenderOptimizationEnabled
    ? getVariationStrokeColor(fill) || createColor(fill).luminosity(30).css()
    : createColor(fill).luminosity(30).css(),
  children,
}: VariationColorProps) => (
  <Diamond
    className={cx(styles.icon, className)}
    size={size}
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    displayOuterStroke={displayOuterStroke}
    children={children}
  />
);
