import { VariationDisplay } from '@gonfalon/flags';

import { formatTotalDuration, msToTotalDuration } from 'components/automated-rollouts/progressive-rollouts/utils';
import { Variation } from 'utils/flagUtils';
import { SemanticInstructionProgressiveRolloutConfiguration } from 'utils/instructions/shared/types';

import styles from './ProgressiveRolloutConfigurationDescription.module.css';

export function ProgressiveRolloutConfigurationDescription({
  variations,
  progressiveRolloutConfiguration,
  rolloutContextKind,
}: {
  variations: Variation[];
  progressiveRolloutConfiguration: SemanticInstructionProgressiveRolloutConfiguration;
  rolloutContextKind: string;
}) {
  const variationIndex = variations.findIndex((v) => v._id === progressiveRolloutConfiguration.endVariationId);
  if (variationIndex === -1) {
    return <div>VARIATION NOT FOUND</div>;
  }

  const totalDuration = formatTotalDuration(
    msToTotalDuration(progressiveRolloutConfiguration.stages.reduce((acc, { durationMs = 0 }) => acc + durationMs, 0)),
  );

  return (
    <div>
      <div className={styles.rolloutWrapper}>
        Progressively rollout
        <VariationDisplay
          className={styles.variationDisplay}
          variation={variations[variationIndex]}
          variationIndex={variationIndex}
        />
        by context <code className={styles.rolloutContextKind}>{rolloutContextKind}</code>
      </div>
      <div>Total duration: {totalDuration}</div>
    </div>
  );
}
