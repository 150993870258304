import { ModernSubscription, SubscriptionStates } from '../../types';

export function isExpiredTrialOrCanceled(modernSubscription: ModernSubscription) {
  const { state: subscriptionState } = modernSubscription;
  const state = subscriptionState ?? SubscriptionStates.UNKNOWN;
  return [
    SubscriptionStates.GRACE_PERIOD_TRIAL,
    SubscriptionStates.LAPSED_TRIAL,
    SubscriptionStates.LAPSED_SUBSCRIPTION,
  ].includes(state as SubscriptionStates);
}
