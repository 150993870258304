export type ExperimentSummaryResultsType = {
  totals: ExperimentSummaryTotal[];
  stats: ExprimentSummaryStats;
  metricSeen?: ExperimentMetricStatusType;
};

export type ExperimentSummaryTotal = {
  cumulativeValue: number | null;
  cumulativeCount: number | null;
  cumulativeImpressionCount?: number;
  cumulativeConversionRate: number | null;
  cumulativeConfidenceInterval: { lower: number; upper: number } | null;
  pValue: number | null;
  improvement: number | null;
  minSampleSize?: number;
};

export type ExprimentSummaryStats = {
  pValue?: number;
  chi2?: number;
  winningVariationIdx?: number;
  minSampleSizeMet?: boolean;
};

export type ExperimentMetricStatusType = {
  ever: boolean;
  timestamp: number;
};

export enum NumericWinner {
  IS_NUMERIC_SUCCESS = 'isNumericSuccess',
  IS_NUMERIC_SETBACK = 'isNumericSetback',
  IS_NUMERIC_NO_CHANGE = 'isNumericNoChange',
}

export enum SuccessCriteria {
  LOWER_THAN_BASELINE = 'LowerThanBaseline',
  HIGHER_THAN_BASELINE = 'HigherThanBaseline',
}

export enum VariationResultChangeType {
  IMPROVEMENT = 'improvement',
  SETBACK = 'setback',
  NO_CHANGE = 'no change',
}

export type ExperimentsResultQuery = {
  to?: Date | number;
  from?: Date | number;
  tz?: string;
  summary?: number;
  approx?: boolean;
  useObservedEffectForRelativeDelta?: boolean;
  sumMetrics?: boolean;
};

export enum MetricOperatorToggleOption {
  AVERAGE = 'average',
  SUM = 'sum',
}
