import { useEffect, useRef } from 'react';
import { IconButton } from '@launchpad-ui/components';
import { IconName } from '@launchpad-ui/icons';
import classNames from 'clsx';

import './styles.css';

type ModuleEmptyStateProps = {
  interactive?: boolean;
  className?: string;
  onClick?(): void;
  icon?: IconName;
  children?: React.ReactNode;
  shouldFocusOnButton?: boolean;
  ariaLabel?: string;
};

/* eslint-disable import/no-default-export */
export default function ModuleEmptyState({
  interactive = false,
  className,
  children,
  shouldFocusOnButton,
  icon,
  ariaLabel,
  ...props
}: ModuleEmptyStateProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (shouldFocusOnButton) {
      buttonRef.current?.focus();
    }
  }, []);

  const classes = classNames(
    'Module-empty',
    {
      'u-c-pointer': interactive,
    },
    className,
  );

  const isDisabled = !interactive && !props.onClick;
  if (icon) {
    return (
      <IconButton
        aria-disabled={isDisabled}
        className={className}
        ref={buttonRef}
        icon={icon}
        size="medium"
        aria-label={ariaLabel ?? `${icon} button`}
        onPress={props.onClick}
      />
    );
  }

  return (
    <button type="button" {...props} aria-disabled={isDisabled} className={classes} ref={buttonRef}>
      {children}
    </button>
  );
}
