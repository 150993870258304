import { DeepPartial } from 'react-hook-form';
import { isEqual } from '@gonfalon/es6-utils';
import { FlagDefaultsRep, getFlagVariationValueString } from '@gonfalon/flags';
import { schemas } from '@gonfalon/openapi';
import { v4 } from 'uuid';

import { isNullOrUndefined } from 'utils/validationUtils';

import { couldBeJSONValue, couldBeNumerical, ValueType } from '../data-model/flags/type-utils';

import { FlagCreationForm, MigrationFlagTemplate } from './types';

type ClientSideAvailabilityPost = schemas['ClientSideAvailabilityPost'];
type Defaults = schemas['Defaults'];
type Variation = Omit<schemas['Variation'], 'value'> & {
  value: any;
};

export enum StageCount {
  TWO = 2,
  FOUR = 4,
  SIX = 6,
}

export const variationTypes: () => Array<{ type: ValueType; label: string }> = () => [
  { type: ValueType.BOOLEAN, label: 'Boolean' },
  { type: ValueType.STRING, label: 'String' },
  { type: ValueType.NUMBER, label: 'Number' },
  { type: ValueType.JSON, label: 'JSON' },
];

export const variationTypeValidators = {
  // we don't allow you to change boolean values in the new flow
  [ValueType.BOOLEAN]: { func: () => true, message: 'This must be true or false' },
  [ValueType.NUMBER]: { func: couldBeNumerical, message: 'This must be a number' },
  [ValueType.JSON]: { func: couldBeJSONValue, message: 'This must be valid JSON' },
  [ValueType.STRING]: { func: (v?: any) => typeof v === 'string', message: 'This must be a string' },
};

export const MAX_FLAG_NAME_LENGTH = 256;
export const MAX_FLAG_KEY_LENGTH = 100;
export const MAX_DESCRIPTION_LENGTH = 1024;

export enum FlagCreationErrorMessages {
  DESC_MAX_LENGTH = `Description must be fewer than ${MAX_DESCRIPTION_LENGTH} characters`,
  KEY_IS_NEW = 'You cannot use "new" as a key',
  KEY_MAX_LENGTH = `Key must be fewer than ${MAX_FLAG_KEY_LENGTH} characters`,
  KEY_REQUIRED = 'Key is required',
  KEY_IS_UNAVAILABLE = 'A flag already exists with this key. Choose a different key.',
  NAME_MAX_LENGTH = `Name must be fewer than ${MAX_FLAG_NAME_LENGTH} characters`,
  NAME_REQUIRED = 'Name is required',
  UNIQUE_VARIATION_NAMES = 'Variation names must be unique',
  MAINTAINER_REQUIRED = 'Select a maintainer',
  PREREQUISITES_NOT_COMPLETE = 'All prerequisites must have a key and a variation',
}

export const defaultBoolVariationIds = [v4(), v4()];
export const defaultNumberVariationIds = [v4(), v4()];
export const defaultStringVariationIds = [v4(), v4()];
export const defaultJSONVariationIds = [v4(), v4()];

// TODO: Must clean up after we release flag templates
export const startFromScratchTemplate = {
  id: 'custom',
  name: 'Custom',
  key: 'custom',
  description: 'Begin with a blank slate, most common for flags that have very specific use cases and complexity',
  clientSideAvailability: undefined,
  variations: [
    { _id: defaultBoolVariationIds[0], value: 'true', name: 'Enabled', description: '' },
    { _id: defaultBoolVariationIds[1], value: 'false', name: 'Disabled', description: '' },
  ],
  variationJsonSchema: undefined,
  temporary: undefined,
  tags: undefined,
  defaultVariations: undefined,
  variationType: ValueType.BOOLEAN,
};
export const migrationTwoStageDefaultJSONVariationIds = [v4(), v4()];

export const migrationTwoStageTemplate: MigrationFlagTemplate & { id?: string } = {
  id: 'migration-two-stage',
  name: 'Migration',
  key: 'migration-two-stage',
  description:
    'A temporary flag used to migrate data or systems while keeping your application available and disruption free',
  _clientSideAvailability: undefined,
  variations: [
    { _id: migrationTwoStageDefaultJSONVariationIds[0], value: 'off', name: 'off', description: '' },
    { _id: migrationTwoStageDefaultJSONVariationIds[1], value: 'complete', name: 'complete', description: '' },
  ],
  temporary: true,
  tags: undefined,
  defaultVariations: undefined,
  variationType: ValueType.STRING,
  purpose: 'migration',
  migrationSettings: {
    contextKind: '',
    stageCount: 2,
  },
};

export const migrationFourStageDefaultJSONVariationIds = [v4(), v4(), v4(), v4()];

export const migrationFourStageTemplate: MigrationFlagTemplate & { id?: string } = {
  id: 'migration-four-stage',
  name: 'Migration',
  key: 'migration-four-stage',
  description:
    'A temporary flag used to migrate data or systems while keeping your application available and disruption free',
  _clientSideAvailability: undefined,
  variations: [
    { _id: migrationFourStageDefaultJSONVariationIds[0], value: 'off', name: 'off', description: '' },
    { _id: migrationFourStageDefaultJSONVariationIds[1], value: 'shadow', name: 'shadow', description: '' },
    { _id: migrationFourStageDefaultJSONVariationIds[2], value: 'live', name: 'live', description: '' },
    { _id: migrationFourStageDefaultJSONVariationIds[3], value: 'complete', name: 'complete', description: '' },
  ],
  temporary: true,
  tags: undefined,
  defaultVariations: undefined,
  variationType: ValueType.STRING,
  purpose: 'migration',
  migrationSettings: {
    contextKind: '',
    stageCount: 4,
  },
};

export const migrationSixStageDefaultJSONVariationIds = [v4(), v4(), v4(), v4(), v4(), v4()];

export const migrationSixStageTemplate: MigrationFlagTemplate & { id?: string } = {
  id: 'migration-six-stage',
  name: 'Migration',
  key: 'migration-six-stage',
  description:
    'A temporary flag used to migrate data or systems while keeping your application available and disruption free',
  _clientSideAvailability: undefined,
  variations: [
    { _id: migrationSixStageDefaultJSONVariationIds[0], value: 'off', name: 'off', description: '' },
    {
      _id: migrationSixStageDefaultJSONVariationIds[1],
      value: 'dualwrite',
      name: 'dualwrite',
      description: '',
    },
    { _id: migrationSixStageDefaultJSONVariationIds[2], value: 'shadow', name: 'shadow', description: '' },
    { _id: migrationSixStageDefaultJSONVariationIds[3], value: 'live', name: 'live', description: '' },
    { _id: migrationSixStageDefaultJSONVariationIds[4], value: 'rampdown', name: 'rampdown', description: '' },
    { _id: migrationSixStageDefaultJSONVariationIds[5], value: 'complete', name: 'complete', description: '' },
  ],
  temporary: true,
  tags: undefined,
  defaultVariations: undefined,
  variationType: ValueType.STRING,
  purpose: 'migration',
  migrationSettings: {
    contextKind: '',
    stageCount: 6,
  },
};

export const FLAG_CREATION_DEFAULTS: {
  variationDefaults: Defaults;
  clientSideAvailability: ClientSideAvailabilityPost;
  tags: [];
  temporary: boolean;
} = {
  variationDefaults: {
    onVariation: 0,
    offVariation: 1,
  },
  clientSideAvailability: {
    usingMobileKey: true,
    usingEnvironmentId: false,
  },
  tags: [],
  temporary: true,
};

export const defaultNumberVariations: Variation[] = [
  { _id: defaultNumberVariationIds[0], value: '', name: '', description: '' },
  { _id: defaultNumberVariationIds[1], value: '', name: '', description: '' },
];

export const defaultStringVariations: Variation[] = [
  { _id: defaultStringVariationIds[0], value: '', name: '', description: '' },
  { _id: defaultStringVariationIds[1], value: '', name: '', description: '' },
];

export const defaultJSONVariations: Variation[] = [
  { _id: defaultJSONVariationIds[0], value: '', name: '', description: '' },
  { _id: defaultJSONVariationIds[1], value: '', name: '', description: '' },
];

// Dunno if RHF or HTML thing, but a raw value of false flags the associated
// <input /> as empty if the field is required, so we need strings.
// RHF properly knows the value regardless so you only need to do this if your
// <input type="text" /> accepts booleans and is required.
export const defaultBooleanVariations: Variation[] = [
  { _id: defaultBoolVariationIds[0], value: 'true', name: '', description: '' },
  { _id: defaultBoolVariationIds[1], value: 'false', name: '', description: '' },
];

export const defaultVariationsByKind = {
  [ValueType.BOOLEAN]: defaultBooleanVariations,
  [ValueType.JSON]: defaultJSONVariations,
  [ValueType.NUMBER]: defaultNumberVariations,
  [ValueType.STRING]: defaultStringVariations,
};

export const getFlagDefaultsFromRep = (projectFlagDefaults?: FlagDefaultsRep) => {
  const booleanOverrides = projectFlagDefaults?.booleanDefaults;

  const variations = [...defaultBooleanVariations];
  if (booleanOverrides) {
    variations[0].name = booleanOverrides.trueDisplayName;
    variations[0].description = booleanOverrides.trueDescription;
    variations[1].name = booleanOverrides.falseDisplayName;
    variations[1].description = booleanOverrides.falseDescription;
  }

  const defaultOnVariation = FLAG_CREATION_DEFAULTS.variationDefaults.onVariation;
  const defaultOffVariation = FLAG_CREATION_DEFAULTS.variationDefaults.offVariation;
  const variationDefaults = {
    onVariation: booleanOverrides?.onVariation ?? defaultOnVariation,
    offVariation: booleanOverrides?.offVariation ?? defaultOffVariation,
  };

  const clientSideAvailabilityOverrides = projectFlagDefaults?.defaultClientSideAvailability;
  const clientSideAvailability = {
    usingMobileKey:
      clientSideAvailabilityOverrides?.usingMobileKey ?? FLAG_CREATION_DEFAULTS.clientSideAvailability.usingMobileKey,
    usingEnvironmentId:
      clientSideAvailabilityOverrides?.usingEnvironmentId ??
      FLAG_CREATION_DEFAULTS.clientSideAvailability.usingEnvironmentId,
  };

  const tags = projectFlagDefaults?.tags ?? FLAG_CREATION_DEFAULTS.tags;
  const tagsOptions = tags.map((tag) => ({ value: tag, label: tag }));

  return {
    variations,
    variationDefaults,
    clientSideAvailability,
    tags: tagsOptions,
    temporary: projectFlagDefaults?.temporary ?? FLAG_CREATION_DEFAULTS.temporary,
  };
};

export const getVariationName = (variations: Variation[], variationIndex: number) => {
  if (variations.length < 1) {
    return `Variation ${variationIndex}`;
  }

  let index = variationIndex;
  if (variationIndex >= variations.length) {
    // user is removing last element in variations
    index = variations.length - 1;
  }
  const name = variations?.[index]?.name;
  if (name) {
    return name;
  }

  const value = getFlagVariationValueString(variations?.[index]?.value);

  return value || `Variation ${index + 1}`;
};

export const getUsedProjectDefaults = (defaults: FlagDefaultsRep, formState: DeepPartial<FlagCreationForm>) => {
  const usedProjectDefaults = {
    temporary: false,
    sdkAvailability: false,
    tags: false,
    onVariation: false,
    offVariation: false,
  };

  // flagPermanence
  const formStateTemporary = formState.temporary === 'true';
  if (!isNullOrUndefined(defaults.temporary) && defaults.temporary === formStateTemporary) {
    usedProjectDefaults.temporary = true;
  }

  // sdkAvailability
  const defaultAvailability = defaults.defaultClientSideAvailability;
  if (defaultAvailability) {
    if (
      !isNullOrUndefined(defaultAvailability.usingEnvironmentId) &&
      defaultAvailability.usingEnvironmentId === formState.usingEnvironmentId &&
      !isNullOrUndefined(defaultAvailability.usingMobileKey) &&
      defaultAvailability.usingMobileKey === formState.usingMobileKey
    ) {
      usedProjectDefaults.sdkAvailability = true;
    }
  }

  // tags
  const tags = defaults.tags;
  const formStateTags = formState.tags?.map((selectedTagObj) => selectedTagObj?.value);
  if (tags?.length && isEqual(tags, formStateTags)) {
    usedProjectDefaults.tags = true;
  }

  const defaultVariationDefaults = defaults.booleanDefaults;
  // onVariation
  if (defaultVariationDefaults) {
    if (
      !isNullOrUndefined(defaultVariationDefaults.onVariation) &&
      defaultVariationDefaults.onVariation === formState.onVariation?.value
    ) {
      usedProjectDefaults.onVariation = true;
    }
  }

  // offVariation
  if (defaultVariationDefaults) {
    if (
      !isNullOrUndefined(defaultVariationDefaults.offVariation) &&
      defaultVariationDefaults.offVariation === formState.offVariation?.value
    ) {
      usedProjectDefaults.offVariation = true;
    }
  }

  return usedProjectDefaults;
};
