import { useProjectKey } from '@gonfalon/router';

import { useProjectExperimentationSettings } from 'components/experimentation/common/hooks/useProjectExperimentationSettings';

export function useRandomizationUnit(randomizationUnit?: string) {
  const projKey = useProjectKey();
  const queryResult = useProjectExperimentationSettings({ projKey, enabled: !!randomizationUnit });
  if (queryResult.isPending || queryResult.isError) {
    return { ...queryResult, data: undefined };
  }
  const data = queryResult.data.randomizationUnits.find((unit) => unit.randomizationUnit === randomizationUnit);
  if (!data) {
    return {
      ...queryResult,
      data: undefined,
      isError: true,
      error: new Error(`Randomization unit not found: ${randomizationUnit}`),
    };
  }
  return { ...queryResult, data };
}
