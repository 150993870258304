// eslint-disable-next-line no-restricted-imports
import { fromJS, OrderedMap, OrderedSet, Record } from 'immutable';
import { normalize, schema } from 'normalizr';

import { convertMapToOrderedMap } from 'utils/collectionUtils';
import {
  createCustomWorkflow,
  CustomWorkflow,
  CustomWorkflowExecutionStatusType,
  WorkflowsSortOrder,
} from 'utils/customWorkflowUtils';
import http, { jsonToImmutable, jsonToImmutableError, middleware } from 'utils/httpUtils';
import { WorkflowTemplateReport } from 'utils/workflowTemplateUtils';

export type CustomWorkflowsResponse = {
  entities: Record<{ customWorkflows: OrderedMap<string, CustomWorkflow> }>;
  result: Record<{ items: OrderedSet<string> }>;
};

const customWorkflowsResponseEntitiesRecord = Record<{ customWorkflows: OrderedMap<string, CustomWorkflow> }>({
  customWorkflows: OrderedMap<string, CustomWorkflow>(),
});

const customWorkflowsResponseResultRecord = Record<{ items: OrderedSet<string> }>({ items: OrderedSet<string>() });

const customWorkflowsResponseRecord = Record<CustomWorkflowsResponse>({
  entities: customWorkflowsResponseEntitiesRecord(),
  result: customWorkflowsResponseResultRecord(),
});

export type CustomWorkflowsResponseRecord = Record<CustomWorkflowsResponse>;

const customWorkflowsEntity = new schema.Entity(
  'customWorkflows',
  {},
  {
    idAttribute: '_id',
  },
);

const getWorkflowsUrl = (projKey: string, flagKey: string, envKey: string) =>
  `/api/v2/projects/${projKey}/flags/${flagKey}/environments/${envKey}/workflows`;

export async function getCustomWorkflowsbyStatus(
  projKey: string,
  envKey: string,
  flagKey: string,
  status: CustomWorkflowExecutionStatusType,
  sort?: WorkflowsSortOrder,
): Promise<CustomWorkflowsResponseRecord> {
  let url = `${getWorkflowsUrl(projKey, flagKey, envKey)}?status=${
    status === CustomWorkflowExecutionStatusType.PENDING ? CustomWorkflowExecutionStatusType.ACTIVE : status
  }`;

  if (sort) {
    url = `${url}&sort=${sort}`;
  }

  return http
    .get(url, { beta: true })
    .then(async (response) =>
      response.json().then((rawJSON) => {
        const normalized = fromJS(normalize(rawJSON, { items: [customWorkflowsEntity] }));
        const responseRecord: CustomWorkflowsResponseRecord = customWorkflowsResponseRecord({
          entities: customWorkflowsResponseEntitiesRecord({
            customWorkflows: normalized.getIn(['entities', 'customWorkflows'])
              ? convertMapToOrderedMap(
                  normalized.getIn(['entities', 'customWorkflows']),
                  normalized.getIn(['result', 'items']),
                ).map(createCustomWorkflow)
              : OrderedMap<string, CustomWorkflow>(),
          }),
          result: customWorkflowsResponseResultRecord({ items: OrderedSet(normalized.getIn(['result', 'items'])) }),
        });
        return responseRecord;
      }),
    )
    .catch(jsonToImmutableError);
}

export const getCustomWorkflowById = async (
  projKey: string,
  envKey: string,
  flagKey: string,
  workflowId: string,
): Promise<CustomWorkflow> => {
  try {
    const res = await http.get(`${getWorkflowsUrl(projKey, flagKey, envKey)}/${workflowId}`, { beta: true });
    return createCustomWorkflow(await res.json());
  } catch (err) {
    return jsonToImmutableError(err as Response);
  }
};

export async function createNewCustomWorkflow(
  projKey: string,
  flagKey: string,
  envKey: string,
  customWorkflow: CustomWorkflow,
) {
  return http
    .post(getWorkflowsUrl(projKey, flagKey, envKey), {
      body: customWorkflow.toRep(),
      headers: { 'LD-API-Version': 'beta' },
    })
    .then(jsonToImmutable)
    .then(createCustomWorkflow)
    .catch(jsonToImmutableError);
}

export async function deleteCustomWorkflow(projKey: string, flagKey: string, envKey: string, workflowId: string) {
  const url = `${getWorkflowsUrl(projKey, flagKey, envKey)}/${workflowId}`;
  return http.delete(url, { beta: true }).catch(jsonToImmutableError);
}

export type WorkflowTemplateReportParams = {
  projKey: string;
  flagKey: string;
  envKey: string;
  workflowTemplateKey: string;
};

export async function reportCustomWorkflowFromTemplate({
  projKey,
  flagKey,
  envKey,
  workflowTemplateKey,
}: WorkflowTemplateReportParams): Promise<WorkflowTemplateReport> {
  const url = `${getWorkflowsUrl(projKey, flagKey, envKey)}?templateKey=${workflowTemplateKey}&dryRun=true`;

  return http
    .post(url, { beta: true, body: {} })
    .then(middleware.json)
    .catch(async (error) =>
      jsonToImmutableError(error).then((e) => {
        throw e;
      }),
    );
}
