import { ReactNode } from 'react';
import { HoldoutStatus } from '@gonfalon/experiments';
import type { IconProps } from '@launchpad-ui/icons';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';

import {
  ExperimentStatus,
  HOLDOUT_ANALYZING,
  HOLDOUT_CREATED,
  HOLDOUT_ENABLED,
  HOLDOUT_ENDED,
  NOT_STARTED,
  RUNNING,
  STOPPED,
} from 'components/experimentation/common/types';

import styles from './ExperimentStatusIcon.module.css';
/**
 * Helper component wraps an icon and label in an layout element
 *
 * @param param.kind - the display style
 * @param param.icon - the status' icon
 * @param param.label - a descriptive label describing the status
 * @returns
 */
const IconWrapper = ({
  icon,
  label,
  className,
  kind,
}: {
  icon: ReactNode;
  kind: 'minimal' | 'normal';
  label: string;
  className?: string;
}) => (
  <span aria-label="Experiment status" className={`u-flex u-flex-row u-flex-middle ${className}`}>
    {icon}
    {kind === 'normal' && <span className="u-pl-s">{label}</span>}
  </span>
);

/**
 * Component renders an experiment status badge
 *
 * @param param.status - the experiment status to be presented
 * @param param.kind - the display style for the status
 */
export const ExperimentStatusIcon = ({
  status,
  className,
  kind = 'normal',
  isHoldout = false,
}: {
  kind?: 'minimal' | 'normal';
  status: ExperimentStatus | HoldoutStatus;
  className?: string;
  isHoldout?: boolean;
}) => {
  const iconProps = {
    size: 'small' as IconProps['size'],
  };

  const experimentRunning = status === RUNNING;
  const experimentNotStarted = status === NOT_STARTED;
  const experimentPausedOrStopped = status === STOPPED;

  if (status === HOLDOUT_CREATED) {
    return (
      <IconWrapper
        kind={kind}
        className={className}
        icon={<Icon name="created" className={cx(styles.ExperimentStatusIconNotStarted)} {...iconProps} />}
        label="Created"
      />
    );
  }

  if (status === HOLDOUT_ENABLED) {
    return (
      <IconWrapper
        kind={kind}
        className={className}
        icon={<Icon name="holdout" className={cx(styles.ExperimentStatusIconEnabled)} {...iconProps} />}
        label="Ready"
      />
    );
  }

  if (status === HOLDOUT_ANALYZING && isHoldout) {
    return (
      <IconWrapper
        kind={kind}
        className={className}
        icon={<Icon name="flask-bolt" className={cx(styles.ExperimentStatusIconClean)} {...iconProps} />}
        label="Analyzing"
      />
    );
  }

  if (status === HOLDOUT_ENDED) {
    return (
      <IconWrapper
        kind={kind}
        className={className}
        icon={<Icon name="check-circle" className={cx(styles.ExperimentStatusIconStopped)} {...iconProps} />}
        label="Complete"
      />
    );
  }

  if (experimentRunning && !isHoldout) {
    return (
      <IconWrapper
        kind={kind}
        className={className}
        icon={<Icon name="play" className={cx(styles.ExperimentStatusIconRunning)} {...iconProps} />}
        label="Running"
      />
    );
  }

  if (experimentNotStarted) {
    return (
      <IconWrapper
        kind={kind}
        className={className}
        icon={<Icon name="clock-alert" className={cx(styles.ExperimentStatusIconNotStarted)} {...iconProps} />}
        label="Not started"
      />
    );
  }

  if (experimentPausedOrStopped) {
    return (
      <IconWrapper
        kind={kind}
        className={className}
        icon={<Icon name="stop" className={cx(styles.ExperimentStatusIconStopped)} {...iconProps} />}
        label="Stopped"
      />
    );
  }

  return null;
};
