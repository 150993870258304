import { enableFilterFlagsByCreationDate } from '@gonfalon/dogfood-flags';
import { isEmpty } from '@gonfalon/es6-utils';
import { closestTo, startOfDay, subMonths, subWeeks, subYears } from 'date-fns';

import { FilterKind, FilterValue } from 'components/ui/multipleFilters/types';
import FlagFilterInterface, { BackendQueryParamName, QueryParamName } from 'utils/flagFilters/flagFilterInterface';
import { FilterOperator, FlagListBackendQueryParam, FlagListQueryParam } from 'utils/flagFilters/types';

export enum CreationDateRange {
  ONE_WEEK_AGO = '1-week-ago',
  ONE_MONTH_AGO = '1-month-ago',
  TWO_MONTHS_AGO = '2-months-ago',
  THREE_MONTHS_AGO = '3-months-ago',
  SIX_MONTHS_AGO = '6-months-ago',
  ONE_YEAR_AGO = '1-year-ago',
}

const creationDateOptions: Record<CreationDateRange, string> = {
  [CreationDateRange.ONE_WEEK_AGO]: '1 week ago',
  [CreationDateRange.ONE_MONTH_AGO]: '1 month ago',
  [CreationDateRange.TWO_MONTHS_AGO]: '2 months ago',
  [CreationDateRange.THREE_MONTHS_AGO]: '3 months ago',
  [CreationDateRange.SIX_MONTHS_AGO]: '6 months ago',
  [CreationDateRange.ONE_YEAR_AGO]: '1 year ago',
} as const;

export const isCreationDateRange = (v: string): v is CreationDateRange => v in creationDateOptions;

const getCreationDateDates = (datetime: Date | number): Record<CreationDateRange, number> => {
  const baselineDate = startOfDay(datetime);
  return {
    '1-week-ago': subWeeks(baselineDate, 1).getTime(),
    '1-month-ago': subMonths(baselineDate, 1).getTime(),
    '2-months-ago': subMonths(baselineDate, 2).getTime(),
    '3-months-ago': subMonths(baselineDate, 3).getTime(),
    '6-months-ago': subMonths(baselineDate, 6).getTime(),
    '1-year-ago': subYears(baselineDate, 1).getTime(),
  };
};

class CreationDateFlagFilter implements FlagFilterInterface<CreationDateRange> {
  name: string;
  value?: CreationDateRange;
  kind: FilterKind;
  queryParamName: QueryParamName;
  backendQueryParamName: BackendQueryParamName;
  operators: string[];
  defaultValue: CreationDateRange;

  constructor(value?: CreationDateRange) {
    this.name = 'Created';
    this.value = value;
    this.kind = FilterKind.CREATION_DATE;
    this.queryParamName = FlagListQueryParam.CREATION_DATE;
    this.backendQueryParamName = FlagListBackendQueryParam.CREATION_DATE;
    this.operators = [FilterOperator.OVER];
    this.defaultValue = CreationDateRange.ONE_WEEK_AGO;
  }

  getFilterValueDisplayName(value?: FilterValue) {
    if (!value) {
      return '';
    }
    return creationDateOptions[value as CreationDateRange];
  }

  getDisplayName = () => this.name;

  getOptions() {
    const options = Object.entries(creationDateOptions).map(([value, label]) => ({
      label,
      name: label,
      value: value as CreationDateRange,
      filterOption: this.kind,
      isChecked: this.value === value,
    }));
    return options;
  }

  isEnabled = () => enableFilterFlagsByCreationDate();

  isEmpty = () => isEmpty(this.value);

  toQuery = () => this.value;

  isInvalidFilter = () =>
    !this.isEmpty() &&
    !(
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      isCreationDateRange(this.value!)
    ) /* eslint-enable @typescript-eslint/no-non-null-assertion */;

  clearFilter = () => (this.value = undefined);

  toBackendQueryValue(currentDate: number) {
    if (!this.value) {
      return undefined;
    }
    const creationDateDates = getCreationDateDates(currentDate);
    return { before: creationDateDates[this.value] };
  }

  toBackendQuery(currentDate: number) {
    if (!this.value) {
      return '';
    }
    const creationDateDates = getCreationDateDates(currentDate);
    return `creationDate:{"before":${creationDateDates[this.value]}}`;
  }

  parseFilterParam(creationDateQueryParts: string[], currentDate: number) {
    const [, , /* ignore key and {"before" */ value] = creationDateQueryParts;
    const creationDateBeforeDate = value.replace('}', '');
    const creationDate = parseInt(creationDateBeforeDate, 10);
    const creationDateDates = getCreationDateDates(currentDate);
    // the unix milliseconds value sent from the backend will be slightly older than the current time so we need to look up by the closest date
    const closestDateToAgeDate = closestTo(creationDate, Object.values(creationDateDates))?.getTime();
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return (Object.keys(creationDateDates) as CreationDateRange[]).find(
      (key) => creationDateDates[key] === closestDateToAgeDate,
    )!; /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }

  getFilterData() {
    return {
      operators: this.operators,
      options: this.getOptions(),
      defaultValue: this.defaultValue,
    };
  }
}

/* eslint-disable import/no-default-export */
export default CreationDateFlagFilter;
