import { isApprovalsEnabled, isFilterByApproversEnabled, isScheduledFlagChangesEnabled } from '@gonfalon/dogfood-flags';

type PendingChangesFeatureName = 'approvals' | 'filterByApprovers' | 'scheduledChanges';

export function usePendingChangesFeatureAccess(): { [Key in PendingChangesFeatureName]: () => boolean } {
  return {
    approvals: () => isApprovalsEnabled(),
    filterByApprovers: () => isFilterByApproversEnabled(),
    scheduledChanges: () => isScheduledFlagChangesEnabled(),
  };
}
