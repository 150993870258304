export const codes = {
  forbidden: 'forbidden',
  duplicateExperimentKey: 'duplicate_experiment_key',
  flagUsedInExperiment: 'flag_used_in_experiment',
} as const;

export const postExperimentErrorsByCode: Record<string, string> = {
  [codes.forbidden]:
    "Your role does not have permission to create experiments. Update your role's permissions to proceed.",
  [codes.duplicateExperimentKey]:
    'Experiment name already exists. Change the experiment name to create a new experiment.',
};

export const patchExperimentErrorsByCode: Record<string, string> = {
  [codes.forbidden]:
    "Your role does not have permission to edit experiments. Update your role's permissions to proceed.",
  [codes.flagUsedInExperiment]:
    'An experiment that uses this flag is running. You must stop the other experiment before starting this one.',
};

export type ErrorCode = (typeof codes)[keyof typeof codes];
