// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map } from 'immutable';

import { TagsAction } from 'actions/tags';
import { GlobalState } from 'reducers';
import registry from 'reducers/registry';
import { ImmutableServerError } from 'utils/httpUtils';
import { ImmutableMap } from 'utils/immutableUtils';

import 'epics/tags';

type TagsState = ImmutableMap<{
  kind?: string;
  prefix?: string;
  error?: ImmutableServerError;
  entities?: List<string>;
  isFetching: boolean;
  lastFetched?: number;
}>;

export function tags(
  state: TagsState = fromJS({
    lastFetched: null,
    isFetching: false,
    entities: [],
    error: null,
  }),
  action: TagsAction,
): TagsState {
  switch (action.type) {
    case 'tags/FETCH_TAGS':
      return Map({
        isFetching: true,
        prefix: action.prefix,
        kind: action.kind,
      });
    case 'tags/FETCH_TAGS_DONE':
      return state.merge({
        lastFetched: action.timestamp,
        isFetching: false,
        entities: action.tags,
      });
    case 'tags/FETCH_TAGS_FAILED':
      return state.merge({
        isFetching: false,
        error: action.error,
      });
    default:
      return state;
  }
}

export const tagsSelector = (state: GlobalState) => state.tags;

registry.addReducers({ tags });
