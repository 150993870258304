import { is2021Plan as isModernSubscription2021Plan } from '../internal/modernSubscription';
import { Subscription } from '../types';

import { isModernSubscription } from './isModernSubscription';

export function is2021Plan(subscription: Subscription) {
  if (isModernSubscription(subscription)) {
    return isModernSubscription2021Plan(subscription);
  }

  // not supported on legacy plans
  return false;
}
