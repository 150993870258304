import { measuredRolloutsMetricTimeSeriesResults, useMeasuredRollout } from '@gonfalon/rest-api';
import { useQueries } from '@tanstack/react-query';
import nullthrows from 'nullthrows';

import type { MeasuredRolloutTimeSeriesResultsWithMetric } from './types';
import { useLatestMeasuredRollout } from './useLatestMeasuredRollout';

export function useMeasuredRolloutTimeSeriesResults({
  projectKey,
  flagKey,
  environmentKey,
  measuredRolloutId,
}: {
  projectKey: string;
  flagKey: string;
  environmentKey: string;
  measuredRolloutId?: string;
}) {
  const { data: latestMeasuredRollout, isPending: isLatestMeasuredRolloutPending } = useLatestMeasuredRollout({
    projectKey,
    flagKey,
    environmentKey,
    enabled: !measuredRolloutId,
  });
  const { data: exactMeasuredRollout, isPending: isExactMeasuredRolloutPending } = useMeasuredRollout(
    { projectKey, flagKey, environmentKey, measuredRolloutId: measuredRolloutId ?? '' },
    { enabled: !!measuredRolloutId },
  );

  const measuredRollout = measuredRolloutId ? exactMeasuredRollout : latestMeasuredRollout;

  const resultQueries = useQueries({
    queries:
      measuredRollout && measuredRollout.design.metrics
        ? measuredRollout.design.metrics.map((metric) => ({
            ...measuredRolloutsMetricTimeSeriesResults({
              projectKey,
              flagKey,
              environmentKey,
              measuredRolloutId: measuredRollout.design.id,
              metricKey: metric.key,
            }),
            refetchInterval: measuredRollout.state.status === 'monitoring' ? 10000 : undefined, // refetch every 10000 milliseconds (10 seconds) if monitoring is underway
          }))
        : [],
  });

  if (
    (!measuredRolloutId && isLatestMeasuredRolloutPending) ||
    (!!measuredRolloutId && isExactMeasuredRolloutPending) ||
    resultQueries.some((query) => query.isPending)
  ) {
    return { isPending: true };
  }

  const data: MeasuredRolloutTimeSeriesResultsWithMetric[] = resultQueries.map((query, index) => {
    const timeseriesResults = nullthrows(query.data, 'Measured rollout results do not exist');
    const orderedResults = timeseriesResults.results.sort((a, b) => a.timestamp - b.timestamp);
    return {
      metric: nullthrows(measuredRollout?.design.metrics?.[index], 'Metric does not exist at this index'),
      results: {
        ...timeseriesResults,
        results: orderedResults,
      },
    };
  });

  return { isPending: false, data };
}
