import { ContextTargetingExpirationInstruction, createExpiringContextTarget } from 'utils/expiringContextTargetsUtils';
import http, { jsonToImmutable, jsonToImmutableError } from 'utils/httpUtils';

import { ExpiringTargetsUrlProps } from './types/expiringContextTargetsAPI';

const getExpiringContextTargetsForFlag = async ({ projKey, envKey, flagKey }: ExpiringTargetsUrlProps) => {
  const url = `/api/v2/flags/${projKey}/${flagKey}/expiring-targets/${envKey}`;
  return http
    .get(url, {
      beta: true,
    })
    .then(jsonToImmutable)
    .then((workFlows) => workFlows.get('items').map(createExpiringContextTarget))
    .catch(jsonToImmutableError);
};

const updateExpiringContextTargets = async ({
  patches,
  comment = '',
  url,
}: {
  patches: ContextTargetingExpirationInstruction[];
  comment?: string;
  url: string;
}) => {
  const body = {
    comment,
    instructions: patches,
  };

  return http
    .patch(url, {
      beta: true,
      body,
    })
    .then(jsonToImmutable)
    .then((expiringTargets) => expiringTargets.get('items').map(createExpiringContextTarget))
    .catch(jsonToImmutableError);
};

const getExpiringContextTargetsForSegment = async ({
  projKey,
  envKey,
  segmentKey,
}: {
  segmentKey: string;
  projKey: string;
  envKey: string;
}) => {
  const url = `/api/v2/segments/${projKey}/${segmentKey}/expiring-targets/${envKey} `;
  return http
    .get(url, {
      beta: true,
    })
    .then(jsonToImmutable)
    .then((workFlows) => workFlows.get('items').map(createExpiringContextTarget))
    .catch(jsonToImmutableError);
};

export { getExpiringContextTargetsForFlag, updateExpiringContextTargets, getExpiringContextTargetsForSegment };
