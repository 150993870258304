import { ClauseValue, ClauseWithKey } from '@gonfalon/clauses';

import { Clause as ImmutableClause } from 'utils/clauseUtils';

export enum ClauseInstructionKind {
  ADD_CLAUSES = 'addClauses',
  REMOVE_CLAUSES = 'removeClauses',
  UPDATE_CLAUSE = 'updateClause',
  ADD_VALUES_TO_CLAUSE = 'addValuesToClause',
  REMOVE_VALUES_FROM_CLAUSE = 'removeValuesFromClause',
}

export type AddClausesSemanticInstruction = {
  kind: ClauseInstructionKind.ADD_CLAUSES;
  ruleId: string;
  clauses: ClauseWithKey[];
};

export type RemoveClausesSemanticInstruction = {
  kind: ClauseInstructionKind.REMOVE_CLAUSES;
  ruleId: string;
  clauseIds: string[];
};

export type UpdateClauseSemanticInstruction = {
  kind: ClauseInstructionKind.UPDATE_CLAUSE;
  ruleId: string;
  clauseId: string;
  clause: ImmutableClause | null;
};

export type AddValuesToClauseSemanticInstruction = {
  kind: ClauseInstructionKind.ADD_VALUES_TO_CLAUSE;
  ruleId: string;
  clauseId: string;
  values: ClauseValue[];
};

export type RemoveValuesFromClauseSemanticInstruction = {
  kind: ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE;
  ruleId: string;
  clauseId: string;
  values: ClauseValue[];
};
