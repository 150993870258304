/* eslint-disable no-nested-ternary */
import { Icon } from '@launchpad-ui/icons';
import { Tooltip } from 'launchpad';

import { ClauseValidation } from 'utils/validation/clauses';
import { TargetingRuleValidation } from 'utils/validation/flags';
import {
  FallthroughValidation,
  PrerequisiteValidation,
  RolloutValidation,
  WeightedVariationValidation,
} from 'utils/validation/targeting';

type TargetingValidationHintProps = {
  validation:
    | ClauseValidation
    | WeightedVariationValidation
    | RolloutValidation
    | FallthroughValidation
    | PrerequisiteValidation
    | TargetingRuleValidation;
};

const TargetingValidationHint = ({ validation }: TargetingValidationHintProps) => (
  <Tooltip placement="top" content={validation.getMessage()}>
    {validation.isIncomplete() ? (
      <Icon name="warning" size="medium" className="u-warning" />
    ) : (
      <Icon name="minus-circle" size="small" className="u-v-top" />
    )}
  </Tooltip>
);

/* eslint-disable import/no-default-export */
export default TargetingValidationHint;
