import { isString } from '@gonfalon/es6-utils';
import classNames from 'clsx';

import { AlignValue, AlignValueObject, CellLayoutValue, CellLayoutValueObject } from './types';

type CellProps = {
  layout?: CellLayoutValue | CellLayoutValueObject;
  align?: AlignValue | AlignValueObject;
  flex?: boolean;
  tight?: boolean;
  className?: string;
  children?: React.ReactNode;
  testId?: string;
};

export const Cell = ({ className, layout, align, flex, tight, children, testId }: CellProps) => {
  let layoutClass;
  if (layout) {
    if (isString(layout)) {
      layoutClass = `Grid-cell--${layout}`;
    } else {
      layoutClass = classNames(
        layout.mobile ? `xs-Grid-cell--${layout.mobile}` : '',
        layout.tablet ? `sm-Grid-cell--${layout.tablet}` : '',
        layout.desktop ? `md-Grid-cell--${layout.desktop}` : '',
        layout.wide ? `lg-Grid-cell--${layout.wide}` : '',
      );
    }
  }

  let alignClass;
  if (align) {
    if (isString(align)) {
      alignClass = `Grid-cell--${align}`;
    } else {
      alignClass = classNames(
        align.mobile ? `xs-Grid-cell--${align.mobile}` : '',
        align.tablet ? `sm-Grid-cell--${align.tablet}` : '',
        align.desktop ? `md-Grid-cell--${align.desktop}` : '',
        align.wide ? `lg-Grid-cell--${align.wide}` : '',
      );
    }
  }

  const classes = classNames('Grid-cell', className, layoutClass, alignClass, {
    'Grid--flex': flex,
    'Grid-cell--tight': tight,
  });

  return (
    <div className={classes} data-test-id={testId}>
      {children}
    </div>
  );
};

Cell.defaultProps = {
  flex: false,
  tight: false,
};
