import { NewTabLink } from '@gonfalon/launchpad-experimental';

import { getComponentAnalytics, trackDocumentationLinkClicked } from 'utils/analyticsUtils';

export type DocumentationLinkProps = {
  href: string;
  text?: string;
  onTrackEvent?(): void;
  component: string;
  className?: string;
  showIcon?: boolean;
};

const DocumentationLink = ({
  href,
  text = 'read the documentation',
  onTrackEvent,
  component,
  className,
  showIcon = true,
}: DocumentationLinkProps) => {
  const trackEvent = () => {
    onTrackEvent?.();
    trackDocumentationLinkClicked(
      href,
      getComponentAnalytics({
        type: 'link',
        cta: text,
        component,
      }),
    );
  };

  return <NewTabLink href={href} text={text} onClick={trackEvent} className={className} showIcon={showIcon} />;
};

/* eslint-disable import/no-default-export */
export default DocumentationLink;
