import { makeEnvironmentAwareSearchParamsParser, makeEnvironmentAwareSearchParamsSerializer } from '@gonfalon/context';
import { createSearchParamsHook } from '@gonfalon/router';

import { type UIFlagEnvironmentFilter } from './flagEnvironmentFilters';
import { isEnvironmentFilter } from './isEnvironmentFilter';
import { isProjectFilter } from './isProjectFilter';
import { parseUIFlagFilterFromBrowser } from './parseUIFlagFilterFromBrowser';
import { serializeUIFlagFilterForBrowser } from './serializeUIFlagFilterForBrowser';

export const useFlagListEnvironmentFiltersSearchParams = createSearchParamsHook<UIFlagEnvironmentFilter>({
  parse: makeEnvironmentAwareSearchParamsParser((searchParams) => {
    const filter = parseUIFlagFilterFromBrowser(searchParams);
    const environmentFilter = structuredClone(filter);

    for (const fk of Object.keys(filter)) {
      if (!isEnvironmentFilter(fk)) {
        if (isProjectFilter(fk)) {
          delete environmentFilter[fk];
        }
      }
    }

    return environmentFilter;
  }),
  serialize: makeEnvironmentAwareSearchParamsSerializer((filter) => {
    const environmentOnly = structuredClone(filter);
    for (const fk of Object.keys(environmentOnly)) {
      if (!isEnvironmentFilter(fk)) {
        // @ts-expect-error @alexis is fixing this correctly in a follow-up PR
        delete environmentOnly[fk];
      }
    }

    return serializeUIFlagFilterForBrowser(environmentOnly);
  }),
});
