import { keepPreviousData as keepPreviousDataFn, useQuery } from '@tanstack/react-query';

import { EXPERIMENTS_V2_KEY } from 'components/experimentation/common/hooks/useExperiment';
import { ExperimentExpandOptions } from 'components/experimentation/common/types';

import { ExperimentListFilter, getExperiments } from '../api/experiment';

type Props = {
  projectKey: string;
  environmentKey: string;
  filter?: ExperimentListFilter;
  expand?: ExperimentExpandOptions[];
  limit?: number;
  offset?: number;
  enabled?: boolean;
  archived?: boolean;
  keepPreviousData?: boolean;
};

/**
 * Query fetches a list of epxeriments from the v2 API.
 *
 * @param param.projectKey - the key of the project the experiments belong to
 * @param param.environmentKey - the env key the experiments exist in
 * @param param.filter {ExperimentListFilter} - optional query parameters
 * @param param.limit - number of experiments to fetch
 * @param param.offset - where to start the experiment list
 * @param param.enabled - whether the experiments query is enabled or not, defaults to true
 * @param param.archived - whether to query archived experiments. Queries active experiments only by default
 */
export function experimentsQuery({
  projectKey,
  environmentKey,
  filter,
  expand,
  limit = 30,
  offset = 0,
  archived = false,
  enabled = true,
  keepPreviousData = true,
}: Props) {
  return {
    queryFn: async () =>
      getExperiments(projectKey, environmentKey, {
        filter,
        expand,
        limit,
        offset,
        lifecycleState: archived ? 'archived' : 'active',
      }),
    queryKey: [EXPERIMENTS_V2_KEY, projectKey, environmentKey, filter, limit, offset, archived],
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 2,
    placeholderData: keepPreviousData ? keepPreviousDataFn : undefined,
    enabled,
  };
}

/**
 * Hook fetches a list of epxeriments from the v2 API
 *
 * @param param.projectKey - the key of the project the experiments belong to
 * @param param.environmentKey - the env key the experiments exist in
 * @param param.filter {ExperimentListFilter} - optional query parameters
 * @param param.limit - number of experiments to fetch
 * @param param.offset - where to start the experiment list
 * @param param.enabled - whether the experiments query is enabled or not, defaults to true
 * @param param.archived - whether to query archived experiments. Queries active experiments only by default
 */
export function useExperiments({
  projectKey,
  environmentKey,
  filter,
  expand,
  limit = 30,
  offset = 0,
  enabled = true,
  archived = false,
  keepPreviousData = false,
}: Props) {
  return useQuery(
    experimentsQuery({
      projectKey,
      environmentKey,
      filter,
      expand,
      limit,
      offset,
      archived,
      enabled,
      keepPreviousData,
    }),
  );
}
