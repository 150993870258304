import { isBoolean, isNumber, isString } from '@gonfalon/es6-utils';
import { couldBeBoolean, couldBeNumber, isJSONString, isJSONValue } from '@gonfalon/types';
import { Collection, isImmutable } from 'immutable';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const couldBeJSONValue = (v?: any) => isJSONString(v) || isJSONValue(v);

// eslint-disable-next-line @typescript-eslint/no-explicit-any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const areAllBooleans = (values: any[]) => !!values.length && values.every(isBoolean);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const areAllNumbers = (values: any[]) => !!values.length && values.every(isNumber);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const areAllStrings = (values: any[]) => !!values.length && values.every(isString);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const areAllJSON = (values: any[]) => !!values.length && values.every(isJSONValue);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const couldAllBeNumbers = (values: any[] | Collection<any, any>) =>
  isImmutable(values) ? values.every(couldBeNumber) : values.every(couldBeNumber);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const couldAllBeBooleans = (values: any[] | Collection<any, any>) =>
  isImmutable(values) ? values.every(couldBeBoolean) : values.every(couldBeBoolean);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const couldAllBeJSON = (values: any[] | Collection<any, any>) =>
  isImmutable(values) ? values.every(couldBeJSONValue) : values.every(couldBeJSONValue);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAlternateType = (v: any) => {
  const type = typeof v;
  if (type !== 'string') {
    return 'string';
  }
  if (couldBeNumber(v)) {
    return 'number';
  }
  if (couldBeBoolean(v)) {
    return 'boolean';
  }
};
