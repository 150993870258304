import { ProgressBar } from '@launchpad-ui/components';
import { Box } from '@launchpad-ui/core';

export function EnvironmentFallback() {
  return (
    <Box width="$256" height="$128" display="grid" alignItems="center" justifyContent="center">
      <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
    </Box>
  );
}
