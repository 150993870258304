import { AnyAction, combineReducers } from 'redux';

import { ContextKind } from 'components/Contexts/types';
import { ExperimentationSettings } from 'components/experimentation/common/types';

import { createRequestReducer } from './createRequestReducer';
import { GlobalState } from './index';
import registry from './registry';

const request = createRequestReducer([
  'contextKinds/REQUEST_STARTED',
  'contextKinds/REQUEST_FINISHED',
  'contextKinds/REQUEST_FAILED',
  'contextKinds/PATCH_FLAG_RESPONSE_SUCCESS',
  'contextKinds/PATCH_FLAG_RESPONSE_FAILED',
  'contextKinds/DELETE_CONTEXT_SUCCESS',
  'contextKinds/DELETE_CONTEXT_FAILED',
  'contextKinds/PUT_CONTEXT_REQUEST_STARTED',
  'contextKinds/PUT_CONTEXT_REQUEST_FINISHED',
  'contextKinds/PUT_CONTEXT_REQUEST_FAILED',
]);

export const entities = (state = [], action: AnyAction) => {
  if (action.type === 'contextKinds/REQUEST_FINISHED') {
    return action.contextKinds.items;
  }
  if (
    action.type === 'contextKinds/PUT_CONTEXT_REQUEST_FINISHED' ||
    action.type === 'contextKinds/ARCHIVE_CONTEXT' ||
    action.type === 'contextKinds/RESTORE_CONTEXT'
  ) {
    // add or update the context kind in the list
    const contextKind = action.modifiedContext;
    const contextKindIndex = state.findIndex((context: ContextKind) => context.key === contextKind.key);
    if (contextKindIndex === -1) {
      return [...state, contextKind];
    }
    return [...state.slice(0, contextKindIndex), contextKind, ...state.slice(contextKindIndex + 1)];
  }
  return state;
};

export const experimentationSettings = (state = { randomizationUnits: [] }, action: AnyAction) => {
  if (action.type === 'contextKinds/EXPERIMENTATION_SETTINGS_REQUEST_FINISHED') {
    return action.experimentationSettings;
  }
  return state;
};

export const contextKinds = combineReducers({
  experimentationSettings,
  entities,
  request,
});

export const contextKindsExperimentationSettingsSelector = (state: GlobalState): ExperimentationSettings =>
  state.contextKinds.experimentationSettings;

export const contextKindsSelector = (state: GlobalState): ContextKind[] => state.contextKinds.entities;
export const unhiddenContextKindsSelector = (state: GlobalState): ContextKind[] =>
  state.contextKinds.entities.filter((e: ContextKind) => !e.hideInTargeting);
export const contextKindsRequestSelector = (state: GlobalState) => state.contextKinds.request;

registry.addReducers({ contextKinds });
