import UserAvatar from 'components/UserAvatar';

import { ReviewerOptionType } from './SelectReviewers';

import styles from './MemberOption.module.css';

type MemberOptionProps = {
  isInMenu?: boolean;
  isMulti?: boolean;
  option: ReviewerOptionType;
};

/* eslint-disable import/no-default-export */
export default function MemberOption(props: MemberOptionProps) {
  return props.isInMenu ? (
    <span className="u-flex u-flex-row u-flex-middle u-fs-sm ">
      {props.option.member && <UserAvatar member={props.option.member} className={styles.UserAvatar} />}
      {props.option.name && props.option.email && props.isMulti ? (
        <div className="u-o-hidden u-to-ellipsis">
          <div className={`u-fs-sm-even ${styles.Name}`}>{props.option.name}</div>
          <div className={`u-fs-xs-even ${styles.Email}`}>{props.option.email}</div>
        </div>
      ) : (
        props.option.label && <div className="u-o-hidden u-to-ellipsis">{props.option.label}</div>
      )}
    </span>
  ) : (
    <div className="u-flex u-flex-middle u-gap-1 u-fs-sm u-mw-100">
      {props.option.member && <UserAvatar size="small" member={props.option.member} />}{' '}
      <div className="u-o-hidden u-to-ellipsis">{props.option.name || props.option.email}</div>
    </div>
  );
}
