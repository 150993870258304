import { NavigateFunction, useNavigate } from 'react-router-dom';
import { isConcurrentlyFetchManifestsAndSubsEnabled } from '@gonfalon/dogfood-flags';
import { List, Map, OrderedMap } from 'immutable';

import { updateRepositoryForm, updateRepositoryFormDone, updateRepositoryFormFailed } from 'actions/flagCodeReferences';
import actionTypes from 'actionTypes/integrations';
import { useDispatch } from 'hooks/useDispatch';
import { GetState, GlobalDispatch, GlobalState } from 'reducers';
import {
  destinationsSelector,
  goaltenderManifestsSelector,
  goaltenderSubscriptionsSelector,
  repositoriesSelector,
  slackIncomingHooksRequestSelector,
} from 'reducers/integrations';
import { load, save } from 'sources/AccountLocalStorage';
// eslint-disable-next-line import/no-namespace
import * as CodeReferencesAPI from 'sources/CodeReferencesAPI';
// eslint-disable-next-line import/no-namespace
import * as IntegrationAPI from 'sources/IntegrationAPI';
import {
  DestinationResponse,
  DynamicEnumOption,
  GoaltenderManifestsAndSubscriptionRes,
  SlackHookResponse,
  TriggerCreateArgs,
} from 'sources/IntegrationAPI';
import { LaunchDarklyIntegrationsManifest } from 'types/generated/integrationSchema';
import { Repository } from 'utils/codeRefs/codeRefsUtils';
import { Environment } from 'utils/environmentUtils';
import { GoaltenderSubscription, manifestMapHasEventsHookCapability, ManifestRecord } from 'utils/goaltenderUtils';
import { Destination, SlackIncomingHook } from 'utils/integrationUtils';
import { Project } from 'utils/projectUtils';
import { GenerateActionType } from 'utils/reduxUtils';
import { TriggerType } from 'utils/triggerUtils';

export type IntegrationRequestOptions = { pathOnDone?: string };

const fetchRepositoryAction = () => ({ type: actionTypes.REQUEST_REPOSITORIES });
const fetchRepositoryActionDone = (repos: List<Repository>) => ({
  type: actionTypes.REQUEST_REPOSITORIES_DONE,
  repos,
});
const fetchRepositoryActionFailed = (error: Error) => ({ type: actionTypes.REQUEST_REPOSITORIES_FAILED, error });

function fetchRepositories() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(fetchRepositoryAction());
    return CodeReferencesAPI.getRepositories()
      .then((repos) => {
        dispatch(fetchRepositoryActionDone(repos));
      })
      .catch((error) => {
        dispatch(fetchRepositoryActionFailed(error));
      });
  };
}

function shouldFetchRepositories(state: GlobalState) {
  const repos = repositoriesSelector(state);
  return !repos.get('lastFetched') && !repos.get('isFetching');
}

function fetchRepositoriesIfNeeded() {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchRepositories(getState())) {
      return dispatch(fetchRepositories());
    }
  };
}

const updateRepositoryAction = (repo: Repository) => ({ type: 'integrations/UPDATE_REPOSITORY', repo }) as const;
const updateRepositoryActionDone = (repo: Repository) =>
  ({ type: 'integrations/UPDATE_REPOSITORY_DONE', repo }) as const;
const updateRepositoryActionFailed = (repo: Repository, error: Error) =>
  ({
    type: 'integrations/UPDATE_REPOSITORY_FAILED',
    repo,
    error,
  }) as const;

export const useUpdateRepository = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (oldRepository: Repository, newRepository: Repository, options: IntegrationRequestOptions = {}) => {
    dispatch(updateRepositoryForm(newRepository));
    dispatch(updateRepositoryAction(newRepository));

    return CodeReferencesAPI.updateRepository(oldRepository, newRepository).then(
      (updated) => {
        if (options.pathOnDone) {
          navigate(options.pathOnDone);
        }
        dispatch(updateRepositoryFormDone(newRepository));
        dispatch(updateRepositoryActionDone(updated));
      },
      (error) => {
        if (error.get('status') === 403 && options.pathOnDone) {
          navigate(options.pathOnDone);
        }
        dispatch(updateRepositoryFormFailed(newRepository, error));
        dispatch(updateRepositoryActionFailed(oldRepository, error));
      },
    );
  };
};

const deleteRepoAction = (repo: Repository) => ({ type: 'integrations/DELETE_REPOSITORY', repo }) as const;
const deleteRepoActionDone = (repo: Repository) => ({ type: 'integrations/DELETE_REPOSITORY_DONE', repo }) as const;
const deleteRepoActionFailed = (repo: Repository, error: Error) =>
  ({
    type: 'integrations/DELETE_REPOSITORY_FAILED',
    repo,
    error,
  }) as const;

export const useDeleteRepository = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (repo: Repository, options: IntegrationRequestOptions = {}) => {
    dispatch(deleteRepoAction(repo));
    return CodeReferencesAPI.deleteRepository(repo)
      .then(() => {
        options.pathOnDone && navigate({ pathname: options.pathOnDone });
        dispatch(deleteRepoActionDone(repo));
      })
      .catch((error) => {
        options.pathOnDone && navigate({ pathname: options.pathOnDone });
        dispatch(deleteRepoActionFailed(repo, error));
      });
  };
};

const fetchDestinationsAction = () => ({ type: actionTypes.REQUEST_DESTINATIONS });
const fetchDestinationsDone = (response: DestinationResponse) => ({
  type: actionTypes.REQUEST_DESTINATIONS_DONE,
  response,
});
const fetchDestinationsFailed = (error: Error) => ({ type: actionTypes.REQUEST_DESTINATIONS_FAILED, error });

function fetchDestinations(projects: OrderedMap<string, Project>, envs: OrderedMap<string, Environment>) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(fetchDestinationsAction());
    return IntegrationAPI.getAllDestinations(projects, envs)
      .then((response) => dispatch(fetchDestinationsDone(response)))
      .catch((error) => dispatch(fetchDestinationsFailed(error)));
  };
}

function shouldFetchDestinations(state: GlobalState) {
  const destinations = destinationsSelector(state);
  return !destinations.get('lastFetched') && !destinations.get('isFetching');
}

function fetchDestinationsIfNeeded(projects: OrderedMap<string, Project>, envs: OrderedMap<string, Environment>) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchDestinations(getState())) {
      return dispatch(fetchDestinations(projects, envs));
    }
  };
}

const createDestinationAction = (destination: Destination) =>
  ({ type: 'integrations/CREATE_DESTINATION', destination }) as const;
const createDestinationActionDone = (destination: Destination) =>
  ({
    type: 'integrations/CREATE_DESTINATION_DONE',
    destination,
  }) as const;
const createDestinationActionFailed = (destination: Destination, error: Error) =>
  ({
    type: 'integrations/CREATE_DESTINATION_FAILED',
    destination,
    error,
  }) as const;

export const useCreateDestination = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (
    destination: Destination,
    options: IntegrationRequestOptions & { projects?: Map<string, Project> } = {},
  ) => {
    dispatch(createDestinationAction(destination));
    return IntegrationAPI.createDestination(destination).then(
      (created) => {
        if (options.pathOnDone) {
          navigate({ pathname: options.pathOnDone });
        }
        dispatch(createDestinationActionDone(created));
      },
      (error) => {
        if (error.get('status') === 403 && options.pathOnDone) {
          navigate({ pathname: options.pathOnDone });
        }
        dispatch(createDestinationActionFailed(destination, error));
        throw error;
      },
    );
  };
};

const updateDestinationAction = (destination: Destination) =>
  ({
    type: 'integrations/UPDATE_DESTINATION',
    destination,
  }) as const;
const updateDestinationActionDone = (destination: Destination) =>
  ({
    type: 'integrations/UPDATE_DESTINATION_DONE',
    destination,
  }) as const;
const updateDestinationActionFailed = (destination: Destination, error: Error) =>
  ({
    type: 'integrations/UPDATE_DESTINATION_FAILED',
    destination,
    error,
  }) as const;

export const useUpdateDestination = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (oldDestination: Destination, newDestination: Destination, options: IntegrationRequestOptions = {}) => {
    dispatch(updateDestinationAction(newDestination));
    return IntegrationAPI.updateDestination(oldDestination, newDestination).then(
      (updated) => {
        if (options.pathOnDone) {
          navigate(options.pathOnDone);
        }
        dispatch(updateDestinationActionDone(updated));
      },
      (error) => {
        if (error.get('status') === 403 && options.pathOnDone) {
          navigate(options.pathOnDone);
        }

        dispatch(updateDestinationActionFailed(oldDestination, error));
        throw error;
      },
    );
  };
};

const deleteDestinationAction = (destination: Destination) =>
  ({ type: 'integrations/DELETE_DESTINATION', destination }) as const;
const deleteDestinationActionDone = (destination: Destination) =>
  ({
    type: 'integrations/DELETE_DESTINATION_DONE',
    destination,
  }) as const;
const deleteDestinationActionFailed = (destination: Destination, error: Error) =>
  ({
    type: 'integrations/DELETE_DESTINATION_FAILED',
    destination,
    error,
  }) as const;

export const useDeleteDestination = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (destination: Destination, options: IntegrationRequestOptions = {}) => {
    dispatch(deleteDestinationAction(destination));
    return IntegrationAPI.deleteDestination(destination)
      .then(() => {
        options.pathOnDone && navigate({ pathname: options.pathOnDone });
        dispatch(deleteDestinationActionDone(destination));
      })
      .catch((error) => {
        options.pathOnDone && navigate({ pathname: options.pathOnDone });
        dispatch(deleteDestinationActionFailed(destination, error));
      });
  };
};

const sendDestinationTestAction = (destination: Destination) => ({
  type: actionTypes.SEND_DESTINATION_EVENT,
  destination,
});
const sendDestinationTestActionDone = (destination: Destination) => ({
  type: actionTypes.SEND_DESTINATION_EVENT_DONE,
  destination,
});
const sendDestinationTestActionFailed = (destination: Destination, error: Error) => ({
  type: actionTypes.SEND_DESTINATION_EVENT_FAILED,
  destination,
  error,
});

const sendDestinationTestEvent = (destination: Destination) => async (dispatch: GlobalDispatch) => {
  dispatch(sendDestinationTestAction(destination));
  return IntegrationAPI.sendDestinationTestEvent(destination)
    .then(() => {
      dispatch(sendDestinationTestActionDone(destination));
    })
    .catch((error) => {
      dispatch(sendDestinationTestActionFailed(destination, error));
    });
};

const requestSlackHookAction = () => ({ type: actionTypes.REQUEST_SLACK_INCOMING_HOOKS });
const requestSlackHookActionDone = (response: SlackHookResponse) => ({
  type: actionTypes.REQUEST_SLACK_INCOMING_HOOKS_DONE,
  response,
});
const requestSlackHookActionFailed = (error: Error) => ({
  type: actionTypes.REQUEST_SLACK_INCOMING_HOOKS_FAILED,
  error,
});

function fetchSlackIncomingHooks() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestSlackHookAction());
    return IntegrationAPI.getAllSlackIncomingHooks()
      .then((response) => dispatch(requestSlackHookActionDone(response)))
      .catch((error) => dispatch(requestSlackHookActionFailed(error)));
  };
}

function shouldFetchAllSlackIncomingHooks(state: GlobalState) {
  const slack = slackIncomingHooksRequestSelector(state);
  return !slack.get('lastFetched') && !slack.get('isFetching');
}

function fetchSlackIncomingHooksIfNeeded() {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchAllSlackIncomingHooks(getState())) {
      return dispatch(fetchSlackIncomingHooks());
    }
  };
}

const createSlackHookAction = (hook: SlackIncomingHook) =>
  ({ type: 'integrations/CREATE_SLACK_INCOMING_HOOK', hook }) as const;
const createSlackHookActionDone = (hook: SlackIncomingHook) =>
  ({
    type: 'integrations/CREATE_SLACK_INCOMING_HOOK_DONE',
    hook,
  }) as const;
const createSlackHookActionFailed = (hook: SlackIncomingHook, error: Error) =>
  ({
    type: 'integrations/CREATE_SLACK_INCOMING_HOOK_FAILED',
    hook,
    error,
  }) as const;

export function useCreateSlackIncomingHook() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (hook: SlackIncomingHook, options: IntegrationRequestOptions): Promise<SlackIncomingHook> => {
    dispatch(createSlackHookAction(hook));
    return new Promise(async (resolve, reject) =>
      IntegrationAPI.createSlackIncomingHook(hook).then(
        (created) => {
          navigate({ pathname: options.pathOnDone });
          dispatch(createSlackHookActionDone(created));
          resolve(created);
        },
        (error) => {
          if (error.get('status') === 403) {
            navigate({ pathname: options.pathOnDone });
          }
          dispatch(createSlackHookActionFailed(hook, error));
          reject(error);
        },
      ),
    );
  };
}

export async function updateIntegrationBase<T>(
  slackHookActionTypes: string[],
  propName: string,
  updater: (o: T, n: T) => Promise<T>,
  oldIntegration: T,
  newIntegration: T,
  navigate: NavigateFunction,
  dispatch: GlobalDispatch,
  options: IntegrationRequestOptions = {},
) {
  const [start, done, failed] = slackHookActionTypes;

  dispatch({ type: start, old: oldIntegration, new: newIntegration });
  return new Promise((resolve, reject) => {
    updater(oldIntegration, newIntegration)
      .then((res) => {
        options.pathOnDone && navigate({ pathname: options.pathOnDone });
        dispatch({ type: done, [propName]: res });
        resolve(res);
      })
      .catch((error) => {
        if (error.get('status') === 403) {
          options.pathOnDone && navigate({ pathname: options.pathOnDone });
        }
        dispatch({ type: failed, [propName]: newIntegration, error });
        reject(error);
      });
  });
}

const updateSlackHookAction = (old: SlackIncomingHook, newHook: SlackIncomingHook) =>
  ({
    type: 'integrations/UPDATE_SLACK_INCOMING_HOOK',
    old,
    new: newHook,
  }) as const;
const updateSlackHookActionDone = (hook: SlackIncomingHook) =>
  ({
    type: 'integrations/UPDATE_SLACK_INCOMING_HOOK_DONE',
    hook,
  }) as const;
const updateSlackHookActionFailed = (hook: SlackIncomingHook, error: Error) =>
  ({
    type: 'integrations/UPDATE_SLACK_INCOMING_HOOK_FAILED',
    hook,
    error,
  }) as const;

export function useUpdateSlackIncomingHook() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async function (
    oldHook: SlackIncomingHook,
    newHook: SlackIncomingHook,
    options: IntegrationRequestOptions = {},
  ): Promise<SlackIncomingHook> {
    return updateIntegrationBase<SlackIncomingHook>(
      [
        'integrations/UPDATE_SLACK_INCOMING_HOOK',
        'integrations/UPDATE_SLACK_INCOMING_HOOK_DONE',
        'integrations/UPDATE_SLACK_INCOMING_HOOK_FAILED',
      ],
      'hook',
      IntegrationAPI.updateSlackIncomingHook,
      oldHook,
      newHook,
      navigate,
      dispatch,
      options,
    ) as Promise<SlackIncomingHook>;
  };
}

const deleteSlackHookAction = (hook: SlackIncomingHook) =>
  ({ type: 'integrations/DELETE_SLACK_INCOMING_HOOK', hook }) as const;
const deleteSlackHookActionDone = (hook: SlackIncomingHook) =>
  ({
    type: 'integrations/DELETE_SLACK_INCOMING_HOOK_DONE',
    hook,
  }) as const;
const deleteSlackHookActionFailed = (hook: SlackIncomingHook, error: Error) =>
  ({
    type: 'integrations/DELETE_SLACK_INCOMING_HOOK_FAILED',
    error,
    hook,
  }) as const;

export function useDeleteSlackIncomingHook() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (hook: SlackIncomingHook, options: IntegrationRequestOptions) => {
    dispatch(deleteSlackHookAction(hook));
    return IntegrationAPI.deleteSlackIncomingHook(hook)
      .then(() => {
        navigate({ pathname: options.pathOnDone });
        dispatch(deleteSlackHookActionDone(hook));
      })
      .catch((error) => {
        navigate({ pathname: options.pathOnDone });
        dispatch(deleteSlackHookActionFailed(hook, error));
      });
  };
}

const fetchGoaltenderAction = () => ({ type: actionTypes.REQUEST_GOALTENDER_MANIFESTS });
const fetchGoaltenderActionDone = (response: Map<string, ManifestRecord>) => ({
  type: actionTypes.REQUEST_GOALTENDER_MANIFESTS_DONE,
  response,
});
const fetchGoaltenderActionFailed = (error: Error) => ({
  type: actionTypes.REQUEST_GOALTENDER_MANIFESTS_FAILED,
  error,
});

function fetchGoaltenderManifests(integrationKey?: string) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(fetchGoaltenderAction());
    return (
      integrationKey ? IntegrationAPI.getGoaltenderManifests(integrationKey) : IntegrationAPI.getGoaltenderManifests()
    )
      .then((response) => dispatch(fetchGoaltenderActionDone(response)))
      .catch((error) => dispatch(fetchGoaltenderActionFailed(error)));
  };
}

function fetchGoaltenderManifestsAndSubscriptions(integrationKey?: string) {
  if (isConcurrentlyFetchManifestsAndSubsEnabled()) {
    return fetchGoaltenderManifestsAndSubscriptionsConcurrently();
  }
  return integrationKey
    ? fetchGoaltenderManifestsAndSubscriptionsSequentially(integrationKey)
    : fetchGoaltenderManifestsAndSubscriptionsSequentially();
}

const requestGoaltenderManifests = () => ({ type: actionTypes.REQUEST_GOALTENDER_MANIFESTS });
const requestGoaltenderManifestsDone = (manifests: OrderedMap<string, ManifestRecord>) => ({
  type: actionTypes.REQUEST_GOALTENDER_MANIFESTS_DONE,
  response: manifests,
});
const requestGoaltenderManifestsFailed = (error: Error) => ({
  type: actionTypes.REQUEST_GOALTENDER_MANIFESTS_FAILED,
  error,
});

/**
 * Fetches integration manifests, then uses the integration keys from the manifest response to fetch each
 * individual subscription.
 */
function fetchGoaltenderManifestsAndSubscriptionsSequentially(integrationKey?: string) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestGoaltenderManifests());
    return (
      integrationKey ? IntegrationAPI.getGoaltenderManifests(integrationKey) : IntegrationAPI.getGoaltenderManifests()
    )
      .then((manifests) => {
        dispatch(fetchAllGoaltenderSubscriptions(manifests));
        dispatch(requestGoaltenderManifestsDone(manifests));
      })
      .catch((error) => {
        dispatch(requestGoaltenderManifestsFailed(error));
      });
  };
}

const goaltenderManifestsFetchRequest = () => ({ type: actionTypes.REQUEST_GOALTENDER_MANIFESTS_AND_SUBSCRIPTIONS });
const goaltenderManifestsFetchRequestDone = (
  manifests: GoaltenderManifestsAndSubscriptionRes['manifests'],
  subscriptions: GoaltenderManifestsAndSubscriptionRes['subscriptions'],
) => ({
  type: actionTypes.REQUEST_GOALTENDER_MANIFESTS_AND_SUBSCRIPTIONS_DONE,
  subscriptions,
  manifests,
});
const goaltenderManifestsFetchRequestFailed = (error: Error) => ({
  type: actionTypes.REQUEST_GOALTENDER_MANIFESTS_AND_SUBSCRIPTIONS_FAILED,
  error,
});

/**
 * Bulk fetches subscriptions and manifests concurrently, then maps each subscription to its corresponding manifest
 */
function fetchGoaltenderManifestsAndSubscriptionsConcurrently() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(goaltenderManifestsFetchRequest());
    return IntegrationAPI.getAllGoaltenderManifestsAndSubscriptions()
      .then(({ manifests, subscriptions }) => {
        dispatch(goaltenderManifestsFetchRequestDone(manifests, subscriptions));
      })
      .catch((error) => {
        dispatch(goaltenderManifestsFetchRequestFailed(error));
      });
  };
}

const requestGoaltenderSubDoneNoCapabilities = () => ({
  type: actionTypes.REQUEST_GOALTENDER_SUBSCRIPTIONS_DONE_NO_CAPABILITIES,
});

function fetchAllGoaltenderSubscriptions(manifests: OrderedMap<string, ManifestRecord>) {
  return (dispatch: GlobalDispatch) => {
    // Manifests without the audit log events hook capability cannot have subscriptions so only fetch subscriptions with manifests
    const integrationsWithCapabilities = manifests.filter((manifest) => manifestMapHasEventsHookCapability(manifest));
    if (integrationsWithCapabilities.size === 0) {
      dispatch(requestGoaltenderSubDoneNoCapabilities());
    }
    integrationsWithCapabilities.map(async (manifest) => dispatch(fetchGoaltenderSubscriptions(manifest)));
  };
}

function shouldFetchGoaltenderManifests(state: GlobalState) {
  const manifests = goaltenderManifestsSelector(state);
  return !manifests.get('lastFetched') && !manifests.get('isFetching');
}

function shouldFetchGoaltenderSubscriptions(state: GlobalState) {
  const subscriptions = goaltenderSubscriptionsSelector(state);
  return !subscriptions.get('lastFetched') && !subscriptions.get('isFetching');
}

function fetchGoaltenderManifestsIfNeeded() {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchGoaltenderManifests(getState())) {
      return dispatch(fetchGoaltenderManifests());
    }
  };
}
function fetchGoaltenderManifestsAndSubscriptionsIfNeeded(integrationKey?: string) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    const state = getState();
    if (shouldFetchGoaltenderManifests(state)) {
      return dispatch(
        integrationKey
          ? fetchGoaltenderManifestsAndSubscriptions(integrationKey)
          : fetchGoaltenderManifestsAndSubscriptions(),
      );
    }
    if (shouldFetchGoaltenderSubscriptions(state)) {
      // Currently we can't fetch all subscriptions unless we have received a response for manifests
      // In the future we should consider using a single endpoint to fetch all subscriptions to remove
      // the dependency on manifests
      const manifestsState = goaltenderManifestsSelector(state);
      const manifests = manifestsState.get('manifests');
      if (manifests) {
        return dispatch(fetchAllGoaltenderSubscriptions(manifests));
      } else {
        return dispatch(fetchGoaltenderManifestsAndSubscriptions());
      }
    }
  };
}

const createRequestGoaltenderSubsAction = (kind: string) => ({
  type: actionTypes.REQUEST_GOALTENDER_SUBSCRIPTIONS,
  kind,
});
const createRequestGoaltenderSubsActionDone = (kind: string, response: GoaltenderSubscription[]) => ({
  type: actionTypes.REQUEST_GOALTENDER_SUBSCRIPTIONS_DONE,
  kind,
  response,
});
const createRequestGoaltenderSubsActionFailed = (kind: string, error: Error) => ({
  type: actionTypes.REQUEST_GOALTENDER_SUBSCRIPTIONS_FAILED,
  kind,
  error,
});

// fetches all goaltender auditlog event hooks subscriptions for a single integration
function fetchGoaltenderSubscriptions(manifest: ManifestRecord = {} as ManifestRecord) {
  const kind = manifest.get('key');
  return async (dispatch: GlobalDispatch) => {
    dispatch(createRequestGoaltenderSubsAction(kind));
    return IntegrationAPI.getGoaltenderSubscriptions(manifest)
      .then((response) => dispatch(createRequestGoaltenderSubsActionDone(kind, response)))
      .catch((error) => dispatch(createRequestGoaltenderSubsActionFailed(kind, error)));
  };
}

const createGoaltenderSubAction = (subscription: GoaltenderSubscription) =>
  ({
    type: 'integrations/CREATE_GOALTENDER_SUBSCRIPTION',
    subscription,
  }) as const;
const createGoaltenderSubActionDone = (subscription: GoaltenderSubscription, kind?: string) =>
  ({
    type: 'integrations/CREATE_GOALTENDER_SUBSCRIPTION_DONE',
    subscription,
    kind,
  }) as const;
const createGoaltenderSubActionFailed = (subscription: GoaltenderSubscription, error: Error) =>
  ({
    type: 'integrations/CREATE_GOALTENDER_SUBSCRIPTION_FAILED',
    subscription,
    error,
  }) as const;

export function useCreateGoaltenderSubscription() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (
    subscription: GoaltenderSubscription,
    options: IntegrationRequestOptions & { kind?: string } = {},
  ): Promise<GoaltenderSubscription> => {
    dispatch(createGoaltenderSubAction(subscription));
    return new Promise(async (resolve, reject) =>
      IntegrationAPI.createGoaltenderSubscription(subscription).then(
        (created) => {
          options.pathOnDone && navigate({ pathname: options.pathOnDone });
          dispatch(createGoaltenderSubActionDone(created, options.kind));
          resolve(created);
        },
        (error) => {
          if (error.get('status') === 403) {
            options.pathOnDone && navigate({ pathname: options.pathOnDone });
          }
          dispatch(createGoaltenderSubActionFailed(subscription, error));
          reject(error);
        },
      ),
    );
  };
}

const deleteGoaltenderOAuthTokenAction = (integrationKey?: string) => ({
  type: actionTypes.DELETE_GOALTENDER_OAUTH_TOKEN,
  integrationKey,
});
const deleteGoaltenderOAuthTokenActionDone = (integrationKey?: string) => ({
  type: actionTypes.DELETE_GOALTENDER_OAUTH_TOKEN_DONE,
  integrationKey,
});
const deleteGoaltenderOAuthTokenActionFailed = (error: Error, integrationKey?: string) => ({
  type: actionTypes.DELETE_GOALTENDER_OAUTH_TOKEN_FAILED,
  integrationKey,
  error,
});

function deleteGoaltenderOAuthToken(integrationKey: string) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(deleteGoaltenderOAuthTokenAction(integrationKey));
    return new Promise(async (resolve, reject) =>
      IntegrationAPI.deleteGoaltenderOAuthToken(integrationKey).then(
        (deleted) => {
          dispatch(deleteGoaltenderOAuthTokenActionDone(integrationKey));
          resolve(deleted);
        },
        (error) => {
          dispatch(deleteGoaltenderOAuthTokenActionFailed(error, integrationKey));
          reject(error);
        },
      ),
    );
  };
}

const sendGoaltenderTestEventAction = (subscription: GoaltenderSubscription) => ({
  type: actionTypes.SEND_GOALTENDER_TEST_EVENT,
  subscription,
});

type GoaltenderTestEventResult = {
  subscription: GoaltenderSubscription;
  statusCode?: number;
  responseBody?: string;
  timestamp?: number;
  success?: boolean;
  updateStats: boolean;
  errorMessage?: string;
  error?: Error;
};

const sendGoaltenderTestEventActionDone = (result: GoaltenderTestEventResult) => ({
  type: actionTypes.SEND_GOALTENDER_TEST_EVENT_DONE,
  subscription: result.subscription,
  statusCode: result.statusCode,
  responseBody: result.responseBody,
  timestamp: result.timestamp,
  success: result.success,
  updateStats: result.updateStats,
});
const sendGoaltenderTestEventActionFailed = (result: GoaltenderTestEventResult) => ({
  type: actionTypes.SEND_GOALTENDER_TEST_EVENT_FAILED,
  errorMessage: result.errorMessage,
  subscription: result.subscription,
  statusCode: result.statusCode,
  responseBody: result.responseBody,
  timestamp: result.timestamp,
  success: result.success,
  updateStats: result.updateStats,
});

function sendGoaltenderTestEvent(subscription: GoaltenderSubscription) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(sendGoaltenderTestEventAction(subscription));
    return IntegrationAPI.sendGoaltenderTestEvent(subscription)
      .then((response) => {
        const { success, errorMessage, responseBody, timestamp, statusCode } = response;
        const actionPayload = { subscription, statusCode, responseBody, timestamp, success, updateStats: true };

        if (success) {
          dispatch(sendGoaltenderTestEventActionDone({ ...actionPayload }));
        } else {
          dispatch(sendGoaltenderTestEventActionFailed({ ...actionPayload, errorMessage }));
        }
      })
      .catch((error) => {
        dispatch(sendGoaltenderTestEventActionFailed({ subscription, error, updateStats: false }));
      });
  };
}

const createGoaltenderOAuthTokenActionDone = (integrationKey?: string) => ({
  type: actionTypes.CREATE_GOALTENDER_OAUTH_TOKEN_DONE,
  integrationKey,
});

function createGoaltenderOAuthToken(integrationKey?: string) {
  return (dispatch: GlobalDispatch) => {
    dispatch(createGoaltenderOAuthTokenActionDone(integrationKey));
  };
}

// these are only used for type safety in the reducer
const updateGoaltenderSubAction = (old: GoaltenderSubscription, newSub: GoaltenderSubscription) =>
  ({
    type: 'integrations/UPDATE_GOALTENDER_SUBSCRIPTION',
    old,
    new: newSub,
  }) as const;
const updateGoaltenderSubActionDone = (subscription: GoaltenderSubscription) =>
  ({
    type: 'integrations/UPDATE_GOALTENDER_SUBSCRIPTION_DONE',
    subscription,
  }) as const;
const updateGoaltenderSubActionFailed = (subscription: GoaltenderSubscription, error: Error) =>
  ({
    type: 'integrations/UPDATE_GOALTENDER_SUBSCRIPTION_FAILED',
    subscription,
    error,
  }) as const;

export function useUpdateGoaltenderSubscription() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async function (
    oldSubscription: GoaltenderSubscription,
    newSubscription: GoaltenderSubscription,
    options: IntegrationRequestOptions = {},
  ): Promise<GoaltenderSubscription> {
    return updateIntegrationBase<GoaltenderSubscription>(
      [
        'integrations/UPDATE_GOALTENDER_SUBSCRIPTION',
        'integrations/UPDATE_GOALTENDER_SUBSCRIPTION_DONE',
        'integrations/UPDATE_GOALTENDER_SUBSCRIPTION_FAILED',
      ],
      'subscription',
      IntegrationAPI.updateGoaltenderSubscription,
      oldSubscription,
      newSubscription,
      navigate,
      dispatch,
      options,
    ) as Promise<GoaltenderSubscription>;
  };
}

const deleteGoaltenderSubAction = (subscription: GoaltenderSubscription) => ({
  type: actionTypes.DELETE_GOALTENDER_SUBSCRIPTION,
  subscription,
});
const deleteGoaltenderSubActionDone = (subscription: GoaltenderSubscription) => ({
  type: actionTypes.DELETE_GOALTENDER_SUBSCRIPTION_DONE,
  subscription,
});
const deleteGoaltenderSubActionFailed = (subscription: GoaltenderSubscription, error: Error) => ({
  type: actionTypes.DELETE_GOALTENDER_SUBSCRIPTION_FAILED,
  subscription,
  error,
});

function deleteGoaltenderSubscription(subscription: GoaltenderSubscription) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(deleteGoaltenderSubAction(subscription));
    return IntegrationAPI.deleteGoaltenderSubscription(subscription)
      .then(() => {
        dispatch(deleteGoaltenderSubActionDone(subscription));
      })
      .catch((error) => {
        dispatch(deleteGoaltenderSubActionFailed(subscription, error));
      });
  };
}

const createTriggerAction = (args: TriggerCreateArgs) => ({
  type: actionTypes.CREATE_TRIGGER,
  triggerCreateArgs: args,
});
const createTriggerActionDone = (trigger: TriggerType) => ({
  type: actionTypes.CREATE_TRIGGER_DONE,
  trigger,
});
const createTriggerActionFailed = (error: Error) => ({
  type: actionTypes.CREATE_TRIGGER_FAILED,
  error,
});

function createTrigger(triggerCreateArgs: TriggerCreateArgs) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(createTriggerAction(triggerCreateArgs));
    return new Promise(async (resolve, reject) =>
      IntegrationAPI.createTrigger(triggerCreateArgs).then(
        (created) => {
          dispatch(createTriggerActionDone(created));
          resolve(created);
        },
        (error) => {
          dispatch(createTriggerActionFailed(error));
          reject(error);
        },
      ),
    );
  };
}

const deleteTriggerAction = (trigger: TriggerType) => ({ type: actionTypes.DELETE_TRIGGER, trigger });
const deleteTriggerActionDone = (trigger: TriggerType) => ({
  type: actionTypes.DELETE_TRIGGER_DONE,
  trigger,
});
const deleteTriggerActionFailed = (trigger: TriggerType, error: Error) => ({
  type: actionTypes.DELETE_TRIGGER_FAILED,
  trigger,
  error,
});

// this is used to clear all triggers from the redux state when a flag is deleted
export const deleteAllTriggersAction = () => ({
  type: actionTypes.DELETE_ALL_TRIGGERS,
});

function deleteTrigger(trigger: TriggerType) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(deleteTriggerAction(trigger));
    return new Promise(async (resolve, reject) =>
      IntegrationAPI.deleteTrigger(trigger).then(
        () => {
          dispatch(deleteTriggerActionDone(trigger));
          resolve(undefined);
        },
        (error) => {
          dispatch(deleteTriggerActionFailed(trigger, error));
          reject(error);
        },
      ),
    );
  };
}

const fetchTriggersAction = (flagEnv: IntegrationAPI.FlagInfo) => ({ type: actionTypes.FETCH_TRIGGERS, flagEnv });
const fetchTriggersActionDone = (triggers: TriggerType[]) => ({
  type: actionTypes.FETCH_TRIGGERS_DONE,
  triggers,
});
const fetchTriggersActionFailed = (flagEnv: IntegrationAPI.FlagInfo, error: Error) => ({
  type: actionTypes.FETCH_TRIGGERS_FAILED,
  flagEnv,
  error,
});

function fetchTriggers(flagInfo: IntegrationAPI.FlagInfo, triggerManifests: LaunchDarklyIntegrationsManifest[]) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(fetchTriggersAction(flagInfo));
    return new Promise(async (resolve, reject) =>
      IntegrationAPI.getTriggers(flagInfo, triggerManifests).then(
        (fetched) => {
          dispatch(fetchTriggersActionDone(fetched));
          resolve(fetched);
        },
        (error) => {
          dispatch(fetchTriggersActionFailed(flagInfo, error));
          reject(error);
        },
      ),
    );
  };
}

const patchTriggerAction = (trigger: TriggerType, kind: string) => ({ type: actionTypes.PATCH_TRIGGER, trigger, kind });
const patchTriggerActionDone = (trigger: TriggerType, kind: string) => ({
  type: actionTypes.PATCH_TRIGGER_DONE,
  trigger,
  kind,
});
const patchTriggerActionFailed = (trigger: TriggerType, kind: string, error: Error) => ({
  type: actionTypes.PATCH_TRIGGER_DONE,
  trigger,
  kind,
  error,
});

function patchTrigger(trigger: TriggerType, kind: string) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(patchTriggerAction(trigger, kind));
    return new Promise(async (resolve, reject) =>
      IntegrationAPI.patchTrigger(trigger, kind, trigger._manifest).then(
        (updated) => {
          dispatch(patchTriggerActionDone(updated, kind));
          resolve(updated);
        },
        (error) => {
          dispatch(patchTriggerActionFailed(trigger, kind, error));
          reject(error);
        },
      ),
    );
  };
}

const clearCreatedTriggersAction = () => ({ type: actionTypes.CLEAR_CREATED_TRIGGERS });

function clearCreatedTriggers() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(clearCreatedTriggersAction());
    return new Promise((resolve) => {
      resolve(undefined);
    });
  };
}

const fetchDynamicEnum = (integrationKey: string, enumKey: string) => ({
  type: actionTypes.FETCH_GOALTENDER_DYNAMIC_ENUM_OPTIONS,
  integrationKey,
  enumKey,
});
const fetchDynamicEnumDone = (integrationKey: string, enumKey: string, dynamicEnumOptions: DynamicEnumOption[]) => ({
  type: actionTypes.FETCH_GOALTENDER_DYNAMIC_ENUM_OPTIONS_DONE,
  dynamicEnumOptions,
  integrationKey,
  enumKey,
});
const fetchDynamicEnumFailed = (integrationKey: string, enumKey: string, error: Error) => ({
  type: actionTypes.FETCH_GOALTENDER_DYNAMIC_ENUM_OPTIONS_FAILED,
  error,
  integrationKey,
  enumKey,
});

function fetchDynamicEnumOptions(integrationKey: string, enumKey: string) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(fetchDynamicEnum(integrationKey, enumKey));
    return new Promise(async (resolve, reject) =>
      IntegrationAPI.getDynamicEnumOptions(integrationKey, enumKey).then(
        (updated) => {
          dispatch(fetchDynamicEnumDone(integrationKey, enumKey, updated));
          resolve(updated);
        },
        (error) => {
          dispatch(fetchDynamicEnumFailed(integrationKey, enumKey, error));
          reject(error);
        },
      ),
    );
  };
}

const mapExtApprovalMembersAction = (integrationKey: string) => ({
  type: actionTypes.MAP_EXTERNAL_APPROVAL_MEMBERS,
  integrationKey,
});
const mapExtApprovalMembersActionDone = (integrationKey: string) => ({
  type: actionTypes.MAP_EXTERNAL_APPROVAL_MEMBERS_DONE,
  integrationKey,
});
const mapExtApprovalMembersActionFailed = (integrationKey: string, error: Error) => ({
  type: actionTypes.MAP_EXTERNAL_APPROVAL_MEMBERS_FAILED,
  error,
  integrationKey,
});

function mapApprovalMembers(integrationKey: string) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(mapExtApprovalMembersAction(integrationKey));
    return new Promise(async (resolve, reject) =>
      IntegrationAPI.mapApprovalMembers(integrationKey).then(
        () => {
          dispatch(mapExtApprovalMembersActionDone(integrationKey));
          resolve(undefined);
        },
        (error) => {
          dispatch(mapExtApprovalMembersActionFailed(integrationKey, error));
          reject(error);
        },
      ),
    );
  };
}

const setStickyFiltersToCache = (projKey: string, searchText: string) =>
  save(load().setIn(['stickyFilters', projKey, 'integrations'], searchText));

const getStickyIntegrationFiltersFromCache = (projKey: string) => {
  const stickyFiltersForProject = load().get('stickyFilters').get(projKey);
  if (!stickyFiltersForProject) {
    return '';
  }
  return stickyFiltersForProject.get('integrations');
};

const setStickyIntegrationFilters = (projKey: string, searchText: string) => {
  setStickyFiltersToCache(projKey, searchText);
  return {
    type: actionTypes.SET_STICKY_INTEGRATION_FILTERS,
    stickySearchText: getStickyIntegrationFiltersFromCache(projKey),
  };
};

const getStickyIntegrationFilters = (projKey: string) => (dispatch: GlobalDispatch) => {
  const filtersFromStorage = getStickyIntegrationFiltersFromCache(projKey);
  dispatch({
    type: actionTypes.GET_STICKY_INTEGRATION_FILTERS,
    stickySearchText: filtersFromStorage,
  });
};

const RepositoryActionCreators = {
  fetchRepositoryAction,
  fetchRepositoryActionDone,
  fetchRepositoryActionFailed,
  updateRepositoryAction,
  updateRepositoryActionDone,
  updateRepositoryActionFailed,
  deleteRepoAction,
  deleteRepoActionDone,
  deleteRepoActionFailed,
};
export type RepositoryAction = GenerateActionType<typeof RepositoryActionCreators>;

const DestinationActionCreators = {
  fetchDestinationsAction,
  fetchDestinationsDone,
  fetchDestinationsFailed,
  createDestinationAction,
  createDestinationActionDone,
  createDestinationActionFailed,
  updateDestinationAction,
  updateDestinationActionDone,
  updateDestinationActionFailed,
  deleteDestinationAction,
  deleteDestinationActionDone,
  deleteDestinationActionFailed,
  sendDestinationTestAction,
  sendDestinationTestActionDone,
  sendDestinationTestActionFailed,
};
export type DestinationAction = GenerateActionType<typeof DestinationActionCreators>;

const SlackHookActionCreators = {
  requestSlackHookAction,
  requestSlackHookActionDone,
  requestSlackHookActionFailed,
  createSlackHookAction,
  createSlackHookActionDone,
  createSlackHookActionFailed,
  updateSlackHookAction,
  updateSlackHookActionDone,
  updateSlackHookActionFailed,
  deleteSlackHookAction,
  deleteSlackHookActionDone,
  deleteSlackHookActionFailed,
};
export type SlackHookAction = GenerateActionType<typeof SlackHookActionCreators>;

const GoaltenderSubscriptionActionCreators = {
  fetchGoaltenderAction,
  fetchGoaltenderActionDone,
  fetchGoaltenderActionFailed,
  requestGoaltenderManifests,
  requestGoaltenderManifestsDone,
  requestGoaltenderManifestsFailed,
  goaltenderManifestsFetchRequest,
  goaltenderManifestsFetchRequestDone,
  goaltenderManifestsFetchRequestFailed,
  requestGoaltenderSubDoneNoCapabilities,
  createRequestGoaltenderSubsAction,
  createRequestGoaltenderSubsActionDone,
  createRequestGoaltenderSubsActionFailed,
  createGoaltenderSubAction,
  createGoaltenderSubActionDone,
  createGoaltenderSubActionFailed,
  deleteGoaltenderOAuthTokenAction,
  deleteGoaltenderOAuthTokenActionDone,
  deleteGoaltenderOAuthTokenActionFailed,
  sendGoaltenderTestEventAction,
  sendGoaltenderTestEventActionDone,
  sendGoaltenderTestEventActionFailed,
  createGoaltenderOAuthTokenActionDone,
  updateGoaltenderSubAction,
  updateGoaltenderSubActionDone,
  updateGoaltenderSubActionFailed,
  deleteGoaltenderSubAction,
  deleteGoaltenderSubActionDone,
  deleteGoaltenderSubActionFailed,
  fetchDynamicEnum,
  fetchDynamicEnumDone,
  fetchDynamicEnumFailed,
};
export type GoaltenderSubAction = GenerateActionType<typeof GoaltenderSubscriptionActionCreators>;

const TriggersActionCreators = {
  createTriggerAction,
  createTriggerActionDone,
  createTriggerActionFailed,
  deleteTriggerAction,
  deleteTriggerActionDone,
  deleteTriggerActionFailed,
  fetchTriggersAction,
  fetchTriggersActionDone,
  fetchTriggersActionFailed,
  patchTriggerAction,
  patchTriggerActionDone,
  patchTriggerActionFailed,
  clearCreatedTriggersAction,
  deleteAllTriggersAction,
};
export type TriggersAction = GenerateActionType<typeof TriggersActionCreators>;

export {
  setStickyIntegrationFilters,
  getStickyIntegrationFilters,
  fetchSlackIncomingHooksIfNeeded as fetchSlackIncomingHooks,
  fetchDestinationsIfNeeded as fetchDestinations,
  sendDestinationTestEvent,
  fetchRepositoriesIfNeeded as fetchRepositories,
  fetchGoaltenderManifestsIfNeeded as fetchGoaltenderManifests,
  fetchGoaltenderManifestsAndSubscriptionsIfNeeded as fetchGoaltenderManifestsAndSubscriptions,
  deleteGoaltenderSubscription,
  sendGoaltenderTestEvent,
  deleteGoaltenderOAuthToken,
  createGoaltenderOAuthToken,
  createTrigger,
  deleteTrigger,
  fetchTriggers,
  patchTrigger,
  clearCreatedTriggers,
  fetchDynamicEnumOptions,
  mapApprovalMembers,
};
