import { useLayoutEffect, useState } from 'react';

import { useUpdatingRef } from './useUpdatingRef';

/**
 * This works like "useState", but will keep its state in sync with the external state provided when the external state changes.
 */
export function useExternalState<T>(externalState: T) {
  const [state, setState] = useState(externalState);
  const stateRef = useUpdatingRef(state);
  useLayoutEffect(() => {
    if (stateRef.current !== externalState) {
      setState(externalState);
    }
  }, [stateRef, externalState]);
  return [state, setState] as const;
}
