import { constants } from 'utils/reduxUtils';

/* eslint-disable import/no-default-export */
export default constants('environments', [
  'REQUEST_CURRENT_PROJECT_AND_ENVIRONMENT',
  'REQUEST_CURRENT_PROJECT_AND_ENVIRONMENT_DONE',
  'REQUEST_CURRENT_PROJECT_AND_ENVIRONMENT_FAILED',
  'SELECT_ENVIRONMENT',
  'SELECT_ENVIRONMENT_FAILED',
  'CREATE_ENVIRONMENT',
  'CREATE_ENVIRONMENT_DONE',
  'CREATE_ENVIRONMENT_FAILED',
  'DELETE_ENVIRONMENT',
  'DELETE_ENVIRONMENT_DONE',
  'DELETE_ENVIRONMENT_FAILED',
  'EDIT_ENVIRONMENT',
  'CHECK_ACCESS_RESOURCE',
  'RESET_API_KEY',
  'RESET_API_KEY_DONE',
  'RESET_API_KEY_FAILED',
  'RESET_MOBILE_KEY',
  'RESET_MOBILE_KEY_DONE',
  'RESET_MOBILE_KEY_FAILED',
  'UPDATE_ENVIRONMENT',
  'UPDATE_ENVIRONMENT_DONE',
  'UPDATE_ENVIRONMENT_FAILED',
  'FILTER_ENVIRONMENTS_BY_TEXT',
  'FOLLOW_ENVIRONMENT',
  'FOLLOW_ENVIRONMENT_DONE',
  'FOLLOW_ENVIRONMENT_FAILED',
]);
