import { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { components, MenuPlacement, MenuProps, MultiValueProps } from 'react-select';
import { isArray } from '@gonfalon/es6-utils';
import { CustomSelect, OptionProps } from '@gonfalon/launchpad-experimental';
import { toViewCustomRole } from '@gonfalon/navigator';
import { Icon } from '@launchpad-ui/icons';
import { OrderedMap, Set } from 'immutable';

import { sortMemberPermissions } from 'utils/memberUtils';
import { Role } from 'utils/roleUtils';
import { stringContains } from 'utils/stringUtils';

import styles from './SelectRole.module.css';

export type SelectRoleProps = {
  disabled?: boolean;
  showDetailsLink?: boolean;
  roles: OrderedMap<string, Role>;
  selectedRoles?: Role[];
  // custom role keys to filter out of the menu options
  filterRoles?: Set<string>;
  isLoading: boolean;
  onChange(roles?: Role[] | Role): void;
  fetchRoles(): void;
  isMulti?: boolean;
  menuPlacement?: MenuPlacement;
};

export const renderOption = (option: OptionProps, disabled?: boolean, showDetailsLink?: boolean) => (
  <div className="u-flex u-flex-row u-flex-middle u-fs-sm ">
    <div className="u-o-hidden u-to-ellipsis">{option.label}</div>
    {!disabled && showDetailsLink && option.key && (
      <Link to={toViewCustomRole({ roleKey: option.key })} className="u-flex-none u-pullright" target="_blank">
        Details
        <Icon name="link-external" className={styles.icon} size="small" />
      </Link>
    )}
  </div>
);

function SelectRole({
  disabled,
  roles,
  isLoading,
  selectedRoles,
  onChange,
  fetchRoles,
  filterRoles,
  showDetailsLink,
  isMulti = true,
  menuPlacement,
  ...other
}: SelectRoleProps) {
  const options = isLoading
    ? []
    : roles
        .toList()
        .sort((a, b) => sortMemberPermissions(a, b))
        .map((role) => ({
          value: role.key,
          label: role.name,
          key: role.key,
        }));

  const selectedOptions = isLoading
    ? []
    : selectedRoles
        ?.sort((a, b) => sortMemberPermissions(a, b))
        .map((role) =>
          role?.key
            ? {
                value: role.key,
                label: role.name,
                key: role.key,
              }
            : undefined,
        );
  const MultiValue = (multiValueProps: MultiValueProps<OptionProps>) => (
    <div
      role="presentation"
      onMouseDown={(event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <components.MultiValue {...multiValueProps}>
        {renderOption(multiValueProps.data, disabled, showDetailsLink)}
      </components.MultiValue>
    </div>
  );

  const Menu = (menuProps: MenuProps<OptionProps>) => (
    <components.Menu {...menuProps}>{menuProps.children}</components.Menu>
  );

  const filterOption = ({ data }: OptionProps, searchValue: string) =>
    !filterRoles?.includes(data.key) &&
    (stringContains(data.label, searchValue) || stringContains(data.key, searchValue));

  const handleChange = (option: OptionProps | null) => {
    // multi-selection
    if (isArray(option)) {
      const roleArray = option.map((data: OptionProps) => roles.get(data.value));
      onChange(roleArray as Role[]);
      return;
    }

    // singular selection
    if (option) {
      const role = roles.get(option.value);
      onChange(role as Role);
    }
  };

  const customStyles = {
    control: { padding: '0.125rem 0' },
    valueContainer: { maxHeight: '10.625rem', overflow: 'auto', padding: '0.125rem 0.1875rem' },
    multiValue: {
      alignItems: 'center',
      background: 'var(--lp-color-bg-interactive-secondary-hover)',
      borderRadius: '0.5rem',
      height: '1.75rem',
      maxWidth: '32.5rem',
      marginLeft: '0.1875rem',
      paddingLeft: '0.0625rem',
    },
    multiValueLabel: {},
    multiValueRemove: {
      borderRadius: '0 0.5rem 0.5rem 0',
      height: '100%',
      display: disabled ? 'none' : 'flex',
    },
  };

  const placeholderLabel = isMulti ? 'Select roles' : 'Select a role';

  return (
    <CustomSelect
      {...other}
      ariaLabel={selectedOptions?.flatMap((o) => `${o?.label}`).join(', ') || placeholderLabel}
      placeholder={placeholderLabel}
      styles={customStyles}
      customComponents={{ MultiValue, Menu }}
      disabled={disabled}
      noOptionsMessage={() => (isLoading ? 'Loading' : 'No roles found')}
      value={selectedOptions}
      options={options}
      isClearable={false}
      isMulti={isMulti}
      onChange={handleChange}
      formatOptionLabel={(option) => option && renderOption(option, disabled)}
      getOptionLabel={(option: OptionProps) => (option ? String(option.label) : '')}
      filterOption={filterOption}
      menuPlacement={menuPlacement}
    />
  );
}

/* eslint-disable import/no-default-export */
export default SelectRole;
