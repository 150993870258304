import { ModernSubscription, SubscriptionStates } from '../../types';

export function hasSubscription(modernSubscription: ModernSubscription) {
  const { state } = modernSubscription;
  return [
    SubscriptionStates.ACTIVE_SUBSCRIPTION,
    SubscriptionStates.ACTIVE_TRIAL_WITH_SUBSCRIPTION,
    SubscriptionStates.FAILED_PAYMENT_SUBSCRIPTION,
    SubscriptionStates.LAPSED_SUBSCRIPTION,
  ].includes(state as SubscriptionStates);
}
