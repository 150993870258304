import { MemberOptionType } from 'components/SelectMember';
import UserAvatar from 'components/UserAvatar';

type SelectMemberOptionProps = {
  isInMenu?: boolean;
  isMulti?: boolean;
  option: MemberOptionType;
};

// This component controls the "option" within a select / dropdown for Members.
// • `isInMenu`: what we show when the dropdown is open and they're selecting an option
//   otherwise, what we show when the dropdown isn't open/active
// • `isMulti`: what we show when there are multiple options that can be selected

const SelectMemberOption = ({ isInMenu, isMulti, option }: SelectMemberOptionProps) => {
  const { email, label, member, name } = option;
  const getLabelContents = () => {
    if (isInMenu) {
      return isMulti && name && email ? (
        <>
          <div className="u-fs-sm-even">{name}</div>
          <div className="u-fs-xs-even u-color-text-ui-tertiary ">{email}</div>
        </>
      ) : (
        // by including `label` here, we render "Me" in the dropdown for the current user's profile
        label || name || email
      );
    }
    return name || email;
  };

  const labelWithWrapper = <div className="u-o-hidden u-to-ellipsis">{getLabelContents()}</div>;

  return (
    <div className="u-flex u-flex-middle u-gap-1 u-fs-sm u-mw-100">
      {member && <UserAvatar member={member} size={isInMenu ? 'medium' : 'small'} />}
      {labelWithWrapper}
    </div>
  );
};

export function getMemberOptionStringLabel(option: MemberOptionType) {
  return [option.name, option.email].filter(Boolean).join(': ');
}

/* eslint-disable import/no-default-export */
export default SelectMemberOption;
