import { FocusEvent, FormEvent } from 'react';
import classNames from 'clsx';

import './styles/Form.css';

type FormProps = {
  id?: string;
  name?: string;
  action?: string;
  className?: string;
  inline?: boolean;
  method?: string;
  // Increases margin between form fields to make room for error messages.
  // This prevents the form from shifting when rendering a field error.
  // This may be desired when the form contains external links that will
  // shift while clicking if the form shifts from validation.
  hasIncreasedErrorMargin?: boolean;
  onBlur?(e: FocusEvent): void;
  onBlurCapture?(e: FocusEvent): void;
  onSubmit?(e: FormEvent<EventTarget>): void;
  ariaLabel?: string;
  children: React.ReactNode;
};

export function Form(props: FormProps) {
  const { id, name, className, inline, children, ariaLabel, hasIncreasedErrorMargin, ...finalProps } = props;
  const classes = classNames('Form', className, {
    'Form--inline': inline,
    'Form--increasedErrorMargin': !!hasIncreasedErrorMargin,
  });

  return (
    <form id={id} name={name} aria-label={ariaLabel} {...finalProps} className={classes}>
      {children}
    </form>
  );
}
