import { kebabCase } from '@gonfalon/es6-utils';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, OrderedMap, Record } from 'immutable';
import { AnyAction, combineReducers } from 'redux';
import { createSelector, createStructuredSelector } from 'reselect';

import formActionTypes from 'actionTypes/forms';
import { GlobalState } from 'reducers';
import { createRequestReducer, createRequestReducerByKey } from 'reducers/createRequestReducer';
import registry from 'reducers/registry';
import textFilter from 'reducers/textFilter';
import { createFormState, FormState } from 'utils/formUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { createPagination } from 'utils/paginationUtils';
import { ready } from 'utils/reduxUtils';
import { createRequest, Request, RequestAction } from 'utils/requestUtils';
import {
  BigSegmentTarget,
  createBigSegmentIndividualTargetUpdates,
  createSegment,
  createSegmentForm,
  RelatedFlag,
  Segment,
} from 'utils/segmentUtils';

import {
  UPLOAD_CSV_TO_SEGMENT_IMPORT_STARTED,
  UPLOAD_CSV_TO_SEGMENT_MODAL_CLOSED,
  UPLOAD_CSV_TO_SEGMENT_MODAL_OPENED,
  UPLOAD_CSV_TO_SEGMENT_UPLOAD_FAILED,
  UPLOAD_CSV_TO_SEGMENT_UPLOAD_STARTED,
} from '../actionTypes/segments';

const segmentInitialFormState = (segment = createSegmentForm()) => createFormState(segment);
const segmentEmptyFormState = createFormState(createSegmentForm());

type SegmentFormState = FormState<Segment>;

const handleEdit = (state: SegmentFormState, action: AnyAction) => {
  const { field, value } = action;
  let modified = state.modified.set(field, value);

  if (field === 'name' && !state.wasChanged('key')) {
    modified = modified.set('key', kebabCase(value));
  }

  let newState = state;
  if (field === 'tags') {
    newState = newState.hitField(field);
  }

  return newState.trackField(field).revalidate(modified);
};

const creationForm = (state = segmentEmptyFormState, action: AnyAction) => {
  if (action.model && action.model !== 'createSegmentForm') {
    return state;
  }
  switch (action.type) {
    case formActionTypes.INITIALIZE:
      return segmentInitialFormState(action.initialState);
    case 'segments/EDIT_SEGMENT':
      return handleEdit(state, action);
    case 'segments/CREATE_SEGMENT':
      return state.submitting();
    case 'segments/CREATE_SEGMENT_DONE':
    case formActionTypes.DESTROY:
      return segmentEmptyFormState;
    case 'segments/CREATE_SEGMENT_FAILED':
      return state.submitFailed(action.segment, action.error, false);
    case formActionTypes.BLUR:
      return state.handleBlur(action.field, state.modified);
    default:
      return state;
  }
};

const settingsFormInitialState = (segment = createSegment()) => createFormState(segment);

const settingsForm = (state = settingsFormInitialState(), action: AnyAction) => {
  if (action.model && action.model !== 'segmentSettingsForm') {
    return state;
  }

  switch (action.type) {
    case 'segments/EDIT_SEGMENT_SETTINGS':
      return state.trackField(action.field).revalidate(state.modified.set(action.field, action.value));
    case 'segments/UPDATE_SEGMENT_SETTINGS':
      return state.submitting();
    case 'segments/UPDATE_SEGMENT_SETTINGS_DONE':
      return settingsFormInitialState(action.segment);
    case 'segments/UPDATE_SEGMENT_SETTINGS_FAILED':
      return state.submitFailed(action.segment, action.error, false);
    case formActionTypes.INITIALIZE:
      return settingsFormInitialState(action.initialState);
    case formActionTypes.BLUR:
      return state.handleBlur(action.field, state.modified);
    case formActionTypes.DESTROY:
      return settingsFormInitialState();
    default:
      return state;
  }
};

const entities = (state: OrderedMap<string, Segment> = OrderedMap(), action: AnyAction) => {
  if (action.type === 'segments/REQUEST_SEGMENTS_DONE') {
    return action.response.getIn(['entities', 'segments']);
  }

  if (action.type === 'segments/CREATE_SEGMENT_DONE') {
    return state.set(action.segment.key, action.segment).sort((a, b) => b.creationDate - a.creationDate);
  }

  if (
    action.type === 'segments/REQUEST_SEGMENT_BY_KEY_DONE' ||
    action.type === 'segments/UPDATE_SEGMENT_TARGETING_DONE' ||
    action.type === 'segments/REQUEST_SEGMENT_RELATED_FLAGS_DONE' ||
    action.type === 'segments/UPDATE_SEGMENT_SETTINGS_DONE'
  ) {
    return state.set(action.segment.key, action.segment);
  }

  if (action.type === 'segments/UPDATE_BIG_SEGMENT_INDIVIDUAL_TARGETS_DONE') {
    const segment = state.get(action.segmentKey);
    if (segment) {
      return state.set(
        action.segmentKey,
        segment.updateBigSegmentTargetCount({
          addedCount: action.updates.added.size,
          removedCount: action.updates.removed.size,
        }),
      );
    }
  }

  if (action.type === 'segments/DELETE_SEGMENT_DONE') {
    return state.delete(action.segment.key);
  }

  return state;
};

const listRequest = (state = createRequest(), action: RequestAction) => {
  switch (action.type) {
    case 'segments/REQUEST_SEGMENTS':
      return state.start();
    case 'segments/REQUEST_SEGMENTS_FAILED':
      return state.failed(action);
    case 'segments/REQUEST_SEGMENTS_DONE':
      return state.done(action);
    default:
      return state;
  }
};

const pagination = (state = createPagination(), action: AnyAction) => {
  switch (action.type) {
    case 'segments/REQUEST_SEGMENTS_DONE':
      const links = action.response.getIn(['result', '_links']);
      const totalCount = action.response.getIn(['result', 'totalCount']);
      return createPagination(
        fromJS({
          nextPageUrl: links.getIn(['next', 'href']),
          prevPageUrl: links.getIn(['prev', 'href']),
          totalCount,
        }),
      );
    case 'segments/REQUEST_SEGMENTS':
    case 'segments/REQUEST_SEGMENTS_FAILED':
    default:
      return state;
  }
};

const requestsByKey = (state: Map<string, Request> = Map(), action: RequestAction) => {
  switch (action.type) {
    case 'segments/REQUEST_SEGMENT_BY_KEY':
      return state.set(
        action.segmentKey,
        createRequest({
          isFetching: true,
        }),
      );
    case 'segments/REQUEST_SEGMENT_BY_KEY_FAILED':
      return state.update(action.segmentKey, createRequest(), (req) =>
        req.merge({
          isFetching: false,
          lastFetched: action.timestamp,
          error: action.error,
        }),
      );
    case 'segments/REQUEST_SEGMENT_BY_KEY_DONE':
      return state.update(action.segmentKey, createRequest(), (req) =>
        req.merge({
          isFetching: false,
          lastFetched: action.timestamp,
          error: null,
        }),
      );
    case 'segments/REQUEST_SEGMENTS_DONE':
      const doneReq = createRequest().done(action);

      return state.withMutations((st) => {
        const segments = action.response.getIn(['entities', 'segments']);
        segments.forEach((segment: Segment) => {
          st.set(segment.key, doneReq);
        });
        return st;
      });
    case 'segments/CREATE_SEGMENT_DONE':
      return state.update(action.segment.key, createRequest(), (req) =>
        req.merge({
          isFetching: false,
          lastFetched: action.timestamp,
          error: null,
        }),
      );
    case 'segments/DELETE_SEGMENT_DONE':
      return state.delete(action.segment.key);
    default:
      return state;
  }
};

const filterSegmentType = (state: string = '', action: AnyAction) => {
  switch (action.type) {
    case 'segments/FILTER_SEGMENTS_TYPE':
      return action.segmentType;
    default:
      return state;
  }
};

const filterSegmentText = textFilter({
  type: 'segments/FILTER_SEGMENTS_TEXT',
  props: ['name', 'key'],
});

type SegmentTargetingManagerState = ImmutableMap<{
  original?: Segment;
  modified?: Segment;
  isSaving: boolean;
  error?: Error | null;
  isUpdateComplete: boolean;
}>;

const targetingManagerInitialState: SegmentTargetingManagerState = Map({
  original: null,
  modified: null,
  isSaving: false,
  error: null,
  isUpdateComplete: false,
});

const targetingManager = (state = targetingManagerInitialState, action: AnyAction) => {
  if (
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    action.segment &&
    state.get('original') &&
    state.get('original')!.key !== action.segment.key /* eslint-enable @typescript-eslint/no-non-null-assertion */
  ) {
    return state;
  }

  switch (action.type) {
    case 'segments/INITIALIZE_SEGMENT_TARGETING_MANAGER':
      return targetingManagerInitialState.merge({
        original: action.segment,
        modified: action.segment,
      });
    case 'segments/DESTROY_SEGMENT_TARGETING_MANAGER':
      return targetingManagerInitialState;
    case 'segments/RESET_SEGMENT_TARGETING_MANAGER':
      return targetingManagerInitialState.merge({
        original: state.get('original'),
        modified: state.get('original'),
      });
    case 'segments/CHANGE_SEGMENT_TARGETING':
      return state.set('modified', action.segment);
    case 'segments/UPDATE_SEGMENT_TARGETING':
      return state.merge({
        isSaving: true,
        error: null,
      });
    case 'segments/UPDATE_SEGMENT_TARGETING_FAILED':
      return state.merge({
        error: action.error,
        isSaving: false,
      });
    case 'segments/UPDATE_SEGMENT_TARGETING_DONE':
      return targetingManagerInitialState.merge({
        original: action.segment,
        modified: action.segment,
        isUpdateComplete: true,
      });
    case 'segments/COMMIT_DISCARDED_PENDING_CHANGES':
      return targetingManagerInitialState.merge({
        original: state.get('original'),
        modified: action.segment,
      });
    default:
      return state;
  }
};

const relatedFlagsRequestByKey = (state: Map<string, Request> = Map(), action: AnyAction) => {
  if (action.type === 'segments/DESTROY_SEGMENT_TARGETING_MANAGER') {
    return state.delete(action.segment.key);
  }
  return createRequestReducerByKey(
    [
      'segments/REQUEST_SEGMENT_RELATED_FLAGS',
      'segments/REQUEST_SEGMENT_RELATED_FLAGS_DONE',
      'segments/REQUEST_SEGMENT_RELATED_FLAGS_FAILED',
    ],
    (a) => a.segmentKey,
  )(state, action);
};

const relatedFlagsByKey = (state: OrderedMap<string, List<RelatedFlag>> = OrderedMap(), action: AnyAction) => {
  switch (action.type) {
    case 'segments/REQUEST_SEGMENT_RELATED_FLAGS_DONE':
      return state.set(action.segmentKey, action.segment._flags);
    case 'segments/DESTROY_SEGMENT_TARGETING_MANAGER':
      return state.delete(action.segment.key);
    default:
      return state;
  }
};

const bigSegmentTargets = (state: Map<string, BigSegmentTarget> = Map(), action: AnyAction) => {
  switch (action.type) {
    case 'segments/REQUEST_BIG_SEGMENT_STATUS_DONE':
      return state.set(action.userKey, action.target);
    default:
      return state;
  }
};

export type UploadCsvToSegmentStateType = {
  isModalOpen: boolean;
  isUploading: boolean;
  error: string | null;
};

export const UploadCsvToSegmentInitialState = {
  isModalOpen: false,
  isUploading: false,
  error: null,
};

export class UploadCsvToSegmentState extends Record<UploadCsvToSegmentStateType>(UploadCsvToSegmentInitialState) {}

export const uploadCsvToSegment = (state = new UploadCsvToSegmentState(), action: AnyAction) => {
  switch (action.type) {
    case UPLOAD_CSV_TO_SEGMENT_MODAL_OPENED:
      return state.merge({ isModalOpen: true, error: null });
    case UPLOAD_CSV_TO_SEGMENT_MODAL_CLOSED:
      return state.merge({ isModalOpen: false, error: null });
    case UPLOAD_CSV_TO_SEGMENT_UPLOAD_STARTED:
      return state.merge({ isUploading: true, error: null });
    case UPLOAD_CSV_TO_SEGMENT_UPLOAD_FAILED:
      return state.merge({ isUploading: false, error: action.error.get('message') || `${action.error}` });
    case UPLOAD_CSV_TO_SEGMENT_IMPORT_STARTED:
      return state.merge({ isModalOpen: false, isUploading: false });
    default:
      return state;
  }
};

const bigSegmentFileExportMetaDataRequest = createRequestReducer([
  'segments/REQUEST_BIG_SEGMENT_EXPORT_FILE_METADATA',
  'segments/RECEIVE_BIG_SEGMENT_EXPORT_FILE_METADATA',
  'segments/REQUEST_BIG_SEGMENT_EXPORT_FILE_METADATA_FAILED',
]);

type BigSegmentFileMetaDataStateType = {
  [key: string]: BigSegmentFileMetaData;
};

export const BigSegmentFileMetaDataInitialState = {};

export class BigSegmentFileMetaDataState extends Record<BigSegmentFileMetaDataStateType>(
  BigSegmentFileMetaDataInitialState,
) {}

export const bigSegmentFileExportMetaData = (state = new BigSegmentFileMetaDataState(), action: AnyAction) => {
  switch (action.type) {
    case 'segments/RECEIVE_BIG_SEGMENT_EXPORT_FILE_METADATA':
      Object.assign(state, { [action.exportKey]: action.data });
      return state;
    default:
      return state;
  }
};

export const nativeBigSegmentFormKey = 'bigSegmentIndividualTargetUpdates';

const bigSegmentIndividualTargetUpdates = (
  state = createFormState(createBigSegmentIndividualTargetUpdates()),
  action: RequestAction,
) => {
  switch (action.type) {
    case 'segments/EDIT_BIG_SEGMENT_INDIVIDUAL_TARGETS':
      const modified = state.modified.set(action.field, action.value);
      return state.revalidate(modified).trackField(action.field);
    case 'segments/UPDATE_BIG_SEGMENT_INDIVIDUAL_TARGETS':
      return state.submitting();
    case 'segments/BIG_SEGMENT_INDIVIDUAL_TARGETS_APPROVAL_CREATED':
      return state.submitted(state.original);
    case 'segments/BIG_SEGMENT_INDIVIDUAL_TARGETS_APPROVAL_FAILED':
      return state.submitFailed(state.modified, action.error, true);
    case 'segments/UPDATE_BIG_SEGMENT_INDIVIDUAL_TARGETS_DONE':
      return createFormState(createBigSegmentIndividualTargetUpdates());
    case 'segments/UPDATE_BIG_SEGMENT_INDIVIDUAL_TARGETS_FAILED':
      return state.submitFailed(state.modified, action.error, true);
    case formActionTypes.DESTROY:
      if (action?.model !== nativeBigSegmentFormKey) {
        return state;
      }
      return createFormState(createBigSegmentIndividualTargetUpdates());

    case formActionTypes.INITIALIZE:
      if (action?.model !== nativeBigSegmentFormKey) {
        return state;
      }
      return createFormState(action.initialState);
    default:
      return state;
  }
};

export const bigSegmentIndividualTargetUpdatesSelector = (state: GlobalState) =>
  segmentsSelector(state).bigSegmentIndividualTargetUpdates;

export const segments = combineReducers({
  entities,
  pagination,
  listRequest,
  requestsByKey,
  creationForm,
  settingsForm,
  targetingManager,
  filterSegmentText,
  filterSegmentType,
  relatedFlagsByKey,
  relatedFlagsRequestByKey,
  bigSegmentTargets,
  uploadCsvToSegment,
  bigSegmentFileExportMetaDataRequest,
  bigSegmentFileExportMetaData,
  bigSegmentIndividualTargetUpdates,
});

export const segmentsSelector = (state: GlobalState) => state.segments;
export const segmentCreationFormSelector = (state: GlobalState) => segmentsSelector(state).creationForm;
export const segmentEntitiesSelector = (state: GlobalState) => segmentsSelector(state).entities;
export const segmentListRequestSelector = (state: GlobalState) => segmentsSelector(state).listRequest;
export const segmentListSelector = createSelector(segmentEntitiesSelector, (segmentEntities) =>
  segmentEntities.toList(),
);
export type BigSegmentFileMetaData = {
  fileName: string;
  fileSize: string;
  fileGeneratedBy: string;
  fileGeneratedOn: Date;
  downloadLink: string;
};
export const segmentTextFilterSelector = (state: GlobalState) => segmentsSelector(state).filterSegmentText;
export const segmentTypeFilterSelector = (state: GlobalState) => segmentsSelector(state).filterSegmentType;
export const uploadCsvToSegmentStateSelector = (state: GlobalState) => segmentsSelector(state).uploadCsvToSegment;
export const bigSegmentFileExportMetaDataSelector = (state: GlobalState) =>
  segmentsSelector(state).bigSegmentFileExportMetaData;
export const bigSegmentFileExportMetaDataRequestSelector = (state: GlobalState) =>
  segmentsSelector(state).bigSegmentFileExportMetaDataRequest;

export const filteredSegmentListSelector = createSelector(
  segmentListSelector,
  segmentTextFilterSelector,
  segmentTypeFilterSelector,
  (list, segmentTextFilter, segmentTypeFilter) =>
    list
      .filter(segmentTextFilter.get('predicate'))
      .filter((segment: Segment) => segmentTypeFilter === '' || segment._external === segmentTypeFilter),
);

type SegmentOwnProps = {
  segmentKey: string;
};

const segmentKeyFromProps = (props: SegmentOwnProps) => props.segmentKey;

export const segmentRequestByKeySelector = (state: GlobalState, props: SegmentOwnProps) =>
  segmentsSelector(state).requestsByKey.get(segmentKeyFromProps(props));

export const segmentRelatedFlagsRequestByKeySelector = (state: GlobalState, props: SegmentOwnProps) =>
  segmentsSelector(state).relatedFlagsRequestByKey.get(segmentKeyFromProps(props)) || createRequest();

export const segmentRelatedFlagsByKeySelector = (state: GlobalState, props: SegmentOwnProps) =>
  segmentsSelector(state).relatedFlagsByKey.get(segmentKeyFromProps(props)) || List();

export const segmentEntityByKeySelector = (state: GlobalState, props: SegmentOwnProps) =>
  segmentEntitiesSelector(state).get(segmentKeyFromProps(props));
export const segmentByKeySelector = createStructuredSelector<
  GlobalState,
  SegmentOwnProps,
  { request?: Request; entity: Segment }
>({
  request: segmentRequestByKeySelector,
  entity: segmentEntityByKeySelector,
});

export const segmentSettingsFormSelector = (state: GlobalState) => segmentsSelector(state).settingsForm;

export const segmentTargetingManagerSelector = (state: GlobalState) => segmentsSelector(state).targetingManager;

export const shouldFetchSegmentSelector = (state: GlobalState, props: SegmentOwnProps) => {
  const segment = segmentByKeySelector(state, props);

  if (segment.entity) {
    return false;
  }

  return segment.request ? segment.request.shouldFetch() : true;
};

export const shouldFetchSegmentRelatedFlagsSelector = (state: GlobalState, props: SegmentOwnProps) => {
  const request = segmentRelatedFlagsRequestByKeySelector(state, props);
  return request ? request.shouldFetch() : true;
};

export const isSegmentReadySelector = (state: GlobalState, props: SegmentOwnProps) => {
  const segment = segmentByKeySelector(state, props);
  return ready(segment.request);
};

export const segmentRelatedFlagsStateSelector = (state: GlobalState, props: SegmentOwnProps) => {
  const relatedRequest = segmentRelatedFlagsRequestByKeySelector(state, props);
  const flags = segmentRelatedFlagsByKeySelector(state, props);

  return {
    isReady: ready(relatedRequest),
    flags,
  };
};

export const bigSegmentTargetSelector = (state: GlobalState, userKey: string) =>
  segmentsSelector(state).bigSegmentTargets.get(userKey);

export function getCheckAccessResourceSegment(state: boolean = false, action: AnyAction) {
  switch (action.type) {
    case 'segments/CHECK_ACCESS_RESOURCE':
      return action.willRemoveEditingAbility;
    case 'segments/UPDATE_SEGMENT_SETTINGS_DONE':
      return false;
    default:
      return state;
  }
}

export const checkAccessResourceSegment = (state: GlobalState) => state.getCheckAccessResourceSegment;

export const segmentsPaginationSelector = (state: GlobalState) => segmentsSelector(state).pagination;

registry.addReducers({ getCheckAccessResourceSegment, segments });
