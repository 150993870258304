import { useEffect, useRef } from 'react';
/**
 * This returns a ref whose value is updated after every render. This can be useful when we want to reference a value in a memoized callback without listing it as an explicit dependency.
 */
export function useUpdatingRef<T>(value: T) {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref;
}
