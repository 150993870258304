import { profileSelector } from 'reducers/profile';
import { ready } from 'utils/reduxUtils';

import { useSelector } from './useSelector';

export const useProfile = () => {
  const profileState = useSelector(profileSelector);
  const isReady = ready(profileState);

  return {
    entity: profileState.get('entity'),
    isReady,
  };
};
