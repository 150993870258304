import { groupBy } from 'lodash';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

// eslint-disable-next-line import/no-namespace
import * as actionTypes from 'actionTypes/usage';
import registry from 'reducers/registry';
import { convertGroupedSDKsToFlatArray } from 'utils/usageUtils';

import { createRequestReducer } from './createRequestReducer';

export const createConnectionSDKReducer = (usageType) => {
  const request = createRequestReducer(
    [actionTypes.REQUEST_SDK_VERSIONS, actionTypes.REQUEST_SDK_VERSIONS_DONE, actionTypes.REQUEST_SDK_VERSIONS_FAILED],
    (action) => action.usageType === usageType,
  );

  const sdkVersionsList = (state = [], action) => {
    if (action.usageType !== usageType) {
      return state;
    }

    switch (action.type) {
      case actionTypes.REQUEST_SDK_VERSIONS_DONE:
        return action.sdkVersions.sort((a, b) => (a.sdk !== b.sdk ? a.sdk - b.sdk : a.version - b.version));
      default:
        return state;
    }
  };

  return combineReducers({
    request,
    list: sdkVersionsList,
  });
};

export const createMauSDKReducer = (usageType) => {
  const request = createRequestReducer(
    [actionTypes.REQUEST_MAU_SDKS, actionTypes.REQUEST_MAU_SDKS_DONE, actionTypes.REQUEST_MAU_SDKS_FAILED],
    (action) => action.usageType === usageType,
  );

  const mauSdkList = (state = [], action) => {
    if (action.usageType !== usageType) {
      return state;
    }

    switch (action.type) {
      case actionTypes.REQUEST_MAU_SDKS_DONE:
        const sdks = action.sdks;
        return (sdks?.length ?? 0) > 0 ? sdks.sort() : [];
      default:
        return state;
    }
  };

  return combineReducers({
    request,
    list: mauSdkList,
  });
};

export const createUsageReducer = (metric) => {
  const request = createRequestReducer(
    [actionTypes.REQUEST_USAGE_DATA, actionTypes.REQUEST_USAGE_DATA_DONE, actionTypes.REQUEST_USAGE_DATA_FAILED],
    (action) => action.metric === metric,
  );

  const data = (
    state = {
      _links: {},
      metadata: [],
      series: [],
    },
    action,
  ) => {
    if (action.metric !== metric) {
      return state;
    }

    switch (action.type) {
      case actionTypes.REQUEST_USAGE_DATA_DONE:
        return action.data;
      default:
        return state;
    }
  };

  return combineReducers({
    request,
    data,
  });
};

export const createUsageSelector = (rootSelector) =>
  createSelector(rootSelector, (state) => {
    const { request, data } = state;

    return {
      isReady: request.isReady(),
      metadata: data.metadata,
      series: data.series,
      error: request.error,
    };
  });

export const serverConnectionsUsage = createUsageReducer('server/connections');
export const serverConnectionsUsageSelector = createUsageSelector((state) => state.usage.server.connections);

export const clientConnectionsUsage = createUsageReducer('client/connections');
export const clientConnectionsUsageSelector = createUsageSelector((state) => state.usage.client.connections);

export const serverMauUsage = createUsageReducer('server/mau');
export const serverMauUsageSelector = createUsageSelector((state) => state.usage.server.mau);

export const clientMauUsage = createUsageReducer('client/mau');
export const clientMauUsageSelector = createUsageSelector((state) => state.usage.client.mau);

export const eventsReceivedUsage = createUsageReducer('experiments/receivedEvents');
export const eventsReceivedUsageSelector = createUsageSelector((state) => state.usage.experiments.receivedEvents);

export const eventsPublishedUsage = createUsageReducer('dataExport/publishedEvents');
export const eventsPublishedUsageSelector = createUsageSelector((state) => state.usage.dataExport.publishedEvents);

export const flagEvaluations = createUsageReducer('flagEvaluations');
export const flagEvaluationsSelector = createUsageSelector((state) => state.usage.flagEvaluations);

const serverConnectionSDK = createConnectionSDKReducer('server');
const clientConnectionSDK = createConnectionSDKReducer('client');
const serverMauSDK = createMauSDKReducer('server');
const clientMauSDK = createMauSDKReducer('client');

export const sdkVersionsSelector = (rootSelector) =>
  createSelector(rootSelector, (state) => {
    const { request, list } = state;
    const groupedSDKs = groupBy(list, 'sdk');
    const SDKs = list.length > 1 ? convertGroupedSDKsToFlatArray(groupedSDKs) : [];

    return {
      isReady: request.isReady(),
      SDKs,
      groupedSDKs,
    };
  });

export const mauSdkSelector = (rootSelector) =>
  createSelector(rootSelector, (state) => {
    const { request, list } = state;

    return {
      isReady: request.isReady(),
      SDKs: list,
    };
  });

export const serverConnectionSDKSelector = sdkVersionsSelector((state) => state.usage.server.connectionSDK);
export const clientConnectionSDKSelector = sdkVersionsSelector((state) => state.usage.client.connectionSDK);
export const serverMauSDKSelector = mauSdkSelector((state) => state.usage.server.mauSdk);
export const clientMauSDKSelector = mauSdkSelector((state) => state.usage.client.mauSdk);

export const serverUsage = combineReducers({
  connectionSDK: serverConnectionSDK,
  mauSdk: serverMauSDK,
  connections: serverConnectionsUsage,
  mau: serverMauUsage,
});

export const clientUsage = combineReducers({
  connectionSDK: clientConnectionSDK,
  mauSdk: clientMauSDK,
  connections: clientConnectionsUsage,
  mau: clientMauUsage,
});

export const experimentUsage = combineReducers({
  receivedEvents: eventsReceivedUsage,
});

export const dataExportUsage = combineReducers({
  publishedEvents: eventsPublishedUsage,
});

/**
 * @param {boolean | undefined} state
 */
function relayDetection(state = null, action) {
  switch (action.type) {
    case actionTypes.REQUEST_RELAY_METRICS_DONE:
      return action.res;
    default:
      return state;
  }
}

/**
 * @param {import("reducers").GlobalState} state
 * @returns {boolean | undefined}
 */
export function relayDetectionSelector(state) {
  return state.usage.relayDetection;
}

export const usage = combineReducers({
  server: serverUsage,
  client: clientUsage,
  experiments: experimentUsage,
  dataExport: dataExportUsage,
  flagEvaluations,
  relayDetection,
});

registry.addReducers({ usage });
