import { forwardRef, ReactNode, useCallback, useEffect, useRef } from 'react';
import { components, GroupBase, MultiValueProps, SingleValueProps } from 'react-select';
import { CustomSelect, GroupedOption, OptionProps } from '@gonfalon/launchpad-experimental';
import { Metric } from '@gonfalon/metrics';
import { useMetrics } from '@gonfalon/rest-api';
import { useProjectKey } from '@gonfalon/router';
import cx from 'clsx';
import { FieldError as LaunchpadFieldError, FormGroup, FormHint, Label } from 'launchpad';

import { RandomizationUnit } from 'components/experimentation/common/types';
import { FieldError } from 'components/FieldError';
import MetricLabel from 'components/metrics/MetricLabel';
import MetricKind from 'utils/MetricKind';

import { useSelectMetricGroupHeading } from './useSelectMetricGroupHeading';

import styles from './SelectMetric.module.css';

type MetricOption = OptionProps & {
  label: string;
  isDisabled?: boolean;
};

type BaseProps = {
  id?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  hint?: ReactNode;
  isMulti?: boolean;
  disabled?: boolean;
  required?: boolean;
  experimentRandomizationUnit?: RandomizationUnit;
  filterMetrics?: (metric: Metric) => boolean;
  mapMetricToOption?: (metric: Metric) => Partial<MetricOption>;
  getGroupHeading?: (group: GroupBase<GroupedOption>) => JSX.Element | undefined;
  errorMessage?: string;
  menuPosition?: 'absolute' | 'fixed';
  className?: string;
  showMetricType?: boolean;
};

type SingleProps = {
  isMulti?: false;
  value?: Metric;
  onChange: (metric: Metric) => void;
};

type MultiProps = {
  isMulti: true;
  value?: Metric[];
  onChange: (metrics: Metric[]) => void;
};

type Props = BaseProps & (SingleProps | MultiProps);

export const SelectMetric = forwardRef(
  (
    {
      id,
      name,
      label,
      placeholder,
      hint,
      value,
      onChange,
      isMulti,
      disabled,
      required,
      experimentRandomizationUnit,
      filterMetrics,
      mapMetricToOption,
      getGroupHeading,
      errorMessage,
      menuPosition,
      className,
      showMetricType,
    }: Props,
    ref,
  ) => {
    const innerRef = useRef();
    useEffect(() => {
      if (typeof ref === 'function') {
        ref(innerRef.current);
      } else if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = innerRef.current;
      }
    });

    const GroupHeading = useSelectMetricGroupHeading({
      getGroupHeading,
      randomizationUnit: experimentRandomizationUnit?.randomizationUnit,
    });

    const hasRandomizationUnitMismatch = (metric: Metric) => {
      if (!experimentRandomizationUnit || !metric.randomizationUnits) {
        return false;
      }

      return !metric.randomizationUnits.includes(experimentRandomizationUnit.randomizationUnit);
    };

    const toOption = (metric: Metric): MetricOption => ({
      data: metric,
      label: metric.name,
      value: metric.key,
      isDisabled: hasRandomizationUnitMismatch(metric),
      ...(mapMetricToOption?.(metric) ?? {}),
    });

    const projectKey = useProjectKey();
    const { data, isPending, isError } = useMetrics({ projectKey });
    const metrics = data?.items;

    const formatOptionLabel = (option: OptionProps) => {
      const metric = option.data as Metric;

      return (
        <div className="u-flex u-flex-column">
          <div className="u-flex u-flex-between">
            <span>{option.label} </span>
            {showMetricType && !option.isDisabled ? (
              <MetricLabel
                kind={metric.kind as MetricKind}
                isNumeric={metric.isNumeric}
                unitAggregationType={metric.unitAggregationType}
              />
            ) : null}
          </div>
          <div className={styles.incompatibleRandomizationUnits}>
            {metric.randomizationUnits &&
              hasRandomizationUnitMismatch(metric) &&
              metric.randomizationUnits.map((rUnit) => (
                <code key={`${option.label} ${rUnit}`} className={styles.optionChip}>
                  {rUnit}
                </code>
              ))}
          </div>
        </div>
      );
    };

    const SingleValue = (props: SingleValueProps<OptionProps>) => (
      <components.SingleValue {...props}>
        <span>{props.data.label}</span>
      </components.SingleValue>
    );

    const MultiValue = (props: MultiValueProps<OptionProps>) => {
      const metric = props.data.data as Metric;
      const hasMismatch = hasRandomizationUnitMismatch(metric);
      const spanProps = hasMismatch
        ? {
            title: `This metric is incompatible with the context "${
              experimentRandomizationUnit?._displayName ?? experimentRandomizationUnit?.randomizationUnit
            }".`,
          }
        : {};

      return (
        <components.MultiValue {...props} className={cx(styles.multiValue, { [styles.multiValueError]: hasMismatch })}>
          <span {...spanProps}>{props.data.label}</span>
        </components.MultiValue>
      );
    };

    const selectId = id ?? (isMulti ? 'metrics' : 'metric');
    const selectName = name ?? selectId;
    const selectLabel = label ?? (isMulti ? 'Metrics' : 'Metric');
    const selectPlaceholder = placeholder ?? 'Type metric name';
    // eslint-disable-next-line no-nested-ternary
    const selectValue = Array.isArray(value) ? value.map(toOption) : value ? toOption(value) : undefined;

    const selectOptions =
      metrics
        ?.filter((metric) => filterMetrics?.(metric) ?? true)
        .map(toOption)
        .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

    const selectAvailableOptions = selectOptions.filter((option) => !option.isDisabled);
    const selectUnavailableOptions = selectOptions.filter((option) => option.isDisabled);

    const selectSectionedOptions = [
      {
        label: 'Available',
        options: selectAvailableOptions,
      },
      {
        label: 'Unavailable',
        options: selectUnavailableOptions,
      },
    ];

    // eslint-disable-next-line no-nested-ternary
    const isAnyMetricMismatched = Array.isArray(value)
      ? value.some(hasRandomizationUnitMismatch)
      : value
        ? hasRandomizationUnitMismatch(value)
        : false;
    const handleChange = useCallback(
      (option: OptionProps | OptionProps[]) => {
        if (isMulti) {
          onChange(Array.isArray(option) ? option.map((o) => o.data) : []);
        } else if (!Array.isArray(option)) {
          onChange(option.data);
        }
      },
      [onChange, isMulti],
    );

    return (
      <FormGroup className={className}>
        <Label htmlFor={selectId} required={required} className={styles.label}>
          {selectLabel}
        </Label>
        {hint && <FormHint>{hint}</FormHint>}
        <CustomSelect
          id={selectId}
          name={selectName}
          ariaLabel={selectLabel}
          placeholder={selectPlaceholder}
          isLoading={isPending}
          isDisabled={disabled || isPending || isError}
          isClearable={isMulti}
          value={selectValue}
          options={selectSectionedOptions}
          onChange={handleChange}
          isMulti={isMulti}
          autoSort
          formatOptionLabel={formatOptionLabel}
          customComponents={{ GroupHeading, MultiValue, SingleValue }}
          innerRef={ref ? innerRef : undefined}
          menuPosition={menuPosition}
        />
        {errorMessage && <LaunchpadFieldError name={name ?? 'metric'} errorMessage={errorMessage} />}
        {isAnyMetricMismatched && (
          <FieldError>One or more metrics are incompatible with the selected randomization unit.</FieldError>
        )}
        {isError && <FieldError>There was a problem loading this project's metrics.</FieldError>}
      </FormGroup>
    );
  },
);
