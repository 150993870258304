import { creationDateSchema } from './types';

export function parseCreationDate(value: unknown) {
  const result = creationDateSchema.safeParse(value);

  if (!result.success) {
    return undefined;
  }

  return result.data;
}
