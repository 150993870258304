import { groupBy, mapValues } from '@gonfalon/es6-utils';
import { startOfDay } from 'date-fns';

import { AuditLogEntryRep } from './types';

export function groupEntriesByDay(entries?: AuditLogEntryRep[], sort: 'asc' | 'desc' = 'desc') {
  return mapValues(
    groupBy(sort === 'desc' ? entries : entries?.reverse(), (entry) => {
      const date = startOfDay(entry.date).valueOf();
      return date;
    }),
  );
}
