import { Button, Dialog, Heading, IconButton } from '@launchpad-ui/components';

import { Shortcut } from '../../types';
import { ShortcutsForm } from '../ShortcutsForm';

import styles from './ShortcutsDialog.module.css';

export function ShortcutsDialog({ shortcutType }: { shortcutType: Shortcut['type'] }) {
  return (
    <Dialog className={styles.dialog}>
      {({ close }) => (
        <>
          <div slot="header" className={styles.header}>
            <Heading slot="title">Save shortcut</Heading>
            <IconButton aria-label="close" icon="cancel" size="small" variant="minimal" onPress={close} />
          </div>
          <div slot="body">
            <p className={styles.description}>
              Shortcut will include all filters, search queries, and environments you have in the current view.
            </p>
            <ShortcutsForm shortcutType={shortcutType} />
          </div>
          <div slot="footer">
            <Button variant="minimal" onPress={close}>
              Cancel
            </Button>
            <Button formTarget="shortcut" form="shortcut" variant="primary" type="submit">
              Save
            </Button>
          </div>
        </>
      )}
    </Dialog>
  );
}
