import { type InternalEnvironment } from '@gonfalon/environments';
import { getQueryClient } from '@gonfalon/react-query-client';
import { internalEnvironmentList, projectsDetail } from '@gonfalon/rest-api';
import invariant from 'tiny-invariant';

import { readProjectContextFromRequest } from './readProjectContextFromRequest';

export async function fetchProjectContextForRequest(request: Request) {
  const queryClient = getQueryClient();
  const projectContext = readProjectContextFromRequest(request);

  invariant(projectContext, 'fetchProjectContextForRequest requires a valid project context');

  const [project, environments] = await Promise.all([
    queryClient.fetchQuery(projectsDetail({ projectKey: projectContext.projectKey })),
    queryClient.fetchQuery(
      internalEnvironmentList({
        projectKey: projectContext.projectKey,
        query: {
          key: Array.from(projectContext.environmentKeys),
        },
      }),
    ),
  ]);

  const sortedEnvironments: InternalEnvironment[] = [];
  for (const key of projectContext.environmentKeys) {
    const environment = environments.items.find((it) => it.key === key);
    if (!environment) {
      projectContext.environmentKeys.delete(key);
      continue;
    }

    sortedEnvironments.push(environment);
  }

  return {
    context: projectContext,
    project,
    environments: sortedEnvironments,
  };
}
