import { type ReactNode } from 'react';
import {
  type SelectProps,
  Button,
  FieldError,
  Label,
  ListBox,
  Popover,
  Select,
  SelectValue,
} from '@launchpad-ui/components';
import { Icon, IconName } from '@launchpad-ui/icons';

import { Description } from '../Form';

import styles from './Picker.module.css';

export type PickerProps<T extends object> = Omit<SelectProps<T>, 'children'> & {
  label?: string;
  description?: ReactNode;
  errorMessage?: string;
  items?: Iterable<T>;
  selectedLabel?: string;
  iconName?: IconName;
  children: React.ReactNode | ((item: T) => React.ReactNode);
};

export function Picker<T extends object>({
  items,
  label,
  description,
  errorMessage,
  children,
  selectedLabel,
  iconName = 'chevrons-out',
  ...props
}: PickerProps<T>) {
  return (
    <Select aria-label={label || selectedLabel} {...props}>
      {label && <Label>{label}</Label>}
      <Button className={styles.button}>
        {selectedLabel && <div className={styles.selectedItemPrefix}>{selectedLabel}</div>}
        <SelectValue />
        <Icon name={iconName} size="small" />
      </Button>
      {description && <Description>{description}</Description>}
      {errorMessage && <FieldError>{errorMessage}</FieldError>}
      <Popover>
        <ListBox items={items}>{children}</ListBox>
      </Popover>
    </Select>
  );
}
