import { createRelativeDifferenceFormatter, createValueFormatter } from '@gonfalon/metrics';

import { MeasuredRolloutResults } from '.';

export function getTreatmentResultFormatters(measuredRolloutResults: MeasuredRolloutResults) {
  const formatValue = createValueFormatter([
    measuredRolloutResults.controlResult.credibleInterval,
    measuredRolloutResults.treatmentResult.credibleInterval,
  ]);

  const formatRelativeDifference = createRelativeDifferenceFormatter([measuredRolloutResults.relativeDifference]);

  return { formatValue, formatRelativeDifference };
}
