import { useMemo } from 'react';
import { components, GroupBase, GroupHeadingProps } from 'react-select';
import { GroupedOption } from '@gonfalon/launchpad-experimental';

import { RandomizationUnitLabel } from '../RandomizationUnitLabel';

import styles from './SelectMetric.module.css';

type Props<T> = {
  getGroupHeading?: (group: GroupBase<T>) => JSX.Element | undefined;
  randomizationUnit?: string;
};

export function useSelectMetricGroupHeading<T = GroupedOption>({ getGroupHeading, randomizationUnit }: Props<T>) {
  return useMemo(
    () => (groupHeadingProps: GroupHeadingProps<T>) => {
      const isUnavailable = groupHeadingProps.data.label === 'Unavailable';
      const heading =
        getGroupHeading?.(groupHeadingProps.data) ??
        (isUnavailable && randomizationUnit ? (
          <span>
            Incompatible context kind: These metrics cannot measure events from{' '}
            <RandomizationUnitLabel randomizationUnit={randomizationUnit} />
          </span>
        ) : null);

      return (
        <div
          className={isUnavailable ? styles['groupHeading-container-incompatible'] : styles['groupHeading-container']}
        >
          <components.GroupHeading className={styles.groupHeading} {...groupHeadingProps} />
          {heading}
        </div>
      );
    },
    [getGroupHeading, randomizationUnit],
  );
}
