import { MeasuredRolloutResults } from '@gonfalon/measured-rollouts';

import { ExperimentBayesianResults, ExperimentQuantileResults } from './types';

const SRM_PROBABILITY_THRESHOLD = 0.99;

export function hasSampleRatioMismatch(
  results: ExperimentBayesianResults | ExperimentQuantileResults | MeasuredRolloutResults,
) {
  if (!results.probabilityOfMismatch) {
    return false;
  }

  return results.probabilityOfMismatch >= SRM_PROBABILITY_THRESHOLD;
}
