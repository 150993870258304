import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';
import type { IconButtonProps } from 'launchpad';
import { IconButton } from 'launchpad';

import styles from './ClearButton.module.css';

type ClearButtonProps = Omit<IconButtonProps, 'icon' | 'size' | 'kind'>;

export const ClearButton = ({ className, ...rest }: ClearButtonProps) => (
  <IconButton
    className={cx(styles.clearButton, className)}
    size="small"
    kind="default"
    {...rest}
    icon={<Icon name="cancel" size="small" className={styles.icon} />}
  />
);
