import { type ListBoxProps as AriaListBoxProps, composeRenderProps, ListBox as ListBox_ } from 'react-aria-components';
import clsx from 'clsx';

import styles from './ListBox.module.css';

export type ListBoxProps<T extends object> = AriaListBoxProps<T>;

export function ListBox<T extends object>({ children, ...props }: ListBoxProps<T>) {
  return (
    <ListBox_ {...props} className={composeRenderProps(props.className, (className) => clsx(className, styles.list))}>
      {children}
    </ListBox_>
  );
}
