import { useCallback, useMemo, useSyncExternalStore } from 'react';

import { createStorageSlot } from './createStorageSlot';
import { StorageSlot } from './StorageSlot';
import { defaultStorageType, StorageType } from './StorageType';

export function useStorageSlot(
  key: string,
  { storageType, isEnabled }: { storageType?: StorageType; isEnabled?: boolean } = { storageType: defaultStorageType },
) {
  const slot = useMemo(() => createStorageSlot(key, { storageType, isEnabled }), [key]);

  const subscribe: StorageSlot['subscribe'] = useCallback((onChange) => slot.subscribe(onChange), [slot]);

  const currentValue = useSyncExternalStore(
    subscribe,
    () => slot.get(),
    () => null,
  );

  const restrictedSlot = useMemo(
    () => ({
      set: slot.set,
      get: slot.get,
      remove: slot.remove,
    }),
    [slot],
  );

  return [currentValue, restrictedSlot] as const;
}
