import { useChangeHistory } from '@gonfalon/change-history';
import { IconButton, Tooltip, TooltipTrigger } from '@launchpad-ui/components';

import { trackChangeHistoryEvent } from '../analytics';

export function ChangeHistoryTrigger() {
  const { hasRouteSpec, open, parsedResource } = useChangeHistory();

  if (!hasRouteSpec) {
    return null;
  }

  const handleOpen = () => {
    const additionalData: { resourceType?: string; isList?: boolean } = {};
    if (parsedResource) {
      additionalData.resourceType = parsedResource.type;
      additionalData.isList = 'name' in parsedResource && parsedResource.name === '*';
    }
    trackChangeHistoryEvent('Show change history clicked', additionalData);
    open();
  };

  return (
    <TooltipTrigger>
      <IconButton
        onPress={handleOpen}
        size="medium"
        variant="minimal"
        aria-label="Show change history"
        icon="clock-history"
      />
      <Tooltip>Show change history</Tooltip>
    </TooltipTrigger>
  );
}
