import { track } from '@gonfalon/analytics';

import { LaunchDarklyIntegrationsManifest as IntegrationManifestType } from 'types/generated/integrationSchema';

type MemberSummaryType = {
  _links: { self: { href: string } };
  _id: string;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
};

const memberFullName = (m: MemberSummaryType) => [m.firstName, m.lastName].join(' ').trim();
export const memberDisplayName = (m: MemberSummaryType) => memberFullName(m) || m.email;

export type TriggerType = {
  _links: { self: { href: string } };
  _manifest: IntegrationManifestType;
  _id: string;
  _integrationKey: string;
  _triggerCount: number;
  instructions: Array<{ kind: 'turnFlagOn' | 'turnFlagOff' }>;
  triggerURL: string;
  enabled: boolean;
  isNew: boolean;
  _maintainer?: MemberSummaryType;
  _creationDate: number;
};

export const TriggerPatchKind = {
  ENABLE: 'enableTrigger',
  DISABLE: 'disableTrigger',
  RESET_URL: 'cycleTriggerUrl',
};

export const obscureTriggerURL = (triggerURL: string) => {
  const start = triggerURL.substring(0, triggerURL.indexOf('/', 8) + 1);
  const end = triggerURL.substring(triggerURL.length - 4);
  return `${start}********${end}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const saveTriggerButtonClicked = (attributes: any = {}) =>
  track('Trigger Save Button Clicked', 'Triggers', attributes);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteTriggerButtonClicked = (attributes: any = {}) =>
  track('Trigger Delete Button Clicked', 'Triggers', attributes);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cycleTriggerButtonClicked = (attributes: any = {}) =>
  track('Trigger Cycle-URL Button Clicked', 'Triggers', attributes);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const enableTriggerButtonClicked = (attributes: any = {}) =>
  track('Trigger Enable Button Clicked', 'Triggers', attributes);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const disableTriggerButtonClicked = (attributes: any = {}) =>
  track('Trigger Disable Button Clicked', 'Triggers', attributes);
