import { Member } from '@gonfalon/members';
import { checkAccess } from '@gonfalon/permissions';

import { ReleasePipeline } from './types';

export type Action =
  | 'createReleasePipeline'
  | 'updateDefaultReleasePipeline'
  | 'updateReleasePipelineName'
  | 'updateReleasePipelineDescription'
  | 'updateReleasePipelineTags'
  | 'updateReleasePipelinePhaseName'
  | 'addReleasePipeline'
  | 'deleteReleasePipeline'
  | 'updateReleasePipelinePhase';

export function checkReleasePipelineAccess(member: Member, action: Action, releasePipeline: ReleasePipeline) {
  return checkAccess(member, releasePipeline._access, action);
}
