import { List, Map } from 'immutable';

import { VARIATION_NOT_FOUND } from 'components/InstructionList/instructionListUtils';
import Variation from 'components/Variation';
import { USER_CONTEXT_KIND } from 'utils/constants';
import { colorVariation, Variation as variationType } from 'utils/flagUtils';

export const RolloutDescription = ({
  contextKind,
  rolloutWeights,
  variations,
  bucketBy,
}: {
  contextKind?: string;
  rolloutWeights: Map<string, number>;
  variations: List<variationType>;
  bucketBy?: string;
}) => {
  const renderVariation = (index: number) => (
    <Variation
      className="u-ml-s"
      key={index}
      color={colorVariation(index)}
      value={
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        variations.get(index)!.getDisplay() /* eslint-enable @typescript-eslint/no-non-null-assertion */
      }
    />
  );

  let i = 0;
  const length = rolloutWeights.size;
  const rolloutContextKind = contextKind || USER_CONTEXT_KIND;
  return (
    <span className="RolloutDescription">
      {rolloutWeights
        .map((value: number, variationID: string) => {
          let variationIndex: number | string = VARIATION_NOT_FOUND;
          variations.forEach((v, index) => {
            if (v._id === variationID) {
              variationIndex = index;
            }
          });
          const percent = value / 1000;
          const additionalRolloutText = i++ !== length - 1 && ', ';
          return (
            <span key={variationID}>
              {typeof variationIndex === 'number' ? renderVariation(variationIndex) : ` ${VARIATION_NOT_FOUND}`}
              <span className="u-ml-s">({percent}%)</span>
              {additionalRolloutText}
            </span>
          );
        })
        .valueSeq()}
      {bucketBy && ` bucketed by ${rolloutContextKind} attribute ${bucketBy}`}
    </span>
  );
};
