import { useQuery } from '@tanstack/react-query';

import { getExperiment } from '../api/experiment';
import { ExperimentExpandOptions } from '../types';

type Props = {
  projectKey: string;
  environmentKey: string;
  experimentKey: string;
  expand?: ExperimentExpandOptions[];
  enabled?: boolean;
};

export const EXPERIMENTS_V2_KEY = 'EXPERIMENTS_V2';

/**
 * Hook fetches a single experiment from the v2 API
 *
 * @param param.projectKey - the key of the project the experiment belongs to
 * @param param.environmentKey - the env key the experiment exists in
 * @param param.experimentKey - the key of the experiment
 * @param param.expand - an array of options to add additional data to the response
 * @param param.enabled - whether the hook is enabled
 */
export function useExperiment({ projectKey, environmentKey, experimentKey, expand, enabled = true }: Props) {
  return useQuery({
    queryKey: [EXPERIMENTS_V2_KEY, projectKey, environmentKey, experimentKey, expand],
    queryFn: async () => getExperiment(projectKey, environmentKey, experimentKey, expand?.sort()),
    retry: (failureCount, error) => {
      if (error instanceof Response && error.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
    enabled,
  });
}
