import { constants } from 'utils/reduxUtils';

/* eslint-disable import/no-default-export */
export default constants('approvals', [
  'FETCH_FLAG_CONFIG_APPROVAL_REQUESTS',
  'FETCH_FLAG_CONFIG_APPROVAL_REQUESTS_DONE',
  'FETCH_FLAG_CONFIG_APPROVAL_REQUESTS_FAILED',

  'FETCH_FLAG_CONFIG_APPROVAL_REQUEST_BY_ID',
  'FETCH_FLAG_CONFIG_APPROVAL_REQUEST_BY_ID_DONE',
  'FETCH_FLAG_CONFIG_APPROVAL_REQUEST_BY_ID_FAILED',

  'CREATE_APPROVAL_REQUEST',
  'CREATE_APPROVAL_REQUEST_DONE',
  'CREATE_APPROVAL_REQUEST_FAILED',

  'REVIEW_FLAG_CONFIG_APPROVAL_REQUEST',
  'REVIEW_FLAG_CONFIG_APPROVAL_REQUEST_DONE',
  'REVIEW_FLAG_CONFIG_APPROVAL_REQUEST_FAILED',

  'DELETE_FLAG_CONFIG_APPROVAL_REQUEST',
  'DELETE_FLAG_CONFIG_APPROVAL_REQUEST_DONE',
  'DELETE_FLAG_CONFIG_APPROVAL_REQUEST_FAILED',

  'APPLY_FLAG_CONFIG_APPROVAL_REQUEST',
  'APPLY_FLAG_CONFIG_APPROVAL_REQUEST_DONE',
  'APPLY_FLAG_CONFIG_APPROVAL_REQUEST_FAILED',

  'EDIT_APPROVAL_REQUEST',
]);
