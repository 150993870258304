// eslint-disable-next-line no-restricted-imports
import { fromJS, Map } from 'immutable';

import { GetState, GlobalDispatch, GlobalState } from 'reducers';
import { followPreferencesRequestSelector } from 'reducers/followPreferences';
import { notificationSettingsSelector, profileSelector } from 'reducers/profile';
// eslint-disable-next-line import/no-namespace
import * as AccountAPI from 'sources/AccountAPI';
import {
  FollowPreferences,
  ImmutableNotificationsSettingsType,
  Member,
  MemberType,
  NotificationChannel,
  NotificationsSettingsType,
  NotificationTopics,
  PasswordUpdate,
  PasswordUpdateProps,
} from 'utils/accountUtils';
import { ImmutableServerError, isImmutableBeastModeError } from 'utils/httpUtils';
import { GenerateActionType } from 'utils/reduxUtils';

const requestProfile = () => ({ type: 'profile/REQUEST_PROFILE' }) as const;

const requestProfileDone = (profile: Member) => ({ type: 'profile/REQUEST_PROFILE_DONE', profile }) as const;

const requestProfileFailed = (error: ImmutableServerError) =>
  ({ type: 'profile/REQUEST_PROFILE_FAILED', error }) as const;

function fetchProfile() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestProfile());
    return new Promise((resolve, reject) => {
      AccountAPI.getProfile()
        .then((profile) => {
          dispatch(requestProfileDone(profile));
          resolve(profile);
        })
        .catch((error) => {
          dispatch(requestProfileFailed(error));
          reject(error);
        });
    });
  };
}

function shouldFetchProfile(state: GlobalState) {
  const profile = profileSelector(state);
  return !profile.get('lastFetched') && !profile.get('isFetching') && !profile.get('doNotFetch');
}

function fetchProfileIfNeeded() {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchProfile(getState())) {
      return dispatch(fetchProfile());
    } else {
      return Promise.resolve(profileSelector(getState()).get('entity'));
    }
  };
}

function reloadProfile() {
  return async (dispatch: GlobalDispatch) =>
    new Promise(async (resolve) =>
      AccountAPI.getProfile().then((profile) => {
        dispatch(requestProfileDone(profile));
        resolve(profile);
      }),
    );
}

const doUpdateProfile = (profile: Member) => ({ type: 'profile/UPDATE_PROFILE', profile }) as const;

const doUpdateProfileDone = (profile: Member) => ({ type: 'profile/UPDATE_PROFILE_DONE', profile }) as const;

const doUpdateProfileFailed = (profile: Member, error: ImmutableServerError) =>
  ({
    type: 'profile/UPDATE_PROFILE_FAILED',
    profile,
    error,
  }) as const;

// newProfile is optional in the event the profile update is not done via form
function updateProfile(newProfile?: Member) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    const formState = getState().updateProfileForm;
    const { original, modified } = formState;
    const modifiedProfile = newProfile || modified;
    dispatch(doUpdateProfile(modifiedProfile));
    return AccountAPI.updateProfile(original, modifiedProfile)
      .then((profile) => dispatch(doUpdateProfileDone(profile)))
      .then(async () => dispatch(reloadProfile()))
      .catch((error) => {
        // the error that comes from Beast Mode is not an Immutable object, but FormState expects errors to be Immutable
        const finalError = isImmutableBeastModeError(error) ? Map(error) : error;
        return dispatch(doUpdateProfileFailed(modifiedProfile, finalError));
      });
  };
}

// updateProfileBeautyMode bypasses beastMode escalation and only allows updates to select fields
function updateProfileBeautyMode(newProfile: Member) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    const formState = getState().updateProfileForm;
    const { original, modified } = formState;
    const modifiedProfile = newProfile || modified;
    dispatch(doUpdateProfile(modifiedProfile));

    return AccountAPI.updateProfileBeautyMode(original, modifiedProfile)
      .then((profile) => dispatch(doUpdateProfileDone(profile)))
      .then(async () => dispatch(reloadProfile()))
      .catch((error) => dispatch(doUpdateProfileFailed(modifiedProfile, error)));
  };
}

const doEditProfile = (field: keyof MemberType, profile: Member) =>
  ({
    type: 'profile/EDIT_PROFILE',
    field,
    profile,
  }) as const;

function editProfile(field: keyof MemberType, value: string) {
  return (dispatch: GlobalDispatch, getState: GetState) => {
    const formState = getState().updateProfileForm;
    return dispatch(doEditProfile(field, formState.modified.set(field, value)));
  };
}

const doUpdatePassword = () => ({ type: 'profile/UPDATE_PASSWORD' }) as const;

const doUpdatePasswordDone = (password: PasswordUpdate) =>
  ({ type: 'profile/UPDATE_PASSWORD_DONE', password }) as const;

const doUpdatePasswordFailed = (password: PasswordUpdate, error: ImmutableServerError) =>
  ({
    type: 'profile/UPDATE_PASSWORD_FAILED',
    password,
    error,
  }) as const;

function updatePassword() {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    const formState = getState().updatePasswordForm;
    const { modified } = formState;
    dispatch(doUpdatePassword());
    return AccountAPI.updatePassword(modified)
      .then(() => dispatch(doUpdatePasswordDone(modified)))
      .catch((error) => dispatch(doUpdatePasswordFailed(modified, error)));
  };
}

const doEditPassword = (field: keyof PasswordUpdateProps, password: PasswordUpdate) =>
  ({
    type: 'profile/EDIT_PASSWORD',
    field,
    password,
  }) as const;

function editPassword(field: keyof PasswordUpdateProps, value: string) {
  return (dispatch: GlobalDispatch, getState: GetState) => {
    const formState = getState().updatePasswordForm;
    return dispatch(doEditPassword(field, formState.modified.set(field, value)));
  };
}

const doDisableMFAFailed = (error: ImmutableServerError) => ({ type: 'account/DISABLE_MFA_FAILED', error }) as const;

function disableMFA() {
  return (dispatch: GlobalDispatch) => {
    AccountAPI.disableMFA().then(
      async () => dispatch(reloadProfile()),
      (error) => dispatch(doDisableMFAFailed(error)),
    );
  };
}

const shouldFetchFollowPreferences = (state: GlobalState) => followPreferencesRequestSelector(state).shouldFetch();

const requestFollow = () => ({ type: 'profile/REQUEST_FOLLOW_PREFERENCES' }) as const;

const requestFollowDone = (preferences: FollowPreferences) =>
  ({ type: 'profile/REQUEST_FOLLOW_PREFERENCES_DONE', preferences }) as const;

const requestFollowFailed = (error: ImmutableServerError) =>
  ({ type: 'profile/REQUEST_FOLLOW_PREFERENCES_FAILED', error }) as const;

const fetchFollowPreferences = () => (dispatch: GlobalDispatch) => {
  dispatch(requestFollow());
  AccountAPI.getFollowPreferences().then(
    (preferences) => dispatch(requestFollowDone(preferences)),
    (error) => dispatch(requestFollowFailed(error)),
  );
};

const fetchFollowPreferencesIfNeeded = () => (dispatch: GlobalDispatch, getState: GetState) => {
  if (shouldFetchFollowPreferences(getState())) {
    dispatch(fetchFollowPreferences());
  }
};

const resend = () => ({ type: 'profile/RESEND_VERIFICATION' }) as const;

const resendDone = (email: string) => ({ type: 'profile/RESEND_VERIFICATION_DONE', email }) as const;

const resendFailed = (email: string, error: ImmutableServerError) =>
  ({ type: 'profile/RESEND_VERIFICATION_FAILED', email, error }) as const;

function resendVerification(email: string) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(resend());
    return AccountAPI.resendVerification()
      .then(() => dispatch(resendDone(email)))
      .catch((error) => dispatch(resendFailed(email, error)));
  };
}

const cancel = () => ({ type: 'profile/CANCEL_VERIFICATION' }) as const;

const cancelDone = () => ({ type: 'profile/CANCEL_VERIFICATION_DONE' }) as const;

const cancelFailed = (error: ImmutableServerError) => ({ type: 'profile/CANCEL_VERIFICATION_FAILED', error }) as const;

function cancelVerification() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(cancel());
    return AccountAPI.cancelVerification()
      .then(async () => dispatch(reloadProfile()))
      .then(() => dispatch(cancelDone()))
      .catch((error) => dispatch(cancelFailed(error)));
  };
}

function notifyProfileVerified() {
  return { type: 'profile/PROFILE_VERIFIED' } as const;
}

export function requestProfileNotificationSettings() {
  return {
    type: 'profile/REQUEST_NOTIFICATION_SETTINGS',
  } as const;
}

export const requestProfileNotificationSettingsDone = (notificationSettings: ImmutableNotificationsSettingsType) =>
  ({
    type: 'profile/REQUEST_NOTIFICATION_SETTINGS_DONE',
    notificationSettings,
  }) as const;

export const requestProfileNotificationSettingsFailed = (error: ImmutableServerError) =>
  ({
    type: 'profile/REQUEST_NOTIFICATION_SETTINGS_FAILED',
    error,
  }) as const;

function shouldFetchNotificationSettings(state: GlobalState) {
  const notificationSettings = notificationSettingsSelector(state);
  return (
    !notificationSettings.get('lastFetched') &&
    !notificationSettings.get('isFetching') &&
    !notificationSettings.get('doNotFetch')
  );
}

function fetchNotificationSettings() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestProfileNotificationSettings());
    return AccountAPI.getNotificationSettings()
      .then((settings) => dispatch(requestProfileNotificationSettingsDone(settings)))
      .catch((error) => dispatch(requestProfileNotificationSettingsFailed(error)));
  };
}

function fetchNotificationSettingsIfNeeded() {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchNotificationSettings(getState())) {
      return dispatch(fetchNotificationSettings());
    }
  };
}

export const doUpdateNotificationSettings = (
  topic: NotificationTopics,
  channel: NotificationChannel,
  channelEnabled: boolean,
) =>
  ({
    type: 'profile/UPDATE_NOTIFICATION_SETTINGS',
    notificationSettings: fromJS({
      [channel]: channelEnabled,
    }),
    topic,
  }) as const;

const doUpdateNotificationSettingsDone = () =>
  ({
    type: 'profile/UPDATE_NOTIFICATION_SETTINGS_DONE',
  }) as const;

export const doUpdateNotificationSettingsFailed = (
  oldSettings: ImmutableNotificationsSettingsType,
  error: ImmutableServerError,
) =>
  ({
    type: 'profile/UPDATE_NOTIFICATION_SETTINGS_FAILED',
    notificationSettings: oldSettings,
    error,
  }) as const;

function updateNotificationSettings(
  topic: NotificationTopics,
  channel: NotificationChannel,
  channelEnabled: boolean,
  oldSettings: NotificationsSettingsType,
) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(doUpdateNotificationSettings(topic, channel, channelEnabled));
    return AccountAPI.putNotificationSettings(topic, channel, channelEnabled, oldSettings)
      .then(() => {
        dispatch(doUpdateNotificationSettingsDone());
      })
      .catch((error) => {
        dispatch(doUpdateNotificationSettingsFailed(fromJS({ entity: oldSettings }), error));
      });
  };
}

export {
  fetchProfileIfNeeded as fetchProfile,
  fetchNotificationSettingsIfNeeded as fetchNotificationSettings,
  reloadProfile,
  updateProfile,
  updateProfileBeautyMode,
  editProfile,
  updatePassword,
  editPassword,
  disableMFA,
  fetchFollowPreferencesIfNeeded as fetchFollowPreferences,
  resendVerification,
  cancelVerification,
  notifyProfileVerified,
  updateNotificationSettings,
};

const ProfileActionCreators = {
  requestProfile,
  requestProfileDone,
  requestProfileFailed,
  requestProfileNotificationSettings,
  requestProfileNotificationSettingsDone,
  requestProfileNotificationSettingsFailed,
  doUpdateNotificationSettings,
  doUpdateNotificationSettingsDone,
  doUpdateNotificationSettingsFailed,
  doUpdateProfile,
  doUpdateProfileDone,
  doUpdateProfileFailed,
  doUpdatePassword,
  doUpdatePasswordDone,
  doUpdatePasswordFailed,
  resend,
  resendDone,
  resendFailed,
  cancel,
  cancelDone,
  cancelFailed,
  notifyProfileVerified,
  requestFollow,
  requestFollowDone,
  requestFollowFailed,
  doEditProfile,
  doEditPassword,
};

export type ProfileAction = GenerateActionType<typeof ProfileActionCreators>;
