import cx from 'clsx';

import './styles.css';

type CardHeaderProps = {
  extendedBorder?: boolean;
  isBorderless?: boolean;
  className?: string;
  children?: React.ReactNode;
};

export function CardHeader({ extendedBorder = false, className, children, isBorderless }: CardHeaderProps) {
  return (
    <div
      className={cx('Card-header', className, {
        'Card-header--withBorder': !isBorderless,
        'Card-header--extendedBorder': extendedBorder,
      })}
    >
      {children}
    </div>
  );
}
