import { baseUri } from '@gonfalon/constants';

import Jumbo from 'components/Jumbo';
import { ImmutableServerError } from 'utils/httpUtils';
import { getDocumentationUrl } from 'utils/urlUtils';

const authenticationDocLink = getDocumentationUrl('reference#authentication');

type CouldNotConnectProps = {
  error: ImmutableServerError;
};

function CouldNotConnect({ error }: CouldNotConnectProps) {
  const status = error.get('status');
  let explanation = 'There was a problem connecting to LaunchDarkly. Check your connection and try again later.';
  let action;
  if (status === 403) {
    explanation = `Your browser is making requests with an origin header that doesn’t match ${baseUri()}. Check your browser configuration.`;
    action = (
      <div>
        To learn more, read <a href={authenticationDocLink}>Authentication</a>.
      </div>
    );
  }
  return <Jumbo title="Unable to connect" lead={explanation} actions={action} isError />;
}

/* eslint-disable import/no-default-export */
export default CouldNotConnect;
