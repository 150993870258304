import { getTrialDaysRemaining as getLegacySubscriptionTrialDaysRemaining } from '../internal/legacySubscription';
import { getTrialDaysRemaining as getModernSubscriptionTrialDaysRemaining } from '../internal/modernSubscription';
import { Subscription } from '../types';

import { isModernSubscription } from './isModernSubscription';

export function getTrialDaysRemaining(subscription: Subscription) {
  if (isModernSubscription(subscription)) {
    return getModernSubscriptionTrialDaysRemaining(subscription);
  }

  return getLegacySubscriptionTrialDaysRemaining(subscription);
}
