import { List, Map } from 'immutable';

import { VARIATION_NOT_FOUND } from 'components/InstructionList/instructionListUtils';
import Variation from 'components/Variation';
import { colorVariation, ExperimentRollout, Variation as variationType } from 'utils/flagUtils';

export const ExperimentRolloutDescription = ({
  rolloutWeights,
  variations,
  experimentAllocation,
}: {
  rolloutWeights: Map<string, number>;
  variations: List<variationType>;
  experimentAllocation: ExperimentRollout;
}) => {
  const renderVariation = (index: number) => (
    <Variation
      className="u-ml-s"
      key={index}
      color={colorVariation(index)}
      value={
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        variations.get(index)!.getDisplay() /* eslint-enable @typescript-eslint/no-non-null-assertion */
      }
    />
  );

  let total = 0;

  return (
    <span className="RolloutDescription">
      {rolloutWeights
        .map((value, variationID) => {
          const variationIndex = variations.findIndex((v) => v._id === variationID);
          total += value;
          const percent = value / 1000;
          return (
            <span key={variationID}>
              {variationIndex >= 0 ? renderVariation(variationIndex) : ` ${VARIATION_NOT_FOUND}`}
              <span className="u-ml-s">({percent}%),</span>
            </span>
          );
        })
        .valueSeq()}
      {` and remaining ${(100000 - total) / 1000}% to `}
      {typeof experimentAllocation.defaultVariation === 'number'
        ? renderVariation(experimentAllocation.defaultVariation)
        : VARIATION_NOT_FOUND}
      {` and ${experimentAllocation.canReshuffle ? 'users can be reshuffled' : 'users cannot be reshuffled'}.`}
    </span>
  );
};
