import { pluralize } from '@gonfalon/strings';
import { z } from 'zod';

export const durationUnits = ['day', 'hour', 'minute'] as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isDurationUnit(value: any): value is (typeof durationUnits)[number] {
  return durationUnits.includes(value);
}

export const durationQuantityLimits = {
  day: {
    min: 1,
    max: 7, // 1 week
  },
  hour: {
    min: 1,
    max: 168, // 1 week
  },
  minute: {
    min: 1,
    max: 10080, // 1 week
  },
} as const satisfies {
  [K in (typeof durationUnits)[number]]: { min: number; max: number };
};

const rolloutWeightSchema = z
  // eslint-disable-next-line @typescript-eslint/naming-convention
  .number({ required_error: 'Set rollout percentage' })
  .int('Set rollout percentage to be an integer')
  .min(10, 'Increase rollout percentage to at least 0.01%')
  .lt(100000, 'Decrease rollout percentage to less than 100%');

const progressiveRolloutConfigurationStepSchema = z.object({
  key: z.string().min(1, 'step key is required'),
  rolloutWeight: rolloutWeightSchema,
  duration: z.union([
    z.object({
      quantity: z
        .number()
        .min(
          durationQuantityLimits.day.min,
          `Increase duration to at least ${durationQuantityLimits.day.min} ${pluralize('day', durationQuantityLimits.day.min)}`,
        )
        .max(
          durationQuantityLimits.day.max,
          `Decrease duration to no more than ${durationQuantityLimits.day.max} ${pluralize('day', durationQuantityLimits.day.max)}`,
        ),
      unit: z.literal('day'),
    }),
    z.object({
      quantity: z
        .number()
        .min(
          durationQuantityLimits.hour.min,
          `Increase duration to at least ${durationQuantityLimits.hour.min} ${pluralize('hour', durationQuantityLimits.hour.min)}`,
        )
        .max(
          durationQuantityLimits.hour.max,
          `Decrease duration to no more than ${durationQuantityLimits.hour.max} ${pluralize('hour', durationQuantityLimits.hour.max)}`,
        ),
      unit: z.literal('hour'),
    }),
    z.object({
      quantity: z
        .number()
        .min(
          durationQuantityLimits.minute.min,
          `Increase duration to at least ${durationQuantityLimits.minute.min} ${pluralize('minute', durationQuantityLimits.minute.min)}`,
        )
        .max(
          durationQuantityLimits.minute.max,
          `Decrease duration to no more than ${durationQuantityLimits.minute.max} ${pluralize('minute', durationQuantityLimits.minute.max)}`,
        ),
      unit: z.literal('minute'),
    }),
  ]),
});

export type ProgressiveRolloutConfigurationStep = z.infer<typeof progressiveRolloutConfigurationStepSchema>;

export const progressiveRolloutConfigurationStepsSchema = z
  .array(progressiveRolloutConfigurationStepSchema)
  .superRefine((steps, ctx) => {
    toNumberPairs(steps.map((step) => step.rolloutWeight)).forEach(([a, b], aIndex) => {
      if (b <= a) {
        ctx.addIssue({
          code: 'custom',
          message: 'Increase rollout percentage from previous step',
          path: [aIndex + 1, 'rolloutWeight'],
        });
      }
    });
  });

export const progressiveRolloutConfigurationSchema = z.object({
  contextKind: z.string().min(1, 'context kind is required'),
  steps: progressiveRolloutConfigurationStepsSchema,
});

// we use a custom "ruleId" type here so we get the union 'fallthrough' | string, because Zod can't infer a type like that
export type ProgressiveRolloutConfiguration = z.infer<typeof progressiveRolloutConfigurationSchema>;

type NumberPair = [a: number, b: number];
const toNumberPairs = (arr: number[]) => arr.slice(1).map((e, i): NumberPair => [arr[i], e]);
