import { ChangeEvent } from 'react';
import cx from 'clsx';
import { Label, RequiredAsterisk } from 'launchpad';

import CommentArea, { COMMENT_AREA_ID } from 'components/forms/CommentArea';
import { FieldError } from 'components/ui/forms';
import { REQUIRED_FIELD_MESSAGE } from 'utils/validationUtils';

import 'stylesheets/components/Comment.css';

type CommentProps = {
  optional?: boolean;
  onChange(event: ChangeEvent<HTMLTextAreaElement>): void;
  onBlur?(): void;
  onFocus?(): void;
  placeholder?: string;
  comment: string;
  className?: string;
  focusRef?(): void;
  label?: string;
  showAsterix?: boolean;
  isNotValid?: boolean;
  rows?: number;
  validationMsg?: string;
  disabled?: boolean;
};
export const Comment = ({
  className,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  comment,
  focusRef,
  label = COMMENT_LABEL,
  showAsterix,
  optional = false,
  isNotValid = false,
  rows = 3,
  validationMsg,
  disabled,
}: CommentProps) => (
  <div className="u-mt-l">
    <Label className="Comment-label" htmlFor={COMMENT_AREA_ID} optional={optional}>
      {label}
      {showAsterix && <RequiredAsterisk />}
    </Label>
    <CommentArea
      rows={rows}
      value={comment || ''}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      placeholder={placeholder}
      className={cx(
        {
          'Comment-error': isNotValid,
        },
        className,
      )}
      focusRef={focusRef}
      disabled={disabled}
    />
    {isNotValid && <FieldError name={COMMENT_AREA_ID} errorMessage={validationMsg || REQUIRED_FIELD_MESSAGE} />}
  </div>
);

export const COMMENT_LABEL = 'Comment';
