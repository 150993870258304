import { isSeatLimitEnforced as isSeatLimitEnforcedFlag } from '@gonfalon/dogfood-flags';

import { LegacySubscription } from '../../types';

import { getSeatLimit } from './getSeatLimit';

export function isSeatLimitReached(legacySubscription: LegacySubscription) {
  const isSeatLimitEnforced = isSeatLimitEnforcedFlag();
  const seatLimit = getSeatLimit(legacySubscription);

  if (!isSeatLimitEnforced) {
    return false;
  }

  return (
    seatLimit > 0 &&
    legacySubscription._usage?.memberCount !== undefined &&
    legacySubscription._usage?.memberCount >= seatLimit
  );
}
