import { CampaignType } from './types';

export function hasBeenExtended(campaign: CampaignType) {
  const { extensionCount } = campaign;
  let extCount = 0;
  if (extensionCount !== undefined) {
    extCount = extensionCount;
  }
  return extCount > 0;
}
