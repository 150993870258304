import { constants } from 'utils/reduxUtils';

/* eslint-disable import/no-default-export */
export default constants('experiments', [
  'REQUEST_EXPERIMENTS',
  'RECEIVE_EXPERIMENTS',
  'REQUEST_EXPERIMENTS_FAILED',
  'CREATE_EXPERIMENT',
  'CREATE_EXPERIMENT_DONE',
  'CREATE_EXPERIMENT_FAILED',
]);
