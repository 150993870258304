import { isFlagStatusEnabled } from '@gonfalon/dogfood-flags';
import { isEmpty } from '@gonfalon/es6-utils';

import { FilterKind, FilterValue } from 'components/ui/multipleFilters/types';
import FlagFilterInterface, { BackendQueryParamName, QueryParamName } from 'utils/flagFilters/flagFilterInterface';
import { FilterOperator, FlagListBackendQueryParam, FlagListQueryParam } from 'utils/flagFilters/types';
import {
  FlagStatusKind,
  flagStatusKindDisplayNames,
  getFlagStatusKind,
  getFlagStatusKindDisplayName,
} from 'utils/flagStatusUtils';

const STATUS = 'status';
const STATUS_DISPLAY_NAME = 'Status';

class StatusFilter implements FlagFilterInterface<string> {
  name: string;
  value?: string;
  kind: FilterKind;
  queryParamName: QueryParamName;
  backendQueryParamName: BackendQueryParamName;
  operators: string[];
  defaultValue: string;

  constructor(value?: string) {
    this.name = STATUS_DISPLAY_NAME;
    this.value = value;
    this.kind = FilterKind.STATUS;
    this.queryParamName = FlagListQueryParam.STATUS;
    this.backendQueryParamName = FlagListBackendQueryParam.STATUS;
    this.operators = [FilterOperator.IS];
    this.defaultValue = FlagStatusKind.NEW;
  }

  getFilterValueDisplayName(value?: FilterValue) {
    if (!value) {
      return '';
    }
    return getFlagStatusKindDisplayName(value as FlagStatusKind) || '';
  }

  getDisplayName = () => this.name;

  getOptions() {
    return Object.entries(flagStatusKindDisplayNames).map(([value, label]) => ({
      label,
      name: label,
      value,
      filterOption: this.kind,
      isChecked: this.value === value,
    }));
  }

  isEnabled = () => isFlagStatusEnabled();

  isEmpty = () => isEmpty(this.value);

  toQuery = () => this.value;

  isInvalidFilter = () =>
    !this.isEmpty() &&
    !(
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      getFlagStatusKind(this.value!)
    ) /* eslint-enable @typescript-eslint/no-non-null-assertion */;

  clearFilter = () => (this.value = undefined);

  toBackendQuery() {
    if (this.isEmpty()) {
      return '';
    }
    return `${STATUS}:${this.value}`;
  }

  parseFilterParam(queryParts: string[]) {
    const [, value] = queryParts;
    return value;
  }

  getFilterData() {
    return {
      operators: this.operators,
      options: this.getOptions(),
      defaultValue: this.defaultValue,
    };
  }
}

/* eslint-disable import/no-default-export */
export default StatusFilter;
