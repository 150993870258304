import { isInvoiced as isLegacySubscriptionInvoiced } from '../internal/legacySubscription';
import { isInvoiced as isModernSubscriptionInvoiced } from '../internal/modernSubscription';
import { Subscription } from '../types';

import { isModernSubscription } from './isModernSubscription';

export function isInvoiced(subscription: Subscription) {
  if (isModernSubscription(subscription)) {
    return isModernSubscriptionInvoiced(subscription);
  }

  return isLegacySubscriptionInvoiced(subscription);
}
