import { useCallback, useState } from 'react';
import { ReleaseGuardianIcon } from '@gonfalon/icons';
import {
  trackAddMetricsClicked,
  trackEditMetricsClicked,
  trackMetricsClicked,
  trackRemoveAllMetricsClicked,
} from '@gonfalon/measured-rollouts';
import { Icon } from '@launchpad-ui/icons';
import { Button, Counter } from 'launchpad';

import { useMeasuredRolloutMetrics } from '../useMeasuredRolloutMetrics';

import { LegacyMeasuredRolloutMetricsPopover } from './LegacyMeasuredRolloutMetricsPopover';

type MeasuredRolloutMetricsButtonProps = {
  projectKey: string;
  flagKey: string;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export function LegacyMeasuredRolloutMetricsButton({
  projectKey,
  flagKey,
  setModalOpen,
}: MeasuredRolloutMetricsButtonProps) {
  const { metrics, isFetching, isPending, handleRemove } = useMeasuredRolloutMetrics(projectKey, flagKey);
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const isConfigured = metrics && metrics.length > 0;

  const handleAdd = useCallback(() => {
    trackAddMetricsClicked();
    setModalOpen(true);
  }, []);

  const handleMetricsClick = useCallback(() => {
    trackMetricsClicked();
  }, []);

  const handlePopoverInteraction = useCallback((isOpen: boolean) => {
    setPopoverOpen(isOpen);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setPopoverOpen(false);
  }, []);

  const handlePopoverEdit = useCallback(() => {
    trackEditMetricsClicked();
    setPopoverOpen(false);
    setModalOpen(true);
  }, []);

  const handlePopoverRemove = useCallback(async () => {
    metrics &&
      trackRemoveAllMetricsClicked({
        previousMetrics: metrics.map((m) => m.name),
      });
    await handleRemove();
    setPopoverOpen(false);
  }, [metrics, handleRemove]);

  if (isPending || isFetching) {
    return null;
  }

  return (
    <>
      {!isConfigured ? (
        <Button icon={<ReleaseGuardianIcon />} renderIconFirst onClick={handleAdd}>
          Add metrics
        </Button>
      ) : (
        <LegacyMeasuredRolloutMetricsPopover
          target={
            <Button icon={<ReleaseGuardianIcon />} renderIconFirst onClick={handleMetricsClick}>
              Metrics <Counter value={metrics.length} subtle /> <Icon name="chevron-down" size="small" />
            </Button>
          }
          metrics={metrics}
          isOpen={isPopoverOpen}
          onInteraction={handlePopoverInteraction}
          onClose={handlePopoverClose}
          onEdit={handlePopoverEdit}
          onRemove={handlePopoverRemove}
        />
      )}
    </>
  );
}
