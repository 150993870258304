import { MeasuredRollout, MeasuredRolloutEvent, MeasuredRolloutEventKind, MeasuredRolloutStatus } from './types';

const STOPPED_STATES: Array<Partial<MeasuredRolloutEventKind>> = [
  'manually_stopped',
  'archived',
  'completed',
  'reverted',
  'manually_completed',
  'manually_reverted',
  'rolled_out',
  'manually_rolled_out',
];

const REGRESSED_STATES: Array<Partial<MeasuredRolloutStatus>> = [
  'monitoring_regressed',
  'reverted',
  'monitoring_stopped',
];
const RUNNING_STATES: Array<Partial<MeasuredRolloutStatus>> = ['monitoring', 'monitoring_regressed'];

export function isRolloutInRunningState(measuredRollout: MeasuredRollout) {
  return RUNNING_STATES.includes(measuredRollout.state.status);
}

export function isRolloutInStoppedState(lastEventSeen: MeasuredRolloutEvent | undefined) {
  if (!lastEventSeen) {
    return false;
  }

  return STOPPED_STATES.includes(lastEventSeen.kind);
}

export function getRolloutEndedAt(lastEventSeen: MeasuredRolloutEvent | undefined) {
  if (!lastEventSeen || !isRolloutInStoppedState(lastEventSeen)) {
    return undefined;
  }

  return lastEventSeen.createdAt;
}

export function getRolloutStartedAt(measuredRollout: MeasuredRollout) {
  if (!isRolloutInRunningState(measuredRollout)) {
    return undefined;
  }

  return measuredRollout.state.monitoringStartedAt;
}

export function isRegressedState(status: MeasuredRolloutStatus | undefined) {
  if (!status) {
    return false;
  }

  return REGRESSED_STATES.includes(status);
}
