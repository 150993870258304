import { createContext } from 'react';

import actionTypes from 'actionTypes/experimentation';

import { patchExperimentErrorsByCode, postExperimentErrorsByCode } from '../api/errors';

export type Context = {
  alertExperimentCreateFailed(code?: string): void;

  alertExperimentUpdateFailed(code?: string): void;
  alertExperimentUpdateSucceeded(): void;
  alertExperimentArchiveSucceeded(): void;
  alertExperimentArchiveFailed(code?: string): void;
  alertExperimentRestoreSucceeded(): void;
  alertExperimentRestoreFailed(code?: string): void;
};

export const defaultContext: Context = {
  alertExperimentCreateFailed: () => null,
  alertExperimentUpdateFailed: () => null,
  alertExperimentUpdateSucceeded: () => null,
  alertExperimentArchiveSucceeded: () => null,
  alertExperimentArchiveFailed: () => null,
  alertExperimentRestoreSucceeded: () => null,
  alertExperimentRestoreFailed: () => null,
};

export const AlertsContext = createContext<Context>(defaultContext);

type Action = {
  type: string;
  payload?: Error;
  message?: string;
};

export type ProviderProps = {
  children: React.ReactNode;
  dispatcher?: (action: Action) => void;
};

const getAlertExperimentCreateFailed = (dispatcher: (action: Action) => void) => (code: string) => {
  const message = postExperimentErrorsByCode[code];
  dispatcher({ type: actionTypes.EXPERIMENT_SAVED_FAILED, message });
};

const getAlertExperimentUpdateFail = (dispatcher: (action: Action) => void) => (code: string) => {
  const message = patchExperimentErrorsByCode[code];
  dispatcher({ type: actionTypes.EXPERIMENT_SAVED_FAILED, message });
};

const getAlertExperimentSucceeded = (dispatcher: (action: Action) => void) => () => {
  dispatcher({ type: actionTypes.EXPERIMENT_SAVED_DONE });
};

const getAlertExperimentArchiveSucceeded = (dispatcher: (action: Action) => void) => () => {
  dispatcher({ type: actionTypes.EXPERIMENT_ARCHIVE_DONE });
};

const getAlertExperimentArchiveFailed = (dispatcher: (action: Action) => void) => (code: string) => {
  const message = patchExperimentErrorsByCode[code];
  dispatcher({ type: actionTypes.EXPERIMENT_ARCHIVE_FAILED, message });
};

const getAlertExperimentRestoreSucceeded = (dispatcher: (action: Action) => void) => () => {
  dispatcher({ type: actionTypes.EXPERIMENT_RESTORE_DONE });
};

const getAlertExperimentRestoreFailed = (dispatcher: (action: Action) => void) => (code: string) => {
  const message = patchExperimentErrorsByCode[code];
  dispatcher({ type: actionTypes.EXPERIMENT_RESTORE_FAILED, message });
};

export const AlertsProvider = ({ dispatcher = () => null, children }: ProviderProps) => (
  <AlertsContext.Provider
    value={{
      alertExperimentCreateFailed: getAlertExperimentCreateFailed(dispatcher),
      alertExperimentUpdateFailed: getAlertExperimentUpdateFail(dispatcher),
      alertExperimentUpdateSucceeded: getAlertExperimentSucceeded(dispatcher),
      alertExperimentArchiveSucceeded: getAlertExperimentArchiveSucceeded(dispatcher),
      alertExperimentArchiveFailed: getAlertExperimentArchiveFailed(dispatcher),
      alertExperimentRestoreSucceeded: getAlertExperimentRestoreSucceeded(dispatcher),
      alertExperimentRestoreFailed: getAlertExperimentRestoreFailed(dispatcher),
    }}
  >
    {children}
  </AlertsContext.Provider>
);
