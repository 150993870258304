import { type ModernSubscription } from '@gonfalon/billing';
import { hasLegacyPlan } from '@gonfalon/constants';
import { accountDetail, accountLegacySubscription, accountSubscription, membersProfile } from '@gonfalon/rest-api';
import { conditionalSuspenseQuery, isSuspenseQueryEnabled } from '@gonfalon/suspense';
import { useSuspenseQueries } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

export function useAccountContext() {
  const accountHasLegacyPlan = hasLegacyPlan() ?? false;

  const [profile, account, legacySubscription, modernSubscription] = useSuspenseQueries({
    queries: [
      membersProfile,
      accountDetail,
      conditionalSuspenseQuery({
        query: accountLegacySubscription,
        enabled: accountHasLegacyPlan,
      }),
      conditionalSuspenseQuery({
        query: accountSubscription,
        enabled: !accountHasLegacyPlan,
      }),
    ],
  });

  if (accountHasLegacyPlan && isSuspenseQueryEnabled(legacySubscription)) {
    return {
      profile: profile.data,
      account: account.data,
      subscription: legacySubscription.data,
      isLegacySubscription: true,
    } as const;
  }

  invariant(isSuspenseQueryEnabled(modernSubscription), 'Expected modern subscription query to be enabled');

  return {
    profile: profile.data,
    account: account.data,
    // We generally prefer validation (via a zod schema or similar) instead of casting, but in this case
    // if something went wrong, we'd be in big(ger) trouble anyways.
    subscription: modernSubscription.data as ModernSubscription,
    isLegacySubscription: false,
  } as const;
}
