import cx from 'clsx';

import './styles.css';

export type DataListToolbarProps = {
  className?: string;
  showBorder?: boolean;
  children?: React.ReactNode;
};

export const DataListToolbar = ({ className, children, showBorder = true }: DataListToolbarProps) => (
  <div
    className={cx(className, 'DataListToolbar', {
      'DataListToolbar-border': showBorder,
    })}
  >
    {children}
  </div>
);
