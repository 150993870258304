import { redirect } from 'react-router-dom';
import { enableFetchProfileContextFromAPI } from '@gonfalon/dogfood-flags';
import { flashMessage } from '@gonfalon/flash-messages';
import { fetchEnvironments, fetchSelectedEnvironments, isRESTAPIError } from '@gonfalon/rest-api';
import { createCustomSearchParamsUpdater } from '@gonfalon/router';

import { fetchProjectContextForRequest } from './fetchProjectContextForRequest';
import { parseProjectContextSearchParams } from './parseProjectContextSearchParams';
import { readProjectContextFromRequest } from './readProjectContextFromRequest';
import { putSelectedProjectKey } from './selectedProjectKeyCache';
import { serializeProjectContextSearchParams } from './serializeProjectContextSearchParams';

const projectContextParamsUpdater = createCustomSearchParamsUpdater({
  parse: parseProjectContextSearchParams,
  serialize: serializeProjectContextSearchParams,
});

export async function requireProjectContext(request: Request) {
  const projectContextFromRequest = readProjectContextFromRequest(request);

  // If we don't have a project context in the URL, there's not much we can do
  if (!projectContextFromRequest) {
    const url = new URL('/', window.location.origin);
    url.searchParams.set('root-redirect', 'true');
    throw redirect(url.toString());
  }

  let projectContext = projectContextFromRequest;

  // We don't have any environments in the URL
  if (projectContext.environmentKeys.size === 0) {
    projectContext = { ...projectContext };

    try {
      // Ask the API for the last set of environments
      const fromAPI = await fetchSelectedEnvironments({ projectKey: projectContext.projectKey });
      projectContext.environmentKeys = new Set(fromAPI.environments.map((env) => env.key));
      projectContext.selectedEnvironmentKey = fromAPI.selectedEnvironmentKey;
    } catch (error) {
      if (isRESTAPIError(error) && error.status === 404) {
        flashMessage(
          'missing-project',
          `Project with key "${projectContext.projectKey}" not found. Select another project to continue.`,
        );
        // TODO: It would be better if we could use the navigator here. But we can't because
        // of the circular dependency: @gonfalon/navigator -> @gonfalon/context -> @gonfalon/navigator
        throw redirect('/settings/projects');
      }

      throw new Response('Unexpected error fetching project context', { status: 500 });
    }

    // The API didn't have any saved environments either
    if (projectContext.environmentKeys.size === 0) {
      // Pick the first two environments from the project by ascending alphabetical order
      projectContext.environmentKeys = new Set(
        (
          await fetchEnvironments({
            projectKey: projectContext.projectKey,
            params: { sort: 'name', limit: 2 },
          })
        ).items
          .slice(0, 2)
          .map((env) => env.key),
      );

      // If we still don't have any, we're in trouble
      if (projectContext.environmentKeys.size === 0) {
        throw new Response('unexpected error', { status: 500 });
      }
    }
  }

  const url = new URL(request.url);

  if (
    projectContext.selectedEnvironmentKey === undefined ||
    projectContext.selectedEnvironmentKey === '' ||
    !projectContext.environmentKeys.has(projectContext.selectedEnvironmentKey)
  ) {
    projectContext = { ...projectContext };
    projectContext.selectedEnvironmentKey = Array.from(projectContext.environmentKeys)[0];
  }

  // If the project context was modified, redirect to the new URL
  if (projectContext !== projectContextFromRequest) {
    const nextUrl = new URL(url);

    const newSearchParamsInit = projectContextParamsUpdater(nextUrl.searchParams, projectContext);

    nextUrl.search = newSearchParamsInit.toString();

    throw redirect(nextUrl.toString());
  }

  if (!enableFetchProfileContextFromAPI()) {
    // TODO: the server should cache the last selected project key, but do it here for now
    // to improve the dogfooding experience
    // NOTE for later: is this still required?
    putSelectedProjectKey(projectContext.projectKey);
  }

  try {
    return await fetchProjectContextForRequest(request);
  } catch (error) {
    if (isRESTAPIError(error) && error.status === 404) {
      flashMessage(
        'missing-project',
        `Project with key "${projectContext.projectKey}" not found. Select another project to continue.`,
      );
      throw redirect('/settings/projects');
    }

    throw new Response('Unexpected error fetching project context', { status: 500 });
  }
}
