import { useRandomizationUnit } from '../../hooks/useRandomizationUnit';

export function RandomizationUnitLabel({
  randomizationUnit,
  className,
}: {
  randomizationUnit: string;
  className?: string;
}) {
  const { data } = useRandomizationUnit(randomizationUnit);

  return <code className={className}>{data?._displayName ?? randomizationUnit}</code>;
}
