import { Button, ButtonProps } from 'launchpad';

type DestructiveButtonProps = Omit<ButtonProps, 'kind'>;

const DestructiveButton = ({ children, ...other }: DestructiveButtonProps) => (
  <Button {...other} kind="destructive">
    {children}
  </Button>
);

export { DestructiveButton };
