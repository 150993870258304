import { isSeatLimitEnforced as isSeatLimitEnforcedFlag } from '@gonfalon/dogfood-flags';

import { ModernSubscription, SubscriptionUsage } from '../../types';

import { getSeatLimit } from './getSeatLimit';
import { isSeatLimitReached } from './isSeatLimitReached';

// return the number of unused but paid for seats
export function getRemainingSeats(subscription: ModernSubscription, subscriptionUsage: SubscriptionUsage) {
  const isSeatLimitEnforced = isSeatLimitEnforcedFlag();

  const seatLimit = getSeatLimit(subscription);

  if (!isSeatLimitEnforced || seatLimit === -1) {
    return -1;
  }

  if (!isSeatLimitReached(subscription, subscriptionUsage) && subscriptionUsage?.SeatCount !== undefined) {
    return seatLimit - subscriptionUsage.SeatCount;
  }

  return 0;
}
