import { LegacySubscription } from '../../types';

import { getRemainingSeats } from './getRemainingSeats';
import { getSeatLimit } from './getSeatLimit';

export function getSeatAvailability(legacySubscription: LegacySubscription, numberOfSeatsToAdd: number) {
  // They have paid for this many seats
  const subscriptionSeatLimit = getSeatLimit(legacySubscription);

  const numberOfUnusedButPaidForSeats = getRemainingSeats(legacySubscription);

  // Of the seats they've already paid for, they have this many still available
  const numberOfNewSeatsLeft = numberOfUnusedButPaidForSeats - numberOfSeatsToAdd;

  // If numberOfUnusedButPaidForSeats === -1, they have unlimited seats, so don't need to purchase any new seats
  const planHasUnlimitedSeats = numberOfUnusedButPaidForSeats === -1;

  // If they don't have unlimited seats, they might need to purchase some so they can invite everyone
  const numberOfNewSeatsToPurchase = planHasUnlimitedSeats ? 0 : Math.max(0, numberOfNewSeatsLeft * -1);

  // The current amount of seats in the subscription plus the additional new seats that need to be purchased
  const totalNewSeats = subscriptionSeatLimit + numberOfNewSeatsToPurchase;

  // modern subscriptions only
  const isPlanUpgradeNeeded = false;
  const canRenderUpsell = false;
  const canRenderRequestSeatsUpsell = false;

  return {
    totalNewSeats,
    numberOfNewSeatsToPurchase,
    canRenderUpsell,
    canRenderRequestSeatsUpsell,
    numberOfNewSeatsLeft,
    planHasUnlimitedSeats,
    isPlanUpgradeNeeded,
    planSeatLimit: subscriptionSeatLimit,
  };
}
