import { type Theme } from '@gonfalon/theme';
import tokens from '@launchpad-ui/tokens';
// This module does exist…
// eslint-disable-next-line import/no-unresolved
import { ColorSpace, deltaE2000, LCH, sRGB } from 'colorjs.io/fn';

ColorSpace.register(sRGB); // for parsing
ColorSpace.register(LCH); // for calculations

// Source: https://colorjs.io/docs/color-difference#:~:text=For%20most%20DeltaE%20algorithms%2C%202.3%20is%20considered%20the%20%22Just%20Noticeable%20Difference%22%20(JND).
const justNoticeableDifference = 0.023;

const distanceFn = deltaE2000;

type SpecialCaseTest = (color: string) => boolean;

const closeTo = (a: string) => (b: string) => distanceFn(a, b) < justNoticeableDifference;

// Implement special cases by using the same perceptual distance function
// we use for mapping colors to the design system.
const specialCases: SpecialCaseTest[] = [
  closeTo('#fff'),
  closeTo('#000'),

  // These 2 grays are part of the default palette we provide when creating environments. We want to avoid
  // map them to the fallback color.
  closeTo('#d1d3d4'),
  closeTo('#939598'),
];

function isSpecialCase(color: string) {
  return specialCases.some((test) => test(color));
}

const lightFallback = __TEST__ ? '' : tokens.color.gray[500];
const lightMode = __TEST__
  ? []
  : ([
      tokens.color.blue[500],
      tokens.color.brand.cyan.base,
      tokens.color.brand.pink.base,
      tokens.color.purple[500],
      tokens.color.red[500],
      tokens.color.green[500],
      tokens.color.brand.yellow.base,
    ] as const);

const darkFallback = __TEST__ ? '' : tokens.color.gray[400];
const darkMode = __TEST__
  ? []
  : ([
      tokens.color.blue[400],
      tokens.color.brand.cyan.light,
      tokens.color.brand.pink.light,
      tokens.color.purple[400],
      tokens.color.red[400],
      tokens.color.green[400],
      tokens.color.brand.yellow.light,
    ] as const);

// Map a color to the nearest midtone color in the design system palette, perceptually.
// See https://colorjs.io/docs/color-difference for more information.
export function computeRingColor(referenceColor: string, theme: Theme) {
  const ref = referenceColor.startsWith('#') ? referenceColor : `#${referenceColor}`;

  const fallback = theme === 'default' ? lightFallback : darkFallback;
  if (isSpecialCase(ref)) {
    return fallback;
  }

  const palette = theme === 'default' ? lightMode : darkMode;
  const paletteByDistance = Array.from(palette).sort((a, b) => distanceFn(ref, a) - distanceFn(ref, b));
  return paletteByDistance[0];
}
