import { slackAppHost } from '@gonfalon/constants';
// eslint-disable-next-line no-restricted-imports
import { fromJS, Map } from 'immutable';
import { createSelector } from 'reselect';

import { WebhookAction } from 'actions/webhooks';
import formActionTypes from 'actionTypes/forms';
import { GlobalState } from 'reducers';
import registry from 'reducers/registry';
import { createFormState } from 'utils/formUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { createWebhookForm, Webhook, WebhookForm } from 'utils/webhookUtils';

const SLACK_APP_HOST = slackAppHost();

export const webhookFormKey = 'webhookForm';

const initialFormState = (webhook = createWebhookForm()) => createFormState(webhook);

export function webhookForm(state = initialFormState(), action: WebhookAction) {
  switch (action.type) {
    case 'webhooks/EDIT_WEBHOOK':
      return state.trackField(action.field).revalidate(action.webhook);
    case 'webhooks/CREATE_WEBHOOK':
    case 'webhooks/UPDATE_WEBHOOK':
      return state.submitting();
    case 'webhooks/CREATE_WEBHOOK_FAILED':
      return state.submitFailed(action.webhook.toForm(), action.error);
    case 'webhooks/UPDATE_WEBHOOK_FAILED':
      return state.submitFailed(state.modified, action.error);
    case 'webhooks/UPDATE_WEBHOOK_DONE':
      return state.submitted(action.webhook.toForm());
    case formActionTypes.BLUR:
      if (action.model !== webhookFormKey) {
        return state;
      }
      return state.handleBlur(action.field, state.modified);
    case formActionTypes.INITIALIZE:
      if (action.model !== webhookFormKey) {
        return state;
      }
      return initialFormState(action.initialState as WebhookForm);
    case formActionTypes.DESTROY:
      if (action.model !== webhookFormKey) {
        return state;
      }
      return initialFormState();
    case formActionTypes.DISABLE_SUBMIT:
      return state.disableSubmit();
    case formActionTypes.ENABLE_SUBMIT:
      return state.enableSubmit();
    default:
      return state;
  }
}

type WebhookState = ImmutableMap<{
  entities: Map<string, Webhook>;
  lastFetched: number;
  isFetching: boolean;
  error?: Error;
}>;

export function webhooks(
  state: WebhookState = fromJS({
    entities: {},
    lastFetched: null,
    isFetching: false,
  }),
  action: WebhookAction,
) {
  switch (action.type) {
    case 'webhooks/REQUEST_WEBHOOKS':
      return state.set('isFetching', true);
    case 'webhooks/REQUEST_WEBHOOKS_FAILED':
      return state.merge({
        isFetching: false,
        error: action.error,
      });
    case 'webhooks/REQUEST_WEBHOOKS_DONE':
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        entities: action.response.getIn(['entities', 'webhooks']),
      });
    case 'webhooks/CREATE_WEBHOOK_DONE':
    case 'webhooks/UPDATE_WEBHOOK_DONE':
      return state.update('entities', (entities) => entities.set(action.webhook._id, action.webhook));
    case 'webhooks/DELETE_WEBHOOK_DONE':
      return state.update('entities', (entities) => entities.delete(action.webhook._id));
    default:
      return state;
  }
}

export const webhooksSelector = (state: GlobalState): WebhookState => state.webhooks;

export const getSlackAppWebhooks = (webhookState: WebhookState) =>
  webhookState.get('entities').filter((webhook) => !!SLACK_APP_HOST && webhook.url.startsWith(SLACK_APP_HOST));

export const webhookByIdSelector = createSelector(
  webhooksSelector,
  (_: GlobalState, props: { match: { params: { webhookId: string } } }) => props.match.params.webhookId,
  (wh, webhookId) =>
    fromJS({
      lastFetched: wh.get('lastFetched'),
      isFetching: wh.get('isFetching'),
      entity: wh.getIn(['entities', webhookId]),
    }),
);

export const webhookFormSelector = (state: GlobalState) => state.webhookForm;

registry.addReducers({ webhookForm, webhooks });
