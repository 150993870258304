import { lazy, Suspense, useState } from 'react';
import { Button } from '@launchpad-ui/components';

import { Member } from 'utils/accountUtils';
import { getComponentAnalytics } from 'utils/analyticsUtils';
import { Subscription } from 'utils/billingUtils';
import { trackEnterpriseCampaignCtaClicked } from 'utils/campaignUtils';

import styles from './styles/StartEnterpriseCampaignButton.module.css';

const ConfirmEnterpriseCampaignModal = lazy(
  async () =>
    import(
      /* webpackChunkName: "ConfirmEnterpriseCampaignModal" */ 'components/billing/ConfirmEnterpriseCampaignModal'
    ),
);

export type StartEnterpriseCampaignButtonProps = {
  subscription: Subscription;
  enableSelfServiceEnterpriseTrials: boolean;
  onSetTrialEnterpriseCampaign(): void;
  cta?: string;
  upsellFeaturePageKind: string;
  profile: Member;
};

export const StartEnterpriseCampaignButton = ({
  subscription,
  onSetTrialEnterpriseCampaign,
  enableSelfServiceEnterpriseTrials,
  cta = 'Start an Enterprise trial',
  upsellFeaturePageKind,
  profile,
}: StartEnterpriseCampaignButtonProps) => {
  const [showEnterprisePlanModal, setShowEnterprisePlanModal] = useState(false);

  const renderEnterpriseTrialModal = () => {
    if (showEnterprisePlanModal) {
      return (
        <Suspense fallback={null}>
          <ConfirmEnterpriseCampaignModal
            showModal={showEnterprisePlanModal}
            setShowModal={setShowEnterprisePlanModal}
            onCtaClick={onSetTrialEnterpriseCampaign}
            onModalCancel={() => setShowEnterprisePlanModal(false)}
          />
        </Suspense>
      );
    }
  };

  const handleStartEntFreeTrialBtnClicked = () => {
    setShowEnterprisePlanModal(true);

    trackEnterpriseCampaignCtaClicked(
      upsellFeaturePageKind,
      getComponentAnalytics({
        component: 'PlanSummary',
        cta,
        type: 'button',
      }),
    );
  };

  if (
    enableSelfServiceEnterpriseTrials &&
    profile.isAdminOrOwner() &&
    !subscription.getEnterpriseCampaign()?.isActive() &&
    !subscription.getEnterpriseCampaign()?.isExpired() &&
    subscription.canStartEnterpriseCampaign()
  ) {
    return (
      <>
        {renderEnterpriseTrialModal()}
        {enableSelfServiceEnterpriseTrials &&
          !subscription.getEnterpriseCampaign()?.isActive() &&
          !subscription.getEnterpriseCampaign()?.isExpired() &&
          subscription.canStartEnterpriseCampaign() && (
            <Button className={styles.button} onPress={handleStartEntFreeTrialBtnClicked}>
              {cta}
            </Button>
          )}
      </>
    );
  } else {
    return null;
  }
};
