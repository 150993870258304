import { TextArea } from 'launchpad';

type CommentAreaProps = {
  value: string;
  focusRef?(): void;
  onChange?(event: React.ChangeEvent<HTMLTextAreaElement>): void;
  placeholder?: string;
  onBlur?(): void;
  onFocus?(): void;
  className?: string;
  rows?: number;
  required?: boolean;
  disabled?: boolean;
};

const CommentArea = ({
  required,
  focusRef,
  value,
  placeholder = '',
  className,
  onBlur,
  onFocus,
  onChange,
  rows,
  disabled,
}: CommentAreaProps) => {
  const rowCount = rows ? rows : 4;
  return (
    <TextArea
      disabled={disabled}
      required={required}
      id={COMMENT_AREA_ID}
      name={COMMENT_AREA_ID}
      rows={rowCount}
      value={value}
      ref={focusRef}
      onChange={onChange}
      placeholder={placeholder}
      className={className}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};

export const COMMENT_AREA_ID = 'comment';

/* eslint-disable import/no-default-export */
export default CommentArea;
