import { capitalize } from '@gonfalon/strings';

import { VariationType } from 'utils/flagUtils';

import { VARIATION_NOT_FOUND } from './instructionListUtils';
import { VariationValue } from './variationInstructionListItemUtils';

export type RemoveVariationInstructionEntryProps = {
  variationType: VariationType;
  isVariationNotFound?: boolean;
  value: string;
};

export function RemoveVariationInstructionEntry({
  isVariationNotFound,
  value,
  variationType,
}: RemoveVariationInstructionEntryProps) {
  return (
    <>
      <span className="u-mr-s">with value:</span>
      {isVariationNotFound ? (
        <code>{capitalize(VARIATION_NOT_FOUND)}</code>
      ) : (
        <VariationValue value={value} variationType={variationType} />
      )}
    </>
  );
}
