import { isEmpty } from '@gonfalon/es6-utils';
// eslint-disable-next-line no-restricted-imports
import { fromJS, Map, Record, Set } from 'immutable';
import qs from 'qs';

import { QueryParams } from 'reducers/router';
import { allowDecision, createAccessDecision } from 'utils/accessUtils';
import { createMemberSummary, MemberSummary } from 'utils/accountUtils';
import { CreateFunctionInput } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';
import { isPolicyString } from 'utils/policyUtils';
import { stringContains } from 'utils/stringUtils';
import { isJSON, isNotEmpty, optional, validateCustomRoles, validateRecord } from 'utils/validationUtils';

export const TOKEN_DISPLAY_LENGTH = 4;

export type AccessTokenFormProps = {
  _links: Link;
  name: string;
  role: string;
  customRoleIds: Set<string>;
  inlineRole: string | null | string[];
  defaultApiVersion: null | number | string;
  serviceToken: boolean;
  creationDate: number;
};
export class AccessTokenForm extends Record<AccessTokenFormProps>({
  _links: Map(),
  name: '',
  role: 'reader',
  customRoleIds: Set(),
  inlineRole: null,
  defaultApiVersion: null,
  serviceToken: false,
  creationDate: 0,
}) {
  validate(forceCustom: boolean) {
    let predicates = [
      isNotEmpty('name'),
      validateCustomRoles((token: AccessToken) => token.customRoleIds, forceCustom),
      optional(isJSON)('statements'),
      optional(isPolicyString)('statements'),
    ];

    if (this.inlineRole) {
      predicates = predicates.concat(isJSON('inlineRole'), isPolicyString('inlineRole'));
    }

    return validateRecord(this, ...predicates);
  }

  selfLink() {
    return this._links.getIn(['self', 'href']);
  }

  toRep() {
    return fromJS(this.toJSON()).withMutations((map: AccessTokenForm) => {
      map.delete('_links');

      if (!map.get('role') || !map.get('customRoleIds').isEmpty() || map.get('inlineRole')) {
        map.delete('role');
      }

      if (map.get('customRoleIds').isEmpty()) {
        map.delete('customRoleIds');
      }

      if (!map.get('inlineRole')) {
        map.delete('inlineRole');
      } else {
        map.update('inlineRole', (ir) => {
          if (Array.isArray(ir)) {
            return ir.map((i) => JSON.parse(i));
          } else {
            return ir && JSON.parse(ir);
          }
        });
      }

      if (!map.get('defaultApiVersion')) {
        map.delete('defaultApiVersion');
      } else {
        map.update('defaultApiVersion', (v) => (typeof v === 'string' ? parseInt(v, 10) : v));
      }
    });
  }
}

export const createAccessTokenForm = (props?: CreateFunctionInput<AccessTokenForm>) =>
  props instanceof AccessTokenForm ? props : new AccessTokenForm(fromJS(props));

type AccessTokenProps = AccessTokenFormProps & {
  _id: string;
  _member: null | MemberSummary;
  token: string;
  lastUsed: number;
  creationDate: number;
};
export class AccessToken extends Record<AccessTokenProps>({
  _links: Map(),
  _id: '',
  _member: null,
  name: '',
  token: '',
  role: '',
  customRoleIds: Set(),
  inlineRole: null,
  defaultApiVersion: null,
  serviceToken: false,
  lastUsed: -1,
  creationDate: 0,
}) {
  selfLink() {
    return this._links.getIn(['self', 'href']);
  }

  siteLink() {
    return this._links.getIn(['site', 'href']);
  }

  isStatusToken() {
    return this.token.startsWith('sts');
  }

  getCreatorDisplayName() {
    return this._member ? this._member.getDisplayName() : 'a deleted member';
  }

  toForm() {
    return createAccessTokenForm({
      _links: this._links,
      name: this.name,
      role: this.role,
      customRoleIds: this.customRoleIds,
      inlineRole: this.inlineRole ? JSON.stringify(this.inlineRole, null, 2) : null,
      defaultApiVersion: this.defaultApiVersion,
      serviceToken: this.serviceToken,
    });
  }
}

export const filterAccessToken = (token: AccessToken, term: string, type?: string) => {
  let fields = [token.name];

  if (token._member) {
    fields = fields.concat(token._member.getDisplayName());
  }

  // Special case for when we want to redirect to a filtered token without knowing it's name
  if (token._id && token._id === term) {
    return true;
  }

  // Special case any substring of `api-`, as it would match any full token value
  if (!stringContains('api-', term)) {
    fields = fields.concat(token.token);
  }
  if (!fields.some((f) => stringContains(f, term))) {
    return false;
  }

  switch (type) {
    case 'service':
      return token.serviceToken;
    case 'personal':
      return !token.serviceToken;
    default:
  }
  return true;
};

export const createAccessToken = (props?: CreateFunctionInput<AccessToken>) => {
  const token = props instanceof AccessToken ? props : new AccessToken(fromJS(props));
  return token.withMutations((t) => {
    t.update('_member', (m) => (m ? createMemberSummary(m) : null));
    t.update('customRoleIds', (rs) => (rs ? rs.toSet() : rs));
  });
};

type AccessTokenFiltersProps = {
  accessTokenQuery: string;
  accessTokenType: string;
  createdBy: string;
};

export class AccessTokenFilters extends Record<AccessTokenFiltersProps>({
  accessTokenQuery: '',
  accessTokenType: '',
  createdBy: '',
}) {
  isEmpty() {
    return isEmpty(this.createdBy) && isEmpty(this.accessTokenQuery) && isEmpty(this.accessTokenType);
  }

  toQuery() {
    const ret: { accessTokenQuery?: string; createdBy?: string; accessTokenType?: string } = {};

    if (!isEmpty(this.accessTokenQuery)) {
      ret.accessTokenQuery = this.accessTokenQuery;
    }

    if (!isEmpty(this.createdBy)) {
      ret.createdBy = this.createdBy;
    }

    if (!isEmpty(this.accessTokenType)) {
      ret.accessTokenType = this.accessTokenType;
    }

    return ret;
  }

  toQueryString() {
    return qs.stringify(this.toQuery(), { indices: false });
  }
}

export function createAccessTokenFilters(props = {}) {
  return props instanceof AccessTokenFilters ? props : new AccessTokenFilters(fromJS(props));
}

export function createAccessTokenFiltersFromQuery(query: QueryParams) {
  const accessTokenQuery = query && query.get('accessTokenQuery');
  const createdBy = query && query.get('createdBy');
  const accessTokenType = query && query.get('accessTokenType');

  return createAccessTokenFilters({
    accessTokenQuery,
    createdBy,
    accessTokenType,
  });
}

export function getAccessTokenDecision(token?: AccessToken) {
  return !!token && token.serviceToken
    ? createAccessDecision({ isAllowed: false, isServiceToken: true })
    : allowDecision();
}

export const isNewAccessToken = (t: AccessToken) => t.token.length > TOKEN_DISPLAY_LENGTH;
