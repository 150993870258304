import classNames from 'clsx';
import type { ButtonProps } from 'launchpad';

import ContactUsButton from 'components/contactUs/ContactUsButton';
import { ContactUsModal } from 'components/contactUs/ContactUsModal';
import useToggle from 'hooks/useToggle';

import styles from './styles/ContactUs.module.css';

export type ContactUsProps = {
  componentForAnalyticTracking: string;
  featureKind?: string;
  productInterest?: string;
  className?: string;
  isLink?: boolean;
  isTrialBanner?: boolean;
  cta?: string;
  isEnterpriseOnlyFeature?: boolean;
  isAddOnFeature?: boolean;
  modalHeaderText?: string;
  buttonKind?: ButtonProps['kind'];
  buttonSize?: ButtonProps['size'];
  contactSales?: boolean;
};

export const ContactUs = ({
  componentForAnalyticTracking,
  featureKind,
  productInterest,
  cta,
  className,
  isLink,
  isTrialBanner,
  modalHeaderText,
  isEnterpriseOnlyFeature = false,
  isAddOnFeature = false,
  buttonKind,
  buttonSize,
  contactSales,
}: ContactUsProps) => {
  const [openContactUsModal, handleOpenContactUsModal] = useToggle(false);

  return (
    <>
      <ContactUsButton
        featureKind={featureKind}
        isEnterpriseOnlyFeature={isEnterpriseOnlyFeature}
        isAddOnFeature={isAddOnFeature}
        onOpenModal={handleOpenContactUsModal}
        className={className}
        componentForAnalyticTracking={componentForAnalyticTracking}
        isLink={isLink}
        cta={cta}
        isTrialBanner={isTrialBanner}
        buttonKind={buttonKind}
        buttonSize={buttonSize}
        contactSales={contactSales}
      />
      <div
        className={classNames(styles.contactUs, { 'contact-form': openContactUsModal })}
        data-test-id="ContactUsModal"
      >
        <ContactUsModal
          isOpen={openContactUsModal}
          onCancel={handleOpenContactUsModal}
          requestedFeature={featureKind}
          productInterest={productInterest}
          headerText={modalHeaderText}
        />
      </div>
    </>
  );
};

/* eslint-disable import/no-default-export */
export default ContactUs;
