import { createStorageScope } from './createStorageScope';
import { getBrowserStorage } from './getBrowserStorage';
import { noopStorageSlot } from './StorageSlot';
import { defaultStorageType, StorageType } from './StorageType';

export function clearAllStorageSlots(
  { storageType }: { storageType?: StorageType } = { storageType: defaultStorageType },
) {
  const storage = getBrowserStorage(storageType);
  if (storage === undefined) {
    return noopStorageSlot;
  }

  const scope = createStorageScope();

  for (const [key] of Object.entries(storage)) {
    if (key.startsWith(scope)) {
      storage.removeItem(key);
    }
  }

  return storage;
}
