import { isBefore } from 'date-fns';

import { LegacySubscription } from '../../types';

export function isTrialExpired(legacySubscription: LegacySubscription) {
  const { trialEndDate } = legacySubscription;
  // trialEndDate can be an empty string or a valid date string
  // new Date('') returns an 'invalid date' error, and causes isTrialExpired to return false
  const date = !!trialEndDate ? new Date(trialEndDate) : 0;
  return isBefore(date, Date.now());
}
