import { Dispatch } from 'redux';

import actionTypes from 'actionTypes/policies';
import { GlobalState } from 'reducers';
// eslint-disable-next-line import/no-namespace
import * as ActionAPI from 'sources/ActionAPI';

function fetchPolicyActions() {
  return async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.REQUEST_POLICY_ACTIONS });
    return ActionAPI.getActions()
      .then((response) => dispatch({ type: actionTypes.REQUEST_POLICY_ACTIONS_DONE, response }))
      .catch((error) => dispatch({ type: actionTypes.REQUEST_POLICY_ACTIONS_FAILED, error }));
  };
}

function shouldFetchPolicyActions(state: GlobalState) {
  return !state.policyActions.get('lastFetched') && !state.policyActions.get('isFetching');
}

function fetchPolicyActionsIfNeeded() {
  return (dispatch: $TSFixMe, getState: () => GlobalState) => {
    if (shouldFetchPolicyActions(getState())) {
      return dispatch(fetchPolicyActions());
    }
  };
}

export { fetchPolicyActionsIfNeeded as fetchPolicyActions };
