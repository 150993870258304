import type { ClauseValue } from '@gonfalon/clauses';
import { type List, fromJS } from 'immutable';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const required = (value: any) => fromJS({ type: 'required', value });
export const min = (m: number, value: number) => fromJS({ type: 'min', min: m, value });
export const max = (m: number, value: number) => fromJS({ type: 'max', max: m, value });
export const minLength = (m: number, value: number) => fromJS({ type: 'minLength', minLength: m, value });
export const oneOf = (values: string[], value: string) => fromJS({ type: 'oneOf', values, value });
export const invalidRegExp = (value: List<ClauseValue>) => fromJS({ type: 'invalidRegExp', value });
export const invalidSemVer = (value: List<ClauseValue>) => fromJS({ type: 'invalidSemVer', value });
