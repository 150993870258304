import { track } from '@gonfalon/analytics';
import { List } from 'immutable';

import { CustomWorkflowType } from 'components/CustomWorkflows/types';
import { GlobalState } from 'reducers';
import reducerRegistry from 'reducers/registry';
import { FlagConfiguration } from 'utils/flagUtils';
import { AddRuleSemanticInstruction } from 'utils/instructions/rules/types';
import { pureReplaceAtIndex } from 'utils/reduxUtils';

import { WorkflowBuilderStateReducerAction } from './actions';
import {
  canAddCondition,
  canAddInstruction,
  createWorkflowBuilderStep,
  doStepKindsMatch,
  generateKey,
  hasKey,
  isApprovalStep,
  isInstructionStep,
  isLastStep,
  isPreviousStepInstruction,
  isScheduleStep,
  WORKFLOW_BUILDER_STAGES_LIMIT,
  WorkflowBuilderInstructionStep,
  WorkflowBuilderStateType,
  WorkflowBuilderUpdateTargetsSemanticInstructionsContainer,
} from './utils';

const createInitialState = () =>
  ({ name: 'Workflow', stages: [], type: CustomWorkflowType.CUSTOM_WORKFLOW }) as WorkflowBuilderStateType;
export function workflowBuilder(
  state: WorkflowBuilderStateType = createInitialState(),
  action: WorkflowBuilderStateReducerAction,
): WorkflowBuilderStateType {
  switch (action.type) {
    case 'workflowBuilder/INITIALIZE_STATE': {
      return action.payload.initialState;
    }
    case 'workflowBuilder/RESET_STATE': {
      return createInitialState();
    }
    case 'workflowBuilder/EDIT_WORKFLOW_NAME': {
      return { ...state, name: action.payload.name };
    }
    case 'workflowBuilder/EDIT_WORKFLOW_DESCRIPTION': {
      return { ...state, description: action.payload.description };
    }
    case 'workflowBuilder/ADD_CONDITION_STEP': {
      if (state.stages.length === 0) {
        return {
          ...state,
          stages: [
            {
              key: generateKey(),
              name: 'Stage 1',
              steps: [createWorkflowBuilderStep(action.payload.condition)],
            },
          ],
        };
      }

      const stageIndex = state.stages.findIndex(hasKey(action.payload.stageKey));
      const stage = state.stages[stageIndex];

      if (!stage || !canAddCondition(stage.steps, action.payload.index, action.payload.condition.kind)) {
        return state;
      }

      const stages = [...state.stages];

      if (
        isLastStep(stage.steps, action.payload.index) &&
        isPreviousStepInstruction(stage.steps, action.payload.index) &&
        stages.length < WORKFLOW_BUILDER_STAGES_LIMIT
      ) {
        const newStage = {
          key: generateKey(),
          name: `Stage ${stageIndex + 2}`,
          steps: [createWorkflowBuilderStep(action.payload.condition)],
        };

        stages.splice(stageIndex + 1, 0, newStage);

        track('Add Step', 'WorkflowBuilder', { kind: action.payload.condition.kind });

        return { ...state, stages };
      }

      const steps = [...stage.steps];
      steps.splice(action.payload.index, 0, createWorkflowBuilderStep(action.payload.condition));

      stages[stageIndex] = { ...stage, steps };

      return { ...state, stages };
    }
    case 'workflowBuilder/ADD_INSTRUCTION_STEP': {
      const stages = state.stages.map((stage) => {
        if (
          stage.key !== action.payload.stageKey ||
          !canAddInstruction(
            stage.steps,
            action.payload.index,
            action.payload.instruction.kind,
            (
              action.payload as {
                context?: { flagConfiguration: FlagConfiguration; pendingRules: AddRuleSemanticInstruction[] };
              }
            ).context,
          )
        ) {
          return stage;
        }

        const steps = [...stage.steps];
        steps.splice(action.payload.index, 0, createWorkflowBuilderStep(action.payload.instruction));

        return { ...stage, steps };
      });

      track('Add Step', 'WorkflowBuilder', { kind: action.payload.instruction.kind });

      return { ...state, stages };
    }
    case 'workflowBuilder/UPDATE_SCHEDULE_DATE': {
      const stageIndex = state.stages.findIndex(hasKey(action.payload.stageKey));
      const stage = state.stages[stageIndex];

      const scheduleStepIndex = stage?.steps.findIndex(hasKey(action.payload.stepKey));
      const step = stage?.steps[scheduleStepIndex];
      if (!stage || !step || !isScheduleStep(step)) {
        return state;
      }

      const stages = [...state.stages];
      const steps = [...stage.steps];
      const updatedStep = {
        key: step.key,
        content: step.content.set(
          'executionDate',
          action.payload.date instanceof Date ? action.payload.date.valueOf() : 0,
        ),
      };
      steps.splice(scheduleStepIndex, 1, updatedStep);
      stages.splice(stageIndex, 1, { ...stage, steps });

      return { ...state, stages };
    }
    case 'workflowBuilder/UPDATE_SCHEDULE_KIND': {
      const stageIndex = state.stages.findIndex(hasKey(action.payload.stageKey));
      const stage = state.stages[stageIndex];

      const scheduleStepIndex = stage?.steps.findIndex(hasKey(action.payload.stepKey));
      const step = stage?.steps[scheduleStepIndex];
      if (!stage || !step || !isScheduleStep(step)) {
        return state;
      }

      const stages = [...state.stages];
      const steps = [...stage.steps];
      const updatedStep = {
        key: step.key,
        content: step.content.set('scheduleKind', action.payload.scheduleKind),
      };
      steps.splice(scheduleStepIndex, 1, updatedStep);
      stages.splice(stageIndex, 1, { ...stage, steps });

      return { ...state, stages };
    }
    case 'workflowBuilder/UPDATE_SCHEDULE_WAIT_DURATION': {
      const stageIndex = state.stages.findIndex(hasKey(action.payload.stageKey));
      const stage = state.stages[stageIndex];

      const scheduleStepIndex = stage?.steps.findIndex(hasKey(action.payload.stepKey));
      const step = stage?.steps[scheduleStepIndex];
      if (!stage || !step || !isScheduleStep(step)) {
        return state;
      }

      const stages = [...state.stages];
      const steps = [...stage.steps];
      const updatedStep = {
        key: step.key,
        content: step.content.set('waitDuration', action.payload.waitDuration),
      };
      steps.splice(scheduleStepIndex, 1, updatedStep);
      stages.splice(stageIndex, 1, { ...stage, steps });

      return { ...state, stages };
    }
    case 'workflowBuilder/UPDATE_SCHEDULE_WAIT_DURATION_UNIT': {
      const stageIndex = state.stages.findIndex(hasKey(action.payload.stageKey));
      const stage = state.stages[stageIndex];

      const scheduleStepIndex = stage?.steps.findIndex(hasKey(action.payload.stepKey));
      const step = stage?.steps[scheduleStepIndex];
      if (!stage || !step || !isScheduleStep(step)) {
        return state;
      }

      const stages = [...state.stages];
      const steps = [...stage.steps];
      const updatedStep = {
        key: step.key,
        content: step.content.set('waitDurationUnit', action.payload.waitDurationUnit),
      };
      steps.splice(scheduleStepIndex, 1, updatedStep);
      stages.splice(stageIndex, 1, { ...stage, steps });

      return { ...state, stages };
    }
    case 'workflowBuilder/UPDATE_APPROVAL_DESCRIPTION': {
      const stageIndex = state.stages.findIndex(hasKey(action.payload.stageKey));
      const stage = state.stages[stageIndex];

      const approvalStepIndex = stage?.steps.findIndex(hasKey(action.payload.stepKey));
      const step = stage?.steps[approvalStepIndex];
      if (!stage || !step || !isApprovalStep(step)) {
        return state;
      }

      const stages = [...state.stages];
      const steps = [...stage.steps];
      const updatedStep = {
        key: step.key,
        content: step.content.set('description', action.payload.description),
      };
      steps.splice(approvalStepIndex, 1, updatedStep);
      stages.splice(stageIndex, 1, { ...stage, steps });

      return { ...state, stages };
    }
    case 'workflowBuilder/UPDATE_APPROVAL_REVIEWERS': {
      const stageIndex = state.stages.findIndex(hasKey(action.payload.stageKey));
      const stage = state.stages[stageIndex];

      const approvalStepIndex = stage?.steps.findIndex(hasKey(action.payload.stepKey));
      const step = stage?.steps[approvalStepIndex];
      if (!stage || !step || !isApprovalStep(step)) {
        return state;
      }

      const stages = [...state.stages];
      const steps = [...stage.steps];
      const updatedStep = {
        key: step.key,
        content: step.content
          .set('notifyMemberIds', List(action.payload.memberIds))
          .set('notifyTeamKeys', List(action.payload.teamKeys ?? [])),
      };
      steps.splice(approvalStepIndex, 1, updatedStep);
      stages.splice(stageIndex, 1, { ...stage, steps });

      return { ...state, stages };
    }
    case 'workflowBuilder/UPDATE_ADD_USER_TARGETS_STEP': {
      const stageIndex = state.stages.findIndex(({ key }) => key === action.payload.stageKey);
      const stage = state.stages[stageIndex];

      const stepIndex = stage?.steps.findIndex(({ key }) => key === action.payload.stepKey);
      const step = stage?.steps[stepIndex];

      if (!stage || !step) {
        return state;
      }

      return {
        ...state,
        stages: pureReplaceAtIndex(state.stages, stageIndex, {
          ...stage,
          steps: pureReplaceAtIndex(stage.steps, stepIndex, {
            ...step,
            content: action.payload.instruction,
          }),
        }),
      };
    }
    case 'workflowBuilder/UPDATE_ADD_TARGETS_STEP': {
      const stageIndex = state.stages.findIndex(({ key }) => key === action.payload.stageKey);
      const stage = state.stages[stageIndex];

      const stepIndex = stage?.steps.findIndex(({ key }) => key === action.payload.stepKey);
      const step = stage.steps[stepIndex];

      if (!stage || !step) {
        return state;
      }

      let updatedContent = step.content as WorkflowBuilderUpdateTargetsSemanticInstructionsContainer;
      updatedContent = updatedContent.update('instructions', (instructions) => {
        const index = instructions.findIndex(
          (ins) =>
            ins.variationId === action.payload.instruction.variationId &&
            ins.contextKind === action.payload.instruction.contextKind,
        );

        if (index !== -1) {
          if (action.payload.instruction.values.length === 0) {
            // if the new instruction has no selected targets, remove it from our list of instructions
            return instructions.splice(index, 1);
          } else {
            // otherwise, update it
            return instructions.splice(index, 1, action.payload.instruction);
          }
        } else {
          return instructions.push(action.payload.instruction);
        }
      });

      return {
        ...state,
        stages: pureReplaceAtIndex(state.stages, stageIndex, {
          ...stage,
          steps: pureReplaceAtIndex(stage.steps, stepIndex, {
            ...step,
            content: updatedContent,
          }),
        }),
      };
    }
    case 'workflowBuilder/UPDATE_INSTRUCTION_STEP': {
      const stageIndex = state.stages.findIndex(hasKey(action.payload.stageKey));
      const stage = state.stages[stageIndex];

      const scheduleStepIndex = stage?.steps.findIndex(hasKey(action.payload.stepKey));
      const step = stage?.steps[scheduleStepIndex];

      if (!stage || !step || !isInstructionStep(step)) {
        return state;
      }

      const stages = [...state.stages];
      const steps = [...stage.steps];
      const updatedStep = {
        key: step.key,
        content: action.payload.instruction,
      } as WorkflowBuilderInstructionStep;
      steps.splice(scheduleStepIndex, 1, updatedStep);
      stages.splice(stageIndex, 1, { ...stage, steps });

      return { ...state, stages };
    }
    case 'workflowBuilder/EDIT_STAGE_NAME': {
      if (!action.payload.name) {
        return state;
      }

      return {
        ...state,
        stages: state.stages.map((stage) =>
          hasKey(action.payload.stageKey)(stage) ? { ...stage, name: action.payload.name } : stage,
        ),
      };
    }
    case 'workflowBuilder/REPLACE_STEP': {
      const stageIndex = state.stages.findIndex(hasKey(action.payload.stageKey));
      const stage = state.stages[stageIndex];

      const stepIndex = stage?.steps.findIndex(hasKey(action.payload.stepKey));
      const step = stage?.steps[stepIndex];

      if (!stage || !step || !doStepKindsMatch(step, action.payload.step)) {
        return state;
      }

      const stages = [...state.stages];
      const steps = [...stage.steps];
      steps.splice(stepIndex, 1, action.payload.step);
      stages.splice(stageIndex, 1, { ...stage, steps });

      return {
        ...state,
        stages,
      };
    }
    case 'workflowBuilder/REMOVE_STEP': {
      const stages = state.stages
        .map((stage) => {
          if (stage.key !== action.payload.stageKey) {
            return stage;
          }

          // if this is the only step in the stage, and this is not the only stage, remove the stage
          if (stage.steps.length === 1 && state.stages.length > 1) {
            return false;
          }

          return {
            ...stage,
            steps: stage.steps.filter((step) => !hasKey(action.payload.stepKey)(step)),
          };
        })
        .filter((stage) => stage) as WorkflowBuilderStateType['stages'];

      return {
        ...state,
        stages,
      };
    }
    default: {
      return state;
    }
  }
}

reducerRegistry.addReducers({ workflowBuilder });

export const workflowBuilderSelector = (state: GlobalState) => state.workflowBuilder;
