import { Modal } from 'launchpad';

import { UpsellFeatureProps } from './UpsellFeature';
import UpsellFeatureContainer from './UpsellFeatureContainer';

export type UpsellFeatureModalProps = Omit<UpsellFeatureProps, 'canManagePlans'> & {
  isOpen: boolean;
  onCancel(): void;
};

export function UpsellModal({
  customCta,
  description,
  featureBullets,
  featureKind,
  isEnterpriseOnlyFeature,
  learnMoreUrl,
  isOpen,
  onCancel,
  title,
  featureImage,
  componentForAnalyticTracking,
}: UpsellFeatureModalProps) {
  if (!isOpen) {
    return <></>;
  }

  return (
    <Modal onCancel={onCancel} theme="dark">
      <UpsellFeatureContainer
        customCta={customCta}
        description={description}
        featureBullets={featureBullets}
        featureKind={featureKind}
        featureImage={featureImage}
        isEnterpriseOnlyFeature={isEnterpriseOnlyFeature}
        isModal
        learnMoreUrl={learnMoreUrl}
        componentForAnalyticTracking={componentForAnalyticTracking}
        title={title}
      />
    </Modal>
  );
}
