import { capitalize } from '@gonfalon/strings';

import { VariationType } from 'utils/flagUtils';

import { VARIATION_NOT_FOUND } from './instructionListUtils';
import { VariationValue } from './variationInstructionListItemUtils';

export type UpdateVariationInstructionEntryProps = {
  variationType: VariationType;
  currentVariationName: string | undefined;
  isVariationNotFound?: boolean;
  name?: { current?: string; updated?: string };
  description?: { current?: string; updated?: string };
  value?: { current: string; updated?: string };
};

export function UpdateVariationInstructionEntry({
  isVariationNotFound,
  variationType,
  currentVariationName,
  name: providedName,
  description: providedDescription,
  value: providedValue,
}: UpdateVariationInstructionEntryProps) {
  const name = providedName && { ...providedName };
  const description = providedDescription && { ...providedDescription };
  const value = providedValue && { ...providedValue };

  const notFoundContent = capitalize(VARIATION_NOT_FOUND);

  if (isVariationNotFound) {
    if (name) {
      name.current = notFoundContent;
    }
    if (description) {
      description.current = notFoundContent;
    }
  }

  return (
    <div className="VariationInstructionEntry">
      <ul className="ChangeList">
        <MetadataChangeEntry label="name" value={name} />
        <MetadataChangeEntry label="description" value={description} />
        {value?.updated !== undefined && (
          <li>
            <span>
              Change value
              {currentVariationName && !isVariationNotFound && (
                <>
                  {' '}
                  of variation <span className="MetadataValue">{currentVariationName}</span>
                </>
              )}
            </span>
            <ChangeDiff
              from={
                isVariationNotFound ? (
                  <span>{notFoundContent}</span>
                ) : (
                  <VariationValue value={value.current} variationType={variationType} />
                )
              }
              to={<VariationValue value={value.updated} variationType={variationType} />}
            />
          </li>
        )}
      </ul>
    </div>
  );
}

function ChangeDiff({ from, to }: { from: JSX.Element; to: JSX.Element }) {
  return (
    <div className="ChangeList">
      <div className="ChangeEntry">
        <span className="ChangeEntry-label">from:</span> <span>{from}</span>
      </div>
      <div className="ChangeEntry">
        <span className="ChangeEntry-label">to:</span> <span>{to}</span>
      </div>
    </div>
  );
}

function MetadataChangeEntry({
  label,
  value,
}: {
  label: 'name' | 'description';
  value: UpdateVariationInstructionEntryProps['name'];
}) {
  if (value?.current && value.updated === '') {
    // updating to the empty string means we are removing the property's current value
    return (
      <li>
        Remove {label} <span className="MetadataValue">{value.current}</span>
      </li>
    );
  }

  if (value?.current && value.updated) {
    return (
      <li>
        <span>Change {label}</span>
        <ChangeDiff
          from={<span className="MetadataValue">{value.current}</span>}
          to={<span className="MetadataValue">{value.updated}</span>}
        />
      </li>
    );
  }

  if (value?.updated) {
    return (
      <li>
        Set {label} to <span className="MetadataValue">{value.updated}</span>
      </li>
    );
  }

  return null;
}
