import { useLocation } from 'react-router-dom';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { isPaginatedExperimentsDashboardEnabled } from '@gonfalon/dogfood-flags';
import { matchExperiments } from '@gonfalon/navigator';
import { useProjectKey } from '@gonfalon/router';
import { useQuery } from '@tanstack/react-query';

import { useSelector } from 'hooks/useSelector';
import { experimentFilterSelector, experimentListSelector, experimentRequestSelector } from 'reducers/experiments';
import http from 'utils/httpUtils';
import { ready } from 'utils/reduxUtils';

export function useHasLegacyExperiments(options?: { enabled?: boolean }) {
  const enabled = options?.enabled ?? true;

  const location = useLocation();
  const projKey = useProjectKey();
  const envKey = useSelectedEnvironmentKey();

  const isExperimentsDashboard = matchExperiments(location);

  const experiments = useSelector(experimentListSelector);
  const experimentsFilter = useSelector(experimentFilterSelector);
  const experimentsRequest = useSelector(experimentRequestSelector);

  const isExperimentsFiltered = Boolean(experimentsFilter.query || experimentsFilter.status);

  const useExperimentState =
    !isPaginatedExperimentsDashboardEnabled() && isExperimentsDashboard && !isExperimentsFiltered;

  const { data: hasLegacyExperiments, isPending } = useQuery({
    queryKey: ['HAS_LEGACY_EXPERIMENTS', projKey, envKey],
    queryFn: async () =>
      http
        .get(`/internal/experiments/${projKey}/${envKey}?limit=1`)
        .then(async (response) => response.json())
        .then((response) => response.totalCount > 0),
    enabled: enabled && !useExperimentState,
  });

  // If we already have data from the query hook, just use that
  if (typeof hasLegacyExperiments !== 'undefined' && !isPending) {
    return { hasLegacyExperiments, isLoading: isPending };
  } else if (!isExperimentsFiltered && ready(experimentsRequest)) {
    return {
      hasLegacyExperiments: enabled && ready(experimentsRequest) ? experiments.size > 0 : undefined,
      isLoading: enabled ? !ready(experimentsRequest) : false,
    };
  }

  return { hasLegacyExperiments, isLoading: isPending };
}
