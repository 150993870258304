import { ModernSubscription, SubscriptionStates } from '../../types';

import { hasSubscription } from './hasSubscription';

export function hasPlan(modernSubscription: ModernSubscription) {
  return (
    hasSubscription(modernSubscription) ||
    [SubscriptionStates.PENDING_CANCELED_SUBSCRIPTION].includes(modernSubscription.state as SubscriptionStates)
  );
}
