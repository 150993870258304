import { enableLDidVerificationForSalesforce } from '@gonfalon/dogfood-flags';
import {
  toChooseOrganization,
  toHref,
  toLogin,
  toProfile,
  toUnauthenticatedVerifyEmail,
  toVerifyEmail,
} from '@gonfalon/navigator';

import { urlWithRelayState } from 'utils/urlUtils';

/**
 * NOTE
 *
 * These redirects rely on the backend's understanding of the current project
 * and environment, which is why they don't use `appRoute` at the moment.
 *
 * We're not touching them for now.
 */

export function navigateToGetStarted(verified: boolean = false) {
  window.location.href = `/get-started${verified ? '?verified=true' : ''}`;
}

export function navigateToDashboard() {
  const url = new URL('/', window.location.origin);
  window.location.href = url.toString();
}

export function navigateToVerifyEmail() {
  window.location.href = toHref(toVerifyEmail());
}

export const redirectToPostLogoutUrl = (url: string) => {
  window.location.assign(url);
};

export const navigateToUnauthenticatedVerifyEmail = (email: string) => {
  window.location.href = toHref(toUnauthenticatedVerifyEmail({ email }));
};

export const navigateToInvalidEmail = () => {
  // invalid-email is an invalid route so the 404 page will return from the BE when directed there
  window.location.href = '/invalid-email';
};

export const redirectToLogin = () => {
  const baseUrl = toHref(toLogin());
  if (enableLDidVerificationForSalesforce()) {
    const loginUrl = addVerificationModalParamToUrl(baseUrl);
    window.location.assign(loginUrl);
  } else {
    window.location.assign(baseUrl);
  }
};

export const redirectToLoginWithGoogleOauthError = () => {
  window.location.assign(toHref(toLogin({ search: { googleOauthError: 'true' } })));
};

export const redirectToLoginWithGithubOauthError = () => {
  window.location.assign(toHref(toLogin({ search: { githubOauthError: 'true' } })));
};

export const redirectToChooseOrganization = (email: string) => {
  window.location.assign(toHref(toChooseOrganization({ email })));
};

export const getDestinationUrl = () => {
  if (window.location.pathname.match(/^\/login/i)) {
    return '/';
  } else {
    return window.location.pathname + window.location.search;
  }
};

export const redirectToSSOProvider = (url: string, destination: string) => {
  const withRelayState = urlWithRelayState(url, destination || getDestinationUrl());
  window.location.assign(withRelayState);
};

export const redirectToDashboardOrContinue = () => {
  if (
    window.location.pathname.match(/^\/(login|reset|verify|oauth)/i) ||
    window.location.pathname.match(/\/enter-password$/i)
  ) {
    const baseUrl = '/';
    if (enableLDidVerificationForSalesforce()) {
      const dashboardUrl = addVerificationModalParamToUrl(baseUrl);
      window.location.assign(dashboardUrl);
    } else {
      window.location.assign(baseUrl);
    }
  } else {
    window.location.reload();
  }
};

export const redirectToProfile = (hash: string) => {
  window.location.assign(toHref(toProfile({ hash })));
};

export const addVerificationModalParamToUrl = (baseUrl: string) => {
  const url = new URL(baseUrl, window.location.origin);
  const verificationCodeParamKey = 'salesforceverificationcode';
  const showVerificationModalKey = 'showverificationmodal';
  const urlParams = new URLSearchParams(window.location.search);
  const verifCode = urlParams.get(verificationCodeParamKey);
  const showVerificationModalVal = urlParams.get(showVerificationModalKey);
  if (showVerificationModalVal) {
    url.searchParams.set(showVerificationModalKey, 'true');
    if (verifCode !== null) {
      url.searchParams.set(verificationCodeParamKey, verifCode);
    }
  }
  return url.toString();
};
