import withForm from 'components/withForm';
import { createPolicyStatement, PolicyStatement } from 'utils/policyUtils';

import PolicyStatementEditor, { PolicyStatementEditorProps } from './PolicyStatementEditor';

type ContainerProps = {
  onSubmitSuccess(): void;
  onSubmit(modified: PolicyStatement): void;
};
type PolicyStatementEditorContainerProps = PolicyStatementEditorProps & ContainerProps;

const EditorWithForm = withForm<PolicyStatement>({
  initialState(props: PolicyStatementEditorContainerProps) {
    return createPolicyStatement(
      props.statement.withMutations((s) => s.update('effect', (effect) => effect || 'allow')),
    );
  },
  onSubmit(props: PolicyStatementEditorContainerProps, modified: PolicyStatement) {
    props.onSubmit(modified);
  },
  onSubmitSuccess(props: PolicyStatementEditorContainerProps) {
    props.onSubmitSuccess();
  },
})<PolicyStatementEditorProps, ContainerProps>(PolicyStatementEditor);

/* eslint-disable import/no-default-export */
export default EditorWithForm;
