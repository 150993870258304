import { capitalize } from '@gonfalon/strings';

import Variation from 'components/Variation';
import { colorVariation, Flag, Variation as VariationType } from 'utils/flagUtils';
import { FlagPrerequisitesInstructionKind } from 'utils/instructions/prerequisites/types';

import './styles.css';

export type PrerequisiteEntryProps = {
  instructionKind: FlagPrerequisitesInstructionKind;
  variationId?: string;
  prerequisiteFlag: Flag;
};

export const PrerequisiteEntry = ({ instructionKind, variationId, prerequisiteFlag }: PrerequisiteEntryProps) => {
  let preposition = '';
  let verb = '';
  switch (instructionKind) {
    case FlagPrerequisitesInstructionKind.ADD_PREREQUISITE:
      verb = 'add';
      preposition = 'with variation';
      break;
    case FlagPrerequisitesInstructionKind.REMOVE_PREREQUISITE:
      verb = 'remove';
      break;
    case FlagPrerequisitesInstructionKind.UPDATE_PREREQUISITE:
      verb = 'update';
      preposition = 'to the variation';
      break;
    default:
      preposition = 'with variation';
  }
  const prereqName = prerequisiteFlag.name;
  const description = capitalize(`${verb} prerequisite flag`.trim());

  let variation: VariationType | undefined;
  let variationIndex = -1;
  if (variationId) {
    variationIndex = prerequisiteFlag.getVariationIndexFromId(variationId);
    variation = prerequisiteFlag.variations.get(variationIndex);
  }
  const ariaLabel = `${description} ${prereqName} ${preposition} ${
    variationIndex > -1 && variation ? variation.getValueString() : ''
  }`;

  return (
    <span aria-label={ariaLabel}>
      <span>
        {description} <span className="u-fw-semibold">{prereqName} </span>
        {preposition}
      </span>
      {variationIndex > -1 && variation && (
        <span className="u-ws-nowrap">
          <Variation
            className="u-ml-s"
            key={variationId}
            color={colorVariation(variationIndex)}
            value={variation.getDisplay()}
          />
        </span>
      )}
    </span>
  );
};
