import { Map } from 'immutable';
import { combineReducers } from 'redux';

import actionTypes from 'actionTypes/flags';
import registry from 'reducers/registry';

const makeFlagMetricKey = (flagKey, metricKey) => (flagKey && metricKey ? `${flagKey}:${metricKey}` : null);

export const initialSummaryState = Map({
  data: {
    _links: {},
    metadata: [],
    stats: {},
    totals: [],
    series: [],
    metricSeen: {},
  },
  isFetching: false,
  lastFetched: null,
  error: null,
});

const initialSeriesState = Map({
  data: {
    _links: {},
    metadata: [],
    stats: {},
    totals: [],
    series: [],
    granularity: '',
    metricSeen: {},
  },
  isFetching: false,
  lastFetched: null,
  error: null,
});

const initalResetExperimentState = { isFetching: false, error: false, metricKey: {} };

const resetExperiment = (state = initalResetExperimentState, { type, metricKey, error }) => {
  switch (type) {
    case actionTypes.RESET_EXPERIMENT:
      return { isFetching: true, metricKey, error: false };
    case actionTypes.RESET_EXPERIMENT_DONE:
      return { isFetching: false, metricKey, error: false };
    case actionTypes.RESET_EXPERIMENT_FAILED:
      return { isFetching: false, metricKey, error };
    default:
      return state;
  }
};

export const resetExperimentSelector = (state) => state.flagExperiments.resetExperiment;

function flagExperimentSummary(state = initialSummaryState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_EXPERIMENT_SERIES_RESULTS:
    case actionTypes.REQUEST_EXPERIMENT_SUMMARY_RESULTS:
      return state.set('isFetching', true);
    case actionTypes.REQUEST_EXPERIMENT_SERIES_RESULTS_FAILED:
    case actionTypes.REQUEST_EXPERIMENT_SUMMARY_RESULTS_FAILED:
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        error: action.error,
      });
    case actionTypes.REQUEST_EXPERIMENT_SERIES_RESULTS_DONE:
    case actionTypes.REQUEST_EXPERIMENT_SUMMARY_RESULTS_DONE:
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        data: action.data,
        error: null,
      });
    default:
      return state;
  }
}

export function summaryResults(state = Map(), action) {
  switch (action.type) {
    case actionTypes.REQUEST_EXPERIMENT_SERIES_RESULTS:
    case actionTypes.REQUEST_EXPERIMENT_SUMMARY_RESULTS:
    case actionTypes.REQUEST_EXPERIMENT_SERIES_RESULTS_FAILED:
    case actionTypes.REQUEST_EXPERIMENT_SUMMARY_RESULTS_FAILED:
    case actionTypes.REQUEST_EXPERIMENT_SERIES_RESULTS_DONE:
    case actionTypes.REQUEST_EXPERIMENT_SUMMARY_RESULTS_DONE:
      return state.update(makeFlagMetricKey(action.flag.key, action.metricKey), (f) =>
        flagExperimentSummary(f, action),
      );
    default:
      return state;
  }
}

export const flagExperimentSummarySelector = (flagKey, metricKey) => (state) =>
  state.flagExperiments.summaryResults.get(makeFlagMetricKey(flagKey, metricKey)) || initialSummaryState;

function flagExperimentSeries(state = initialSeriesState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_EXPERIMENT_SERIES_RESULTS:
      return state.set('isFetching', true);
    case actionTypes.REQUEST_EXPERIMENT_SERIES_RESULTS_FAILED:
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        error: action.error,
      });
    case actionTypes.REQUEST_EXPERIMENT_SERIES_RESULTS_DONE:
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        data: action.data,
        error: null,
      });
    default:
      return state;
  }
}

export function seriesResults(state = Map(), action) {
  switch (action.type) {
    case actionTypes.REQUEST_EXPERIMENT_SERIES_RESULTS:
    case actionTypes.REQUEST_EXPERIMENT_SERIES_RESULTS_FAILED:
    case actionTypes.REQUEST_EXPERIMENT_SERIES_RESULTS_DONE:
      return state.update(makeFlagMetricKey(action.flag.key, action.metricKey), (f) => flagExperimentSeries(f, action));
    default:
      return state;
  }
}

export const flagExperimentSeriesSelector = (flagKey, metricKey) => (state) =>
  state.flagExperiments.seriesResults.get(makeFlagMetricKey(flagKey, metricKey)) || initialSeriesState;

export const flagExperiments = combineReducers({
  summaryResults,
  seriesResults,
  resetExperiment,
});

registry.addReducers({ flagExperiments });
