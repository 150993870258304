import { startOfToday, startOfYesterday, subDays } from 'date-fns';

import { DEFAULT_FROM_DATE } from 'components/usage/constants';
import { FilterType, TypeOfUsage, UsageType } from 'components/usage/types';
import { GetState, GlobalDispatch } from 'reducers';
// isRelaySetup reads from this state.
/* eslint-disable-next-line no-restricted-imports */
import { relayDetectionSelector } from 'reducers/usage';
import { load, save } from 'sources/AccountLocalStorage';
// eslint-disable-next-line import/no-namespace
import * as UsageAPI from 'sources/UsageAPI';
import { ImmutableServerError } from 'utils/httpUtils';
import { GenerateActionType } from 'utils/reduxUtils';
import { convertStartAndEndDateUsageFilters, hasRelayConnection } from 'utils/usageUtils';

export type UsageOptionType = {
  projKey?: string;
  envKey?: string;
  flagKey?: string;
  defaultFromDate: string;
  renderTotal?: boolean;
  typeOfUsage?: UsageType;
};

export const requestUsageData = (metricId: string) =>
  ({
    type: 'usage/REQUEST_USAGE_DATA',
    metric: metricId,
  }) as const;

export const requestUsageDataDone = (metricId: string, data: UsageAPI.RechartsData) =>
  ({
    type: 'usage/REQUEST_USAGE_DATA_DONE',
    metric: metricId,
    data,
  }) as const;

export const requestUsageDataFailed = (metricId: string, error: ImmutableServerError) =>
  ({
    type: 'usage/REQUEST_USAGE_DATA_FAILED',
    metric: metricId,
    error,
  }) as const;

const createFetchUsageAction =
  (metric: string, callAPI: (filters: FilterType, options: UsageOptionType) => Promise<UsageAPI.RechartsData>) =>
  (filters: FilterType, options: UsageOptionType) =>
  (dispatch: GlobalDispatch) => {
    const { typeOfUsage, defaultFromDate } = options;
    const metricId = typeOfUsage ? `${typeOfUsage}/${metric}` : metric;
    dispatch(requestUsageData(metricId));
    const usageFilters = convertStartAndEndDateUsageFilters(filters, metric, defaultFromDate);
    callAPI(usageFilters, options).then(
      (data: UsageAPI.RechartsData) => dispatch(requestUsageDataDone(metricId, data)),
      (error: ImmutableServerError) => dispatch(requestUsageDataFailed(metricId, error)),
    );
  };

export const fetchReceivedEventUsage = createFetchUsageAction('receivedEvents', UsageAPI.getReceivedEventUsage);

export const fetchPublishedEventUsage = createFetchUsageAction('publishedEvents', UsageAPI.getPublishedEventUsage);

export const fetchMauUsage = createFetchUsageAction('mau', UsageAPI.getMauUsage);

export const fetchConnectionsUsage = createFetchUsageAction(
  'connections',
  async (filters: FilterType, { typeOfUsage }: UsageOptionType) => {
    if (!typeOfUsage) {
      throw new Error('required option typeOfUsage not specified for usage action');
    }
    if (filters && ('sdk' in filters || 'version' in filters)) {
      return UsageAPI.getStreamUsageBySdkVersion(filters, { typeOfUsage });
    } else {
      return UsageAPI.getStreamUsage(filters, { typeOfUsage });
    }
  },
);

export const requestSDKVersions = (usageType: UsageType) =>
  ({
    type: 'usage/REQUEST_SDK_VERSIONS',
    usageType,
  }) as const;

export const requestSDKVersionsDone = (sdkVersions: UsageAPI.SdkVersionsType[], usageType: UsageType) =>
  ({
    type: 'usage/REQUEST_SDK_VERSIONS_DONE',
    sdkVersions,
    usageType,
  }) as const;

export const requestSDKVersionsFailed = (error: ImmutableServerError) =>
  ({
    type: 'usage/REQUEST_SDK_VERSIONS_FAILED',
    error,
  }) as const;

export const fetchSdkVersions = (usageType: UsageType) => async (dispatch: GlobalDispatch) => {
  dispatch(requestSDKVersions(usageType));
  try {
    const response = await UsageAPI.getSdkVersions(usageType);
    dispatch(requestSDKVersionsDone(response.sdkVersions, usageType));
  } catch (error) {
    dispatch(requestSDKVersionsFailed(error as ImmutableServerError));
  }
};

export const requestMauSDKS = (usageType: UsageType) =>
  ({
    type: 'usage/REQUEST_MAU_SDKS',
    usageType,
  }) as const;

export const requestMauSDKSDone = (sdks: string[], usageType: UsageType) =>
  ({
    type: 'usage/REQUEST_MAU_SDKS_DONE',
    sdks,
    usageType,
  }) as const;

export const requestMauSDKSFailed = (error: ImmutableServerError) =>
  ({
    type: 'usage/REQUEST_MAU_SDKS_FAILED',
    error,
  }) as const;

export const fetchMauSdks = (usageType: UsageType, filters: FilterType) => async (dispatch: GlobalDispatch) => {
  dispatch(requestMauSDKS(usageType));
  const usageFilters = convertStartAndEndDateUsageFilters(filters, usageType, DEFAULT_FROM_DATE);
  try {
    const response = await UsageAPI.getMauSdks(usageType, usageFilters);
    dispatch(requestMauSDKSDone(response.sdks, usageType));
  } catch (error) {
    dispatch(requestMauSDKSFailed(error as ImmutableServerError));
  }
};

export const fetchFlagEvaluations = createFetchUsageAction('flagEvaluations', UsageAPI.getFlagEvaluations);

const sdkLDRelay = 'LDRelay';

export const requestRelayMetrics = () =>
  ({
    type: 'usage/REQUEST_RELAY_METRICS',
  }) as const;

export const requestRelayMetricsDone = (res: boolean) =>
  ({
    type: 'usage/REQUEST_RELAY_METRICS_DONE',
    res,
  }) as const;

export const requestRelayMetricsFailed = (error: ImmutableServerError) =>
  ({
    type: 'usage/REQUEST_RELAY_METRICS_FAILED',
    error,
  }) as const;

const getLDRelayUsageFromCache = (projKey: string, envKey: string, timestamp: string) =>
  load().get('ldRelayUsage').get(projKey)?.get(envKey)?.get(timestamp);

const saveLDRelayUsageToCache = (projKey: string, envKey: string, timestamp: string, relayConnectionExists: boolean) =>
  save(load().setIn(['ldRelayUsage', projKey, envKey, timestamp], relayConnectionExists));

export const isRelaySetup = (projKey: string, envKey: string, staticTimeQuery?: boolean) => {
  const timeNow = Date.now();
  const timeWith24hrs = subDays(timeNow, 1);
  const startToday = startOfToday();
  const startYesterday = startOfYesterday();
  // Modify the filter to use static 24 hour periods if flag is enabled
  // The goal here is to ensure we can cache the data more aggressively as this query is run from a very high traffic page
  // We're currently solving this by using the previous 24 hour periods data
  const filters = {
    sdk: sdkLDRelay,
    // The backend expects the time to be in milliseconds
    to: staticTimeQuery ? startToday.getTime().toString() : timeNow.toString(),
    from: staticTimeQuery ? startYesterday.getTime().toString() : timeWith24hrs.getTime().toString(),
    project: projKey,
    environment: envKey,
  };

  return async (dispatch: GlobalDispatch, getState: GetState) => {
    const isRelayDetectionFetched = relayDetectionSelector(getState()) !== null;
    // Don't want use localstorage cache if the staticTimeQuery flag is false for now
    const cachedRelayConnectionValue = staticTimeQuery
      ? getLDRelayUsageFromCache(projKey, envKey, startYesterday.getTime().toString())
      : undefined;
    if (cachedRelayConnectionValue !== undefined) {
      dispatch(requestRelayMetricsDone(cachedRelayConnectionValue));
    } else if (!isRelayDetectionFetched) {
      dispatch(requestRelayMetrics());
      try {
        const response = await UsageAPI.getStreamUsageBySdkVersion(filters, { typeOfUsage: TypeOfUsage.SERVER });
        // Don't want use localstorage cache if the staticTimeQuery flag is false for now
        if (staticTimeQuery) {
          saveLDRelayUsageToCache(projKey, envKey, startYesterday.getTime().toString(), hasRelayConnection(response));
        }
        dispatch(requestRelayMetricsDone(hasRelayConnection(response)));
      } catch (error) {
        dispatch(requestRelayMetricsFailed(error as ImmutableServerError));
      }
    }
  };
};

const UsageActionCreators = {
  requestUsageData,
  requestUsageDataDone,
  requestUsageDataFailed,
  requestSDKVersions,
  requestSDKVersionsDone,
  requestSDKVersionsFailed,
  requestMauSDKS,
  requestMauSDKSDone,
  requestMauSDKSFailed,
  requestRelayMetrics,
  requestRelayMetricsDone,
  requestRelayMetricsFailed,
};

export type UsageActions = GenerateActionType<typeof UsageActionCreators>;
