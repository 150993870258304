import { AuditLogEntryRep } from './types';

export function accessesStartsWith(AuditLogEntry: AuditLogEntryRep, verb: string, optionalVerb?: string) {
  return AuditLogEntry.accesses.every((a) => {
    if (optionalVerb) {
      return a?.action?.startsWith(verb) || a?.action?.startsWith(optionalVerb);
    } else {
      return a?.action?.startsWith(verb);
    }
  });
}
