export function dispatchStorageEvent({
  key,
  oldValue,
  newValue,
  storage,
}: {
  key: string;
  oldValue: string | null;
  newValue: string | null;
  storage: Storage;
}) {
  if (oldValue === newValue) {
    return;
  }

  const event = new StorageEvent('storage', {
    key,
    oldValue,
    newValue,
    storageArea: storage,
    url: window.location.href,
  });

  window.dispatchEvent(event);
}
