import { memo } from 'react';
import { assetBase, assetPath } from '@gonfalon/constants';

export type UpsellFeatureImageProps = {
  altText: string;
  fileName: string;
  height: string;
  width: string;
  fileExtension?: string;
};

const UpsellFeatureImage = ({ altText, fileName, height, width, fileExtension = 'png' }: UpsellFeatureImageProps) => (
  <img
    alt={altText}
    src={`${assetBase()}/${assetPath()}/img/${fileName}.${fileExtension}`}
    height={height}
    width={width}
  />
);

/* eslint-disable import/no-default-export */
export default memo(UpsellFeatureImage);
