// Poor man's parsing. We'll add proper resource specifier/identifier parsing later.

const projectRegex = /(proj\/([a-zA-Z0-9-_.]+)):?/;
const environmentRegex = /(env\/([a-zA-Z0-9-_.]+)):?/;

export enum resourceKinds {
  PROJECT = 'project',
  ENVIRONMENT = 'environment',
  FLAG = 'flag',
  EXPERIMENT = 'experiment',
}

export enum mapKind {
  proj = resourceKinds.PROJECT,
  env = resourceKinds.ENVIRONMENT,
  flag = resourceKinds.FLAG,
  experiment = resourceKinds.EXPERIMENT,
}

export function kindFromIdentifier(identifier: string) {
  const nodes = identifier.split(':');
  const [kind] = nodes[nodes.length - 1].split('/');
  return mapKind[kind as keyof typeof mapKind];
}

export function idFromIdentifier(identifier: string) {
  const nodes = identifier.split(':');
  const [, name] = nodes[nodes.length - 1].split('/');
  return name;
}

export function projectFromIdentifier(identifier: string) {
  const match = projectRegex.exec(identifier);
  if (match) {
    return match[match.length - 1];
  }
}

export function environmentFromIdentifier(identifier: string) {
  const match = environmentRegex.exec(identifier);
  if (match) {
    return match[match.length - 1];
  }
}
