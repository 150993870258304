import { stateSchema } from './types';

export function parseState(value: unknown) {
  const result = stateSchema.safeParse(value);

  if (!result.success) {
    return undefined;
  }

  return result.data;
}
