import { isEmpty } from '@gonfalon/es6-utils';
import { List, Map, Record } from 'immutable';

import { Clause } from 'utils/clauseUtils';
import { DEVICE_CONTEXT_KIND, OS_NAME_ATTRIBUTE, OS_VERSION_ATTRIBUTE } from 'utils/constants';
import { testSemVer } from 'utils/validationUtils';

import { invalidRegExp, invalidSemVer, minLength, oneOf, required } from './errors';
import { invalidAttribute } from './targeting';

const semVerOps = ['semVerEqual', 'semVerLessThan', 'semVerGreaterThan'];
const ops = [
  'in',
  'endsWith',
  'startsWith',
  'matches',
  'contains',
  'lessThan',
  'lessThanOrEqual',
  'greaterThan',
  'greaterThanOrEqual',
  'before',
  'after',
  'segmentMatch',
  'applicationVersionSupported',
];

export class ClauseValidation extends Record({
  attribute: Map(),
  op: Map(),
  values: Map(),
}) {
  isEmpty() {
    return this.attribute.isEmpty() && this.op.isEmpty() && this.values.isEmpty();
  }

  isInvalid() {
    return (
      !this.isEmpty() &&
      (this.getIn(['op', 'type']) === 'oneOf' ||
        this.getIn(['values', 'type']) === 'invalidRegExp' ||
        this.getIn(['values', 'type']) === 'invalidSemVer' ||
        this.getIn(['attribute', 'type']) === 'invalidAttribute')
    );
  }

  isIncomplete() {
    return (
      !this.isEmpty() &&
      (this.getIn(['attribute', 'type']) === 'required' ||
        this.getIn(['op', 'type']) === 'required' ||
        this.getIn(['values', 'type']) === 'minLength')
    );
  }

  getMessage() {
    if (this.isEmpty()) {
      return;
    }

    if (this.isInvalid()) {
      if (this.getIn(['op', 'type']) === 'oneOf') {
        return 'You must select an operator';
      }

      if (this.getIn(['values', 'type']) === 'invalidRegExp') {
        return 'You must specify a valid regular expression';
      }

      if (this.getIn(['values', 'type']) === 'invalidSemVer') {
        return 'You must specify a valid semantic version';
      }

      if (this.getIn(['attribute', 'type']) === 'invalidAttribute') {
        const attributeName = this.getIn(['attribute', 'name']);
        if (attributeName === OS_VERSION_ATTRIBUTE) {
          return 'Make sure to select OS name when targeting OS versions.';
        }
        return `Invalid attribute "${attributeName}" specified. Targeting by this attribute is not allowed.`;
      }
    }

    if (this.isIncomplete()) {
      if (this.getIn(['attribute', 'type']) === 'required') {
        return 'You must select a user attribute';
      }

      if (this.getIn(['values', 'type']) === 'minLength') {
        const bound = this.getIn(['values', 'minLength']);
        return `You must specify at least ${bound} value${bound !== 1 ? 's' : ''}`;
      }
    }
  }
}

export function validateClause(clause: Clause, allClauses?: List<Clause>) {
  let validation = new ClauseValidation();
  const validOps = [...ops, ...semVerOps];
  // remove this once OS version matching is released
  const showOSVersionWarning = false;

  if (isEmpty(clause.attribute) && clause.op !== 'segmentMatch') {
    validation = validation.set('attribute', required(clause.attribute));
  }

  if (clause.attribute === 'secondary') {
    validation = validation.set('attribute', invalidAttribute(clause.attribute));
  }
  if (
    showOSVersionWarning &&
    allClauses &&
    clause.contextKind === DEVICE_CONTEXT_KIND &&
    clause.attribute === OS_VERSION_ATTRIBUTE
  ) {
    const hasOSNameClause = allClauses?.filter(({ attribute }) => attribute && attribute === OS_NAME_ATTRIBUTE);
    if (hasOSNameClause?.size === 0) {
      validation = validation.set('attribute', invalidAttribute(clause.attribute));
    }
  }

  if (!clause.op) {
    validation = validation.set('op', required(clause.op));
  } else if (!validOps.includes(clause.op)) {
    validation = validation.set('op', oneOf(validOps, clause.op));
  } else if (clause.op === 'matches') {
    try {
      new RegExp(clause.values.first('').toString());
    } catch (error) {
      validation = validation.set('values', invalidRegExp(clause.values));
    }
  } else if (clause.op.startsWith('semVer')) {
    if (!clause.values.every((value) => testSemVer(value.toString()))) {
      validation = validation.set('values', invalidSemVer(clause.values));
    }
  }

  if (clause.values.isEmpty()) {
    validation = validation.set('values', minLength(1, clause.values.size));
  }

  return validation;
}
