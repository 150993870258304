import { useMemo } from 'react';
import { type Navigation, useNavigation } from 'react-router-dom';
import { isProperSupersetOf } from '@gonfalon/collections';
import { InternalEnvironment, isInternalEnvironment } from '@gonfalon/environments';

import { hashProjectContext } from './hashProjectContext';
import { isProjectContextValid } from './isProjectContextValid';
import { readProjectContextFromPath } from './readProjectContextFromPath';
import { type ProjectContextData, useProjectContext } from './useProjectContext';

/**
 * Compute an optimistic project context based on the current project context and the navigation state.
 *
 * When an environment is added or removed, the UI will reflect the change immediately.
 */
export function useOptimisticProjectContext() {
  const currentProjectContext = useProjectContext();
  const navigation = useNavigation();

  const optimisticContext = useMemo(
    () => computeOptimisticProjectContext(currentProjectContext, navigation),
    [navigation.state, currentProjectContext],
  );

  return optimisticContext;
}

function computeOptimisticProjectContext(currentProjectContext: ProjectContextData, navigation: Navigation) {
  if (navigation.state !== 'loading') {
    return;
  }

  const currentHash = hashProjectContext(currentProjectContext.context);

  const locationContext = readProjectContextFromPath(navigation.location);
  if (
    locationContext === undefined ||
    !isProjectContextValid(locationContext) ||
    locationContext.projectKey !== currentProjectContext.context.projectKey
  ) {
    return;
  }

  const context = locationContext;
  const locationHash = hashProjectContext(context);

  // If contexts have not changed, we don't need to do anything.
  if (currentHash === locationHash) {
    return;
  }

  let environments = Array.from(currentProjectContext.environments);

  const isAddition = isProperSupersetOf(context.environmentKeys, currentProjectContext.context.environmentKeys);
  const isRemoval = isProperSupersetOf(currentProjectContext.context.environmentKeys, context.environmentKeys);

  // If we added an environment, we optimistically insert it into the loader data so the UI
  // will reflect the change before the loader revalidates.
  if (isAddition) {
    const nextList = navigation.location.state;
    // If we don't have the data for the newly added environment, we can't optimistically add it to the list.
    if (
      !Array.isArray(nextList) || // invalid data
      !nextList.every(isInternalEnvironment) || // invalid data
      !Array.from(context.environmentKeys).every((key) => nextList.find((env) => env.key === key)) // missing data
    ) {
      return;
    }
    environments = nextList;
  } else if (isRemoval) {
    // If we removed an environment, we optimistally remove the data so the UI can will reflect
    // the change immediately (and no consumer will get confused if they iterate directly over the list
    // of environments).
    environments = environments.filter((it) => context.environmentKeys.has(it.key));
  } else {
    // The only other possible change is re-ordering, which has no impact on the data we fetch.
    // Again, we'll provide the reordered list to the UI so the change is reflected immediately
    // to all consumers.
  }

  const selectedEnvironment = environments.find((e) => e.key === context.selectedEnvironmentKey);
  if (selectedEnvironment === undefined) {
    return;
  }

  const sortedEnvironments: InternalEnvironment[] = [];
  for (const key of Array.from(context.environmentKeys)) {
    const environment = environments.find((it) => it.key === key);
    if (!environment) {
      // if an environment is missing, we give up
      // given what we know about the data so far, this
      return;
    }

    sortedEnvironments.push(environment);
  }

  return {
    context,
    project: currentProjectContext.project,
    environments: sortedEnvironments,
    selectedEnvironment,
  } as const;
}
