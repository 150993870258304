import { AuthorizedAppDataRep } from '../types';

type linkProps = {
  target?: string;
  rel?: string;
  className?: string;
  href?: string;
  children: React.ReactNode;
};

const MaybeLink = ({ href, children, ...linkProps }: linkProps) => {
  if (href) {
    return (
      <a href={href} {...linkProps}>
        {children}
      </a>
    );
  }
  return null;
};

type EntryAppProps = {
  app: AuthorizedAppDataRep;
};

export function EntryApp({ app }: EntryAppProps) {
  const siteLink = app._links && app._links.site;
  const siteHref = siteLink && siteLink.href;

  return (
    <>
      {app.isScim && `Via SCIM (${app.name})`}
      {!app.isScim && (
        <>
          Via the app{' '}
          <MaybeLink href={siteHref} className="u-currentColor">
            {app.name}
          </MaybeLink>
        </>
      )}
    </>
  );
}
