import { useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useDispatch, useSelector } from 'react-redux';
import { isManagedInstance } from '@gonfalon/constants';
import {
  areFollowFlagTargetingChangesEnabled,
  isABTestingEnabled,
  isAuditLogEnabled,
  isCodeReferencesEnabled,
  isDisableCodeReferencesWithOverrideEnabled,
  isFlagLinksEnabled,
  isPresenceEnabled,
  isTeamsEnabled,
} from '@gonfalon/dogfood-flags';

import { fetchFlag as fetchFlagAction } from 'actions/flags';
import { useIsWorkflowBuilderEnabled, useIsWorkflowBuilderUpsellEnabled } from 'context/workflows';
import { GlobalState } from 'reducers';
import { flagByKeySelector, flagStatusesByProjectKeySelector } from 'reducers/flags';
import { profileSelector } from 'reducers/profile';
import { currentEnvironmentEntitySelector } from 'reducers/projects';
import { isFullRep, ready } from 'utils/reduxUtils';

export function useManageFlagState({ projKey, envKey, flagKey }: { projKey: string; envKey: string; flagKey: string }) {
  const environment = useSelector(currentEnvironmentEntitySelector);

  const dispatch = useDispatch();

  const fetchFlag = useCallback(
    () =>
      dispatch(
        fetchFlagAction(projKey, flagKey, envKey, {
          expandEvaluation: true,
        }),
      ),
    [projKey, envKey, flagKey],
  );

  const flagFromState = useSelector((state: GlobalState) => flagByKeySelector(state, { flagKey }));

  const statuses = useSelector(flagStatusesByProjectKeySelector);

  const profile = useSelector(profileSelector);
  const status = flagFromState.entity ? statuses.getIn([envKey, 'entities', flagKey, 'entity']) : undefined;

  const breadcrumb = ready(flagFromState.request) ? flagFromState.entity?.name : null;

  const error = flagFromState.request && flagFromState.request.error;
  // if we get into the unexpected situation that we have a partial flag rep as the flag entity, this will result in a re-fetch
  const flag = flagFromState.request && isFullRep(flagFromState.request) ? flagFromState.entity : undefined;

  const reduxIsReady = ready(flagFromState.request) && flagFromState.request && isFullRep(flagFromState.request);
  const flagIsWellformed = flag?.environments.has(envKey);
  const isReady = reduxIsReady && flagIsWellformed;

  const isWorkflowBuilderEnabled = useIsWorkflowBuilderEnabled();
  const isWorkflowBuilderUpsellEnabled = useIsWorkflowBuilderUpsellEnabled();

  return {
    isReady,
    error,
    projKey,
    envKey,
    envId: environment._id,
    flagKey,
    flag,
    status,
    breadcrumb,
    isWorkflowsTabEnabled: isWorkflowBuilderEnabled() || isWorkflowBuilderUpsellEnabled(),
    isABTestingEnabled: isABTestingEnabled(),
    isCodeReferencesEnabled: !isDisableCodeReferencesWithOverrideEnabled() && isCodeReferencesEnabled(),
    isFlagLinksEnabled: isFlagLinksEnabled(),
    isTeamsEnabled: isTeamsEnabled(),
    isAuditLogEnabled: isAuditLogEnabled(),
    isInAppDemoEnabled: !isManagedInstance(),
    enableFollowFlag: areFollowFlagTargetingChangesEnabled(),
    isPresenceEnabled: isPresenceEnabled(),
    fetchFlag,
    profile: profile.get('entity'),
  };
}
