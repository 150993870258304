import { constants } from 'utils/reduxUtils';

/* eslint-disable import/no-default-export */
export default constants('scheduledChanges', [
  'FETCH_FLAG_SCHEDULED_CHANGES',
  'RECEIVE_FLAG_SCHEDULED_CHANGES',
  'RECEIVE_FLAG_SCHEDULED_CHANGES_FAILED',

  'FETCH_FLAG_SCHEDULED_CHANGE',
  'RECEIVE_FLAG_SCHEDULED_CHANGE',
  'RECEIVE_FLAG_SCHEDULED_CHANGE_FAILED',

  'FETCH_CONFLICTS',
  'RECEIVE_CONFLICTS',
  'RECEIVE_CONFLICTS_FAILED',

  'CREATE_FLAG_SCHEDULED_CHANGE',
  'CREATE_FLAG_SCHEDULED_CHANGE_DONE',
  'CREATE_FLAG_SCHEDULED_CHANGE_FAILED',

  'DELETE_FLAG_SCHEDULED_CHANGE',
  'DELETE_FLAG_SCHEDULED_CHANGE_DONE',
  'DELETE_FLAG_SCHEDULED_CHANGE_FAILED',

  'UPDATE_FLAG_SCHEDULED_CHANGE',
  'UPDATE_FLAG_SCHEDULED_CHANGE_DONE',
  'UPDATE_FLAG_SCHEDULED_CHANGE_FAILED',
]);
