import { constants } from 'utils/reduxUtils';

/* eslint-disable import/no-default-export */
export default constants('profile', [
  'REQUEST_PROFILE',
  'REQUEST_PROFILE_DONE',
  'REQUEST_PROFILE_FAILED',
  'UPDATE_PROFILE',
  'UPDATE_PROFILE_DONE',
  'UPDATE_PROFILE_FAILED',
  'EDIT_PROFILE',
  'UPDATE_PASSWORD',
  'UPDATE_PASSWORD_DONE',
  'UPDATE_PASSWORD_FAILED',
  'EDIT_PASSWORD',
  'REQUEST_FOLLOW_PREFERENCES',
  'REQUEST_FOLLOW_PREFERENCES_DONE',
  'REQUEST_FOLLOW_PREFERENCES_FAILED',
  'UPDATE_FOLLOW_PREFERENCES',
  'UPDATE_FOLLOW_PREFERENCES_DONE',
  'UPDATE_FOLLOW_PREFERENCES_FAILED',
  'RESEND_VERIFICATION',
  'RESEND_VERIFICATION_DONE',
  'RESEND_VERIFICATION_FAILED',
  'CANCEL_VERIFICATION',
  'CANCEL_VERIFICATION_DONE',
  'CANCEL_VERIFICATION_FAILED',
  'PROFILE_VERIFIED',
]);
