import { ChangeHistoryItem, changeHistoryItemSchema } from './schema';

export function assertChangeHistoryItem(item: unknown): asserts item is ChangeHistoryItem {
  changeHistoryItemSchema.parse(item);
}

export function ensureChangeHistoryItem(item: unknown) {
  assertChangeHistoryItem(item);
  return item;
}
