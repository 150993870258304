import { useDeferredValue, useState } from 'react';
import { FieldValues, UseControllerProps } from 'react-hook-form';
import { ComponentsObject, CustomCreatable, HookFormCreateable, StylesObject } from '@gonfalon/launchpad-experimental';
import { keyRegexp } from '@gonfalon/strings';
import { useQuery } from '@tanstack/react-query';
import { getTagsQuery } from 'ia-poc/services/queries/tags-queries';

import { TagKind } from './tagFilter/types';
import { OldSelectTagsComponent } from './SelectTagsOld';

type TagOption = {
  value: string;
  label: string;
};

export type SelectTagsProps<F extends FieldValues = FieldValues> = UseControllerProps<F> & {
  value?: string[];
  kind?: TagKind;
  placeholder?: string;
  styles?: StylesObject;
  ariaLabel?: string;
  useHookForm?: boolean;
  customComponents?: ComponentsObject;
  className?: string;
  id?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?(tags: string[]): void;
};
const invalidTagsMsg =
  "Invalid tag. Tags must start with a letter or number and only contain letters, numbers, '.', '_' or '-'";
const defaultHookFormRules = {
  validate: {
    allTagsValid: (tags: TagOption[]) => {
      if (!tags) {
        return true;
      }
      for (const tag of tags) {
        if (!keyRegexp.test(tag.value)) {
          return invalidTagsMsg;
        }
      }

      return true;
    },
  },
};

// TODO: Make this the default export and remove the container once we want to fully cut over
// to the new select tags component.
function SelectTags<F extends FieldValues = FieldValues>({
  useHookForm = false,
  value,
  control,
  rules,
  kind = TagKind.FLAG,
  onChange,
  onBlur,
  ...props
}: SelectTagsProps<F>) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const deferredSearchTerm = useDeferredValue(searchTerm);

  const tagsQuery = useQuery({
    ...getTagsQuery(kind, 50, deferredSearchTerm),
    refetchOnWindowFocus: false,
    retry() {
      return false;
    },
    enabled: isMenuOpen,
  });

  const formatCreateLabel = (tag: string) => `Add tag "${tag}"`;
  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);
  const onInputChange = (input: string) => setSearchTerm(input);

  const options: TagOption[] = tagsQuery.data ? tagsQuery.data.items.map((tag) => ({ label: tag, value: tag })) : [];
  if (useHookForm) {
    const hookFormRules = { ...defaultHookFormRules, ...rules };

    if (onChange) {
      hookFormRules.onChange = (event) => {
        const tagOptions = event.target.value as TagOption[];

        onChange(tagOptions.map((option) => option.value));
      };
    }

    if (onBlur) {
      hookFormRules.onBlur = onBlur;
    }

    // Hook form manages onChange, onBlur, and value
    return (
      <HookFormCreateable<TagOption, F>
        {...props}
        control={control}
        rules={hookFormRules}
        isMulti
        options={options}
        isLoading={tagsQuery.isFetching}
        formatCreateLabel={formatCreateLabel}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        onInputChange={onInputChange}
        noOptionsMessage={() => 'Type to add tags'}
      />
    );
  }

  const handleChange = (selectedTags: TagOption[]) => {
    if (!onChange) {
      return;
    }

    const tags = new Set(selectedTags.map((t) => t.value.trim()));
    onChange(Array.from(tags));
  };

  return (
    <CustomCreatable<TagOption>
      {...props}
      isMulti
      value={value?.map((d) => ({ value: d, label: d }))}
      options={options}
      isLoading={tagsQuery.isFetching}
      formatCreateLabel={formatCreateLabel}
      onInputChange={onInputChange}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      onChange={handleChange}
      onBlur={onBlur}
      noOptionsMessage={() => 'Type to add tags'}
    />
  );
}

/* eslint-disable import/no-default-export */
export default function SelectTagsContainer<F extends FieldValues = FieldValues>(props: SelectTagsProps<F>) {
  if (props.useHookForm) {
    return <SelectTags<F> {...props} />;
  }

  const { value, kind = TagKind.FLAG, ...otherProps } = props;

  return <OldSelectTagsComponent value={value as string[]} kind={kind} {...otherProps} />;
}

export { SelectTagsContainer as SelectTags };
