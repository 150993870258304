import { AnyAction } from 'redux';
import { ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, debounceTime, map, skip, switchMap, takeUntil } from 'rxjs/operators';

import { fetchSubscriptionPriceFailed, receiveSubscriptionPrice } from 'actions/billing';
import registry from 'epics/registry';
import { GlobalState } from 'reducers';
import { previewSubscriptionPrice } from 'sources/BillingAPI';
import { ImmutableServerError } from 'utils/httpUtils';
import { DEBOUNCE_MS } from 'utils/inputUtils';

export function fetchSubscriptionPrice(
  action$: Observable<AnyAction>,
  _: Observable<GlobalState>,
  { previewSubscriptionPriceAPI } = { previewSubscriptionPriceAPI: previewSubscriptionPrice },
) {
  return action$.pipe(
    ofType('billing/PREVIEW_SUBSCRIPTION_PRICE'),
    debounceTime(DEBOUNCE_MS),
    switchMap((action) =>
      from(previewSubscriptionPriceAPI(action.subscription, action.promoCode)).pipe(
        map((price) => receiveSubscriptionPrice(price, action.promoCode)),
        catchError((error: ImmutableServerError) => of(fetchSubscriptionPriceFailed(error))),
        takeUntil(action$.pipe(skip(1), ofType('billing/PREVIEW_SUBSCRIPTION_PRICE'))),
      ),
    ),
  );
}

registry.addEpics([fetchSubscriptionPrice]);
