import { useEffect } from 'react';

import { fetchPolicyActions } from 'actions/policies';
import { useDispatch } from 'hooks/useDispatch';
import { useSelector } from 'hooks/useSelector';
import { policyActionsSelector } from 'reducers/policies';
import { ready } from 'utils/reduxUtils';

import PolicyStatement, { PolicyStatementProps } from './PolicyStatement';

export type PolicyStatementContainerProps = Omit<PolicyStatementProps, 'policyActions'>;

/* eslint-disable import/no-default-export */
export default function PolicyStatementContainer(props: PolicyStatementContainerProps) {
  const policyActions = useSelector(policyActionsSelector);
  const policyActionEntities = policyActions.get('entities');
  const isPolicyActionsReady = ready(policyActions);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPolicyActions());
  }, []);

  return (
    <PolicyStatement policyActions={policyActionEntities} isPolicyActionsReady={isPolicyActionsReady} {...props} />
  );
}
