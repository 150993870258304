import { useEffect } from 'react';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { useProjectKey } from '@gonfalon/router';
import { Map } from 'immutable';
import { noop } from 'lodash';

import { fetchFlag as fetchFlagAction } from 'actions/flags';
import { GlobalState } from 'reducers';
import { flagByKeySelector } from 'reducers/flags';
import { Flag } from 'utils/flagUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import { ready, serverError } from 'utils/reduxUtils';

import { useDispatch } from './useDispatch';
import { useSelector } from './useSelector';

const useRedux = (projectKey: string, environmentKey: string, flagKey?: string, expandEvaluationEnvKey?: string) => {
  const flagState = useSelector((state: GlobalState) => {
    if (flagKey) {
      return flagByKeySelector(state, { flagKey });
    }

    return {
      entity: undefined,
      request: undefined,
    };
  });

  const isEvaluationReady = expandEvaluationEnvKey
    ? flagState?.entity?.getConfiguration(expandEvaluationEnvKey)?.evaluation
    : true;
  const isReady = ready(flagState.request) && isEvaluationReady;

  const dispatch = useDispatch();
  const fetchFlag = async () => {
    if (flagKey) {
      try {
        await dispatch(
          fetchFlagAction(projectKey, flagKey, environmentKey, { expandEvaluation: !!expandEvaluationEnvKey }),
        );
      } catch (error) {
        noop();
      }
    }
  };

  return {
    isReady,
    flagState,
    fetchFlag,
  };
};

export function useFlagByKey(
  flagKey?: string,
  expandEvaluationEnvKey?: string,
  enable = true,
): { entity: Flag; error: null } | { entity: undefined; error: ImmutableServerError } {
  const projectKey = useProjectKey();
  const environmentKey = useSelectedEnvironmentKey();
  const { isReady, flagState, fetchFlag } = useRedux(projectKey, environmentKey, flagKey, expandEvaluationEnvKey);

  useEffect(() => {
    if (!isReady && enable) {
      fetchFlag().then(noop, noop);
    }
  }, [enable]);

  const error = serverError(flagState.request);

  if (!flagState.entity) {
    return { entity: undefined, error: error || (Map() as ImmutableServerError) };
  }
  return { entity: flagState.entity, error: null };
}
