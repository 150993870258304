import { createConstantNamespace } from 'utils/reduxUtils';

const ns = createConstantNamespace('metrics');

export const REQUEST_METRICS = ns('REQUEST_METRICS');
export const RECEIVE_METRICS = ns('RECEIVE_METRICS');
export const REQUEST_METRICS_FAILED = ns('REQUEST_METRICS_FAILED');
export const REQUEST_METRIC = ns('REQUEST_METRIC');
export const RECEIVE_METRIC = ns('RECEIVE_METRIC');
export const REQUEST_METRIC_FAILED = ns('REQUEST_METRIC_FAILED');
export const EDIT_METRIC = ns('EDIT_METRIC');
export const ADD_URL_MATCHER = ns('ADD_URL_MATCHER');
export const REMOVE_URL_MATCHER = ns('REMOVE_URL_MATCHER');
export const EDIT_URL_MATCHER = ns('EDIT_URL_MATCHER');
export const CREATE_METRIC = ns('CREATE_METRIC');
export const CREATE_METRIC_DONE = ns('CREATE_METRIC_DONE');
export const CREATE_METRIC_FAILED = ns('CREATE_METRIC_FAILED');
export const DELETE_METRIC = ns('DELETE_METRIC');
export const DELETE_METRIC_DONE = ns('DELETE_METRIC_DONE');
export const DELETE_METRIC_FAILED = ns('DELETE_METRIC_FAILED');
export const UPDATE_METRIC = ns('UPDATE_METRIC');
export const UPDATE_METRIC_DONE = ns('UPDATE_METRIC_DONE');
export const UPDATE_METRIC_FAILED = ns('UPDATE_METRIC_FAILED');
