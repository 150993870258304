import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useDispatch } from 'react-redux';

import { FieldPath } from 'utils/formUtils';
import { GenerateActionType } from 'utils/reduxUtils';

export function blur(model: string, field: FieldPath) {
  return {
    type: 'forms/BLUR',
    model,
    field,
  } as const;
}

export function focus(model: string, field: FieldPath) {
  return {
    type: 'forms/FOCUS',
    model,
    field,
  } as const;
}

export function initialize<T>(model: string, initialState: T, options = {}) {
  return {
    type: 'forms/INITIALIZE',
    model,
    initialState,
    ...options,
  } as const;
}

export function destroy(model: string) {
  return {
    type: 'forms/DESTROY',
    model,
  } as const;
}

export function disableSubmit(model: string) {
  return {
    type: 'forms/DISABLE_SUBMIT',
    model,
  } as const;
}

export function enableSubmit(model: string) {
  return {
    type: 'forms/ENABLE_SUBMIT',
    model,
  } as const;
}

export type BindFormActionsType = {
  blur: ReturnType<typeof bindFormActions>['blur'];
  focus: ReturnType<typeof bindFormActions>['focus'];
  initialize: ReturnType<typeof bindFormActions>['initialize'];
  destroy: ReturnType<typeof bindFormActions>['destroy'];
  onBlur: ReturnType<typeof bindFormActions>['onBlur'];
  onFocus: ReturnType<typeof bindFormActions>['onFocus'];
  onInitializeForm: ReturnType<typeof bindFormActions>['onInitializeForm'];
  onDestroyForm: ReturnType<typeof bindFormActions>['onDestroyForm'];
  onDisableSubmit: ReturnType<typeof bindFormActions>['onDisableSubmit'];
  onEnableSubmit: ReturnType<typeof bindFormActions>['onEnableSubmit'];
};

export function bindFormActions(model: string) {
  return {
    blur: (field: FieldPath) => FormActionCreators.blur(model, field),
    focus: (field: FieldPath) => FormActionCreators.focus(model, field),
    initialize: <T>(initialState: T) => FormActionCreators.initialize(model, initialState),
    destroy: () => FormActionCreators.destroy(model),

    // new components follow the onEvent naming convention to make handler props obvious
    // so we're also binding the form actions using that convention.
    onBlur: (field: FieldPath) => FormActionCreators.blur(model, field),
    onFocus: (field: FieldPath) => FormActionCreators.focus(model, field),
    onInitializeForm: <T>(initialState: T) => FormActionCreators.initialize(model, initialState),
    onDestroyForm: () => FormActionCreators.destroy(model),
    onDisableSubmit: () => FormActionCreators.disableSubmit(model),
    onEnableSubmit: () => FormActionCreators.enableSubmit(model),
  };
}

// This is an updated hook version of bindFormActions above.
// This is used in useRedux hooks in our containers.
export function useFormActions(model: string) {
  const dispatch = useDispatch();
  return useMemo(
    () => ({
      onBlur: (field: FieldPath) => dispatch(FormActionCreators.blur(model, field)),
      onFocus: (field: FieldPath) => dispatch(FormActionCreators.focus(model, field)),
      onInitializeForm: <T>(initialState: T, options = {}) =>
        dispatch(FormActionCreators.initialize(model, initialState, options)),
      onDestroyForm: () => dispatch(FormActionCreators.destroy(model)),
      onDisableSubmit: () => dispatch(FormActionCreators.disableSubmit(model)),
      onEnableSubmit: () => dispatch(FormActionCreators.enableSubmit(model)),
    }),
    [dispatch, model],
  );
}

const FormActionCreators = {
  blur,
  focus,
  initialize,
  destroy,
  disableSubmit,
  enableSubmit,
};

export type FormAction = GenerateActionType<typeof FormActionCreators>;
