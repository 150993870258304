import { mergeSearchParams } from '@gonfalon/router';

import { type ProjectContext } from './projectContext';
import { projectContextToURLSearchParams } from './projectContextToURLSearchParams';

export function updateURLSearchParamsWithProjectContext(searchParams: URLSearchParams, nextContext: ProjectContext) {
  const nextParams = new URLSearchParams(searchParams);
  nextParams.delete('env');
  nextParams.delete('selected-env');
  return mergeSearchParams(nextParams, projectContextToURLSearchParams(nextContext));
}
