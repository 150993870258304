import { PureComponent } from 'react';
import type { LegacyIconProps } from '@gonfalon/icons';
import { LegacyIcon } from '@gonfalon/icons';

type MarkerProps = {
  fill?: string;
  stroke?: string;
  strokeWidth?: number;
  className?: string;
  size?: LegacyIconProps['size'];
};

/**
 * @deprecated Use `@launchpad-ui/icons` instead.
 */
export class Marker extends PureComponent<MarkerProps> {
  static defaultProps = {
    fill: '#777', // --lp-color-text-ui-secondary
    stroke: '',
  };

  render() {
    const { fill, stroke, strokeWidth, className, size, ...other } = this.props;
    const styles = { fill, stroke, strokeWidth: strokeWidth || (stroke ? 1 : 0) };

    return (
      <LegacyIcon {...other} size={size} className={className} name="marker" subtle={false}>
        <svg
          aria-hidden="true"
          focusable="false"
          role="img"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect style={styles} x="4" y="4" width="16" height="16" rx="2" />
        </svg>
      </LegacyIcon>
    );
  }
}
