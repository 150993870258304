import { useAccountContext } from '@gonfalon/context';
import { isPlanChangeEnabled } from '@gonfalon/dogfood-flags';
import { hasAdminRights } from '@gonfalon/members';

export function useBillingPermissions() {
  const { profile } = useAccountContext();

  return {
    canChangePlan: hasAdminRights(profile) && isPlanChangeEnabled(),
  };
}
