import AppHeader from 'components/AppHeader';
import { Module, ModuleContent } from 'components/ui/module';

import 'stylesheets/components/AppNav.css';
import './styles.css';

/* eslint-disable import/no-default-export */
export default function AppSkeletonBody() {
  return (
    <div role="progressbar" aria-valuetext="loading" aria-label="loading">
      <AppHeader hasDescription>
        <div className="SkeletonApp--loading SkeletonApp-header"></div>
        <div className="SkeletonApp-headerDescription--loading SkeletonApp--loading"></div>
      </AppHeader>
      <section>
        <Module>
          <ModuleContent>
            <div className="block SkeletonApp--loading SkeletonApp-body--loading"></div>
          </ModuleContent>
        </Module>
      </section>
    </div>
  );
}
