import { isApprovalByTeamEnabled } from '@gonfalon/dogfood-flags';

import { InviteMembersControl, InviteMembersControlProps } from './InviteMembersControl';

type InviteMembersOptionProps = {
  component: string;
  onClick?(): void;
  inviteMembersModalProps?: Pick<InviteMembersControlProps, 'modalProps'>['modalProps'];
};

export const InviteMembersOption = ({ component, onClick, inviteMembersModalProps }: InviteMembersOptionProps) => {
  const notFoundMessage = isApprovalByTeamEnabled() ? 'No members or teams found.' : 'No members found.';

  return (
    <>
      {notFoundMessage}
      <br />
      <InviteMembersControl
        analyticsProps={{
          componentName: component,
          type: 'link',
        }}
        buttonProps={{ kind: 'link', onClick }}
        modalProps={{ referrer: component, ...inviteMembersModalProps }}
      />
    </>
  );
};
