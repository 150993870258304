import { Component, ComponentType, FocusEvent, FormEvent } from 'react';
import { isArray, isString, noop } from '@gonfalon/es6-utils';
import { is, Record } from 'immutable';

import { shallowEqual } from 'utils/comparisonUtils';
import { createFormState, FieldPath, FormContext, FormState, ValidationFunction } from 'utils/formUtils';

function isEvent(v: unknown): v is React.ChangeEvent {
  return typeof v === 'object' && v !== null && 'stopPropagation' in v && 'preventDefault' in v;
}

// Enhance a form component by providing (local) state management,
// and lifecycle hooks.
//
// Usage:
//  withForm<SomeFormRecord>({
//    initialState(props) => {},
//    onSubmit(props, modified) {},
//  })(SomeFormComponent);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormProps = any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormChangeValue = any;

// Types for the arguments passed directly into the `withForm` function (eg, `withForm(args)(...)`)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type WithFormArgs<T extends Record<any>> = {
  initialState: ((props: FormProps, prevProps?: FormProps) => T) | T;
  onSubmit(props: FormProps, modified: T, original: T): T | void;
  validate?: ValidationFunction;
  onChange?(props: FormProps, modified: T): void;
  onSubmitSuccess?(props: FormProps, record?: T): void;
  onSubmitFailed?(props: FormProps, error: Error, modified: T, original: T): void;
  onDestroy?(): void;
};

// Types that are added to the React Component passed into withForm (eg, `withForm(...)(Component)`)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type WithFormEnhancerProps<FormRecordType extends Record<any>> = {
  original: FormRecordType;
  modified: FormRecordType;
  isDirtyAndUnsaved: boolean;
  onBlur(field: FocusEvent | FieldPath): void;
  onChange(fieldName: FieldPath, value?: FormChangeValue): void;
  onSubmit(event: FormEvent<EventTarget>): void;
  onDisableSubmit?(): void;
  onEnableSubmit?(): void;
};

/* eslint-disable import/no-default-export */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function withForm<T extends Record<any>>({
  initialState,
  onSubmit,
  validate,
  onChange = noop,
  onSubmitSuccess = noop,
  onSubmitFailed = noop,
  onDestroy = noop,
}: WithFormArgs<T>) {
  return <PassedComponentProps extends WithFormEnhancerProps<T>, AdditionalContainerProps = {}>(
    PassedComponent: ComponentType<PassedComponentProps>,
  ) => {
    // The wrapper component, which is returned by withForm(args)(Component), takes the passed component's props
    // but not `WithFormEnhancerProps`, which get generated and passed by the HOC
    // You can optionally pass additional container props which are used by the container but not the child component:
    //   withForm<SomeRecord>(args)<ComponentProps, ContainerProps>(Component)
    type WrapperComponentProps = Omit<PassedComponentProps, keyof WithFormEnhancerProps<T>> & AdditionalContainerProps;

    type StateProps = {
      formState: FormState<T>;
    };

    class WithForm extends Component<WrapperComponentProps, StateProps> {
      state = {
        formState: createFormState(this.readInitialState(this.props as WrapperComponentProps), { validate }),
      };
      static displayName: string;

      componentDidUpdate(prevProps: WrapperComponentProps) {
        if (shallowEqual(this.props, prevProps)) {
          return;
        }
        const nextValue = this.readInitialState(this.props as WrapperComponentProps, prevProps);
        if (nextValue && !is(nextValue, this.state.formState.original)) {
          this.setState({
            formState: createFormState(nextValue, { validate }),
          });
        }
      }

      componentWillUnmount() {
        onDestroy();
      }

      render() {
        const { formState } = this.state;

        return (
          <FormContext.Provider value={{ formState: this.state.formState }}>
            <PassedComponent
              {...(this.props as unknown as PassedComponentProps)}
              original={formState.original}
              modified={formState.modified}
              isDirtyAndUnsaved={formState.isFormDirtyAndUnsaved()}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              onSubmit={this.handleSubmit}
              onDisableSubmit={this.handleDisableSubmit}
              onEnableSubmit={this.handleEnableSubmit}
            />
          </FormContext.Provider>
        );
      }

      handleBlur = (field: React.FocusEvent<HTMLInputElement> | FieldPath) => {
        const fieldName = !isString(field) && !isArray(field) && isEvent(field) ? field.target.name : field;
        this.setState(({ formState }) => ({
          formState: formState.hitField(fieldName).revalidate(formState.modified, this.props),
        }));
      };
      handleChange = (field: FieldPath, value: FormChangeValue) => {
        this.setState(
          ({ formState }) => {
            const modified = formState.modified.setIn(isArray(field) ? field : [field], value);
            return {
              formState: formState.trackField(field).revalidate(modified, this.props),
            };
          },
          () => {
            const { formState } = this.state;
            const { modified } = formState;
            if (onChange) {
              onChange(this.props, modified);
            }
          },
        );
      };

      handleSubmit = (event: FormEvent<EventTarget>) => {
        event.preventDefault();
        this.setState(
          ({ formState }) => ({
            formState: formState.submitting(),
          }),
          () => {
            const { formState } = this.state;
            const original = formState.original;
            const modified = formState.modified;
            const result = onSubmit(this.props, modified, original);
            Promise.resolve(result).then(
              (record) => {
                this.setState(
                  (previousState) => ({ formState: previousState.formState.submitted(record || undefined) }),
                  () => onSubmitSuccess(this.props, record || undefined),
                );
              },
              (error) => {
                this.setState(
                  (previousState) => ({
                    formState: previousState.formState.submitFailed(modified, error, false),
                  }),
                  () => onSubmitFailed(this.props, error, modified, original),
                );
              },
            );
          },
        );
      };

      readInitialState(props: WrapperComponentProps, prevProps?: WrapperComponentProps) {
        return typeof initialState === 'function' ? initialState(props, prevProps) : initialState;
      }

      handleDisableSubmit = () => {
        this.setState(({ formState }) => ({
          formState: formState.disableSubmit(),
        }));
      };

      handleEnableSubmit = () => {
        this.setState(({ formState }) => ({
          formState: formState.enableSubmit(),
        }));
      };
    }

    WithForm.displayName = `WithForm(${PassedComponent.displayName || PassedComponent.name})`;

    return WithForm;
  };
}
