export enum MembersAndTeamsInstructionKind {
  ADD_MEMBERS = 'addMembers',
  REMOVE_MEMBERS = 'removeMembers',
  ADD_TEAM_KEYS = 'addTeamKeys',
}

export type SemanticMembersAndTeamsInstruction =
  | SemanticAddMembersInstruction
  | SemanticRemoveMembersInstruction
  | SemanticAddTeamKeysInstruction;

export type SemanticAddMembersInstruction = {
  kind: MembersAndTeamsInstructionKind.ADD_MEMBERS;
  values: string[];
};

export type SemanticRemoveMembersInstruction = {
  kind: MembersAndTeamsInstructionKind.REMOVE_MEMBERS;
  values: string[];
};

export type SemanticAddTeamKeysInstruction = {
  kind: MembersAndTeamsInstructionKind.ADD_TEAM_KEYS;
  values: string[];
};
