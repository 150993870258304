import { List } from 'immutable';

import { Prerequisite } from 'utils/flagUtils';
import { toJS } from 'utils/immutableUtils';
import { InstructionCategory, InstructionsType, SemanticInstruction } from 'utils/instructions/shared/types';
import Logger from 'utils/logUtils';

import { getInstructionsByManyKinds } from '../shared/helpers';

import {
  FlagPrerequisitesInstructionKind,
  SemanticAddPrerequisiteInstruction,
  SemanticPrerequisiteInstruction,
  SemanticRemovePrerequisiteInstruction,
  SemanticReplacePrerequisitesInstruction,
  SemanticUpdatePrerequisiteInstruction,
} from './types';

const logger = Logger.get('prerequisites');

export function makeUpdatePrerequisitesKey(flagKey: string): string {
  return `${InstructionCategory.PREREQUISITES}_${flagKey}`;
}

export function makePrerequisiteSemanticInstruction(
  kind: FlagPrerequisitesInstructionKind,
  instructionProps: SemanticPrerequisiteInstruction,
): SemanticPrerequisiteInstruction {
  switch (kind) {
    case FlagPrerequisitesInstructionKind.ADD_PREREQUISITE:
      return {
        ...toJS(instructionProps as SemanticAddPrerequisiteInstruction),
        kind,
      };
    case FlagPrerequisitesInstructionKind.REMOVE_PREREQUISITE:
      return {
        ...toJS(instructionProps),
        kind,
      };
    case FlagPrerequisitesInstructionKind.UPDATE_PREREQUISITE:
      return {
        ...toJS(instructionProps as SemanticUpdatePrerequisiteInstruction),
        kind,
      };
    case FlagPrerequisitesInstructionKind.REPLACE_PREREQUISITES:
      return {
        ...toJS(instructionProps as SemanticReplacePrerequisitesInstruction),
        kind: FlagPrerequisitesInstructionKind.REPLACE_PREREQUISITES,
      };
    default:
      logger.warn(`Unable to create expiring target semantic instruction with kind ${kind}`);
      return {
        ...toJS(instructionProps as SemanticAddPrerequisiteInstruction),
        kind: FlagPrerequisitesInstructionKind.ADD_PREREQUISITE,
      };
  }
}

/**
 * @param addPrereq - true if the update is updating/adding a prereq, false if update is removing one
 */
export function makeUpdatedPrerequisiteInstructionsArray(
  variationId: string,
  key: string,
  originalPrerequisites: List<Prerequisite>,
  previousPrerequisiteKey: string | null,
  addPrereq: boolean = true,
): SemanticPrerequisiteInstruction[] {
  // find if prerequisite exists
  let prerequisiteAlreadyExists = false;
  for (const prerequisite of originalPrerequisites) {
    if (prerequisite.key === key) {
      prerequisiteAlreadyExists = true;
      break;
    }
  }

  const constructedInstructionsList: SemanticPrerequisiteInstruction[] = [];
  if (addPrereq) {
    if (prerequisiteAlreadyExists) {
      constructedInstructionsList.push(makeUpdatePrerequisiteInstruction(key, variationId));
    } else {
      constructedInstructionsList.push(makeAddPrerequisiteInstruction(key, variationId));
    }
  } else if (prerequisiteAlreadyExists) {
    constructedInstructionsList.push(makeRemovePrerequisiteInstruction(key));
  }

  if (previousPrerequisiteKey) {
    if (addPrereq) {
      constructedInstructionsList.push(makeRemovePrerequisiteInstruction(previousPrerequisiteKey));
    } else {
      constructedInstructionsList.push(makeAddPrerequisiteInstruction(previousPrerequisiteKey, ''));
    }
  }
  return constructedInstructionsList;
}

export function makeUpdatePrerequisiteInstruction(
  key: string,
  variationId: string,
): SemanticUpdatePrerequisiteInstruction {
  return {
    kind: FlagPrerequisitesInstructionKind.UPDATE_PREREQUISITE,
    key,
    variationId,
  };
}

export function makeRemovePrerequisiteInstruction(key: string): SemanticRemovePrerequisiteInstruction {
  return {
    kind: FlagPrerequisitesInstructionKind.REMOVE_PREREQUISITE,
    key,
  };
}

export function makeAddPrerequisiteInstruction(key: string, variationId: string): SemanticAddPrerequisiteInstruction {
  return {
    kind: FlagPrerequisitesInstructionKind.ADD_PREREQUISITE,
    key,
    variationId,
  };
}

export function makeReplacePrerequisiteInstruction(
  key: string,
  prerequisites: Array<{ key: string; variationId: string }>,
): SemanticReplacePrerequisitesInstruction {
  return {
    kind: FlagPrerequisitesInstructionKind.REPLACE_PREREQUISITES,
    key,
    prerequisites,
  };
}

export function combinePrerequisiteInstructions(
  newInstruction: SemanticPrerequisiteInstruction,
  pendingSemanticPatch: InstructionsType,
): InstructionsType {
  if (newInstruction.key === '') {
    // this edge case occurs if user adds prerequisite and deletes it without setting a prerequisite flag
    return pendingSemanticPatch;
  }

  // hashing with instruction.key so that we don't overwrite the same prereq
  const instructionKey = makeUpdatePrerequisitesKey(newInstruction.key);

  const existingInstruction = pendingSemanticPatch.get(instructionKey);
  if (
    existingInstruction?.kind === FlagPrerequisitesInstructionKind.ADD_PREREQUISITE &&
    newInstruction.kind === FlagPrerequisitesInstructionKind.REMOVE_PREREQUISITE
  ) {
    return pendingSemanticPatch.delete(instructionKey);
  } else if (
    existingInstruction?.kind === FlagPrerequisitesInstructionKind.REMOVE_PREREQUISITE &&
    newInstruction.kind === FlagPrerequisitesInstructionKind.ADD_PREREQUISITE
  ) {
    return pendingSemanticPatch.delete(instructionKey);
  }
  return pendingSemanticPatch.set(instructionKey, newInstruction);
}

export function filterSemanticInstructionsByFlagPrerequisiteKinds(semanticInstructions: SemanticInstruction[]) {
  return getInstructionsByManyKinds(semanticInstructions, Object.values(FlagPrerequisitesInstructionKind));
}
