import { useContext } from 'react';
import { Icon } from '@launchpad-ui/icons';
import { Button, ButtonProps } from 'launchpad';

import { FormContext, FormState } from 'utils/formUtils';

export type SubmitButtonProps = Omit<ButtonProps, 'Kind'> & {
  kind?: 'primary' | 'destructive';

  // SubmitButton does not care or know about the type form state.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formState?: FormState<any>;
};

export function SubmitButton(props: SubmitButtonProps) {
  const { loadingText, kind = 'primary', icon, children, disabled, formState: formStateProp, ...other } = props;

  const formState = useContext(FormContext)?.formState || formStateProp;
  const loading = formState?.isFormSubmitting();
  const finalIcon = loading ? <Icon name="more-horiz" /> : icon;
  const text = loading ? loadingText : children;
  let shouldDisable = !formState?.canSubmitForm();
  if (disabled !== null && typeof disabled !== 'undefined') {
    shouldDisable = loading || disabled;
  }

  return (
    <Button type="submit" {...other} kind={kind} icon={finalIcon} disabled={shouldDisable}>
      {text}
    </Button>
  );
}
