// A simple CodeMirror wrapper.
// See http://codemirror.net/doc/manual.html for full docs.
import { Component, TextareaHTMLAttributes } from 'react';
import classNames from 'clsx';
import CodeMirrorPackage from 'codemirror';
import { Label } from 'launchpad';
import { marked } from 'marked'; // Import marked library

import { createFieldErrorId } from 'utils/formUtils';
import Logger from 'utils/logUtils';

import 'codemirror/addon/display/placeholder';

import './styles.css';

const logger = Logger.get('CodeEditor');

const editorSettings = {
  theme: 'neo',
  keyMap: 'sublime',
  autoCloseBrackets: true,
  matchTags: { bothTags: true },
  styleActiveLine: true,
};

const modes = {
  json: {
    mode: 'application/json',
  },
  markdown: {
    mode: 'markdown',
  },
};

export type CodeEditorProps = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> & {
  value?: string;
  mode?: keyof typeof modes;
  lint?: boolean;
  keyMap?: CodeMirror.KeyMap;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  options?: {};
  readOnly?: boolean;
  // CodeMirror is passed in as a prop to allow for lazy-loading
  CodeMirror?: typeof CodeMirrorPackage;
  id?: string;
  name?: string;
  label?: string | JSX.Element | null;
  screenReaderLabel?: string;
  preview?: boolean;
  startCursorAtLine?: number;
  // Optionally bootstraps the editor with any custom modes/code that are specific to editor instances
  bootstrapFn?: (
    editor: CodeMirrorPackage.EditorFromTextArea,
    codeMirror: typeof CodeMirrorPackage,
  ) => CodeMirrorPackage.EditorFromTextArea;
  // If true, will swallow errors that occur when the component unmounts
  // These can trigger when combined with suspense fetches/loading states, and throw globally
  // so they don't get captured by error boundaries. Swallowing them stops the error from bubbling
  // but doesn't prevent the component from unmounting or being remounted.
  swallowUnmountErrors?: boolean;
};

// Usage:
//
// <CodeEditor mode="json" />
export class CodeEditor extends Component<CodeEditorProps> {
  static defaultProps = {
    readOnly: false,
    lint: false,
    preview: false,
    swallowUnmountErrors: false,
  };

  textarea: HTMLTextAreaElement | null = null;
  cm: CodeMirrorPackage.EditorFromTextArea | undefined;

  render() {
    const { value, className, id = 'editor', label = 'Editor', preview } = this.props;
    const classes = classNames('CodeEditor', className);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const markup = { __html: marked(value || '') };

    return (
      <div className={classes}>
        {label && <Label htmlFor={id}>{label}</Label>}
        {preview ? (
          // eslint-disable-next-line react/no-danger
          <div className="CodeEditor-preview">{value && <div dangerouslySetInnerHTML={markup} />}</div>
        ) : (
          <textarea
            autoComplete="off"
            className="CodeEditor-textarea"
            defaultValue={value}
            ref={(element) => {
              this.textarea = element;
            }}
          />
        )}
      </div>
    );
  }

  componentDidMount() {
    const { CodeMirror, preview } = this.props;
    if (CodeMirror && !this.cm && !preview) {
      this.setup();
    }
  }

  componentDidUpdate() {
    const { CodeMirror, preview } = this.props;
    if (this.cm) {
      if (this.value() !== this.props.value) {
        this.cm.setValue(this.props.value || '');
      }
      this.cm.setOption('readOnly', this.props.readOnly ? ('nocursor' as const) : false);
    } else if (CodeMirror && !preview) {
      this.setup();

      if (this.props.bootstrapFn && this.cm) {
        this.props.bootstrapFn(this.cm, CodeMirror);
      }
    }
  }

  componentWillUnmount() {
    if (this.props.swallowUnmountErrors) {
      try {
        this.cm && this.cm.toTextArea();
      } catch (e) {
        // Swallow this error if it was specified to
      }
      return;
    } else {
      this.cm && this.cm.toTextArea();
    }
  }

  setup() {
    const editor = this.textarea;
    const { options, mode, lint, readOnly, keyMap, CodeMirror, screenReaderLabel, startCursorAtLine, placeholder } =
      this.props;

    const modeSettings = mode ? modes[mode] : {};
    const finalOptions: CodeMirrorPackage.EditorConfiguration = {
      ...options,
      ...editorSettings,
      ...modeSettings,
      readOnly: readOnly ? ('nocursor' as const) : false,
      lint,
      lineWrapping: true,
      screenReaderLabel: screenReaderLabel ?? 'Editor',
      placeholder,
    };

    if (!editor) {
      logger.warn('Textarea element is undefined while initializing CodeMirror');
      return;
    }

    let cm = (this.cm = CodeMirror?.fromTextArea(editor, finalOptions));

    if (!cm) {
      logger.warn('CodeMirror failed to initialize textarea properly');
      return;
    }

    if (this.props.bootstrapFn && CodeMirror !== undefined) {
      cm = this.props.bootstrapFn(cm, CodeMirror);
    }

    if (typeof startCursorAtLine === 'number' && cm) {
      setTimeout(() => {
        const line = startCursorAtLine - 1; // Convert to 0-based index
        const lineContent = cm.getLine(line) || '';
        // Trim trailing whitespace to get the last non-whitespace character
        const lastNonWhitespacePos = lineContent.trimEnd().length;
        cm.setCursor({ line, ch: lastNonWhitespacePos });
      }, 0);
    }

    if (keyMap) {
      cm.addKeyMap(keyMap);
    }

    cm.on('change', this.handleChange);
    cm.on('blur', this.handleBlur);
    cm.on('focus', this.handleFocus);

    // Codemirror has a non-hidden input field.
    // This is what we need to describe for accessibility, not the initial textarea that
    // codemirror mounts on and replaces.
    const { id: idProp, name, ...props } = this.props;
    const id = idProp ?? 'editor';
    const inputField = cm.getInputField();
    inputField.setAttribute('id', id);
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    inputField.setAttribute(
      'aria-describedby',
      props['aria-describedby'] ?? createFieldErrorId(id)!,
    ); /* eslint-enable @typescript-eslint/no-non-null-assertion */
    inputField.setAttribute('name', name ?? id);
  }

  handleChange = (editor: CodeMirrorPackage.Editor) => {
    const value = editor.getValue();

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  handleFocus = () => {
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  handleBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  value() {
    return this.cm && this.cm.getValue();
  }

  focus() {
    this.cm && this.cm.focus();
    return this;
  }

  withDocument(fn: (cm?: CodeMirrorPackage.EditorFromTextArea) => void) {
    fn(this.cm);
  }

  lock() {
    this.cm && this.cm.setOption('readOnly', 'nocursor');
    return this;
  }

  unlock() {
    this.cm && this.cm.setOption('readOnly', false);
    return this;
  }
}
