import { constants } from 'utils/reduxUtils';

/* eslint-disable import/no-default-export */
export default constants('integrations', [
  'REQUEST_SLACK_INCOMING_HOOKS',
  'REQUEST_SLACK_INCOMING_HOOKS_DONE',
  'REQUEST_SLACK_INCOMING_HOOKS_FAILED',
  'CREATE_SLACK_INCOMING_HOOK',
  'CREATE_SLACK_INCOMING_HOOK_DONE',
  'CREATE_SLACK_INCOMING_HOOK_FAILED',
  'UPDATE_SLACK_INCOMING_HOOK',
  'UPDATE_SLACK_INCOMING_HOOK_DONE',
  'UPDATE_SLACK_INCOMING_HOOK_FAILED',
  'DELETE_SLACK_INCOMING_HOOK',
  'DELETE_SLACK_INCOMING_HOOK_DONE',
  'DELETE_SLACK_INCOMING_HOOK_FAILED',

  'REQUEST_DATADOG_EVENT',
  'REQUEST_DATADOG_EVENT_DONE',
  'REQUEST_DATADOG_EVENT_FAILED',
  'CREATE_DATADOG_EVENT',
  'CREATE_DATADOG_EVENT_DONE',
  'CREATE_DATADOG_EVENT_FAILED',
  'UPDATE_DATADOG_EVENT',
  'UPDATE_DATADOG_EVENT_DONE',
  'UPDATE_DATADOG_EVENT_FAILED',
  'DELETE_DATADOG_EVENT',
  'DELETE_DATADOG_EVENT_DONE',
  'DELETE_DATADOG_EVENT_FAILED',

  'REQUEST_GOALTENDER_MANIFESTS',
  'REQUEST_GOALTENDER_MANIFESTS_DONE',
  'REQUEST_GOALTENDER_MANIFESTS_FAILED',
  'REQUEST_GOALTENDER_SUBSCRIPTIONS',
  'REQUEST_GOALTENDER_SUBSCRIPTIONS_DONE',
  'REQUEST_GOALTENDER_SUBSCRIPTIONS_DONE_NO_CAPABILITIES',
  'REQUEST_GOALTENDER_MANIFESTS_AND_SUBSCRIPTIONS',
  'REQUEST_GOALTENDER_MANIFESTS_AND_SUBSCRIPTIONS_DONE',
  'REQUEST_GOALTENDER_MANIFESTS_AND_SUBSCRIPTIONS_FAILED',
  'REQUEST_GOALTENDER_SUBSCRIPTIONS_FAILED',
  'CREATE_GOALTENDER_SUBSCRIPTION',
  'CREATE_GOALTENDER_SUBSCRIPTION_DONE',
  'CREATE_GOALTENDER_SUBSCRIPTION_FAILED',
  'UPDATE_GOALTENDER_SUBSCRIPTION',
  'UPDATE_GOALTENDER_SUBSCRIPTION_DONE',
  'UPDATE_GOALTENDER_SUBSCRIPTION_FAILED',
  'DELETE_GOALTENDER_SUBSCRIPTION',
  'DELETE_GOALTENDER_SUBSCRIPTION_DONE',
  'DELETE_GOALTENDER_SUBSCRIPTION_FAILED',
  'DELETE_GOALTENDER_OAUTH_CONFIG',
  'DELETE_GOALTENDER_OAUTH_CONFIG_DONE',
  'DELETE_GOALTENDER_OAUTH_CONFIG_FAILED',
  'DELETE_GOALTENDER_OAUTH_TOKEN',
  'DELETE_GOALTENDER_OAUTH_TOKEN_DONE',
  'DELETE_GOALTENDER_OAUTH_TOKEN_FAILED',
  'CREATE_GOALTENDER_OAUTH_TOKEN',
  'CREATE_GOALTENDER_OAUTH_TOKEN_DONE',
  'CREATE_GOALTENDER_OAUTH_TOKEN_FAILED',
  'SEND_GOALTENDER_TEST_EVENT',
  'SEND_GOALTENDER_TEST_EVENT_DONE',
  'SEND_GOALTENDER_TEST_EVENT_FAILED',
  'FETCH_GOALTENDER_DYNAMIC_ENUM_OPTIONS',
  'FETCH_GOALTENDER_DYNAMIC_ENUM_OPTIONS_DONE',
  'FETCH_GOALTENDER_DYNAMIC_ENUM_OPTIONS_FAILED',

  'CREATE_TRIGGER',
  'CREATE_TRIGGER_DONE',
  'CREATE_TRIGGER_FAILED',
  'DELETE_TRIGGER',
  'DELETE_ALL_TRIGGERS',
  'DELETE_TRIGGER_DONE',
  'DELETE_TRIGGER_FAILED',
  'FETCH_TRIGGERS',
  'FETCH_TRIGGERS_DONE',
  'FETCH_TRIGGERS_FAILED',
  'CLEAR_CREATED_TRIGGERS',
  'PATCH_TRIGGER',
  'PATCH_TRIGGER_DONE',
  'PATCH_TRIGGER_FAILED',

  'REQUEST_DESTINATIONS',
  'REQUEST_DESTINATIONS_DONE',
  'REQUEST_DESTINATIONS_FAILED',
  'CREATE_DESTINATION',
  'CREATE_DESTINATION_DONE',
  'CREATE_DESTINATION_FAILED',
  'UPDATE_DESTINATION',
  'UPDATE_DESTINATION_DONE',
  'UPDATE_DESTINATION_FAILED',
  'DELETE_DESTINATION',
  'DELETE_DESTINATION_DONE',
  'DELETE_DESTINATION_FAILED',
  'SEND_DESTINATION_EVENT',
  'SEND_DESTINATION_EVENT_DONE',
  'SEND_DESTINATION_EVENT_FAILED',

  'REQUEST_REPOSITORIES',
  'REQUEST_REPOSITORIES_DONE',
  'REQUEST_REPOSITORIES_FAILED',
  'UPDATE_REPOSITORY',
  'UPDATE_REPOSITORY_DONE',
  'UPDATE_REPOSITORY_FAILED',
  'DELETE_REPOSITORY',
  'DELETE_REPOSITORY_DONE',
  'DELETE_REPOSITORY_FAILED',

  'MAP_EXTERNAL_APPROVAL_MEMBERS',
  'MAP_EXTERNAL_APPROVAL_MEMBERS_DONE',
  'MAP_EXTERNAL_APPROVAL_MEMBERS_FAILED',

  'SEND_FEATURE_STORE_TEST_EVENT_DONE',
  'SEND_FEATURE_STORE_TEST_EVENT_FAILED',

  'SET_STICKY_INTEGRATION_FILTERS',
  'GET_STICKY_INTEGRATION_FILTERS',
]);
