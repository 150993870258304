import { Children, ComponentPropsWithoutRef, ComponentType, isValidElement, ReactElement, ReactNode } from 'react';
import { mapValues } from '@gonfalon/es6-utils';

import { warning } from './internal/warning';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SlotConfig = Record<string, ComponentType<any>>;

type SlotElements<T extends SlotConfig> = {
  [Property in keyof T]: ReactElement<ComponentPropsWithoutRef<T[Property]>, T[Property]>;
};

/**
 * Extract components from `children` so we can render them in different places.
 *
 * The result array contains,
 * - recognized slots in index 0
 * - any other children in index 1
 *
 * Note that useSlots doesn't support array slots yet. (It can't enforce that
 * children of CardList are instances of Card for example.)
 */
export function useSlots<T extends SlotConfig>(
  children: ReactNode,
  config: T,
): [Partial<SlotElements<T>>, ReactNode[]] {
  const slots: Partial<SlotElements<T>> = mapValues(config, () => undefined);
  const rest: ReactNode[] = [];
  const keys = Object.keys(config) as Array<keyof T>;
  const values = Object.values(config);

  Children.forEach(children, (child) => {
    if (!isValidElement(child)) {
      rest.push(child);
      return;
    }

    const slotIndex = values.findIndex((value) => child.type === value);

    if (slotIndex === -1) {
      rest.push(child);
      return;
    }

    const slotKey = keys[slotIndex];

    // Ignore duplicate slots
    if (slots[slotKey]) {
      warning(true, `Ignoring duplicate "${slotKey.toString()}" slot.`);
      return;
    }

    slots[slotKey] = child as ReactElement<ComponentPropsWithoutRef<T[keyof T]>, T[keyof T]>;
  });

  return [slots, rest];
}
