import { Map } from 'immutable';

export enum FilterKind {
  TYPE = 'type',
  STATUS = 'status',
  TAGS = 'tags',
  MAINTAINER = 'maintainerId',
  MAINTAINER_TEAM = 'maintainerTeamKey',
  EXPERIMENT = 'hasExperiment',
  DATA_EXPORT = 'hasDataExport',
  EVALUATED = 'evaluated',
  SDKAVAILABILITY = 'sdkAvailability',
  TARGETING = 'targeting',
  CODE_REFERENCES = 'codeReferences',
  FOLLOWER = 'followerId',
  CREATION_DATE = 'creationDate',
  CONTEXT_KIND_TARGETED = 'contextKindTargeted',
  CONTEXT_KINDS_EVALUATED = 'contextKindsEvaluated',
  SEGMENT_TARGETED = 'segmentTargeted',
  APPLICATION_EVALUATED = 'applicationEvaluated',
  STATE = 'state',
}

export type FilterValue = string | string[] | number | boolean | Map<string, number>;
