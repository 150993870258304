import { daysBetweenDates } from '@gonfalon/format';

import { getEndDate } from './getEndDate';
import { CampaignType } from './types';

export function daysRemaining(campaign: CampaignType) {
  const { endDateTime } = campaign;
  if (!!endDateTime) {
    return daysBetweenDates(getEndDate(campaign), new Date());
  }
  return 0;
}
