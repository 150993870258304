import { type ListBoxItemProps, composeRenderProps, ListBoxItem as ListBoxItem_ } from 'react-aria-components';
import clsx from 'clsx';

import styles from './ListBox.module.css';

export function ListBoxItem<T extends object>(props: ListBoxItemProps<T>) {
  return (
    <ListBoxItem_
      {...props}
      className={composeRenderProps(props.className, (className) => clsx(className, styles.item))}
    />
  );
}
