import { useEffect, useRef } from 'react';
import { announce } from '@react-aria/live-announcer';
import classNames from 'clsx';

import { Cell, CellLayoutValue } from 'components/ui/grid';
import styles from 'stylesheets/components/EmptyState.module.css';

import GridSkeleton from './skeletons/GridSkeleton';
import DocumentationLink from './DocumentationLink';

import 'components/SkeletonLoader/styles.css';

export type EmptyStateProps = {
  action?: React.ReactNode;
  className?: string;
  documentation?: string;
  image?: JSX.Element;
  isCentered?: boolean;
  lead?: string | JSX.Element;
  noBorder?: boolean;
  showSkeletonLoading?: boolean;
  testId?: string;
  title?: string;
  titleEl?: JSX.Element;
};

/* eslint-disable import/no-default-export */
export default function EmptyState({
  action,
  className,
  documentation,
  image,
  isCentered = true,
  lead,
  noBorder = false,
  showSkeletonLoading,
  testId = 'EmptyState',
  title,
  titleEl,
}: EmptyStateProps) {
  const renderSkeletonLoading = (numberOfSkeletonRows: number) =>
    Array.from({ length: numberOfSkeletonRows }, (_, i) => (
      <Cell key={i}>
        <span className="block SkeletonApp--loading SkeletonApp-table-row--loading-thin " />
      </Cell>
    ));
  const classes = classNames(
    styles.emptyState,
    { [styles.centered]: isCentered, [styles.noBorder]: noBorder },
    className,
  );
  const leadRef = useRef<HTMLDivElement | HTMLParagraphElement>(null);

  useEffect(() => {
    announce(`${title} ${leadRef.current?.innerText}`, 'polite', 300);
  }, []);

  let titleElement = null;

  // use a title element if present
  if (titleEl) {
    titleElement = titleEl;
  }
  // if a title string is passed in it takes precedence as the title (since that's the widely used case)
  if (title) {
    titleElement = <p className={styles.title}>{title}</p>;
  }

  return (
    <div className={classes} data-test-id={testId}>
      {image}
      {titleElement}

      {(lead || documentation || action) && (
        <div className={styles.body}>
          {lead && (
            <span className={styles.lead} ref={leadRef}>
              {lead}
            </span>
          )}
          {documentation && (
            <div className={styles.documentation}>
              To learn more, <DocumentationLink href={documentation} component="EmptyState" />
            </div>
          )}
          {action && <div className={styles.action}>{action}</div>}
        </div>
      )}
      {showSkeletonLoading && (
        <GridSkeleton>
          <Cell layout={CellLayoutValue.FIVE_OF_TWELVE}>
            <>{renderSkeletonLoading(5)}</>
          </Cell>
          <Cell layout={CellLayoutValue.TWO_OF_TWELVE}>
            <>{renderSkeletonLoading(5)}</>
          </Cell>
          <Cell layout={CellLayoutValue.FIVE_OF_TWELVE}>
            <>{renderSkeletonLoading(5)}</>
          </Cell>
        </GridSkeleton>
      )}
    </div>
  );
}
