import { OrderedSet } from 'immutable';

export const DEBOUNCE_MS = 200;

export function tokenizeInput(input: string): OrderedSet<string> {
  if (!input) {
    return OrderedSet.of();
  }

  const values = input
    .split(/[\n,]/g)
    .map((v) => v.trim())
    .filter((v) => v.length > 0);

  return OrderedSet.of(...values);
}

export function isBulkInput(input: string) {
  return tokenizeInput(input).size > 1;
}

export function readPastedInput(event: ClipboardEvent | React.ClipboardEvent<HTMLElement>) {
  const input = event.clipboardData?.getData('text/plain') ?? '';

  return input;
}
