import { ReactNode } from 'react';
import { StringUnion } from '@gonfalon/types';
import { Map } from 'immutable';
import nullthrows from 'nullthrows';

import { ProgressiveRolloutVariationDisplay } from 'components/automated-rollouts/progressive-rollouts/ProgressiveRolloutVariationDisplay';
import { Flag, Rule } from 'utils/flagUtils';

import { RolloutDescription } from './RolloutDescription';

import styles from './StopProgressiveRolloutInstructionEntry.module.css';

const currentPercentageDisplayValue = 'current percentage';

export function StopProgressiveRolloutInstructionEntry({
  flag,
  environmentKey,
  ruleIdOrFallthrough,
  endVariationId,
}: {
  flag: Flag;
  environmentKey: string;
  ruleIdOrFallthrough: StringUnion<'fallthrough'>;
  endVariationId?: string;
}) {
  let ruleName: string;
  let rule: Rule | undefined;

  if (ruleIdOrFallthrough === 'fallthrough') {
    ruleName = 'Default rule';
  } else {
    const ruleIndex = flag.getRuleIndex(environmentKey, ruleIdOrFallthrough);
    if (ruleIndex > -1) {
      rule = nullthrows(
        flag.getRules(environmentKey)?.get(ruleIndex),
        `expected flag to have rule in environment with key "${environmentKey}" at index ${ruleIndex}`,
      );
      ruleName = rule.description || `Rule ${ruleIndex + 1}`;
    } else {
      ruleName = 'Rule not found';
    }
  }

  let variationDisplay: ReactNode = currentPercentageDisplayValue;

  if (endVariationId) {
    const variationIndex = flag.getVariationIndexFromId(endVariationId);
    if (variationIndex > -1) {
      variationDisplay = (
        <ProgressiveRolloutVariationDisplay
          variation={nullthrows(flag.variations.get(variationIndex))}
          variationIndex={variationIndex}
        />
      );
    } else {
      variationDisplay = 'Variation not found';
    }
  }

  const rolloutWeightEntries = rule?.rollout?.variations
    .valueSeq()
    .map((wv): [variationId: string, rolloutWeight: number] => [
      nullthrows(flag.variations.get(wv.get('variation'))?._id),
      wv.get('weight'),
    ]);

  const rolloutWeightsMap = rolloutWeightEntries && Map(rolloutWeightEntries);

  return (
    <div>
      <div>Rule: {ruleName}</div>
      <div className={styles.serve}>Serve: {variationDisplay}</div>
      {variationDisplay === currentPercentageDisplayValue && rule?.rollout && rolloutWeightsMap && (
        <RolloutDescription
          contextKind={rule.rollout.contextKind}
          rolloutWeights={rolloutWeightsMap}
          variations={flag.variations}
          bucketBy={rule.rollout.bucketBy}
        />
      )}
    </div>
  );
}
