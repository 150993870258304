import { createTrackerForCategory } from '@gonfalon/analytics';
import { baseUri } from '@gonfalon/constants';
import { halfwayIA } from '@gonfalon/dogfood-flags';
import { environmentScoped, projectScoped } from '@gonfalon/router';
import nullthrows from 'nullthrows';
import qs from 'qs';

const envContextRegex =
  /^\/[0-9a-zA-Z\-\_\.]+\/[0-9a-zA-Z\-\_\.]+\/(features|segments|users|contexts|console|debugger|live|audit|quickstart|experiments|get-started|metrics\/analytics)(\/.*)?$/;
const projectContextRegex = /^\/[0-9a-zA-Z\-\_\.]+\/(goals|metrics|integrations)(\/.*)?$/;

const v2ProjectContextRegex =
  /^\/projects\/[0-9a-zA-Z\-\_\.]+\/(?<section>flags|experiments|integrations|contexts|segments|audit|metrics\/analytics)(?<rest>\/.*)?$/;

function updateFilterEnvFilter(search: string, envKey: string) {
  const updateSearch = qs.parse(search, { ignoreQueryPrefix: true });
  const filterEnv = updateSearch.filterEnv;

  if (filterEnv) {
    if (filterEnv !== envKey) {
      updateSearch.filterEnv = envKey;
      return qs.stringify(updateSearch, { indices: false, addQueryPrefix: true });
    }
  }

  return search;
}

type PreviousLocationContext = {
  previousProjectKey: string;
  previousEnvironmentId: string;
};
type LocationContext = { projectKey: string; environmentId: string; environmentKey: string };

function transformUrlContext(
  location: Location,
  { previousProjectKey, previousEnvironmentId }: PreviousLocationContext,
  { projectKey, environmentKey, environmentId }: LocationContext,
) {
  const sameProject = previousProjectKey === projectKey;
  const sameEnvironment = previousEnvironmentId === environmentId;
  const path = location.pathname;
  const search = updateFilterEnvFilter(location.search, environmentKey);
  const hash = location.hash;

  if (halfwayIA()) {
    const match = v2ProjectContextRegex.exec(path);
    if (match && match.length > 0) {
      // if we have a match, we must have a section
      const section = nullthrows(match.groups?.section);
      // we may not have a nested route though
      const rest = match.groups?.rest ?? '';
      const searchParams = new URLSearchParams(search);
      searchParams.set('env', environmentKey);

      const nextUrl = new URL(`/projects/${projectKey}/${section}${rest}`, window.location.origin);
      nextUrl.search = searchParams.toString();
      return nextUrl.toString().replace(baseUri(), '');
    }
  }

  let match = envContextRegex.exec(path);

  if (match && match.length > 0) {
    const [, section, rest = ''] = match;
    let subpath;

    if (!sameEnvironment && environmentScoped.includes(section)) {
      subpath = '';
    } else if (!sameProject && projectScoped.includes(section)) {
      subpath = '';
    } else {
      subpath = `${rest}${search}${hash}`;
    }

    return `/${projectKey}/${environmentKey}/${section}${subpath}`;
  }

  match = projectContextRegex.exec(path);
  if (match && match.length > 0) {
    const [, section, rest = ''] = match;
    let subpath;

    if (!sameProject && projectScoped.includes(section)) {
      subpath = '';
    } else {
      subpath = `${rest}${search}${hash}`;
    }

    return `/${projectKey}/${section}${subpath}`;
  }

  return location.pathname + location.search + location.hash;
}

export const getNewUrlContext = (
  { previousProjectKey, previousEnvironmentId }: PreviousLocationContext,
  { projectKey, environmentId, environmentKey }: LocationContext,
) =>
  transformUrlContext(
    window.location,
    { previousProjectKey, previousEnvironmentId },
    { projectKey, environmentId, environmentKey },
  );

export const trackPageLeaveEvent = createTrackerForCategory('PageLeave');
