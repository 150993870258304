import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { useSegment } from '@gonfalon/rest-api';
import { useProjectKey } from '@gonfalon/router';

type UseOriginalSegmentProps = {
  segmentKey: string;
};

export function useOriginalSegment({ segmentKey }: UseOriginalSegmentProps) {
  const environmentKey = useSelectedEnvironmentKey();
  const projectKey = useProjectKey();
  const { data: originalSegment } = useSegment({ projectKey, environmentKey, segmentKey });
  return originalSegment;
}
