// eslint-disable-next-line no-restricted-imports
import { fromJS } from 'immutable';
import { normalize, schema } from 'normalizr';

import http, { middleware } from 'utils/httpUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { PolicyActionsObject } from 'utils/policyUtils';

const policyActions = new schema.Entity('policyActions', {}, { idAttribute: 'key' });

const getActions = async () => {
  const url = '/internal/actions?';
  const searchParams = new URLSearchParams({
    includeInternalActions: 'false',
  });
  const request = await http.get(url + searchParams.toString());
  const json = await middleware.json(request).catch(middleware.jsonError);
  const data = Object.keys(json).reduce<
    Array<{
      key: string;
      items: {
        entities: {
          policyActions: PolicyActionsObject;
        };
      };
    }>
  >((arr, key) => [...arr, { key, items: json[key] }], []);

  const normalizedData = normalize(data, [policyActions]);

  return fromJS(normalizedData) as ImmutableMap<{
    entities: {
      policyActions: PolicyActionsObject;
    };
    result: string[];
  }>;
};
export { getActions };
