import { isArray } from '@gonfalon/es6-utils';
import { Set } from 'immutable';

import { FilterKind, FilterValue } from '../../components/ui/multipleFilters/types';

import FlagFilterInterface, { BackendQueryParamName, QueryParamName } from './flagFilterInterface';
import { FilterOperator, FlagListBackendQueryParam, FlagListQueryParam } from './types';

class ContextKindsEvaluatedFilter implements FlagFilterInterface<string[]> {
  name: string;
  value: Set<string>;
  kind: FilterKind;
  queryParamName: QueryParamName;
  backendQueryParamName: BackendQueryParamName;
  operators: string[];
  defaultValue: string;

  constructor(value?: Set<string>) {
    this.name = 'Context kinds evaluated';
    this.value = value || Set();
    this.kind = FilterKind.CONTEXT_KINDS_EVALUATED;
    this.queryParamName = FlagListQueryParam.CONTEXT_KINDS_EVALUATED;
    this.backendQueryParamName = FlagListBackendQueryParam.CONTEXT_KINDS_EVALUATED;
    this.operators = [FilterOperator.ARE_ALL_OF];
    this.defaultValue = '';
  }

  getFilterValueDisplayName(value?: FilterValue) {
    if (isArray(value)) {
      return value.join(', ');
    }

    return '';
  }

  getDisplayName = () => this.name;

  getOptions() {
    // context kinds must make API request to fetch options
    return [];
  }

  isEnabled = () => true;

  isEmpty = () => this.value.isEmpty();

  toQuery = () => this.value.toArray();

  isInvalidFilter = () => false;

  clearFilter = () => (this.value = Set());

  toBackendQuery() {
    if (this.isEmpty()) {
      return '';
    }

    return `contextKindsEvaluated:${this.value.toArray().join('+')}`;
  }

  parseFilterParam(queryParts: string[]) {
    if (queryParts.length !== 2) {
      return [];
    }
    const [, value] = queryParts;
    return value.split('+');
  }

  getFilterData() {
    return {
      operators: this.operators,
      options: [],
      defaultValue: this.defaultValue,
    };
  }
}

/* eslint-disable import/no-default-export */
export default ContextKindsEvaluatedFilter;
