import { createRelativeDifferenceFormatter, createValueFormatter } from '@gonfalon/metrics';

import { NormalizedTreatmentResult } from './types';

export function getTreatmentResultFormatters(results: NormalizedTreatmentResult[], asPercent?: boolean) {
  const valueIntervals = results.map((result) => result.valueInterval);
  const allRelativeDifferences = results.flatMap((result) => result.relativeDifferences);

  const formatValue = createValueFormatter(valueIntervals, asPercent);
  const formatRelativeDifference = createRelativeDifferenceFormatter(allRelativeDifferences);

  return { formatValue, formatRelativeDifference };
}
