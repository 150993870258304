import { CustomSelect, OptionProps } from '@gonfalon/launchpad-experimental';
import { FormGroup, Label } from 'launchpad';

type PolicyStatementEffectProps = {
  customEffects?: { allow: string; deny: string };
  effect: string;
  onChange: (key: 'effect', value: string) => void;
};

const PolicyStatementEffect = ({ customEffects, effect, onChange }: PolicyStatementEffectProps) => {
  const defaultEffects = {
    allow: 'Allow',
    deny: 'Deny',
  };

  type defaultEffectsType = keyof typeof defaultEffects;

  const createEffectOptions = (effects: { allow: string; deny: string }) =>
    Object.keys(effects).map((k) => ({ value: k, label: effects[k as defaultEffectsType] }));

  const effectOptions = createEffectOptions(customEffects ?? defaultEffects);

  return (
    <FormGroup>
      <Label htmlFor="PolicyStatement-Effect">Allow or deny actions on the resource</Label>
      <CustomSelect
        id="PolicyStatement-Effect"
        backspaceRemoves={false}
        onChange={(option: OptionProps) => option && onChange('effect', option.value)}
        options={effectOptions}
        value={effectOptions.filter((e) => e.value === effect)}
      />
    </FormGroup>
  );
};

/* eslint-disable import/no-default-export */
export default PolicyStatementEffect;
