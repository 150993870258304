import { maximumAllowedMonitoredRolloutDurationMs } from '@gonfalon/dogfood-flags';
import { isNil } from '@gonfalon/es6-utils';
import { millisecondsToDuration } from '@gonfalon/measured-rollouts';
import { formatDuration } from 'date-fns';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, Record } from 'immutable';

import {
  Fallthrough,
  MeasuredRolloutConfig,
  Prerequisite,
  Rollout,
  Target,
  Variation,
  VariationType,
  WeightedVariation,
} from 'utils/flagUtils';

import { max, min, required } from './errors';

export const variationNotFound = (variation: number) =>
  fromJS({
    type: 'variationNotFound',
    variation,
  });

export const variationOrRollout = (variation?: number, rollout?: Rollout) =>
  fromJS({
    type: 'variationOrRollout',
    variation,
    rollout,
  });

export const variationTypeMismatch = (variations: List<Variation>, variationType?: VariationType) =>
  fromJS({
    type: 'variationTypeMismatch',
    variations,
    variationType,
  });

export const variationDuplicateNames = (variations: List<Variation>, duplicates: List<Variation>) =>
  fromJS({
    type: 'variationDuplicateNames',
    variations,
    duplicates,
  });

export const variationDuplicateValues = (variations: List<Variation>, duplicates: List<Variation>) =>
  fromJS({
    type: 'variationDuplicateValues',
    variations,
    duplicates,
  });

export const invalidAttribute = (name: string) =>
  fromJS({
    type: 'invalidAttribute',
    name,
  });

type RolloutValidationProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bucketBy: Map<any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variations: Map<any, WeightedVariationValidation>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  root: Map<any, any>;
};

export class RolloutValidation extends Record<RolloutValidationProps>({
  bucketBy: Map(),
  variations: Map(),
  root: Map(),
}) {
  isEmpty() {
    return this.variations.isEmpty() && this.root.isEmpty() && this.bucketBy.isEmpty();
  }

  isInvalid() {
    return (
      this.variations.some((v) => v.isInvalid()) ||
      this.getIn(['root', 'type']) === 'max' ||
      this.getIn(['root', 'type']) === 'min' ||
      this.getIn(['root', 'type']) === 'variationNotFound' ||
      this.getIn(['bucketBy', 'type']) === 'invalidAttribute'
    );
  }

  isIncomplete() {
    return this.variations.some((v) => v.isIncomplete());
  }

  getMessage() {
    if (this.isEmpty()) {
      return;
    }

    if (this.isInvalid()) {
      if (this.getIn(['bucketBy', 'type']) === 'invalidAttribute') {
        const attributeName = this.getIn(['bucketBy', 'name']);
        return `Invalid attribute "${attributeName}" specified. Bucketing by this attribute is not allowed.`;
      }
    }
  }
}

interface MeasuredRolloutConfigValidationProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  randomizationUnit: Map<string, any>;
  monitoringWindowMilliseconds: Map<string, any>;
  rolloutWeight: Map<string, any>;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  stages?: List<{
    rolloutWeight: number;
    monitoringWindowMilliseconds: number;
  }>;
  metrics?: List<{
    metricKey: string;
    regressionThreshold: number;
    onRegression: {
      notify: boolean;
      rollback: boolean;
    };
  }>;
}

export class MeasuredRolloutConfigValidation extends Record<MeasuredRolloutConfigValidationProps>({
  randomizationUnit: Map(),
  monitoringWindowMilliseconds: Map(),
  rolloutWeight: Map(),
}) {
  isEmpty() {
    return (
      this.randomizationUnit.isEmpty() &&
      this.monitoringWindowMilliseconds.isEmpty() &&
      (!this.stages || this.stages.isEmpty()) &&
      (!this.metrics || this.metrics.isEmpty())
    );
  }

  isInvalid() {
    return (
      this.monitoringWindowMilliseconds.get('type') === 'max' ||
      this.monitoringWindowMilliseconds.get('type') === 'min' ||
      this.rolloutWeight.get('type') === 'max' ||
      this.rolloutWeight.get('type') === 'min'
    );
  }

  isIncomplete() {
    return this.randomizationUnit.get('type') === 'required';
  }

  /* TODO: Add V2 validation messages in a follow up PR */
  getMessage() {
    if (this.isEmpty()) {
      return;
    }

    if (this.isInvalid()) {
      if (this.monitoringWindowMilliseconds.get('type') === 'max') {
        const duration = formatDuration(millisecondsToDuration(this.monitoringWindowMilliseconds.get('max')));
        return `Guarded rollouts cannot be monitored for more than ${duration}`;
      }

      if (this.monitoringWindowMilliseconds.get('type') === 'min') {
        return 'Guarded rollouts require a monitoring window greater than zero';
      }

      if (this.rolloutWeight.get('type') === 'max') {
        const value = this.rolloutWeight.get('max');
        return `Guarded rollouts cannot allocate more than ${value / 1000}% of traffic to the test variation`;
      }

      if (this.rolloutWeight.get('type') === 'min') {
        return 'Guarded rollouts require a traffic allocation greater than zero';
      }
    }

    if (this.isIncomplete()) {
      if (this.randomizationUnit.get('type') === 'required') {
        return 'Guarded rollouts require a randomization unit';
      }
    }
  }
}

export class WeightedVariationValidation extends Record({
  variation: Map(),
  weight: Map(),
}) {
  isEmpty() {
    return this.variation.isEmpty() && this.weight.isEmpty();
  }

  isInvalid() {
    return this.getIn(['weight', 'type']) === 'min' || this.getIn(['weight', 'type']) === 'max';
  }

  isIncomplete() {
    return this.getIn(['variation', 'type']) === 'required';
  }

  getMessage() {
    if (this.isEmpty()) {
      return;
    }

    if (this.isInvalid()) {
      if (this.getIn(['weight', 'type']) === 'min') {
        const value = this.getIn(['weight', 'min']);
        return `The rollout percentage must be at least ${value}`;
      }

      if (this.getIn(['weight', 'type']) === 'max') {
        const value = this.getIn(['weight', 'max']);
        return `The rollout percentage must be at most ${value}`;
      }
    }

    if (this.isIncomplete()) {
      return 'You must specify a rollout';
    }
  }
}

export class PrerequisiteValidation extends Record({
  key: null,
}) {
  isEmpty() {
    return !this.key;
  }

  isInvalid() {
    return false;
  }

  isIncomplete() {
    return !this.isEmpty() && this.getIn(['key', 'type']) === 'required';
  }

  getMessage() {
    if (this.isEmpty()) {
      return;
    }

    if (this.isIncomplete()) {
      return 'You must select a feature flag';
    }
  }
}

export class UserTargetsValidation extends Record({
  userTargets: List(),
  alertThreshold: -1,
  warningThreshold: -1,
}) {
  isEmpty() {
    return !this.userTargets || this.getNumberTargets() === 0;
  }

  enforceWarning() {
    return this.warningThreshold >= 0;
  }

  enforceAlert() {
    return this.alertThreshold >= 0;
  }

  getNumberTargets() {
    return this.userTargets.reduce((sum, target) => sum + parseInt(target.values.size, 10), 0);
  }

  hasTooManyTargetsAlert() {
    return this.enforceAlert() ? !this.isEmpty() && this.getNumberTargets() > this.alertThreshold : false;
  }

  isAlert() {
    return this.enforceAlert() ? this.hasTooManyTargetsAlert() : false;
  }

  isWarning() {
    return this.enforceWarning()
      ? !this.isEmpty() &&
          (!this.enforceAlert() || this.getNumberTargets() <= this.alertThreshold) &&
          this.getNumberTargets() > this.warningThreshold
      : false;
  }
}

export class FallthroughValidation extends Record({
  root: Map(),
  rollout: new RolloutValidation(),
  measuredRolloutConfig: new MeasuredRolloutConfigValidation(),
}) {
  isEmpty() {
    return this.root.isEmpty() && this.rollout.isEmpty() && this.measuredRolloutConfig.isEmpty();
  }

  isInvalid() {
    return (
      !this.isEmpty() &&
      (this.rollout.isInvalid() ||
        this.measuredRolloutConfig.isInvalid() ||
        this.getIn(['root', 'type']) === 'variationNotFound')
    );
  }

  isIncomplete() {
    return (
      !this.isEmpty() &&
      (this.rollout.isIncomplete() ||
        this.measuredRolloutConfig.isIncomplete() ||
        this.getIn(['root', 'type']) === 'variationOrRollout')
    );
  }

  getMessage() {
    if (this.isEmpty()) {
      return;
    }

    if (this.isInvalid()) {
      if (this.getIn(['root', 'type']) === 'variationNotFound') {
        return 'An invalid variation was specified.  You must select a valid variation.';
      }

      if (this.getIn(['rollout', 'root', 'type']) === 'variationNotFound') {
        return 'An invalid variation was specified in the rollout.  You must reconfigure the percentage rollout.';
      }

      if (this.measuredRolloutConfig.isInvalid()) {
        return this.measuredRolloutConfig.getMessage();
      }
    }

    if (this.isIncomplete()) {
      if (this.getIn(['root', 'type']) === 'variationOrRollout') {
        return 'You must either select a variation to serve or set a percentage rollout';
      }

      if (this.measuredRolloutConfig.isIncomplete()) {
        return this.measuredRolloutConfig.getMessage();
      }
    }
  }
}

export function validatePrerequisite(prereq: Prerequisite) {
  let validation = new PrerequisiteValidation();

  if (prereq.key === '') {
    validation = validation.set('key', required(prereq.key));
  }

  return validation;
}

export type ThresholdsType = {
  userTargetAlertThreshold?: number;
  userTargetWarningThreshold?: number;
};

export function validateUserTargets(userTargets: List<Target>, thresholds: ThresholdsType = {}) {
  return new UserTargetsValidation({
    userTargets,
    alertThreshold: thresholds.userTargetAlertThreshold,
    warningThreshold: thresholds.userTargetWarningThreshold,
  });
}

export function validateWeightedVariation(wv: WeightedVariation, variations: List<Variation>) {
  let validation = new WeightedVariationValidation();

  if (isNil(wv.variation)) {
    validation = validation.set('variation', required(wv.variation));
  }

  if (isFinite(wv.weight) && wv.weight < 0) {
    validation = validation.set('weight', min(0, wv.weight));
  }

  if (variations && !variations.get(wv.variation)) {
    validation = validation.set('variation', variationNotFound(wv.variation));
  }

  return validation;
}

export function validateRollout(rollout: Rollout, variations?: List<Variation>, weightTotal?: number) {
  let validation = new RolloutValidation();

  const total = weightTotal
    ? weightTotal
    : rollout.variations.reduce((sum, wv) => (!wv._untracked ? sum + wv.weight : sum + 0), 0);

  if (total > 100000) {
    validation = validation.set('root', max(100000, total));
  }

  if (total < 100000 && !rollout.experimentAllocation) {
    validation = validation.set('root', min(100000, total));
  }

  if (rollout.experimentAllocation && total < 0) {
    validation = validation.set('root', min(0, total));
  }

  if (rollout.bucketBy === 'secondary') {
    validation = validation.set('bucketBy', invalidAttribute(rollout.bucketBy));
  }

  for (let i = 0; i < rollout.variations.size; i++) {
    const wv = rollout.variations.get(i);
    if (wv && variations) {
      const result = validateWeightedVariation(wv, variations);
      if (!result.isEmpty()) {
        if (result.getIn(['variation', 'type']) === 'variationNotFound') {
          validation = validation.set('root', result.variation);
        } else {
          validation = validation.setIn(['variations', i], result);
        }
      }
    }
  }

  return validation;
}

export function validateMeasuredRolloutConfig(config: MeasuredRolloutConfig) {
  let validation = new MeasuredRolloutConfigValidation();
  const maxDuration = maximumAllowedMonitoredRolloutDurationMs();

  if (!config.randomizationUnit) {
    validation = validation.set('randomizationUnit', required(config.randomizationUnit));
  }

  if (config.monitoringWindowMilliseconds > maxDuration) {
    validation = validation.set('monitoringWindowMilliseconds', max(maxDuration, config.monitoringWindowMilliseconds));
  }

  if (config.monitoringWindowMilliseconds < 1) {
    validation = validation.set('monitoringWindowMilliseconds', min(0, config.monitoringWindowMilliseconds));
  }

  if (config.rolloutWeight > 50000) {
    validation = validation.set('rolloutWeight', max(50000, config.rolloutWeight));
  }

  if (config.rolloutWeight < 1000) {
    validation = validation.set('rolloutWeight', min(1, config.rolloutWeight));
  }

  if (config.stages && config.stages.isEmpty()) {
    validation = validation.set('stages', required(config.stages));
  }

  if (
    config.stages &&
    config.stages.some((stage) => stage.rolloutWeight < 1 || stage.monitoringWindowMilliseconds < 1)
  ) {
    validation = validation.set('stages', invalidAttribute('stages'));
  }

  if (config.stages && !config.stages.isEmpty()) {
    const maxStageRolloutWeight = config.stages.maxBy((stage) => stage.rolloutWeight)?.rolloutWeight;
    const maxStageMonitoringWindow = config.stages.maxBy(
      (stage) => stage.monitoringWindowMilliseconds,
    )?.monitoringWindowMilliseconds;

    const minStageMonitoringWindow = config.stages.minBy(
      (stage) => stage.monitoringWindowMilliseconds,
    )?.monitoringWindowMilliseconds;
    const minStageRolloutWeight = config.stages.minBy((stage) => stage.rolloutWeight)?.rolloutWeight;

    if (minStageRolloutWeight && minStageRolloutWeight < 1) {
      validation = validation.setIn(['stages', 'rolloutWeight'], min(1, minStageRolloutWeight));
    }

    if (minStageMonitoringWindow && minStageMonitoringWindow < 1) {
      validation = validation.setIn(['stages', 'monitoringWindowMilliseconds'], min(1, minStageMonitoringWindow));
    }

    if (maxStageRolloutWeight && maxStageRolloutWeight > 50000) {
      validation = validation.setIn(['stages', 'rolloutWeight'], max(50000, maxStageRolloutWeight));
    }

    if (maxStageMonitoringWindow && maxStageMonitoringWindow > maxDuration) {
      validation = validation.setIn(
        ['stages', 'monitoringWindowMilliseconds'],
        max(maxDuration, maxStageMonitoringWindow),
      );
    }
  }

  if (config.metrics && !config.metrics.isEmpty()) {
    if (config.metrics.some((metric) => metric.regressionThreshold < 0)) {
      validation = validation.set('metrics', invalidAttribute('metrics'));
    }

    if (
      config.metrics.some(
        (metric) => metric.onRegression && (metric.onRegression.notify || metric.onRegression.rollback),
      )
    ) {
      validation = validation.set('metrics', invalidAttribute('metrics'));
    }

    if (config.metrics.some((metric) => metric.metricKey === '')) {
      validation = validation.set('metrics', invalidAttribute('metrics'));
    }
  }

  return validation;
}

export function validateFallthrough(fallthrough: Fallthrough, variations: List<Variation>) {
  let validation = new FallthroughValidation();

  if (
    (isNil(fallthrough.variation) && isNil(fallthrough.rollout)) ||
    (!isNil(fallthrough.variation) && !isNil(fallthrough.rollout))
  ) {
    validation = validation.set('root', variationOrRollout(fallthrough.variation, fallthrough.rollout));
  }

  if (fallthrough.variation && !variations.get(fallthrough.variation)) {
    validation = validation.set('root', variationNotFound(fallthrough.variation));
  }

  if (fallthrough.rollout) {
    const result = validateRollout(fallthrough.rollout, variations, undefined);
    if (!result.isEmpty()) {
      validation = validation.set('rollout', result);
    }
  }

  if (fallthrough.measuredRolloutConfig) {
    const result = validateMeasuredRolloutConfig(fallthrough.measuredRolloutConfig);

    if (!result.isEmpty()) {
      validation = validation.set('measuredRolloutConfig', result);
    }
  }

  return validation;
}

export function validateVariation(variation: Variation) {
  let errors = Map();

  const value = variation.value;
  if (isNil(value) || Number.isNaN(value)) {
    errors = errors.set('value', required(value));
  }

  return errors;
}
