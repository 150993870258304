import { isEmpty } from '@gonfalon/es6-utils';
// eslint-disable-next-line no-restricted-imports
import { fromJS, is, List, Map, Record } from 'immutable';

import { AccessChecks, allowDecision, CheckAccessFunction, createAccessDecision } from 'utils/accessUtils';
import { Member } from 'utils/accountUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';
import { isPolicyString } from 'utils/policyUtils';
import { isAbsoluteURL, isValidURLPort } from 'utils/urlUtils';
import { isChecked, isJSON, isLength, isNotEmpty, optional, validateRecord } from 'utils/validationUtils';

export type WebhookRequestOptions = { pathOnDone?: string };

type WebhookProps = {
  _access?: AccessChecks;
  _links: ImmutableMap<{
    self: Link;
  }>;
  _id: string;
  name: string;
  url: string;
  sign: boolean;
  secret?: string;
  statements?: string;
  on: boolean;
  tags: List<string>;
};

export class Webhook extends Record<WebhookProps>({
  _access: undefined,
  _links: Map(),
  _id: '',
  name: '',
  url: '',
  sign: false,
  secret: undefined,
  statements: undefined,
  on: true,
  tags: List(),
}) {
  checkAccess({ profile }: { profile: Member }): CheckAccessFunction {
    const access = this.get('_access');

    if (profile.isReader()) {
      return () => createAccessDecision({ isAllowed: false, appliedRoleName: 'Reader' });
    }

    if (profile.isAdmin() || profile.isWriter() || profile.isOwner() || !access || !access.get('denied')) {
      return allowDecision;
    }

    return (action) => {
      const deniedAction = access.get('denied')?.find((v) => v.get('action') === action);
      if (deniedAction) {
        const reason = deniedAction.get('reason');
        const roleName = reason && reason.get('role_name');
        return createAccessDecision({
          isAllowed: false,
          appliedRoleName: roleName,
        });
      }
      return createAccessDecision({ isAllowed: true });
    };
  }

  selfLink() {
    return this.getIn(['_links', 'self', 'href']);
  }

  toForm() {
    return createWebhookForm({
      name: this.name,
      url: this.url,
      statements: this.statements ? JSON.stringify(this.statements, null, 2) : '',
      tags: this.tags,
      sign: this.sign,
      secret: this.secret || '',
      on: this.on,
      // We only want to get privacy policy approval for new webhooks. If we already have a webhook configuration
      // then we can assume the privacy policy has been approved
      privacyPolicyApproved: true,
    });
  }

  mergeForm(form: WebhookForm) {
    return this.withMutations((webhook) =>
      webhook.merge({
        name: form.name,
        url: form.url,
        statements: isEmpty(form.statements) ? undefined : JSON.parse(form.statements),
        tags: form.tags,
        secret: form.secret as string,
        sign: form.sign,
        on: form.on,
      }),
    );
  }
}

export type WebhookFormProps = {
  name: string;
  url: string;
  sign: boolean;
  secret: string | null;
  statements: string;
  on: boolean;
  tags: List<string>;
  privacyPolicyApproved: boolean;
};

// Intermediate model for webhook form with JSON statement value -- makes tracking dirty
// state trivial.
export class WebhookForm extends Record<WebhookFormProps>({
  name: '',
  url: '',
  sign: false,
  secret: '',
  statements: '',
  on: true,
  tags: List(),
  privacyPolicyApproved: false,
}) {
  validate() {
    return validateRecord(
      this,
      isChecked('privacyPolicyApproved'),
      optional(isNotEmpty)('name'),
      isLength(0, 100)('name'),
      isNotEmpty('url'),
      isAbsoluteURL('url'),
      isValidURLPort('url'),
      optional(isJSON)('statements'),
      optional(isPolicyString)('statements'),
    );
  }

  is(other: WebhookForm) {
    try {
      return (
        this.name === other.name &&
        this.url === other.url &&
        this.secret === other.secret &&
        ((isEmpty(this.statements) && isEmpty(other.statements)) ||
          is(fromJS(JSON.parse(this.statements)), fromJS(JSON.parse(other.statements)))) &&
        this.on === other.on &&
        is(this.tags, other.tags)
      );
    } catch (e) {
      // could fail to parse as JSON
      return false;
    }
  }

  toWebhook() {
    const statements = isEmpty(this.statements) ? undefined : JSON.parse(this.statements);
    return createWebhook({
      name: this.name,
      url: this.url,
      sign: this.sign,
      secret: !isEmpty(this.secret) ? this.secret : undefined,
      statements: statements && statements.length ? statements : undefined,
      on: this.on,
      tags: this.tags,
    });
  }
}

export function createWebhook(props = {}) {
  return props instanceof Webhook ? props : new Webhook(props);
}

export function createWebhookForm(props = {}) {
  return props instanceof WebhookForm ? props : new WebhookForm(props);
}
