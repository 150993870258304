// eslint-disable-next-line no-restricted-imports
import { useSelector } from 'react-redux';
import { Collection, List, Map } from 'immutable';
import { combineReducers } from 'redux';

import registry from 'reducers/registry';
import { ExpiringTargetsUrlProps } from 'sources/types/expiringContextTargetsAPI';
import {
  ContextTargetingExpirationInstruction,
  ContextTargetingExpirationProps,
  ExpiringContextTarget,
  ExpiringContextValueUpdateForFlag,
  getMapOfScheduledWorkFlowsForGivenKey,
} from 'utils/expiringContextTargetsUtils';
import { createRequest } from 'utils/requestUtils';

import { GlobalState } from './index';

type HTTPType = {
  POST: 'POST';
  PATCH: 'PATCH';
  DELETE: 'DELETE';
};

type ExpiringContextTargetsAction = {
  action?: HTTPType;
  deleteUpdates?: List<object>;
  envKey: string;
  projKey: string;
  flagKey: string;
  contextKind: string;
  contextKey: string;
  type: string;
  patchScheduledContexts?: List<{ contextKind: string; contextKey: string }>;
  postScheduledContexts?: List<{ contextKind: string; contextKey: string }>;
  patchUpdates?: [];
  postUpdates?: [];
  targetType?: string;
  segmentKey?: string;
  expiringValues: List<ExpiringContextTarget>;
  deletedKeys: List<{ contextKind: string; contextKey: string; flagKey: string; segmentKey: string }>;
  expiringTargetsForContext?: List<ExpiringContextTarget>;
  timestamp: number;
};

export type ExpiringContextTargetsByContextKindAndKey =
  | Map<string, Map<string, List<ExpiringContextTarget>>>
  | Map<string, Collection.Keyed<string, List<ExpiringContextTarget>>>;

export type ExpiringContextTargetsByFlagOrSegmentKey = Map<string, ExpiringContextTargetsByContextKindAndKey>;

export const expiringContextTargetsForFlag = (
  state: ExpiringContextTargetsByFlagOrSegmentKey = Map(),
  action: ExpiringContextTargetsAction,
) => {
  switch (action.type) {
    case 'expiringContextTargets/RECEIVE_EXPIRING_TARGETS_FOR_FLAG':
      const key = `${action.flagKey}/${action.projKey}/${action.envKey}`;
      const workFlowsForKey = getMapOfScheduledWorkFlowsForGivenKey(action.expiringValues, key);
      return state.set(
        key,
        workFlowsForKey
          .groupBy((d) => d.getContextKind())
          .map((v) => v.groupBy((c) => c.getContextKey()).map((x) => x.toList()))
          .toMap(),
      );
    case 'expiringContextTargets/UPDATE_CONTEXT_TARGETING_EXPIRATION_DONE':
      if (!action.flagKey || action.targetType) {
        return state;
      }
      const expirationKey = `${action.flagKey}/${action.projKey}/${action.envKey}`;
      return state.withMutations((st) => {
        if (action.deletedKeys?.size) {
          action.deletedKeys.forEach((expiringValue: { contextKind: string; contextKey: string }) => {
            st.deleteIn([expirationKey, expiringValue.contextKind, expiringValue.contextKey, 0]);
          });
        }
        action.expiringValues.forEach((expiringValue: ExpiringContextTarget) => {
          st.setIn([expirationKey, expiringValue.getContextKind(), expiringValue.getContextKey(), 0], expiringValue);
        });
        return st;
      });
    default:
      return state;
  }
};

export const expiringTargetsForSegment = (
  state: ExpiringContextTargetsByFlagOrSegmentKey = Map(),
  action: ExpiringContextTargetsAction,
) => {
  if (action.type === 'expiringContextTargets/RECEIVE_EXPIRING_TARGETS_FOR_SEGMENT') {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return state.set(
      action.segmentKey!,
      action
        .expiringTargetsForContext!.groupBy((d) => d.getContextKind())
        .map((v) => v.groupBy((c) => c.getContextKey()).map((x) => x.toList()))
        .toMap(),
    ); /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }

  if (action.type === 'expiringContextTargets/UPDATE_CONTEXT_TARGETING_EXPIRATION_DONE') {
    if (!action.segmentKey) {
      return state;
    }
    return state.withMutations((st) => {
      if (action.deletedKeys?.size) {
        action.deletedKeys.forEach((expiringValue: { segmentKey: string; contextKey: string; contextKind: string }) => {
          st.deleteIn([expiringValue.segmentKey, expiringValue.contextKind, expiringValue.contextKey]);
        });
      }
      action.expiringValues.forEach((expiringValue: ExpiringContextTarget) => {
        st.setIn(
          [expiringValue.getResourceKindKey(), expiringValue.getContextKind(), expiringValue.getContextKey(), 0],
          expiringValue,
        );
      });
      return st;
    });
  }

  return state;
};

const handleUndoingTargetExpiration = ({
  key,
  contextKind,
  contextKey,
  state,
}: {
  key: string;
  contextKind: string;
  contextKey: string;
  state: $TSFixMe;
}) => {
  const isOnlyOneChangeEntry = state.get(key)?.size === 1 && state.getIn([key, contextKind])?.size === 1;

  if (!isOnlyOneChangeEntry) {
    if ((state.getIn([key, contextKind])?.size ?? 0) > 1) {
      return state.deleteIn([key, contextKind, contextKey]);
    }

    return state.deleteIn([key, contextKind]);
  }

  return state.delete(key);
};

export type ContextTargetingExpirationUpdates = Map<
  string,
  Map<string, Map<string, ExpiringContextValueUpdateForFlag>>
>;
export const contextExpirationUpdatesInitialState: ContextTargetingExpirationUpdates = Map();

/*
State formatted as:
{
  flagKey | segmentKey: {
    contextKind: {
      contextKey: {
        instruction: {...}
        updatedExpirationDate: 456
        updatedExpirationVariationId: "123"
      }
    }
  }
}
*/
export const contextTargetingExpirationUpdates = (
  state = contextExpirationUpdatesInitialState,
  action: {
    type: string;
    contextKind: string;
    contextKey: string;
    flagKey: string;
    segmentKey?: string;
    updatedExpirationDate?: number | null;
    variationId: string | null;
    instruction?: ContextTargetingExpirationInstruction;
  },
) => {
  if (action.type === 'expiringContextTargets/CHANGE_CONTEXT_TARGETING_EXPIRATION') {
    if (action.segmentKey) {
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      return state.setIn([action.segmentKey, action.contextKind, action.contextKey], {
        updatedExpirationDate: action.updatedExpirationDate!,
        variationId: action.variationId!,
        instruction: action.instruction,
      }); /* eslint-enable @typescript-eslint/no-non-null-assertion */
    }
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return state.setIn([action.flagKey, action.contextKind, action.contextKey], {
      updatedExpirationDate: action.updatedExpirationDate!,
      variationId: action.variationId!,
      instruction: action.instruction,
    }); /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }

  if (action.type === 'expiringContextTargets/CHANGE_CONTEXT_TARGETING_EXPIRATION_UNDO') {
    if (action.segmentKey) {
      return handleUndoingTargetExpiration({
        key: action.segmentKey,
        contextKind: action.contextKind,
        contextKey: action.contextKey,
        state,
      });
    }
    return handleUndoingTargetExpiration({
      key: action.flagKey,
      contextKind: action.contextKind,
      contextKey: action.contextKey,
      state,
    });
  }
  if (action.type === 'expiringContextTargets/UPDATE_CONTEXT_TARGETING_EXPIRATION_DONE') {
    return contextExpirationUpdatesInitialState;
  }
  if (action.type === 'expiringContextTargets/RESET_CONTEXT_TARGETING_EXPIRATIONS') {
    return contextExpirationUpdatesInitialState;
  }
  return state;
};

export const expiringTargetsForContextListRequest = (state = createRequest(), action: ExpiringContextTargetsAction) => {
  switch (action.type) {
    case 'expiringContextTargets/FETCH_EXPIRING_TARGETS_FOR_CONTEXT':
      return state.start();
    case 'expiringContextTargets/RECEIVE_EXPIRING_TARGETS_FOR_CONTEXT':
      return state.done(action);
    case 'expiringContextTargets/RECEIVE_EXPIRING_TARGETS_FOR_CONTEXT_FAILED':
      return state.failed(action);
    default:
      return state;
  }
};

export const expiringContextTargetsForFlagListRequest = (
  state = createRequest(),
  action: ContextTargetingExpirationProps,
) => {
  switch (action.type) {
    case 'expiringContextTargets/FETCH_EXPIRING_TARGETS_FOR_FLAG':
      return state.start();
    case 'expiringContextTargets/RECEIVE_EXPIRING_TARGETS_FOR_FLAG':
      return state.done(action);
    case 'expiringContextTargets/RECEIVE_EXPIRING_TARGETS_FOR_FLAG_FAILED':
      return state.failed(action);
    default:
      return state;
  }
};

export const expiringContextTargetsForSegmentListRequest = (
  state = createRequest(),
  action: ContextTargetingExpirationProps,
) => {
  switch (action.type) {
    case 'expiringContextTargets/FETCH_EXPIRING_TARGETS_FOR_SEGMENT':
      return state.start();
    case 'expiringContextTargets/RECEIVE_EXPIRING_TARGETS_FOR_SEGMENT':
      return state.done(action);
    case 'expiringContextTargets/RECEIVE_EXPIRING_TARGETS_FOR_SEGMENT_FAILED':
      return state.failed(action);
    default:
      return state;
  }
};

export const expiringContextTargets = combineReducers({
  expiringContextTargetsForFlagListRequest,
  expiringContextTargetsForFlag,
  contextTargetingExpirationUpdates,
  expiringTargetsForContextListRequest,
  expiringContextTargetsForSegmentListRequest,
  expiringTargetsForSegment,
});

const expiringContextsSelector = (state: GlobalState) => state.expiringContextTargets;

export const expiringContextTargetsForFlagSelector = (state: GlobalState) =>
  expiringContextsSelector(state).expiringContextTargetsForFlag;

export const contextTargetingExpirationUpdatesSelector = (state: GlobalState) =>
  expiringContextsSelector(state).contextTargetingExpirationUpdates;

export function useContextTargetingExpirationUpdates(): ContextTargetingExpirationUpdates {
  return useSelector(contextTargetingExpirationUpdatesSelector);
}

export const contextExpirationUpdatesSelectorByContextAndFlagKey = (state: $TSFixMe, props: ExpiringTargetsUrlProps) =>
  contextTargetingExpirationUpdatesSelector(state).getIn([props.flagKey, props.contextKind, props.contextKey]);

export const contextExpirationUpdatesSelectorByContextAndSegmentKey = (
  state: $TSFixMe,
  props: ExpiringTargetsUrlProps,
) => contextTargetingExpirationUpdatesSelector(state).getIn([props.segmentKey, props.contextKind, props.contextKey]);

export const expiringContextTargetsSelectorByFlagAndEnvKey = (state: GlobalState, props: ExpiringTargetsUrlProps) => {
  const key = `${props.flagKey}/${props.projKey}/${props.envKey}`;
  return expiringContextTargetsForFlagSelector(state).get(key);
};

export const expiringContextTargetsForFlagRequestSelector = (state: GlobalState) =>
  expiringContextsSelector(state).expiringContextTargetsForFlagListRequest;

export const expiringContextTargetsForSegmentListRequestSelector = (state: $TSFixMe) =>
  expiringContextsSelector(state).expiringContextTargetsForSegmentListRequest;

export const scheduledRemovedContextTargetsByKeySelector = (state: $TSFixMe, props: ExpiringTargetsUrlProps) => {
  const entities = expiringContextTargetsSelectorByFlagAndEnvKey(state, props);
  if (entities) {
    return entities
      .get(props.contextKind || '')
      ?.get(props.contextKey || '')
      ?.first();
  }
};

export const expiringTargetsForSegmentSelector = (state: $TSFixMe, segmentKey: string) =>
  expiringContextsSelector(state).expiringTargetsForSegment.get(segmentKey);

export const expiringTargetsForSegmentByContextKeySelector = (
  state: $TSFixMe,
  props: { segmentKey: string; contextKey: string; contextKind: string },
) => expiringTargetsForSegmentSelector(state, props.segmentKey)?.get(props.contextKind)?.get(props.contextKey)?.first();

registry.addReducers({
  expiringContextTargets,
});
