import actionTypes from 'actionTypes/sso';
import { GetState, GlobalDispatch, GlobalState } from 'reducers';
import { samlConfigSelector } from 'reducers/samlConfig';
// eslint-disable-next-line import/no-namespace
import * as AccountAPI from 'sources/AccountAPI';
import { SamlConfig } from 'utils/accountUtils';
import { ImmutableServerError } from 'utils/httpUtils';

function setIsManagingTeams(shouldTurnOnTeamSync?: boolean) {
  return async (dispatch: GlobalDispatch) => {
    if (shouldTurnOnTeamSync) {
      return AccountAPI.turnOnTeamSync()
        .then(() => dispatch({ type: actionTypes.SET_IS_MANAGING_TEAMS_DONE, isManagingTeams: true }))
        .catch(() => dispatch({ type: actionTypes.UPDATE_SAML_CONFIG_FAILED }));
    } else {
      return AccountAPI.turnOffTeamSync()
        .then(() => dispatch({ type: actionTypes.SET_IS_MANAGING_TEAMS_DONE, isManagingTeams: false }))
        .catch(() => dispatch({ type: actionTypes.UPDATE_SAML_CONFIG_FAILED }));
    }
  };
}

function fetchSamlAppDetails() {
  return async (dispatch: GlobalDispatch) => {
    dispatch({ type: actionTypes.GET_SAML_APP_DETAILS });
    return AccountAPI.getSamlAppDetails().then(
      (config) => dispatch({ type: actionTypes.GET_SAML_APP_DETAILS_DONE, config }),
      (error) => dispatch({ type: actionTypes.GET_SAML_APP_DETAILS_FAILED, error }),
    );
  };
}

function fetchSamlConfig() {
  return async (dispatch: GlobalDispatch) => {
    dispatch({ type: actionTypes.GET_SAML_CONFIG });
    return AccountAPI.getSamlConfig().then(
      (config) => dispatch({ type: actionTypes.GET_SAML_CONFIG_DONE, config }),
      (error) => dispatch({ type: actionTypes.GET_SAML_CONFIG_FAILED, error }),
    );
  };
}

function shouldFetchSamlConfig(state: GlobalState) {
  const saml = samlConfigSelector(state);
  const req = saml.request;
  return req.shouldFetch();
}

function fetchSamlConfigIfNeeded() {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchSamlConfig(getState())) {
      return dispatch(fetchSamlConfig());
    }
  };
}

function saveSamlConfig(original: SamlConfig, modified: SamlConfig) {
  let api: Promise<SamlConfig>;
  let types: string[];

  const isNew = original.isEmpty();
  if (!isNew) {
    api = AccountAPI.updateSamlConfig(original, modified);
    types = [
      actionTypes.UPDATE_SAML_CONFIG,
      actionTypes.UPDATE_SAML_CONFIG_DONE,
      actionTypes.UPDATE_SAML_CONFIG_FAILED,
    ];
  } else {
    api = AccountAPI.createSamlConfig(modified);
    types = [
      actionTypes.CREATE_SAML_CONFIG,
      actionTypes.CREATE_SAML_CONFIG_DONE,
      actionTypes.CREATE_SAML_CONFIG_FAILED,
    ];
  }

  return async (dispatch: GlobalDispatch) => {
    dispatch({ type: types[0] });
    return new Promise((resolve, reject) => {
      api.then(
        (res: SamlConfig) => {
          const config = isNew ? res : modified;
          dispatch({ type: types[1], config });
          resolve(config);
        },
        (error: ImmutableServerError) => {
          dispatch({ type: types[2], modified, error });
          reject(error);
        },
      );
    });
  };
}

function deleteSamlConfig() {
  return async (dispatch: GlobalDispatch) => {
    dispatch({ type: actionTypes.DELETE_SAML_CONFIG });
    return AccountAPI.deleteSamlConfig().then(
      () => dispatch({ type: actionTypes.DELETE_SAML_CONFIG_DONE }),
      (error) => dispatch({ type: actionTypes.DELETE_SAML_CONFIG_FAILED, error }),
    );
  };
}

function testSamlConfig(config: SamlConfig) {
  return () => {
    const redirectUrl = config.getRedirectUrlWithRelayState('/settings/security');
    window.location.assign(redirectUrl);
  };
}

export {
  setIsManagingTeams,
  fetchSamlAppDetails,
  fetchSamlConfigIfNeeded as fetchSamlConfig,
  saveSamlConfig,
  deleteSamlConfig,
  testSamlConfig,
};
