import { useContext } from 'react';
import classNames from 'clsx';

import { AnyFormRecord, FormContext, FormState } from 'utils/formUtils';

import './styles/Form.css';

export type FormGroupProps = {
  name?: string | string[];
  formState?: FormState<AnyFormRecord>;
  ignoreValidation?: boolean;
  className?: string;
  onBlur?: () => void;
  testId?: string;
  children: React.ReactNode;
};

export function FormGroup(props: FormGroupProps) {
  const { className, name, ignoreValidation, children, formState: formStateProp, testId, ...other } = props;
  const formState = useContext(FormContext)?.formState || formStateProp;
  const classes = classNames('Form-group', className, {
    'is-invalid': !ignoreValidation && name && formState && formState.needsErrorFeedback(name),
  });

  return (
    <fieldset className={classes} data-test-id={testId} {...other}>
      {children}
    </fieldset>
  );
}
