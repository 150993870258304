import cx from 'clsx';

import './styles.css';

export enum BorderStyles {
  NONE = 'none',
  SOLID = 'solid',
}

export type DividerProps = {
  className?: string;
  text?: string;
  borderStyle?: BorderStyles;
};

const Divider = ({ className, text, borderStyle = BorderStyles.SOLID }: DividerProps) => (
  <hr
    data-test-id="divider"
    className={cx('Divider', borderStyle && `Divider--border-${borderStyle}`, className)}
    data-content={text}
  />
);

/* eslint-disable import/no-default-export */
export default Divider;
