import { omit } from '@gonfalon/es6-utils';
import { add, formatDuration, intervalToDuration, millisecondsToSeconds } from 'date-fns';

import { addOneToHighestUnit, unitsToIncludeInTimeLeftDisplay } from './unitsToIncludeInTimeDisplay';
import { MeasuredRollout, unitsToIncludeInTimeDisplay } from '.';

export function getMeasuredRolloutTimings(measuredRollout: MeasuredRollout, endedAt?: number) {
  const startedAt = measuredRollout.state.monitoringStartedAt;
  const stoppedAt = endedAt ?? measuredRollout.state.monitoringEndedAt;

  if (!startedAt) {
    return {
      anticipatedRunTime: {},
      stoppedAt: undefined,
      elapsedTime: {},
      remainingTimeDisplay: '',
      remainingTimeFormatted: '',
      anticipatedRunTimeDisplay: '',
      elapsedTimeDisplay: '',
    };
  }

  const anticipatedStopTime = add(startedAt, {
    seconds: millisecondsToSeconds(
      measuredRollout.design.stages[measuredRollout.state.latestStageIndex].maxDurationMillis,
    ),
  });
  const anticipatedRunTime = omit(intervalToDuration({ start: startedAt, end: anticipatedStopTime }), ['seconds']);
  const anticipatedRunTimeDisplay = formatDuration(anticipatedRunTime, {
    delimiter: ', ',
    format: unitsToIncludeInTimeDisplay(anticipatedRunTime),
  });
  const elapsedTime = omit(
    intervalToDuration({
      start: startedAt,
      end: stoppedAt ?? new Date(),
    }),
  );

  const now = new Date();

  const remainingTime = omit(
    intervalToDuration({
      start: now,
      end: anticipatedStopTime > now ? anticipatedStopTime : now,
    }),
    ['seconds'],
  );

  const elapsedTimeDisplay = formatDuration(elapsedTime, {
    delimiter: ', ',
    format: unitsToIncludeInTimeDisplay(elapsedTime),
  });

  const adjustedRemainingTime = now < anticipatedStopTime ? addOneToHighestUnit(remainingTime) : {};

  const remainingTimeFormatted = formatDuration(adjustedRemainingTime, {
    delimiter: ', ',
    format: unitsToIncludeInTimeLeftDisplay(adjustedRemainingTime),
  });
  const shouldDisplayRemainingTime = remainingTimeFormatted !== '' && now < anticipatedStopTime;
  const remainingTimeDisplay = shouldDisplayRemainingTime ? `< ${remainingTimeFormatted} left` : '';
  return {
    anticipatedRunTime,
    anticipatedRunTimeDisplay,
    stoppedAt,
    elapsedTime,
    remainingTimeDisplay,
    remainingTimeFormatted: shouldDisplayRemainingTime ? remainingTimeFormatted : '',
    elapsedTimeDisplay,
  };
}
