import { pluralize } from '@gonfalon/strings';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';

import styles from './TeamOption.module.css';

export type TeamOptionProps = {
  isInMenu?: boolean;
  teamName: string;
  memberCount: number;
};

/* eslint-disable import/no-default-export */
export default function TeamOption(props: TeamOptionProps) {
  return props.isInMenu ? (
    <span className="u-flex u-flex-row u-flex-middle u-fs-sm ">
      <Icon name="group" className={styles.Icon} size="medium" />
      <div className="u-o-hidden u-to-ellipsis">
        <div className={cx({ [styles.Option]: props.memberCount > 0 })}>
          {props.teamName} ({props.memberCount} {pluralize('person', props.memberCount)})
        </div>
      </div>
    </span>
  ) : (
    <div className="u-flex u-flex-middle u-gap-1 u-fs-sm u-mw-100">
      <Icon name="group" size="small" />
      <div className="u-o-hidden u-to-ellipsis">
        {props.teamName} ({props.memberCount} {pluralize('person', props.memberCount)})
      </div>
    </div>
  );
}
