import { startOfDay, subDays, subHours } from 'date-fns';

import { type EvaluationDate } from './types';

export function evaluatedDateRangeToDate(evaluated: EvaluationDate, referenceTimestamp: number) {
  const today = startOfDay(referenceTimestamp);

  switch (evaluated) {
    case 'last-60-minutes':
      return subHours(referenceTimestamp, 1);
    case 'last-24-hours':
      return subHours(referenceTimestamp, 24);
    case 'last-7-days':
      return subDays(today, 7);
    case 'last-14-days':
      return subDays(today, 14);
    case 'last-30-days':
      return subDays(today, 30);
    case 'last-60-days':
      return subDays(today, 60);
    default:
      return undefined;
  }
}
