import type { FilterOption } from 'launchpad';

import { VariationValue } from 'utils/flagUtils';
import { EventKind } from 'utils/liveEventUtils';

export type OptionType = FilterOption;

export type FilterProps = {
  filters: FilterType;
};

export type FilterType = {
  mauTo?: string;
  mauFrom?: string;
  category?: string;
  group?: string;
  sdk?: string;
  version?: string;
  environment?: string;
  project?: string;
  mauProj?: string;
  mauEnv?: string;
  mauSdk?: string;
  mauVersion?: string;
  groupby?: string;
  sdktype?: string;
  tz?: string;
  to?: string;
  from?: string;
  [propName: string]: string | undefined;
};

export type DataPoint = Readonly<{
  time: number;
  [key: number]: number;
}>;

export type SeriesOption = 'hour' | 'day' | 'month';

export type Series = readonly DataPoint[];

export type UsageMetadataType = Readonly<{
  projectKey?: string;
  environmentKey?: string;
  source?: string;
  sdk?: string;
  version?: string;
  category?: string;
  kind?: EventKind;
  sdkType: 'server' | 'client';
  key?: string;
}>;

export type MetadataProps = {
  key: VariationValue;
};

export type UsageMetadata = readonly UsageMetadataType[];

export enum TypeOfUsage {
  SERVER = 'server',
  CLIENT = 'client',
  EXPERIMENTS = 'experiments',
  DATA_EXPORT = 'dataExport',
}

export type UsageType = TypeOfUsage;
