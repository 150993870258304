import { ReactNode } from 'react';
import { Dialog, Heading } from 'react-aria-components';
import { IconButton } from '@launchpad-ui/components';
import clsx from 'clsx';

import { SearchField } from '../Form';
import { ListBox } from '../ListBox';
import { ListBoxProps } from '../ListBox/ListBox';

import styles from './ListBoxDialog.module.css';

export type ListBoxDialogProps<T extends object> = Omit<ListBoxProps<T>, 'style'> & {
  title?: string;
  isBusy?: boolean;
  onFilterChange: (filterText: string) => void;
  afterSearch?: ReactNode;
};

export function ListBoxDialog<T extends object>({
  title,
  isBusy,
  onFilterChange,
  afterSearch,
  ...props
}: ListBoxDialogProps<T>) {
  return (
    <Dialog {...props} className={clsx(props.className, styles.dialog)}>
      {({ close }) => (
        <>
          {title ? (
            <div className={styles.header}>
              <Heading level={3} slot="title">
                {title}
              </Heading>
              <IconButton aria-label="Close dialog" variant="minimal" icon="cancel" size="small" onPress={close} />
            </div>
          ) : null}
          <SearchField
            className={styles.search}
            autoFocus
            aria-label="Filter items"
            isBusy={isBusy}
            onChange={onFilterChange}
          />
          {afterSearch ? (
            <div slot="afterSearch" className={styles.afterSearch}>
              {afterSearch}
            </div>
          ) : null}
          <ListBox
            {...props}
            className={styles.list}
            renderEmptyState={() => (isBusy ? 'Searching…' : 'No items found')}
          />
        </>
      )}
    </Dialog>
  );
}
