import { Environment, EnvironmentMarker as EnvironmentMarker_ } from '@gonfalon/environments';
import classNames from 'clsx';
import { Environment as EnvironmentFromWorker } from 'command-bar/types';
import { Chip, Tooltip } from 'launchpad';

import { createColor } from 'utils/colorUtils';
import { Environment as EnvironmentRep } from 'utils/environmentUtils';

import 'stylesheets/components/EnvironmentMarker.css';

export type EnvironmentMarkerPropTypes = {
  env: EnvironmentRep | EnvironmentFromWorker | Environment;
  href?: string;
  className?: string;
  environmentMarkerClassName?: string;
  environmentNameClassName?: string;
  omitName?: boolean;
  openInNewTab?: boolean;
  showCriticalEnvironmentMarker?: boolean;
  onClickEnvironmentLink?(): void;
};

function envIsEnvironmentType(env: EnvironmentRep | EnvironmentFromWorker | Environment): env is EnvironmentRep {
  return env instanceof EnvironmentRep;
}

export function getStrokeFromColor(color: string) {
  return createColor(color).luminosity(30).css();
}

export const EnvironmentMarker = ({
  env,
  className,
  environmentMarkerClassName,
  environmentNameClassName,
  href,
  omitName = false,
  openInNewTab = false,
  showCriticalEnvironmentMarker = false,
  onClickEnvironmentLink,
}: EnvironmentMarkerPropTypes) => {
  const classes = classNames('u-ws-nowrap', className);

  const fill = envIsEnvironmentType(env) ? env.color.css() : `#${env.color}`;

  const environmentNameElement = <span className={environmentNameClassName}>{env.name}</span>;

  const renderEnvironmentName = () => {
    if (omitName) {
      return null;
    }

    if (!!href) {
      return (
        <a
          className="u-mr-m u-c-pointer"
          href={href}
          onClick={onClickEnvironmentLink}
          {...(openInNewTab ? { target: '_blank', rel: 'noreferrer' } : {})}
        >
          {environmentNameElement}
        </a>
      );
    }

    return environmentNameElement;
  };

  return (
    <>
      <span className={classes}>
        <EnvironmentMarker_ color={fill} className={classNames('EnvironmentMarker', environmentMarkerClassName)} />
      </span>
      {renderEnvironmentName()}

      {showCriticalEnvironmentMarker && env.critical && (
        <Tooltip content="Critical environments affect your customers or the health of your systems and may have added safeguards in place.">
          <Chip size="small" className="u-ml-m">
            Critical
          </Chip>
        </Tooltip>
      )}
    </>
  );
};
