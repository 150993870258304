// transformed by jscodeshift
import { useContext } from 'react';
import cx from 'clsx';

import { createFieldErrorId, FieldPath, FormContext, FormState } from 'utils/formUtils';

import './styles/Form.css';

export type FieldErrorProps = {
  name: FieldPath;
  formState?: FormState;
  errorMessage?: string;
  className?: string;
};

export function FieldError(props: FieldErrorProps) {
  const { name, className, errorMessage } = props;
  const formState = useContext(FormContext)?.formState || props.formState;
  const err = errorMessage || (formState?.needsErrorFeedback(name) && formState.getError(name));
  const errorContent = err ? <span>Error: {err}</span> : null;

  // aria-live works by announcing the changes within. It should not work if the aria-live region itself is added/removed from the DOM.
  // Mac voiceover allows ^ and announces changes properly. NVDA, a popular windows screen reader, does not announce these changes properly.
  // In order to get aria-live to announce in NVDA (windows), the container must be rendered and the content inside it can change.
  // FYI: NVDA + chrome has a bug where changes are read twice, as noted here https://github.com/nvaccess/nvda/issues/7996
  return (
    <div className={cx('Form-fieldError', className)} aria-live="polite" id={createFieldErrorId(name)}>
      {errorContent}
    </div>
  );
}
