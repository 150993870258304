import classNames from 'clsx';

import './styles.css';

type ModuleContentProps = {
  isIncomplete?: boolean;
  isInvalid?: boolean;
  isSnug?: boolean;
  isSeamless?: boolean;
  isDraft?: boolean;
  addSpaceBelow?: boolean;
  isCondensedVertically?: boolean;
  className?: string;
  children?: React.ReactNode;
  tabIndex?: number;
};

export function ModuleContent({
  isIncomplete = false,
  isInvalid = false,
  isSnug = false,
  isSeamless = false,
  isCondensedVertically = false,
  isDraft = false,
  addSpaceBelow = false,
  className,
  children,
  ...props
}: ModuleContentProps) {
  const classes = classNames('Module-content', className, {
    'Module-content--incomplete': isIncomplete,
    'Module-content--invalid': isInvalid,
    'Module-content--snug': isSnug,
    'Module-content--seamless': isSeamless,
    'Module-content--condensedY': isCondensedVertically,
    'Module-content--draft': isDraft,
    'Module-content--addSpaceBelow': addSpaceBelow,
  });

  return (
    <div {...props} className={classes}>
      {children}
    </div>
  );
}
