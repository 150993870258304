import { lazy, Suspense } from 'react';
import { ProgressBar } from '@launchpad-ui/components';

import { TimeSeriesComponentProps } from './TimeSeriesComponent';

const TimeSeriesComponent = lazy(
  async () => import(/* webpackChunkName: "TimeSeriesComponent" */ './TimeSeriesComponent'),
);

export const TimeSeries = (props: TimeSeriesComponentProps) => (
  <Suspense fallback={<ProgressBar aria-label="Loading…" isIndeterminate />}>
    <TimeSeriesComponent {...props} />
  </Suspense>
);
