import { ExperimentRollout } from 'utils/flagUtils';

import {
  MeasuredRolloutSemanticInstruction,
  MeasuredRolloutSemanticInstructionV2,
  SemanticInstructionProgressiveRolloutConfiguration,
} from '../shared/types';

export enum OnOffInstructionKind {
  TURN_FLAG_ON = 'turnFlagOn',
  TURN_FLAG_OFF = 'turnFlagOff',
  UPDATE_FALLTHROUGH_VARIATION_OR_ROLLOUT = 'updateFallthroughVariationOrRollout',
  UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT = 'updateFallthroughWithMeasuredRollout',
  UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT_V2 = 'updateFallthroughWithMeasuredRolloutV2',
  UPDATE_OFF_VARIATION = 'updateOffVariation',
  STOP_MEASURED_ROLLOUT_ON_FALLTHROUGH = 'stopMeasuredRolloutOnFallthrough',
}

export type TurnFlagOnSemanticInstruction = {
  kind: OnOffInstructionKind.TURN_FLAG_ON;
};

export type TurnFlagOffSemanticInstruction = {
  kind: OnOffInstructionKind.TURN_FLAG_OFF;
};

export type OffVariationSemanticInstruction = {
  kind: OnOffInstructionKind.UPDATE_OFF_VARIATION;
  variationId: string | null;
};

export type UpdateFallthroughVariationOrRolloutSemanticInstruction = {
  kind: OnOffInstructionKind.UPDATE_FALLTHROUGH_VARIATION_OR_ROLLOUT;
  contextKind?: string;
  rolloutWeights?: { [key: string]: number };
  rolloutBucketBy?: string;
  rolloutContextKind?: string;
  variationId?: string;
  experimentAllocation?: ExperimentRollout;
  progressiveRolloutConfiguration?: never;
};

export type StopMeasuredRolloutOnFallthrough = {
  kind: OnOffInstructionKind.STOP_MEASURED_ROLLOUT_ON_FALLTHROUGH;
  finalVariationId: string;
  comment: string;
};

export type UpdateFallthroughWithMeasuredRolloutSemanticInstruction = MeasuredRolloutSemanticInstruction & {
  kind: OnOffInstructionKind.UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT;
};

export type UpdateFallthroughWithMeasuredRolloutV2SemanticInstruction = MeasuredRolloutSemanticInstructionV2 & {
  kind: OnOffInstructionKind.UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT_V2;
};

export type UpdateFallthroughWithProgressiveRolloutSemanticInstruction = {
  kind: OnOffInstructionKind.UPDATE_FALLTHROUGH_VARIATION_OR_ROLLOUT;
  progressiveRolloutConfiguration: SemanticInstructionProgressiveRolloutConfiguration;
  rolloutContextKind: string;
  contextKind?: never;
  rolloutWeights?: never;
  rolloutBucketBy?: never;
  variationId?: never;
  experimentAllocation?: never;
};
