import { memo, useMemo } from 'react';
import { Snippet } from '@gonfalon/launchpad-experimental';
import { isJSONValue } from 'ia-poc/services/data-model/flags/type-utils';

import { VariationType } from 'utils/flagUtils';

const SnippetMemo = memo(Snippet);

export type VariationValueProps = { value: string; variationType?: VariationType };

export function VariationValue({ value, variationType }: VariationValueProps) {
  const { jsonString, isJSON } = useMemo(() => {
    let parsedJSON;
    let parseFailed = false;

    if (variationType === VariationType.JSON) {
      try {
        parsedJSON = JSON.parse(value);
      } catch (error) {
        parseFailed = true;
      }
    }

    return {
      jsonString: parsedJSON ? JSON.stringify(parsedJSON, null, 2) : '',
      isJSON: !parseFailed && isJSONValue(parsedJSON),
    } as const;
  }, [value, variationType]);

  return variationType === VariationType.JSON && isJSON ? (
    <SnippetMemo className="JSONSnippet" lang="json" withCopyButton>
      {jsonString}
    </SnippetMemo>
  ) : (
    // if the variation has a "string" type, wrap the value in quotes
    // we then also replace all spaces with the Unicode space character,
    // so that we accurately render the real whitespace contained in the string
    <code style={{ overflowWrap: 'anywhere' }}>
      {variationType === VariationType.STRING ? `"${value.replace(/ /g, '\u00a0')}"` : value}
    </code>
  );
}
