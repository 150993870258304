import { capitalize } from '@gonfalon/strings';

import Variation from 'components/Variation';
import { colorVariation, Flag } from 'utils/flagUtils';

import { VARIATION_NOT_FOUND } from './instructionListUtils';

export type VariationInstructionEntryProps = { variationId: string; flag: Flag };

export const VariationInstructionEntry = ({ variationId, flag }: VariationInstructionEntryProps) => {
  const variationIdx = flag.getVariationIndexFromId(variationId);
  return variationIdx > -1 ? (
    // We found the variation by id above so we can safely assert it exists here.
    <Variation
      color={colorVariation(variationIdx)}
      value={
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        flag
          .getVariations()
          .get(variationIdx)!
          .getDisplay() /* eslint-enable @typescript-eslint/no-non-null-assertion */
      }
    />
  ) : (
    <span>{capitalize(VARIATION_NOT_FOUND)}</span>
  );
};
