import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { noop } from '@gonfalon/es6-utils';

import { ReleaseStrategy } from './types';

interface ReleaseStrategyContextValue {
  releaseStrategy?: ReleaseStrategy;
  setReleaseStrategy: React.Dispatch<React.SetStateAction<ReleaseStrategy | undefined>>;
}

const ReleaseStrategyContext = createContext<ReleaseStrategyContextValue>({
  releaseStrategy: undefined,
  setReleaseStrategy: noop,
});

interface ReleaseStrategyProviderProps {
  children: ReactNode;
}

export function LegacyReleaseStrategyProvider({ children }: ReleaseStrategyProviderProps) {
  const [releaseStrategy, setReleaseStrategy] = useState<ReleaseStrategy | undefined>(undefined);
  const releaseStrategyContextValue = useMemo(() => ({ releaseStrategy, setReleaseStrategy }), [releaseStrategy]);

  return (
    <ReleaseStrategyContext.Provider value={releaseStrategyContextValue}>{children}</ReleaseStrategyContext.Provider>
  );
}

export function useLegacyReleaseStrategy() {
  return useContext(ReleaseStrategyContext);
}
