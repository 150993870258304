import { WaitDurationUnit } from 'utils/scheduledChangesUtils';

export enum CustomWorkflowType {
  PROGRESSIVE_ROLLOUT = 'PROGRESSIVE_ROLLOUT',
  MAINTENANCE_WINDOW = 'MAINTENANCE_WINDOW',
  CUSTOM_WORKFLOW = 'CUSTOM_WORKFLOW',
  TEMPLATE = 'TEMPLATE',
}

export type CustomWorkflowProgressiveRolloutIncrement = 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100;
export type CustomWorkflowProgressiveRolloutPeriod = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export type CustomWorkflowProgressiveRolloutConfig = Readonly<{
  startDate: number;
  variationId: string;
  increment: CustomWorkflowProgressiveRolloutIncrement;
  period: CustomWorkflowProgressiveRolloutPeriod;
  bucketBy?: string;
  contextKind?: string;
}>;

export type CustomWorkflowMaintenanceWindowConfig = Readonly<{
  targeting: 'on' | 'off';
  startDate: number;
  waitDuration: number;
  waitDurationUnit: WaitDurationUnit;
}>;

export type CustomWorkflowConfig = Readonly<
  | {
      customWorkflowType: CustomWorkflowType.PROGRESSIVE_ROLLOUT;
      config: CustomWorkflowProgressiveRolloutConfig;
    }
  | { customWorkflowType: CustomWorkflowType.MAINTENANCE_WINDOW; config: CustomWorkflowMaintenanceWindowConfig }
>;
