import { ReactNode, Suspense } from 'react';
import { flagListDisplayPreferences } from '@gonfalon/dogfood-flags';
import { QuietErrorBoundary } from '@gonfalon/error-boundaries';
import { useSlots } from '@gonfalon/react';
import clsx from 'clsx';
import invariant from 'tiny-invariant';

import { Action } from '../internal/Action';
import { ChangeHistoryTrigger } from '../internal/ChangeHistoryTrigger';
import { DisplayPreferences } from '../internal/DisplayPreferences';
import { EnvironmentController } from '../internal/EnvironmentController';
import { Filters } from '../internal/Filters';
import { LayoutHeader, LayoutHeaderActions } from '../internal/LayoutHeader';
import { Ordering } from '../internal/Ordering';
import { ShortcutsTrigger } from '../internal/ShortcutsTrigger';
import { Views } from '../internal/Views';

import styles from './ProjectCollectionLayout.module.css';

export function ProjectCollectionLayout({ children }: { children?: ReactNode }) {
  const [{ context, content, footer }] = useSlots(children, {
    content: ProjectCollectionContent,
    footer: ProjectCollectionFooter,
    context: ProjectCollectionContext,
  });

  invariant(content, 'ProjectCollectionLayout must include a ProjectCollectionContent');

  return (
    <div className={styles.collection}>
      <LayoutHeader>
        <LayoutHeaderActions>
          <Filters />
          <Ordering />
          <Views />
          {flagListDisplayPreferences() ? <DisplayPreferences /> : null}
          <Action />
          <Suspense fallback={null}>
            <QuietErrorBoundary>
              <ShortcutsTrigger />
            </QuietErrorBoundary>
          </Suspense>
          <ChangeHistoryTrigger />
        </LayoutHeaderActions>
      </LayoutHeader>
      <div className={styles.scroller}>
        {context}
        <div className={styles.main}>{content}</div>
      </div>
      {footer}
    </div>
  );
}

export function ProjectCollectionContext({ children, wide = false }: { children: ReactNode; wide?: boolean }) {
  const [{ freeform, controls, environmentSelector }] = useSlots(children, {
    freeform: ProjectCollectionFreeform,
    controls: ProjectCollectionControls,
    environmentSelector: EnvironmentController,
  });

  if (freeform) {
    return <div className={styles.context}>{freeform}</div>;
  }

  return (
    <div
      className={clsx(styles.context, {
        [styles.contextWide]: wide,
      })}
    >
      {controls}
      {environmentSelector && <div>{environmentSelector}</div>}
    </div>
  );
}

export function ProjectCollectionFreeform({ children }: { children: ReactNode }) {
  return <div className={styles.freeform}>{children}</div>;
}

export function ProjectCollectionContent({ children }: { children: ReactNode }) {
  return <div className={styles.content}>{children}</div>;
}

export function ProjectCollectionFooter({ children }: { children: ReactNode }) {
  return <div className={styles.footer}>{children}</div>;
}

export function ProjectCollectionControls({ children }: { children: ReactNode }) {
  return <div className={styles.controls}>{children}</div>;
}
