import { ContextKind } from 'components/Contexts/types';
import { createEnvironment, Environment } from 'utils/environmentUtils';
import http, { jsonToImmutableError, middleware as mw } from 'utils/httpUtils';
import { createJsonPatch } from 'utils/patchUtils';
import {
  createNormalizedProjectEntity,
  EnvironmentsFilters,
  NewProject,
  NormalizedProjectCollectionResponse,
  NormalizedProjectResponse,
  processPaginatedProjectCollectionRep,
  processProjectCollectionRep,
  processProjectEnvironmentsCollection,
  Project,
  ProjectFilters,
} from 'utils/projectUtils';

export async function getAllProjectsV3(projectFilters?: ProjectFilters): Promise<NormalizedProjectCollectionResponse> {
  const headers = { headers: { 'LD-API-Version': '20220603' } };
  const url = projectFilters?.toBackendQueryString()
    ? `/api/v2/projects${projectFilters?.toBackendQueryString()}&expand=environments`
    : '/api/v2/projects?expand=environments';
  return http.get(url, headers).then(mw.json).then(processPaginatedProjectCollectionRep).catch(jsonToImmutableError);
}

export async function getAllProjects(): Promise<NormalizedProjectCollectionResponse> {
  const headers = { headers: { 'LD-API-Version': '20210729' } };

  return http
    .get('/api/v2/projects', headers)
    .then(mw.json)
    .then(processProjectCollectionRep)
    .catch(jsonToImmutableError);
}

export async function fetchProjectEnvironments(projectKey: string, filters: EnvironmentsFilters) {
  return http
    .get(`/api/v2/projects/${projectKey}/environments${filters.toBackendQueryString()}`)
    .then(mw.json)
    .then(processProjectEnvironmentsCollection)
    .catch(jsonToImmutableError);
}

export async function fetchProjectEnvironment(projectKey: string, environmentKey: string): Promise<Environment> {
  return http
    .get(`/api/v2/projects/${projectKey}/environments/${environmentKey}`)
    .then(mw.json)
    .then(createEnvironment)
    .catch(jsonToImmutableError);
}

export async function createProject(project: NewProject): Promise<NormalizedProjectResponse> {
  return http
    .post('/api/v2/projects', { body: project })
    .then(mw.json)
    .then(createNormalizedProjectEntity)
    .catch(jsonToImmutableError);
}

export async function updateProject(oldProject: Project, newProject: Project): Promise<NormalizedProjectResponse> {
  const patch = createJsonPatch(oldProject, newProject, { propFilter: false });
  return http
    .patch(oldProject.selfLink(), { body: patch })
    .then(mw.json)
    .then(createNormalizedProjectEntity)
    .catch(jsonToImmutableError);
}

export async function getProject(projectKey: string) {
  return http
    .get(`/api/v2/projects/${projectKey}`, { headers: { 'LD-API-Version': '20210729' } })
    .then(async (res) => res.json().then(createNormalizedProjectEntity))
    .catch(jsonToImmutableError);
}

export async function deleteProject(project: Project): Promise<void> {
  return http.delete(project.selfLink()).catch(jsonToImmutableError);
}

export const getContextKindsForProject = async (projectKey: string): Promise<ContextKind[]> =>
  http
    .get(`/api/v2/projects/${projectKey}/context-kinds`, {
      beta: true,
    })
    .then(async (res) => res.json())
    .catch(jsonToImmutableError);
