import { isWorkflowBuilderEnabled, isWorkflowBuilderUpsellEnabled } from '@gonfalon/dogfood-flags';

import { useCurrentEnvironmentEntity } from 'reducers/projects';

export function useIsWorkflowBuilderEnabled() {
  const currentEnvironment = useCurrentEnvironmentEntity();
  return () => isWorkflowBuilderEnabled() && !currentEnvironment?.hasIntegrationApproval();
}

export function useIsWorkflowBuilderUpsellEnabled() {
  const currentEnvironment = useCurrentEnvironmentEntity();
  return () => isWorkflowBuilderUpsellEnabled() && !currentEnvironment?.hasIntegrationApproval();
}
