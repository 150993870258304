import { flushSync } from 'react-dom';
import { NavigateFunction } from 'react-router-dom';
import { defer, noop } from '@gonfalon/es6-utils';
import { toApproval, toFlagApproval, toFlagPendingTargetingChanges } from '@gonfalon/navigator';
import { SnackbarQueue, ToastQueue } from '@launchpad-ui/components';
import { announce } from '@react-aria/live-announcer';
import { List, OrderedMap } from 'immutable';

import { forceFetchFlag } from 'actions/flags';
import { fetchScheduledChangesForFlag } from 'actions/scheduledChanges';
import { WorkflowNotificationLink } from 'components/PendingChanges/WorkflowNotificationLink';
import { GetState, GlobalDispatch, GlobalState } from 'reducers';
import { flagConfigApprovalRequestByIdAPIRequestSelector } from 'reducers/approvals';
import { currentEnvironmentKeySelector } from 'reducers/projects';
// eslint-disable-next-line import/no-namespace
import * as ApprovalsAPI from 'sources/Approvals';
import {
  ApprovalRequestApplyPostType,
  ApprovalRequestDeleteByIdType,
  ApprovalRequestForFlagCopyPostType,
  ApprovalRequestGetByIdType,
  ApprovalRequestPostType,
  ApprovalRequestReviewPostType,
} from 'sources/types/approvalsAPI';
import { UrlProps } from 'sources/types/utils';
import { ApprovalRequest, ApprovalRequestOptions, getApprovalNotificationMessage } from 'utils/approvalsUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import { WorkflowKind, WorkflowNotificationKind } from 'utils/pendingChangesUtils';
import { GenerateActionType } from 'utils/reduxUtils';

const requestApprovalRequestsForFlagConfiguration = (flagKey: string, projKey: string, envKey: string) =>
  ({ type: 'approvals/FETCH_FLAG_CONFIG_APPROVAL_REQUESTS', flagKey, projKey, envKey }) as const;

const requestApprovalRequestsForFlagConfigurationDone = (
  projKey: string,
  flagKey: string,
  envKey: string,
  approvals: OrderedMap<string, ApprovalRequest>,
) =>
  ({
    type: 'approvals/FETCH_FLAG_CONFIG_APPROVAL_REQUESTS_DONE',
    projKey,
    flagKey,
    envKey,
    approvals,
  }) as const;

const requestApprovalRequestsForFlagConfigurationFailed = (
  error: ImmutableServerError,
  projKey: string,
  flagKey: string,
  envKey: string,
) =>
  ({
    type: 'approvals/FETCH_FLAG_CONFIG_APPROVAL_REQUESTS_FAILED',
    error,
    projKey,
    flagKey,
    envKey,
  }) as const;

export const getApprovalRequestsForFlagConfiguration =
  ({ flagKey, projKey, envKey }: UrlProps) =>
  async (dispatch: GlobalDispatch) => {
    dispatch(requestApprovalRequestsForFlagConfiguration(flagKey, projKey, envKey));
    return ApprovalsAPI.getApprovalRequestsForFlagConfiguration({
      projKey,
      flagKey,
      envKey,
    })
      .then((approvals) => {
        dispatch(requestApprovalRequestsForFlagConfigurationDone(projKey, flagKey, envKey, approvals));
      })
      .catch((error) => {
        dispatch(requestApprovalRequestsForFlagConfigurationFailed(error, projKey, flagKey, envKey));
      });
  };

export const getApprovalRequestsByResourceId =
  ({ flagKey, projKey, envKey }: UrlProps) =>
  async (dispatch: GlobalDispatch) => {
    dispatch(requestApprovalRequestsForFlagConfiguration(flagKey, projKey, envKey));
    return ApprovalsAPI.getApprovalRequestsForAccount({
      params: {
        expand: { project: true, flag: true, environments: true },
        filter: `resourceId equals proj/${projKey}:env/${envKey}:flag/${flagKey}`,
      },
    })
      .then((response) => {
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        const approvals =
          response.result.items && response.entities.approvalRequests
            ? OrderedMap(
                response.result.items
                  .map(
                    (itemId) =>
                      [itemId, response.entities.approvalRequests![itemId]] as [
                        itemId: string,
                        ap: (typeof response.entities.approvalRequests)[keyof typeof response.entities.approvalRequests],
                      ],
                  )
                  .filter((entry) => entry[1]), // double check that the approval request was included in the "entities.approvalRequests" object
              )
            : OrderedMap<
                string,
                (typeof response.entities.approvalRequests)[keyof typeof response.entities.approvalRequests]
              >(); /* eslint-enable @typescript-eslint/no-non-null-assertion */

        dispatch(requestApprovalRequestsForFlagConfigurationDone(projKey, flagKey, envKey, approvals));
      })
      .catch((error) => {
        dispatch(requestApprovalRequestsForFlagConfigurationFailed(error, projKey, flagKey, envKey));
      });
  };

const handleCreateApprovalNotification = (
  getState: () => GlobalState,
  navigate: NavigateFunction,
  {
    projKey,
    envKey,
    flagKey,
    updatedApproval,
    shouldNotify,
    notificationKind,
    resourceId,
  }: {
    updatedApproval: ApprovalRequest;
    shouldNotify: boolean;
    notificationKind: WorkflowNotificationKind;
    resourceId?: string;
  } & UrlProps,
) => {
  const globalLink = toApproval({ approvalRequestId: updatedApproval.getId(), projectKey: projKey });
  const flagLink = toFlagApproval({
    projectKey: projKey,
    flagKey,
    approvalRequestId: updatedApproval.getId(),
    environmentKey: envKey,
  });
  const workflowLink = resourceId ? globalLink : flagLink;
  const pendingChangesLink = toFlagPendingTargetingChanges({ projectKey: projKey, flagKey, environmentKey: envKey });

  if (notificationKind === WorkflowNotificationKind.REDIRECT) {
    defer(() => navigate(workflowLink));
  }

  if (!shouldNotify) {
    return;
  }

  const description = updatedApproval?.isIntegrationApproval()
    ? 'Approval requested successfully.'
    : 'Approval requested successfully and reviewers have been notified.';

  if (
    notificationKind === WorkflowNotificationKind.LINK_WORKFLOW ||
    notificationKind === WorkflowNotificationKind.LINK_PENDING_CHANGES_DRAWER
  ) {
    SnackbarQueue.info({
      description,
      action: (
        <WorkflowNotificationLink
          forceRefresh={currentEnvironmentKeySelector(getState()) !== envKey}
          workflowKind={WorkflowKind.APPROVAL_REQUEST}
          notificationKind={notificationKind}
          workflowLink={workflowLink}
          pendingChangesLink={pendingChangesLink}
        />
      ),
    });
  } else {
    ToastQueue.success(description);
  }
  announce(description, 'polite');
};

export const requestCreateApproval = (flagKey?: string, projKey?: string, envKey?: string) =>
  ({ type: 'approvals/CREATE_APPROVAL_REQUEST', flagKey, projKey, envKey }) as const;

export const requestCreateApprovalDone = (
  updatedApproval: ApprovalRequest,
  projKey?: string,
  flagKey?: string,
  envKey?: string,
) =>
  ({
    type: 'approvals/CREATE_APPROVAL_REQUEST_DONE',
    updatedApproval,
    projKey,
    flagKey,
    envKey,
  }) as const;

const requestCreateApprovalFailed = (
  error: ImmutableServerError,
  projKey?: string,
  flagKey?: string,
  envKey?: string,
) =>
  ({
    type: 'approvals/CREATE_APPROVAL_REQUEST_FAILED',
    error,
    projKey,
    flagKey,
    envKey,
  }) as const;

export const createApprovalRequestForFlag =
  (
    navigate: NavigateFunction,
    {
      flagKey,
      projKey,
      envKey,
      comment,
      description,
      instructions,
      notifyMemberIds,
      notifyTeamKeys,
      executionDate,
      operatingOnId,
      integrationConfig,
    }: UrlProps & ApprovalRequestPostType,
    {
      shouldNotify = true,
      notificationKind = WorkflowNotificationKind.LINK_PENDING_CHANGES_DRAWER,
    }: ApprovalRequestOptions = {},
  ) =>
  async (dispatch: GlobalDispatch, getState: () => GlobalState) => {
    flushSync(() => {
      dispatch(requestCreateApproval(flagKey, projKey, envKey));
    });
    return ApprovalsAPI.postApprovalRequestForFlagConfiguration({
      projKey,
      flagKey,
      envKey,
      comment,
      description,
      instructions,
      notifyMemberIds,
      notifyTeamKeys,
      executionDate,
      operatingOnId,
      integrationConfig,
    })
      .then((res) => {
        // NOTE: If updating this function, make sure the notifications timing works
        flushSync(() => {
          dispatch(requestCreateApprovalDone(res, projKey, flagKey, envKey));
        });
        flushSync(() => {
          handleCreateApprovalNotification(getState, navigate, {
            updatedApproval: res,
            projKey,
            envKey,
            flagKey,
            shouldNotify,
            notificationKind,
          });
        });
      })
      .catch((error) => {
        dispatch(requestCreateApprovalFailed(error, projKey, flagKey, envKey));
      });
  };

export const createApprovalRequestForFlagCopy =
  (
    navigate: NavigateFunction,
    requestProps: UrlProps & ApprovalRequestForFlagCopyPostType,
    { shouldNotify = true, notificationKind = WorkflowNotificationKind.LINK_WORKFLOW }: ApprovalRequestOptions = {},
  ) =>
  async (dispatch: GlobalDispatch, getState: () => GlobalState) => {
    const { flagKey, projKey, envKey } = requestProps;
    dispatch(requestCreateApproval(flagKey, projKey, envKey));
    return ApprovalsAPI.postApprovalRequestForFlagCopy(requestProps)
      .then((res) => {
        dispatch(requestCreateApprovalDone(res, projKey, flagKey, envKey));
        handleCreateApprovalNotification(getState, navigate, {
          updatedApproval: res,
          projKey,
          envKey,
          flagKey,
          shouldNotify,
          notificationKind,
        });
      })
      .catch((error) => {
        dispatch(requestCreateApprovalFailed(error, projKey, flagKey, envKey));
      });
  };

export const createApprovalRequest =
  (
    navigate: NavigateFunction,
    {
      flagKey,
      projKey,
      envKey,
      comment,
      description,
      instructions,
      notifyMemberIds,
      notifyTeamKeys,
      operatingOnId,
      integrationConfig,
    }: UrlProps & ApprovalRequestPostType,
    {
      shouldNotify = true,
      notificationKind = WorkflowNotificationKind.LINK_PENDING_CHANGES_DRAWER,
    }: ApprovalRequestOptions = {},
  ) =>
  async (dispatch: GlobalDispatch, getState: () => GlobalState) => {
    flushSync(() => {
      dispatch(requestCreateApproval());
    });
    const resourceId = `proj/${projKey}:env/*:flag/${flagKey}`;
    return ApprovalsAPI.postApprovalRequest({
      comment,
      description,
      instructions,
      notifyMemberIds,
      notifyTeamKeys,
      operatingOnId,
      integrationConfig,
      resourceId,
    })
      .then((res) => {
        // NOTE: If updating this function, make sure the notifications timing works
        flushSync(() => {
          dispatch(requestCreateApprovalDone(res));
        });
        flushSync(() => {
          handleCreateApprovalNotification(getState, navigate, {
            updatedApproval: res,
            projKey,
            envKey,
            flagKey,
            shouldNotify,
            notificationKind,
            resourceId,
          });
        });
      })
      .catch((error) => {
        dispatch(requestCreateApprovalFailed(error));
      });
  };

const requestApprovalRequestReview = (flagKey: string, projKey: string, envKey: string) =>
  ({ type: 'approvals/REVIEW_FLAG_CONFIG_APPROVAL_REQUEST', flagKey, projKey, envKey }) as const;

export const requestApprovalRequestReviewDone = (
  projKey: string,
  envKey: string,
  flagKey: string,
  updatedApproval: ApprovalRequest,
  notificationMessage: string,
) =>
  ({
    type: 'approvals/REVIEW_FLAG_CONFIG_APPROVAL_REQUEST_DONE',
    projKey,
    envKey,
    flagKey,
    updatedApproval,
    notificationMessage,
  }) as const;

export const requestApprovalRequestReviewFailed = (
  projKey: string,
  envKey: string,
  flagKey: string,
  error: ImmutableServerError,
) =>
  ({
    type: 'approvals/REVIEW_FLAG_CONFIG_APPROVAL_REQUEST_FAILED',
    projKey,
    envKey,
    flagKey,
    error,
  }) as const;

export const reviewApprovalRequestForFlagConfig =
  ({ flagKey, projKey, envKey, comment, kind, requestId }: UrlProps & ApprovalRequestReviewPostType) =>
  async (dispatch: GlobalDispatch) => {
    dispatch(requestApprovalRequestReview(flagKey, projKey, envKey));
    return new Promise<void>((resolve, reject) => {
      ApprovalsAPI.postReviewApprovalRequestForFlagConfiguration({
        projKey,
        envKey,
        flagKey,
        comment,
        kind,
        requestId,
      })
        .then((res) => {
          dispatch(
            requestApprovalRequestReviewDone(projKey, envKey, flagKey, res, getApprovalNotificationMessage(kind)),
          );
          resolve();
        })
        .catch((error) => {
          dispatch(requestApprovalRequestReviewFailed(projKey, envKey, flagKey, error));
          reject();
        });
    });
  };

function shouldFetchApprovalRequestByIdForFlagConfiguration(
  state: GlobalState,
  { flagKey, projKey, envKey }: UrlProps,
) {
  const req = flagConfigApprovalRequestByIdAPIRequestSelector(state, { flagKey, projKey, envKey });
  return req ? req.shouldFetch() : true;
}

const requestApprovalRequest = (flagKey: string, projKey: string, envKey: string, requestId: string) =>
  ({ type: 'approvals/FETCH_FLAG_CONFIG_APPROVAL_REQUEST_BY_ID', flagKey, projKey, envKey, requestId }) as const;

const requestApprovalRequestDone = (
  projKey: string,
  flagKey: string,
  envKey: string,
  id: string,
  approvalById: ApprovalRequest,
) =>
  ({
    type: 'approvals/FETCH_FLAG_CONFIG_APPROVAL_REQUEST_BY_ID_DONE',
    projKey,
    flagKey,
    envKey,
    id,
    approvalById,
  }) as const;

export const requestApprovalRequestFailed = (
  error: ImmutableServerError,
  projKey: string,
  flagKey: string,
  envKey: string,
  approvalRequestId: string,
) =>
  ({
    type: 'approvals/FETCH_FLAG_CONFIG_APPROVAL_REQUEST_BY_ID_FAILED',
    error,
    projKey,
    flagKey,
    envKey,
    approvalRequestId,
  }) as const;

export const getApprovalRequestByIdForFlagConfiguration =
  ({ flagKey, projKey, envKey, requestId }: UrlProps & ApprovalRequestGetByIdType) =>
  async (dispatch: GlobalDispatch, getState: GetState) => {
    if (!shouldFetchApprovalRequestByIdForFlagConfiguration(getState(), { flagKey, projKey, envKey })) {
      return;
    }

    dispatch(requestApprovalRequest(flagKey, projKey, envKey, requestId));
    return ApprovalsAPI.getApprovalRequestByIdForFlagConfiguration({
      projKey,
      flagKey,
      envKey,
      requestId,
    })
      .then((approval) => {
        dispatch(requestApprovalRequestDone(projKey, flagKey, envKey, requestId, approval));
      })
      .catch((error) => {
        dispatch(requestApprovalRequestFailed(error, projKey, flagKey, envKey, requestId));
      });
  };

const requestDeleteApprovalRequest = (flagKey: string, projKey: string, envKey: string) =>
  ({ type: 'approvals/DELETE_FLAG_CONFIG_APPROVAL_REQUEST', flagKey, projKey, envKey }) as const;

export const requestDeleteApprovalRequestDone = (projKey: string, flagKey: string, envKey: string, deletedId: string) =>
  ({
    type: 'approvals/DELETE_FLAG_CONFIG_APPROVAL_REQUEST_DONE',
    projKey,
    flagKey,
    envKey,
    deletedId,
  }) as const;

export const requestDeleteApprovalRequestFailed = (
  error: ImmutableServerError,
  projKey: string,
  flagKey: string,
  envKey: string,
  deletedId: string,
) =>
  ({
    type: 'approvals/DELETE_FLAG_CONFIG_APPROVAL_REQUEST_FAILED',
    error,
    projKey,
    flagKey,
    envKey,
    deletedId,
  }) as const;

export const deleteApprovalRequestForFlag =
  ({ flagKey, projKey, envKey, requestId }: UrlProps & ApprovalRequestDeleteByIdType) =>
  async (dispatch: GlobalDispatch) => {
    dispatch(requestDeleteApprovalRequest(flagKey, projKey, envKey));
    return ApprovalsAPI.deleteApprovalRequest({
      requestId,
    })
      .then(() => {
        dispatch(requestDeleteApprovalRequestDone(projKey, flagKey, envKey, requestId));
      })
      .catch((error) => {
        dispatch(requestDeleteApprovalRequestFailed(error, projKey, flagKey, envKey, requestId));
      });
  };

export const deleteApprovalRequestForFlagConfiguration =
  ({ flagKey, projKey, envKey, requestId }: UrlProps & ApprovalRequestDeleteByIdType) =>
  async (dispatch: GlobalDispatch) => {
    dispatch(requestDeleteApprovalRequest(flagKey, projKey, envKey));
    return ApprovalsAPI.deleteApprovalRequestForFlagConfiguration({
      projKey,
      flagKey,
      envKey,
      requestId,
    })
      .then(() => {
        dispatch(requestDeleteApprovalRequestDone(projKey, flagKey, envKey, requestId));
      })
      .catch((error) => {
        dispatch(requestDeleteApprovalRequestFailed(error, projKey, flagKey, envKey, requestId));
      });
  };

const requestApplyApprovalRequest = (flagKey: string, projKey: string, envKey: string) =>
  ({ type: 'approvals/APPLY_FLAG_CONFIG_APPROVAL_REQUEST', flagKey, projKey, envKey }) as const;

export const requestApplyApprovalRequestDone = (
  projKey: string,
  flagKey: string,
  envKey: string,
  updatedApproval: ApprovalRequest,
) =>
  ({
    type: 'approvals/APPLY_FLAG_CONFIG_APPROVAL_REQUEST_DONE',
    projKey,
    flagKey,
    envKey,
    updatedApproval,
  }) as const;

export const requestApplyApprovalRequestFailed = (
  error: ImmutableServerError,
  projKey: string,
  flagKey: string,
  envKey: string,
) =>
  ({
    type: 'approvals/APPLY_FLAG_CONFIG_APPROVAL_REQUEST_FAILED',
    error,
    projKey,
    flagKey,
    envKey,
  }) as const;

export const applyApprovalRequestForFlagConfiguration =
  (
    { flagKey, projKey, envKey, comment, requestId }: UrlProps & ApprovalRequestApplyPostType,
    { shouldRefetchScheduledFlagChanges }: { shouldRefetchScheduledFlagChanges: boolean },
  ) =>
  async (dispatch: GlobalDispatch) => {
    dispatch(requestApplyApprovalRequest(flagKey, projKey, envKey));
    return ApprovalsAPI.postApplyApprovalRequestForFlagConfiguration({
      projKey,
      flagKey,
      envKey,
      comment,
      requestId,
    })
      .then(async (res) => {
        dispatch(forceFetchFlag(projKey, flagKey, envKey))
          .then(() => {
            dispatch(getApprovalRequestByIdForFlagConfiguration({ flagKey, projKey, envKey, requestId }))
              .then(() => {
                dispatch(requestApplyApprovalRequestDone(projKey, flagKey, envKey, res));
              })
              .catch(noop);
          })
          .catch(noop);
        shouldRefetchScheduledFlagChanges &&
          (await dispatch(fetchScheduledChangesForFlag({ flagKey, projKey, envKey })));
      })
      .catch((error) => {
        dispatch(requestApplyApprovalRequestFailed(error, projKey, flagKey, envKey));
      });
  };

export const editApprovalRequest = (field: string, value: string | List<string>) =>
  ({
    type: 'approvals/EDIT_APPROVAL_REQUEST',
    field,
    value,
  }) as const;

const ApprovalActionCreators = {
  editApprovalRequest,
  requestApprovalRequestsForFlagConfiguration,
  requestApprovalRequestsForFlagConfigurationDone,
  requestApprovalRequestsForFlagConfigurationFailed,
  requestCreateApproval,
  requestCreateApprovalDone,
  requestCreateApprovalFailed,
  requestApprovalRequestReview,
  requestApprovalRequestReviewDone,
  requestApprovalRequestReviewFailed,
  requestApprovalRequest,
  requestApprovalRequestDone,
  requestApprovalRequestFailed,
  requestDeleteApprovalRequest,
  requestDeleteApprovalRequestDone,
  requestDeleteApprovalRequestFailed,
  requestApplyApprovalRequest,
  requestApplyApprovalRequestDone,
  requestApplyApprovalRequestFailed,
};

export type ApprovalAction = GenerateActionType<typeof ApprovalActionCreators>;
