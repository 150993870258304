import { RequiredAsterisk } from 'launchpad';

const RequiredFieldsNotice = ({ singular = false }: { singular?: boolean }) => (
  <span className="u-fs-sm">
    <RequiredAsterisk /> Required field{singular ? '' : 's'}
  </span>
);

/* eslint-disable import/no-default-export */
export default RequiredFieldsNotice;
