import { metricsDetail } from '@gonfalon/rest-api';
import { useProjectKey } from '@gonfalon/router';
import { useQueries } from '@tanstack/react-query';

export function useMetricsByKeys(keys: string[]) {
  const projectKey = useProjectKey();

  const metricsQueries = useQueries({
    queries: keys.map((metricKey) => metricsDetail({ projectKey, metricKey })),
  });
  const isPending = metricsQueries.some((query) => query.isPending);
  const isFetching = metricsQueries.some((query) => query.isFetching);

  // return an empty list until all metrics are fetched
  if (isPending) {
    return {
      metrics: undefined,
      isPending,
      isFetching,
    };
  }

  const metrics = metricsQueries
    .map((query) => query.data)
    .filter((metric): metric is Exclude<typeof metric, undefined> => typeof metric !== 'undefined');

  return {
    metrics,
    isPending,
    isFetching,
  };
}
