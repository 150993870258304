// eslint-disable-next-line no-restricted-imports
import { fromJS, Map as ImmutableMap } from 'immutable';
import { createSelector } from 'reselect';

import { FormAction } from 'actions/forms';
import { RoleAction } from 'actions/roles';
import { GlobalState } from 'reducers';
import registry from 'reducers/registry';
import textFilter from 'reducers/textFilter';
import { createFormState } from 'utils/formUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import { ImmutableMap as ImmutableMapType } from 'utils/immutableUtils';
import { createRoleForm, Role, RoleForm } from 'utils/roleUtils';

const initialFormState = createFormState(createRoleForm({}));

// Is this a RequestAction or a FormAction | RoleAction??
export function roleForm(state = initialFormState, action: FormAction | RoleAction) {
  switch (action.type) {
    case 'roles/EDIT_ROLE':
      let st = state.trackField(action.field).revalidate(action.role);
      if (action.options && action.options.shouldClearServerError) {
        st = st.clearServerError();
      }
      return st;
    case 'roles/CREATE_ROLE':
      return state.submitting();
    case 'roles/CREATE_ROLE_FAILED':
      return state.submitFailed(action.role, action.error);
    case 'roles/CREATE_ROLE_DONE':
      return initialFormState;
    case 'roles/UPDATE_ROLE':
      return state.submitting();
    case 'roles/UPDATE_ROLE_FAILED':
      return state.submitFailed(state.modified, action.error);
    case 'roles/UPDATE_ROLE_DONE':
      return state.submitted(action.role.toForm());
    case 'forms/BLUR':
      if (action.model !== 'roleForm') {
        return state;
      }
      return state.handleBlur(action.field, state.modified);
    case 'forms/INITIALIZE':
      if (action.model !== 'roleForm') {
        return state;
      }
      /*
      Worth noting: action.initialState is typed to be a generic <T>
      which means that its type is unknown. createFormState cannot accept
      a type of unknown as an argument to its type FormRecord<Record<any>>
      so the casting as a type of RoleForm seemed to be the best option here
      */
      return createFormState(action.initialState as RoleForm);
    case 'forms/DESTROY':
      if (action.model !== 'roleForm') {
        return state;
      }
      return initialFormState;
    case 'forms/DISABLE_SUBMIT':
      return state.disableSubmit();
    case 'forms/ENABLE_SUBMIT':
      return state.enableSubmit();
    default:
      return state;
  }
}

export type RolesReducerType = ImmutableMapType<{
  entities: ImmutableMap<string, Role>;
  lastFetched?: number;
  isFetching: boolean;
  error: ImmutableServerError | null;
}>;

export function roles(
  state: RolesReducerType = fromJS({
    entities: {},
    lastFetched: null,
    isFetching: false,
    error: null,
  }),
  action: RoleAction,
) {
  switch (action.type) {
    case 'roles/REQUEST_ROLES':
      return state.set('isFetching', true);
    case 'roles/REQUEST_ROLES_FAILED':
      return state.merge({
        isFetching: false,
        error: action.error,
      });
    case 'roles/REQUEST_ROLES_DONE':
      return state.merge({
        isFetching: false,
        lastFetched: action.timestamp,
        entities: action.response.getIn(['entities', 'roles']),
      });
    case 'roles/CREATE_ROLE_DONE':
    case 'roles/UPDATE_ROLE_DONE':
      return state.update('entities', (entities) => entities.set(action.role.identifier(), action.role));
    case 'roles/DELETE_ROLE_DONE':
      return state.update('entities', (entities) => entities.delete(action.role.identifier()));
    default:
      return state;
  }
}

export const roleFilter = textFilter({
  type: 'roles/FILTER_BY_TEXT',
  props: ['name', 'key'],
});

export const rolesSelector = (state: GlobalState) => state.roles;
export const roleListSelector = createSelector(rolesSelector, (rolesState) => rolesState.get('entities').toList());
export const rolesByIdSelector = createSelector(roleListSelector, (roleList) => {
  const byId = new Map();

  for (const role of roleList) {
    byId.set(role._id, role);
  }
  return byId;
});

export const roleFilterSelector = (state: GlobalState) => state.roleFilter;

export const filteredRolesSelector = createSelector(rolesSelector, roleFilterSelector, (rolesState, filter) =>
  rolesState.get('entities').filter(filter.get('predicate')),
);

export const roleFormSelector = (state: GlobalState) => state.roleForm;

registry.addReducers({ roleFilter, roleForm, roles });
