export interface StorageSlot {
  get(value?: string): string | null;
  set(value: string): void;
  remove(): void;
  subscribe(onChange: (event: StorageEvent) => void): () => void;
}

export const noopStorageSlot: StorageSlot = {
  get: () => null,
  set: () => {},
  remove: () => {},
  subscribe: () => () => {},
};
