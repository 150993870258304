import { enableAppsAndAppVersions } from '@gonfalon/dogfood-flags';

import { FilterKind, FilterValue } from '../../components/ui/multipleFilters/types';

import FlagFilterInterface, { BackendQueryParamName, QueryParamName } from './flagFilterInterface';
import { FilterOperator, FlagListBackendQueryParam, FlagListQueryParam } from './types';

class ApplicationEvaluatedFilter implements FlagFilterInterface<string> {
  name: string;
  value: string;
  kind: FilterKind;
  queryParamName: QueryParamName;
  backendQueryParamName: BackendQueryParamName;
  operators: string[];
  defaultValue: string;

  constructor(value?: string) {
    this.name = 'Application evaluated';
    this.value = value || '';
    this.kind = FilterKind.APPLICATION_EVALUATED;
    this.queryParamName = FlagListQueryParam.APPLICATION_EVALUATED;
    this.backendQueryParamName = FlagListBackendQueryParam.APPLICATION_EVALUATED;
    this.operators = [FilterOperator.IS];
    this.defaultValue = '';
  }

  getFilterValueDisplayName(value?: FilterValue) {
    if (typeof value === 'string') {
      return value;
    }

    return '';
  }

  getDisplayName = () => this.name;

  getOptions() {
    // Fetches own options
    return [];
  }

  isEnabled = () => enableAppsAndAppVersions();

  isEmpty = () => this.value === '';

  toQuery = () => this.value;

  isInvalidFilter = () => false;

  clearFilter = () => (this.value = '');

  toBackendQuery() {
    if (this.isEmpty()) {
      return '';
    }

    return `${this.backendQueryParamName}:${this.value}`;
  }

  parseFilterParam(queryParts: string[]) {
    const [, value] = queryParts;
    return value;
  }

  getFilterData() {
    // Fetches own options
    return {
      operators: this.operators,
      options: [],
      defaultValue: this.defaultValue,
    };
  }
}

/* eslint-disable import/no-default-export */
export default ApplicationEvaluatedFilter;
