import nullthrows from 'nullthrows';

import { createFormatter } from './internal/createFormatter';
import { findPrecision } from './internal/findPrecision';
import { RelativeDifference } from './types';

const MAX_FORMATTED_PERCENT = 1000;

export function createRelativeDifferenceFormatter(differences: RelativeDifference[]) {
  const intervalMultiplier = 0.25;
  // Use the most precise value of the estimates. This ensures that the within
  // variation differences are always viewable. This could be reconsidered.
  const precision = findPrecision(
    Math.min(
      ...differences
        .filter(({ lower, upper }) => !(lower === 0 && upper === 0))
        .map(({ lower, upper }) => intervalMultiplier * (nullthrows(upper) - nullthrows(lower))),
    ),
  );

  // Important: precision should be in original units. createFormatter will
  // handle adjusting for the x 100 in percentages.
  const format = createFormatter({ precision, asPercent: true });

  return (num: number) => {
    if (num > MAX_FORMATTED_PERCENT / 100) {
      return `>${(MAX_FORMATTED_PERCENT / 100).toLocaleString('en-US', { style: 'percent' })}`;
    }

    if (num < -MAX_FORMATTED_PERCENT / 100) {
      return `<${(-MAX_FORMATTED_PERCENT / 100).toLocaleString('en-US', { style: 'percent' })}`;
    }

    return format(num);
  };
}
