import { isEnvironmentFilter } from './isEnvironmentFilter';
import { type UIFlagFilter } from './types';

export function clearEnvironmentFilters(filter: UIFlagFilter) {
  const cleared = structuredClone(filter);

  for (const key of Object.keys(filter)) {
    if (isEnvironmentFilter(key)) {
      cleared[key] = undefined;
    }
  }

  return cleared;
}
