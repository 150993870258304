import { ensureChangeHistoryItem } from './ensureChangeHistoryItem';
import { ChangeHistoryItem, ChangeHistoryPreferences } from './schema';

export function serializePreferencesToSelection(preferences: ChangeHistoryPreferences) {
  const selection = new Set<ChangeHistoryItem>();

  for (const [k, v] of Object.entries(preferences)) {
    if (v === true) {
      selection.add(ensureChangeHistoryItem(k));
    }
  }

  return selection;
}
