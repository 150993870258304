import { ContextKind } from '@gonfalon/flags';

export const USER_CONTEXT_KIND = ContextKind.USER;
export const APPLICATION_CONTEXT_KIND = ContextKind.APPLICATION;
export const DEVICE_CONTEXT_KIND = ContextKind.DEVICE;
export const CONTEXT_KIND_ATTRIBUTE = 'kind';
export const SEGMENT_MATCH_ATTRIBUTE = 'segmentMatch';
export const NOT_SEGMENT_MATCH_ATTRIBUTE = 'not-segmentMatch';
export const OS_VERSION_ATTRIBUTE = '/os/version';
export const OS_NAME_ATTRIBUTE = '/os/name';
export const HIDDEN_MOBILE_ATTRIBUTES = ['/os/family', 'key', 'envAttributesVersion'];
export const CONTEXT = 'context';
export const APPLICATION_VERSION_SUPPORTED_OP = 'applicationVersionSupported';
export const APP_VERSION_SUPPORT_STATUS_ATTRIBUTE = 'version_support_status';
