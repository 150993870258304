import { MeasuredRolloutTimeSeriesResultsWithMetric } from '@gonfalon/measured-rollouts';

export const hasInsufficientResultsForMetric = (
  timeSeriesResults: MeasuredRolloutTimeSeriesResultsWithMetric | undefined,
  minimumDataPoints = 1,
): boolean => {
  if (!timeSeriesResults?.results?.results?.length) {
    return true;
  }

  return timeSeriesResults.results.results.length < minimumDataPoints;
};
