import { omitDeep } from '@gonfalon/collections';
import { isNil } from '@gonfalon/es6-utils';
import { JSONPatch } from '@gonfalon/rest-api';
// eslint-disable-next-line no-restricted-imports
import { fromJS, isImmutable } from 'immutable';
import { diff as createPatch } from 'vendor/immutablediff/diff';

type CreateJSONPatchOptions = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  propFilter?: false | ((v?: any) => boolean);
  transformPatch?: (p: JSONPatch) => JSONPatch;
  comment?: string | null;
  shouldTestVersion?: boolean;
  removeEmptyFields?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultPropFilter = (v?: any) => isNil(v) || v === '';

type MaybeVersionedObject = {
  version?: number;
};

/**
 * Create a JSON patch from two objects
 * @param  {Immutable.Collection|Immutable.Record|Object}   prevObj object to apply patch to
 * @param  {Immutable.Collection|Immutable.Record|Object}   nextObj object to create patch from
 * @param  {Function} propFilter         a function to filter out props that shouldn't be used in patch creation
 * @param  {Function} transformPatch     a function to transform the final JSON patch
 * @param  {Function} comment            a comment to send with the patch
 * @param  {Boolean} shouldTestVersion   whether or not to test the version for the update
 * @param  {Boolean}  removeEmptyFields  when true, uses "remove" operation instead of "replace" for nullish values
 * @return {Object}                      a JSON patch object
 */
export function createJsonPatch<T>(
  prevObj: T,
  nextObj: T,
  { propFilter, transformPatch, comment, removeEmptyFields, shouldTestVersion }: CreateJSONPatchOptions = {
    propFilter: defaultPropFilter,
  },
) {
  const prev: MaybeVersionedObject = (isImmutable(prevObj) ? prevObj.toJS() : prevObj) as MaybeVersionedObject;
  const next: MaybeVersionedObject = (isImmutable(nextObj) ? nextObj.toJS() : nextObj) as MaybeVersionedObject;

  const initialPatch = createPatch(
    fromJS(propFilter ? omitDeep(prev, propFilter) : prev),
    fromJS(propFilter ? omitDeep(next, propFilter) : next),
    '',
    { removeEmptyFields },
  ).toJS();

  const transformed = transformPatch ? transformPatch(initialPatch) : initialPatch;
  const finalPatch = shouldTestVersion
    ? [{ op: 'test', path: '/version', value: next.version }, ...transformed]
    : transformed;

  return maybeAddCommentToJsonPatch(finalPatch, comment);
}

export function maybeAddCommentToJsonPatch(patch: JSONPatch, comment?: string | null) {
  if (comment && comment.length) {
    return {
      comment,
      patch,
    };
  }
  return patch;
}
