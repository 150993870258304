export const parseValidParams = (searchParams: URLSearchParams) => {
  const params = new URLSearchParams(searchParams);
  params.forEach((value, key) => {
    try {
      if (!value || value === '""' || decodeURIComponent(value) === '""') {
        params.delete(key);
      }
    } catch (e) {
      // if something goes wrong (e.g. invalid encoding), just remove the param
      params.delete(key);
    }
  });

  return params;
};
