import { isValid as isValidDate, parseISO } from 'date-fns';
// eslint-disable-next-line no-restricted-imports
import { fromJS, isImmutable, List, Map, Record } from 'immutable';

import { CreateFunctionInput, keyIn, valueExists } from 'utils/immutableUtils';

import { APP_VERSION_SUPPORT_STATUS_ATTRIBUTE, OS_NAME_ATTRIBUTE, OS_VERSION_ATTRIBUTE } from './constants';

export const builtinAttributes = [
  'email',
  'firstName',
  'lastName',
  'name',
  'ip',
  'avatar',
  'country',
  'anonymous',
  'key',
  'secondary',
];

export const standardAttributesList = Object.freeze(builtinAttributes);

const deviceAttributeMapping: { [key: string]: string } = {
  [OS_NAME_ATTRIBUTE]: 'OS name',
  [OS_VERSION_ATTRIBUTE]: 'OS version',
};

const applicationAttributeMapping: { [key: string]: string } = {
  version: 'Version',
  id: 'ID',
  name: 'Name',
  [APP_VERSION_SUPPORT_STATUS_ATTRIBUTE]: 'Version support status',
};

export const mobileAttributesMapping: { [key: string]: string } = {
  ...deviceAttributeMapping,
  ...applicationAttributeMapping,
};

export const standardAttributesMapping: { [key: string]: string } = {
  key: 'key',
  anonymous: 'anonymous',
  country: 'country',
  email: 'email',
  firstName: 'first name',
  ip: 'ip',
  lastName: 'last name',
  name: 'name',
  secondary: 'secondary',
};

export type UserAttributesProps = {
  key: string;
  secondary?: string;
  ip?: string;
  email?: string;
  name?: string;
  avatar?: string;
  firstName?: string;
  lastName?: string;
  country?: string;
  anonymous?: string;
  privateAttrs: List<string>;
  custom: Map<string, string | List<string>>;
};

export type DisplayableUser = Pick<UserAttributesProps, 'key' | 'email' | 'name' | 'firstName' | 'lastName'>;

export function getDisplayName(attributes: DisplayableUser) {
  if (attributes.name) {
    return attributes.name;
  } else if (attributes.firstName && attributes.lastName) {
    return `${attributes.firstName} ${attributes.lastName}`;
  } else if (attributes.email) {
    return attributes.email;
  } else {
    return attributes.key;
  }
}

export class UserAttributes extends Record<UserAttributesProps>({
  key: '',
  secondary: undefined,
  ip: undefined,
  email: undefined,
  name: undefined,
  avatar: undefined,
  firstName: undefined,
  lastName: undefined,
  country: undefined,
  anonymous: undefined,
  privateAttrs: List(),
  custom: Map(),
}) {
  hasName() {
    return !!(this.name || (this.firstName && this.lastName) || this.email);
  }

  getDisplayName() {
    return getDisplayName(this);
  }

  getPrivateAttrs() {
    return this.privateAttrs;
  }

  getBaseAttributes() {
    return Map(this.toObject())
      .filter(keyIn(...builtinAttributes))
      .filter(valueExists);
  }

  getCustomAttributes() {
    return this.custom;
  }

  hasNonEmptyAttributes() {
    return !this.getBaseAttributes().isEmpty() || !this.getCustomAttributes().isEmpty();
  }
}

export function createUserAttributes(props: CreateFunctionInput<UserAttributes>) {
  return props instanceof UserAttributes ? props : new UserAttributes(fromJS(props));
}

// fix in [ch99585]
// a user attribute can truly be just about anything, so it's typed accordingly
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stringifyAttributeValue = (value: any) => {
  if (isImmutable(value)) {
    const asJS = value.toJS();
    return asJS.join ? asJS.join(', ') : JSON.stringify(asJS);
  }
  if (typeof value === 'object') {
    return JSON.stringify(value);
  }
  return String(value);
};

export const isDateAttribute = (name: string, value: number | string | object) => {
  const regexResult =
    [/_at$/, /At$/, /_date$/, /Date$/, /_time$/, /Time$/, /^last_seen$/, /^lastSeen$/].some((pat) => pat.test(name)) &&
    String(value).length >= 13;

  let sanitizedValue = value;
  if (typeof sanitizedValue === 'string') {
    sanitizedValue = parseISO(sanitizedValue);
  }

  return regexResult && isValidDate(sanitizedValue);
};
