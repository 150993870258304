import { has, isEmpty } from '@gonfalon/es6-utils';
import { capitalize } from '@gonfalon/strings';

import { FilterKind, FilterValue } from 'components/ui/multipleFilters/types';
import FlagFilterInterface, { BackendQueryParamName, QueryParamName } from 'utils/flagFilters/flagFilterInterface';
import { FilterOperator, FlagListBackendQueryParam, FlagListQueryParam } from 'utils/flagFilters/types';

export enum FlagTypeOption {
  TEMPORARY = 'temporary',
  PERMANENT = 'permanent',
}

const flagTypeOptionDisplayNames: Record<FlagTypeOption, string> = {
  temporary: 'Temporary',
  permanent: 'Permanent',
};

class TypeFilter implements FlagFilterInterface<string> {
  name: string;
  value?: string;
  kind: FilterKind;
  queryParamName: QueryParamName;
  backendQueryParamName: BackendQueryParamName;
  operators: string[];
  defaultValue: string;

  constructor(value?: string) {
    this.name = 'Type';
    this.value = value;
    this.kind = FilterKind.TYPE;
    this.queryParamName = FlagListQueryParam.TYPE;
    this.backendQueryParamName = FlagListBackendQueryParam.TYPE;
    this.operators = [FilterOperator.IS];
    this.defaultValue = FlagTypeOption.TEMPORARY;
  }

  getFilterValueDisplayName(value?: FilterValue) {
    if (!value) {
      return '';
    }
    return capitalize(value as string);
  }

  getDisplayName = () => this.name;

  getOptions() {
    return Object.entries(flagTypeOptionDisplayNames).map(([value, label]) => ({
      label,
      name: label,
      value,
      filterOption: this.kind,
      isChecked: this.value === value,
    }));
  }

  isEnabled = () => true;

  isEmpty = () => isEmpty(this.value);

  toQuery = () => this.value;

  isInvalidFilter = () =>
    !this.isEmpty() &&
    !(
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      has(flagTypeOptionDisplayNames, this.value!)
    ) /* eslint-enable @typescript-eslint/no-non-null-assertion */;

  clearFilter = () => (this.value = undefined);

  toBackendQuery() {
    if (this.isEmpty()) {
      return '';
    }
    return `type:${this.value}`;
  }

  parseFilterParam(queryParts: string[]) {
    const [, value] = queryParts;
    return value;
  }

  getFilterData() {
    return {
      operators: this.operators,
      options: this.getOptions(),
      defaultValue: this.defaultValue,
    };
  }
}

/* eslint-disable import/no-default-export */
export default TypeFilter;
