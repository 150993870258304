import type { ComponentPropsWithRef, ReactNode } from 'react';
import { Slot } from '@gonfalon/react';
import clsx from 'clsx';

import styles from './Card.module.css';

export type CardProps = ComponentPropsWithRef<'div'> & {
  children: ReactNode;
  kind?: 'default' | 'compact' | 'destructive';
  edgeless?: boolean;
  asChild?: boolean;
  className?: string;
};

export const Card = ({ children, className, kind = 'default', edgeless = false, asChild, ...rest }: CardProps) => {
  const Comp = asChild ? Slot : 'div';

  return (
    <Comp {...rest} className={clsx(className, styles.card, styles[kind], { [styles.edgy]: !edgeless })}>
      {children}
    </Comp>
  );
};
