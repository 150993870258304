import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { useProjectKey } from '@gonfalon/router';

import { fetchFlagIfNeeded } from 'actions/flags';
import { GlobalDispatch, GlobalState } from 'reducers';
import { flagByKeyForProjectSelector, flagRequestByKeyForProjectSelector } from 'reducers/flags';
import { currentProjectKeySelector } from 'reducers/projects';
import { Flag } from 'utils/flagUtils';
import { ready } from 'utils/reduxUtils';

export type FlagDisplayForInstructionsContainerProp = {
  isReady: boolean;
  flagKey: string;
  flag?: Flag;
  fetchFlag(flagKey: string, projKey: string, envKey: string): void;
};

export function FlagDisplayForInstructions({
  isReady,
  flagKey,
  flag,
  fetchFlag,
}: FlagDisplayForInstructionsContainerProp) {
  const projKey = useProjectKey();
  const envKey = useSelectedEnvironmentKey();

  useEffect(() => {
    if (!isReady) {
      fetchFlag(projKey, flagKey, envKey);
    }
  }, []);

  return flag ? <Link to={flag.siteLink(envKey)}>{flag.name}</Link> : <code>{flagKey}</code>;
}

type FlagDisplayForInstructionsContainerOwnProps = {
  flagKey: string;
  projKey?: string;
};

const mapStateToProps = (
  state: GlobalState,
  { flagKey, projKey = currentProjectKeySelector(state) }: FlagDisplayForInstructionsContainerOwnProps,
) => {
  const request = flagRequestByKeyForProjectSelector(state, { flagKey, projKey });
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  const flag = flagByKeyForProjectSelector(state, {
    flagKey,
    projKey,
  }).entity!; /* eslint-enable @typescript-eslint/no-non-null-assertion */
  const isReady = ready(request);
  return {
    isReady,
    flag,
  };
};

const mapDispatchToProps = (dispatch: GlobalDispatch) => ({
  fetchFlag: async (flagKey: string, projKey: string, envKey: string) =>
    dispatch(fetchFlagIfNeeded(projKey, flagKey, envKey)),
});

/* eslint-disable import/no-default-export */
export default connect(mapStateToProps, mapDispatchToProps)(FlagDisplayForInstructions);
