import { canMakeSelfServeChanges as canMakeLegacySubscriptionSelfServeChanges } from '../internal/legacySubscription';
import { canMakeSelfServeChanges as canMakeModernSubscriptionSelfServeChanges } from '../internal/modernSubscription';
import { Subscription } from '../types';

import { isModernSubscription } from './isModernSubscription';

export function canMakeSelfServeChanges(subscription: Subscription) {
  if (isModernSubscription(subscription)) {
    return canMakeModernSubscriptionSelfServeChanges(subscription);
  }

  return canMakeLegacySubscriptionSelfServeChanges(subscription);
}
