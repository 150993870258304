import { useQuery } from '@tanstack/react-query';

import { getProjectExperimentationSettings } from 'components/experimentation/common/api/experiment';
import { EXPERIMENTS_V2_KEY } from 'components/experimentation/common/hooks/useExperiment';

type Props = {
  projKey: string;
  enabled?: boolean;
};

// @deprecated Use @gonfalon/rest-api#useProjectExperimentationSettings instead
export function useProjectExperimentationSettings({ projKey, enabled = true }: Props) {
  return useQuery({
    queryKey: [EXPERIMENTS_V2_KEY, 'EXPERIMENTATION_SETTINGS', projKey],
    queryFn: async () => getProjectExperimentationSettings(projKey),
    enabled,
  });
}
