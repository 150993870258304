import { readProjectContextFromPath } from './readProjectContextFromPath';

export function readProjectContextFromRequest(request: Request) {
  const requestUrl = new URL(request.url);

  return readProjectContextFromPath({
    pathname: requestUrl.pathname,
    search: requestUrl.search,
    hash: requestUrl.hash,
  });
}
