import { useCallback, useMemo } from 'react';
import type { Selection } from 'react-aria-components';
import { createStorageSlot, useStorageSlot } from '@gonfalon/browser-storage';

import { parsePreferencesFromSelection } from './parsePreferencesFromSelection';
import { defaultPreferences, schema } from './schema';
import { serializePreferencesToSelection } from './serializePreferencesToSelection';

export function getChangeHistoryDisplayPreferences() {
  const rawSlot = createStorageSlot('change-history-display-preferences', {
    storageType: 'sessionStorage',
  });
  try {
    return schema.parse(JSON.parse(rawSlot.get() ?? ''));
  } catch (e) {
    return defaultPreferences;
  }
}

export function useChangeHistoryDisplayPreferences() {
  const [rawSlot, slot] = useStorageSlot('change-history-display-preferences', {
    storageType: 'sessionStorage',
  });
  const cachedPreferences = useMemo(() => {
    try {
      return schema.parse(JSON.parse(rawSlot ?? ''));
    } catch (e) {
      return defaultPreferences;
    }
  }, [rawSlot]);

  const selection = useMemo(() => serializePreferencesToSelection(cachedPreferences), [cachedPreferences]);

  const updateWithSelection = useCallback(
    (nextSelection: Selection) => slot.set(JSON.stringify(parsePreferencesFromSelection(nextSelection))),
    [],
  );

  return useMemo(
    () => ({ preferences: cachedPreferences, selection, updateWithSelection }) as const,
    [cachedPreferences, selection, updateWithSelection],
  );
}
