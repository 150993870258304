import { useEffect, useState } from 'react';
import { ProgressBar } from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';
import { IconButton } from 'launchpad';

import { CounterHeader } from 'components/ui/counterHeader';
import { EventLocation } from 'utils/analyticsUtils';
import { PaginationType, trackPaginationEvent } from 'utils/paginationUtils';

import './styles.css';

const PaginationResourceLabels = {
  flags: 'flags',
  members: 'members',
  experiments: 'experiments',
  teamCustomRoles: 'team roles',
  teams: 'teams',
  userFlags: 'flags',
  payloadFilters: 'payload filters',
};

type PaginationResource = keyof typeof PaginationResourceLabels;

export type PaginationControlProps = {
  onPageClick(query: string): void;
  pagination?: PaginationType;
  currentOffset: string;
  pageSize: number;
  resourceKind: PaginationResource;
  isReady: boolean;
  paginationTotalCountOverride?: number;
};

enum PaginationClick {
  FIRST = 'first',
  PREV = 'prev',
  NEXT = 'next',
  LAST = 'last',
}

function getPaginationUrl(pagination: PaginationType | undefined, dir: string) {
  let url: string;

  switch (dir) {
    case PaginationClick.PREV:
      url = pagination?.get('prevPageUrl') || '';
      break;
    case PaginationClick.NEXT:
      url = pagination?.get('nextPageUrl') || '';
      break;
    case PaginationClick.FIRST:
      url = pagination?.get('firstPageUrl') || '';
      break;
    case PaginationClick.LAST:
      url = pagination?.get('lastPageUrl') || '';
      break;
    default:
      url = '';
  }

  return url;
}

function PaginationControl({
  pagination,
  currentOffset,
  pageSize,
  resourceKind,
  isReady,
  paginationTotalCountOverride,
  onPageClick,
}: PaginationControlProps) {
  const hasUrl = (dir: string) => !!getPaginationUrl(pagination, dir);

  const paginationTotalCount = paginationTotalCountOverride ?? pagination?.get('totalCount');
  const renderPaginationText = () => {
    const offset = Math.max(0, (currentOffset && parseInt(currentOffset, 10)) || 0);
    const from = offset + 1;
    const to = paginationTotalCount && Math.min(offset + pageSize, paginationTotalCount);
    if (!isReady) {
      return (
        <div>
          <ProgressBar aria-label="Loading…" isIndeterminate />
        </div>
      );
    }

    if (!paginationTotalCount) {
      return <strong>No results</strong>;
    }

    return (
      <div className="u-ph-s">
        <strong>
          {from}-{to}
        </strong>{' '}
        of <strong>{paginationTotalCount}</strong>
      </div>
    );
  };

  const handlePageClick = (direction: string) => () => {
    let paginationControlLocation;
    switch (resourceKind) {
      case 'flags':
        paginationControlLocation = EventLocation.FLAG_LIST;
        break;
      case 'members':
        paginationControlLocation = EventLocation.MEMBER_LIST;
        break;
      case 'experiments':
        paginationControlLocation = EventLocation.EXPERIMENT_LIST;
        break;
      case 'teamCustomRoles':
        paginationControlLocation = EventLocation.TEAM_CUSTOM_ROLES;
        break;
      case 'teams':
        paginationControlLocation = EventLocation.TEAMS;
        break;
      case 'userFlags':
        paginationControlLocation = EventLocation.USER_FLAGS;
        break;
      case 'payloadFilters':
        paginationControlLocation = EventLocation.PAYLOAD_FILTERS;
        break;
      default:
        return null;
    }
    const url = getPaginationUrl(pagination, direction);
    if (!url) {
      return null;
    }

    const parts = url.split('?');
    if (parts.length > 2) {
      // bad stuff
    }
    const query = parts[1] || '';
    onPageClick(query);

    switch (direction) {
      case PaginationClick.FIRST:
        return trackPaginationEvent('Pagination First Page Button Click', {
          location: paginationControlLocation,
        });
      case PaginationClick.PREV:
        return trackPaginationEvent('Pagination Previous Page Button Click', {
          location: paginationControlLocation,
        });
      case PaginationClick.NEXT:
        return trackPaginationEvent('Pagination Next Page Button Click', {
          location: paginationControlLocation,
        });
      case PaginationClick.LAST:
        return trackPaginationEvent('Pagination Last Page Button Click', {
          location: paginationControlLocation,
        });
      default:
        return null;
    }
  };

  return (
    <div className="PaginationControl">
      <div className="PaginationControl--body">
        <IconButton
          disabled={!hasUrl(PaginationClick.FIRST)}
          className={hasUrl(PaginationClick.FIRST) ? '' : 'PaginationControl--disabled'}
          onClick={handlePageClick(PaginationClick.FIRST)}
          size="small"
          icon={<Icon name="chevrons-left" size="small" />}
          aria-label={`first ${resourceKind} page`}
        />
        <IconButton
          disabled={!hasUrl(PaginationClick.PREV)}
          className={hasUrl(PaginationClick.PREV) ? '' : 'PaginationControl--disabled'}
          onClick={handlePageClick(PaginationClick.PREV)}
          size="small"
          icon={<Icon name="chevron-left" size="small" />}
          aria-label={`previous ${resourceKind} page`}
        />
        {renderPaginationText()}
        <IconButton
          disabled={!hasUrl(PaginationClick.NEXT)}
          className={hasUrl(PaginationClick.NEXT) ? '' : 'PaginationControl--disabled'}
          onClick={handlePageClick(PaginationClick.NEXT)}
          size="small"
          icon={<Icon name="chevron-right" size="small" />}
          aria-label={`next ${resourceKind} page`}
        />
        <IconButton
          disabled={!hasUrl(PaginationClick.LAST)}
          className={hasUrl(PaginationClick.LAST) ? '' : 'PaginationControl--disabled'}
          onClick={handlePageClick(PaginationClick.LAST)}
          size="small"
          icon={<Icon name="chevrons-right" size="small" />}
          aria-label={`last ${resourceKind} page`}
        />
      </div>
    </div>
  );
}

export type PaginationControlWrapperProps = PaginationControlProps & {
  children?: React.ReactNode;
  limit: number;
  renderTopPaginationControl?: boolean;
};

export function PaginationControlWrapper({
  children,
  limit,
  pagination,
  currentOffset,
  pageSize,
  resourceKind,
  isReady,
  paginationTotalCountOverride,
  onPageClick,
  renderTopPaginationControl = true,
}: PaginationControlWrapperProps) {
  const [validPageSize, setValidPageSize] = useState(20);
  useEffect(() => {
    trackPaginationEvent('view');
  }, []);

  useEffect(() => {
    setValidPageSize(pageSize > 0 ? pageSize : 20);
  }, [pageSize]);

  const paginationTotalCount = paginationTotalCountOverride || pagination?.get('totalCount');
  const isLessThanFullPage = paginationTotalCount ? paginationTotalCount <= limit : false;
  const shouldRenderBottomPaginationControl = !isLessThanFullPage && children;

  return (
    <>
      {renderTopPaginationControl &&
        (isLessThanFullPage ? (
          <CounterHeader
            className="PaginationControl-CounterHeader"
            resource={PaginationResourceLabels[resourceKind]}
            resourceCount={paginationTotalCount || 0}
          />
        ) : (
          <PaginationControl
            pagination={pagination}
            currentOffset={currentOffset}
            pageSize={validPageSize}
            resourceKind={resourceKind}
            isReady={isReady}
            onPageClick={onPageClick}
            paginationTotalCountOverride={paginationTotalCountOverride}
          />
        ))}
      {children}
      {shouldRenderBottomPaginationControl && (
        <PaginationControl
          pagination={pagination}
          currentOffset={currentOffset}
          pageSize={validPageSize}
          resourceKind={resourceKind}
          isReady={isReady}
          onPageClick={onPageClick}
          paginationTotalCountOverride={paginationTotalCountOverride}
        />
      )}
    </>
  );
}
