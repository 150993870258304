import { OrderedSet } from 'immutable';

import { ContextItemsEntities, ContextTargetKindKey, ResolveContextsResponse } from 'components/Contexts/types';
import { ImmutableServerError } from 'utils/httpUtils';
import { GenerateActionType } from 'utils/reduxUtils';

function loadContextTarget({
  viewId,
  projKey,
  envKey,
  target,
}: {
  viewId: string;
  projKey: string;
  envKey: string;
  target: ContextTargetKindKey;
}) {
  return {
    type: 'contexts/LOAD_TARGET',
    viewId,
    projKey,
    envKey,
    target,
  } as const;
}

function loadContextTargetBatchDone(
  viewId: string,
  targets: ContextTargetKindKey[],
  contextsResponse: ResolveContextsResponse,
  projKey: string,
  envKey: string,
) {
  return {
    type: 'contexts/LOAD_TARGET_BATCH_DONE',
    viewId,
    targets,
    contextsResponse,
    projKey,
    envKey,
  } as const;
}

function loadContextTargetBatchFailed(
  error: ImmutableServerError,
  viewId: string,
  targets: ContextTargetKindKey[],
  projKey: string,
  envKey: string,
) {
  return {
    type: 'contexts/LOAD_TARGET_BATCH_FAILED',
    error,
    viewId,
    targets,
    projKey,
    envKey,
  } as const;
}

function loadContextOptions(id: string, q: string, projKey: string, envKey: string, contextKind: string) {
  return {
    type: 'contexts/SEARCH_CONTEXTS',
    id,
    q,
    projKey,
    envKey,
    contextKind,
  } as const;
}

function receiveContextOptions(
  id: string,
  q: string,
  contextsResponse: ResolveContextsResponse,
  projKey: string,
  envKey: string,
  contextKind: string,
) {
  return {
    type: 'contexts/SEARCH_CONTEXTS_DONE',
    id,
    q,
    contextsResponse,
    projKey,
    envKey,
    contextKind,
  } as const;
}

function loadContextOptionsFailed(
  error: ImmutableServerError,
  id: string,
  q: string,
  projKey: string,
  envKey: string,
  contextKind: string,
) {
  return {
    type: 'contexts/SEARCH_CONTEXTS_FAILED',
    error,
    id,
    q,
    projKey,
    envKey,
    contextKind,
  } as const;
}

function clearContextOptions(id: string, contextKind: string) {
  return {
    type: 'contexts/CLEAR_CONTEXTS_OPTIONS',
    contextKind,
    id,
  } as const;
}

function resolveContexts(
  targets: OrderedSet<ContextTargetKindKey>,
  projKey: string,
  envKey: string,
  sort: string,
  searchEmails?: boolean,
) {
  return {
    type: 'contexts/RESOLVE_CONTEXTS',
    targets,
    projKey,
    envKey,
    sort,
    searchEmails,
  } as const;
}

function resolveContextsDone(
  targets: OrderedSet<ContextTargetKindKey>,
  results: ContextItemsEntities,
  contextsResponse: ResolveContextsResponse,
  projKey: string,
  envKey: string,
) {
  return {
    type: 'contexts/RESOLVE_CONTEXTS_DONE',
    projKey,
    envKey,
    targets,
    results,
    contextsResponse,
  } as const;
}

function resolveContextsFailed(
  error: ImmutableServerError,
  targets: OrderedSet<ContextTargetKindKey>,
  projKey: string,
  envKey: string,
) {
  return {
    type: 'contexts/RESOLVE_CONTEXTS_FAILED',
    error,
    projKey,
    envKey,
    targets,
  } as const;
}

function clearContextsResolutionResults(projKey: string, envKey: string) {
  return {
    type: 'contexts/CLEAR_RESOLUTION_RESULTS',
    projKey,
    envKey,
  } as const;
}

export {
  loadContextTarget,
  loadContextTargetBatchDone,
  loadContextTargetBatchFailed,
  loadContextOptions,
  receiveContextOptions,
  loadContextOptionsFailed,
  clearContextOptions,
  resolveContexts,
  resolveContextsDone,
  resolveContextsFailed,
  clearContextsResolutionResults,
};

const ContextOptionsActionCreators = {
  loadContextOptions,
  receiveContextOptions,
  loadContextOptionsFailed,
  clearContextOptions,
};

const ContextTargetsActionCreators = {
  loadContextTarget,
  loadContextTargetBatchDone,
  loadContextTargetBatchFailed,
};

const ContextResolutionActionCreators = {
  resolveContexts,
  resolveContextsDone,
  resolveContextsFailed,
  clearContextsResolutionResults,
};

export type ContextOptionsAction = GenerateActionType<typeof ContextOptionsActionCreators>;
export type ContextTargetsAction = GenerateActionType<typeof ContextTargetsActionCreators>;
export type ContextResolutionAction = GenerateActionType<typeof ContextResolutionActionCreators>;
