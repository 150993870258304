import { groupBy, mapValues, uniq } from '@gonfalon/es6-utils';
import { MigrationMetricRep } from '@gonfalon/flags';
import { capitalize } from '@gonfalon/strings';

import { AuditLogEntryListingRep, shortChangeDescriptions, titleVerbActions } from './types';

const getShortChangeDescriptionForAction = (action: string) => shortChangeDescriptions[action];

const getChangeSummariesForEntry = (entry: AuditLogEntryListingRep) => {
  const action = entry.accesses[0]?.action;
  if (action && titleVerbActions.indexOf(action) !== -1) {
    return [capitalize(entry.titleVerb)];
  }
  return entry.accesses.map((a) => getShortChangeDescriptionForAction(a?.action || 'default'));
};

const getChangeSummariesForEntryList = (entries?: AuditLogEntryListingRep[]) =>
  uniq(entries?.reduce((arr: string[], entry) => [...arr, ...getChangeSummariesForEntry(entry)], []));

const groupEntriesIntoTimeBucketsForMigrations = (
  timeSeriesData: MigrationMetricRep | undefined,
  auditLogData: AuditLogEntryListingRep[],
) => {
  // Group audit log data by the hour of its timestamp
  const groupedAuditLogs = groupBy(auditLogData, (auditLog) => {
    const timestampInMilliseconds = auditLog.date;
    const timestampInSeconds = Math.floor(timestampInMilliseconds / 1000);
    const hourInSeconds = 60 * 60;
    const hourTimestampInSeconds = Math.floor(timestampInSeconds / hourInSeconds);
    return hourTimestampInSeconds * hourInSeconds * 1000; // Convert back to milliseconds
  });

  // Bucket the time series data by time and associate with grouped audit logs
  const bucketedData = mapValues(groupBy(timeSeriesData?.series, 'time'), (series) => {
    if (!series) {
      return [];
    }
    const time = series[0].time || 0;
    const hourTimestampInSeconds = Math.floor(time / 1000 / 60 / 60) * 60 * 60;
    const associatedAuditLogs = groupedAuditLogs[hourTimestampInSeconds * 1000] || [];
    return associatedAuditLogs;
  });

  return bucketedData;
};

export { getChangeSummariesForEntry, getChangeSummariesForEntryList, groupEntriesIntoTimeBucketsForMigrations };
