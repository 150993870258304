import { clientMauUsageCutoverDate as clientMauUsageCutoverDateFunction } from '@gonfalon/dogfood-flags';
import { takeWhile } from '@gonfalon/es6-utils';
import { DateFormat } from '@gonfalon/format';
import { format, isBefore } from 'date-fns';
import qs from 'qs';

import { FilterType, TypeOfUsage, UsageType } from 'components/usage/types';
import http, { middleware as mw } from 'utils/httpUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';
import Logger from 'utils/logUtils';
import { convertMauFilters, SDKVersion } from 'utils/usageUtils';

const logger = Logger.get('UsageAPI');

const clientMauUsageCutoverDate = clientMauUsageCutoverDateFunction();
const clientMauUsageCutoverDateFormatted = format(clientMauUsageCutoverDate, DateFormat.MMM_D_YYY);

export const getStreamUsage = async (
  filters: FilterType,
  { typeOfUsage }: { typeOfUsage: UsageType },
): Promise<RechartsData> =>
  http
    .get(`/api/v2/usage/streams/${typeOfUsage}?${qs.stringify(filters)}`, { beta: true })
    .then(mw.json)
    .catch(mw.jsonError);

export const getStreamUsageBySdkVersion = async (
  filters: FilterType,
  { typeOfUsage }: { typeOfUsage: UsageType },
): Promise<RechartsData> =>
  http
    .get(`/api/v2/usage/streams/${typeOfUsage}/bysdkversion?${qs.stringify(filters)}`, { beta: true })
    .then(mw.json)
    .catch(mw.jsonError);

export const getReceivedEventUsage = async (filters: FilterType) =>
  http
    .get(`/api/v2/usage/events/received?${qs.stringify(filters)}`, { beta: true })
    .then(mw.json)
    .catch(mw.jsonError);

export const getPublishedEventUsage = async (filters: FilterType) =>
  http
    .get(`/api/v2/usage/events/published?${qs.stringify(filters)}`, { beta: true })
    .then(mw.json)
    .catch(mw.jsonError);

export const getSdkVersions = async (typeOfUsage: UsageType): Promise<SdkVersionsData> =>
  http.get(`/api/v2/usage/streams/${typeOfUsage}/sdkversions`, { beta: true }).then(mw.json).catch(mw.jsonError);

export const getMauSdks = async (typeOfUsage: UsageType, filters: FilterType): Promise<SdkTypeData> =>
  http
    .get(`/api/v2/usage/mau/sdks?sdktype=${typeOfUsage}&${qs.stringify(filters)}`, { beta: true })
    .then(mw.json)
    .catch(mw.jsonError);

type MauUsageOptions = { renderTotal?: boolean };

type GetMauUsageType = (filters: FilterType, options: MauUsageOptions) => Promise<RechartsData>;

export type RechartsSeriesItem = {
  [key: string]: string | number | undefined;
  time?: number;
};

export type SdkVersionsType = {
  sdk: string;
  version: string;
};

export type RechartsData = {
  _links: ImmutableMap<{
    parent: Link;
    self: Link;
  }>;
  metadata: SDKVersion[];
  series: RechartsSeriesItem[];
};

export type SdkVersionsData = {
  _links: ImmutableMap<{
    parent: Link;
    self: Link;
  }>;
  sdkVersions: SdkVersionsType[];
};

export type SdkTypeData = {
  _links: ImmutableMap<{
    parent: Link;
    self: Link;
  }>;
  sdks: string[];
};

const mergeLegacyNewMauUsage = (legacyData: RechartsData, newData: RechartsData): RechartsData => {
  const newDataSeries = newData.series.map((seriesItem) => {
    const mergedSeriesItem: RechartsSeriesItem = { time: 0 };
    Object.keys(seriesItem).forEach((key) => {
      const seriesItemValue = seriesItem[key] as number;
      if (key === 'time') {
        mergedSeriesItem.time = seriesItemValue;
      } else {
        const mergedKey = parseInt(key, 10) + 2; // magic number 2 referring to legacy data mobile & brower count
        mergedSeriesItem[mergedKey] = seriesItemValue;
      }
    });

    return mergedSeriesItem;
  });

  return {
    _links: newData._links,
    metadata: [...legacyData.metadata, ...newData.metadata],
    series: [...legacyData.series, ...newDataSeries],
  };
};

const getMauUsageLegacy = async (filters: FilterType) => {
  const { group, sdktype, from, to, tz } = filters;
  const legacyFilters = { group: group || sdktype, from, to, tz };
  const url = `/api/v2/usage/mau/bycategory?${qs.stringify(legacyFilters)}`;

  logger.log(`Fetching legacy mau usage url: ${url}`);

  const data = await http.get(url, { beta: true }).then(mw.json).catch(mw.jsonError);

  logger.log(`Filtering legacy data up to but excluding ${clientMauUsageCutoverDateFormatted}`);

  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  // return only up to primary or secondary cutover date
  const dataBeforeCutover = takeWhile(
    data.series,
    (s: RechartsSeriesItem) => s.time! < clientMauUsageCutoverDate,
  ); /* eslint-enable @typescript-eslint/no-non-null-assertion */
  return { ...data, series: dataBeforeCutover };
};

export const getMauUsage: GetMauUsageType = async (filters, { renderTotal }) => {
  const convertedFilters = convertMauFilters(filters, renderTotal);
  const url = `/api/v2/usage/mau?${qs.stringify(convertedFilters)}`;
  logger.log(`Fetching new mau usage url: ${url}`);

  let newData = await http.get(url, { beta: true }).then(mw.json).catch(mw.jsonError);

  const { from, sdk, sdktype, project, environment } = convertedFilters;
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  const fromDate = new Date(parseInt(from!, 10)); /* eslint-enable @typescript-eslint/no-non-null-assertion */

  // returns true if fromDate is before the cutover date
  const isFromDateBeforeCutover = isBefore(fromDate, clientMauUsageCutoverDate);
  const mixedData = sdktype === TypeOfUsage.CLIENT && !sdk && !project && !environment && isFromDateBeforeCutover;

  if (mixedData) {
    const legacyData = await getMauUsageLegacy(filters);

    if (legacyData.series.length > 0) {
      const { series } = newData;

      logger.log(`Filtering new data from and including ${clientMauUsageCutoverDateFormatted}`);

      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      // remove data prior to cutover date
      const primaryDateIndex = series.findIndex(
        (s: RechartsSeriesItem) => s.time! >= clientMauUsageCutoverDate,
      ); /* eslint-enable @typescript-eslint/no-non-null-assertion */

      if (primaryDateIndex < 0) {
        logger.error(
          `There's no new client mau usage data after ${clientMauUsageCutoverDateFormatted}. The server data might be corrupted. Response: ${JSON.stringify(
            series,
          )}`,
        );
        return newData;
      } else {
        newData = { ...newData, series: series.slice(primaryDateIndex) };
        newData = mergeLegacyNewMauUsage(legacyData, newData);
      }
    }
  }
  return newData;
};

export const getFlagEvaluations = async (
  filters: FilterType,
  { projKey, envKey, flagKey }: { projKey?: string; envKey?: string; flagKey?: string },
) =>
  http
    .get(`/api/v2/usage/evaluations/${projKey}/${envKey}/${flagKey}?${qs.stringify(filters)}`, { beta: true })
    .then(mw.json)
    .catch(mw.jsonError);
