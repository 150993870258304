export const parseConfluenceLink = (link: string) => {
  const url = new URL(link);
  const linkParser = url.pathname.split('/');
  return {
    hostname: url.hostname,
    spaceKey: linkParser[3],
    contentID: linkParser[5],
    pageTitle: linkParser[6].replaceAll('+', ' '),
  };
};
