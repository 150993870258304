import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useDispatch, useSelector } from 'react-redux';
import { CustomSelectProps } from '@gonfalon/launchpad-experimental';
import { Set } from 'immutable';

import { fetchRoles as fetchRolesAction } from 'actions/roles';
import { rolesSelector } from 'reducers/roles';
import { ready } from 'utils/reduxUtils';
import { Role } from 'utils/roleUtils';

import SelectRole from './SelectRole';

export type SelectRoleContainerProps = Partial<CustomSelectProps> & {
  disabled?: boolean;
  selectedRoleKeys?: string[];
  selectedRoles?: Role[];
  selectedRoleIDs?: string[];
  filterRoles?: Set<string>;
  onChange(roles: Role[] | Role): void;
  showDetailsLink?: boolean;
  isMulti?: boolean;
};

export const useRedux = () => {
  const dispatch = useDispatch();
  const fetchRoles = () => dispatch(fetchRolesAction());
  const roles = useSelector(rolesSelector);
  const isReady = ready(roles);

  return {
    fetchRoles,
    isReady,
    roles: roles.get('entities'),
  };
};

/* eslint-disable import/no-default-export */
export default function SelectRoleContainer({
  disabled,
  selectedRoles,
  selectedRoleKeys,
  selectedRoleIDs,
  onChange,
  filterRoles,
  showDetailsLink,
  isMulti = true,
  ...props
}: SelectRoleContainerProps) {
  const { fetchRoles, isReady, roles } = useRedux();
  const roleKeysToRoles = selectedRoleKeys
    ?.map((roleKey: string) => roles.get(roleKey))
    .filter((r?: Role): r is Role => !!r);
  const roleIDsToRoles = roles
    .filter((r: Role) => selectedRoleIDs?.includes(r._id))
    .toList()
    .toArray();

  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <SelectRole
      id="custom-role"
      disabled={disabled}
      isLoading={!isReady}
      roles={roles}
      selectedRoles={selectedRoles || roleKeysToRoles || roleIDsToRoles}
      filterRoles={filterRoles}
      onChange={onChange}
      fetchRoles={fetchRoles}
      showDetailsLink={showDetailsLink}
      isMulti={isMulti}
      {...props}
    />
  );
}
