import { Map, OrderedMap } from 'immutable';
import { combineReducers } from 'redux';

import actionTypes from 'actionTypes/approvals';
import { GlobalState } from 'reducers';
import registry from 'reducers/registry';
import { ApprovalRequest } from 'utils/approvalsUtils';

import { getKeyFromFlagProjEnvKeys } from './utils/hashing';
import { createRequestReducerByKey } from './createRequestReducer';

export type AllFlagConfigApprovalRequestsState = Map<string, FlagConfigApprovalRequestsState>;
export type FlagConfigApprovalRequestsState = OrderedMap<string, ApprovalRequest>;

export type ApprovalsActionType = {
  type: string;
  projKey: string;
  envKey: string;
  flagKey: string;
  approvals?: OrderedMap<string, ApprovalRequest>;
  approvalById?: ApprovalRequest;
  /**
   * updatedApproval stores any created approval, reviewed approval, or applied approval
   */
  updatedApproval?: ApprovalRequest;
  deletedId?: string;
  shouldNotify?: boolean;
};

const initialFlagConfigApprovalsState: AllFlagConfigApprovalRequestsState = Map();

export const flagConfigApprovalRequests = (state = initialFlagConfigApprovalsState, action: ApprovalsActionType) => {
  const key = getKeyFromFlagProjEnvKeys({
    flagKey: action.flagKey,
    projKey: action.projKey,
    envKey: action.envKey,
  });
  switch (action.type) {
    case actionTypes.FETCH_FLAG_CONFIG_APPROVAL_REQUESTS:
      return state;
    case actionTypes.FETCH_FLAG_CONFIG_APPROVAL_REQUESTS_DONE:
      return state.setIn([key], action.approvals);
    case actionTypes.FETCH_FLAG_CONFIG_APPROVAL_REQUESTS_FAILED:
      return state;
    case actionTypes.CREATE_APPROVAL_REQUEST_DONE: {
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      const updatedState = state.setIn(
        [key, action.updatedApproval!._id],
        action.updatedApproval!,
      ); /* eslint-enable @typescript-eslint/no-non-null-assertion */
      const sortedApprovals = updatedState.getIn([key]).sortBy(
        (approval: ApprovalRequest) => approval.get('creationDate'),
        (a: number, b: number) => b - a,
      );
      return updatedState.setIn([key], sortedApprovals);
    }
    case actionTypes.DELETE_FLAG_CONFIG_APPROVAL_REQUEST_DONE:
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      return state.deleteIn([key, action.deletedId!]); /* eslint-enable @typescript-eslint/no-non-null-assertion */
    case actionTypes.APPLY_FLAG_CONFIG_APPROVAL_REQUEST_DONE:
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      return state.setIn(
        [key, action.updatedApproval!.getId()],
        action.updatedApproval!,
      ); /* eslint-enable @typescript-eslint/no-non-null-assertion */
    case actionTypes.REVIEW_FLAG_CONFIG_APPROVAL_REQUEST_DONE:
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      const existingConflicts = state
        .getIn([key, action.updatedApproval!.getId()])
        ?.getConflicts(); /* eslint-enable @typescript-eslint/no-non-null-assertion */
      if (existingConflicts) {
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        const updatedApprovalWithConflicts = action.updatedApproval!.set(
          'conflicts',
          existingConflicts,
        ); /* eslint-enable @typescript-eslint/no-non-null-assertion */
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        return state.setIn(
          [key, action.updatedApproval!.getId()],
          updatedApprovalWithConflicts,
        ); /* eslint-enable @typescript-eslint/no-non-null-assertion */
      }

      return state;
    case actionTypes.FETCH_FLAG_CONFIG_APPROVAL_REQUEST_BY_ID_DONE: {
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      const updatedState = state.setIn(
        [key, action.approvalById!._id],
        action.approvalById,
      ); /* eslint-enable @typescript-eslint/no-non-null-assertion */
      const sortedApprovals = updatedState.getIn([key]).sortBy(
        (approval: ApprovalRequest) => approval.get('creationDate'),
        (a: number, b: number) => b - a,
      );
      return updatedState.setIn([key], sortedApprovals);
    }
    default:
  }
  return state;
};

const flagConfigApprovalRequestsAPIRequest = createRequestReducerByKey(
  [
    actionTypes.FETCH_FLAG_CONFIG_APPROVAL_REQUESTS,
    actionTypes.FETCH_FLAG_CONFIG_APPROVAL_REQUESTS_DONE,
    actionTypes.FETCH_FLAG_CONFIG_APPROVAL_REQUESTS_FAILED,
  ],
  (action) => getKeyFromFlagProjEnvKeys({ flagKey: action.flagKey, projKey: action.projKey, envKey: action.envKey }),
);

const flagConfigApprovalRequestByIdAPIRequest = createRequestReducerByKey(
  [
    actionTypes.FETCH_FLAG_CONFIG_APPROVAL_REQUEST_BY_ID,
    actionTypes.FETCH_FLAG_CONFIG_APPROVAL_REQUEST_BY_ID_DONE,
    actionTypes.FETCH_FLAG_CONFIG_APPROVAL_REQUEST_BY_ID_FAILED,
  ],
  (action) => getKeyFromFlagProjEnvKeys({ flagKey: action.flagKey, projKey: action.projKey, envKey: action.envKey }),
);

const reviewFlagConfigApprovalRequestAPIRequest = createRequestReducerByKey(
  [
    actionTypes.REVIEW_FLAG_CONFIG_APPROVAL_REQUEST,
    actionTypes.REVIEW_FLAG_CONFIG_APPROVAL_REQUEST_DONE,
    actionTypes.REVIEW_FLAG_CONFIG_APPROVAL_REQUEST_FAILED,
  ],
  (action) => getKeyFromFlagProjEnvKeys({ flagKey: action.flagKey, projKey: action.projKey, envKey: action.envKey }),
);

export const flagApprovalRequests = combineReducers({
  flagConfigApprovalRequestByIdAPIRequest,
  flagConfigApprovalRequestsAPIRequest,
  reviewFlagConfigApprovalRequestAPIRequest,
  flagConfigApprovalRequests,
});

const flagApprovalRequestsSelector = (state: GlobalState) => state.flagApprovalRequests;

export const flagConfigApprovalRequestsAPIRequestSelector = (
  state: GlobalState,
  { flagKey, projKey, envKey }: { flagKey: string; projKey: string; envKey: string },
) => {
  const key = getKeyFromFlagProjEnvKeys({ flagKey, projKey, envKey });
  return flagApprovalRequestsSelector(state).flagConfigApprovalRequestsAPIRequest.get(key);
};

export const flagConfigApprovalRequestByIdAPIRequestSelector = (
  state: GlobalState,
  { flagKey, projKey, envKey }: { flagKey: string; projKey: string; envKey: string },
) => {
  const key = getKeyFromFlagProjEnvKeys({ flagKey, projKey, envKey });
  return flagApprovalRequestsSelector(state).flagConfigApprovalRequestByIdAPIRequest.get(key);
};

export const reviewFlagConfigApprovalRequestAPIRequestSelector = (
  state: GlobalState,
  { flagKey, projKey, envKey }: { flagKey: string; projKey: string; envKey: string },
) => {
  const key = getKeyFromFlagProjEnvKeys({ flagKey, projKey, envKey });
  return flagApprovalRequestsSelector(state).reviewFlagConfigApprovalRequestAPIRequest.get(key);
};

export const flagConfigApprovalRequestsSelector = (
  state: GlobalState,
  { flagKey, projKey, envKey }: { flagKey: string; projKey: string; envKey: string },
) => {
  const key = getKeyFromFlagProjEnvKeys({ flagKey, projKey, envKey });
  return flagApprovalRequestsSelector(state).flagConfigApprovalRequests.get(key);
};

registry.addReducers({
  flagApprovalRequests,
});
