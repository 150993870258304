import { isExperimentArchivingEnabled } from '@gonfalon/dogfood-flags';
import type { ExperimentTimeSeriesResults } from '@gonfalon/experiments';

import http, { middleware } from 'utils/httpUtils';
import { addQueryParams } from 'utils/urlUtils';

import { FunnelExperimentResult } from '../hooks/useFunnelExperimentResults';
import {
  CreateRandomizationUnitPayload,
  ExperimentationSettings,
  ExperimentExpandOptions,
  ExperimentPercentileResults,
  ExperimentResults,
  ExperimentStatus,
  ExperimentSummaryResultsType,
  ExperimentV2,
  FunnelMetric,
  Link,
} from '../types';

import {
  ARCHIVE_EXPERIMENT,
  ExperimentBody,
  ExperimentPatchBody,
  RESTORE_EXPERIMENT,
  START_ITERATION,
  UPDATE_NAME,
  UpdateName,
} from './api.types';

/**
 * Fetcher fetches an experiment's summary results from the v2 API
 */
export async function getExperimentSummaryResults(
  flagkey: string,
  projectKey: string,
  environmentKey: string,
  metricKey: string,
): Promise<ExperimentSummaryResultsType> {
  const url = `/api/v2/flags/${projectKey}/${flagkey}/experiments/${environmentKey}/${metricKey}`;
  const request = await http.get(url, { beta: true });

  const json: ExperimentSummaryResultsType = await middleware.json(request).catch(middleware.jsonError);
  return {
    totals: json.totals,
    stats: json.stats,
  };
}

/**
 * Fetcher creates an experiment against the V2 API
 */
export async function createExperiment(
  projectKey: string,
  flagKey: string,
  metricKey: string,
  options: {
    eventLocation: string;
    baselineIdx?: number;
  },
) {
  const body = {
    flagKey,
    metricKey,
    eventLocation: options.eventLocation,
    baselineIdx: options.baselineIdx,
  };

  const request = await http.post(`/api/v2/projects/${projectKey}/experiments`, {
    body,
    beta: true,
  });

  const json: ExperimentV2 = await middleware.json(request).catch(middleware.jsonError);
  return json;
}

export type ExperimentListFilter = Partial<{
  flagKey: string;
  status: ExperimentStatus;
  query: string;
  kind: 'experiment' | 'measuredRollout';
}>;

export type ExperimentParams = {
  filter?: ExperimentListFilter;
  expand?: ExperimentExpandOptions[];
  limit?: number;
  offset?: number;
  lifecycleState?: string;
};

/**
 * Fetcher fetches a list of experiments from the v2 API
 *
 * @param projectKey - the key of the project the experiments belong to
 * @param environmentKey - the env key the experiments exist in
 * @param params.filter {ExperimentListFilter} - optional query parameters
 * @param params.limit - limit of experiments to fetch
 * @param params.lifecycleState - comma separated string of archived state, "active", "archived", or both "active,archived"
 */
export async function getExperiments(
  projectKey: string,
  environmentKey: string,
  params?: ExperimentParams,
  options?: { signal?: AbortSignal },
) {
  let endpoint = `/api/v2/projects/${projectKey}/environments/${environmentKey}/experiments`;

  if (params) {
    endpoint = addParams(endpoint, params);
  }

  const request = await http.get(endpoint, { beta: true, signal: options?.signal });

  const payload: {
    items: ExperimentV2[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    total_count: number;
    _links: { first?: Link; prev?: Link; next?: Link; last?: Link };
  } = await middleware.json(request).catch(middleware.jsonError);

  return payload;
}

// appends a filter query param i.e. ?filter=flagKey:my-flag,status=running
export function addParams(endpoint: string, { filter, expand, limit, offset, lifecycleState }: ExperimentParams) {
  const params: { filter?: string; expand?: string; limit?: number; offset?: number; lifecycleState?: string } = {};

  if (filter) {
    const filterStrs = Object.entries(filter).reduce((accumulator, entry) => {
      const [key, value] = entry;
      if (value) {
        return accumulator.concat(`${key}:${encodeURIComponent(value)}`);
      }
      return accumulator;
    }, [] as string[]);

    params.filter = filterStrs.join(',');
  }

  if (limit) {
    params.limit = limit;
  }

  if (offset) {
    params.offset = offset;
  }

  if (expand) {
    params.expand = expand.join(',');
  }

  if (isExperimentArchivingEnabled() && lifecycleState) {
    params.lifecycleState = lifecycleState;
  }

  return addQueryParams(endpoint, params);
}

/**
 * Fetcher fetches a single new data model experiment from the v2 API
 */
export async function getExperiment(
  projectKey: string,
  environmentKey: string,
  experimentKey: string,
  expand?: ExperimentExpandOptions[],
) {
  let endpoint = `/api/v2/projects/${projectKey}/environments/${environmentKey}/experiments/${experimentKey}`;

  if (expand) {
    endpoint = `${endpoint}?expand=${expand.join(',')}`;
  }

  const request = await http.get(endpoint, { beta: true });
  const payload: ExperimentV2 = await middleware.json(request).catch(middleware.jsonError);
  return payload;
}

/**
 * Fetcher creates an experiment with an iteration
 *
 * @param projectKey - the key of the project the experiment belongs to
 * @param environmentKey - the env key the experiments exist in
 * @param body - the new experiment payload
 */
export async function createExperimentWithIteration(projectKey: string, environmentKey: string, body: ExperimentBody) {
  const endpoint = `/api/v2/projects/${projectKey}/environments/${environmentKey}/experiments`;
  const request = await http.post(endpoint, {
    body,
    beta: true,
  });

  const json = await middleware.json(request).catch(middleware.jsonError);

  return json;
}

export async function updateExperimentWithIteration(
  projectKey: string,
  environmentKey: string,
  experimentKey: string,
  body: ExperimentPatchBody[],
  comment?: string,
) {
  const endpoint = `/api/v2/projects/${projectKey}/environments/${environmentKey}/experiments/${experimentKey}`;

  const commentPayload = comment ? { comment } : {};
  const request = await http.patch(endpoint, {
    body: { instructions: body, ...commentPayload },
    beta: true,
  });

  const json: ExperimentV2 = await middleware.json(request).catch(middleware.jsonError);

  return json;
}

export async function setWinningVariation(
  projectKey: string,
  environmentKey: string,
  experimentKey: string,
  treatmentId: string,
) {
  const endpoint = `/api/v2/projects/${projectKey}/environments/${environmentKey}/experiments/${experimentKey}`;
  const request = await http.patch(endpoint, {
    body: {
      instructions: [
        {
          kind: 'stopIteration',
          winningTreatmentId: treatmentId,
          winningReason: 'Leading variation shipped by system',
        },
      ],
    },
    beta: true,
  });

  const json = await middleware.json(request).catch(middleware.jsonError);

  return json;
}

export type Attribute = {
  name: string;
  values: AttributeValue[];
};

export type AttributeValue = string | number | boolean;

export type GetExperimentResultsOptions = {
  tz?: string;
  sumMetrics?: boolean;
  defaultMissingMetrics?: boolean;
  attributes?: Attribute[];

  // temporary query bool for validating snowflake results
  snowflake?: boolean;
};

export type GetExperimentPercentileResultsOptions = GetExperimentResultsOptions & {
  percentile?: number;
  confidence?: number;
};

export type GetExperimentTimeseriesOptions = GetExperimentResultsOptions & {
  start?: number;
  end?: number;
};

/**
 * Fetcher gets new data model experiment results
 *
 * @param projectKey - the key of the project the experiment belongs to
 * @param environmentKey - the env key the experiments exist in
 * @param experimentKey - the key of the experiment
 * @param metricKey - the key of the metric in the experiment
 * @param iterationId - the id of the iteration you want results from
 * @param options.tz - timezone
 * @param options.sumMetrics - Use the average sum of user events when calculating treatment effect
 * @param options.defaultMissingMetrics - Include users with no events when calculating treatment effect
 * @param options.attributes - which attribute(s) and values to slice by
 * @param signal - abort signal
 */
export async function getExperimentResults(
  projectKey: string,
  environmentKey: string,
  experimentKey: string,
  metricKey: string,
  iterationId: string,
  options: GetExperimentResultsOptions = {},
  signal?: AbortSignal,
) {
  const { attributes, ...otherOptions } = options;
  const queryParams = new URLSearchParams(
    Object.entries({
      iterationId,
      attributes: JSON.stringify(attributes),
      // we can hard-code expand: traffic here because the server doesn't have to do any extra work to populate this field
      // eventually expanding traffic may have performance implications, at which time we will want to refactor this, so we
      // only expand it when we absolutely need it.
      expand: 'traffic',
      ...otherOptions,
    })
      .filter(([, v]) => v !== undefined)
      .map(([k, v]) => [k, String(v)]),
  );
  const endpoint = `/api/v2/projects/${projectKey}/environments/${environmentKey}/experiments/${experimentKey}/metrics/${metricKey}/results?${queryParams}`;

  const request = await http.get(endpoint, { beta: true, signal });
  const payload: ExperimentResults = await middleware.json(request).catch(middleware.jsonError);
  return payload;
}

type ExperimentMetricGroupResultsResponse = {
  metrics: Array<{
    metric: FunnelMetric;
    results: ExperimentResults;
  }>;
};

/**
 * getExperimentMetricGroupResults gets experiment results for a metric group
 *
 * @param projectKey - the key of the project the experiment belongs to
 * @param environmentKey - the env key the experiments exist in
 * @param experimentKey - the key of the experiment
 * @param metricGroupKey - the key of the metric group in the experiment
 * @param iterationId - the id of the iteration you want results from
 * @param options.tz - timezone
 * @param options.sumMetrics - Use the average sum of user events when calculating treatment effect
 * @param options.defaultMissingMetrics - Include users with no events when calculating treatment effect
 * @param options.attributes - which attribute(s) and values to slice by
 */
export async function getExperimentMetricGroupResults(
  projectKey: string,
  environmentKey: string,
  experimentKey: string,
  metricGroupKey: string,
  iterationId: string,
  options: GetExperimentResultsOptions = {},
): Promise<FunnelExperimentResult[]> {
  const { attributes, ...otherOptions } = options;
  const queryParams = new URLSearchParams(
    Object.entries({ iterationId, attributes: JSON.stringify(attributes), ...otherOptions })
      .filter(([, v]) => v !== undefined)
      .map(([k, v]) => [k, String(v)]),
  );
  const endpoint = `/api/v2/projects/${projectKey}/environments/${environmentKey}/experiments/${experimentKey}/metric-groups/${metricGroupKey}/results?${queryParams}`;

  const request = await http.get(endpoint, { beta: true });
  const payload: ExperimentMetricGroupResultsResponse = await middleware.json(request).catch(middleware.jsonError);
  return payload.metrics.map(({ metric, results }) => ({ ...metric, ...results }));
}

/**
 * Fetcher gets new data model experiment results
 *
 * @param projectKey - the key of the project the experiment belongs to
 * @param environmentKey - the env key the experiments exist in
 * @param experimentKey - the key of the experiment
 * @param metricKey - the key of the metric in the experiment
 * @param iterationId - the id of the iteration you want results from
 * @param options.tz - timezone
 * @param options.sumMetrics - Use the average sum of user events when calculating treatment effect
 * @param options.defaultMissingMetrics - Include users with no events when calculating treatment effect
 * @param options.attributes - which attribute(s) to slice by
 * @param options.attributeValues - which attribute values to slice by
 * @param options.percentile - the percentile to use for experiment results (0-100)
 * @param options.confidence - the confidence percentage for percentile experiment results (0-100)
 * @param signal - abort signal
 */
export async function getExperimentPercentileResults(
  projectKey: string,
  environmentKey: string,
  experimentKey: string,
  metricKey: string,
  iterationId: string,
  options: GetExperimentPercentileResultsOptions = {},
  signal?: AbortSignal,
) {
  const queryParams = new URLSearchParams(Object.entries({ iterationId, ...options }).map(([k, v]) => [k, String(v)]));
  const endpoint = `/api/v2/projects/${projectKey}/environments/${environmentKey}/experiments/${experimentKey}/metrics/${metricKey}/results/quantile?${queryParams}`;

  const request = await http.get(endpoint, { beta: true, signal });
  const payload: ExperimentPercentileResults = await middleware.json(request).catch(middleware.jsonError);
  return payload;
}

/**
 * Fetcher gets historical experimental results
 *
 * @param projectKey - the key of the project the experiment belongs to
 * @param environmentKey - the env key the experiments exist in
 * @param experimentKey - the key of the experiment
 * @param metricKey - the key of the metric in the experiment
 * @param iterationId - the id of the iteration you want results from
 * @param options - possible options for fetching historical results,
 * @param signal - abort signal
 */
export async function getExperimentCredibleIntervalTimeseriesResults(
  projectKey: string,
  environmentKey: string,
  experimentKey: string,
  metricKey: string,
  iterationId: string,
  options?: GetExperimentTimeseriesOptions,
  signal?: AbortSignal,
) {
  const queryParams = new URLSearchParams(Object.entries({ iterationId, ...options }).map(([k, v]) => [k, String(v)]));
  const endpoint = `/api/v2/projects/${projectKey}/environments/${environmentKey}/experiments/${experimentKey}/metrics/${metricKey}/results/timeseries?${queryParams}`;

  const request = await http.get(endpoint, { beta: true, signal });
  const payload: ExperimentTimeSeriesResults = await middleware.json(request).catch(middleware.jsonError);
  return payload;
}

export async function updateExperimentName(
  projectKey: string,
  environmentKey: string,
  experimentKey: string,
  name: string,
) {
  const comment = 'Experiment details - update experiment name';

  const body: UpdateName = {
    kind: UPDATE_NAME,
    value: name,
  };

  return updateExperimentWithIteration(projectKey, environmentKey, experimentKey, [body], comment);
}

export async function getAllExperiments() {
  const endpoint = '/internal/account/experiments';
  const request = await http.get(endpoint, { beta: true });

  const payload: Array<Pick<ExperimentV2, '_creationDate' | '_maintainerId' | '_id' | 'key' | 'name'>> =
    await middleware.json(request).catch(middleware.jsonError);

  return payload;
}

export async function archiveExperiment(projectKey: string, environmentKey: string, experimentKey: string) {
  const endpoint = `/api/v2/projects/${projectKey}/environments/${environmentKey}/experiments/${experimentKey}`;

  const body: ExperimentPatchBody[] = [
    {
      kind: ARCHIVE_EXPERIMENT,
    },
  ];

  const request = await http.patch(endpoint, {
    body: { instructions: body },
    beta: true,
  });

  const json: ExperimentV2 = await middleware.json(request).catch(middleware.jsonError);
  return json;
}

export async function restoreExperiment(projectKey: string, environmentKey: string, experimentKey: string) {
  const endpoint = `/api/v2/projects/${projectKey}/environments/${environmentKey}/experiments/${experimentKey}`;

  const body: ExperimentPatchBody[] = [
    {
      kind: RESTORE_EXPERIMENT,
    },
  ];

  const request = await http.patch(endpoint, {
    body: { instructions: body },
    beta: true,
  });

  const json: ExperimentV2 = await middleware.json(request).catch(middleware.jsonError);
  return json;
}

export async function startExperimentIteration(
  projectKey: string,
  environmentKey: string,
  experimentKey: string,
  changeJustification: string,
) {
  const endpoint = `/api/v2/projects/${projectKey}/environments/${environmentKey}/experiments/${experimentKey}`;

  const body: ExperimentPatchBody[] = [
    {
      kind: START_ITERATION,
      changeJustification,
    },
  ];

  const request = await http.patch(endpoint, {
    body: { instructions: body },
    beta: true,
  });

  const json: ExperimentV2 = await middleware.json(request).catch(middleware.jsonError);
  return json;
}

export async function getProjectExperimentationSettings(projectKey: string) {
  const endpoint = `/api/v2/projects/${projectKey}/experimentation-settings`;

  const request = await http.get(endpoint, {
    beta: true,
  });

  const json: ExperimentationSettings = await middleware.json(request).catch(middleware.jsonError);
  return json;
}

/**
 * Fetcher updates the project experimentation settings
 *
 * @param projectKey - the key of the project the experiment belongs to
 * @param body - the new experiment settings payload
 */
export async function updateProjectExperimentationSettings(
  projectKey: string,
  body: { randomizationUnits: CreateRandomizationUnitPayload[] },
) {
  const endpoint = `/api/v2/projects/${projectKey}/experimentation-settings`;

  const request = await http.put(endpoint, {
    body,
    beta: true,
  });

  const json: ExperimentationSettings = await middleware.json(request).catch(middleware.jsonError);
  return json;
}

/**
 * Fetcher adds a new randomization unit to the project experimentation settings
 *
 * @param projectKey - the key of the project the experiment belongs to
 * @param newRandomizationUnit - the new randomization unit to add
 */
export async function addRandomizationUnitToExperimentSettings(
  projectKey: string,
  newRandomizationUnit: CreateRandomizationUnitPayload | undefined,
) {
  const currentSettings = await getProjectExperimentationSettings(projectKey);
  const randomizationUnits = currentSettings.randomizationUnits || [];

  const currentUnits: CreateRandomizationUnitPayload[] = randomizationUnits
    .filter((unit) => unit.randomizationUnit !== newRandomizationUnit?.randomizationUnit)
    .map((unit) => ({
      ...unit,
      default: !newRandomizationUnit?.default && unit.default === true,
    }));

  if (!newRandomizationUnit) {
    return updateProjectExperimentationSettings(projectKey, {
      randomizationUnits: currentUnits,
    });
  }

  return updateProjectExperimentationSettings(projectKey, {
    randomizationUnits: [newRandomizationUnit, ...currentUnits],
  });
}

/**
 * Fetcher removes a randomization unit from the project experimentation settings
 *
 * @param projectKey - the key of the project the experiment belongs to
 * @param randomizationUnitKey - the key of the randomization unit to remove
 *
 */
export async function removeRandomizationUnitFromExperimentSettings(projectKey: string, randomizationUnitKey: string) {
  const currentSettings = await getProjectExperimentationSettings(projectKey);
  const randomizationUnits = currentSettings.randomizationUnits || [];

  const newUnits = randomizationUnits
    .filter((unit) => unit.randomizationUnit !== randomizationUnitKey)
    .map((unit) => ({
      ...unit,
      default: unit.default === true,
    }));

  return updateProjectExperimentationSettings(projectKey, {
    randomizationUnits: newUnits,
  });
}
