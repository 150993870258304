import { forwardRef } from 'react';
import cx from 'clsx';

import './styles.css';

export enum CardWidth {
  full = 'full',
  half = 'half',
  auto = 'auto',
}

export type CardProps = {
  isShadowed?: boolean;
  isNewStyle?: boolean;
  disabled?: boolean;
  width?: CardWidth;
  className?: string;
  children?: React.ReactNode;
};

// eslint-disable-next-line prefer-arrow-callback
export const Card = forwardRef<HTMLDivElement, CardProps>(function Card(
  {
    isShadowed = false,
    isNewStyle = false,
    width = CardWidth.full,
    disabled = false,
    className = '',
    children,
  }: CardProps,
  ref,
) {
  return (
    <div
      ref={ref}
      className={cx('Card', `Card--${width}`, className, {
        'Card--shadowed': isShadowed,
        'Card--newStyle': isNewStyle,
        'Card--disabled': disabled,
      })}
    >
      {children}
    </div>
  );
});
