import { AnalyticEventData, createTrackerForCategory } from '@gonfalon/analytics';
import { syncedSegmentRelayVersion } from '@gonfalon/dogfood-flags';
import { forEach, isEmpty, omit } from '@gonfalon/es6-utils';
import { endOfToday } from 'date-fns';
import { Map, OrderedMap } from 'immutable';

import { DataOnlySeries } from 'components/ui/datavis/TimeSeriesComponent';
import { FilterType, TypeOfUsage } from 'components/usage/types';
import { RechartsSeriesItem } from 'sources/UsageAPI';
import { createUsageShortcuts } from 'utils/dateUtils';
import { EnvironmentProps } from 'utils/environmentUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';
import { Project } from 'utils/projectUtils';

export type SDKVersion = {
  sdk: string;
  version: string;
  source?: TypeOfUsage;
};

export type SDKType = {
  [key: string]: SDKVersion[];
};

type FlatArrayOfOptions = {
  name: string;
  value: string;
  sdk?: string;
  version?: string;
  groupHeader?: boolean;
  nested?: boolean;
  projKey?: string;
};

export const convertGroupedSDKsToFlatArray = (SDKs: SDKType) => {
  const options: FlatArrayOfOptions[] = [];

  Object.values(SDKs).forEach((sdk: SDKVersion[]) =>
    sdk.forEach((type: SDKVersion, i: number) => {
      if (i === 0) {
        options.push({
          name: type.sdk === 'Unknown' ? `${type.sdk} SDK` : type.sdk,
          value: `${type.sdk}`,
          sdk: type.sdk,
          groupHeader: true,
        });
      }

      options.push({
        name: type.version === 'Unknown' ? `${type.version} version` : type.version,
        value: `${type.sdk}--${type.version}`,
        sdk: type.sdk,
        version: type.version,
        nested: true,
      });
    }),
  );

  return options;
};

type ProjectAndEnvProps = {
  _access: null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _links: any;
  key: string;
  name: string;
  tags: string[];
  defaultClientSideAvailability: {
    usingMobileKey: boolean;
    usingEnvironmentId: boolean;
  };
  environments: string[];
};

export const groupProjectsAndEnvironments = (
  projects: OrderedMap<string, Project>,
  environments: Map<string, EnvironmentProps>,
) => {
  const options: FlatArrayOfOptions[] = [];
  Object.values(projects.toJS()).forEach((proj: ProjectAndEnvProps) =>
    proj.environments.forEach((envId: string, i: number) => {
      if (i === 0) {
        options.push({
          name: proj.name,
          value: proj.key,
          groupHeader: true,
        });
      }

      const environment: EnvironmentProps | undefined = environments.get(envId);

      if (environment) {
        options.push({
          name: environment.name,
          value: environment._id,
          projKey: proj.key,
          nested: true,
        });
      }
    }),
  );

  return options;
};

export type createUsageShortcutsProps = {
  [key: string]:
    | {
        label: string;
        dateRange: Date[];
      }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | any;
};

export const getFromDate = (filterValue: number | undefined, defaultFromDate: string) => {
  const createUsageShortcutsArray: createUsageShortcutsProps[] = createUsageShortcuts();
  return (
    filterValue ||
    (defaultFromDate &&
      createUsageShortcutsArray
        .filter((sc: createUsageShortcutsProps) => sc[defaultFromDate])[0]
        [defaultFromDate].dateRange[0].valueOf())
  );
};

export const convertMauFilters = (filters: FilterType, renderTotal?: boolean) => {
  const finalFilters = filters;

  if (filters.mauSdk) {
    finalFilters.sdk = filters.mauSdk;
  }

  if (filters.mauVersion) {
    finalFilters.version = filters.mauVersion;
  }

  if (filters.mauProj) {
    finalFilters.project = filters.mauProj;
  }
  if (filters.mauEnv) {
    finalFilters.environment = filters.mauEnv;
  }

  if (renderTotal) {
    if (finalFilters.project) {
      finalFilters.groupby = 'environment';
    } else {
      finalFilters.groupby = 'project';
    }
  }

  return {
    ...omit(finalFilters, [
      'mauSdk',
      'mauVersion',
      'mauProj',
      'mauEnv',
      !renderTotal || finalFilters.environment ? 'groupby' : '',
    ]),
  };
};

export const convertStartAndEndDateUsageFilters = (
  filters: FilterType,
  metric: string,
  defaultFromDate?: string,
): FilterType => {
  const usageTo = filters.to ? 'to' : `${metric}To`;
  const usageFrom = filters.from ? 'from' : `${metric}From`;
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  const fromDate = getFromDate(
    filters[usageFrom] ? parseInt(filters[usageFrom], 10) : undefined,
    defaultFromDate!,
  )?.toString(); /* eslint-enable @typescript-eslint/no-non-null-assertion */

  const tz = new Intl.DateTimeFormat().resolvedOptions().timeZone;

  // transform metric-specific filters into something the server will understand. Default local to from timestamps
  return {
    ...omit(filters, [usageTo, usageFrom]),
    to: !filters[usageTo] ? endOfToday().valueOf().toString() : filters[usageTo],
    from: fromDate,
    tz,
  };
};

export const handleMauLastDataPoint = (series: DataOnlySeries[]) => {
  const lastDataPoint = series[series.length - 1];

  if (series && series.length > 1) {
    if (
      (lastDataPoint[0] === 0 && !lastDataPoint[1]) ||
      (lastDataPoint[0] === 0 && lastDataPoint[1] && lastDataPoint[1] === 0)
    ) {
      return series.slice(0, -1);
    }
  }
  return series;
};

// This function compares two version that are in strings
// Original code base https://github.com/substack/semver-compare/blob/master/index.js
export const compareSemanticVersions = (versionA: string, versionB: string) => {
  /*
    -1 = versionB > versionA
     0 = versionA === versionB
     1 = versionA > versionB
  */
  const pa = versionA.split('.');
  const pb = versionB.split('.');
  for (let i = 0; i < 3; i++) {
    const na = Number(pa[i]);
    const nb = Number(pb[i]);
    if (na > nb) {
      return 1;
    }
    if (nb > na) {
      return -1;
    }
    if (!isNaN(na) && isNaN(nb)) {
      return 1;
    }
    if (isNaN(na) && !isNaN(nb)) {
      return -1;
    }
  }
  return 0;
};

/*
The hasRelayConnection is simply iterating through a projects 24 hr request and sdk's versions to confirm a specific
relay proxy was successfully set up with LaunchDarkly.

Ex data
 const sdkData = {
    metadata: [
      { sdk: "LDRelay", source: "server", version: "5.5.0" },
      { sdk: "LDRelay", source: "server", version: "6.2.0" },
    ],
    series: [
      { 0: 1, 1: 1, 2: 0, time: 1527638400000 },
      { 0: 2, 1: 1, 2: 0, time: 1530144000000 },
      { 0: 3, 1: 1, 2: 4, time: 1530230400000 },
      { 0: 4, 1: 1, 2: 0, time: 1527638400000 },
    ],
    _links: {},
  };
  - The metadata comprises of all the LDRelay versions
  - Each element in the series array represent entries corresponding to the past 24 hours we requested from the backend
  - The key "0", "1", and "2" represent a version of relays
  - The values represent the number of connections for a specific version.

  Its the helper functions job to find if a LDRelay version is compatible and has connections greater than 0. That means a relay connection exists

*/

export const hasRelayConnection = (data: {
  metadata: SDKVersion[];
  series: RechartsSeriesItem[];
  _links: ImmutableMap<{
    parent: Link;
    self: Link;
  }>;
}) => {
  const { metadata, series } = data;
  const relayVersion = syncedSegmentRelayVersion();
  const compatibleVersions: { [version: string]: string } = {};

  // iterate through all the metadata and save the indexes of all the compatible relay proxy versions
  forEach(metadata, (md, index) => {
    const { version } = md;
    if (compareSemanticVersions(version, relayVersion) === 0 || compareSemanticVersions(version, relayVersion) === 1) {
      compatibleVersions[`${index}`] = version;
    }
  });
  if (isEmpty(compatibleVersions)) {
    return false;
  }
  for (const seriesData of series) {
    for (const key of Object.keys(seriesData)) {
      // detect compatible versions and if there's any connections
      const seriesKey = seriesData[key] as number;
      if (compatibleVersions[key] && seriesKey > 0) {
        return true; // immediately exist once we found the first
      }
    }
  } // exit out of hasRelayConnection function
  return false;
};

const track = createTrackerForCategory('Usage');

export const trackUsagePageLoaded = (attrs?: AnalyticEventData) => track('Usage page loaded', attrs);
export const trackUsageChartError = (attrs?: AnalyticEventData) => track('Usage Chart Error', attrs);
