import { createContext, useCallback, useContext, useMemo, useRef } from 'react';
import { useScrollIntoViewOnMount } from '@gonfalon/dom';
import { List, Map, Record } from 'immutable';
import { v4 } from 'uuid';

import { CustomWorkflowType } from 'components/CustomWorkflows/types';
import { useSelector } from 'hooks/useSelector';
import { Clause as ImmutableClause } from 'utils/clauseUtils';
import {
  createCustomWorkflow,
  createCustomWorkflowAction,
  createCustomWorkflowApprovalCondition,
  createCustomWorkflowScheduleCondition,
  createCustomWorkflowStage,
  CustomWorkflow,
  CustomWorkflowActionType,
  CustomWorkflowApprovalCondition,
  CustomWorkflowApprovalConditionProps,
  CustomWorkflowConditionKindType,
  CustomWorkflowConditionType,
  CustomWorkflowConflict,
  CustomWorkflowDefaultCondition,
  CustomWorkflowExecutionStatusType,
  CustomWorkflowScheduleCondition,
} from 'utils/customWorkflowUtils';
import { createRule, Flag, FlagConfiguration, Rule, Variation } from 'utils/flagUtils';
import {
  makeTurnFlagOffInstruction,
  makeTurnFlagOnInstruction,
  makeUpdateFallthroughRolloutInstruction,
} from 'utils/instructions/onOff/helpers';
import {
  OnOffInstructionKind,
  TurnFlagOffSemanticInstruction,
  TurnFlagOnSemanticInstruction,
  UpdateFallthroughVariationOrRolloutSemanticInstruction,
} from 'utils/instructions/onOff/types';
import {
  AddRuleSemanticInstruction,
  RuleInstructionKind,
  SemanticInstructionRollout,
  SemanticInstructionRolloutWeights,
  UpdateRuleVariationOrRolloutSemanticInstruction,
} from 'utils/instructions/rules/types';
import { AllFlagInstructionKinds } from 'utils/instructions/shared/types';
import { TargetsInstructionKind, UpdateTargetsSemanticInstruction } from 'utils/instructions/targets/types';
import { AddUserTargetsSemanticInstruction, UserTargetsInstructionKind } from 'utils/instructions/userTargets/types';
import { ScheduleKind, WaitDurationUnit } from 'utils/scheduledChangesUtils';
import { WorkflowSemanticInstruction } from 'utils/workflowUtils';

import { workflowBuilderSelector } from './reducer';

export const WORKFLOW_BUILDER_STAGES_LIMIT = 20;

export enum WorkflowBuilderMode {
  READ_ONLY = 'readOnly',
  EDIT = 'edit',
  CREATE = 'create',
}

export const WorkflowBuilderActionsContext = createContext<WorkflowBuilderActionsType>(
  {} as WorkflowBuilderActionsType,
);
export const WorkflowBuilderModeContext = createContext<WorkflowBuilderMode>(WorkflowBuilderMode.CREATE);
export const WorkflowBuilderInitialStateContext = createContext<WorkflowBuilderStateType>(
  {} as WorkflowBuilderStateType,
);

type WorkflowBuilderViewOptionsType = {
  hideExpiringTargets?: boolean;
  hideTargetsBulkActions?: boolean;
};
export const WorkflowBuilderViewOptionsContext = createContext<WorkflowBuilderViewOptionsType>({
  hideExpiringTargets: false,
  hideTargetsBulkActions: false,
});

export function useWorkflowBuilderState<Value>(selector: (state: WorkflowBuilderStateType) => Value): Value;
export function useWorkflowBuilderState(): WorkflowBuilderStateType;
export function useWorkflowBuilderState<Value>(selector?: (state: WorkflowBuilderStateType) => Value) {
  return useSelector(
    (state) =>
      (selector ? selector(workflowBuilderSelector(state)) : workflowBuilderSelector(state)) as unknown as Value,
  );
}
export const useWorkflowBuilderActions = () => useContext(WorkflowBuilderActionsContext);
export const useWorkflowBuilderMode = () => useContext(WorkflowBuilderModeContext);
export const useWorkflowBuilderInitialState = () => useContext(WorkflowBuilderInitialStateContext);
export const useIsReadOnlyMode = () => useWorkflowBuilderMode() === WorkflowBuilderMode.READ_ONLY;
export const useWorkflowBuilderViewOptions = () => useContext(WorkflowBuilderViewOptionsContext);

export const WorkflowBuilderConditionKind = {
  APPROVAL: CustomWorkflowConditionKindType.APPROVAL,
  SCHEDULE: CustomWorkflowConditionKindType.SCHEDULE,
} as const;

export type WorkflowBuilderCondition =
  | CustomWorkflowScheduleCondition
  | CustomWorkflowApprovalCondition
  | CustomWorkflowDefaultCondition;

export type WorkflowBuilderScheduleStep = {
  key: string;
  content: CustomWorkflowScheduleCondition;
};

export type WorkflowBuilderApprovalStep = {
  key: string;
  content: CustomWorkflowApprovalCondition;
};

export type WorkflowBuilderDefaultConditionStep = {
  key: string;
  content: CustomWorkflowDefaultCondition;
};

export type WorkflowBuilderConditionStep =
  | WorkflowBuilderScheduleStep
  | WorkflowBuilderApprovalStep
  | WorkflowBuilderDefaultConditionStep;

export type WorkflowBuilderInstruction = (
  | TurnFlagOnSemanticInstruction
  | TurnFlagOffSemanticInstruction
  | UpdateRuleVariationOrRolloutSemanticInstruction
  | UpdateFallthroughVariationOrRolloutSemanticInstruction
  | AddRuleSemanticInstruction
  | AddUserTargetsSemanticInstruction
  | WorkflowBuilderUpdateTargetsSemanticInstructionsContainer
) & { _id?: string };

export type WorkflowBuilderTurnFlagOnStep = {
  key: string;
  content: TurnFlagOnSemanticInstruction & { _id?: string };
};

export type WorkflowBuilderTurnFlagOffStep = {
  key: string;
  content: TurnFlagOffSemanticInstruction & { _id?: string };
};

export type WorkflowBuilderSetRolloutStep = {
  key: string;
  content: UpdateRuleVariationOrRolloutSemanticInstruction & { _id?: string };
};

export type WorkflowBuilderSetDefaultVariationStep = {
  key: string;
  content: UpdateFallthroughVariationOrRolloutSemanticInstruction & { _id?: string };
};

export type WorkflowBuilderAddSegmentRuleStep = {
  key: string;
  content: AddRuleSemanticInstruction & { _id?: string };
};

export type WorkflowBuilderAddMobileRuleStep = {
  key: string;
  content: AddRuleSemanticInstruction & { _id?: string };
};

// This is identical to Segment Rule and will eventually replace it,
// but helps differentiate it for now.
export type WorkflowBuilderAddRuleStep = {
  key: string;
  content: AddRuleSemanticInstruction & { _id?: string };
};

export type WorkflowBuilderAddUserTargetsStep = {
  key: string;
  content: AddUserTargetsSemanticInstruction & { _id?: string };
};

/* Workflow builder steps are designed to contain a single condition or instruction,
which means an object with a unique "kind" property. An "add targets" step can allow
the user to add targets to any number of flag variations, which results in any number
of "addTargets" instructions. We want to represent these instructions as a single step
in the workflow builder, so we use a "container" class with its own unique "kind" property
to store a list of "addTargets" instructions the user has added for that step. */
export enum WorkflowBuilderInstructionsContainerKind {
  ADD_TARGETS_STEP_INSTRUCTIONS_CONTAINER = 'addTargetsStepInstructionsContainer',
}

type WorkflowBuilderUpdateTargetsSemanticInstructionsContainerType = {
  kind: WorkflowBuilderInstructionsContainerKind.ADD_TARGETS_STEP_INSTRUCTIONS_CONTAINER;
  instructions: List<UpdateTargetsSemanticInstruction>;
};

export class WorkflowBuilderUpdateTargetsSemanticInstructionsContainer extends Record<WorkflowBuilderUpdateTargetsSemanticInstructionsContainerType>(
  {
    kind: WorkflowBuilderInstructionsContainerKind.ADD_TARGETS_STEP_INSTRUCTIONS_CONTAINER,
    instructions: List(),
  },
) {}

export type WorkflowBuilderAddTargetsStep = {
  key: string;
  content: WorkflowBuilderUpdateTargetsSemanticInstructionsContainer & { _id?: string };
};

export type WorkflowBuilderInstructionStep =
  | WorkflowBuilderTurnFlagOnStep
  | WorkflowBuilderTurnFlagOffStep
  | WorkflowBuilderSetRolloutStep
  | WorkflowBuilderSetDefaultVariationStep
  | WorkflowBuilderAddSegmentRuleStep
  | WorkflowBuilderAddMobileRuleStep
  | WorkflowBuilderAddRuleStep
  | WorkflowBuilderAddUserTargetsStep
  | WorkflowBuilderAddTargetsStep;

export type WorkflowBuilderStep = WorkflowBuilderConditionStep | WorkflowBuilderInstructionStep;

export const WorkflowBuilderInstructionKind = {
  TURN_FLAG_ON: OnOffInstructionKind.TURN_FLAG_ON,
  TURN_FLAG_OFF: OnOffInstructionKind.TURN_FLAG_OFF,
  UPDATE_RULE_VARIATION_OR_ROLLOUT: RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT,
  UPDATE_FALLTHROUGH_VARIATION_OR_ROLLOUT: OnOffInstructionKind.UPDATE_FALLTHROUGH_VARIATION_OR_ROLLOUT,
  ADD_RULE: RuleInstructionKind.ADD_RULE,
  ADD_USER_TARGETS: UserTargetsInstructionKind.ADD_USER_TARGETS,
  REMOVE_USER_TARGETS: UserTargetsInstructionKind.REMOVE_USER_TARGETS,
  ADD_TARGETS_STEP_INSTRUCTIONS_CONTAINER:
    WorkflowBuilderInstructionsContainerKind.ADD_TARGETS_STEP_INSTRUCTIONS_CONTAINER,
  REMOVE_TARGETS: TargetsInstructionKind.REMOVE_TARGETS,
} as const;

export type WorkflowBuilderInstructionKindType =
  | OnOffInstructionKind.TURN_FLAG_ON
  | OnOffInstructionKind.TURN_FLAG_OFF
  | RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT
  | OnOffInstructionKind.UPDATE_FALLTHROUGH_VARIATION_OR_ROLLOUT
  | RuleInstructionKind.ADD_RULE
  | UserTargetsInstructionKind.ADD_USER_TARGETS
  | UserTargetsInstructionKind.REMOVE_USER_TARGETS
  | TargetsInstructionKind.REMOVE_TARGETS
  | WorkflowBuilderInstructionsContainerKind.ADD_TARGETS_STEP_INSTRUCTIONS_CONTAINER;

export type WorkflowBuilderStepKind = CustomWorkflowConditionKindType | WorkflowBuilderInstructionKindType;

export type WorkflowBuilderStageSteps = WorkflowBuilderStep[];

export type WorkflowBuilderStage = {
  key: string;
  name: string;
  steps: WorkflowBuilderStageSteps;
  status?: CustomWorkflowExecutionStatusType;
  conflict?: CustomWorkflowConflict;
};

export type WorkflowBuilderStateType = {
  name: string;
  description?: string;
  stages: WorkflowBuilderStage[];
  templateKey?: string;
  type?: CustomWorkflowType;
};

export type WorkflowBuilderActionsType = {
  editWorkflowName(name: string): void;
  editWorkflowDescription(description: string): void;
  addScheduleStep(stageKey: string, stepIndex: number): void;
  addApprovalStep(
    stageKey: string,
    stepIndex: number,
    notifyMemberIds?: CustomWorkflowApprovalConditionProps['notifyMemberIds'],
  ): void;
  addTurnFlagOnStep(stageKey: string, stepIndex: number): void;
  addTurnFlagOffStep(stageKey: string, stepIndex: number): void;
  addSetRolloutStep(
    stageKey: string,
    stepIndex: number,
    flag: Flag,
    flagConfiguration: FlagConfiguration,
    pendingRules: AddRuleSemanticInstruction[],
    stageSteps: WorkflowBuilderStageSteps,
  ): void;
  addSetDefaultVariationStep(stageKey: string, stepIndex: number, variationId: string): void;
  addAddRuleStep(stageKey: string, stepIndex: number, flagRulesCount?: number): void;
  addTargetsStep(stageKey: string, stepIndex: number): void;
  addSegmentStep(stageKey: string, stepIndex: number, flagRulesCount?: number): void;
  addMobileStep(stageKey: string, stepIndex: number, flagRulesCount?: number): void;
  updateScheduleDate(stageKey: string, stepKey: string, date: Date | null): void;
  updateScheduleKind(stageKey: string, stepKey: string, scheduleKind: ScheduleKind): void;
  updateScheduleWaitDuration(stageKey: string, stepKey: string, waitDuration: number): void;
  updateScheduleWaitDurationUnit(stageKey: string, stepKey: string, waitDurationUnit: WaitDurationUnit): void;
  updateApprovalDescription(stageKey: string, stepKey: string, description: string): void;
  updateApprovalReviewers(stageKey: string, stepKey: string, reviewerIds: string[], teamKeys?: string[]): void;
  updateFlagTargetingStep(stageKey: string, stepKey: string, checked: boolean): void;
  updateDefaultVariationStep(stageKey: string, stepKey: string, variationId: string): void;
  updateAddRuleStep(stageKey: string, stepKey: string, instruction: AddRuleSemanticInstruction): void;
  updateAddUserTargetsStep(stageKey: string, stepKey: string, userKeys: string[], variationId: string): void;
  updateAddTargetsStep(
    stageKey: string,
    stepKey: string,
    contextKeys: string[],
    contextKind: string,
    variationId: string,
  ): void;
  updateRuleRolloutStep(
    stageKey: string,
    stepKey: string,
    ruleId: string,
    ruleIsPending: boolean,
    rollout: SemanticInstructionRollout,
  ): void;
  updateFallthroughRolloutStep(stageKey: string, stepKey: string, rollout: SemanticInstructionRollout): void;
  editStageName(stageKey: string, stepIndex: string): void;
  replaceStep(stageKey: string, stepKey: string, step: WorkflowBuilderStep): void;
  removeStep(stageKey: string, stepKey: string): void;
};

export function generateKey() {
  return v4();
}

export function getWorkflowBuilderInitialState(
  name: string,
  description?: string,
  initialSteps?: WorkflowBuilderStageSteps,
): WorkflowBuilderStateType {
  return {
    name,
    description,
    stages: [
      {
        key: generateKey(),
        name: 'Stage 1',
        steps: initialSteps || [],
      },
    ],
  };
}

export const hasKey = (key: string) => (entity: { key: string }) => entity.key === key;

export function isFirstStage(stageIndex: number) {
  return stageIndex === 0;
}

export function isLastStage(stages: WorkflowBuilderStage[], stageIndex: number) {
  return stageIndex === stages.length - 1;
}

export function doesStepHaveKind(
  stepKind: WorkflowBuilderStepKind | AllFlagInstructionKinds | WorkflowBuilderInstructionsContainerKind,
) {
  return (step: WorkflowBuilderStep) => step.content.kind === stepKind;
}

function hasStepBeenAdded(steps: WorkflowBuilderStageSteps, stepKind: WorkflowBuilderStepKind) {
  return !!steps.find(doesStepHaveKind(stepKind));
}

export function isDuplicateInstructionAllowedInStage(instructionKind: WorkflowBuilderInstructionKindType) {
  // set rule rollout, or add user segment rule
  return (
    instructionKind === RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT ||
    instructionKind === RuleInstructionKind.ADD_RULE
  );
}

export const noFlagRulesToSetDisabledReason = 'Flag has no further rule rollouts to set';

export function getIsInstructionDisabledForFlagConfig(
  steps: WorkflowBuilderStageSteps,
  instructionKind: WorkflowBuilderInstructionKindType,
  flagConfiguration?: FlagConfiguration,
  pendingRuleInstructions?: AddRuleSemanticInstruction[],
) {
  const pendingRules = pendingRuleInstructions?.map((ins) =>
    createRule({ ...ins, clauses: ins.clauses.filter((c): c is ImmutableClause => c !== null) }),
  );
  if (
    instructionKind === RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT &&
    doStepsIncludeInstructionOfKind(steps, OnOffInstructionKind.UPDATE_FALLTHROUGH_VARIATION_OR_ROLLOUT) &&
    (!flagConfiguration?.rules.size || flagConfiguration.rules.every(isRuleIncludedInSteps(steps))) &&
    (!pendingRules?.length || pendingRules.every(isRuleIncludedInSteps(steps)))
  ) {
    return { isDisabled: true, disabledReason: noFlagRulesToSetDisabledReason } as const;
  }

  return { isDisabled: false, disabledReason: '' } as const;
}

export function isLastStep(steps: WorkflowBuilderStageSteps, index: number) {
  return steps.slice(index).length === 0;
}

export function doStepKindsMatch(stepA: WorkflowBuilderStep, stepB: WorkflowBuilderStep) {
  if (!stepA || !stepB) {
    return false;
  }

  if (
    (stepA.content.kind === OnOffInstructionKind.TURN_FLAG_ON &&
      stepB.content.kind === OnOffInstructionKind.TURN_FLAG_OFF) ||
    (stepA.content.kind === OnOffInstructionKind.TURN_FLAG_OFF &&
      stepB.content.kind === OnOffInstructionKind.TURN_FLAG_ON)
  ) {
    return true;
  }

  return stepA.content.kind === stepB.content.kind;
}

// condition utils
export function isConditionStep(step: WorkflowBuilderStep): step is WorkflowBuilderConditionStep {
  return Object.values(CustomWorkflowConditionKindType).includes(step.content.kind as CustomWorkflowConditionKindType);
}

export function doStepsIncludeCondition(steps: WorkflowBuilderStageSteps) {
  return steps.some(isConditionStep);
}

export function doPreviousStepsIncludeCondition(steps: WorkflowBuilderStageSteps, index: number) {
  return doStepsIncludeCondition(steps.slice(0, index));
}

export function isPreviousStepCondition(steps: WorkflowBuilderStageSteps, index: number) {
  return index - 1 >= 0 && isConditionStep(steps[index - 1]);
}

export function isScheduleStep(step: WorkflowBuilderStep): step is WorkflowBuilderScheduleStep {
  return step.content.kind === CustomWorkflowConditionKindType.SCHEDULE;
}

export function doStepsIncludeSchedule(steps: WorkflowBuilderStageSteps) {
  return steps.some(isScheduleStep);
}

export function doStepsIncludeRelativeSchedule(steps: WorkflowBuilderStageSteps) {
  return steps.some(isRelativeScheduleKind);
}

export function doStepsIncludeAbsoluteSchedule(steps: WorkflowBuilderStageSteps) {
  return steps.some(isAbsoluteScheduleKind);
}

export function isRelativeScheduleKind(step: WorkflowBuilderStep): step is WorkflowBuilderScheduleStep {
  const content = step.content as CustomWorkflowScheduleCondition;
  return content.scheduleKind === ScheduleKind.RELATIVE;
}

export function isAbsoluteScheduleKind(step: WorkflowBuilderStep): step is WorkflowBuilderScheduleStep {
  const content = step.content as CustomWorkflowScheduleCondition;
  return content.scheduleKind === ScheduleKind.ABSOLUTE;
}

export function getWaitDurationUnitArray(steps: WorkflowBuilderStageSteps) {
  const waitDurationUnit: string[] = [];
  steps.forEach((step) => {
    if (isScheduleStep(step)) {
      const content = step.content;
      waitDurationUnit.push(content.waitDurationUnit ?? 'none');
    }
  });
  return waitDurationUnit;
}

export function doPreviousStepsIncludeSchedule(steps: WorkflowBuilderStageSteps, index: number) {
  return doStepsIncludeSchedule(steps.slice(0, index));
}

export function isPreviousStepSchedule(steps: WorkflowBuilderStageSteps, index: number) {
  return index - 1 >= 0 && isScheduleStep(steps[index - 1]);
}

export function isApprovalStep(step: WorkflowBuilderStep): step is WorkflowBuilderApprovalStep {
  return step.content.kind === CustomWorkflowConditionKindType.APPROVAL;
}

export function doStepsIncludeApproval(steps: WorkflowBuilderStageSteps) {
  return steps.some(isApprovalStep);
}

export function doPreviousStepsIncludeApproval(steps: WorkflowBuilderStageSteps, index: number) {
  return doStepsIncludeApproval(steps.slice(0, index));
}

export function isPreviousStepApproval(steps: WorkflowBuilderStageSteps, index: number) {
  return index - 1 >= 0 && isApprovalStep(steps[index - 1]);
}

export function doesWorkflowStartWithApprovalStep(stages: WorkflowBuilderStage[]) {
  return stages[0]?.steps.length > 0 && isApprovalStep(stages[0].steps[0]);
}

export function canAddCondition(
  steps: WorkflowBuilderStageSteps,
  stepIndex: number,
  conditionKind: CustomWorkflowConditionKindType,
) {
  const isConditionApprovalFollowingSchedule =
    conditionKind === CustomWorkflowConditionKindType.APPROVAL && isPreviousStepSchedule(steps, stepIndex);

  return (
    steps.length === 0 ||
    (!hasStepBeenAdded(steps, conditionKind) &&
      isPreviousStepCondition(steps, stepIndex) &&
      !isConditionApprovalFollowingSchedule) ||
    (!doStepsIncludeCondition(steps) && stepIndex === 0) ||
    (isLastStep(steps, stepIndex) && isPreviousStepInstruction(steps, stepIndex))
  );
}

export function canAddSomeCondition(steps: WorkflowBuilderStageSteps, stepIndex: number) {
  return Object.values(WorkflowBuilderConditionKind).some((conditionKind) =>
    canAddCondition(steps, stepIndex, conditionKind as CustomWorkflowConditionKindType),
  );
}

export const INITIAL_CONDITION_STEP_SELECTOR_KEY = 'INITIAL_CONDITION_STEP_SELECTOR_KEY';

// instruction utils
export function isInstructionStep(step: WorkflowBuilderStep): step is WorkflowBuilderInstructionStep {
  return Object.values(WorkflowBuilderInstructionKind).includes(
    step.content.kind as WorkflowBuilderInstructionKindType,
  );
}

export function isPreviousStepInstruction(steps: WorkflowBuilderStageSteps, index: number) {
  return index - 1 >= 0 && isInstructionStep(steps[index - 1]);
}

export function isNextStepInstruction(steps: WorkflowBuilderStageSteps, index: number) {
  return isInstructionStep(steps[index]);
}

export function doStepsIncludeInstruction(steps: WorkflowBuilderStageSteps) {
  return steps.some(isInstructionStep);
}

export function doPreviousStepsIncludeInstruction(steps: WorkflowBuilderStageSteps, index: number) {
  return doStepsIncludeInstruction(steps.slice(0, index));
}

export function doStepsIncludeInstructionOfKind(
  steps: WorkflowBuilderStageSteps,
  kind: AllFlagInstructionKinds | WorkflowBuilderInstructionsContainerKind,
) {
  return steps.some(doesStepHaveKind(kind));
}

export function doesStepHaveRolloutWeight(step: WorkflowBuilderStep, variationId: string, rolloutWeight: number) {
  return (
    (step.content as UpdateFallthroughVariationOrRolloutSemanticInstruction).rolloutWeights?.[variationId] ===
    rolloutWeight
  );
}

export function canAddInstruction(
  steps: WorkflowBuilderStageSteps,
  stepIndex: number,
  instructionKind: WorkflowBuilderInstructionKindType,
  context?: { flagConfiguration: FlagConfiguration; pendingRules: AddRuleSemanticInstruction[] },
) {
  return (
    doPreviousStepsIncludeCondition(steps, stepIndex) &&
    (!hasStepBeenAdded(steps, instructionKind) || isDuplicateInstructionAllowedInStage(instructionKind)) &&
    !getIsInstructionDisabledForFlagConfig(steps, instructionKind, context?.flagConfiguration, context?.pendingRules)
      .isDisabled &&
    (isLastStep(steps, stepIndex) || isNextStepInstruction(steps, stepIndex))
  );
}

export function canAddSomeInstruction(
  steps: WorkflowBuilderStageSteps,
  stepIndex: number,
  context?: { flagConfiguration: FlagConfiguration; pendingRules: AddRuleSemanticInstruction[] },
) {
  return Object.values(WorkflowBuilderInstructionKind).some((instructionKind) =>
    canAddInstruction(steps, stepIndex, instructionKind, context),
  );
}

// selectors
export function workflowBuilderNameSelector(state: WorkflowBuilderStateType) {
  return state.name;
}

export function useWorkflowBuilderName() {
  return useWorkflowBuilderState(workflowBuilderNameSelector);
}

export function workflowBuilderDescriptionSelector(state: WorkflowBuilderStateType) {
  return state.description;
}

export function useWorkflowBuilderDescription() {
  return useWorkflowBuilderState(workflowBuilderDescriptionSelector);
}

export function stageSelector(state: WorkflowBuilderStateType, key: string) {
  return state.stages.find(hasKey(key));
}

export function useWorkflowBuilderStage(stageKey: string) {
  return useWorkflowBuilderState((state) => stageSelector(state, stageKey));
}

export function isWorkflowEmptySelector(state: WorkflowBuilderStateType) {
  return state.stages.length === 1 && state.stages[0].steps.length === 0;
}

export function useIsWorkflowEmpty() {
  return useWorkflowBuilderState(isWorkflowEmptySelector);
}

export type StepLocation = {
  stageKey: string;
  stepIndex: number;
};

export type ConditionOptionValidationProps = StepLocation & { conditionKind: CustomWorkflowConditionKindType };

export function isConditionOptionHiddenSelector(
  state: WorkflowBuilderStateType,
  { stageKey, stepIndex, conditionKind }: ConditionOptionValidationProps,
) {
  const stage = stageSelector(state, stageKey);
  return !!stage && !canAddCondition(stage.steps, stepIndex, conditionKind);
}

export function useIsConditionOptionHidden(props: ConditionOptionValidationProps) {
  return useWorkflowBuilderState((state) => isConditionOptionHiddenSelector(state, props));
}

export function areAllConditionOptionsHiddenSelector(
  state: WorkflowBuilderStateType,
  { stageKey, stepIndex }: StepLocation,
) {
  return (
    isConditionOptionHiddenSelector(state, {
      stageKey,
      stepIndex,
      conditionKind: CustomWorkflowConditionKindType.SCHEDULE,
    }) &&
    isConditionOptionHiddenSelector(state, {
      stageKey,
      stepIndex,
      conditionKind: CustomWorkflowConditionKindType.APPROVAL,
    })
  );
}

export function useAreAllConditionOptionsHidden(props: StepLocation) {
  return useWorkflowBuilderState((state) => areAllConditionOptionsHiddenSelector(state, props));
}

export function canAddSomeStepSelector(state: WorkflowBuilderStateType, { stageKey, stepIndex }: StepLocation) {
  const stage = stageSelector(state, stageKey);

  return !!stage && (canAddSomeCondition(stage.steps, stepIndex) || canAddSomeInstruction(stage.steps, stepIndex));
}

export function useCanAddSomeStep({ stageKey, prevStepKey }: { stageKey: string; prevStepKey: string }) {
  const stepIndex = useStepIndex({ stageKey, stepKey: prevStepKey }) + 1;
  return useWorkflowBuilderState((state) => canAddSomeStepSelector(state, { stageKey, stepIndex }));
}

export function doPreviousStepsIncludeApprovalSelector(state: WorkflowBuilderStateType, props: StepLocation) {
  const stage = stageSelector(state, props.stageKey);
  return !!stage && doPreviousStepsIncludeApproval(stage.steps, props.stepIndex);
}

export function useDoPreviousStepsIncludeApproval({ stageKey, stepKey }: { stageKey: string; stepKey: string }) {
  const stepIndex = useStepIndex({ stageKey, stepKey });
  return useWorkflowBuilderState((state) => doPreviousStepsIncludeApprovalSelector(state, { stageKey, stepIndex }));
}

export const isStepForRule = (rule: Rule) => (step: WorkflowBuilderStep) =>
  step.content.kind === RuleInstructionKind.UPDATE_RULE_VARIATION_OR_ROLLOUT &&
  ((rule._id && step.content.ruleId === rule._id) || (rule.ref && step.content.ref === rule._key));

export const isRuleIncludedInSteps = (steps: WorkflowBuilderStageSteps) => (rule: Rule) =>
  steps.some(isStepForRule(rule));

export function useDoStageStepsIncludeRuleCallback({ stageKey, stepKey }: { stageKey: string; stepKey: string }) {
  const stage = useWorkflowBuilderStage(stageKey);

  return useCallback(
    (rule: Rule) => !!stage && stage.steps.some((step) => step.key !== stepKey && isStepForRule(rule)(step)),
    [stage?.steps],
  );
}

export type InstructionOptionValidationProps = StepLocation & {
  instructionKind: WorkflowBuilderInstructionKindType;
  context?: { flagConfiguration: FlagConfiguration };
};

export const actionAlreadyIncludedInStageTooltip = 'This action is already included in this stage';

export function isInstructionOptionDisabledSelector(
  state: WorkflowBuilderStateType,
  { stageKey, stepIndex, instructionKind, context }: InstructionOptionValidationProps,
) {
  const stage = stageSelector(state, stageKey);

  if (!stage) {
    return [false, ''] as const;
  }

  if (
    doStepsIncludeInstructionOfKind(stage.steps, instructionKind) &&
    !isDuplicateInstructionAllowedInStage(instructionKind)
  ) {
    return [true, actionAlreadyIncludedInStageTooltip] as const;
  }

  const stepKey = stage?.steps[stepIndex]?.key;
  const pendingRules = getPendingRules(state, { stageKey, stepKey });

  const disabledForFlagConfigResult = getIsInstructionDisabledForFlagConfig(
    stage.steps,
    instructionKind,
    context?.flagConfiguration,
    pendingRules,
  );

  if (disabledForFlagConfigResult.isDisabled) {
    return [true, disabledForFlagConfigResult.disabledReason] as const;
  }

  return [false, ''] as const;
}

export function useIsInstructionOptionDisabled(props: InstructionOptionValidationProps) {
  return useWorkflowBuilderState((state) => isInstructionOptionDisabledSelector(state, props));
}

export function getAddRuleStepCount(state: WorkflowBuilderStateType) {
  return state.stages.reduce(
    (addRuleStepCount, stage) =>
      addRuleStepCount + stage.steps.filter((step) => step.content.kind === RuleInstructionKind.ADD_RULE).length,
    0,
  );
}

export function useAddRuleStepCount() {
  return useWorkflowBuilderState(getAddRuleStepCount);
}

export function useDoesStageSetDefaultVariation({ stageKey, stepKey }: { stageKey: string; stepKey: string }) {
  return useWorkflowBuilderState((state) => {
    const stage = stageSelector(state, stageKey);

    return (
      !!stage &&
      stage.steps.some(
        (step) =>
          step.content.kind === WorkflowBuilderInstructionKind.UPDATE_FALLTHROUGH_VARIATION_OR_ROLLOUT &&
          step.key !== stepKey,
      )
    );
  });
}

export function getPendingRules(
  state: WorkflowBuilderStateType,
  { stageKey, stepKey }: { stageKey: string; stepKey?: string },
) {
  const rules = [];
  for (const stage of state.stages) {
    for (const step of stage.steps) {
      if (stepKey && stage.key === stageKey && step.key === stepKey) {
        break;
      }

      if (step.content.kind === WorkflowBuilderInstructionKind.ADD_RULE) {
        rules.push(step.content);
      }
    }
    if (stage.key === stageKey) {
      return rules;
    }
  }
  return rules;
}

export function usePendingRules({ stageKey, stepKey }: { stageKey: string; stepKey?: string }) {
  const prevPendingRules = useRef<ReturnType<typeof getPendingRules>>([]);
  return useWorkflowBuilderState((state) => {
    const pendingRules = getPendingRules(state, { stageKey, stepKey });
    if (
      pendingRules.length !== prevPendingRules.current.length ||
      pendingRules.some((rule, index) => rule._id !== prevPendingRules.current[index]._id)
    ) {
      prevPendingRules.current = pendingRules;
      return pendingRules;
    }

    return prevPendingRules.current;
  });
}

export function getPendingUserTargets(
  state: WorkflowBuilderStateType,
  { stageKey, stepKey }: { stageKey: string; stepKey?: string },
) {
  const userTargets: string[] = [];
  for (const stage of state.stages) {
    for (const step of stage.steps) {
      if (stepKey && stage.key === stageKey && step.key === stepKey) {
        break;
      }

      if (step.content.kind === WorkflowBuilderInstructionKind.ADD_USER_TARGETS) {
        step.content.values.forEach((v) => userTargets.push(v));
      }
    }
    if (stage.key === stageKey) {
      return userTargets;
    }
  }
  return userTargets;
}

export function convertWorkflowBuilderStageToCustomWorkflowStage(stage: WorkflowBuilderStage) {
  // convert the condition steps into workflow condition records
  const conditions = List(
    stage.steps.filter(isConditionStep).map((step) => step.content) as CustomWorkflowConditionType[],
  );

  let instructions = List<WorkflowSemanticInstruction>();
  stage.steps.filter(isInstructionStep).forEach((step) => {
    if (step.content.kind === WorkflowBuilderInstructionsContainerKind.ADD_TARGETS_STEP_INSTRUCTIONS_CONTAINER) {
      // if this is an "add targets" step, we store the instructions for each variation/contextKind combination
      // in a list of "instructions" on a wrapper Record which still has the same "kind" as the normal add targets instruction
      // we need to get that list of instructions and add each one to our flag list of instructions for this stage's "action"
      step.content.instructions.forEach((ins) => {
        instructions = instructions.push(ins);
      });
    } else {
      // otherwise, proceed as normal and add the step's "content" to our list of instructions
      instructions = instructions.push(step.content);
    }
  });

  // convert the instruction steps into a single workflow action record
  const action = createCustomWorkflowAction({
    kind: CustomWorkflowActionType.PATCH,
    instructions,
  });

  // custom workflows created using the UI should always execute conditions in sequence to match how
  // conditions are laid out in the UI.
  const executeConditionsInSequence = true;

  return createCustomWorkflowStage({ name: stage.name, conditions, action, executeConditionsInSequence });
}

export function convertWorkflowBuilderStateToCustomWorkflow(state: WorkflowBuilderStateType) {
  const stages = List(state.stages.map(convertWorkflowBuilderStageToCustomWorkflowStage));
  return createCustomWorkflow({
    name: state.name,
    description: state.description,
    templateKey: state.templateKey,
    stages,
  });
}

export function stepIndexFromKeySelector(
  state: WorkflowBuilderStateType,
  { stageKey, stepKey }: { stageKey: string; stepKey: string },
) {
  const index = stageSelector(state, stageKey)?.steps.findIndex(hasKey(stepKey));
  return index === undefined ? -1 : index;
}

export function useStepIndex(props: { stageKey: string; stepKey: string }) {
  return useWorkflowBuilderState((state) => stepIndexFromKeySelector(state, props));
}

export function getZeroedRolloutWeights(variations: List<Variation>) {
  return variations.reduce(
    (accum, variation) => ({ ...accum, [variation._id]: 0 }),
    {} as SemanticInstructionRolloutWeights,
  );
}

export function getInitialRolloutWeights({
  isBooleanFlag,
  variations,
}: {
  isBooleanFlag: boolean;
  variations: List<Variation>;
}) {
  let initialRolloutWeight = getZeroedRolloutWeights(variations);

  if (isBooleanFlag) {
    const defaultBooleanRolloutWeight = 100000;
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    const firstVariation = variations.get(0)!; // can assert bc flags have a min of 2 variations;/* eslint-enable @typescript-eslint/no-non-null-assertion */
    initialRolloutWeight = { ...initialRolloutWeight, [firstVariation._id]: defaultBooleanRolloutWeight };
  }
  return initialRolloutWeight;
}
// validation utils
export function isValidWorkflow(state: WorkflowBuilderStateType) {
  return Boolean(state.name && state.name.length > 0 && state.stages && state.stages.length > 0);
}

export function stageValidator(stage: WorkflowBuilderStage): string | undefined {
  if (!doStepsIncludeCondition(stage.steps)) {
    return 'All stages must include a condition.';
  }
  if (!doStepsIncludeInstruction(stage.steps)) {
    return 'All stages must include an action.';
  }
}

export function createWorkflowBuilderStep(content: WorkflowBuilderCondition): WorkflowBuilderConditionStep;
export function createWorkflowBuilderStep(content: WorkflowBuilderInstruction): WorkflowBuilderInstructionStep;
export function createWorkflowBuilderStep(content: WorkflowBuilderCondition | WorkflowBuilderInstruction) {
  return {
    key: content._id || generateKey(),
    content,
  };
}

export function createWorkflowBuilderApprovalStep() {
  return createWorkflowBuilderStep(
    createCustomWorkflowApprovalCondition({
      kind: CustomWorkflowConditionKindType.APPROVAL,
    }),
  ) as WorkflowBuilderApprovalStep;
}

export function createWorkflowBuilderAbsoluteScheduleStep(executionDate: number) {
  return createWorkflowBuilderStep(
    createCustomWorkflowScheduleCondition({
      kind: CustomWorkflowConditionKindType.SCHEDULE,
      scheduleKind: ScheduleKind.ABSOLUTE,
      executionDate,
    }),
  ) as WorkflowBuilderScheduleStep;
}

export function createWorkflowBuilderRelativeScheduleStep(waitDuration: number, waitDurationUnit: WaitDurationUnit) {
  return createWorkflowBuilderStep(
    createCustomWorkflowScheduleCondition({
      kind: CustomWorkflowConditionKindType.SCHEDULE,
      scheduleKind: ScheduleKind.RELATIVE,
      waitDuration,
      waitDurationUnit,
    }),
  );
}

export function createWorkflowBuilderTurnFlagOnStep() {
  return createWorkflowBuilderStep(makeTurnFlagOnInstruction()) as WorkflowBuilderTurnFlagOnStep;
}

export function createWorkflowBuilderTurnFlagOffStep() {
  return createWorkflowBuilderStep(makeTurnFlagOffInstruction()) as WorkflowBuilderTurnFlagOffStep;
}

export function createWorkflowBuilderProgressiveRolloutStep({
  weights,
  bucketBy,
  contextKind,
}: {
  weights: SemanticInstructionRolloutWeights;
  bucketBy?: string;
  contextKind?: string;
}) {
  return createWorkflowBuilderStep(
    makeUpdateFallthroughRolloutInstruction({
      bucketBy,
      contextKind,
      weights,
      experimentAllocation: undefined,
    }),
  );
}

export function createWorkflowBuilderStage(name: string, steps: WorkflowBuilderStageSteps): WorkflowBuilderStage {
  return {
    key: generateKey(),
    name,
    steps,
  };
}

function convertCustomWorkflowStagesToWorkflowBuilderStages(customWorkflow: CustomWorkflow) {
  const conflicts = customWorkflow._conflicts.toArray();
  return customWorkflow.stages
    .map((stage): WorkflowBuilderStage => {
      const conditionSteps = stage.conditions.map((condition) => createWorkflowBuilderStep(condition)).toArray();
      let instructions = List<WorkflowBuilderInstruction>([]);
      let updateContextTargetsInstructions = List<UpdateTargetsSemanticInstruction>([]);
      let continueAddTargets = true;
      stage.action.instructions.forEach((ins) => {
        if (
          ins.kind === TargetsInstructionKind.ADD_TARGETS &&
          (continueAddTargets || updateContextTargetsInstructions.isEmpty())
        ) {
          updateContextTargetsInstructions = updateContextTargetsInstructions.push(ins);
          continueAddTargets = true;
        } else {
          if (continueAddTargets && !updateContextTargetsInstructions.isEmpty()) {
            instructions = instructions.push(
              new WorkflowBuilderUpdateTargetsSemanticInstructionsContainer({
                instructions: updateContextTargetsInstructions,
              }),
            );
            updateContextTargetsInstructions = List<UpdateTargetsSemanticInstruction>([]);
          }
          instructions = instructions.push(ins as WorkflowBuilderInstruction);
          continueAddTargets = false;
        }
      });

      if (continueAddTargets && !updateContextTargetsInstructions.isEmpty()) {
        instructions = instructions.push(
          new WorkflowBuilderUpdateTargetsSemanticInstructionsContainer({
            instructions: updateContextTargetsInstructions,
          }),
        );
      }
      const instructionSteps = instructions.map((ins) => createWorkflowBuilderStep(ins)).toArray();
      const stageSteps = [...conditionSteps, ...instructionSteps];
      const conflict = conflicts.find((c) => c.stageId === stage._id);
      return {
        key: stage._id,
        name: stage.name,
        steps: stageSteps,
        status: stage._execution.status,
        conflict,
      };
    })
    .toArray();
}

export const convertCustomWorkflowToWorkflowBuilderState = (
  customWorkflow: CustomWorkflow,
): WorkflowBuilderStateType => {
  const workflowBuilderStages = convertCustomWorkflowStagesToWorkflowBuilderStages(customWorkflow);
  return {
    name: customWorkflow.name,
    description: customWorkflow.description,
    stages: workflowBuilderStages,
    ...(customWorkflow.templateKey ? { templateKey: customWorkflow.templateKey } : {}),
  };
};

function useIsInInitialState(stageKey: string, stepKey?: string) {
  const initialState = useWorkflowBuilderInitialState();

  return useMemo(
    () =>
      initialState.stages.some(
        (stage) => stage.key === stageKey && (stepKey ? stage.steps.some((step) => step.key === stepKey) : true),
      ),
    [initialState, stageKey, stepKey],
  );
}

export function useScrollWorkflowBuilderElementIntoView<T extends HTMLElement>(stageKey: string, stepKey?: string) {
  const isReadOnlyMode = useIsReadOnlyMode();
  const isInInitialState = useIsInInitialState(stageKey, stepKey);

  return useScrollIntoViewOnMount<T>({ isDisabled: isReadOnlyMode || isInInitialState });
}

let prevStageKeys: string[] = [];
const stageKeysSelector = (state: WorkflowBuilderStateType) => {
  const stageKeys = state.stages.map((stage) => stage.key);

  if (
    stageKeys.length !== prevStageKeys.length ||
    stageKeys.some((stageKey, index) => stageKey !== prevStageKeys[index])
  ) {
    prevStageKeys = stageKeys;
    return stageKeys;
  }

  return prevStageKeys;
};

export function useWorkflowBuilderStageKeys() {
  return useWorkflowBuilderState(stageKeysSelector);
}

export function parseUpdateContextTargetInstructions(instructions: List<UpdateTargetsSemanticInstruction>) {
  let variationsToTargets: Map<string, Map<string, List<string>>> = Map();
  let variationsToTargetsCount: Map<string, number> = Map();

  instructions.forEach((ins) => {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    let contextKindToValues: Map<string, List<string>> = variationsToTargets.has(ins.variationId)
      ? variationsToTargets.get(ins.variationId)!
      : Map(); /* eslint-enable @typescript-eslint/no-non-null-assertion */
    contextKindToValues = contextKindToValues.set(ins.contextKind, List(ins.values));
    variationsToTargets = variationsToTargets.set(ins.variationId, contextKindToValues);
    const targetsCount = (variationsToTargetsCount.get(ins.variationId) || 0) + ins.values.length;
    variationsToTargetsCount = variationsToTargetsCount.set(ins.variationId, targetsCount);
  });

  return { variationsToTargets, variationsToTargetsCount };
}
