import { type GetSegmentsQueryParams } from '@gonfalon/openapi';

export enum SegmentType {
  AMPLITUDE = 'amplitude',
  REGULAR = 'regular',
  NATIVE_BIG = 'native_big',
}

export enum SyncedSegmentSourceKind {
  AMPLITUDE = 'amplitude',
  HEAP = 'heap',
  SEGMENT = 'segment',
}

type SegmentTrackEventInfo = {
  action?: string;
  bucketContextKind?: string;
  contextKind?: string;
  description?: boolean;
  filter?: string;
  filterType?: 'tag' | 'source';
  isRelaySetup?: boolean;
  location?: string;
  syncedSource?: SyncedSegmentSourceKind;
  tags?: boolean;
  type?: 'individual' | 'rule' | SegmentType;
  ruleKind?: string;
  clauseKind?: string;
  operatorName?: string;
  attributeName?: string;
  isBigSegment?: boolean;
};

export { type SegmentTrackEventInfo };

export type UISegmentsListView = {
  sort?: GetSegmentsQueryParams['sort'];
  query?: string;
  keys?: string[];
  tags?: string[];
  kind?: string;
};
