/* eslint-disable @typescript-eslint/naming-convention */
import { List, Map, OrderedMap, OrderedSet } from 'immutable';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import { CustomWorkflowsAction } from 'actions/customWorkflows';
import { GlobalState } from 'reducers';
import { createRequestReducer, createRequestReducerByKey } from 'reducers/createRequestReducer';
import { CustomWorkflow, CustomWorkflowExecutionStatusType } from 'utils/customWorkflowUtils';

import registry from './registry';

type CustomWorkflowsIdsState = OrderedSet<string>;
type CustomWorkflowsEntitiesState = OrderedMap<string, CustomWorkflow>;

// customWorkflows.ids reducer
function ids(state: CustomWorkflowsIdsState = OrderedSet<string>(), action: CustomWorkflowsAction) {
  switch (action.type) {
    case 'customWorkflows/RECEIVE_CUSTOM_WORKFLOWS_BY_STATUS': {
      return state.union(action.response.get('result').get('items'));
    }
    case 'customWorkflows/RECEIVE_CUSTOM_WORKFLOW_BY_ID': {
      return state.add(action.response.get('_id'));
    }
    case 'customWorkflows/DELETE_CUSTOM_WORKFLOW_DONE': {
      return state.delete(action.customWorkflow.getId());
    }
    default: {
      return state;
    }
  }
}

// customWorkflows.entities reducer
function entities(
  state: CustomWorkflowsEntitiesState = OrderedMap<string, CustomWorkflow>(),
  action: CustomWorkflowsAction,
) {
  switch (action.type) {
    case 'customWorkflows/RECEIVE_CUSTOM_WORKFLOWS_BY_STATUS': {
      return state.merge(action.response.get('entities').get('customWorkflows'));
    }
    case 'customWorkflows/RECEIVE_CUSTOM_WORKFLOW_BY_ID': {
      return state.set(action.response._id, action.response);
    }
    case 'customWorkflows/DELETE_CUSTOM_WORKFLOW_DONE': {
      return state.delete(action.customWorkflow.getId());
    }
    default: {
      return state;
    }
  }
}

const initialByStatusState = Map<CustomWorkflowExecutionStatusType, CustomWorkflowsEntitiesState>([
  [CustomWorkflowExecutionStatusType.ACTIVE, OrderedMap<string, CustomWorkflow>()],
  [CustomWorkflowExecutionStatusType.COMPLETED, OrderedMap<string, CustomWorkflow>()],
]);
type CustomWorkflowsByStatusState = Map<CustomWorkflowExecutionStatusType, CustomWorkflowsEntitiesState>;
function byStatus(state: CustomWorkflowsByStatusState = initialByStatusState, action: CustomWorkflowsAction) {
  switch (action.type) {
    case 'customWorkflows/RECEIVE_CUSTOM_WORKFLOWS_BY_STATUS': {
      return state.set(action.status, action.response.get('entities').get('customWorkflows'));
    }
    default: {
      return state;
    }
  }
}

function currentFlag(state: string = '', action: CustomWorkflowsAction) {
  switch (action.type) {
    case 'customWorkflows/RECEIVE_CUSTOM_WORKFLOWS_BY_STATUS': {
      return action.flagKey;
    }
    default: {
      return state;
    }
  }
}

// customWorkflows.request reducer
const workflowsByStatusRequest = createRequestReducerByKey(
  [
    'customWorkflows/REQUEST_CUSTOM_WORKFLOWS_BY_STATUS',
    'customWorkflows/RECEIVE_CUSTOM_WORKFLOWS_BY_STATUS',
    'customWorkflows/REQUEST_CUSTOM_WORKFLOWS_BY_STATUS_FAILED',
  ],
  (action) => action.status,
);
const allWorkflowsRequest = createRequestReducer([
  'customWorkflows/REQUEST_ALL_CUSTOM_WORKFLOWS',
  'customWorkflows/RECEIVE_ALL_CUSTOM_WORKFLOWS',
  'customWorkflows/REQUEST_ALL_CUSTOM_WORKFLOWS_DONE',
  'customWorkflows/REQUEST_ALL_CUSTOM_WORKFLOWS_FAILED',
]);
const workflowByIdRequest = createRequestReducer([
  'customWorkflows/REQUEST_CUSTOM_WORKFLOW_BY_ID',
  'customWorkflows/RECEIVE_CUSTOM_WORKFLOW_BY_ID',
  'customWorkflows/REQUEST_CUSTOM_WORKFLOW_BY_ID_FAILED',
]);

// main reducer
export const customWorkflows = combineReducers({
  currentFlag,
  ids,
  entities,
  byStatus,
  workflowsByStatusRequest,
  allWorkflowsRequest,
  workflowByIdRequest,
});

registry.addReducers({ customWorkflows });

// selectors
export const customWorkflowsSelector = (state: GlobalState) => state.customWorkflows;
export const customWorkflowsIdsSelector = (state: GlobalState) => customWorkflowsSelector(state).ids;
export const customWorkflowsCurrentFlagSelector = (state: GlobalState) => customWorkflowsSelector(state).currentFlag;
export const customWorkflowsEntitiesSelector = (state: GlobalState) => customWorkflowsSelector(state).entities;
export const customWorkflowsRequestSelector = (state: GlobalState) =>
  customWorkflowsSelector(state).workflowsByStatusRequest;
export const allCustomWorkflowsRequestSelector = (state: GlobalState) =>
  customWorkflowsSelector(state).allWorkflowsRequest;
export const customWorkflowByIdRequestSelector = (state: GlobalState) =>
  customWorkflowsSelector(state).workflowByIdRequest;
/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const customWorkflowsListSelector = createSelector(
  customWorkflowsIdsSelector,
  customWorkflowsEntitiesSelector,
  (idsSet, entitiesMap) =>
    idsSet.reduce((list, id) => (entitiesMap.has(id) ? list.push(entitiesMap.get(id)!) : list), List<CustomWorkflow>()),
); /* eslint-enable @typescript-eslint/no-non-null-assertion */
export const customWorkflowsByStatusSelector = (state: GlobalState) => customWorkflowsSelector(state).byStatus;
/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const activeCustomWorkflowsListSelector = (state: GlobalState) =>
  customWorkflowsByStatusSelector(state).has(CustomWorkflowExecutionStatusType.ACTIVE)
    ? customWorkflowsByStatusSelector(state).get(CustomWorkflowExecutionStatusType.ACTIVE)!.toList()
    : List<CustomWorkflow>(); /* eslint-enable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const completedCustomWorkflowsListSelector = (state: GlobalState) =>
  customWorkflowsByStatusSelector(state).has(CustomWorkflowExecutionStatusType.COMPLETED)
    ? customWorkflowsByStatusSelector(state).get(CustomWorkflowExecutionStatusType.COMPLETED)!.toList()
    : List<CustomWorkflow>(); /* eslint-enable @typescript-eslint/no-non-null-assertion */

export const customWorkflowByIdSelector = createSelector<
  GlobalState,
  { id: string },
  CustomWorkflowsEntitiesState,
  string,
  CustomWorkflow | undefined
>(
  customWorkflowsEntitiesSelector,
  (_, props) => props.id,
  (entitiesMap, id) => entitiesMap.get(id),
);
