import { Icon, IconName } from '@launchpad-ui/icons';
import cx from 'clsx';

import { InstructionListChangeKind } from './instructionListUtils';

import './styles.css';

const instructionListIcons: { [key in InstructionListChangeKind]: IconName | undefined } = {
  [InstructionListChangeKind.CHANGE]: 'change-history',
  [InstructionListChangeKind.ADD]: 'add',
  [InstructionListChangeKind.REMOVE]: 'minus',
  [InstructionListChangeKind.REORDER]: 'swap-vertical',
  [InstructionListChangeKind.DEFAULT]: undefined,
  [InstructionListChangeKind.NONE]: undefined,
  [InstructionListChangeKind.DECIMAL]: undefined,
};

type FlagStatusIconProps = {
  changeKind: InstructionListChangeKind;
  className?: string;
  testId?: string;
};

/* eslint-disable import/no-default-export */
export default function InstructionListItemIcon({ changeKind, className, testId }: FlagStatusIconProps) {
  const name = instructionListIcons[changeKind] as IconName;
  if (name) {
    return (
      <Icon
        name={name}
        data-test-id={testId}
        size="small"
        className={cx(`InstructionListItemIcon-${changeKind}`, 'InstructionListItemIcon', className)}
      />
    );
  }
  return <></>;
}
