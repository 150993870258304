import { useState } from 'react';
import { createDefaultShortcuts, DateRangeFilterWithTrigger } from '@gonfalon/datetime';
import { endOfDay, startOfDay, subMonths } from 'date-fns';

type ActivityFiltersProps = {
  before?: Date;
  after?: Date;
  onFilterChange: ({ before, after }: { before: Date; after: Date }) => void;
};

export const ActivityDateFilters = ({ before, after, onFilterChange }: ActivityFiltersProps) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const today = new Date();
  const threeMonthsAgo = subMonths(today, 3);
  const [defaultAfter, defaultBefore] = [startOfDay(threeMonthsAgo), endOfDay(today)];

  const [selectedDateRange, setSelectedDateRange] = useState({
    selectedStartDate: after || defaultAfter,
    selectedEndDate: before || defaultBefore,
  });

  const handleDatesChange = (dates: Date[]) => {
    const [startDate, endDate] = dates;
    setSelectedDateRange({
      selectedStartDate: startDate,
      selectedEndDate: endDate,
    });
  };

  const handleDatesConfirm = () => {
    onFilterChange({
      after: selectedDateRange.selectedStartDate,
      before: selectedDateRange.selectedEndDate,
    });
    setIsDatePickerOpen(false);
  };

  return (
    <DateRangeFilterWithTrigger
      createShortcuts={createDefaultShortcuts}
      isOpen={isDatePickerOpen}
      dates={[selectedDateRange.selectedStartDate, selectedDateRange.selectedEndDate]}
      onChange={handleDatesChange}
      onConfirm={handleDatesConfirm}
    />
  );
};
