import { components, SingleValueProps } from 'react-select';
import { isNil } from '@gonfalon/es6-utils';
import { CustomSelect, OptionProps } from '@gonfalon/launchpad-experimental';
import { getVariationName } from 'ia-poc/services/flag-creation/utils';
import { List } from 'immutable';

import VariationComponent from 'components/Variation';
import { colorVariation, Variation } from 'utils/flagUtils';

type VariationOptionProps = {
  name?: string;
  value: string;
  color: string;
  description?: string;
  _key: string;
  _id: string;
};

type SelectVariationProps = {
  value?: number;
  variations: List<Variation>;
  onChange(variation: Variation): void;
  disabled?: boolean;
  variationIdsToFilter?: string[];
  placeholder?: string;
  customComponents?: object;
  ariaLabel?: string;
  id?: string;
};

function Option({
  name,
  value,
  color,
  description,
}: Pick<VariationOptionProps, 'name' | 'value' | 'color' | 'description'>) {
  return <VariationComponent name={name} value={value} color={color} description={description} inlineDescription />;
}

export const SingleValue = (singleValueProps: SingleValueProps<VariationOptionProps>) => (
  <components.SingleValue {...singleValueProps}>
    <Option name={singleValueProps.data.name} value={singleValueProps.data.value} color={singleValueProps.data.color} />
  </components.SingleValue>
);

/* eslint-disable import/no-default-export */
export default function SelectVariation({
  ariaLabel = 'Select variation',
  id = 'selectVariation',
  value,
  variations,
  onChange,
  disabled,
  placeholder,
  customComponents,
  variationIdsToFilter = [],
}: SelectVariationProps) {
  const options: VariationOptionProps[] = variations
    .map((v, i) => ({
      value: v.getValueString(),
      name: v.name,
      description: v.description,
      color: colorVariation(i),
      _key: v._key,
      _id: v._id,
    }))
    .filter((v) => !variationIdsToFilter.includes(v._id))
    .toJS();

  const selected = !isNil(value) && variations.get(value) ? variations.get(value)?.getValueString() : undefined;

  function onChangeVariation(option: OptionProps) {
    const variation = variations.find((v) => v._key === option._key);
    if (variation) {
      onChange(variation);
    }
  }

  return (
    <CustomSelect
      id={id}
      ariaLabel={id ? undefined : ariaLabel}
      isDisabled={disabled}
      isClearable={false}
      backspaceRemoves={false}
      options={options}
      value={options.filter((option) => option.value === selected)}
      formatOptionLabel={(option) => <Option {...(option as unknown as VariationOptionProps)} />}
      getOptionLabel={(option: VariationOptionProps) =>
        getVariationName(
          variations.toJS(),
          variations.findIndex((v) => v._key === option._key),
        )
      }
      onChange={onChangeVariation}
      placeholder={placeholder}
      customComponents={{ ...customComponents, SingleValue }}
    />
  );
}
