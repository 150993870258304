import { stringifyValue } from '@gonfalon/types';
import { List } from 'immutable';

import { Flag, Variation } from 'utils/flagUtils';
import { toJS } from 'utils/immutableUtils';
import { InstructionCategory, InstructionsType, SemanticInstruction } from 'utils/instructions/shared/types';
import { UserSettings } from 'utils/userUtils';

import { getInstructionsByManyKinds } from '../shared/helpers';
import { makeUpdateUserTargetsKey } from '../userTargets/helpers';
import {
  AddUserTargetsSemanticInstruction,
  RemoveUserTargetsSemanticInstruction,
  UserTargetsInstructionKind,
} from '../userTargets/types';

import {
  AddTargetsSemanticInstruction,
  RemoveTargetsSemanticInstruction,
  ReplaceTargetsSemanticInstruction,
  TargetsInstructionKind,
  UpdateTargetsSemanticInstruction,
} from './types';

export function makeAddTargetsInstruction(
  targetKeys: string[],
  variationId: string,
  contextKind: string,
): AddTargetsSemanticInstruction {
  return {
    kind: TargetsInstructionKind.ADD_TARGETS,
    contextKind,
    values: targetKeys,
    variationId,
  };
}

export function makeRemoveTargetsInstruction(
  targetKeys: string[],
  variationId: string,
  contextKind: string,
): RemoveTargetsSemanticInstruction {
  return {
    kind: TargetsInstructionKind.REMOVE_TARGETS,
    contextKind,
    values: targetKeys,
    variationId,
  };
}

export function sortTargetInstructions(instructions: List<SemanticInstruction> | undefined): List<SemanticInstruction> {
  if (!instructions) {
    return List();
  }
  const removeTargets = instructions.filter((i) => i.kind === TargetsInstructionKind.REMOVE_TARGETS);
  const addTargets = instructions.filter((i) => i.kind === TargetsInstructionKind.ADD_TARGETS);
  return removeTargets.concat(addTargets);
}

export function combineTargetsInstructions(
  newInstruction: UpdateTargetsSemanticInstruction,
  pendingSemanticPatch: InstructionsType,
): InstructionsType {
  const variationId = newInstruction.variationId;
  const contextKind = newInstruction.contextKind;
  const addKey = makeUpdateTargetsKey(variationId, TargetsInstructionKind.ADD_TARGETS, contextKind);
  const removeKey = makeUpdateTargetsKey(variationId, TargetsInstructionKind.REMOVE_TARGETS, contextKind);
  let add = pendingSemanticPatch.get(addKey) as UpdateTargetsSemanticInstruction;
  let remove = pendingSemanticPatch.get(removeKey) as UpdateTargetsSemanticInstruction;

  if (newInstruction.kind === TargetsInstructionKind.ADD_TARGETS) {
    if (!add) {
      add = makeAddTargetsInstruction([], variationId, contextKind);
    }
    // Reverse to match order that the server will add.
    for (const value of newInstruction.values.reverse()) {
      if (remove && remove.values.includes(value)) {
        remove = { ...remove, values: remove.values.filter((target) => target !== value) };
      } else if (!add.values.includes(value)) {
        add = { ...add, values: [value, ...add.values] };
      }
    }
  } else if (newInstruction.kind === TargetsInstructionKind.REMOVE_TARGETS) {
    if (!remove) {
      remove = makeRemoveTargetsInstruction([], variationId, contextKind);
    }
    for (const value of newInstruction.values) {
      if (add && add.values.includes(value)) {
        add = { ...add, values: add.values.filter((target) => target !== value) };
      } else if (!remove.values.includes(value)) {
        remove = { ...remove, values: [value, ...remove.values] };
      }
    }
  }

  let result = pendingSemanticPatch;

  // clear old instructions
  result = result.delete(addKey).delete(removeKey);

  if (add && add.values.length > 0) {
    result = result.set(addKey, add);
  }
  if (remove && remove.values.length > 0) {
    result = result.set(removeKey, remove);
  }
  return result;
}

export function makeUpdateTargetsKey(
  variationId: string,
  instructionKind: TargetsInstructionKind,
  contextKind: string,
): string {
  return [InstructionCategory.TARGETS, variationId, instructionKind, contextKind].join('|');
}

export const getPendingAddedUsers = (variationId: string, pendingSemanticPatchInstructions: InstructionsType) => {
  const key = makeUpdateUserTargetsKey(variationId, UserTargetsInstructionKind.ADD_USER_TARGETS);
  const addedUsersInstruction = toJS(pendingSemanticPatchInstructions.get(key)) as
    | AddUserTargetsSemanticInstruction
    | undefined;
  return addedUsersInstruction?.values || [];
};

export const getPendingRemovedUsers = (variationId: string, pendingSemanticPatchInstructions: InstructionsType) => {
  const key = makeUpdateUserTargetsKey(variationId, UserTargetsInstructionKind.REMOVE_USER_TARGETS);
  const removedUsersInstruction = toJS(pendingSemanticPatchInstructions.get(key)) as
    | RemoveUserTargetsSemanticInstruction
    | undefined;
  return removedUsersInstruction?.values || [];
};

export const getPendingAddedTargets = (
  variationId: string,
  pendingSemanticPatchInstructions: InstructionsType,
  contextKind: string,
) => {
  const key = makeUpdateTargetsKey(variationId, TargetsInstructionKind.ADD_TARGETS, contextKind);
  const addedTargetsInstruction = pendingSemanticPatchInstructions.get(key) as
    | UpdateTargetsSemanticInstruction
    | undefined;
  return addedTargetsInstruction?.values || List();
};

export const getPendingRemovedTargets = (
  variationId: string,
  pendingSemanticPatchInstructions: InstructionsType,
  contextKind: string,
) => {
  const key = makeUpdateTargetsKey(variationId, TargetsInstructionKind.REMOVE_TARGETS, contextKind);
  const removedTargetsInstruction = pendingSemanticPatchInstructions.get(key) as
    | UpdateTargetsSemanticInstruction
    | undefined;
  return removedTargetsInstruction?.values || List();
};

export const getUpdateTargetsInstructionKindForUser = ({
  userKey,
  variationId,
  pendingSemanticPatchInstructions,
}: {
  userKey: string;
  variationId: string;
  pendingSemanticPatchInstructions: InstructionsType;
}) => {
  const addedUsers = getPendingAddedUsers(variationId, pendingSemanticPatchInstructions);
  const removedUsers = getPendingRemovedUsers(variationId, pendingSemanticPatchInstructions);

  if (addedUsers?.includes(userKey)) {
    return UserTargetsInstructionKind.ADD_USER_TARGETS;
  }
  if (removedUsers?.includes(userKey)) {
    return UserTargetsInstructionKind.REMOVE_USER_TARGETS;
  }

  return undefined;
};

export const getUpdateTargetsInstructionKindForContext = ({
  targetKey,
  contextKind,
  variationId,
  pendingSemanticPatchInstructions,
}: {
  targetKey: string;
  contextKind: string;
  variationId: string;
  pendingSemanticPatchInstructions: InstructionsType;
}) => {
  const addedTargetKeys = getPendingAddedTargets(variationId, pendingSemanticPatchInstructions, contextKind);
  const removedTargetKeys = getPendingRemovedTargets(variationId, pendingSemanticPatchInstructions, contextKind);
  if (addedTargetKeys?.includes(targetKey)) {
    return TargetsInstructionKind.ADD_TARGETS;
  }
  if (removedTargetKeys?.includes(targetKey)) {
    return TargetsInstructionKind.REMOVE_TARGETS;
  }

  return undefined;
};

export const makeRemoveContextDetailTargetInstruction = (
  ContextKeys: string[],
  variationId: string,
  contextKind: string,
) => makeRemoveTargetsInstruction(ContextKeys ?? [], variationId, contextKind);

export const makeAddContextDetailTargetInstruction = (
  ContextKeys: string[],
  variationId: string,
  contextKind: string,
) => makeAddTargetsInstruction(ContextKeys ?? [], variationId, contextKind);

export const getTargetsInstructionsFromContextsDetails = (
  contextKey: string,
  flag: Flag,
  originalUserSetting: UserSettings,
  contextKind: string,
  targetVariation?: Variation,
) => {
  const originalVariationValue = stringifyValue(originalUserSetting._value);
  let isOriginalValueDefault = true;
  const newVariationValue = targetVariation ? stringifyValue(targetVariation.value) : null;
  if (originalVariationValue !== newVariationValue) {
    isOriginalValueDefault = false;
  }
  if (originalVariationValue === newVariationValue && !isOriginalValueDefault) {
    return [];
  }

  const shouldAddToNewVariation = newVariationValue !== null;
  const shouldRemoveFromCurrVariation =
    (newVariationValue === null && !isOriginalValueDefault) || (shouldAddToNewVariation && !isOriginalValueDefault);

  const instructions = [];
  if (shouldRemoveFromCurrVariation) {
    const currVariationId = flag.variations.find((v) => stringifyValue(v.value) === originalVariationValue)?._id;
    if (currVariationId) {
      instructions.push(makeRemoveContextDetailTargetInstruction([contextKey], currVariationId, contextKind));
    }
  }
  if (shouldAddToNewVariation) {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    instructions.push(
      makeAddContextDetailTargetInstruction([contextKey], targetVariation!._id, contextKind),
    ); /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }
  return instructions;
};

export function filterSemanticInstructionsByTargetsInstructionKinds(semanticInstructions: SemanticInstruction[]) {
  return getInstructionsByManyKinds(semanticInstructions, Object.values(TargetsInstructionKind));
}

export function makeReplaceTargetsInstruction(
  targets: Array<{ variationId: string; values: string[]; contextKind: string }>,
  contextKind: string,
): ReplaceTargetsSemanticInstruction {
  return {
    kind: TargetsInstructionKind.REPLACE_TARGETS,
    targets,
    contextKind,
  };
}

export function hasEmptyTargets(instruction: ReplaceTargetsSemanticInstruction) {
  return instruction.targets.every((v) => v.values.length === 0);
}
