import { getEnterpriseCampaign as getModernSubscriptionEnterpriseCampaign } from '../internal/modernSubscription';
import { Subscription } from '../types';

import { isModernSubscription } from './isModernSubscription';

export function getEnterpriseCampaign(subscription: Subscription) {
  if (isModernSubscription(subscription)) {
    return getModernSubscriptionEnterpriseCampaign(subscription);
  }

  // no campaigns on legacy subscriptions
  return undefined;
}
