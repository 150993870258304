import type { ChipProps } from 'launchpad';
import { Chip } from 'launchpad';

type OnOffChipProps = Omit<ChipProps, 'kind'> & {
  isOn: boolean;
};
const OnOffChip = ({ isOn, ...props }: OnOffChipProps) => (
  <Chip data-test-id="on-off-chip" kind={isOn ? 'success' : undefined} aria-disabled={!isOn} {...props}>
    {isOn ? 'On' : 'Off'}
  </Chip>
);

/* eslint-disable import/no-default-export */
export default OnOffChip;
