export enum UserTargetsInstructionKind {
  ADD_USER_TARGETS = 'addUserTargets',
  REMOVE_USER_TARGETS = 'removeUserTargets',
  REPLACE_USER_TARGETS = 'replaceUserTargets',
}

export type AddUserTargetsSemanticInstruction = {
  kind: UserTargetsInstructionKind.ADD_USER_TARGETS;
  values: string[];
  variationId: string;
};

export type RemoveUserTargetsSemanticInstruction = {
  kind: UserTargetsInstructionKind.REMOVE_USER_TARGETS;
  values: string[];
  variationId: string;
};

export type UpdateUserTargetsSemanticInstruction =
  | AddUserTargetsSemanticInstruction
  | RemoveUserTargetsSemanticInstruction;

export type ReplaceUserTargetsSemanticInstruction = {
  kind: UserTargetsInstructionKind.REPLACE_USER_TARGETS;
  targets: Array<{ variationId: string; values: string[] }>;
};
