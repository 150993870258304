import { OrderedMap } from 'immutable';

import http, { jsonToImmutable, jsonToImmutableError } from 'utils/httpUtils';
import { AllFlagInstructionKinds, ScheduleInstructionKind } from 'utils/instructions/shared/types';
import {
  createScheduledChange,
  FlagConfigScheduledChangesState,
  ScheduledChange,
  ScheduleFetchType,
} from 'utils/scheduledChangesUtils';

import { UrlProps } from './types/utils';

const getConflictsForScheduledChangesForFlag = async ({
  projKey,
  envKey,
  flagKey,
  instructions,
  executionDate,
  existingScheduledChangeId,
}: UrlProps & ScheduleFetchType) => {
  const url = `/api/v2/projects/${projKey}/flags/${flagKey}/environments/${envKey}/scheduled-changes-conflicts`;
  const body: ScheduleFetchType = {
    executionDate,
    instructions,
  };

  if (existingScheduledChangeId) {
    body.existingScheduledChangeId = existingScheduledChangeId;
  }

  return http
    .report(url, {
      beta: true,
      body,
    })
    .then(jsonToImmutable)
    .then((conflicts) => conflicts.get('items'))
    .catch(jsonToImmutableError);
};

const getScheduledChangesForFlag = async ({ projKey, envKey, flagKey }: UrlProps) => {
  const url = `/api/v2/projects/${projKey}/flags/${flagKey}/environments/${envKey}/scheduled-changes`;
  return http
    .get(url, {
      beta: true,
    })
    .then(jsonToImmutable)
    .then((scheduledChanges) =>
      scheduledChanges
        .get('items')
        .map(createScheduledChange)
        .reduce(
          (accum: FlagConfigScheduledChangesState, curr: ScheduledChange) => accum.set(curr.get('_id'), curr),
          OrderedMap(),
        ),
    )
    .catch(jsonToImmutableError);
};

const getScheduledChangeForFlag = async ({ projKey, envKey, flagKey, id }: UrlProps & { id: string }) => {
  const url = `/api/v2/projects/${projKey}/flags/${flagKey}/environments/${envKey}/scheduled-changes/${id}`;
  return http.get(url, { beta: true }).then(jsonToImmutable).then(createScheduledChange).catch(jsonToImmutableError);
};

const postScheduledChangesForFlag = async ({
  projKey,
  envKey,
  flagKey,
  executionDate,
  instructions,
  comment,
}: {
  flagKey: string;
  projKey: string;
  envKey: string;
  executionDate: number;
  instructions: Array<{ kind: string }>;
  comment?: string;
}) => {
  const url = `/api/v2/projects/${projKey}/flags/${flagKey}/environments/${envKey}/scheduled-changes?ignoreConflicts=true`;
  return http
    .post(url, {
      beta: true,
      body: {
        executionDate,
        instructions,
        comment,
      },
    })
    .then(jsonToImmutable)
    .then(createScheduledChange)
    .catch(jsonToImmutableError);
};

const deleteScheduledChangesForFlag = async ({
  projKey,
  envKey,
  flagKey,
  workflowID,
}: UrlProps & { workflowID: string }) => {
  const url = `/api/v2/projects/${projKey}/flags/${flagKey}/environments/${envKey}/scheduled-changes/${workflowID}`;
  return http
    .delete(url, {
      beta: true,
    })
    .catch(jsonToImmutableError);
};

const updateScheduledChangesForFlag = async ({
  projKey,
  envKey,
  flagKey,
  executionDate,
  workflowID,
  instructions,
  comment,
}: {
  flagKey: string;
  projKey: string;
  envKey: string;
  executionDate?: number;
  workflowID: string;
  instructions: Array<{ kind: AllFlagInstructionKinds }>;
  comment?: string;
}) => {
  const url = `/api/v2/projects/${projKey}/flags/${flagKey}/environments/${envKey}/scheduled-changes/${workflowID}?ignoreConflicts=true`;
  const bodyInstructions: Array<{ kind: AllFlagInstructionKinds | ScheduleInstructionKind; value: number | object }> = [
    { kind: ScheduleInstructionKind.REPLACE_SCHEDULED_CHANGES, value: instructions },
  ];
  const body = {
    comment,
    instructions: bodyInstructions,
  };

  if (executionDate) {
    body.instructions.push({
      kind: ScheduleInstructionKind.UPDATE_SCHEDULED_CHANGES_EXECUTION_DATE,
      value: executionDate,
    });
  }

  return http
    .patch(url, {
      beta: true,
      body,
    })
    .then(jsonToImmutable)
    .then(createScheduledChange)
    .catch(jsonToImmutableError);
};

export {
  getScheduledChangesForFlag,
  postScheduledChangesForFlag,
  deleteScheduledChangesForFlag,
  updateScheduledChangesForFlag,
  getConflictsForScheduledChangesForFlag,
  getScheduledChangeForFlag,
};
