import { isSeatLimitEnforced as isSeatLimitEnforcedFlag } from '@gonfalon/dogfood-flags';

import { LegacySubscription } from '../../types';

import { getSeatLimit } from './getSeatLimit';
import { isSeatLimitReached } from './isSeatLimitReached';

export function getRemainingSeats(legacySubscription: LegacySubscription) {
  const isSeatLimitEnforced = isSeatLimitEnforcedFlag();
  const seatLimit = getSeatLimit(legacySubscription);

  if (!isSeatLimitEnforced || !seatLimit) {
    return -1;
  }

  if (!isSeatLimitReached(legacySubscription) && legacySubscription._usage?.memberCount !== undefined) {
    return seatLimit - legacySubscription._usage.memberCount;
  }

  return 0;
}
