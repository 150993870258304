// eslint-disable-next-line no-restricted-imports
import { useDispatch, useSelector } from 'react-redux';
import { Campaigns } from '@gonfalon/billing';
import { enableSelfServiceEnterpriseTrials as enableSelfServiceEnterpriseTrialsFlag } from '@gonfalon/dogfood-flags';

import { createSubscriptionCampaign } from 'actions/billing';
import { subscriptionSelector } from 'reducers/billing';
import { useProfileEntity } from 'reducers/profile';

import { StartEnterpriseCampaignButton } from './StartEnterpriseCampaignButton';

const useRedux = () => {
  const dispatch = useDispatch();
  const onSetTrialEnterpriseCampaign = () => dispatch(createSubscriptionCampaign(Campaigns.ENTERPRISE));
  const profile = useProfileEntity();
  const subscription = useSelector(subscriptionSelector).entity;
  const enableSelfServiceEnterpriseTrials = enableSelfServiceEnterpriseTrialsFlag();
  return {
    onSetTrialEnterpriseCampaign,
    subscription,
    enableSelfServiceEnterpriseTrials,
    profile,
  };
};

export const StartEnterpriseCampaignContainer = ({
  cta,
  upsellFeaturePageKind,
}: {
  cta?: string;
  upsellFeaturePageKind: string;
}) => {
  const { subscription, onSetTrialEnterpriseCampaign, enableSelfServiceEnterpriseTrials, profile } = useRedux();

  return (
    <StartEnterpriseCampaignButton
      cta={cta}
      upsellFeaturePageKind={upsellFeaturePageKind}
      subscription={subscription}
      enableSelfServiceEnterpriseTrials={enableSelfServiceEnterpriseTrials}
      onSetTrialEnterpriseCampaign={onSetTrialEnterpriseCampaign}
      profile={profile}
    />
  );
};
