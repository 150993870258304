import { useLatestMeasuredRollout } from './useLatestMeasuredRollout';

export function useActiveMeasuredRollout({
  projectKey,
  flagKey,
  environmentKey,
  enabled = true,
}: {
  projectKey: string;
  flagKey: string;
  environmentKey: string;
  enabled?: boolean;
}) {
  const result = useLatestMeasuredRollout({ projectKey, flagKey, environmentKey, enabled });

  if (result.data?.state.status !== 'archived') {
    return result;
  }

  return { ...result, data: undefined };
}
