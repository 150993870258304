// eslint-disable-next-line no-restricted-imports
import { useSelector } from 'react-redux';
import { AnyAction } from 'redux';

import formActionTypes from 'actionTypes/forms';
import { GlobalState } from 'reducers';
import registry from 'reducers/registry';
import { createFormState } from 'utils/formUtils';
import { createPendingChangesForm, PendingChangesFormRecord } from 'utils/pendingChangesUtils';

export const createInitialState = (pendingChangesForm?: PendingChangesFormRecord) =>
  createFormState(pendingChangesForm || createPendingChangesForm({}));

export const pendingChangesForm = (state = createInitialState(), action: AnyAction) => {
  if (action.model && action.model !== 'pendingChangesForm') {
    return state;
  }
  switch (action.type) {
    case 'pendingChanges/EDIT_PENDING_CHANGES_FORM':
      const modified = state.modified.set(action.field, action.value);
      return state.revalidate(modified).trackField(action.field);
    case formActionTypes.DESTROY:
      return createInitialState();
    case formActionTypes.INITIALIZE:
      return createInitialState(action.initialState && action.initialState.set('isInitialized', true));
    case formActionTypes.BLUR:
      return state.handleBlur(action.field, state.modified);
    default:
      return state;
  }
};

export const pendingChangesFormSelector = (state: GlobalState) => state.pendingChangesForm;

export function usePendingChangesFormState() {
  return useSelector(pendingChangesFormSelector);
}
export function usePendingChangesFormModifiedState() {
  return usePendingChangesFormState().get('modified');
}

registry.addReducers({
  pendingChangesForm,
});
