import { isSelfServe as isModernSubscriptionSelfServe } from '../internal/modernSubscription';
import { Subscription } from '../types';

import { isModernSubscription } from './isModernSubscription';

export function isSelfServe(subscription: Subscription) {
  if (isModernSubscription(subscription)) {
    return isModernSubscriptionSelfServe(subscription);
  }

  // not supported on legacy subscriptions
  return false;
}
