import { isModernProfessionalPlan } from '../internal/modernSubscription/isModernProfessionalPlan';
import { Subscription } from '../types';

import { isModernSubscription } from './isModernSubscription';

export function isProfessionalPlan(subscription: Subscription): boolean {
  if (isModernSubscription(subscription)) {
    return isModernProfessionalPlan(subscription);
  }
  return false;
}
