import { some } from '@gonfalon/es6-utils';
import { pluralize } from '@gonfalon/strings';
import { isImmutable, List } from 'immutable';

export function titlecase(str: string | null | undefined) {
  const s = str ?? '';
  return s
    .toString()
    .toLowerCase()
    .replace(/\b([a-z])/g, (ch) => ch.toUpperCase());
}

export function stringContains(str: string | null | undefined, text: string | null | undefined) {
  if (!text || text.trim().length === 0) {
    return true;
  }
  return str && str.length > 0 ? str.toLowerCase().indexOf(text.trim().toLowerCase()) > -1 : false;
}

export function makeFilter(text: string, ...props: string[]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (obj: any) {
    if (text.length === 0) {
      return true;
    }
    return some(props, (p) => {
      const prop = obj.get ? obj.get(p) : obj[p];

      if (isImmutable(prop)) {
        return prop.some((v) => stringContains(v, text));
      }

      return stringContains(prop, text);
    });
  };
}

export const joinOrCollapseItems = (items: List<string>, threshold: number, term: string, separator = ', ') =>
  items.size > threshold ? `${items.size} ${term}` : items.join(separator);

export const dotSeparateKey = (v: string) =>
  v
    .replace(/([a-z])([A-Z])/g, '$1.$2')
    .replace(/\s+/g, '.')
    .replace(/-+/g, '.')
    .replace(/_+/g, '.')
    .toLowerCase();

export const pluralizeLastWord = (text: string, count: number) => {
  const textArray = text.split(' ');
  if (textArray.length === 1) {
    return pluralize(textArray[0], count);
  }
  const lastWord = textArray[textArray.length - 1];
  const sentenceStart = textArray.slice(0, -1).join(' ');
  return `${sentenceStart} ${pluralize(lastWord, count)}`;
};

export const numberToWord = (n: number) => {
  if (n < 0 || n > 10 || !Number.isInteger(n)) {
    return `${n}`;
  }
  return ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'][n];
};

export const truncateString = (string: string, maxLength: number, replacement = '…') => {
  if (string.length < maxLength) {
    return string;
  }
  return `${string.substring(0, maxLength)}${replacement}`;
};

export const stringContainsEmoji = (str: string) => /\p{Emoji}/u.test(str);

export const stripEmojisFromString = (str: string) => {
  const regex =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  return str.replace(regex, '');
};

/**
By default, this function returns strings like this:
- [] => ''
- ['one'] => 'one'
- ['one', 'two'] => 'one and two'
- ['one', 'two', 'three'] => 'one, two and three'
- ['one', 'two', 'three', 'four'] => 'one, two, three and four'

Customize the conjunction by supplying a new one, or false to not use one.

{ conjunction: 'with' }
- ['one', 'two', 'three'] => 'one, two with three'

{ conjunction: false }
- ['one', 'two', 'three'] => 'one, two, three'

To truncate the joined list, pass { truncate: true } as an option to use defaults,
- ['one', 'two', 'three'] => 'one, two and 1 other'
- ['one', 'two', 'three', 'four'] => 'one, two and 2 others'

or provide props to customize the truncation. For example:

{ truncate: { threshold: 3, term: 'more' } }
- ['one', 'two', 'three', 'four'] => 'one, two, three and 1 more'
 */
export function joinStringArray(
  array: string[],
  options: {
    separator?: string;
    conjunction?: boolean | string;
    truncate?:
      | boolean
      | {
          threshold?: number;
          term?: string;
        };
  } = {},
) {
  const separator = options.separator || ', ';
  const conjunction = options.conjunction === undefined || options.conjunction === true ? 'and' : options.conjunction;
  const lastSeparator = conjunction ? ` ${conjunction} ` : separator;
  const { threshold = 2, term = 'other' } = options.truncate === true ? {} : options.truncate || {};

  if (options.truncate && array.length > threshold) {
    const visible = array.slice(0, threshold);
    const others = array.slice(threshold);

    return [visible.join(separator), `${others.length} ${pluralizeLastWord(term, others.length)}`].join(lastSeparator);
  }

  return array.length > 1
    ? [array.slice(0, array.length - 1).join(separator), array[array.length - 1]].join(lastSeparator)
    : array.join(lastSeparator);
}
