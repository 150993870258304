import { Time } from '@gonfalon/datetime';
import { DateFormat } from '@gonfalon/format';
import { format, isSameYear } from 'date-fns';

function monthAndYear(date: Date | number) {
  return format(date, DateFormat.MMM_YYYY);
}

export type TimeRangeProps = {
  startDate: Date | number;
  endDate: Date | number;
};

export function TimeRange({ startDate, endDate }: TimeRangeProps) {
  let startDateFormat;
  let endDateFormat;

  if (monthAndYear(startDate) === monthAndYear(endDate)) {
    startDateFormat = DateFormat.MMM_D;
    endDateFormat = DateFormat.D_YYYY;
  } else if (isSameYear(startDate, endDate)) {
    startDateFormat = DateFormat.MMM_D;
    endDateFormat = DateFormat.MMM_D_YYYY;
  } else {
    startDateFormat = DateFormat.MMM_D_YYYY;
    endDateFormat = DateFormat.MMM_D_YYYY;
  }

  return (
    <span>
      <Time dateFormat={startDateFormat} datetime={startDate} notooltip /> &ndash;{' '}
      <Time dateFormat={endDateFormat} datetime={endDate} notooltip />
    </span>
  );
}
